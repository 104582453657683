import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const ReviewCardWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fff;
  position: relative;
  font-family: "Lato", sans-serif;
  border-radius: 20px;
  cursor: pointer;
  .column {
    float: left;
    width: 25%;
    padding: 0 10px;
   }  
   @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }
`;

export const ContentWrapper = styled.div`
.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 16px;
    text-align: center;
    background-color: #f1f1f1;
  }  
`;

export const OpenQuote = styled.blockquote`
  border:none;
  font-family:Georgia, "Times New Roman", Times, serif;
//   margin-bottom:-30px;
  content: close-quote;
  position: relative; 

  h3:before {
    content: close-quote;   
    font-weight: bold;
    font-size: 100px;
    color: #889c0b;
    position: absolute; 
    top: 10%;
    left: 50%; 
  }
`;

export const Comment = styled.span`
  font-size: 21px;
  font-weight: 700;
  @media screen and (max-width: 768px) {
      font-size: 14px;
  }
`;