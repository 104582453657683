import React, { useState } from "react";
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast'
import API from "../network/dashboardAPI";

const ResendPaymentOrder = (props) => {

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastBgColor, setToastBgColor] = useState("bg-success");
  const [toastMessage, setToastMessage] = useState("Payment reports has been sent successful");

  const handleClose = () => {
    setShow(false);
  };
  const handleSubmit = () => {
    try {
      setIsLoading(true);

      API.resendPaymentReport({order_id : props.orderId})
        .then((response)=>{
          setTimeout(() => {
            if(response.status !== 200)
              alert("Resend payment report failed!");

            setToastBgColor("bg-success");
            setToastMessage("Payment reports has been sent successful");
            setShowToast(true);
            setIsLoading(false);
            setShow(false);
          }, 750)
        })
    } catch(error) {
      setToastBgColor("bg-danger");
      setToastMessage("Resend payment report failed!");
      setShowToast(true);
      setIsLoading(false);

      console.log("Debug error: ", error)
    }
  };

  return (
    <div id='resendPaymentReport'>
      <Button size="sm"
              onClick={() => setShow(true)}
      >Resend Order</Button>

      <Toast className={toastBgColor}
             onClose={() => setShowToast(false)}
             show={showToast}
             delay={3000}
             autohide
             style={{
               position: 'absolute',
               top: 10,
               right: 35,
               zIndex: 1041,
             }}
      >
        <Toast.Header>
          <strong className="mr-auto">{toastMessage}</strong>
        </Toast.Header>
      </Toast>

      <Modal
        show={show}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Resend payment report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Regenerate Excel/PDF file and resend to Telegram.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={isLoading}>
            Exit
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? <Spinner className='mr-1' as="span" animation="grow" size="sm" role="status" aria-hidden="true"/> : ''}
            {isLoading ? 'Loading...' : 'Resend'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

ResendPaymentOrder.defaultProps = {
  orderId: null,
};

export default connect(null, null)(ResendPaymentOrder)
