import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Modal } from "@redq/reuse-modal";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../contexts/auth/auth.context";
import * as Constant from "../../constants/Constant";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import UploadService from "../../services/FileUploadService";
import {addCategory,setError,setLoading} from '../../store/actions/dashboardAction'
import { connect } from "react-redux";

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const AddCategory = (props) => {

    const { authDispatch } = useContext(AuthContext);
    let admin = localStorage.getItem("admin");
    let parsedUser = JSON.parse(admin);
    if(!admin) {
      window.location.href = "/";
    }

    const [itemName, setItemName] = useState("");
    const [image , setImage] = useState(null);
    const [file , setFile] = useState(null);
    const [isUploded, setIsUploded] = useState(false);
 
    const handleFileChange = e => {
        console.log(e.target.files[0])
        setFile(e.target.files[0])
    }

    const changeName = ((event) => {
         setItemName(event.target.value)
    });
 
    const addCategoryDate = () => {
        let postData = {   
            name: itemName,
            image : image,
        };
        props.addCategory(postData);
    }

    const uploadProductImage = () =>{
        if(file){
            setIsUploded(true)
            let formData = new FormData();
            formData.append("image", file);
            UploadService.uploadImageFromDashboard(formData)
            .then(response => {
            if(response.data.success) {
                setIsUploded(false)
                console.log(response.data.data[0].url)
               setImage(response.data.data[0].url)
            }
          });
        }
      }
   
  return (
    <Modal>
        <HelpPageWrapper>
            <Row>
                <Col sm={11}>
                    <h4>Category add</h4>
                </Col>
            </Row>
            <Row>
                <Col sm={11}>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" value={itemName} onChange={changeName} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Label>Image</Form.Label>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridFile">
                            <Form.Control type="file"  onChange={handleFileChange} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                            {isUploded ? (
                            <Spinner animation="grow" variant="success" />
                              ) :
                            <Button variant="primary" onClick={uploadProductImage}>
                                Uplode image
                            </Button>
                            }   
                            </Form.Group>
                            </Form.Row>
                            { !props.success ? (
                            <Alert variant="danger">
                                {props.error_message}
                            </Alert>
                        ) : ("")}
                            {(props.loading  || isUploded) ? (
                            <Spinner animation="grow" variant="success" />
                        ) : (
                            <Button variant="primary" onClick={addCategoryDate}>
                                Add
                            </Button>
                        )}
                    </Form>
                </Col> 
            </Row>
        </HelpPageWrapper>
    </Modal>
  );
}


const mapStateToProps = state =>{
     return {
      success : state.dashboardReducer.success,
      loading : state.dashboardReducer.loading,
      error_message : state.dashboardReducer.error_message
     }
    
   }
   
const mapDispatchToProps = dispatch => {
     return {
       addCategory : data => addCategory(data,dispatch),
       setError : data => setError(data,dispatch),
       setLoading : data => setLoading(data,dispatch)
     }
   }
export default connect(mapStateToProps,mapDispatchToProps)(AddCategory)
