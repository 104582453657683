import React, { useEffect, lazy, Suspense, useState } from "react";
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import StoreNav from "../components/StoreNav/StoreNav";
import Carousel from "../components/Carousel/Carousel";
import Banner from "../containers/Banner/Banner";
import Sidebar from "../containers/Sidebar/Sidebar";
// import Products from "../containers/Products/Products";
import CartPopUp from "../containers/Cart/CartPopUp";
import LazyLoader from "../components/LazyLoader/LazyLoader";
import {
  MainContentArea,
  SidebarSection,
  ContentSection,
  OfferSection,
  MainHeader,
  MobileCarouselDropdown,
} from "../styled/pages.style";
import { useDeviceType } from "../helper/useDeviceType";
import { useCart } from "../contexts/cart/use-cart";
// Static Data Import Here
// import OFFERS from '../data/offers';
import BannerImg from "../image/grocery.png";
import storeType from "../constants/storeType";
import queryString from "query-string";
import { connect, createDispatchHook } from "react-redux";
import {
  productCategory,
  ProductModalData,
  searchSuggestions,
  searchStoreProduct,
  getProductsMappedwithSubCat,
  getProductsMappedwithCat,
  getProductOutOfStock,
  getProductOutOfStockCat,
  getProductBrands,
  getProductFilterBrands,
  getSortProductsName,
  getSortProductsPrice,
  sortByNameProduct,
  SearchStoreProductByBrandRef,
  Toggle_Suggestions,
  changePage,
  resetPage
} from "../store/actions/productAction";
import {
  oosModified,
} from "../store/actions/globalAction"
import { SearchContext } from "../contexts/search/search.context";
import { offerSlider } from "../store/actions/globalAction";
import {cats} from "../helper/constant";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { BreadcrumbItem } from "react-bootstrap";
import Button from "../components/Button/Button";
import { setCookie, getCookie } from "../helper/constant";
import t from '../i18n/translate';

const PAGE_TYPE = "grocery";
const Products = lazy(() => import('../containers/Products/Products'));
const useRouter = () => {
  const params = useParams();

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
    };
  }, [params, match, location, history]);
};

const SearchedResults = (props, { userAgent }) => {
  const deviceType = useDeviceType(userAgent);
  const router = useRouter();
  let { catrefid, subcatrefid, text, brandName } = useParams();
  const { query, history } = useRouter();
  let mql = window.matchMedia("all and (min-width: 769px)");
  let mqs = window.matchMedia("all and (max-width: 768px)");
  const { state, searchdispatch } = React.useContext(SearchContext);
  const targetRef = React.useRef(null);
  const [catName, setCatName] = useState("");
  const [showBN, setShowBN] = useState(true);
  const [doOnlyOneTime, setDoOnlyOneTime] = useState(true);

  const {
    fetchOrderItems,
  } = useCart();
  const lang = localStorage.getItem('lang') || 'en';

  useEffect(() => {
    if(doOnlyOneTime) {
      setTimeout(() => {
        window.scrollTo({ top: 0 });
        setDoOnlyOneTime(false);
      }, 250)
    }
  }, [doOnlyOneTime])

  const breadCrumbmatch = () => {
    if (window.location.pathname.split("/").indexOf("brand") >= 0){
      //brand page
      return <>
        <Breadcrumb.Item href={"/brands"}>
        {"All Brands"}
        </Breadcrumb.Item>
        <Breadcrumb.Item href={"/brand/"+brandName} active>
        {brandName}
        </Breadcrumb.Item>
      </>
    }
    else if(window.location.pathname.split("/").indexOf("brand") >= 0){
      //allbrands
      return <>
        <Breadcrumb.Item href={"/brands"} active>
        {"All Brands"}
        </Breadcrumb.Item>
      </>
    }
    else if(catrefid !== undefined && subcatrefid !== undefined){
      //subcat
      return <>
        <Breadcrumb.Item href={"/"+catrefid}>
          {catName}
        </Breadcrumb.Item>
        <Breadcrumb.Item href={"/"+catrefid+"/"+subcatrefid} active>
          {localStorage.getItem("subcatname")}
        </Breadcrumb.Item>
      </>
    }
    else{
      //cat
      return <>
        <Breadcrumb.Item href={"/"+catrefid} active>
          {catName}
        </Breadcrumb.Item>
      </>
    }
  };

  const checkifcat = () => {
    const cat = props.category.filter((element, index) => {
      return element.cat_ref_id === window.location.pathname.split("/")[1]
    });
    if(cat.length > 0){
      return true;
    }
    else{
      return false;
    }
  }

  useEffect(() => {
    document.getElementsByClassName("product-cart")[0].style.visibility = "hidden";
    if(!props.category.length){
      props.productCategory({ get_type: 2, store_id: 1, lang });
      props.getProductBrands();

      if (text && text.length >= 3) {
        props.searchSuggestions({ search_query: text, store_id: 1 });
      }
    }
  }, []);

  useEffect(() => {
    if (props.category.length > 0 && window.location.pathname.split("/")[1] === "search") {
      document.getElementsByClassName("SearchBox")[0].value = text;
      if(mqs.matches){
        document.getElementsByClassName("searchIconWrapper")[0].click();
      }
      localStorage.setItem("mode","search");
      searchdispatch({
        type: "UPDATE",
        payload: {
          ...state,
          text,
        },
      });
    }
    else if (props.category.length > 0 && catrefid && subcatrefid) {
      let isSubCat = 0;
      let isCat = 0;
      props.category.map((element, index) => {
        if (element.cat_ref_id == catrefid) {
          isCat = 1;
          element.map_categories_sub_cats.map((sub_cat, idx1) => {
            sub_cat.list_sub_categories.map((sub_name, idx2) => {
              if (sub_name.sub_cat_ref_id == subcatrefid) {
                isSubCat = 1;
                localStorage.setItem("sub_cat_id", sub_name.sub_cat_id);
                localStorage.setItem("subcatname", sub_name.name);
                setCatName(element.name);
                if(!props.oos){
                  localStorage.setItem("mode","subcatoos");
                  props.resetPage();
                  props.getProductOutOfStock({
                    sub_cat_id: sub_name.sub_cat_id,
                    store_id: 1,
                    page: 1,
                    oos: props.oos,
                    brand_id: 0,
                    lang
                  });
                }
                else{
                  localStorage.setItem("mode","subcat");
                  props.resetPage();
                  props.getProductsMappedwithSubCat({
                    sub_cat_id: sub_name.sub_cat_id,
                    store_id: 1,
                    page: 1,
                    oos: props.oos,
                    brand_id: 0,
                    lang
                  });
                }
              }
            });
          });
        }
      });
      if (isSubCat === 0 || isCat === 0){
        history.push(`/not-found`);
      }
    }
    else if (props.category.length > 0 && window.location.pathname.split("/")[1] === "brand"){
      localStorage.setItem("mode","brand");
      props.SearchStoreProductByBrandRef({
        "store_id": 1,
        "brand_ref_id": brandName,
      });
    }
    else if (props.category.length > 0 && checkifcat){
      props.category.map((element, index) => {
        if (element.cat_ref_id === window.location.pathname.split("/")[1]) {
          setCatName(element.name);
          if(props.oos){
            localStorage.setItem("mode","catoos");
            localStorage.setItem("cat_id",element.cat_id);
            props.resetPage();
            props.getProductOutOfStockCat({
              cat_id: element.cat_id,
              store_id: 1,
              page: 1,
              oos: props.oos,
              brand_id: 0
            });
          }
          else{
            localStorage.setItem("mode","cat");
            localStorage.setItem("cat_id",element.cat_id);
            props.resetPage();
            props.getProductsMappedwithCat({
              cat_id: element.cat_id,
              store_id: 1,
              page: 1,
              oos: props.oos,
              brand_id: 0
            });
          }
          if(document.getElementById("oosbtn")){
            document.getElementById("oosbtn").click();
          }
          localStorage.setItem("sub_cat_id",0);
          localStorage.setItem("subcatname", "");
        }
      });
    }
    else{
      return;
    }
    if(localStorage.getItem("user")){
      fetchOrderItems();
    }
  }, [props.category, catrefid, subcatrefid, text, brandName]);

  return (
    <>
      <div>
        {deviceType.desktop ? (
          <>
            <MainContentArea
              style={{ paddingTop: mql.matches ? "9vh" : "0px" }}
            >
              <ContentSection id="productBlock" style={{width: "100%"}}>
                <div ref={targetRef}>
                  <Suspense fallback={<LazyLoader/>}>
                    {props.category.length > 0 && window.location.pathname.split("/").indexOf("search") < 0 &&
                      <Breadcrumb>
                        <BreadcrumbItem href={"/"}>{ t('shop_address_home_label') }</BreadcrumbItem>
                        {breadCrumbmatch()}
                      </Breadcrumb>
                    }
                    <Products
                      type={PAGE_TYPE}
                      deviceType={deviceType}
                      fetchLimit={16}
                      props={props}
                    />
                  </Suspense>
                </div>
              </ContentSection>
            </MainContentArea>
          </>
        ) : (
          <MainContentArea style={{ paddingTop: mqs.matches ? "9vh" : "0px" }}>
            {/* <StoreNav items={storeType} /> */}
            {/* {props.sliders.length > 0 ? (
              <OfferSection>
                <div className="offer-mobile">
                  <Carousel deviceType={deviceType} data={props.sliders} />
                </div>
              </OfferSection>
            ) : null} */}
            <ContentSection style={{ width: "100%" }}>
            <Suspense fallback={<LazyLoader/>}>
              {props.category.length > 0 && window.location.pathname.split("/").indexOf("search") < 0 &&
                <Breadcrumb>
                  <BreadcrumbItem href={"/"}>{ t('shop_address_home_label') }</BreadcrumbItem>
                  {breadCrumbmatch()}
                </Breadcrumb>
              }
              <Products
                type={PAGE_TYPE}
                deviceType={deviceType}
                fetchLimit={16}
                props={props}
              />
              </Suspense>
            </ContentSection>
          </MainContentArea>
        )}
        <CartPopUp deviceType={deviceType} />
        {/* <Modal
          show={showBN && getCookie('furthernotice') !== "seen" &&  (window.location.pathname.indexOf("la-ferme-de-bassac") !== -1  || window.location.pathname.indexOf("bassac-caterer") !== -1 || window.location.pathname.indexOf("butcher-corner") !== -1)}
          onHide={() => {
            setShowBN(false);
            setCookie('furthernotice','seen', 30);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>An Important Notice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>Dear customer,</p>
              <p>We just want to inform you that Bassac Farm and Bassac Caterer (Le Votre) are closed until further notice and their products are not available.</p>
              <p>Sorry for the inconvenience and have a great day.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              title="I understand"
              variant="primary"
              onClick={() => {setShowBN(false);setCookie('furthernotice','seen', 30);}}
            >
              I understand
            </Button>
          </Modal.Footer>
        </Modal> */}
      </div>
    </>
  );
};
SearchedResults.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};
const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    modalData: state.productReducer.modalData,
    products: state.productReducer.products,
    allproducts: state.productReducer.allproducts,
    category: state.productReducer.category,
    sliders: state.globalReducer.sliders,
    loading: state.globalReducer.loading,
    brands: state.productReducer.brands,
    filterBrands: state.productReducer.filterBrands,
    FilteredNameProducts: state.productReducer.FilteredNameProducts,
    filteredProducts: state.productReducer.filteredProducts,
    suggested_subcats: state.productReducer.suggested_subcats,
    all_suggested_subcats: state.productReducer.all_suggested_subcats,
    oos: state.globalReducer.oos,
    page: state.productReducer.page,
    cat_refs: state.productReducer.cat_refs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productCategory: (data) => productCategory(data, dispatch),
    ProductModalData: (data) => ProductModalData(data, dispatch),
    searchSuggestions: (data) => searchSuggestions(data, dispatch),
    searchStoreProduct: (data) => searchStoreProduct(data, dispatch),
    getProductsMappedwithSubCat: (data) => getProductsMappedwithSubCat(data, dispatch),
    getProductsMappedwithCat: (data) => getProductsMappedwithCat(data, dispatch),
    offerSlider: (data) => offerSlider(data, dispatch),
    getProductOutOfStock: (body) => getProductOutOfStock(body, dispatch),
    getProductOutOfStockCat: (body) => getProductOutOfStockCat(body, dispatch),
    getProductBrands: () => getProductBrands(dispatch),
    getSortProductsName: (type, products) =>
      getSortProductsName(type, products, dispatch),
    getSortProductsPrice: (type, products) =>
      getSortProductsPrice(type, products, dispatch),
    getProductFilterBrands: (body, products, allproducts, sub_cat_id, cat_id, oos, text) =>
      getProductFilterBrands(body, products, allproducts, sub_cat_id, cat_id, oos, text, dispatch),
    sortByNameProduct: (type, products) =>
      sortByNameProduct(type, products, dispatch),
    SearchStoreProductByBrandRef: (body) => SearchStoreProductByBrandRef(body, dispatch),
    oosModified: (data) => oosModified(data, dispatch),
    Toggle_Suggestions: (data) => Toggle_Suggestions(data, dispatch),
    changePage: (data) => changePage(data, dispatch),
    resetPage: () => resetPage(dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchedResults);
