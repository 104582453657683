import React, {useMemo} from 'react';
import {
  MainMenu,
  LeftMenuBox,
} from './LeftMenu.style';
import PopOver from '../../../../../components/Popover/Popover';
import {
  FruitsVegetable,
  FacialCare,
  Handbag,
  DressIcon,
  FurnitureIcon,
  BookIcon,
  MedicineIcon,
  Restaurant,
} from '../../../../../components/AllSvgIcon';
import {
  GROCERY_PAGE,
  CLOTHING,
  MAKEUP_PAGE,
  BAGS_PAGE,
  FURNITURE_PAGE,
  BOOK_PAGE,
  MEDICINE_PAGE,
  RESTAURANT_PAGE,
} from '../../../../../constants/navigation';
import { useLocation } from 'react-router-dom';
import Logo from '../../../../../components/Logo/Logo';
import Sidebar from "../../../../../containers/Sidebar/Sidebar";
import {isCategoryPage} from "../../../is-home-page";
import deliExpressLogo from '../../../../../assets/icons/delixpress_logo.svg';
import redAPI from '../../../../../network/redAPI';
import * as actionTypes from "../../../../../store/ActionTypes";
import { useDispatch } from 'react-redux';


const MENU_ITEMS = [
  {
    link: GROCERY_PAGE,
    icon: <FruitsVegetable />,
    label: 'Grocery',
    intlId: 'navGroceryMenu',
  },
  {
    link: MAKEUP_PAGE,
    label: 'Makeup',
    icon: <FacialCare />,
    intlId: 'navMakeupMenu',
  },
  {
    link: BAGS_PAGE,
    label: 'Bags',
    icon: <Handbag />,
    intlId: 'navBagsMenu',
  },
  {
    link: CLOTHING,
    label: 'Clothing',
    icon: <DressIcon />,
    intlId: 'navClothingMenu',
  },
  {
    link: FURNITURE_PAGE,
    label: 'Furniture',
    icon: <FurnitureIcon width='15px' height='13px' />,
    intlId: 'navFurnitureMenu',
  },
  {
    link: BOOK_PAGE,
    label: 'Book',
    icon: <BookIcon width='15px' height='13px' />,
    intlId: 'navBookMenu',
  },
  {
    link: MEDICINE_PAGE,
    label: 'Medicine',
    icon: <MedicineIcon width='15px' height='13px' />,
    intlId: 'navMedicineMenu',
  },
  {
    link: RESTAURANT_PAGE,
    label: 'Foods',
    icon: <Restaurant />,
    intlId: 'navFoodsMenu',
  },
];

const showCat =
    isCategoryPage(window.location.pathname) &&
    window.location.pathname.split("/").length > 1 &&
    window.location.pathname.split("/").length <= 3 && 
    !(window.location.pathname.indexOf("not-found") !== -1)

const useRouter = () => {
  const location = useLocation();
  return useMemo(() => {
    return {
      pathname: location.pathname,
      location
    }
  }, [location])
}
export const LeftMenu = ({ props, logo }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const lang = localStorage.getItem('lang') || 'en';
  const { pathname } = router.pathname;
  const [activeMenu, setActiveMenu] = React.useState(
   "Categories"
  );
  const [category, setCategory] = React.useState([]);

  React.useEffect(() => {
    if(window.location.pathname === "/" && !category.length) {
      redAPI.Category({ get_type: 2, store_id: 1, lang }).then((response) => response.json()).then((data) => {
        if(data.success && data.data.length) {
          setCategory(data.data);
        }
        else {
          setCategory(props.category);
        }
      });
    }
  }, [category])

  return (
    <LeftMenuBox>
      <MainMenu>
        {showCat || props.cartType === 'express'
          ? <PopOver direction='left'
                     className='category'
                     handler={{}}
                     content={ !window.location.pathname.includes('/express/store')
                       ? window.location.pathname === "/"
                         ? <Sidebar type={"grocery"} deviceType={{desktop: 1}} props={props} categoreis={category}/>
                         : <Sidebar type={"grocery"} deviceType={{desktop: 1}} props={props} categoreis={props.category}/>
                       : null
                     }
          />
          : null
        }
      </MainMenu>
      <Logo imageUrl={props.cartType === 'express' ? deliExpressLogo : logo}
            alt={'Shop Logo'}
            cartType={props.cartType}
            onClick={() => setActiveMenu(MENU_ITEMS[0])}
            height={"70px"}
            width={"160px"}
      />
    </LeftMenuBox>
  );
};
