import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@redq/reuse-modal";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { connect } from "react-redux";
import Alert from 'react-bootstrap/Alert'
import { doSupplierLogin } from "../../store/actions/authAction";
import { AuthContext } from "../../contexts/auth/auth.context";
import UploadService from "../../services/FileUploadService";
import * as Constants from "../../constants/Constant";
import "./supplier.css"

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 130px 0 60px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  min-height: 400px;
  @media (min-width: 1100px) {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 60px;
  }
`;

const SupplierLoginScreen = (props) => {

    const { authDispatch } = useContext(AuthContext);
    let supplier = localStorage.getItem("supplier");
     supplier = JSON.parse(supplier);
    
    if(supplier == null) {
      window.location.href = "/supplier/login";
    }
    console.log(supplier)
  
  return (
    <Modal>
      <HelpPageWrapper>
        <HelpPageContainer>

        {/*  */}

        <div class="container emp-profile">
            <form method="post">
                <div class="row">
                    <div class="col-md-4">
                        <div class="profile-img">
                            <img src={supplier.profile_pic} alt="profile_pic"/>
                            {/* <div class="file btn btn-lg btn-primary">
                                Change Photo
                                <input type="file" name="file"/>
                            </div> */}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="profile-head">
                                    <h5>
                                       {supplier.first_name + " " + supplier.last_name}
                                    </h5>
                                    <h6>
                                        {supplier.description}
                                    </h6>
                                    {/* <p class="proile-rating">RANKINGS : <span>8/10</span></p> */}
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">About</a>
                                </li>
                                {/* <li class="nav-item">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Timeline</a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    {/* <div class="col-md-2">
                        <input type="submit" class="profile-edit-btn" name="btnAddMore" value="Edit Profile"/>
                    </div> */}
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="profile-work">
                            {/* <p>WORK LINK</p>
                            <a href="">Website Link</a><br/>
                            <a href="">Bootsnipp Profile</a><br/>
                            <a href="">Bootply Profile</a>
                            <p>SKILLS</p>
                            <a href="">Web Designer</a><br/>
                            <a href="">Web Developer</a><br/>
                            <a href="">WordPress</a><br/>
                            <a href="">WooCommerce</a><br/>
                            <a href="">PHP, .Net</a><br/> */}
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="tab-content profile-tab" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Supplier Id</label>
                                            </div>
                                            <div class="col-md-6">
                                             <p>{supplier.id}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Supplier Name</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{supplier.first_name + " " + supplier.last_name}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Email</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{supplier.email}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Phone</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{supplier.phone}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Description</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{supplier.description}</p>
                                            </div>
                                        </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </form>           
        </div>
        
        </HelpPageContainer>
      </HelpPageWrapper>
    </Modal>
  );
}
const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doSupplierLogin: (body) => doSupplierLogin(body, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupplierLoginScreen);