import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import t from '../../i18n/translate';

const buttonExpress = {
  backgroundColor: '#f01266',
  borderColor: '#f01266',
}

const SimpleMessageDialog = (props) => {
  return (
    <Modal show={props.show}
           onHide={props.onClose}
           centered
           backdrop={props.backdrop}
    >
      {props.showHeader
        ? <Modal.Header closeButton={props.backdrop !== 'static'}><Modal.Title>{props.headerLabel}</Modal.Title></Modal.Header>
        : null
      }

      <Modal.Body className={props.modalBodyClass}>
        <p style={{ marginBottom: 15 }} className={props.className}>{props.message}</p>
        {!props.isConfirmButton
          ? <Button size='sm btn-sm'
                    style={props.cartType === 'express' ? buttonExpress : null}
                    className={`cancelBtn pull-right ${props.cartType}`}
                    variant="success"
                    onClick={props.onClose}
          >{props.closeLabel}</Button>
          : null
        }
      </Modal.Body>

      {props.isConfirmButton
        ? <Modal.Footer>

          <Button size='sm' variant="success" disabled={props.loading} onClick={props.onConfirm} style={props.cartType === 'express' ? buttonExpress : null}>
            {props.loading
              ? <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              : null
            }
            {props.confirmLabel}
          </Button>

          <Button size='sm' className="cancelBtn" disabled={props.loading} variant="info" onClick={props.onClose}>
            {props.loading
              ? <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              : null
            }
            {props.closeLabel}
          </Button>

        </Modal.Footer>
        : null
      }
    </Modal>
  )
};

SimpleMessageDialog.defaultProps = {
  show: false,
  showHeader: false,
  isError: false,
  errorType: null,
  isConfirmButton: false,
  message: t('shop_checkout_error_item_not_available', false),
  headerLabel: t('popup_error_title', false),
  confirmLabel: t('popup_ok_button', false),
  closeLabel: t('button_close_label', false),
  loading: false,
  modalBodyClass: "",
  className: "",
  backdrop: true,
  cartType: 'normal',
}

export default SimpleMessageDialog;
