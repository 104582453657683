import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Modal } from "@redq/reuse-modal";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../contexts/auth/auth.context";
import * as Constant from "../../constants/Constant";
import Spinner from 'react-bootstrap/Spinner';
import UploadService from "../../services/FileUploadService";
import {addBrand,setError,setLoading} from '../../store/actions/dashboardAction'
import { connect } from "react-redux";
import Alert from 'react-bootstrap/Alert';


import "react-datetime/css/react-datetime.css";


const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const AddBrand = (props) => {

    const { authDispatch } = useContext(AuthContext);
    let admin = localStorage.getItem("admin");
    let parsedUser = JSON.parse(admin);
    if(!admin) {
      window.location.href = "/";
    }
    const [name, setItemName] = useState("");
    const [description, setDescription] = useState("")
    const [ image , setImage] = useState(null);
    const [ logo , setLogo] = useState(null);
    const [ logoFile , setLogoFile] = useState(null);
     const [imgfile , setImgFile] = useState(null);
     const [isUplodedImg, setIsUplodedImg] = useState(false);
     const [isUplodedLogo, setIsUplodedLogo] = useState(false);
  

     const handleImgFileChange = e => {
        console.log(e.target.files[0])
        setImgFile(e.target.files[0])
    }

    const handleLogoFileChange = e => {
        console.log(e.target.files[0])
        setLogoFile(e.target.files[0])
    }

    const changeName = ((event) => {
         setItemName(event.target.value)
    });
    const handleDescription = e => setDescription(e.target.value) 
    const addBrandDate = async () => {
        let postData = {           
            name: name,
            description : description,
            logo : logo,
            image : image,
        
        };
        props.addBrand(postData)
    }

    const uploadBrandImage = () =>{
        if(imgfile){
            setIsUplodedImg(true)
            let formData = new FormData();
            formData.append("image", imgfile);
            UploadService.uploadImageFromDashboard(formData)
            .then(response => {
                if(response.data.success) {
                setIsUplodedImg(false)
                console.log(response.data.data[0].url)
               setImage(response.data.data[0].url)
            }
          });
        }
      }

      const uploadBrandlogo = () =>{
        if(logoFile){
            setIsUplodedLogo(true)
            let formData = new FormData();
            formData.append("image", logoFile);
            UploadService.uploadImageFromDashboard(formData)
            .then(response => {
                if(response.data.success) {
                setIsUplodedLogo(false)
                console.log(response.data.data[0].url)
               setLogo(response.data.data[0].url)
            }
          });
        }
      }
   
  return (
    <Modal>
        <HelpPageWrapper>
            <Row>
                <Col sm={11}>
                    <h4>Brand add</h4>
                </Col>
            </Row>
            <Row>
                <Col sm={11}>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Brand Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" value={name} onChange={changeName} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="description" value={description} onChange={handleDescription} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Label>Image</Form.Label>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridFile">
                            <Form.Control type="file"  onChange={handleImgFileChange} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                            {isUplodedImg ? (
                            <Spinner animation="grow" variant="success" />
                              ) :
                            <Button variant="primary" onClick={uploadBrandImage}>
                                Uplode image
                            </Button>
                            }   
                            </Form.Group>
                            </Form.Row>

                            <Form.Label>Logo</Form.Label>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridFile">
                            <Form.Control type="file"  onChange={handleLogoFileChange} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                            {isUplodedLogo ? (
                            <Spinner animation="grow" variant="success" />
                              ) :
                            <Button variant="primary" onClick={uploadBrandlogo}>
                                Uplode Logo
                            </Button>
                            }   
                            </Form.Group>
                            </Form.Row>
                            { !props.success ? (
                            <Alert variant="danger">
                                {props.error_message}
                            </Alert>
                        ) : ("")}
                            {(props.loading || isUplodedImg || isUplodedLogo ) ? (
                            <Spinner animation="grow" variant="success" />
                        ) : (
                            <Button variant="primary" onClick={addBrandDate}>
                                Add
                            </Button>
                        )}
                        
                    </Form>
                </Col> 
            </Row>
        </HelpPageWrapper>
    </Modal>
  );
}

const mapStateToProps = state =>{
    // console.log(state);
     return {
      // brands : state.dashboardReducer.brands
      success : state.dashboardReducer.success,
      loading : state.dashboardReducer.loading,
      error_message : state.dashboardReducer.error_message
     }
    
   }
   
   const mapDispatchToProps = dispatch => {
     return {
       addBrand : data => addBrand(data,dispatch),
       setError : data => setError(data,dispatch),
       setLoading : data => setLoading(data,dispatch)
     }
   }
   export default connect(mapStateToProps,mapDispatchToProps)(AddBrand)
