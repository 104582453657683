import React from "react";
import {
  ProgressBarWrapper,
  ProgressStep,
  ProgressBar,
  StatusTitle,
  StatusBox,
  StatusDetails,
  CheckMarkWrapper,
} from "./ProgressBox.style";
import { CheckMark } from "../AllSvgIcon";
import t from "../../i18n/translate";

const ProgressBox = ({ status, data, cartType }) => {
  /*
   * status = 0 => Order Saved
   * status = 1 => Order Removed
   * status = 2 => Order Placed
   * status = 3 => Order Accepted
   * status = 4 => Order Packed
   * status = 5 => Order Dispatched
   * status = 8 => Order Arrived
   * status = 6 => Order Delivered
   * status = 7 => Order Cancelled
   */
  const excludedPattern = ["Saved", "Removed", "Canceled", "Delivered"];
  const newData = data.filter(item => !excludedPattern.includes(item));

  let newStatus = 2;
  switch (status) {
    case 2:
      newStatus = 0; break;
    case 3:
      newStatus = 1; break;
    case 4:
      newStatus = 2; break;
    case 5:
      newStatus = 3; break;
    case 8:
      newStatus = 4; break;
    case 6:
      newStatus = 5; break;
    default:
      newStatus = status; break;
  }
  return (
    <>
      {newData.map((item, index) => !excludedPattern.includes(index)
        ? <ProgressStep key={index}>
          <ProgressBarWrapper className={newStatus >= index ? "checked" : ""}>
            <StatusBox className={cartType}>
              {newStatus >= index ? (
                <CheckMarkWrapper>
                  <CheckMark />
                </CheckMarkWrapper>
              ) : (
                index + 1
              )}
            </StatusBox>
            <ProgressBar className={cartType} />
          </ProgressBarWrapper>
          <StatusDetails className={cartType}>
            {item
              ? <StatusTitle>{ t(`shop_order_list_status_${ item.replace(' ', '_').toLowerCase() }`) }</StatusTitle>
              : ""
            }
          </StatusDetails>
        </ProgressStep>
        : null
      )}
      <ProgressStep>
        <ProgressBarWrapper>
          <StatusBox className={cartType}>6</StatusBox>
          <ProgressBar className={cartType} />
        </ProgressBarWrapper>
        <StatusDetails>
          <StatusTitle>{ t('shop_order_list_status_delivered') }</StatusTitle>
        </StatusDetails>
      </ProgressStep>
    </>
  );
};

export default ProgressBox;
