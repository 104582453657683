import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';

import { LOCALES } from './locales';
import messages from './messages';

const Provider = ({ children, locale }) => {

  const lang = LOCALES[locale] || navigator.language;

  return (
    <IntlProvider locale="en"
                  textComponent={Fragment}
                  messages={messages[lang]}
    >
      {children}
    </IntlProvider>
  );
};

export default Provider
