import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import styled from "styled-components";
import {Table, Form, ButtonGroup, Spinner, ToggleButton, Button, Modal} from 'react-bootstrap'
import "react-datetime/css/react-datetime.css";
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import {FormattedNumber} from 'react-intl';
import moment from 'moment';
import API from "../../network/dashboardAPI";
import ToastNotification from "../../components/ToastNotification/ToastNotification";
import {initSupplierOrders} from "../../store/actions/dashboardAction";
import DateTimePicker from 'react-datetime';
import jsUtility from "../../services/jsUtility";
import ExportOrders from '../../components/ExportOrders';

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 20px 0 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .btn-secondary.active.completed {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const TableWrapper = styled.div`
  position: relative;

  .item-list .item h5 { display: none; }
  .item-list .item:first-child h5 { display: block; }
  .table-bordered {
    border-color: transparent
  }
  .item-list .item:last-child table {
    margin-bottom: 45px;
  }
  tbody tr:nth-child(1).completed {
    background-color: #3ccb10;
  }
  tbody tr:nth-child(1).pending {
    background-color: #ea9015;
  }
  tbody tr:nth-child(1){
    cursor: pointer;
  }
  .item .form-check {
    height: 50px;
    padding-left: 8px;
  }
  .item .form-check input {
    width: 50px;
    height: 26px;
    cursor: pointer;
  }
  .item-list.hidden,
  tbody:not(.show) tr {
    display: none;
  }
  tbody:not(.show) tr td {
    width: 100%;
    display: block;
  }
  tbody:not(.show) tr:nth-child(1){
    display: block;
  }
  tbody td.noborder {
    border: 0;
  }
  tbody tr.footer td {
    font-weight: bold;
    font-size: 16px;
  }
  tbody tr.footer td:not(.noborder) {
    background-color: #dee2e6;
  }
  .table-bordered tr:not(:first-child) td:nth-child(1),
  .table-bordered tr:not(:first-child) th:nth-child(1) {
    width: 35%
  }
  .table-bordered td:nth-child(2),
  .table-bordered th:nth-child(2){
    width: 15%;
    min-width: 200px;
  }
  .table-bordered td:nth-child(3),
  .table-bordered th:nth-child(3),
  .table-bordered td:nth-child(4),
  .table-bordered th:nth-child(4),
  .table-bordered td:nth-child(5),
  .table-bordered th:nth-child(5),
  .table-bordered td:nth-child(6),
  .table-bordered th:nth-child(6) {
    width: 10%
    min-width: 100px;
  }
  
  form label {
    cursor: pointer;
    text-transform: capitalize;
    min-width: 130px;
  }
  .table-overlay.show {
    display: block;
    background-color: rgb(247 247 247 / 45%);
    position: absolute;
    width: 100%;
    height: calc(100% - 50px);
    left: 0;
    top: 0;
  }
`;

const SupplierOrders = (props) => {

  const user = JSON.parse(localStorage.getItem("supplier"));

  if (!user) {
    window.location.href = "/";
  }

  const perPage = 10;

  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsOrders, setItemsOrders] = useState([]);
  const [editedItem, setEditedItem] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [orderIDs, setOrderIDs] = useState([]);
  const [moveOrderToCompletedModal, setMoveOrderToCompletedModal] = useState(false);
  const [totalOrder, setTotalOrder] = useState(0);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [type, setType] = useState(0);

  //Toast
  const [showToast, setShowToast] = useState(false);
  const closeToast = () => setShowToast(false);

  const supplierOrdersStore = useSelector(state => state.dashboardReducer.supplierOrders);

  const [radioValue, setRadioValue] = useState('pending');
  const buttonFilter = [
    {name: 'Pending', value: 'pending'},
    {name: 'Completed', value: 'completed'},
    {name: 'Show All', value: 'showAll'}
  ];

  useEffect(async () => {
    if (!supplierOrdersStore.length) {
      await getOrderList(user.id, radioValue, perPage);
    }
  }, [supplierOrdersStore]);

  // Get order list non express order
  const getOrderList = async (supplier_id = user.id, status = radioValue, limit = perPage, offset = 0, start_time = null, end_time = null, action = null) => {
    let $params = {supplier_id, status, limit, offset, start_time, end_time};

    setIsLoading(true);
    let supplierOrder = null;

    supplierOrder = await API.getItemsOrdersListsBySupplier($params).then(res => res.json());

    setTotalOrder(supplierOrder.total);
    if (supplierOrder.data.length) {
      let ordersLists = supplierOrder.data;

      if (action !== null && action === 'loadMore') {
        ordersLists = supplierOrdersStore;
        supplierOrder.data.forEach(order => ordersLists.push(order));
      }
      setItemsOrders(jsUtility.groupBy('start_time', ordersLists));
      setCurrentTotal(ordersLists.length);
      props.initSupplierOrders(ordersLists);
    } else {
      if (action === null) {
        setItemsOrders([]);
      }
    }
    clearDateTimeInput(start_time, end_time);
    setIsLoading(false);

  }

  // Update order
  const updateOrder = (params) => {
    API.markOrderAs(params).then(res => res.json())
        .then(res => {
          if (res.success) {
            let newOrderList = null;

            if (Array.isArray(params.order_id))
              newOrderList = supplierOrdersStore.filter(item => !orderIDs.includes(item.order_id));
            else
              newOrderList = supplierOrdersStore.filter(item => item.order_id !== params.order_id);

            setItemsOrders(jsUtility.groupBy('start_time', newOrderList));
            setMoveOrderToCompletedModal(false);
            setOrderIDs([]);
            setShowToast(true);

            document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
            props.initSupplierOrders(newOrderList);
          }
          setShowConfirm(false);
        });
  }

  const groupByType = (list) => {
    let newList = {};
    for(let obj in list){
      newList[obj] = {}
      newList[obj]["Normal"] = [];
      newList[obj]["Express"] = [];

      let normalOrders = list[obj].filter(x => x.type === 1);
      let expressOrders = list[obj].filter(x => x.type === 0);

      newList[obj]["Normal"] = normalOrders;
      newList[obj]["Express"] = expressOrders  ;
    }

    return newList
  }

  // Expand/Collapse the table
  const expandCollapse = (e) => {
    e.preventDefault();

    const $this = e.target;
    const tbody = $this.parentElement.closest("tbody");

    if (tbody.classList.contains("show")) {
      tbody.classList.remove("show");
    } else {
      tbody.classList.add("show");
    }
  };

  // Date range to disable for end date
  const selectableDate = (current) => {
    const dateTime = moment(startDate).subtract(1, 'day');
    return current.isAfter(dateTime);
  };

  // Date/Time picker change
  const handleDatePicker = (e, action) => {
    if (typeof e !== 'string') {
      if (action === 'start_date') {
        setStartDate(e.format('YYYY-MM-DD') + ' 00:00');
        setEndDate(null);

        setTimeout(() => {
          document.querySelector('.end-date-input .form-control').value = '';
        }, 5);
      }
      else
        setEndDate(e.format('YYYY-MM-DD') + ' 23:59');
    }
  };

  // Date/Time filter
  const handleFilter = async () => {
    if (supplierOrdersStore.length) {
      await getOrderList(user.id, radioValue, perPage, 0, startDate, endDate);
      clearDateTimeInput(startDate, endDate);
    } else {
      alert('Oppp!, There is an issue with filter.')
    }
  };

  // Clear Date/Time filter
  const clearFilter = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    await getOrderList(user.id, radioValue);

    clearDateTimeInput();
    setItemsOrders(jsUtility.groupBy('start_time', supplierOrdersStore));
    setStartDate(null);
    setEndDate(null);

    clearDateTimeInput();
  };

  const clearDateTimeInput = (start_time = null, end_time = null) => {
    if (start_time === null && end_time === null) {
      setTimeout(() => {
        document.querySelector('.start-date-input .form-control').value = '';
        document.querySelector('.end-date-input .form-control').value = '';
      }, 5);
    }
  }

  const moveToCompleteCheckbox = (e) => {
    let $this = e.target;

    setOrderIDs(prev => {
      let newValue = [...prev, Number($this.value)];

      if (!$this.checked) {
        newValue = newValue.filter(item => item !== Number($this.value));
      }

      return newValue;
    });
  }

  // Call a modal of move an order to complete
  const btnMarkToCompleted = (e, order) => {
    e.preventDefault();
    e.stopPropagation();

    setEditedItem(order);
    setShowConfirm(true)
  };

  // Handle move an order to complete
  const moveOrderToCompleted = () => {
    try {
      if (Object.keys(editedItem).length) {
        updateOrder({
          "order_id": editedItem.order_id,
          "supplier_id": user.id,
          "status": "completed"
        })
      } else {
        alert("Error, please contact admin");
        setShowConfirm(false);
      }
    } catch (error) {
      console.log("Debug: ", error);
      setShowConfirm(false)
    }
  };

  // Handle move all order selected to complete
  const handleMoveOrderToComplete = () => {
    try {
      if (orderIDs.length) {
        updateOrder({
          "order_id": orderIDs,
          "supplier_id": user.id,
          "status": "completed"
        });
      } else {
        alert("Error, please contact admin");
        setMoveOrderToCompletedModal(false);
      }

    } catch (e) {
      console.log(e);
      setMoveOrderToCompletedModal(false)
    }
  }

  // Handle close move order to complete modal
  const handleCloseConfirmOfMoveAllOrderToComplete = () => setMoveOrderToCompletedModal(false);
  const handleCloseConfirm = () => {
    setEditedItem({});
    setShowConfirm(false);
  };

  // Handle order status change
  const handleRadioChange = async (e) => {
    e.preventDefault();
    setRadioValue(e.currentTarget.value);
    clearDateTimeInput(startDate, endDate);
    await getOrderList(user.id, e.currentTarget.value, perPage, 0, startDate, endDate);
  };

  const handleOrderListLoadMore = () => getOrderList(user.id, radioValue, perPage, currentTotal, startDate, endDate, 'loadMore');

  const renderNormalOrder = (orders, items) => {
    const normalOrder = orders[items].filter(x => x.type === 0);
    return (
        normalOrder.length? normalOrder.map((order, orderIndex) =>
              <div key={orderIndex} className="item d-flex justify-content-center">
                    {order.status === 'pending' && radioValue !== 'showAll' &&
                    <Form.Check type="checkbox"
                                className="d-flex align-items-center"
                                value={order.order_id}
                                onChange={moveToCompleteCheckbox}
                    />
                    }
                    <Table bordered>
                      <tbody className="table-toggle show">
                      <tr className={order.status.toLowerCase()} onClick={(e) => expandCollapse(e)}>
                        <td colSpan={12}>
                          <div className="d-flex justify-content-start">
                            Order ID: <b>{order.order_id}</b>
                            &nbsp;-&nbsp;
                            Time to deliver to customer:&nbsp;

                            <b>
                              {
                                Number(type) === 1 ?
                                    "ASAP" :
                                    <>
                                      {moment(order.start_time).format('ll hh:mm A')}
                                      &nbsp;to&nbsp;
                                      {moment(order.end_time).format('hh:mm A')}
                                    </>
                              }

                            </b>

                            <div style={{marginTop: -3, paddingLeft: 50}}>
                              {order.status === 'pending' && radioValue === 'pending' && !orderIDs.length && !isLoading &&
                              <Button variant="success"
                                      size='sm'
                                      className='mr-3'
                                      onClick={(e) => btnMarkToCompleted(e, order)}
                              >Mark complete</Button>
                              }
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>Name</th>
                        <th>SKU</th>
                        <th>Weight</th>
                        <th style={{width: "10%"}}>Quantity</th>
                        <th style={{width: "10%"}}>Amount</th>
                        <th style={{width: "10%"}}>Total amount</th>
                      </tr>

                      {order.itemsLists && order.itemsLists.map((item, index) =>
                          <tr key={index}>
                            <td>{item.store_item_info.name}</td>
                            <td>{item.store_item_info.product_code}</td>
                            <td>{item.item_price_name || 'N/A'}</td>
                            <td style={{width: "10%"}}>{item.quantity}</td>
                            <td style={{width: "10%"}}>
                              <FormattedNumber value={item.item_MRP}
                                               style="currency"
                                               currency="USD"/>
                            </td>
                            <td style={{width: "10%"}}>
                              <FormattedNumber value={parseFloat(item.item_MRP * item.quantity).toFixed(2)}
                                               style="currency"
                                               currency="USD"/>
                            </td>
                          </tr>
                      )}
                      <tr className="footer">
                        <td className="noborder"></td>
                        <td className="noborder"></td>
                        <td className="noborder"></td>
                        <td className="noborder" style={{width: "10%"}}></td>
                        <td style={{width: "10%"}}>Total</td>
                        <td style={{width: "10%"}}><TotalCost items={order.itemsLists}/></td>
                      </tr>

                      </tbody>
                    </Table>
                  </div>
          ):
              // check to show only first time
              <div style={{paddingLeft: "35px"}}>
                <Table bordered>
                  <tbody className="table-toggle show">
                  <tr className="pending" style={{cursor: "auto"}}>
                    <td colSpan={12}>
                      <div className="d-flex justify-content-start">
                        None
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Name</th>
                    <th>SKU</th>
                    <th>Weight</th>
                    <th style={{width: "10%"}}>Quantity</th>
                    <th style={{width: "10%"}}>Amount</th>
                    <th style={{width: "10%"}}>Total amount</th>
                  </tr>

                  <tr>
                    <td colSpan={12} style={{textAlign: "center"}}>No data</td>
                  </tr>

                  <tr className="footer">
                    <td className="noborder" colSpan={3}></td>
                    <td className="noborder" style={{width: "10%"}}></td>
                    <td style={{width: "10%"}}>Total</td>
                    <td style={{width: "10%"}}>$0.00</td>
                  </tr>

                  </tbody>
                </Table>
              </div>
    );
  }

  const renderExpressOrder = (orders, items) => {
    const expressOrders = orders[items].filter(x => x.type === 1);
    return (
        expressOrders.length? expressOrders.map((order, orderIndex) =>
              <div key={orderIndex} className="item d-flex justify-content-center">
                    {order.status === 'pending' && radioValue !== 'showAll' &&
                    <Form.Check type="checkbox"
                                className="d-flex align-items-center"
                                value={order.order_id}
                                onChange={moveToCompleteCheckbox}
                    />
                    }
                    <Table bordered>
                      <tbody className="table-toggle show">
                      <tr style={{background: "#f01266"}} onClick={(e) => expandCollapse(e)}>
                        <td colSpan={12}>
                          <div className="d-flex justify-content-start">
                            Order ID: <b>{order.order_id}</b>
                            &nbsp;-&nbsp;
                            Time to deliver to customer:&nbsp;

                            <b>
                              {
                                Number(type) === 1 ?
                                    "ASAP" :
                                    <>
                                      {moment(order.start_time).format('ll hh:mm A')}
                                      &nbsp;to&nbsp;
                                      {moment(order.end_time).format('hh:mm A')}
                                    </>
                              }

                            </b>

                            <div style={{marginTop: -3, paddingLeft: 50}}>
                              {order.status === 'pending' && radioValue === 'pending' && !orderIDs.length && !isLoading &&
                              <Button variant="success"
                                      size='sm'
                                      className='mr-3'
                                      onClick={(e) => btnMarkToCompleted(e, order)}
                              >Mark complete</Button>
                              }
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>Name</th>
                        <th>SKU</th>
                        <th>Weight</th>
                        <th style={{width: "10%"}}>Quantity</th>
                        <th style={{width: "10%"}}>Amount</th>
                        <th style={{width: "10%"}}>Total amount</th>
                      </tr>

                      {order.itemsLists && order.itemsLists.map((item, index) =>
                          <tr key={index}>
                            <td>{item.store_item_info.name}</td>
                            <td>{item.store_item_info.product_code}</td>
                            <td>{item.item_price_name || 'N/A'}</td>
                            <td style={{width: "10%"}}>{item.quantity}</td>
                            <td style={{width: "10%"}}>
                              <FormattedNumber value={item.item_MRP}
                                               style="currency"
                                               currency="USD"/>
                            </td>
                            <td style={{width: "10%"}}>
                              <FormattedNumber value={parseFloat(item.item_MRP * item.quantity).toFixed(2)}
                                               style="currency"
                                               currency="USD"/>
                            </td>
                          </tr>
                      )}
                      <tr className="footer">
                        <td className="noborder"></td>
                        <td className="noborder"></td>
                        <td className="noborder"></td>
                        <td className="noborder" style={{width: "10%"}}></td>
                        <td style={{width: "10%"}}>Total</td>
                        <td style={{width: "10%"}}><TotalCost items={order.itemsLists}/></td>
                      </tr>

                      </tbody>
                    </Table>
                  </div>
          ):
            <div style={radioValue === 'pending' ? {paddingLeft: "38px"} : {}}>
              <Table bordered>
                <tbody className="table-toggle show">
                <tr className="pending" style={{cursor: "auto", background: "#f01266"}}>
                  <td colSpan={12}>
                    <div className="d-flex justify-content-start">
                      None
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>Name</th>
                  <th>SKU</th>
                  <th>Weight</th>
                  <th style={{width: "10%"}}>Quantity</th>
                  <th style={{width: "10%"}}>Amount</th>
                  <th style={{width: "10%"}}>Total amount</th>
                </tr>

                <tr>
                  <td colSpan={12} style={{textAlign: "center"}}>No data</td>
                </tr>

                <tr className="footer">
                  <td className="noborder" colSpan={3}></td>
                  <td className="noborder" style={{width: "10%"}}></td>
                  <td style={{width: "10%"}}>Total</td>
                  <td style={{width: "10%"}}>$0.00</td>
                </tr>

                </tbody>
              </Table>
            </div>
    )
  }

  return (
      <>
        <div className="d-flex justify-content-between">
          <div className="mt-5 mr-3 d-flex">
            <div className="ml-2">
              <DateTimePicker id="datetimepicker"
                              dateFormat="ll"
                              closeOnSelect={true}
                              className='start-date-input'
                              inputProps={{placeholder: 'Start Date'}}
                              timeFormat={false}
                              onChange={(e) => handleDatePicker(e, 'start_date')}/>
            </div>
            <div className="ml-2">
              <DateTimePicker id="datetimepicker"
                              dateFormat="ll"
                              isValidDate={selectableDate}
                              className='end-date-input'
                              closeOnSelect={true}
                              inputProps={{placeholder: 'End Date'}}
                              timeFormat={false}
                              onChange={(e) => handleDatePicker(e, 'end_date')}/>
            </div>
            <div>
              <Button variant="primary"
                      className='mr-3 ml-3'
                      onClick={handleFilter}
              ><PollOutlinedIcon style={{fontSize: 21}}/> Filter</Button>
              <Button variant="info"
                      className='mr-3'
                      onClick={clearFilter}
              >Clear</Button>
            </div>
          </div>


          <div className="d-flex">
            <div className="mt-5 mr-3">
              <ExportOrders label="Export"
                            status={radioValue}
                            start_time={startDate}
                            end_time={endDate}
                            supplier_id={user.id}
                            isNotFound={false}
              />
            </div>
            <ButtonGroup toggle className="mb-3 mt-5">
              {buttonFilter.map((btn, idx) => (
                  <ToggleButton key={idx}
                                type="radio"
                                variant="info"
                                name="radio"
                                value={btn.value}
                                checked={radioValue === btn.value}
                                onChange={(e) => handleRadioChange(e)}
                  >{btn.name}</ToggleButton>
              ))}
            </ButtonGroup>
          </div>
        </div>

        <HelpPageWrapper>
          <TableWrapper>
            {isLoading && !Object.keys(itemsOrders).length
                ? <table style={{width: '100%'}}>
                  <tbody>
                  <tr>
                    <td colSpan={12} className="text-center"><Spinner animation="grow"/></td>
                  </tr>
                  </tbody>
                </table>
                : null
            }
            {!isLoading && !Object.keys(itemsOrders).length
                ? <table style={{width: '100%'}}>
                  <tbody>
                  <tr>
                    <td colSpan={12} style={{border: '1px solid #e7e7e7', padding: 10}} className="text-center">No
                      data
                    </td>
                  </tr>
                  </tbody>
                </table>
                : null
            }

            {Object.keys(itemsOrders) && Object.keys(itemsOrders).map((items, index) =>
                <div key={index} className="item-list">

                  {/*
                    ================================== Normal Order ===============================
                  */}
                  <h5 className="mb-2">{moment(items).format('MMM DD')} <span className="h6 p-3">Normal</span></h5>
                  {renderNormalOrder(itemsOrders, items)}

                  {/*
                    ============================ Express Orders ===============================================
                  */}
                  <h5 className="mb-2">{moment(items).format('MMM DD')} <span className="h6 text-danger p-3">Express</span></h5>
                  {renderExpressOrder(itemsOrders, items)}

                </div>
            )}

            {(totalOrder && currentTotal < totalOrder)
                ? <div className="text-center mt-3">
                  <Button variant="primary"
                          isloading={`${isLoading}`}
                          onClick={handleOrderListLoadMore}
                  >
                    {isLoading
                        ? 'Loading...'
                        : 'Load more'
                    }
                  </Button>
                </div>
                : null
            }
            <div className={isLoading ? 'table-overlay show' : 'table-overlay'}>

            </div>
          </TableWrapper>

          <Modal show={showConfirm}
                 onHide={handleCloseConfirm}
                 aria-labelledby="contained-modal-title-vcenter"
                 centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Order ID: {editedItem.order_id}</Modal.Title>
            </Modal.Header>
            <Modal.Body key="modalBody">
              Are you sure you want to mark this order as completed.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={moveOrderToCompleted}>
                Yes
              </Button>
              <Button variant="secondary" onClick={handleCloseConfirm}>
                No
              </Button>
            </Modal.Footer>
          </Modal>
          {orderIDs.length
              ? <Button variant="success"
                        size='md'
                        style={{
                          position: 'fixed',
                          bottom: '3.5vh',
                          right: '11vh',
                        }}
                        onClick={() => setMoveOrderToCompletedModal(true)}
              >Mark all as complete</Button>
              : null
          }
          <Modal show={moveOrderToCompletedModal}
                 onHide={handleCloseConfirmOfMoveAllOrderToComplete}
                 aria-labelledby="contained-modal-title-vcenter"
                 centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Change order status to completed</Modal.Title>
            </Modal.Header>
            <Modal.Body key="modalBody">
              Mark as complete the <b className="text-success">{orderIDs.length}</b> selected order ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleMoveOrderToComplete}>
                Yes
              </Button>
              <Button variant="secondary" onClick={handleCloseConfirmOfMoveAllOrderToComplete}>
                No
              </Button>
            </Modal.Footer>
          </Modal>

          <ToastNotification showToast={showToast} onClose={closeToast}/>
        </HelpPageWrapper>
      </>
  )
};

const TotalCost = (props) => {
  let eachTotalCostOfItem = 0;

  if (props.items.length) {
    props.items.map(item => {
      eachTotalCostOfItem = eachTotalCostOfItem + (item.item_MRP * item.quantity);
    });
  }

  return (
      <span>{'$' + parseFloat(eachTotalCostOfItem).toFixed(2)}</span>
  );
};

TotalCost.defaultProps = {
  items: []
};

const mapStateToProps = state => {
  return {
    supplierOrders: state.dashboardReducer.supplierOrders
  }
};
const mapDispatchToProps = dispatch => {
  return {
    initSupplierOrders: data => initSupplierOrders(data, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierOrders)
