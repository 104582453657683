import React from 'react';
import axios from 'axios';
import * as Constant from '../constants/Constant';
import API from "../network/redAPI";
import t from '../i18n/translate';

const lang = localStorage.getItem('lang') || 'en';

const getExpressStatus = async (storeId = 1) => {
  const userLocalStorage = localStorage.getItem('user');
  let storeAPI = Constant.baseUrl+'v1/web/express/get/status/'+storeId;

  if(userLocalStorage) {
    let user = JSON.parse(userLocalStorage);
    storeAPI = storeAPI+'?userId='+user.cust_id;
  }

  return await axios.get(storeAPI);
};

const fetchCart = async (orderId, customerId, cartType) => {
  return new Promise(async (resolve, reject) => {
    const storeAPI = Constant.baseUrl+'api/cart/fetchCart';
    const response = await axios.post(storeAPI, {
      order_id: orderId,
      cust_id: customerId,
      type: cartType
    });

    if(response.status === 200) {
      resolve(response.data);
    } else {
      resolve([]);
    }
  })
};

const expressStores = async () => {
  return new Promise(async (resolve, reject) => {
    const storeAPI = Constant.baseUrl+'v1/express/shop/feature/0/100?_locale='+lang;
    const token = localStorage.getItem('token')
    const response = await axios.get(storeAPI, { headers: { Authorization: token } });
    if(response.status === 200 && response.data.sections.length) {
      resolve(response.data.sections)
    }
    else {
      reject()
    }
  })
};

const getExpressStore = async (id) => {
  return new Promise(async (resolve, reject) => {
    const storeAPI = Constant.baseUrl+'v1/web/supplier/'+id;
    const token = localStorage.getItem('token')
    const response = await axios.get(storeAPI, { headers: { Authorization: token } });
    if(response.status === 200 && response.data.data !== null && Object.keys(response.data.data).length) {
      resolve(response.data.data)
    }
    else {
      resolve(response.data);
    }
  })
};

const storeItems = async (
  supplierId = [],
  brandIdList = [],
  categoryID = [],
  subCategoryID = [],
  outOfStock = 1,
  search = '',
  sort = '',
  next = 0,
  limit = 50
) => {
  return new Promise(async (resolve, reject) => {
    let $params = `search=${search}`;
    if(supplierId.length) {
      $params += `&supplierIdList=[${supplierId.join()}]`;
    }
    if(brandIdList.length) {
      $params += `&brandIdList=[${brandIdList.join()}]`;
    }
    if(categoryID.length && !categoryID.includes(null)) {
      if(typeof categoryID[0] === 'string') {
        $params += `&categoryIdList=["EXTRA"]`;
      }
      else {
        $params += `&categoryIdList=[${categoryID.join()}]`;
      }
    }
    if(subCategoryID.length) {
      $params += `&subCategoryIdList=[${subCategoryID.join()}]`;
    }
    if(outOfStock !== 0) {
      $params += `&outOfStock=${outOfStock}`;
    }
    if(sort !== '' && typeof sort !== 'function') {
      $params += `&sort=${sort}`;
    }

    const response = await axios.get(`${Constant.baseUrl}web/v1/menuitem/${next}/${limit}?_locale=${lang}&${$params}`);
    if(response.status === 200) {
      resolve(response.data);
    }
    else {
      reject()
    }
  })
};

const getExtraStoreItems = async (currentStoreID = 1, next = 0, limit = 50) => {
  return new Promise(async (resolve, reject) => {
    const response = await axios.get(`${Constant.baseUrl}v1/web/express/get/supplier/extra/item/${currentStoreID}/${next}/${limit}?_locale=${lang}`);
    if(response.status === 200) {
      resolve(response.data.data);
    }
    else {
      reject()
    }
  })
};

const getSubCategoryAndCategoryOfStore = async (storeId = null) => {
  return new Promise(async (resolve, reject) => {
    const storeAPI = Constant.baseUrl+`v1/express/get/category/subcategory/supplier/${storeId}?_locale=${lang}`;
    if(storeId !== null) {
      const response = await axios.get(storeAPI);

      if(response.status === 200 && response.data.length) {
        const categoryList = response.data;
        const newList = categoryList.map(item => {
          let result = {
            cat_id      : item._id,
            name        : item.label,
            image       : item.logo,
            cat_ref_id  : item.catRefId,
            banner_image: item.logo,
            "map_store_category": {
              "id": 24,
              "cat_id": 25,
              "store_id": 1,
              "order": null,
              "is_available": 1,
              "store_specific_image": null
            }
          }

          if(typeof item.subCategories !== 'undefined') {
            result.map_categories_sub_cats = item.subCategories.map(subCategory => {
              return {
                "image": null,
                "list_sub_categories": [
                  {
                    "name": subCategory.label,
                    "image": null,
                    "sub_cat_id": subCategory._id,
                    "order": null,
                    "sub_cat_ref_id": subCategory.label.replace(' ', '-').toLowerCase(),
                    "banner_image": null
                  }
                ]
              }
            });
          }
          else {
            result.map_categories_sub_cats = [];
          }

          return result;
        })
        resolve(newList);
      } else {
        reject();
      }
    }
  })
};

const addItemToCart = async (item, cartType = 'normal', quantity = 1, confirm = 0) => {
  const user = localStorage.getItem("user");
  const parsedUser = JSON.parse(user);

  const item_details = item;
  item_details["quantity"] = 1;
  item_details["item_price_id"] = item_details.store_items_price_types[0].id;

  let body = {
    item_details,
    store_id: 1,
    cust_id: parsedUser.cust_id,
    order_id:
      localStorage.getItem("order_id") !== null
        ? localStorage.getItem("order_id")
        : 0,
  };
  if(cartType === 'express') {
    body = {
      store_id: 1,
      item_id: item["item_id"],
      type: 1,
      quantity,
      confirm
    };
  }

  const itemResp = await API.AddItemToCart(body);

  if(itemResp.status === 200) {
    return itemResp;
  }
  else {
    return {
      error: "multiSupplier",
      status: 400,
      message: t('shop_add_item_to_cart_different_shop_error'),
      success: false
    }
  }
};

const checkoutValidation = (body) => {
  return new Promise(async (resolve, reject) => {
    API.PlaceOrder(body).then(response => response.json()).then(res => {
      resolve(res);
    })
  })
}

const orderLocked = ( cartType = 'normal') => {
  return new Promise(async (resolve, reject) => {
    let api = Constant.baseUrl+'v1/web/basket/open';
    const token = localStorage.getItem('token');

    if(cartType === 'express')
      api = api+'?type=1';
    else
      api = api+'?type=0';

    const orderLockResp =  await axios.put(api, {}, { headers: { Authorization: token } });
    resolve(orderLockResp);
  })
}

const getShopSetting = () => {
  return new Promise(async (resolve, reject) => {
    let api = Constant.baseUrl+'v1/shopsetting';
    const userLocalStorage = localStorage.getItem('user');

    if(userLocalStorage) {
      let user = JSON.parse(userLocalStorage);
      api = api+'?userId='+user.cust_id;
    }

    const result = await axios.get(api);
    resolve(result);
  })
}

const searchItems = async (
  supplierId = [],
  brandIdList = [],
  categoryID = [],
  subCategoryID = [],
  outOfStock = 1,
  search = '',
  sort = '',
  next = 0,
  limit = 50
) => {
  return new Promise(async (resolve, reject) => {
    let $params = `search=${search}`;
    if(supplierId.length) {
      $params += `&supplierIdList=[${supplierId.join()}]`;
    }
    if(brandIdList.length) {
      $params += `&brandIdList=[${brandIdList.join()}]`;
    }
    if(categoryID.length && !categoryID.includes(null)) {
      if(typeof categoryID[0] === 'string') {
        $params += `&categoryIdList=["EXTRA"]`;
      }
      else {
        $params += `&categoryIdList=[${categoryID.join()}]`;
      }
    }
    if(subCategoryID.length) {
      $params += `&subCategoryIdList=[${subCategoryID.join()}]`;
    }
    if(outOfStock !== 0) {
      $params += `&outOfStock=${outOfStock}`;
    }
    if(sort !== '' && typeof sort !== 'function') {
      $params += `&sort=${sort}`;
    }

    const response = await axios.get(`${Constant.baseUrl}web/v1/menuitem/search/1/${next}/${limit}?_locale=${lang}&${$params}`);
    if(response.status === 200) {
      resolve(response.data);
    }
    else {
      reject()
    }
  })
};

export default {
  getExpressStatus,
  fetchCart,
  expressStores,
  getExpressStore,
  storeItems,
  getSubCategoryAndCategoryOfStore,
  addItemToCart,
  getExtraStoreItems,
  checkoutValidation,
  orderLocked,
  getShopSetting,
  searchItems,
};
