import React, { useEffect } from "react";
import Order from "../containers/Profile/Order/CurrentOrder";
import {
  PageWrapper,
  SidebarSection,
} from "../containers/Profile/Profile.style";
import { Modal } from "@redq/reuse-modal";
import { useDeviceType } from "../helper/useDeviceType";
import { connect } from "react-redux";
import {
  currentOrders,
  fetchParticularOrder,
} from "../store/actions/userAction";
const CurrentOrderPage = (props, { userAgent }) => {
  const deviceType = useDeviceType(userAgent);
  return (
    <>
      <Modal>
        <PageWrapper>
          <Order
            deviceType={deviceType}
            props={props}
            orders={props.current_orders}
            orderDetails={props.fetch_particular_order}
          />
        </PageWrapper>
      </Modal>
    </>
  );
};
CurrentOrderPage.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};
const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    modalData: state.productReducer.modalData,
    user_address: state.userReducer.address,
    current_orders: state.userReducer.current_orders,
    fetch_particular_order: state.userReducer.fetch_particular_order,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    currentOrders: (data) => currentOrders(data, dispatch),
    fetchParticularOrder: (data) => fetchParticularOrder(data, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CurrentOrderPage);
