import React from "react";
import styled from "styled-components";
import curseArrow from '../../assets/icons/curse-arrow.svg';

const StyleSheet = styled.div`
  .box-container {
    width: 100%;
    max-width: 865px;
    margin: 0 auto;
    position: relative;
    flex-wrap: nowrap;
    gap: 5px;
    overflow: hidden;
    padding-top: 5px;
  }
  .modal-header {
    position: relative;
    border-bottom: none;
    margin-bottom: 20px;
    z-index: 1;
  }
  .modalHeader {
    flex: 1;
  }
  .modal-header .close {
    position: absolute;
    top: 50%;
    right: 1em;
    margin-top: -28px;
  }
  .modalFooter {
    border-top: none;
  }
  .title {
    color: green;
    font-family: sans-serif;
    font-size: 32px;
  }
  .subTitle {
    color: black;
    font-size: 16px;
  }
  .item {
    position: relative;
    transition: all 0.5s;
    left: 0;
  }
  .dateWrapper {
    display: block;
    width: 100%;
    max-width: 140px;
    text-align: center;
    margin-bottom: 10px;
  }
  .timingWrapper {
    display: flex;
  }
  .day {
    display: block;
    color: green;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
  }
  .dayMonth {
    display: block;
    color: green;
    font-size: 14px;
    font-weight: 600;
  }
  .buttonIntro {
    position: absolute;
    top: 0;
    right: 0;
    
    button {
      border: none;
    }
  }
  .button {
    display: block;
    width: 140px;
    background-color: #ace0a5;
    color: #000;
    font-size: 12px;
    padding: 5px 15px;
    margin-bottom: 5px;
    height: auto;
    font-weight: 400;
    border-radius: 12px;
    
    &.active {
      background-color: #389244 !important;
      border-color: #389244 !important;
    }
  }
  .disabledButton {
    display: block;
    width: 140px;
    background-color: #979696;
    color: #000;
    font-size: 12px;
    padding: 5px 15px;
    margin-bottom: 5px;
    height: auto;
    font-weight: 400;
    border-radius: 12px;
  }
  .nextPrevButton {
    display: block;
    
    a {
      display: flex;
      color: green;
      position: relative;
      flex-direction: column;
      
      &.btnNext {
        display: none;
        align-items: end;
        position: absolute;
        bottom: -45px;
        right: 3em;
        
        &.show { display:block; }
      }
      &.btnPrev {
        display: none;
        align-items: start;
        position: absolute;
        bottom: -45px;
        left: 3em;
        
        &.show { display:block; }
      }
    }
    .icon {
      background-image: url(${curseArrow});
      width: 24px;
      height: 24px;
      display: block;
      background-repeat: no-repeat;
      margin-right: -15px;
      
      &.prevIcon {
        transform: rotate(0deg) scaleX(-1);
        margin-left: -15px;
      }
      &.nextIcon {
        margin-right: -15px;
      }
    }
  }
  .tableRow {
    display: flex;
    justify-content: space-around;
  }
  .tableColumn button {
    border: none;
  }
  
  @media (max-width: 1200px) {
    .dateWrapper,
    .disabledButton,
    .button {
      width: 123px;
    }
    .buttonIntro {
      right: 3.5em;
    }
  }
  
  @media (max-width: 991px) {
    .box-container {
      flex-wrap: wrap;
    }
    .nextPrevButton {
      a.btnNext,
      a.btnNext.show,
      a.btnPrev,
      a.btnPrev.show { display: none; }
    }
    .buttonIntro {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      
      position: unset;
    }
    .modal-header {
      margin-bottom: 35px;
    }
    .modal-header .close {
      position: absolute;
      top: 0.4em;
      right: 15px;
      margin-top: 0;
    }
    .modalFooter {
      justify-content: center;
    }
  }
`;

export default StyleSheet;
