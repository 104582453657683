import React, { useState, useEffect } from "react";
import moment from "moment";

// ------------ Components ------------
import { Modal as CustomModal } from "@redq/reuse-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Loader from "../../components/Loader/Loader";
import { green } from "@material-ui/core/colors";
import { Switch } from "@material-ui/core";
import {
  Table,
  Modal,
  Form,
  Pagination,
  InputGroup,
  ListGroup,
  Toast,
  Spinner
} from "react-bootstrap";
import { Email, RemoveRedEye } from "@material-ui/icons";

// -------------- APIs ---------------
import API from "../../network/dashboardAPI";

// -------------- Style --------------
import styled from "styled-components";
import "react-datetime/css/react-datetime.css";
import { set } from "lodash";

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const labelStyle = {
  lineHeight: "2.5rem",
  width: "14rem"
};

let typingTimer = null;

const BasketList = (props) => {
  // Loading states
    const [isRequesting, setIsRequesting] = useState(false);
    const [isSendRequesting, setIsSendRequesting] = useState(false);
    const [isRequestingOrderDetail, setIsRequestingOrderDetail] = useState(false);

  // --------------- States ------------------
  const [basketList, setBasketList] = useState([]);
  const [countBasket, setCountBasket] = useState(0);
  const [validated, setValidated] = useState(false);
  const [basketToSend, setBasketToSend] = useState({});
  const [selectedBasketType, setSelectedBasketType] = useState("");

  // form props
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [formError, setFormError] = useState({
    title: false,
    message: false,
  });

  // pagination props
  const [next, setNext] = useState(0);
  const [limit, setLimit] = useState(10);
  const [prev, setPrev] = useState(0);
  const [pager, setPager] = useState(1);

  // toast states
  const [toastBgColor, setToastBgColor] = useState('bg-success');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Delete successful");

    // Modal states
    const [isShowBasketDetailModal, setIsShowBasketDetailModal] = useState(false);
    const [basketDataToView, setBasketDataToView] = useState({});

    // Form state
    const [orderId, setOrderId] = useState("");
    const [status, setState] = useState("");
    const [placeAt, setPlaceAt] = useState("");
    const [subTotal, setSubTotal] = useState("");
    const [deliveryFee, setDeliveryFee] = useState("");
    const [productOrderList, setProductOrderList] = useState([]);

    // --------------- Methods -----------------
  const getBasketList = async ($next) => {
    setIsRequesting(true);
    let params = {
      next: $next, 
      limit, 
      statusList: "[0]"
    }

    if(selectedBasketType === "0" || selectedBasketType === "1"){
      params.type = selectedBasketType;
    }
    await API.getOrderListPagination(params)
      .then((response) => response.json())
      .then((response) => {
        setIsRequesting(false);
        if (response.success) {
          setBasketList(response.data.list);
          setCountBasket(response.data.total);

          if (response.data.next) {
            setNext(response.data.next);
          } else if (response.data.list.length) {
              setNext($next > 0?next + response.data.list.length: response.data.list.length);
            // setNext(next + response.data.list.length);
          } else {
            setNext(0);
          }
        }
      });
  };

  const getOrderDetailByOrderId = (orderId) => {
    setIsRequestingOrderDetail(true);

    let body = {
        order_id: orderId
    }
     API.getOrderDetailByOrderId(body)
        .then((res) => res.json())
        .then((res) => {
          if(res.data){
            setBasketDataToView(res.data);
          }
          setIsRequestingOrderDetail(false);
        })
  }

  const getFormatDateForDisplay = (date) => {
    return moment(date).format("DD/MMM/YYYY - h:mm a");
  };

  const getFormatCurrencyForDisplay = (number) => {
    return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "USD"
    }).format(number);
}

  const updatePaginationProps = (type) => {
    let tmpPager = pager + 1;
    if (type === "PREV") {
      tmpPager = pager - 1;
    }

    const tmpNext = limit * tmpPager;
    const tmpPrev = limit * tmpPager - limit;

    setPager(tmpPager);
    setPrev(tmpPrev);

    return { pager: tmpPager, next: tmpNext, prev: tmpPrev };
  };

  const resetForm = () => {
    setTitle("");
    setMessage("");
  };

  const resetFormError = () => {
    setFormError({
        title: false,
        message: false
    })
  }

  const storeItem = (order) => {
    return order.store_item_info ? order.store_item_info : order.store_item_infos[0];
  };

    const orderPayment = (details) => {
        return details.order_payment_detail ? details.order_payment_detail : details.order_payment_details[0];
    };

    const getStatus = status => {
        if(status == 2) return "New";
        else if ( status == 3) return "Accepted";
        else if ( status == 4 ) return "Packed";
        else if ( status == 5 ) return "Dispatched";
        else if ( status == 6 ) return "Delivered";
        else if ( status == 7 ) return "Cancelled";
        else if ( status == 8 ) return "Arrived";
        else if ( status == 0 ) return "Saved";
        else if ( status == 1 ) return "Removed";
    }

    const resetPagination = () => {
        // reset pagination
        setPager(1);
        setPrev(0);
    }

  // --------------- Compute data -----------
  useEffect(() => {
    getBasketList(0);
  }, [selectedBasketType]);

  // --------------- Event change ----------

  const handleChangeBasketTypeFilter = (e) => {
    // reset pagination
    resetPagination();

    setSelectedBasketType(e.target.value);
  }

  const handleSendToClient = (basket) => {
    setBasketToSend(basket);
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
    resetForm();
    resetFormError();
  };

  const handlePaginNext = () => {
    const { next, prev } = updatePaginationProps("NEXT");
    getBasketList(prev);
  };

  const handlePaginPrev = () => {
    const { pager, next, prev } = updatePaginationProps("PREV");
    getBasketList(prev);
  };

  const handleSendCustomPushToUser = async () => {
    setIsSendRequesting(true);

    const body = {
        title,
        message
    }

    const clientId = basketToSend.cust_id;
    await API.sendCustomPushToClient(clientId, body)
      .then((response) => response.json())
      .then((response) => {
        setIsSendRequesting(false);
        if (response.success) {
            handleShowToast("SUCCESS");
          handleCloseModal(); 
        }else{
            handleShowToast("ERROR");
        }
      });
  }

    // toast event
    const handleShowToast = (type) => {
        setShowToast(true);

        if(type === "SUCCESS"){
            setToastBgColor("bg-success");
            setToastMessage("Operation successful.");
        }else if(type === "ERROR"){
            setToastBgColor("bg-danger");
            setToastMessage("Operation error.");
        }
    }

  //  Modal event
    const handleViewBasketDetail = (basket) => {
      setIsShowBasketDetailModal(true)
       getOrderDetailByOrderId(basket.order_id);
    }

    const handelCloseBasketDetailModal = () => {
      setBasketDataToView({})
      setIsShowBasketDetailModal(false);
    }

  // ------------------ Custom components ----------------
  // Pagination
  let pageItems = [
    <Pagination.Item disabled={next <= limit} key={0} onClick={handlePaginPrev}>
      Prev
    </Pagination.Item>,
    <Form.Group key={1} controlId="formGridPagination">
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>{`${next} / ${countBasket}`}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
    </Form.Group>,
    <Pagination.Item
      disabled={next === countBasket}
      key={2}
      onClick={handlePaginNext}
    >
      Next
    </Pagination.Item>,
  ];
  const PaginationBasic = () => (
    <div className="d-flex justify-content-center">
      <Pagination>{pageItems}</Pagination>
    </div>
  );

  return (
    <CustomModal>
      <HelpPageWrapper>
        <Row className="mb-3">
          <Col sm={8}>
            <h3 className="mb-3">Basket Lists</h3>
          </Col>
          <Col sm={4} className="d-flex justify-content-end">
              <span style={labelStyle}>Basket type filter: </span>&nbsp;&nbsp;
              <Form.Control as="select" disabled={isRequesting} onChange={(e) => handleChangeBasketTypeFilter(e)} value={selectedBasketType}>
                <option value={""}>All Type</option>
                <option value={"0"}>Normal</option>
                <option value={"1"}>Express</option>
              </Form.Control>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {isRequesting ? (
              <div style={{ position: "fixed", left: "55%", top: "35%" }}>
                {" "}
                <Loader color="#389244" h="100" />{" "}
              </div>
            ) : (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {!basketList.length ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No data
                      </td>
                    </tr>
                  ) : (
                    basketList.map((basket, index) => (
                      <tr key={index}>
                        <td>{basket && basket.customer_detail ? basket.customer_detail.first_name: ""}</td>
                        <td>{basket && basket.customer_detail ? basket.customer_detail.last_name: ""}</td>
                        <td>{basket && basket.customer_detail ? basket.customer_detail.email: ""}</td>
                        <td>{basket && basket.type && basket.type === 1 ? "Express": basket.type === 0 ? "Normal": ""}</td>
                        <td>{basket && basket.order_payment_detail ? getFormatCurrencyForDisplay(basket.order_payment_detail.to_pay): getFormatCurrencyForDisplay(0)}</td>
                        <td>{getFormatDateForDisplay(basket.placeAt)}</td>
                        <td className="text-center">
                          <RemoveRedEye style={{cursor: "pointer"}} className="text-primary"
                              onClick={() => handleViewBasketDetail(basket)}
                          />&nbsp;&nbsp;
                          <Email
                            style={{ color: green[500], cursor: "pointer" }}
                            onClick={() => handleSendToClient(basket)}
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            )}

            <Modal
              show={isShowModal}
              animation={false}
              onHide={handleCloseModal}
              aria-labelledby="contained-modal-title-vcenter"
              size="xs"
              centered
            >
              <Form noValidate validated={validated} autoComplete="false">
                <Modal.Header closeButton>
                  <Modal.Title>Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body key="modalBody">
                  <Form.Group controlId="formTitle">
                    <Form.Label>Title</Form.Label>
                    <InputGroup hasvalidation="true">
                      <Form.Control
                        type="text"
                        value={title}
                        isInvalid={formError.title}
                        placeholder="Title"
                        required
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId="formMessage">
                    <Form.Label>Message</Form.Label>
                    <InputGroup hasvalidation="true">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={message}
                        isInvalid={formError.message}
                        placeholder="Message"
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button disabled={isSendRequesting} variant="primary" onClick={handleSendCustomPushToUser}>
                      {
                          isSendRequesting?
                              <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                              />:
                              null
                      }

                      <span className="pl-2 pr-2">Save</span>
                  </Button>
                  <Button disabled={isSendRequesting} variant="secondary" onClick={handleCloseModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>

              <Toast className={toastBgColor}
                     onClose={() => setShowToast(false)}
                     show={showToast}
                     delay={3000}
                     autohide
                     style={{
                         position: 'fixed',
                         top: 70,
                         right: 35,
                         zIndex: 1041,
                         width: "300px"
                     }}
              >
                  <Toast.Header>
                      <strong className="mr-auto m-3">{toastMessage}</strong>
                  </Toast.Header>
              </Toast>
          </Col>
        </Row>

        {/*
          ======================= Modal Basket Detail ===================
        */}

          <Modal
              show={isShowBasketDetailModal}
              onHide={handelCloseBasketDetailModal}
              aria-labelledby="contained-modal-title-vcenter"
              size="xl"
              centered
              className="bd-example-modal-xl"
          >
              <Form noValidate autoComplete="false">
                  <Modal.Header closeButton>
                      <Modal.Title>Basket detail</Modal.Title>
                  </Modal.Header>
                  <Modal.Body key="modalBody">
                      {
                        isRequestingOrderDetail?
                            <div style={{minHeight: "220px", position: "relative"}}>
                                <Spinner animation="grow" style={{margin: "0",
                                    position: "absolute",
                                    top: "48%",
                                    left: "48%"}}/>
                            </div>:
                        Object.keys(basketDataToView).length ? (
                          <>
                              <Row>
                                  <Col sm={6}>
                                      <Form.Group controlId="formOrderNumber">
                                          <Form.Label>Order Id</Form.Label>
                                          <InputGroup hasvalidation="true">
                                              <Form.Control
                                                  type="text"
                                                  value={basketDataToView.order_id}
                                                  disabled
                                              />
                                          </InputGroup>
                                      </Form.Group>

                                      <Form.Group controlId="formPlaceAt">
                                          <Form.Label>Place At</Form.Label>
                                          <InputGroup hasvalidation="true">
                                              <Form.Control
                                                  type="text"
                                                  value={basketDataToView.placedAt}
                                                  disabled
                                              />
                                          </InputGroup>
                                      </Form.Group>

                                      <Form.Group controlId="formDeliveryFee">
                                          <Form.Label>Delivery fees ($)</Form.Label>
                                          <InputGroup hasvalidation="true">
                                              <Form.Control
                                                  type="text"
                                                  value={orderPayment(basketDataToView).delivery_fee}
                                                  disabled
                                              />
                                          </InputGroup>
                                      </Form.Group>
                                  </Col>

                                  <Col sm={6}>
                                      <Form.Group controlId="formStatus">
                                          <Form.Label>Status</Form.Label>
                                          <InputGroup hasvalidation="true">
                                              <Form.Control
                                                  type="text"
                                                  value={getStatus(basketDataToView.status)}
                                                  disabled
                                              />
                                          </InputGroup>
                                      </Form.Group>

                                      <Form.Group controlId="formSubTotal">
                                          <Form.Label>Subtotal ($)</Form.Label>
                                          <InputGroup hasvalidation="true">
                                              <Form.Control
                                                  type="text"
                                                  value={orderPayment(basketDataToView).item_total}
                                                  disabled
                                              />
                                          </InputGroup>
                                      </Form.Group>
                                  </Col>
                              </Row>
                              <Row>
                                <Col sm={12}>
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th>SKU</th>
                                            <th>Item Name</th>
                                            <th>Quantity</th>
                                            <th>Weight</th>
                                            <th>Unit Price</th>
                                            <th>Price</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        { basketDataToView && basketDataToView.order_item_details && basketDataToView.order_item_details.length ? (
                                            basketDataToView.order_item_details.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item && item.store_item_infos? storeItem(item).product_code: ""}</td>
                                                    <td>{item.item_name + " (" + item.item_price_name + ")"}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.item_price_name}</td>
                                                    <td>{item.item_price}</td>
                                                    <td>{item.item_total_price}</td>
                                                </tr>
                                            ))
                                        ):(
                                            <tr>
                                                <td colSpan={6} className="text-center">
                                                    No data
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                </Col>
                              </Row>
                          </>
                      ) :
                          <div className="text-center">
                              No data
                          </div>

                      }
                  </Modal.Body>
                  <Modal.Footer>
                      <Button
                          variant="secondary"
                          disabled={isRequesting}
                          onClick={handelCloseBasketDetailModal}
                      >
                          Close
                      </Button>
                  </Modal.Footer>
              </Form>
          </Modal>

          {/*
            =============== Pagination =====================
          */}
        {!isRequesting && (
          <Row>
            <Col sm={12}>
              <PaginationBasic />
            </Col>
          </Row>
        )}
      </HelpPageWrapper>
    </CustomModal>
  );
};

export default BasketList;
