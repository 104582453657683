import React from 'react';
import styled from 'styled-components';

const WalletManagementStyle = styled.div`
  .wallet-heading {
    margin-bottom: 40px;
    h3 { font-size: 1.5rem }
  }
  .content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 50px;
    
    .media img { width: auto; height: 68px; }
    
    .info {
      margin-top: -5px;
      
      .title { font-size: 1.3rem }
      span.text-capitalize { color: #999; }
    }
  }
  
  .last-transactions {
    .heading {
      font-size: 1.5rem;
      margin-bottom: 20px;
    }
    
    table thead tr th { min-width: 175px !important; }
  }
`;

export default WalletManagementStyle;
