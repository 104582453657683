import React from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
`;

const Spinner = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  left: 160px;
  right: 160px;
  top: 100px;
  z-index: 3784874893;
//   -webkit-transform: translate(-50%, -50%);
//   transform: translate(-50%, -50%);
  border: 4px solid #ffffff;
  /* border-top: 4px solid #389244; */
  border-top: 3px solid ${(props) => (props.color ? props.color : "#389244")};
  border-radius: 50%;
  transition-property: transform;
  animation-name: ${rotate};
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const LazyLoader = (color) => {
  return <Spinner {...color} />;
};

export default LazyLoader;
