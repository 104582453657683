import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@redq/reuse-modal";
import Card from 'react-bootstrap/Card';
import { connect } from "react-redux";
import { doSupplierLogin } from "../../store/actions/authAction";
import { AuthContext } from "../../contexts/auth/auth.context";
import ForgotPassword from '../../components/ForgotPassword';
import LoginScreen from '../../components/LoginScreen';

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 130px 0 60px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  @media (min-width: 990px) {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 60px;
  }
`;

const SupplierLoginScreen = (props) => {

  const { authDispatch } = useContext(AuthContext);
  let supplier = localStorage.getItem("supplier");
  let admin = localStorage.getItem("admin");

  if(supplier != null) {
    window.location.href = "/supplier/dashboard";
  }
  if(admin != null) {
    window.location.href = "/admin/dashboard";
  }

  useEffect(() => {
    if (props.success) {
      authDispatch({ type: "SIGNIN_SUCCESS" });
    }
  }, [props.success]);

  return (
    <HelpPageWrapper>
      <HelpPageContainer>
        <Card >
          <Card.Body>

            {(props.location.hash !== "#forgot-password")
              ? <LoginScreen doSupplierLogin={props.doSupplierLogin}/>
              : <ForgotPassword />
            }

          </Card.Body>
        </Card>
      </HelpPageContainer>
    </HelpPageWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    loginresponsemsg: state.authReducer.loginresponsemsg
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doSupplierLogin: (body) => doSupplierLogin(body, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupplierLoginScreen);