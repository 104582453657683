import React, {useState, useEffect, useContext} from "react";
import { useSelector } from 'react-redux';
import LazyLoad from 'react-lazyload';
import Button from "react-bootstrap/Button";
import {
  ProductCardWrapper,
  ProductImageWrapper,
  ProductInfo,
  DiscountPercent,
  ProductPriceWrapper,
  ProductPrice,
  PromoPrice,
  BrandLogoWrapper
} from "./ProductCard.style";
import {useCart} from "../../contexts/cart/use-cart";
import { QuantityModifier } from "../QuantityModifier/QuantityModifier";
import { cartAnimation } from "../../helper/cart-animation";
import Loader from "../Loader/Loader";
import { compDate } from "../../constants/Constant";
import moment from 'moment';
import itemToCartService from '../../services/itemToCartService';
import SimpleMessageDialog from "../SimpleMessageDialog/SimpleMessageDialog";
import Spinner from 'react-bootstrap/Spinner';
import expressService from "../../services/expressService";
import delishopExpressIcon from '../../assets/icons/delixpress_logo.svg';
import delishopIcon from '../../assets/icons/delishop_logo.svg';
import t from '../../i18n/translate';
import {CartIcon} from "../AllSvgIcon";
import ExpressContext from "../../contexts/expressContext";

const outOfStockGray = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: 'rgb(247 247 247 / 60%)',
  zIndex: 1,
};

const ProductCard = ({
  title,
  image,
  weight,
  outOfStock,
  price,
  salePrice,
  discountInPercent,
  cartProducts,
  addToCart,
  updateCart,
  value,
  currency,
  onChange,
  increment,
  decrement,
  data,
  deviceType,
  onClick,
  onCartClick,
  isSameDay,
  promo,
  promoPrice,
  promoStartDate,
  promoEndDate,
  brandId,
  brands,
  loading,
  page,
  promoProduct = false,
  productCode,
  ...props
}) => {
  const {
    items,
    addItemToCart,
    addItem,
    removeItem,
    removeItemFromCart,
    updateItem,
    getItem,
    isInCart,
    resetCart,
    fetchOrderItems,
  } = useCart();
  const [loaded, setLoaded] = useState(false);
  const dayNumber = moment().isoWeekday();
  const cartType = useSelector(state => state.cartReducer.cartType);
  const extraBrands = useSelector(state => state.expressReducer.extraBrands);
  const supplierData = useContext(ExpressContext);

  // Simple Message Dialog
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showHeaderDialog, setShowHeaderDialog] = useState(false);
  const [isConfirmButton, setIsConfirmButton] = useState(false);
  const [confirmLabel, setConfirmLabel] = useState('OK');
  const [dialogLoading, setDialogLoading] = useState(false);
  const [closeLabel, setCloseLabel] = useState('CLOSE');
  const [dialogHeaderLabel, setDialogHeaderLabel] = useState('Error');
  const [errorLog, setErrorLog] = useState(null);
  const [simpleDialogBackdrop, setSimpleDialogBackdrop] = useState(true);

  // Update quantity
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    setLoaded(false);
  },[window.location.pathname]);

  const handleAddClick = async (e) => {
    e.stopPropagation();

    setSimpleDialogBackdrop(true);

    if (!localStorage.getItem("user")) {
      onCartClick();
    } else {
      let itemQuantity = 1;
      setIsLoading(true);
      // If user click on button [Add to cart]
      if (!isInCart(data.item_id)) {
        cartAnimation(e);
      }
      // if user click on button increase item quantity
      else {
        itemQuantity = getItem(data.item_id).quantity + 1;
      }

      const itemResp = await itemToCartService.addItem(data.item_id, cartType, itemQuantity);
      if(itemResp.success) {
        fetchOrderItems(cartType);
      }
      else {
        if(typeof itemResp.error === 'string' && itemResp.error === "multiSupplier") {
          setIsConfirmButton(true);
          setConfirmLabel( t('shop_add_item_to_cart_different_shop_error_remove') );
          setCloseLabel( t('shop_add_item_to_cart_different_shop_error_cancel') );
          setDialogHeaderLabel( t('shop_add_item_to_cart_different_shop_error_title') )
          setShowHeaderDialog(true);
          setErrorLog({
            error: true,
            error_type: 'multiSupplier'
          });
        }
        else if(itemResp.error && itemResp.data.type === "paymentInProgress") {
          setIsConfirmButton(true);
          setConfirmLabel( t('shop_popup_continue_payment_button') );
          setCloseLabel( t('shop_popup_cancel_payment_button') );
          setDialogHeaderLabel( t('shop_popup_payment_locked_label') )
          setShowHeaderDialog(true);
          setErrorLog({error: true, error_type: 'paymentInProgress'});
          setSimpleDialogBackdrop('static')
        }
        else {
          setErrorLog({error: false});
        }
        setErrorMessage(itemResp.message);
        setShowError(true);
      }
      setIsLoading(false);
    }
  };

  // Simple Dialog
  const closeSimpleDialog = async () => {
    if(errorLog.error && errorLog.error_type === 'paymentInProgress') {
      setDialogLoading(true);
      const orderLockResp = await expressService.orderLocked(cartType);
      if(orderLockResp.status === 200) {
        setTimeout(async () => {
          const itemResp = await itemToCartService.addItem(data.item_id, cartType);

          if(itemResp.success) {
            await fetchOrderItems(cartType);
          }
          else {
            setErrorMessage(itemResp.message);
          }

          setShowError(false);
          setDialogLoading(false);
        }, 250);
      }
      else {
        alert( t('shop_item_update_error') );
        setShowError(false);
        setDialogLoading(false);
      }
    }
    else {
      setShowError(false);
    }
  }
  const onConfirm = () => {
    if(errorLog.error && errorLog.error_type === 'multiSupplier') {
      handleOverWriteCart();
    }
    else if(errorLog.error && errorLog.error_type === 'paymentInProgress') {
      window.location.href = '/checkout'
    }
  }
  const handleOverWriteCart = async () => {
    setDialogLoading(true);

    await resetCart();

    setTimeout(async () => {
      const itemResp = await itemToCartService.addItem(data.item_id, cartType);

      if(itemResp.success) {
        await fetchOrderItems(cartType);
      }
      else {
        setErrorMessage(itemResp.message);
      }

      setShowError(false);
      setDialogLoading(false);
    }, 250);
  };

  const handleRemoveClick = async (e) => {
    e.stopPropagation();
    let itemResp = null;

    if (!localStorage.getItem("user")) {
      onCartClick();
    }
    else{
      setIsLoading(true);
      // Remove item from cart When user click on button decrease item quantity <= 1
      if (getItem(data.item_id).quantity === 1) {
        itemResp = await itemToCartService.removeItemFromCart(getItem(data.item_id), cartType);
      }
      // When user click on button decrease item quantity
      else {
        itemResp = await itemToCartService.addItem(data.item_id, cartType, getItem(data.item_id).quantity - 1);
      }

      if(itemResp.success) {
        fetchOrderItems(cartType);
      }
      else {
        setShowError(true);
        setErrorMessage(itemResp.message);
      }
      setIsLoading(false);
    }
  };

  const handleInputUpdateQuantity = async (data, quantity) => {
    if(parseInt(quantity) >= 1) {
      setIsLoading(true);
      const itemResp = await itemToCartService.addItem(data.item_id, cartType, parseInt(quantity));
      if(itemResp.success) {
        fetchOrderItems(cartType);
      }
      else {
        if(typeof itemResp.error === 'string' && itemResp.error === "multiSupplier") {
          setIsConfirmButton(true);
          setConfirmLabel( t('shop_add_item_to_cart_different_shop_error_remove') );
          setCloseLabel( t('shop_add_item_to_cart_different_shop_error_cancel') );
          setDialogHeaderLabel( t('shop_add_item_to_cart_different_shop_error_title') )
          setShowHeaderDialog(true);
        }
        setErrorMessage(itemResp.message);
        setShowError(true);
      }
      setIsLoading(false);
    }
  }

  const getbrandlogo = (id) => {
    var b = brands.filter(elem => {
      return elem.brand_id === id   
    });
    return b.length !== 0 ? b[0].logo : null;
  };

  const addDefaultSrc = (e) => e.target.src = cartType === 'express' ? delishopExpressIcon : delishopIcon;

  function extraBrandImage(brandID) {

    if(extraBrands.length) {
      const findBrand = extraBrands.find(item => item.brand_id === brandID);
      if(typeof findBrand !== "undefined") {
        return <BrandLogoWrapper>
          <LazyLoad height={140}>
            <img
              src={findBrand.logo}
              className="brand-image"
              alt={findBrand.logo}
              onError={(e)=>{
                e.target.onerror = null;
                e.target.src = require('../../image/Background.png');
              }}
            />
          </LazyLoad>
        </BrandLogoWrapper>
      }
      else {
        return null;
      }
    }
  }

  return (
    <>
      <ProductCardWrapper itemScope itemType="http://schema.org/Product" onClick={onClick} className="product-card">
        <ProductImageWrapper className={cartType}>
          { getbrandlogo(brandId) !== null && getbrandlogo(brandId) !== "" && (!loading || page !== 1)
            ? <BrandLogoWrapper>
              <LazyLoad height={140}>
                <img
                  src={getbrandlogo(brandId)}
                  className={promoProduct ? "brand-promo" : "brand-image"}
                  alt={title}
                  onError={(e)=>{
                    e.target.onerror = null;
                    e.target.src = require('../../image/Background.png');
                  }}
                />
              </LazyLoad>
            </BrandLogoWrapper>
            : extraBrandImage(brandId)
          }
          <div className="ribbon">
            {loaded ? null : <Loader className="loaderStyle" color={cartType === 'express' ? "#f01266" : "#389244"} />}
            <LazyLoad height={140}>
            {image
              ? <img
                src={loading && page === 1 ? require('../../image/white.png') : image}
                className={promoProduct ? "promo-image product-image" : "product-image"}
                alt={title}
                onError={addDefaultSrc}
                onLoad={() => setLoaded(true)}
              />
              : <img
                src={require('../../image/white.png')}
                className={promoProduct ? "promo-image product-image" : "product-image"}
                alt={title}
                onError={addDefaultSrc}
                onLoad={() => setLoaded(true)}
              />
            }
            </LazyLoad>
            
            {cartType !== 'express'
              ? isSameDay && dayNumber !== 7
                ? !outOfStock
                  ? <span className="ribbon3">{ t('shop_item_get_same_day') }</span>
                  : null
                : !outOfStock
                  ? data.available_tommorrow && <span className="ribbon3 full-width">
                    { t('shop_item_get_tomorrow') }
                  </span>
                  : null
              : isSameDay && dayNumber !== 7 && data.is_shop_open
                ? !outOfStock
                  ? <span className="ribbon3">{ t('shop_item_get_same_day') }</span>
                  : null
                : null
            }
            {promo === 1 && !outOfStock && promoStartDate !== null && promoEndDate !== null && compDate(promoStartDate, promoEndDate) && !loading &&
              <span className={`ribbon4 ${(dayNumber === 7) ? 'custom' : ''}`}>{ t('shop_item_promo_label') }</span>
            }
          </div>
          {discountInPercent && !outOfStock ? (
            <>
              <DiscountPercent>{discountInPercent}%</DiscountPercent>
            </>
          ) : (
            ""
          )}
        </ProductImageWrapper>
        {loading && page === 1  ? <div style={{textAlign: "center", margin: "0 auto", paddingBottom: "20px"}}>{ t('toast_downloading') }</div> : <ProductInfo>
          {outOfStock ? <div style={outOfStockGray}></div> : null}
          <h3 itemProp={"name"} className={promoProduct ? "promo-title product-title" : "product-title"}>{title}</h3>
          <div className={`product-meta ${cartType}`}>
            <meta itemProp="url" content={"https://delishop.asia"+window.location.pathname} />
            <meta itemProp="sku" content={productCode} />
            <div className="product-weight-and-offer">
              {weight
                ? <p itemProp={"weight"} className={promoProduct ? "promo-weight product-weight" : "product-weight"}>{weight}</p>
                : null
              }
              <ProductPriceWrapper itemProp={"offers"} itemScope itemType={"https://schema.org/Offer"} className={promoProduct && "promo-price"}>
                {price !== salePrice && (
                  <ProductPrice className={promo === 1 && promoStartDate !== null && promoEndDate !== null && compDate(promoStartDate, promoEndDate) ? `strike-through ${cartType}` : ""}>
                    {/* <SalePrice> */}
                    <meta itemProp={"priceCurrency"} content={"USD"} />
                    <span itemProp={"price"}
                          content={parseFloat(salePrice).toFixed(2)}
                          className={`${cartType} ${promoProduct ? "promo-price" : ""}`}
                    >{currency}{parseFloat(salePrice).toFixed(2)}</span>
                    {/* </SalePrice> */}
                  </ProductPrice>
                )}
                { promo === 1 && promoStartDate !== null && promoEndDate !== null && compDate(promoStartDate, promoEndDate) && (
                  <PromoPrice className={promoProduct ? "promo-price" : ""}>
                    {/* <SalePrice> */}
                    <meta itemProp={"priceCurrency"} content={"USD"} />
                    <span itemProp={"price"}
                          content={parseFloat(promoPrice).toFixed(2)}
                          className={`${cartType} ${compDate(promoStartDate, promoEndDate) ? "promo-price" : ""}`}
                    >{currency}{parseFloat(promoPrice).toFixed(2)}</span>
                    <meta itemProp="priceValidUntil" content={promoEndDate} />
                    {/* </SalePrice> */}
                  </PromoPrice>
                )
                }
              </ProductPriceWrapper>
            </div>
            {cartType !== "express"
              ? outOfStock
                ? <div className={promoProduct ? "promo-price" : ""} style={{ color: "red" }}>{ t('shop_item_out_of_stock') }</div>
                : !isInCart(data.item_id)
                  ? <Button colors="success"
                            className={promoPrice ? "btn btn-outline-success promo-cart-button "+"cart-button pr"+data.item_id : "btn btn-outline-success cart-button pr"+data.item_id}
                            onClick={handleAddClick}
                  >
                    {isLoading
                      ? <Spinner animation="border" role="status" size="sm">
                        <span className="sr-only">{ t('toast_downloading') }</span>
                      </Spinner>
                      :<>
                        <span style={{ position: 'relative', top: -2, marginRight: 5 }}><CartIcon /></span> { t('button_add_to_cart') }
                      </>
                    }
                  </Button>
                  : <div onClick={(e)=>e.stopPropagation()}>
                    <QuantityModifier
                      className={`pr${data.item_id} ${cartType}`}
                      value={getItem(data.item_id).quantity}
                      onDecrement={handleRemoveClick}
                      onIncrement={handleAddClick}
                      onUpdate={(quantity) => { handleInputUpdateQuantity(data,quantity) }}
                      promoProduct={promoProduct}
                      isLoading={isLoading}
                    />
                  </div>
              : data.is_shop_open
                ? outOfStock
                  ? <div className={promoProduct ? "promo-price" : ""} style={{ color: "red" }}>{ t('shop_item_out_of_stock') }</div>
                  : !isInCart(data.item_id)
                    ? <Button colors="success"
                              className={promoProduct ? "btn btn-outline-success promo-cart-button "+"cart-button pr"+data.item_id : "btn btn-outline-success cart-button pr"+data.item_id}
                              onClick={handleAddClick}
                    >
                      {isLoading
                        ? <Spinner animation="border" role="status" size="sm">
                          <span className="sr-only">{ t('toast_downloading') }</span>
                        </Spinner>
                        :<>
                          <span style={{ position: 'relative', top: -2, marginRight: 5 }}><CartIcon /></span> { t('button_add_to_cart') }
                        </>
                      }
                    </Button>
                    : <div onClick={(e)=>e.stopPropagation()}>
                      <QuantityModifier
                        className={`pr${data.item_id} ${cartType}`}
                        value={getItem(data.item_id).quantity}
                        onDecrement={handleRemoveClick}
                        onIncrement={handleAddClick}
                        onUpdate={(quantity) => { handleInputUpdateQuantity(data,quantity) }}
                        promoProduct={promoProduct}
                        isLoading={isLoading}
                      />
                    </div>
                : outOfStock
                  ? <div className={promoProduct ? "promo-price" : ""} style={{ color: "red" }}>{ t('shop_item_out_of_stock') }</div>
                  : null
            }
          </div>
        </ProductInfo>}
      </ProductCardWrapper>

      <SimpleMessageDialog show={showError}
                           message={errorMessage}
                           showHeader={showHeaderDialog}
                           closeLabel={closeLabel}
                           confirmLabel={confirmLabel}
                           headerLabel={dialogHeaderLabel}
                           isConfirmButton={isConfirmButton}
                           onClose={closeSimpleDialog}
                           onConfirm={onConfirm}
                           backdrop={simpleDialogBackdrop}
                           loading={dialogLoading}
                           cartType={cartType}
      />
    </>
  );
};

export default ProductCard;
