import React from 'react';
import { FormattedMessage } from 'react-intl';
import message from './messages';
import { LOCALES } from "./locales";

const Translate = (id, tagWrapper = true, value= {}) => {
  const history = window.location;

  if(history.search !== '') {
    let query = history.search;
    query = query.split('=');
    if(query[0].includes('_locale'))
      localStorage.setItem('lang', query[1])
  }
  const lang = LOCALES[localStorage.getItem('lang')] || 'en-US';

  return !Object.keys(value).length
    ? message[lang][id] // Render text only
    : message[lang][id].replace(`{${Object.keys(value)}}`, value[Object.keys(value)]) // Render text with param
}

export default Translate;
