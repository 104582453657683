import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import API from "../network/dashboardAPI"

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const ForgotPassword = (props) => {

  let history = useHistory();
  const _emailRef = useRef("");
  const [isInvalid, setIsInvalid] = useState(false);
  const errorMessage = "Email not found";

  const doResetPassword = async (e) => {
    e.preventDefault();
    try {
      clearForm();

      const email = _emailRef.current.value;
      await API.supplierResetPasswordRequest({email})
        .then(res => res.json())
        .then(res => {
          if(res.success) {
            history.push({
              pathname: '/supplier/reset-password',
              state: {
                email: _emailRef.current.value
              }
            });
          } else {
            setIsInvalid(true);
          }
        })
    } catch(e){
      console.log(e);
      setIsInvalid(true);
    }
  };

  const clearForm = () => {
    setIsInvalid(false);
  };

  return (
    <>
      <Heading>Forgot Password</Heading>
      <Form onSubmit={(e) => doResetPassword(e)}>

        <InputGroup hasValidation>
          <InputGroup.Prepend><InputGroup.Text>Email</InputGroup.Text></InputGroup.Prepend>
          <Form.Control type="email"
                        ref={_emailRef}
                        placeholder="example@example.com"
                        required
                        isInvalid={isInvalid}
                        onChange={clearForm}
          />
          <Form.Control.Feedback type="invalid">{ errorMessage }</Form.Control.Feedback>
        </InputGroup>

        <Form.Group controlId="formBasicCheckbox" className="mt-3">
          <Link to={{pathname: "/supplier/login"}}>Back to login</Link>
        </Form.Group>

        <Button variant="success" type="submit">Submit</Button>
      </Form>
    </>
  );
};

export default ForgotPassword;