import React, { useContext, useState, useEffect } from "react";
import * as Yup from "yup";
import { closeModal } from "@redq/reuse-modal";
import TextField from "../../../components/TextField/TextField";
import Button from "../../../components/Button/Button";
import { FieldWrapper, Heading } from "./Update.style";
import { ProfileContext } from "../../../contexts/profile/profile.context";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./style.css";
import { Modal } from "react-bootstrap";
import GoogleMaps from "../../../components/GoogleMaps";
import { first } from "lodash";
import {browSer} from "../../../helper/constant";
import Stepper from "react-stepper-horizontal";
import UpdateAddressTwo from "./UpdateAddressTwo";
import { openModal } from "@redq/reuse-modal";
import Form from 'react-bootstrap/Form';
import t from '../../../i18n/translate';
import {themeGet} from "@styled-system/theme-get/dist/index";
import styled from "styled-components";

let getLat;
let getLng;
// navigator.geolocation.getCurrentPosition(function(position) {
//         getLat= position.coords.latitude;
//         getLng= position.coords.longitude;

// });

const InputFeedback = styled.span`
  font-family: ${themeGet("fontFamily.0", "sans-serif")};
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.secondaryHover", "#FF282F")};
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

const expressMode = localStorage.getItem("expressMode") || 0
const UpdateAddress = (props) => {
  let user = localStorage.getItem("user");
  let parsedUser = JSON.parse(user);
  let mql = window.matchMedia("all and (min-width: 769px)");

  const [first_name, setFirstName] = useState(parsedUser.first_name);
  const [last_name, setLastName] = useState(parsedUser.last_name);
  const [country_code, setCountry_code] = useState(855);
  const [mobile_number, setMobile] = useState(parsedUser.phone);
  const [house_number, setHouse] = useState("");
  const [appartement_name, setApartment] = useState("");
  const [street_details, setStreet] = useState("");
  const [landmark, setLandmark] = useState("");
  const [area_details, setArea] = useState("");
  const [city, setCity] = useState("");
  const [pin_code, setPin] = useState("");
  const [nick_name, setNickName] = useState("");
  const [is_default, setDefault] = useState(0);
  const [delivery_instructions, setInstruction] = useState("");
  const [address_id, setAddressId] = useState("");
  const [modalshow, setModalShow] = useState(false);
  // const [isMarkerShown, setMarkerShow] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isChecked, setChecked] = useState(false);
  const [marker, setMarker] = useState(false);
  const [currentstep, setCurrentStep] = useState(0);
  const [prev, setPrev] = useState(true);
  const [next, setNext] = useState(false);
  const [reask, setReAsk] = useState(false);
  const [isOutOfRange, setIsOutOfRange] = useState(false);

  const initialError={
    first_name:"",
    last_name:'',
    country_code:'',
    mobile_number:'',
    street_details:'',
    area_details:'',
    city:'',
    saveAs:'',
    house_number:'',
    search_pin:''
  }
  const [error,setError]=useState(initialError);
  // const { state, dispatch } = useContext(ProfileContext);
  //12.5657° N, 104.9910° E combodia latlng
  useEffect(() => {
    if(marker === true){
      fetchAddress(); 
    }
  },[marker,latitude,longitude]);
  useEffect(() => {
    if(props.item.landmark) {
      setInstruction(`${ t('profile_user_landmark_label', false) } ${props.item.landmark} \n`)
    }
    if(props.item.apartment_name) {
      setInstruction(`${ t('profile_user_apartment_name_label', false) } ${props.item.apartment_name} \n`)
    }
    if(props.type === "add"){
      getLocation(()=>{
       geocodeByAddress()
      });
    }
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // Success function
        showPosition,
        // Error function
        defaultPosition,
        // Options. See MDN for details.
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      alert( t('shop_new_address_geolocation_error', false) );
    }
  };

  const showPosition = (position) => {
    getLat = position.coords.latitude;
    getLng = position.coords.longitude;
    setLatitude(getLat);
    setLongitude(getLng);
    setMarker(true);
  };

  const defaultPosition = () => {
    setLatitude(props.item.latitude ? props.item.latitude : 11.5564);
    setLongitude(props.item.long ? props.item.long : 104.9282);
    if (browSer() === "Chrome"){
      if(window.location.hostname === "dev.delishop.asia"){
        alert("Location permissions not allowed for Google Chrome , allow your location by following this link in another tab 'chrome://settings/content/siteDetails?site=https://dev.delishop.asia/' , set it to 'Ask' and reload the page");
      }
      else{
        alert("Location permissions not allowed for Google Chrome , allow your location by following this link in another tab 'chrome://settings/content/siteDetails?site=https://delishop.asia/' , set it to 'Ask' and reload the page");
      }
    }
    else if(browSer() === "Safari"){
      if(window.location.hostname === "dev.delishop.asia"){
        alert("Location permissions not allowed for Safari , allow your location by following these steps : \nSafari -> Preferences -> Websites tab -> Location -> dev.delishop.asia -> ask and reload the page");
      }
      else{
        alert("Location permissions not allowed for Safari , allow your location by following these steps : \nSafari -> Preferences -> Websites tab -> Location -> delishop.asia -> ask and reload the page");
      }
    }
    // latitude !== "" ? setLatitude(latitude) : setLatitude(11.5564);
    // longitude !== "" ? setLongitude(longitude) : setLongitude(104.9282);
  };

  const VerifyAndSubmit=(e)=>{
    e.preventDefault();
    if(first_name === ""){
     setError(pre=>({...pre,first_name: t('shop_new_address_error_field_required', false)}))
    }
    if(last_name === ""){
      setError(pre=>({...pre,last_name: t('shop_new_address_error_field_required', false)}))
    }
    if(country_code === ""){
      setError(pre=>({...pre,country_code: t('shop_new_address_error_field_required', false)}))
    }
    if(mobile_number === ""){
       setError(pre=>({...pre,mobile_number: t('shop_new_address_error_field_required', false)}))
    }
    if(street_details === ""){
       setError(pre=>({...pre,street_details: t('shop_new_address_error_field_required', false)}))
    }
    if(area_details === ""){
      setError(pre=>({...pre,search_pin: t('shop_new_address_error_field_required', false)}))
    }
    if(city === ""){
      setError(pre=>({...pre,city: t('shop_new_address_error_field_required', false)}))
    }
    if(nick_name === ""){
      setError(pre=>({...pre, saveAs: t('shop_new_address_error_field_required', false)}))
    }
    if(house_number === ""){
      setError(pre=>({...pre, house_number: t('shop_new_address_error_field_required', false)}))
    }
    else{
      if(area_details !=="" && city !== "" && nick_name !== "" && house_number !== "" && street_details !== "" && country_code !== "" && mobile_number !== "" && first_name !== "" && last_name !== ""){
        handleSubmit()
      }
      else{
        alert( t('shop_new_address_missing_details_error', false) );
      }
    }
  }

  const handleSubmit = async () => {
    let body = {
      cust_id: parsedUser.cust_id,
      first_name,
      last_name,
      mobile_number,
      house_number,
      appartement_name,
      street_details,
      landmark,
      area_details,
      city,
      pin_code,
      nick_name,
      latitude,
      country_code,
      long: longitude,
      delivery_instructions,
      is_default,
    };

    if (address_id) {
      body["address_id"] = address_id;
      props.props.updateAddress(body);
    } else {
      props.props.addAddress(body);
    }
    closeModal();
    setChecked(false);
  };

  useEffect(() => {
    if (typeof props.item === "object") {
      changeValues();
    }

    // if (props.user_address && props.user_address.length > 0)
    setChecked(props.item.is_default);

  }, [props.item]);

  const changeValues = () => {
    let item = props.item;
    setFirstName(item.first_name);
    setLastName(item.last_name);
    setMobile(item.mobile_number);
    setNickName(item.nick_name);
    setHouse(item.house_number);
    setApartment(item.apartment_name);
    setStreet(item.street_details);
    setArea(item.area_details);
    setLandmark(item.landmark);
    setCity(item.city);
    setCountry_code(item.country_code);
    setLatitude(item.latitude);
    setLongitude(item.long);
    setInstruction(item.delivery_instructions || '');
    setAddressId(item.address_id);
    setDefault(item.is_default);
  };

  function isNumber(evt) {
    let telephone = evt.target.value;
    let regexp = /^[0-9\+\b]+$/;
    if (regexp.test(telephone) || evt.target.value === "") {
      return true;
    }
    else{
      return false;
    }
  }
  const handleAddAdrresPress = () => {
    // setModalShow(true);
    if(!area_details){
      fetchAddress();
    }
    // fetchAddress();
  };
  const onMarkerDragEnd = async (t, map, coord) => {
    const { latLng } = coord;
    setLatitude(latLng.lat());
    setLongitude(latLng.lng());
    setMarker(true);
  };
  const fetchAddress = () => {
    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        latitude +
        "," +
        longitude +
        "&key=" +
        "AIzaSyBNXfan5vWyKWSbFUnnVOtjhcPdXafxQEk"
      // 'AIzaSyCWJ4VxjBDgtEsyoNGvmBqkaalrC3tHl-0'   ,
    )
      .then((response) => response.json())
      .then((responseJson) => {

        if(responseJson.results.length && typeof responseJson.results[0].formatted_address !== 'undefined')
        {
          let countryCode = null;
          const getUserLocation = responseJson.results[0].formatted_address;
          const addressComponents = responseJson.results[0].address_components;

          if(addressComponents.length) {
            addressComponents.find(item => {
              if(item.short_name === "KH") {
                countryCode = item.short_name;
              }
              return;
            });
          }

          if(countryCode === "KM" || countryCode === "KH") {
            setArea(getUserLocation);
            setMarker(false);
            responseJson.results[0].address_components.map(async function (
              item,
              idx
            ) {
              item.types.map(async function (item1, idx1) {
                // if (item1 == "administrative_area_level_1") {
                //   setCity(item.long_name);
                // }
              });
            });
          }
          else {
            setIsOutOfRange(true);
          }
        }
      });
  };

  useEffect(() => {
    if(isOutOfRange) {
      setLatitude('11.5564');
      setLongitude('104.9282');
      setMarker(false);
      setIsOutOfRange(false);
      alert( t('shop_select_location_outside_cambodia_error') );
    }
  }, [isOutOfRange]);

  const handleUserFormChange = (e) => {
    const {name, value} = e.target;

    if(name === 'nick_name'){
      setError((prev) => ({...prev, saveAs: !value? t('shop_new_address_error_field_required', false): ""}));
    }else{
      setError((prev) => ({...prev, [name]: !value? t('shop_new_address_error_field_required', false): ""}));
    }
  }
  return (
    <>
      <form>
      {/* <Stepper steps={ [{title: 'Step One'}, {title: 'Step Two'}] } activeStep={ currentstep } /> */}
        <Heading>
          {props.type !== "add"
            ? t('shop_edit_address_screen_title')
            : t('profile_user_add_address_button')
          }
        </Heading>
        { prev && <>
        <div className="row">
          <div className="col-sm-6 pb-2">
            <TextField
              type="text"
              label={ t('shop_new_address_house_no_title', false) }
              placeholder={ t('shop_new_address_house_no_title', false) }
              error={error.house_number}
              name="house_number"
              value={house_number}
              onChange={(e) => {
                handleUserFormChange(e)
                setHouse(e.target.value);
              }}
            />
          </div>
          <div className="col-sm-6 pb-2">
            <TextField
              type="text"
              label={ t('shop_new_address_street_no_title', false) }
              placeholder={ t('shop_new_address_street_no_title', false) }
              error={error.street_details}
              value={street_details}
              name="street_details"
              onChange={(e) => {
                handleUserFormChange(e);
                setStreet(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 pb-2">
            {/*<TextField
              type="text"
              label={ t('shop_new_address_city_title', false) }
              placeholder={ t('shop_new_address_city_title', false) }
              value={city}
              error={error.city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />*/}
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label>{ t('shop_new_address_city_title', false) }</Form.Label>
              <Form.Control as="select"
                            style={{height: "55px"}}
                            custom
                            name="city"
                            onChange={(e) => {
                              handleUserFormChange(e)
                              setCity(e.target.value);
                            }}
              >
                {props.type === "add"
                  ? <option value=""></option>
                  : null
                }
                <option value="Phnom Penh"
                        selected={city === "Phnom Penh" ? 'selected' : null}
                >{ t('shop_new_address_city_phnom_penh_option_title') }</option>
                <option value="Province"
                        selected={city === "Province" ? 'selected' : null}
                >{ t('shop_new_address_city_province_option_title') }</option>
              </Form.Control>
              {error.city && <InputFeedback>{ t('shop_new_address_error_field_required', false) }</InputFeedback>}
            </Form.Group>
          </div>
          <div className="col-sm-6 pb-2">
            <TextField
              type="text"
              label={ t('shop_new_address_label_title', false) }
              placeholder={ t('shop_new_address_label_title', false) }
              error={error.saveAs}
              value={nick_name}
              name="nick_name"
              onChange={(e) => {
                handleUserFormChange(e);
                setNickName(e.target.value);
              }}
            />
          </div>
        </div>
        <FieldWrapper>
          <TextField
            id="instructions"
            label={ t('shop_new_address_delivery_instruction_title', false) }
            as="textarea"
            placeholder={ t('shop_new_address_delivery_instruction_title', false) }
            value={delivery_instructions}
            onChange={(e) => {
              setInstruction(e.target.value);
            }}
          />
        </FieldWrapper>
        <div className="row">
          <div className="col-sm-6 pb-2">
            <TextField
              type="text"
              label={ t('profile_user_first_name_title', false) }
              placeholder={ t('profile_user_first_name_title', false) }
              value={first_name}
              error={error.first_name}
              name="first_name"
              onChange={(e) => {
                handleUserFormChange(e)
                setFirstName(e.target.value);
              }}
            />
          </div>
          <div className="col-sm-6 pb-2">
            <TextField
              type="text"
              label={ t('profile_user_last_name_title', false) }
              placeholder={ t('profile_user_last_name_title', false) }
              value={last_name}
              error={error.last_name}
              name="last_name"
              onChange={(e) => {
                handleUserFormChange(e);
                setLastName(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-3 pb-2">
            <TextField
              type="tel"
              label={ t('shop_new_address_country_code_title', false) }
              value={country_code}
              name="country_code"
              error={error.country_code}
              onChange={(e) => {
                if(isNumber(e)){
                  handleUserFormChange(e);
                  setCountry_code(e.target.value);
                }
              }}
            />
          </div>
          <div className="col-sm-9 pb-2">
            <TextField
              type="tel"
              label={ t('profile_user_phone_title', false) }
              placeholder={ t('profile_user_phone_title', false) }
              error={error.mobile_number}
              value={mobile_number}
              name="mobile_number"
              onChange={(e) => {
                if(isNumber(e)){
                  handleUserFormChange(e)
                  setMobile(e.target.value);
                }
              }}
              maxLength={14}
            />
          </div>
        </div>
        <FieldWrapper>
          <label className="label_container">
            { t('shop_new_address_primary_address') }
            <input
              name="Is Default"
              type="checkbox"
              style={{ marginRight: 5 }}
              checked={isChecked}
              onChange={(e) => {
                setChecked(e.target.checked);
                e.target.checked ? setDefault(1) : setDefault(0);
              }}
            />
            <span className="checkmark"></span>
          </label>
        </FieldWrapper>
        <Button
          onClick={(e)=>{
            e.preventDefault();

            if(first_name === ""){
            setError(pre=>({...pre,first_name: t('shop_new_address_error_field_required', true)}));
            }
            if(last_name === ""){
              setError(pre=>({...pre,last_name: t('shop_new_address_error_field_required', false)}))
              console.log(error);
            }
            if(country_code === ""){
              setError(pre=>({...pre,country_code: t('shop_new_address_error_field_required', false)}))
            }
            if(mobile_number === ""){
              setError(pre=>({...pre,mobile_number: t('shop_new_address_error_field_required', false)}))
            }
            if(street_details === ""){
              setError(pre=>({...pre,street_details: t('shop_new_address_error_field_required', false)}))
            }
            if(city === ""){
              setError(pre=>({...pre,city: t('shop_new_address_error_field_required', false)}))
            }
            if(nick_name === ""){
              setError(pre=>({...pre, saveAs: t('shop_new_address_error_field_required', false)}))
            }
            if(house_number === ""){
              setError(pre=>({...pre, house_number: t('shop_new_address_error_field_required', false)}))
            }
            else{
              if(nick_name !== "" && city !== "" && house_number !== "" && street_details !== "" && country_code !== "" && mobile_number !== "" && first_name !== "" && last_name !== ""){
                setPrev(false); 
                setNext(true);
                if(modalshow !== true){
                  setModalShow(true);
                  if(!area_details){
                    fetchAddress();
                  }
                }
              }
            }
          }}
          type="button"
          title={ t('shop_new_address_mark_delivery_button', false) }
          size="medium"
          fullwidth={true}
          style={{ marginBottom: 15 }}
        />
        </>}
        {next && <>
        <div className="row">
          <div className="col-sm-12 pb-2">
          <PlacesAutocomplete
              searchOptions={{componentRestrictions: { country: ['kh'] },
              types: []}}
              value={area_details}
              onChange={(address) => { 
                setArea(address);
              }}
              onSelect={(address) => {
                setArea(address);
                geocodeByAddress(address)
                  .then(results => {
                    setLatitude(results[0].geometry.location.lat());
                    setLongitude(results[0].geometry.location.lng());
                    results[0].address_components.map( async function(item, idx){
                      item.types.map(async function(item1, idx1){
                        // if (item1 == "administrative_area_level_1") {
                        //   setCity(item.long_name);
                        // }
                        // if(item1 == 'postal_code'){ setPin(item.long_name); }
                        // if(item1 != 'postal_code'){ setPin(""); }
                        // if(item1 == "administrative_area_level_2"){ setCity(item.long_name); }
                      })
                    })
                  })
                // .then(latLng => console.log('Success', latLng))
              }}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div onClick={ modalshow ? "" : handleAddAdrresPress}>
                  <span onClick={()=>{}} className="searchBar">
                    {area_details === ""
                      ? <i className="fa fa-search" aria-hidden="true"></i>
                      : null
                    }
                    <TextField {...getInputProps({
                      placeholder: t('shop_new_address_map_search_address_input_hint', false),
                      label: t('shop_new_address_map_sub_title', false),
                      className: 'taimage',
                      id: 'searchlocation',
                      as: 'textarea',
                      error: error.search_pin,
                    })}
                    />
                  </span>
                  {/* <textarea
                    {...getInputProps({
                      placeholder: 'Search Address ...',
                      className: 'location-search-input',
                    })}
                  /> */}
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {modalshow ? (
              <>
              <div title={ t('shop_new_address_map_my_location_label', false) } onClick={()=>{
                 getLocation();
                }
              } style={{position: "absolute", top: "25%",left: mql.matches ? "5%" : "7%", zIndex: "1000000", border:"2px solid rgb(0,120,201)", borderRadius:"50%", padding: "10px", backgroundColor: "white"}}>
              <img src={require('../../../assets/icons/Loc.png')} style={{width: mql.matches ? "40px" : "29px"}} alt={"my location"} />
              </div>
              <GoogleMaps
                isMarkerShown={true}
                latitude={latitude}
                longitude={longitude}
                onMarkerDragEnd={onMarkerDragEnd}
                onMyLocation={getLocation}
                fetchAddress={fetchAddress}
              />
              </>
            ) : null}
          </div>
        </div>
        <Button
          onClick={(e)=>{e.preventDefault(); setPrev(true); setNext(false);}}
          type="button"
          title={ t('shop_new_address_map_back_button', false) }
          size="medium"
          fullwidth={false}
          style={{ float: "left",marginBottom: 15 }}
        />
        <Button
          onClick={VerifyAndSubmit}
          type="submit"
          title={ t('shop_new_address_save_address_button', false) }
          size="medium"
          fullwidth={false}
          style={{ float: "right",marginBottom: 15 }}
        />
        {/* <div className="row">
          <div className="col-sm-4" style={{float: "left", textAlign: "right"}}>
            
          </div>
        </div> */}
        </>
        }
      </form>
    </>
  );
};
export default UpdateAddress;
