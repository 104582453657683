import styled from "styled-components";
import arrowFowardIcon from '../assets/icons/fi-br-angle-double-right.svg';

export const ExpressStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 50px;
  align-items: center;
  font-size: 22px;
  line-height: 2.2em;
  
  h2 {
    color: #f01266;
    
    a { color: #f01266; }
  }
  
  .span.ribbon3,
  .button {
    background-color: #f01266;
  }
  .divider { line-height: 0.8em; }
  .btnExpress {
    position: relative;
    display: block;
    padding-left: 30px;
    
    &:before {
      content: '';
      background-image: url('${arrowFowardIcon}');
      display: block;
      width: 100%;
      height: 20px;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      top: 15px;
      
      animation-duration: 0.5s;
      animation-name: slidein;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }
  
  @keyframes slidein {
    from {
      left: 0;
    }
  
    to {
      left: 5px;
    }
  }
  
  @media (max-width: 990px) {
    gap: 10px;
    font-size: 1rem;
    padding: 0 20px;
  
    button.reusecore__button {
      padding-left: 10px;
      padding-right: 15px;
      font-size: 0.8rem;
      height: 34px;
      
      .btn-icon .fa {
        font-size: 1rem !important;
      }
    }
    .btnExpress {
      &:before {
        top: 10px;
      }
      img {
        height: 32px !important;
      }
    }
  }
`;
