import React from 'react';

const BrandFilterPopupButton = ({onClick,onOpen}) => {
    return (
        <>
        <button onClick={onClick} type="button" className="_1cnw8os brand-filter-button">
        <span className="_3hmsj">
            <div className="_a7a5sx" aria-expanded="false">
                <svg className="brand-filter-icon" role="img" focusable="false" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" 
              //   style={{display: 'block', fill: 'none', height: '12px', width: '12px', stroke: 'currentcolor', strokeWidth: '5.33333', overflow: 'visible'}}
                >
                    <g fill="none">
                        <path d="m28 12-11.2928932 11.2928932c-.3905243.3905243-1.0236893.3905243-1.4142136 0l-11.2928932-11.2928932"></path>
                   </g>
               </svg>
           </div>
       </span>
   </button>

    <button onClick={onOpen} type="button" className="_1cnw8os1 brand-filter-mobile-button">
    <span className="_3hmsj">
        <div className="_a7a5sx" aria-expanded="false">
           <svg className="brand-filter-mobile-icon" 
          //  style={{display:'block',fill:'none',height:'16px',width:'16px',stroke:'#717171',strokeWidth:4,overflow:'visible'}}
            aria-hidden="true" role="presentation" focusable="false" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 16H3m26 0H15M29 6h-4m-8 0H3m26 20h-4M7 16a4 4 0 1 0 8 0 4 4 0 0 0-8 0zM17 6a4 4 0 1 0 8 0 4 4 0 0 0-8 0zm0 20a4 4 0 1 0 8 0 4 4 0 0 0-8 0zm0 0H3" fill="none">
           </path></svg>
       </div>
   </span>
</button>
</>
    )
}

export default BrandFilterPopupButton
