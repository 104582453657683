import React from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Dropdown from 'react-bootstrap/Dropdown'
import "./AppSideBar.css";

function SupplierHeader () {

  let supplier = localStorage.getItem("supplier");
  if(!supplier) {
    window.location.href = "/";
  }

  const clearData = (() => {
    localStorage.removeItem('supplier');
    localStorage.removeItem('token');
    window.location.href = "/";
  });

  const dropDownToggle = (isOpen, event, metadata) => {
    event.stopPropagation();

    console.log(isOpen, metadata)
  };

  return (
    <div className="Header">
      <Row>
        <Col sm={1}>
          <h3>DeliShop</h3>
        </Col>
        <Col sm={11}>
          <Dropdown onClick={(e) => e.stopPropagation()}
                    className="float-right"
                    navbar={false}
                    onToggle={dropDownToggle}
          >
            <Dropdown.Toggle variant="success" id="dropdown-basic">
            <AccountCircleIcon></AccountCircleIcon>
            </Dropdown.Toggle>

            <Dropdown.Menu>
            <Dropdown.Item onClick={clearData}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
        
    </div>
  );
};

export default SupplierHeader;
