import React, { useEffect } from "react";
import styled from "styled-components";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import * as Constants from "../constants/Constant";

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

export const ErrorContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ForgotPassword = (props) => {

  const [loading, setLoading] = React.useState(false);
  const [errormsg, setErrorMsg] = React.useState("");
  const [cerror, setCError] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);

  useEffect(() => {
    return ()=>{
      setLoading(false);
      setErrorMsg("");
      setCError("");
      setError(false);
      setEmail("");
      setPassword("");
    }
  },[]);

  const loginCallback = (e) => {
    e.preventDefault();
    if (!error) {
      setLoading(true);
      props.doSupplierLogin({ email: email, password: password });
      setTimeout(() => {
        didLoginFail();
      }, 1000);
    }
  };

  const didLoginFail = () => {
    if(!props.success){
      setCError(true);
    }
    setLoading(false);
  };

  const validateEmail = async () => {
    if (!email) {
      setErrorMsg("Enter an email");
      setError(true);
      return false;
    }
    else if ((Constants.email_reg.test(email)) === false) {
      setErrorMsg("Enter a valid email");
      setError(true);
      return false;
    }
  };

  return (
    <>
      <Heading>Supplier Login</Heading>

      <Form onSubmit={loginCallback}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={validateEmail}
            required />
          <Form.Text className="text-muted">
            Your email which is listed in our system.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required />
        </Form.Group>

        <Form.Group controlId="formBasicCheckbox" className="mb-1">
          <Form.Check type="checkbox" label="Remember me" />
        </Form.Group>

        <Form.Group controlId="formBasicCheckbox">
          <Link to={{
            pathname: "/supplier/login",
            hash: "#forgot-password",
            state: { forgotPassword: true }
          }}
          >Forgot password?</Link>
        </Form.Group>

        <Button variant="success" type="submit" disabled={loading}>
          Submit
        </Button>
        <br/>
        {error === true ? (
          <ErrorContainer>
            <span className="text-danger">{errormsg}</span>
          </ErrorContainer>
        ) : null}
        {cerror && props.loginresponsemsg === "invalid password" && error !== true
          ? <ErrorContainer><span className="text-danger">{props.loginresponsemsg}</span></ErrorContainer>
          : null
        }
      </Form>
    </>
  );
};

export default ForgotPassword;