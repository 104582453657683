import styled from "styled-components";
import css from "@styled-system/css";
import { style, variant, width } from "styled-system";
export const CounterBox = styled.div(
  css({
    display: "flex",
    backgroundColor: "primary",
    color: "white",
    fontSize: 2,
    fontWeight: 6,
  }),
  {
    borderRadius: 200,
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    flexShrink: 0,
    "&:focus": {
      outline: "none",
    },
    "&.express button .spinner-border": {
      color: '#f01266',
    },
  },
  variant({
    variants: {
      horizontal: {
        width: [80,104],
        height: [20,36],
      },
      vertical: {
        width: 40,
        height: 100,
        flexDirection: "column-reverse",
      },
      lightHorizontal: {
        width: 104,
        height: 36,
        backgroundColor: "lightColor",
        color: "darkBold",
      },

      lightVertical: {
        width: 40,
        height: 100,
        flexDirection: "column-reverse",
        backgroundColor: "lightColor",
        color: "darkBold",
      },
    },
  })
);

export const CounterButton = styled.button(
  {
    border: "none",
    backgroundColor: "transparent",
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    padding: 10,
    cursor: "pointer",
    "&:hover, &:focus": {
      outline: "none",
    },
    ".spinner-border": {
      width: '0.7rem',
      height: '0.7rem',
    },
    "@media screen and (max-width: 500px )": {
      padding: 5,
    }
  },
  variant({
    variants: {
      lightHorizontal: {
        color: "darkRegular",
      },
      lightVertical: {
        color: "darkRegular",
      },
    },
  })
);

export const CounterValue = styled.span({
  pointerEvents: "none",
});
CounterValue.displayName = "CounterValue";
CounterButton.displayName = "CounterButton";
CounterBox.displayName = "CounterBox";
CounterBox.defaultProps = {
  variant: "horizontal",
};
