import InputSearch from "./Input";
import React, { useEffect, useRef, useState } from "react";
import SearchResults from "./SearchResults";
import SearchWrapper, {
  SearchBoxWrapper,
  CurrentType,
} from "./SearchBox.style";
import { SearchIcon } from "../AllSvgIcon";
import { Alert } from "react-bootstrap";
import { Toggle_Suggestions } from "../../store/actions/productAction";

const Search = ({
  suggestions,
  buttonText,
  buttonIcon,
  inputStyle,
  style,
  bordered,
  hideType,
  showSvg,
  autoSuggestion,
  className,
  handleSearch,
  onClick,
  value,
  expand,
  minimal,
  props,
  pathname,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [toggleSuggestion, setToggleSuggestion] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);

  let searchRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleSearchInput = (event) => {
    setSearchValue(event.target.value);
    // setToggleSuggestion(true);
    props.Toggle_Suggestions({toggle: false});
    handleSearch(event.target.value);
  };

  const ucwords = (str) => {
    const newString = str.replace(/\//g, "");
    const humanString = newString.replace(/-/g, " ");
    return (humanString + "").replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
    });
  };

  const setSuggestion = (suggestion) => {
    setSearchValue(suggestion);
    setToggleSuggestion(false);
    props.Toggle_Suggestions({toggle: false});
    setToggleSearch(false);
    if (document.getElementById("closeModal")) {
      document.getElementById("closeModal").click();
    }
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setToggleSuggestion(false);
      props.Toggle_Suggestions({toggle: false});
      setToggleSearch(false);
    }
  };

  const onClearBtnClick = () => {
    setToggleSuggestion(false);
    props.Toggle_Suggestions({toggle: false});
  };

  const initSearch = () => {
    const textSearch = searchValue.trim().replace(/  +/g, '+');
    window.location.href = `/express/search/${textSearch}`;
  }

  return (
    <SearchWrapper
      className={className}
      ref={searchRef}
      style={style}
      autoSuggestion={autoSuggestion}
      hideType={hideType}
      expand={expand}
    >
      <SearchBoxWrapper
        className={`${hideType ? "hideType" : ""} ${
          expand === true ? (toggleSearch ? "expanded" : "collapsed") : ""
        } ${minimal === true ? "minimal" : ""}`}
      >
        {/* {pathname && pathname !== '/' ? (
          <CurrentType>{ucwords(pathname)}</CurrentType>
        ) : (
          <CurrentType>Grocery</CurrentType>
        )} */}

        {props.cartType === 'express'
          ? <InputSearch
            type="text"
            value={value}
            onChange={handleSearchInput}
            onFocus={() => {setToggleSearch(true)}}
            onBlur={() => {setToggleSearch(true)}}
            buttonIcon={buttonIcon}
            buttonText={buttonText}
            style={inputStyle}
            bordered={bordered}
            showSvg={showSvg}
            onKeyPress={(e) => { if(e.key === "Enter"){ initSearch() } }}
            onClick={() => initSearch() }
          />
          : <InputSearch
            type="text"
            value={value}
            onChange={handleSearchInput}
            onFocus={() => {setToggleSearch(true)}}
            onBlur={() => {setToggleSearch(true)}}
            buttonIcon={buttonIcon}
            buttonText={buttonText}
            style={inputStyle}
            bordered={bordered}
            showSvg={showSvg}
            onKeyPress={(e)=>{
              if(e.key === "Enter"){
                if(props.page===1){
                  setToggleSuggestion(true);
                  props.Toggle_Suggestions({toggle: true});
                }
                onClick(searchValue);
              }
            }}
            onClick={() => {
              if(props.page===1){
                setToggleSuggestion(true);
                props.Toggle_Suggestions({toggle: true});
              }
              onClick(searchValue);
              // setToggleSuggestion(false);
            }}
          />
        }
      </SearchBoxWrapper>
      {autoSuggestion && (props.togglesuggestion) && props.page === 1 ? (
        <SearchResults
          suggestions={suggestions}
          clearSearch={onClearBtnClick}
          setSuggestionValue={(suggestion) => setSuggestion(suggestion)}
        />
      ) : null}
    </SearchWrapper>
  );
};

Search.defaultProps = {
  bordered: true,
  autoSuggestion: true,
  buttonText: "Search",
  buttonIcon: <SearchIcon />,
  inputStyle: {
    width: "100%",
  },
};

export default Search;
