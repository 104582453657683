import * as actionTypes from "../ActionTypes";

const initialState = {
  userData: [],
  success: false,
  showOtp: false,
  otpVerified: false,
  email: "",
  responsemsg: "",
  loginresponsemsg: "",
  resetmessage: "",
  passwordrequest: false
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.Auth_Login: {
      return {
        ...state,
        userData: action.payload,
        success: action.success,
        loginresponsemsg: action.responsemsg
      };
    }
    case actionTypes.Auth_Signup: {
      return {
        ...state,
        success: action.payload,
        responsemsg: action.responsemsg
      };
    }
    case actionTypes.OTP_Verify: {
      return {
        ...state,
        userData: action.payload,
        success: action.success,
        otpVerified: action.success,
      };
    }
    case actionTypes.Password_Request: {
      return {
        ...state,
        success: action.payload,
        showOtp: action.payload
      };
    }
    case actionTypes.Change_Logged_User_Password: {
      return {
        ...state,
        success: action.payload,
      };
    }
    case actionTypes.Not_Yet_Registered: {
      return {
        ...state,
        loginresponsemsg: action.payload
      }
    }
    case actionTypes.Reset_Response_Messages: {
      return {
        ...state,
        responsemsg: "",
        loginresponsemsg: "",
        passwordrequest: false,
        resetmessage: "",
        otpVerified: false,
      }
    }
    case actionTypes.Password_Request_Success: {
      return {
        ...state,
        passwordrequest: action.passwordrequest,
        email: action.email,
        resetmessage: action.error
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
