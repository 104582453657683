export const Loading_Enable = "Loading_Enable";
export const Loading_Disable = "Loading_Disable";
export const PLoading_Enable = "PLoading_Enable";
export const PLoading_Disable = "PLoading_Disable";
export const NLoading_Enable = "NLoading_Enable";
export const NLoading_Disable = "NLoading_Disable";
export const TLoading_Enable = "TLoading_Enable";
export const TLoading_Disable = "TLoading_Disable";
export const Auth_Login = "Auth_Login";
export const Auth_Signup = "Auth_Signup";
export const OTP_Verify = "OTP_Verify";
export const Password_Request = "Password_Request";
export const Password_Reset = "Password_Reset";
export const Offer_Slider = "Offer_Slider";

// PRODUCT
export const Product_Category = "Product_Category";
export const Products_Mappedwith_SubCat = "Products_Mappedwith_SubCat";
export const Products_Mappedwith_Cat = "Products_Mappedwith_Cat";
export const Products_Mappedwith_Cat_OutOfStock = "Products_Mappedwith_Cat_OutOfStock";
export const Search_Store_Product = "Search_Store_Product";
export const ProductModal = "ProductModal";
export const Out_Of_Stock = "Out_Of_Stock";
export const Product_Brands = "Product_Brands";
export const Product_Filter_Brands = "Product_Filter_Brands";
export const Product_Sort_Name = "Product_Sort_Name";
export const Product_Sort_Price = "Product_Sort_Price";
export const Filter_Brands = "Filter_Brands";
export const Filtered_Name_Products = "Filtered_products";
export const Show_Filtered_Name_Products = "Show_Filtered_Name_Products";
export const Suggested_SubCat = "Suggested_SubCat";
export const Product_Fetch="Product_Fetch";
export const Toggle_Suggestions="Toggle_Suggestions";
export const Promotion_Products="Promotion_Products";
export const Arrival_Products="Arrival_Products";
export const Trending_Products="Trending_Products";
export const Toggle_Categories="Toggle_Categories";
//BRANDS
export const Brand_Products = "Brand_Products";
export const All_Brands = "All_Brands";

//USER
export const Get_Address = "Get_Address";
export const Add_Address = "Add_Address";
export const Update_Address = "Update_Address";
export const Delete_Address = "Delete_Address";
export const Update_Profile = "Update_Profile";
export const Past_Orders = "Past_Orders";
export const Current_Orders = "Current_Orders";
export const Fetch_Particular_Order = "Fetch_Particular_Order";
export const Delivery_Slots = "Delivery_Slots";
export const Delivery_Slots_By_Date = "Delivery_Slots_By_Date";
export const Place_Order = "Place_Order";
export const Delivery_Charges = "Delivery_Charges";
export const Get_All_Coupons = "Get_All_Coupons";
export const Add_Item_To_Cart = "Add_Item_To_Cart";
export const Remove_Item_From_Cart = "Remove_Item_From_Cart";
export const Update_Item_Quantity_Cart = "Update_Item_Quantity_Cart";
export const Clear_Cart = "Clear_Cart";
export const Fetch_Cart = "Fetch_Cart";
export const INIT_BASKET = "INIT_BASKET";
export const Move_To_Checkout = "Move_To_Checkout";
export const Change_Order_Status = "Change_Order_Status";
export const Payment_Dialog_Close_Without_Payment =
  "Payment_Dialog_Close_Without_Payment";
export const Change_Logged_User_Password = "ChangeLoggedUserPassword";
export const Pay_By_Card = "Pay_By_Card";
export const Payment_Callback = "Pay_Callback";
export const Not_Yet_Registered = "Not_Yet_Registered";
export const Reset_Response_Messages = "Reset_Response_Messages";
export const Password_Request_Success = "Password_Request_Success";
export const Reorder = "Reorder";
export const Reset_Reorder_Success = "Reset_Reorder_Success";
export const OOS_Option = "OOS_Option";
export const Reset_Products = "Reset_Products";
export const Reset_Suggestions = "Reset_Suggestions";
export const Change_Page = "Change_Page";
export const Reset_Page = "Reset_Page";
export const Set_All_Slots = "Set_All_Slots";



// Dashboard 
export const SET_LOADING = "SET_LOADING"
export const SET_SUCCESS = "SET_SUCCESS"
export const SET_ERROR = "SET_ERROR"
export const GET_BRANDS = "GET_BRANDS"
export const ADD_BRANDS = "ADD_BRANDS"
export const UPDATE_BRANDS = "UPDATE_BRANDS"
export const GET_CATEGORY = "GET_CATEGORY"
export const ADD_CATEGORY = "ADD_CATEGORY"
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY"
export const ADD_SUB_CATEGORY = "ADD_SUB_CATEGORY"
export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY"
export const GET_PRODUCTS = "GET_PRODUCTS"
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS"
export const GET_SUPPLIERS = "GET_SUPPLIERS"
export const INIT_SUPPLIER_ORDERS = "INIT_SUPPLIER_ORDERS";
export const UPDATE_SUPPLIER_ORDERS = "UPDATE_SUPPLIER_ORDERS";
export const INIT_SUPPLIERS_LISTS = "INIT_SUPPLIERS_LISTS";
export const UPDATE_SUPPLIERS_LISTS = "UPDATE_SUPPLIERS_LISTS";
export const GET_SUB_CATEGORY_BY_CATEGORY = "GET_SUB_CATEGORY_BY_CATEGORY"
export const ADD_PRODUCTS = "ADD_PRODUCTS"
export const UPDATE_PRODUCTS = "UPDATA_PRODUCTS"
export const GET_PRODUCTS_BY_ID = "GET_PRODUCTS_BY_ID"
export const GET_ORDERS = "GET_ORDERS"
export const UPDATE_ORDERS = "UPDATE_ORDERS";

// Express
export const EXPRESS_STORES = "EXPRESS_STORES"
export const EXPRESS_STORE_SELECTED = "EXPRESS_STORE_SELECTED"
export const EXPRESS_STORE_PRODUCTS = "EXPRESS_STORE_PRODUCTS"
export const EXPRESS_STORE_SLIDERS = "EXPRESS_STORE_SLIDERS"
export const EXPRESS_EXTRA_BRANDS = "EXPRESS_EXTRA_BRANDS"

// Wallet
export const INIT_WALLET = "INIT_WALLET"