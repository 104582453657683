import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import defaultMissingImage from '../../assets/images/product-placeholder.png';

import {
  initCategoryExpressStore,
  sortProducts
} from "../../store/actions/productAction";

import {
  initExpressStoreProducts,
  initExpressStores, initExpressStoreSelected,
  initExpressStoreSliders,
} from '../../store/actions/expressAction';

import {useDeviceType} from "../../helper/useDeviceType";
// Cart management component
import CartPopUp from "../../containers/Cart/CartPopUp";
import {useCart} from "../../contexts/cart/use-cart";

import expressService from "../../services/expressService";
import {initBasket} from "../../store/actions/cartAction";
import CustomCarousel from "../../components/Carousel/Carousel";
import { SupplierList } from "./express.style";
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import jsUtility from '../../services/jsUtility';

export const SpinnerCustom = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: '-3rem',
  marginLeft: '-1rem',
  color: '#f01266',
}

const Express = (props, { userAgent }) => {

  const { fetchOrderItems, cartclose } = useCart();
  const user = localStorage.getItem('user');
  const deviceType = useDeviceType(userAgent);
  const history = useHistory();
  const [defaultSetting, setDefaultSetting] = useState(null);
  const [supplierHeading, setSupplierHeading] = useState(null);

  // Clear basket when close windows
  window.onbeforeunload = () => cartclose();

  useEffect(async () => {
    const expressStatus = await expressService.getExpressStatus();
    const shopSettingResp = await expressService.getShopSetting();

    if(shopSettingResp.status === 200) {
      setDefaultSetting(shopSettingResp.data);
    }

    props.initExpressStoreSelected(null);
    if(expressStatus.status === 200 && !expressStatus.data.data.enable_express) {
      window.location.href = '/';
    }
  }, []);

  // Is logged in and init Express mode
  useEffect(() => {
    document.getElementsByClassName("product-cart")[0].style.visibility = "hidden";
    localStorage.setItem('basketType', 'express');
    
    props.initBasket('express');
    localStorage.setItem('expressMode', true);
    props.sortProducts([]);
    props.initProductExpressStore([]);
    props.initCategoryExpressStore({data: []});

    if(user) {
      fetchOrderItems('express');
    }
  }, [user]);

  // Init Express Mode
  useEffect( async () => {
    if(!props.stores.length) {
      const storesResp = await expressService.expressStores() || [];
      const storeItems = getExpressStore(storesResp);
      const carouselItems = getExpressCarousel(storesResp);

      props.initExpressStores(storeItems);
      props.initExpressStoreSliders(carouselItems);
    }
  }, [props.stores]);

  const getExpressCarousel = (data) => {
    const stores = data.find((item) => item.type === 'carousel');
    return stores.data.map(item => {
      return {
        slider_id: item.shopId,
        image_link: item.preview,
        title: item.type,
        description: item.type,
        is_active: 1,
        web_link: item.redirect,
        app_link: item.redirect,
        store_id: item.shopId,
        is_deleted: 0,
        order: null,
        type: item.type,
      }
    });
  };

  const getExpressStore = (data) => {
    const stores = data.find((item) => item.type === 'supplier');
    // Set express heading title
    setSupplierHeading(stores.title);
    
    return stores.data.map(item => {
      return {
        address: item.address || 'n/a',
        brand_id: item._id,
        name: item.name,
        brand_ref_id: item.name.replace(' ', '-').toLowerCase(),
        description: item.description,
        image: item.supplierLogo,
        logo: item.supplierLogo,
        estimateDeliveryMinute: item.estimateDeliveryMinute,
        isShopOpen: item.isShopOpen,
        nextAvailableDay: item.nextAvailableDay,
        is_available: 1,
      }
    });
  };

  const handleStoreSelected = (item = null) => {
    props.initExpressStoreSelected(item);
    history.push(`/express/store/${item.brand_id}`, item);
  };

  return (
    <>
      <CartPopUp deviceType={deviceType} />

      <CustomCarousel deviceType={deviceType} props={props} />

      <SupplierList>
        <h1 className="title">{ supplierHeading }</h1>

        <div className="list">
          {props.stores.length
            ? props.stores.map( item =>
              <div key={item.brand_id} className="item-wrapper">
                <div className={`items ${!item.isShopOpen ? 'shop-is-closed' : ''}`}>
                  <div className="est-box">
                    <div className="number">{ item.estimateDeliveryMinute || defaultSetting?.defaultEstimateDeliveryMinute }</div>
                    <div className="text">MIN</div>
                  </div>
                  <Link to={`/express/store/${item.brand_id}`} className="media">
                    <Image src={ item?.image || defaultMissingImage }
                           className={jsUtility.imageOrientation(item?.image)}
                           onError={(e)=>{
                             e.target.onerror = null;
                             e.target.src = defaultMissingImage
                           }}
                    />
                    {!item.isShopOpen && (
                      <span>{ item.nextAvailableDay }</span>
                    )}
                  </Link>
                  <div className="content">
                    <h3 className="content-title">
                      <Link to={`/express/store/${item.brand_id}`}>{ item.name }</Link>
                    </h3>
                    <p className="content-description">{ item.address }</p>
                  </div>
                </div>
              </div>
            )
            : <Spinner animation="grow" style={SpinnerCustom} />
          }
        </div>
      </SupplierList>
    </>
  )
};

Express.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};

const mapStateToProps = (state) => {
  return {
    stores: state.expressReducer.stores,
    storeSelected: state.expressReducer.storeSelected,
    sliders: state.expressReducer.sliders,
    filterBrands: state.productReducer.filterBrands,
    cartType: state.cartReducer.cartType,
    products: state.expressReducer.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initExpressStores: (data) => initExpressStores(data, dispatch),
    initExpressStoreSelected: (data) => initExpressStoreSelected(data, dispatch),
    initExpressStoreSliders: (data) => initExpressStoreSliders(data, dispatch),
    initBasket: (data) => initBasket(data, dispatch),
    initCategoryExpressStore: (data) => initCategoryExpressStore(data, dispatch),
    sortProducts: (data) => sortProducts(data, dispatch),
    initProductExpressStore: (data) => initExpressStoreProducts(data, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Express);
