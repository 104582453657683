import styled from "styled-components";
import css from "@styled-system/css";
import { themeGet } from "@styled-system/theme-get";
import Inputs from "../../../components/Input/Input";
export const ItemBox = styled.div(
  css({
    fontSize: 2,
    fontWeight: 6,
    py: 15,
    px: 25,
    borderBottom: `1px solid`,
    borderBottomColor: "lightColor",
  }),
  {
    display: "flex",
    alignItems: "center",
  }
);
export const Information = styled.div({
  display: "flex",
  flexDirection: "column",
  marginLeft: "15px",
});
export const BannerImageContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  marginLeft: "5px"
});
export const Banner = styled.span({
  paddingTop: "3px",
  textAlign: "center",
  fontSize: "12px",
  lineHeight: "12px",
  "&.express.bg-success": {
    backgroundColor: "#f01266 !important",
  },
  "&.bg-warning": {
    backgroundColor: "#f78205 !important",
  }
});
export const Image = styled.img({
  width: 60,
  height: 60,
  objectFit: "cover",
  margin: "0 15px",
});
export const Name = styled.span(
  css({
    fontWeight: 3,
    color: "darkBold",
    mb: "0px",
    lineHeight: 1.5,
  })
);
export const Price = styled.span(
  css({
    color: "primary",
    mt: "10px",
    mb: "10px",

    "&.express": {color: "#f01266"},
  })
);
export const Weight = styled.span(
  css({
    fontSize: 1,
    fontWeight: 3,
    color: "darkRegular",
    mb: "5px",
  })
);
export const Total = styled.span(
  css({
    color: "darkBold",
    ml: "auto",
  })
);

export const RemoveButton = styled.button({
  padding: "5px",
  border: 0,
  outline: 0,
  marginLeft: "15px",
  cursor: "pointer",
  color: "#ea4d4a",
  transition: "all 0.4s ease",
  backgroundColor: "transparent",
  // backgroundColor: "#389244",
  borderRadius: "50%",
  lineHeight: "7px",
  "&:hover": {
    color: "#ea4d4a",
  },
});

export const EditButton = styled.button({
  padding: "5px",
  border: 0,
  outline: 0,
  marginLeft: "15px",
  cursor: "pointer",
  color: "#389244",
  transition: "all 0.4s ease",
  borderRadius: "50%",
  lineHeight: "7px",
  backgroundColor: "transparent",
  // backgroundColor: "#389244",

  "&:hover": {
    color: "#389244",
  },
});

export const Input = styled(Inputs)`
  width: 50%;
  margin-right: 20px;
  input {
    border: 1px solid ${themeGet("colors.lightDarkColor", "#e6e6e6")} !important;
    background-color: ${themeGet("colors.lightColor", "#f7f7f7")};
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Errormsg = styled.p({
  color: "red",
});
