import React from "react";
import styled from "styled-components";
import { Modal } from "@redq/reuse-modal";
import SiteFooter from "../components/SiteFooter/SiteFooter";
import Accordion from "../components/Accordion/Accordion";
import { FormattedMessage } from "react-intl";

const accor = [
  {
    id: 1,
    intlTitleId: "faqNo1Title",
    intlDetailsId: "faqNo1Desc",
    header: "hele"
    // values: { number1: 7, number2: 2 },
  },
  {
    id: 2,
    intlTitleId: "faqNo2Title",
    intlDetailsId: "faqNo2Desc",
  },
  {
    id: 3,
    intlTitleId: "faqNo3Title",
    intlDetailsId: "faqNo3Desc",
  },
  {
    id: 4,
    intlTitleId: "faqNo4Title",
    intlDetailsId: "faqNo4Desc",
  },
];

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 130px 0 60px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  @media (min-width: 990px) {
    width: 870px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 30px;
  }
`;

export default function Help() {
  return (
    <Modal>
      <HelpPageWrapper>
        <HelpPageContainer>
          <Heading>F.A.Q</Heading>
          <b>What is Delishop?</b>
          <div className="answer">
          <p>Delishop is the first website offering all your need in grocery market with a home delivery service.</p>
          <p>Check our&nbsp;<a title="About us" href={window.location.protocol + "//" + window.location.hostname + "/about"} target="_self">About us</a>&nbsp;page for more information&nbsp;</p>
          </div>
          <br />
          <b>How do I sign up?</b>
          <div className="answer">
          <p>Signing up is quick and simple. Just fill out our&nbsp;<a title="Login" href={window.location.protocol + "//" + window.location.hostname + "/profile"} target="_self">short registration form</a>&nbsp;with your name, address, pint you on our google map, e-mail and password, then start getting great food delivered to your door as soon as tomorrow.</p>
          </div>
          <br />
          <b>How do I place an order at Delishop?</b>
          <div className="answer">
          <p>&bull;&nbsp;<a title="Login" href={window.location.protocol + "//" + window.location.hostname + "/profile"} target="_self">Sign up to create an account.</a></p>
          <p>&bull; To find groceries, simply browse our departments or use the search box to seek out specific brands or items.</p>
          <p>&bull; When you find what you want, select a quantity, then click the green "Add to Cart" button.</p>
          <p>&bull; Once you've found everything you want, go to Checkout. Select your delivery address and a delivery timeslot.</p>
          <p>&bull; After you finish checkout and submit your order, we'll send you an e-mail confirming your order details.</p>
          <p>&bull; On your next order, you will get the possibility to reorder the same product very quickly.</p>
          </div>

          <br />
          <b>What kind of food does Delishop sell?</b>
          <div className="answer">
          <p>The Delishop shelves are stocked with more than 7,000 high-quality products in a wide range of categories including fresh, organic and all-natural foods, a unique selection of deli items and a full selection of popular beverages to make your everyday life easier.</p>
          </div>

          <br />
          <b>When does Delishop deliver?</b>
          <div className="answer">
          <p>Your Delishop is delivering 6 days a week from 10am to 8pm. Any order made on the website will be delivered 20h later. You can choose a 1-hour delivery time slot during the checkout process.</p>
          <p>Concerning the Delivery the same day : You have to place your order before 1PM, and ONLY the products with the mention "GET IT TODAY" are elligible. If there is some item in your cart without this menion, you can not validate the delivery the same day. Delete it from your cart if you need a delivery the same day.</p>
          </div>

          <br />
          <b>Where does Delishop deliver?</b>
          <div className="answer">
          <p>We currently deliver all district in Phnom-Penh, please check&nbsp;<a title="Delivery" href={window.location.protocol + "//" + window.location.hostname + "/delivery/"} target="_self">the delivery map</a>&nbsp;for more information.</p>
          <p>Our delivery crew will bring your order at your doorstep. Please make sure the delivery man can get into the building if you live in an apartment and feel free&nbsp;<a title="Contact us" href={window.location.protocol + "//" + window.location.hostname + "/contact"} target="_self">to contact us</a>&nbsp;to know if we can deliver in your area.</p>
          </div>

          <br />
          <b>Where is my order?</b>
          <div className="answer">
          <p>Your orders should be delivered by Delishop within the timeslot you select. In case of unforeseen events that cause your order to be late, you will receive a call from our customer service team. If you'd like a status update for your order, feel free to&nbsp;<a title="Contact us" href={window.location.protocol + "//" + window.location.hostname + "/contact"} target="_self">contact us</a>&nbsp;at any time.</p>
          </div>

          <br />
          <b>I did not receive an invoice with my delivery. How can I see if my order is complete and correct?</b>
          <div className="answer">
          <p>You can view or print a copy of your order invoice online by logging into My Account and clicking into My Orders to view and print an invoice.</p>
          </div>

          <br />
          <b>What should I do if I didn't receive everything I ordered?</b>
          <div className="answer">
          <p>While rare, mistakes occasionally happen, so if an item is missing from your order, send us an&nbsp;<a title="Contact us" href={window.location.protocol + "//" + window.location.hostname + "/contact"} target="_self">email&nbsp;</a>or call our customer service : 081 999 405.</p>
          </div>

          <br />
          <b>How can I pay for my order?</b>
          <div className="answer">
          <p>Payment must be made in cash, in USD or Riels. The minimum order is $10. When you place an order, select a convenient delivery time slot for you.</p>
          <p>Delivery fees apply as follows:&nbsp;</p>
          <ul>
          <li><strong>From $10 to $50</strong>&nbsp;: &nbsp; 0.5/km</li>
          <li><strong>From $50 and above</strong>&nbsp;: &nbsp; Free Delivery</li>
          </ul>
          </div>

          <br />
          <b>Should I tip my delivery man?</b>
          <div className="answer">
          <p>You are under no obligation to tip, but have the option of providing a nominal tip if you feel that you've received exceptional service. Delishop personnel are not permitted to solicit tips under any circumstances. If you want to leave a comment, a compliment or a suggestion, please<a title="Contact us" href={window.location.protocol + "//" + window.location.hostname + "/contact"} target="_self">&nbsp;use our contact page</a>. We'd love to hear from you!</p>
          </div>

          <br />
          <b>How can I share feedback about Delishop?</b>
          <div className="answer">
          <p>We are always interested to hear from you about the service you&rsquo;ve received. Please&nbsp;<a title="Contact us" href={window.location.protocol + "//" + window.location.hostname + "/contact"} target="_self">send us an e-mail</a>&nbsp;to detail your experience, or complete the check-out our staff will give you.</p>
          </div>

          <br />
          <b>I'd like to suggest some products. Who should I contact?</b>
          <div className="answer">
          <p>It's easy to suggest items you'd like to see at Delishop! Send&nbsp;<a title="Contact us" href={window.location.protocol + "//" + window.location.hostname + "/contact"} target="_self">us a message</a>. We'd love to hear from you!</p>
          </div>

          <br />
          <b>How can I edit my Delishop profile?</b>
          <div className="answer">
          <p>Log in with your email and your password info and go to your &nbsp;<a title="Login" href={window.location.protocol + "//" + window.location.hostname + "/profile"} target="_self">Account</a>, then select&nbsp;Profile tab. You can choose to edit your account information</p>
          </div>

          <br />
          <b>What if I forget my password?</b>
          <div className="answer">
          <p>No worries! Just&nbsp;<a title="Forgot password" href={window.location.protocol + "//" + window.location.hostname + "/profile"} target="_self">click here</a>&nbsp;and select 'Forgot your password? Reset' in the login dialog . Enter your email address to receive and OTP and fill in the OTP to change your password and start shopping again.</p>
          </div>

          <br />
          <b>Is Delishop secure?</b>
          <div className="answer">
          <p>Yes. We take every precaution to protect your privacy and to prevent misuse of the private information you provide us.&nbsp;Please&nbsp;<a title="Privacy policy" href={window.location.protocol + "//" + window.location.hostname + "/privacy-policy-cookie-restriction-mode"} target="_self">refer to our privacy policy</a>&nbsp;for more information.&nbsp;</p>
          </div>

          <br />
          <b>Will my information be kept private?</b>
          <div className="answer">
          <p>We use your personal information&nbsp;only&nbsp;to process orders, enhance your shopping experience and keep you informed about Delishop offers and discounts. We will not release your personal information to any third party.</p>
          </div>

          <br />
          <b>Does Delishop use cookies?</b>
          <div className="answer">
          <p>Like many websites, ours uses "cookies" to speed up your shopping experience. A cookie is a piece of data stored on your computer's hard drive. It allows our website to identify you, but cannot be used to access sensitive account information. You&nbsp;will always have to log in with your user name and your password to place an order. Please note that if you disable your browser from accepting cookies, some features of the site may be disabled.</p>
          </div>

          <br />
          <b>How can I find out more about Delishop?</b>
          <div className="answer">
          <p>For more information,&nbsp;<a title="About us" href={window.location.protocol + "//" + window.location.hostname + "/about"} target="_self">read our About Us page</a>&nbsp;and follow our facebook page :&nbsp;<a title="Facebook" href="https://www.facebook.com/delishop.asia?ref=bookmarks" target="_blank" rel="noopener noreferrer">Delishop Asia</a></p>
          </div>
        </HelpPageContainer>
      </HelpPageWrapper>
    </Modal>
  );
}
