import React from "react";
import { useSelector } from 'react-redux';
import { SearchBox, SearchButton, SearchInputWrapper } from "./SearchBox.style";
import { FormattedMessage } from "react-intl";
import t from '../../i18n/translate';

const InputSearch = ({
  type,
  value,
  buttonText,
  buttonIcon,
  onChange,
  style,
  onKeyPress,
  onBlur,
  onClick,
  onFocus,
  bordered,
  showSvg,
  inputClass,
}) => {
  const cartType = useSelector(state => state.cartReducer.cartType);

  return (
    <>
      <SearchInputWrapper
        style={style}
        bordered={bordered}
        showSvg={showSvg}
        className={`${inputClass} ${bordered === true ? "bordered" : ""}`}
      >
        <form className="searchform"
          onSubmit={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {showSvg && (
            <span className="searchIcon" onClick={onClick}>
              {buttonIcon}
            </span>
          )}
          <SearchBox
            className="SearchBox"
            type={type}
            value={value}
            placeholder={ t('search_home_screen_input_hint', false) }
            onChange={onChange}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
          />
          {showSvg !== true ? (
            <SearchButton className={cartType} onClick={(e) => {
              e.preventDefault();
              onClick();
              }}>
              {buttonIcon}
            </SearchButton>
          ) : (
            ""
          )}
        </form>
      </SearchInputWrapper>
    </>
  );
};
export default InputSearch;
