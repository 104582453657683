import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@redq/reuse-modal";
import Button from 'react-bootstrap/Button';
import { AuthContext } from "../../contexts/auth/auth.context";
import "react-datetime/css/react-datetime.css";
import { Col, Row, Card, Form, Image, InputGroup, FormControl, Spinner } from 'react-bootstrap/';
import {updateCategory,setError,setLoading} from '../../store/actions/dashboardAction'
import UploadService from "../../services/FileUploadService";
import { connect } from "react-redux";
import API from "../../network/dashboardAPI"
import ToastNotification from "../../components/ToastNotification/ToastNotification";

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const ImageLoading = styled.div`
  position: relative;
  
  .hide {display: none;}
  .isLoading {
    position: absolute;
    background-color: rgb(255,255,255,0.4);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const UpdateCategory = (props) => {

  const { authDispatch } = useContext(AuthContext);
  let admin = localStorage.getItem("admin");
  if(!admin) {
    window.location.href = "/";
  }

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [homeBanner, setHomeBanner] = useState(null);
  const [homepageLink, setHomepageLink] = useState("");
  const [checkoutBanner, setCheckoutBanner] = useState(null);
  const [checkoutLink, setCheckoutLink] = useState("");
  const [showToast, setShowToast] = useState(false);

  // Ref
  const _homeBannerInputFile = React.useRef(null);
  const _homeLinkInputRef = React.useRef(null);
  const _checkoutBannerInputFile = React.useRef(null);
  const _checkoutLinkInputRef = React.useRef(null);

  useEffect(() => {
    API.getBannerImages().then(res => res.json())
      .then((res) => {
        if(res.data.length) {
          setHomeBanner(res.data[0].homepage);
          setHomepageLink(res.data[0].homepageLink);
          setCheckoutBanner(res.data[0].checkout);
          setCheckoutLink(res.data[0].checkoutLink);
        }
      })
  },[]);

  const hideShowLoading = (className = null) => {
    let loadingEl;

    document.getElementsByClassName("home-banner-loading")[0].classList.remove('isLoading');
    document.getElementsByClassName("home-banner-loading")[0].classList.add('hide');

    document.getElementsByClassName("checkout-banner-loading")[0].classList.remove('isLoading');
    document.getElementsByClassName("checkout-banner-loading")[0].classList.add('hide');

    if(className !== null) {
      loadingEl = document.getElementsByClassName(className);
      if(loadingEl.length) {
        loadingEl[0].classList.add('isLoading');
        loadingEl[0].classList.remove('hide');
      }
    }
  };

  const closeToast = (eventData) => setShowToast(eventData);

  const handleInputChange = () => {
    setIsChange(true);
    setHomepageLink(_homeLinkInputRef.current.value);
    setCheckoutLink(_checkoutLinkInputRef.current.value);
  };

  const handleFileChange = async (action) => {
    try {
      setIsChange(true);
      let formData = null;
      hideShowLoading();

      if(_homeBannerInputFile.current !== null && action === "home") {
        formData = new FormData();
        formData.append("image", _homeBannerInputFile.current.files[0]);

        hideShowLoading("home-banner-loading");
        await UploadService.uploadImageFromDashboard(formData).then(response => {
          if(response.data.success && response.data.data.length) {
            setHomeBanner(response.data.data[0].url);
          }
          hideShowLoading();
        });
      }
      if(_checkoutBannerInputFile.current !== null && action === "checkout") {
        formData = new FormData();
        formData.append("image", _checkoutBannerInputFile.current.files[0]);

        hideShowLoading("checkout-banner-loading");
        await UploadService.uploadImageFromDashboard(formData).then(response => {
          if(response.data.success && response.data.data.length) {
            setCheckoutBanner(response.data.data[0].url);
          }
          hideShowLoading();
        });
      }
    } catch(e) {
      console.log("Debug: ", e);
      hideShowLoading();
    }
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      API.updateBanner({
          homepage: homeBanner,
          homepageLink: _homeLinkInputRef.current.value,
          checkout: checkoutBanner,
          checkoutLink: _checkoutLinkInputRef.current.value
        })
        .then(res => res.json())
        .then(res => {
          setTimeout(() => {
            setShowToast(true);
            setIsLoading(false);
            setIsChange(false);
          }, 250)
        })
    } catch(e) {
      console.log(e);
      setIsLoading(false);
    }
  };
   
  return (
    <HelpPageWrapper>
      <Row>
        <Col sm={11}>
          <h4 className="mb-3">Banner Image update</h4>

          <Card>
            <Card.Body>
              <Form>
                <>
                  <div id="homeBanner" className="mb-5">
                    <Card.Title>Home Page Banner(Aspect Ratio 15:2)</Card.Title>
                    <Form.File
                      ref={_homeBannerInputFile}
                      id="custom-file-translate-scss"
                      label={homeBanner || "Select file"}
                      lang="en"
                      className="mb-2"
                      custom
                      onChange={() => handleFileChange("home")}
                    />
                    <ImageLoading>
                      <div className="home-banner-loading hide">
                        <Spinner animation="grow" />
                      </div>
                      <Image src={homeBanner}
                             rounded
                             style={{
                               width: "100%",
                               height: "auto"
                             }}
                      />
                    </ImageLoading>
                    <InputGroup className="mt-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="homeLink">
                          Banner link
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl id="home-link"
                                   ref={_homeLinkInputRef}
                                   value={homepageLink}
                                   aria-describedby="home-link"
                                   onChange={handleInputChange}
                      />
                    </InputGroup>
                  </div>

                  <div id="checkoutBanner">
                    <Card.Title>Checkout Banner(Aspect Ratio 7:10)</Card.Title>
                    <Form.File
                      id="custom-file-translate-scss"
                      ref={_checkoutBannerInputFile}
                      label={checkoutBanner || "Select file"}
                      lang="en"
                      className="mb-2"
                      custom
                      onChange={() => handleFileChange("checkout")}
                    />
                    <ImageLoading>
                      <div className="checkout-banner-loading hide">
                        <Spinner animation="grow" />
                      </div>
                      <Image src={checkoutBanner}
                             rounded
                             style={{
                               width: "auto",
                               height: "100%",
                               maxHeight: "400px"
                             }}
                      />
                    </ImageLoading>
                    <InputGroup className="mt-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="checkoutLink">
                          Banner link
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl id="checkout-link"
                                   ref={_checkoutLinkInputRef}
                                   value={checkoutLink}
                                   aria-describedby="checkout-link"
                                   onChange={handleInputChange}
                      />
                    </InputGroup>
                  </div>
                </>
              </Form>
            </Card.Body>
            <Card.Footer>
              {(!isLoading)
                ? <Button variant="primary" disabled={!isChange} onClick={onSubmit}>Save</Button>
                : <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Saving...
                </Button>
              }
            </Card.Footer>
          </Card>

          <ToastNotification showToast={showToast} onClose={closeToast} />
        </Col>
      </Row>
    </HelpPageWrapper>
  );
}

const mapStateToProps = state =>{
    return {
     success : state.dashboardReducer.success,
     loading : state.dashboardReducer.loading,
     error_message : state.dashboardReducer.error_message
    }
   
  }
  
const mapDispatchToProps = dispatch => {
    return {
      updateCategory : data => updateCategory(data,dispatch),
      setError : data => setError(data,dispatch),
      setLoading : data => setLoading(data,dispatch)
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(UpdateCategory)
