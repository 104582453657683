import React from "react";
export const Basket = ({
    color = "currentColor",
    width = "12px",
    height = "12px",
    cartType,
  }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 31.54 31.53">
             <path id="Path_683" data-name="Path 683" d="M31.54,12.566a2.6,2.6,0,0,0-2.672-2.681c-.656-.006-1.312.017-1.966-.013a.831.831,0,0,1-.536-.253c-1.6-1.861-3.19-3.738-4.783-5.609C20.6,2.8,19.582,1.586,18.54.387a.978.978,0,0,0-1.607.095,1.026,1.026,0,0,0,.192,1.278l6.584,7.75c.084.1.163.2.279.348H7.632c.083-.119.132-.2.193-.274Q10.64,6.271,13.457,2.96c.368-.432.741-.858,1.1-1.3A.986.986,0,1,0,13.07.365c-.1.1-.185.206-.275.312Q9,5.143,5.213,9.612a.71.71,0,0,1-.616.275c-.681-.014-1.362-.02-2.042,0A2.6,2.6,0,0,0,.012,12.466c-.007.428-.008.857,0,1.286A2.534,2.534,0,0,0,1.676,16.1a.68.68,0,0,1,.5.6q1.183,6.1,2.394,12.2a3.249,3.249,0,0,0,3.281,2.684q8.169.006,16.337,0a3.161,3.161,0,0,0,3.2-2.659q1.069-6.183,2.091-12.374a.459.459,0,0,1,.348-.418,2.567,2.567,0,0,0,1.756-2.482C31.591,13.3,31.59,12.931,25.3,12.566ZM9.219,18.776a1,1,0,0,1,1.153.921q.487,3.1.96,6.2c.014.087.018.175.024.234a1,1,0,0,1-.86,1.02A.959.959,0,0,1,9.4,26.333c-.179-1.029-.328-2.064-.488-3.1-.166-1.07-.328-2.141-.492-3.21A1.017,1.017,0,0,1,9.219,18.776Zm5.594,7.289q-.008-3.1,0-6.194a.995.995,0,1,1,1.974.02c0,1.02,0,2.039,0,3.059s0,2.065,0,3.1a.992.992,0,1,1-1.972.019Zm8.372-6.087q-.477,3.117-.962,6.233a.991.991,0,0,1-1.073.939,1.007,1.007,0,0,1-.9-1.107c.121-.788.255-1.659.39-2.529.2-1.281.391-2.563.6-3.844a.992.992,0,0,1,1.138-.9A1,1,0,0,1,23.185,19.979Z"
                   transform="translate(-0.006 -0.012)"
                   fill={cartType === 'express' ? '#f01266' : "#707070"}
             />
        </svg>
    );
  };