import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import {
  Button,
  Wrapper,
  Container,
  LogoWrapper,
  Heading,
  SubHeading,
  HelperText,
  Offer,
  Input,
  Divider,
  LinkButton,
} from "./SignInOutForm.style";
import { AuthContext } from "../../contexts/auth/auth.context";
import { FormattedMessage } from "react-intl";
// import Image from 'components/Image/Image';
// import PickBazar from '../../image/PickBazar.png';
import { connect } from "react-redux";
import { doPasswordChange, resetmessages } from "../../store/actions/authAction";
import * as Constants from "../../constants/Constant";

const ResetPassModal = (props) => {
  const { authDispatch, authState: { isAuthenticated } } = useContext(AuthContext);
  const [OTP, setOTP] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [valid, setValid] = React.useState(false);
  const toggleSignInForm = () => {
    authDispatch({
      type: "SIGNIN",
    });
  };
  const resetPassCallback = (e) => {
    e.preventDefault();
    if (!valid) {
      props.doPasswordChange({
        email: props.email ,
        password: password,
        otp: parseInt(OTP),
      });
    }
  };
  useEffect(() => {
    return () => {
      props.resetmessages();
    }
  },[]);

  useEffect(() => {
    if (props.success) {
      // authDispatch({ type: 'SIGNIN_SUCCESS' });
    }
  }, [props.success]);

  const validatePassword = async () => {
    console.log(valid);
    if (password === confPassword) {
      setValid(false);
    } else {
      setValid(true);
    }
  };
  return (
    <Wrapper>
      <Container>
        {/* <LogoWrapper>
          <Image url={PickBazar} />
        </LogoWrapper> */}

        <Heading className={props.cartType}>
          <FormattedMessage
            id="signUpBtnText"
            defaultMessage="Reset Password"
          />
        </Heading>

        <SubHeading>
          <FormattedMessage
            id='signUpText'
            defaultMessage='Check your email for the OTP'
          />
        </SubHeading>
        <form autoComplete="chrome-off" onSubmit={resetPassCallback}>
          <div>
            <OtpInput
              value={OTP}
              onChange={(otp) => {
                if(parseInt(otp)){
                  setOTP(otp);
                }
              }
              }
              numInputs={4}
              separator={<span style={{ margin: "0 10px" }}>-</span>}
              inputStyle={{ padding: 5, width: "30px" }}
              containerStyle={{ padding: 15, justifyContent: "center" }}
            />
          </div>
          <FormattedMessage id="passwordPlaceholder" defaultMessage="Password">
            {(placeholder) => (
              <Input
                type="password"
                className={props.cartType}
                placeholder={placeholder}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            )}
          </FormattedMessage>

          <FormattedMessage
            id="confirmPasswordPlaceholder"
            defaultMessage="Confirm Password"
          >
            {(placeholder) => (
              <Input
                type="password"
                className={props.cartType}
                placeholder={placeholder}
                value={confPassword}
                onChange={(e) => {
                  setConfPassword(e.target.value);
                }}
                onBlur={validatePassword}
              />
            )}
          </FormattedMessage>
          {valid == true ? (
            <span className="text-danger">Password didn't match </span>
          ) : null}

          <Button
            fullwidth
            type="submit"
            title={"Continue"}
            intlButtonId="continueBtn"
            style={{ color: "#fff", marginBottom: 30 }}
          />
          {/* <span style={{color: "black", marginBottom: "10px"}} >Entered wrong email for reset password? <span onClick={(e) => {
          e.preventDefault();
          authDispatch({
            type: "FORGOTPASS",
          });
          }} 
          style={{color: "green", textDecoration: "underline", fontWeight: "700", cursor: "pointer"}}>
            Click here
            </span>
          </span>
          <br/>
          <br/> */}
        </form>
      </Container>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    email: state.authReducer.email,
    cartType: state.cartReducer.cartType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doPasswordChange: (body) => doPasswordChange(body, dispatch),
    resetmessages: () => resetmessages(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassModal);
