import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { BrandWrapper, BrandImageWrapper, BrandName} from "./Brand.style";

const Brand = ({
    item,
    handleSelection,
    homePage = false,
    className = ''
  }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <BrandWrapper style={{ border: "none"}} onClick={handleSelection}>
            <BrandImageWrapper>
                <LazyLoad height={140}>
                    <img
                    src={item.image !== null ? item.image : require('../../image/Background.png')}
                    className={`product-image ${className}`}
                    style={{ position: "relative", height: "auto", width:"100%" }}
                    alt={"Brand"}
                    onError={(e)=>{
                        e.target.onerror = null;
                        e.target.src = require('../../image/Background.png');
                    }}
                    width={"70"}
                    height={"70"}
                    // onLoad={() => setLoaded(true)}
                    // onLoad={()=> {return <Loader />}}
                    />
                </LazyLoad>
            </BrandImageWrapper>
            {!homePage && <BrandName>{item.name}</BrandName>}
        </BrandWrapper>
    );
  };
  
  export default Brand;
  