import React,{useState, useEffect, useRef} from "react";
import { Plus, Minus } from "../../assets/icons/plus-minus";
import { Tick } from "../../assets/icons/tick";
import { CounterBox, CounterButton, CounterValue } from "./QuantityModifier.style";
import { InputGroup, FormControl } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

export const QuantityModifier = ({
  onDecrement,
  onIncrement,
  onUpdate,
  value,
  variant,
  className,
  id,
  promoProduct = false,
  isLoading = false,
}) => {
  const [val, setVal] = useState(value);
  const [inputMode, setInputMode] = useState(false);
  const counterRef = useRef(null);
  const tickRef = useRef(null);
  const handleClickOutside = (event) => {
    if (counterRef.current && !counterRef.current.contains(event.target) && tickRef.current && !tickRef.current.contains(event.target)) {
        setVal(value);
        setInputMode(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleIncreaseButton = (e) => {
    if(!inputMode){
      onIncrement(e);
      setVal(val+1);
    }
    else{
      setInputMode(false);
      setVal(val);
      onUpdate(val);
    }
  }

  const handleDecreaseButton = (e)=>{
    if(!inputMode){
      onDecrement(e); setVal(val-1);
    }
  }

  return (
    <CounterBox variant={promoProduct ? "promohorizontal" : variant} className={`box-wrapper ${className}`} ref={counterRef}>
      <CounterButton disabled={isLoading} className={`btn-count ${className}`} onClick={handleDecreaseButton} variant={variant}>
        {isLoading
          ? <Spinner animation="border" role="status" size="sm">
            <span className="sr-only">Loading...</span>
          </Spinner>
          : !inputMode && <Minus />
        }
      </CounterButton>
      <CounterValue className="btn-input">
        <InputGroup size={promoProduct ? "lg" : "sm"}>
          <FormControl aria-label="Small"
                       aria-describedby="inputGroup-sizing-sm"
                       type="tel"
                       value={inputMode ? val : value}
                       style={{textAlign: "center", border: 'none'}}
                       onInput ={(e) => {
            if(e.target.value >= 0){
             setVal(e.target.value);
            }
          } 
          }
          onChange={()=>{}}
          onFocus={()=>{
              setVal(value);
              setInputMode(true);
          }}
          disabled={!localStorage.getItem("user")}
           />
        </InputGroup>
        {/* {value} */}
      </CounterValue>
      <CounterButton disabled={isLoading} className={`btn-count ${className}`} ref={inputMode ? tickRef : null} onClick={handleIncreaseButton} variant={variant}>
        {isLoading
          ? <Spinner animation="border" role="status" size="sm">
            <span className="sr-only">Loading...</span>
          </Spinner>
          : inputMode ? <Tick /> : <Plus />
        }
      </CounterButton>
    </CounterBox>
  );
};
