import React from 'react';
import { FormattedMessage } from 'react-intl';
import NavLink from '../../../../components/NavLink/NavLink';
import t from '../../../../i18n/translate';

import {
  PROCEED_TO_CHECKOUT_PAGE,
  PROFILE_PAGE,
  YOUR_ORDER,
  CURRENT_ORDER
} from '../../../../constants/navigation';

const AUTHORIZED_MENU_ITEMS = [
  {
    link: '/profile',
    label: t('profile_user_profile_button', false),
    intlId: 'navlinkProfile',
  },
  {
    link: '/all-orders',
    label: t('home_menu_all_orders', false),
    intlId: 'navlinkAllOrders',
  },
  // {
  //   link: PROCEED_TO_CHECKOUT_PAGE,
  //   label: 'Checkout',
  //   intlId: 'navlinkCheckout',
  // },
  // {
  //   link: ALTERNATIVE_CHECKOUT_PAGE,
  //   label: 'Checkout Alternative',
  //   intlId: 'alternativeCheckout',
  // },
  // {
  //   link: YOUR_ORDER,
  //   label: 'Past Orders',
  //   intlId: 'sidebarYourOrder',
  // },
  // {
  //   link: CURRENT_ORDER,
  //   label: 'Ongoing Order',
  //   intlId: 'navlinkOngoingOrder',
  // },
];


export const AuthorizedMenu= ({ onLogout }) => {
  return (
    <>
      {AUTHORIZED_MENU_ITEMS.map((item, idx) => (
        <NavLink
          key={idx}
          className='menu-item'
          href={item.link}
          label={item.label}
          intlId={item.intlId}
        />
      ))}
      <div className='menu-item' onClick={onLogout}>
        <a>
          <span>
            { t('profile_user_popup_log_out_title') }
          </span>
        </a>
      </div>
    </>
  );
};
