import React, { useState } from 'react'
import ClickOutsideWrapper from '../ClickOutsideWrapper';
import { DropDownWrapper } from './DropDown.style';

const DropDown = ({items,title}) => {
    const initialState={
       isOpen:false,
    }
    const  [state,setState]=useState(initialState)
    return (
        <DropDownWrapper>
            <ClickOutsideWrapper isClicked={state.isOpen} setClickOutside={()=>setState(pre=>({...pre,isOpen:false}))}>
            <button className="dropdownButton"
                    style={{position:'relative',display:'flex',alignItems:'center',justifyContent:'center'}}
                    onClick={()=>{ setState(pre=>({...pre,isOpen:!pre.isOpen})) }}
            >
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>{title}</div>
                    <svg aria-label="Show all" role="img" focusable="false" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"
                     style={{display: 'block', fill: 'none', height: '10px', width: '10px',marginLeft: '4px', stroke: 'currentcolor', strokeWidth: '5.33333', overflow: 'visible'}}>
                              <g fill="none">
                                  <path d="m28 12-11.2928932 11.2928932c-.3905243.3905243-1.0236893.3905243-1.4142136 0l-11.2928932-11.2928932"></path>
                             </g>
                         </svg> 
            {
                state.isOpen?
                <div className="dropdownlist">
                    {items.map((item,index)=>(
                        <div className="dropdownitem" key={index} onClick={item.onClick}> 
                            {item.name}
                        </div>
                    ))}
                </div>
                :null
            }
            </button>
            </ClickOutsideWrapper>
       </DropDownWrapper>
    )
}

export default DropDown;
