import React, { useEffect, useContext } from "react";
// import dynamic from 'next/dynamic';
import Sticky from "react-stickynode";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { AppContext } from "../../contexts/app/app.context";
import { LayoutWrapper } from "./Layout.style";
import { isCategoryPage } from "./is-home-page";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import MobileHeader from "./Header/MobileHeader";
import Sidebar from "../../components/Sidebar";
import SupplierSidebar from "../../components/SupplierSidebar";

import AdminHeader from "../../components/AdminHeader";
import SupplierHeader from "../../components/SupplierHeader";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
let admin = localStorage.getItem("admin");
let supplier = localStorage.getItem("supplier");

const AppLayout = ({
  className,
  children,
  deviceType: { mobile, tablet, desktop },
  token,
}) => {
  const { state } = useContext(AppContext);
  const isSticky = state.isSticky;

  const { pathname } = useLocation();
  const isHomePage = isCategoryPage(pathname);

  if(admin == null && supplier == null)
  {
    return (
      <LayoutWrapper className={`layoutWrapper ${className}`}>
        {(mobile || tablet) && (
          <Sticky enabled={isSticky} innerZ={1001}>
            <MobileHeader
              className={`${isSticky ? "sticky" : "unSticky"} ${
                isHomePage ? "home" : ""
              }`}
              pathname={pathname}
            />
          </Sticky>
        )}
        {desktop && (
          <Sticky enabled={isSticky} innerZ={1001}>
            <MobileHeader
              id="mheader"
              className={`${isSticky ? "sticky" : "unSticky"} ${
                isHomePage ? "home" : ""
              } desktop`}
              pathname={pathname}
            />
            <Header
              id="dheader"
              className={`${isSticky && isHomePage ? "sticky" : "unSticky"} ${
                isHomePage ? "home" : ""
              }`}
              token={token}
              pathname={pathname}
            />
          </Sticky>
        )}
        {children}
        <Footer id={"footer"} />
      </LayoutWrapper>
    );
  } else if(admin) { 
    return (
      <LayoutWrapper className={`layoutWrapper container-fluid ${className}`}>
        <Row>
          <AdminHeader />
        </Row>
        <Row>
          <Col sm={2} className="pl-0 pr-0">
            <Sidebar />
          </Col>
          <Col sm={10}>
            {children}
          </Col>
        </Row>
      </LayoutWrapper>
    );
  } else {
    return (
      <LayoutWrapper className={`layoutWrapper container-fluid ${className}`}>
        <Row>
          <SupplierHeader />
        </Row>
        <Row>
          <Col sm={2} className="pl-0 pr-0">
            <SupplierSidebar />
          </Col>
          <Col sm={10}>
            {children}
          </Col>
        </Row>
      </LayoutWrapper>
    );
  } 
};

export default AppLayout;
