import styled from "styled-components";

const PopoverWrapper = styled.div`
  position: relative;
  cursor: pointer;

  .popover-handler {
    display: inline-block;
    cursor: pointer;
    
    .burger-menu {
      display: flex;
      color: #fff;
      background-color: #389244;
      width: 70px;
      height: 70px;
      align-items: center;
      justify-content: center;
      position: relative;
      
      &.express { background-color: #f01266; }
      
      svg {
        width: 3rem;
        height: 3rem;
        position: relative;
        top: -0.5rem;
      }
      .menu-link {
        font-size: 0.8rem;
        line-height: 0.8rem;
        position: absolute;
        bottom: 0.7rem;
      }
    }
  }

  .popover-content {
    left: 0px;
    top: calc(100% + 15px);
    display: block;
    min-width: 200px;
    padding: 15px 20px;
    position: absolute;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 3px 20px rgba(142, 142, 142, 0.14);
    z-index: 99;
    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 9px 8px;
      border-color: transparent transparent #ffffff transparent;
      top: -8px;
      left: 15px;
      box-shadow: -4px -4px 8px -3px rgba(142, 142, 142, 0.14);
      pointer-events: none;
    }
    
    &.express { top: calc(100%); }
    &.no-pd { padding-bottom: 0 !important; }
    &.express:before { display: none; }
    
    .flex-box {
      width: 100%;
      min-width: 310px;
      background-color: rgb(217 217 217 / 34%);
      padding: 20px 0;
      margin-top: -20px;
      
      .link-back-to-normal-shop {
        width: 100%;
        max-width: 230px;
        margin: 0 auto;
        padding: 10px 30px 10px 5px;
        display: flex;
        align-items: center;
        border: 1px solid #e7e7e7;
        border-radius: 25px;
        gap: 15px;
        
        .logo {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        span { font-weight: bold; }
        img { flex: 1; }
      }
    }
  }

  /* If direction prop set to right */
  &.right {
    .popover-content {
      left: auto;
      right: 0px;
      &:before {
        left: auto;
        right: 15px;
      }
    }
  }
`;

export default PopoverWrapper;
