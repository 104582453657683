import styled from "styled-components";
import deliveryIcon from '../../assets/icons/scooter_bag.svg';
import clockIcon from '../../assets/icons/fi-rr-clock.svg';

export const SpinnerCustomWeapper = {
  display: 'block',
  backgroundColor: '#f7f7f7',
  marginLeft: -15,
  marginRight: -15,
  paddingBottom: 32,
  paddingTop: 32,
}

export const SpinnerCustom = {
  color: '#f01266',
  opacity: 1,
  display: 'block',
  margin: '0 auto',
}

export const SearchWrapper = {
  position: 'relative',
  width: '100%',
};

export const FormCustom = styled.form`
  width: 100%;
  margin-top: 5px;
  
  .form-row {
    margin: 0;
    border: 2px solid #999;
    padding-left: 10px;
  }
  
  .col-auto {
    flex: 1;
    padding: 0;
    
    &.btn-submit { flex: inherit; padding: 0px; }
    
    button.btn {
      width: 50px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      color: #ffffff;
      background-color: transparent;
      border-color: transparent;
      margin-bottom: 0 !important;
      box-shadow: none;
      
      svg {
        width: 16px;
        height: 16px;
        color: #77798C;
      }
    }
  }
  input.form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    padding-left: 0;
    padding-right: 20px;
    margin-bottom: 0 !important;
    border-color: transparent;
    box-shadow: none;
    
    &::placeholder {
      font-weight: bold;
      color: #d9d9d9;
      font-size: 1rem;
    }
  }
  
  @media (max-width: 990px) {
    padding-left: 0;
    padding-right: 0;
    
    .col-auto {
      flex: inherit;
      
      &.input-box {
        flex: 2;
      }
      &.btn-submit {
        flex: 0
      }
      
      button.btn {
        color: #999999;
        background-color: transparent;
        border-color: transparent;
      }
      #textSearch {
        padding-left: 0;
        padding-right: 0;
        border: none;
        
        &:focus { box-shadow: none; }
      }
    }
  }
`;

export const SupplierDetail = styled.div`
  .breadcrumb-item.express a {
    color: #f01266;
    font-weight: bold;
  }
  .header {
    display: flex;
    margin-top: 20px;
    
    .supplier-info {
      flex: 1;
      display: flex;
      gap: 20px;
      
      .media {
        width: 100%;
        max-width: 300px;
        justify-content: center;
      }
      .info {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .title {
      font-size: 1.7rem;
      color: #f01267;
      font-weight: bold;
    }
    address {
      margin-bottom: 0;
    }
    .open-hours {
      margin-top: 20px;
      position: relative;
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: bold;
      
      &:before {
        content: '';
        background-image: url(${deliveryIcon});
        width: 18px;
        height: 18px;
        display: block;
        background-repeat: no-repeat;
      }
    }
    .delivery-time {
      position: relative;
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: bold;
      
      &:before {
        content: '';
        background-image: url(${clockIcon});
        width: 18px;
        height: 18px;
        display: block;
        background-repeat: no-repeat;
      }
    }
    .search-bar {
      width: 100%;
      max-width: 320px;
    }
  }
  
  .scroll-to-top {
    display: none;
    position: fixed;
    bottom: 100px;
    right: 32px;
    z-index: 9999999;
    background-color: #f01266;
    color: #fff;
    padding: 10px 10px;
    border-radius: 50%;
    cursor: pointer;
    
    &.show{ display: block; }
    
    svg {
      width: 24px;
      height: 24px;
    }
  }
  
  @media (max-width: 1440px) {
    padding-top: 105px;
  }
  @media (max-width: 990px) {
    .header {
      flex-direction: column;
      padding: 0 15px;
      
      .supplier-info {
        flex-direction: column;
        
        .media {
          justify-content: center;
        }
        .delivery-time { margin-bottom: 15px; }
      }
    }
  }
`;

export const SupplierList = styled.div`
  display: block;
  margin: 40px 0;
  padding: 20px 70px;
  background-color: #fff;
  
  .title {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
        
    .item-wrapper {
      width: 100%;
      max-width: 300px;
    
      .items {
        background-color: #ffffff;
        position: relative;
        margin-bottom: 10px;
        
        &.shop-is-closed {            
          .media:before {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #000;
            opacity: 0.3;
          }
          
          .media span {
            color: #fff;
            width: 100%;
            display: block;
            position: absolute;
            bottom: 0;
            font-size: 1.5rem;
            background-color: #f01266;
            text-align: center;
          }
          .content { opacity: 0.5 }
        }
            
        .est-box{
          position: absolute;
          top: 0;
          right: 0;
          padding: 10px 10px;
          box-shadow: -2px 3px 3px 1px #ddd;
          text-align: center;
          
          .number{
            font-size: 1.2rem;
            line-height: 20px;
          }
          .text{
            font-size: 0.7rem;
            line-height: 0.7rem;
          }
        }
        .media {
          display: flex;
          width: 100%;
          max-width: 300px;
          height: 260px;
          box-shadow: 0 3px 11px 4px #dddddd;
          margin-bottom: 20px;
          align-items: center;
          justify-content: center;
          position: relative;
          
          img {
            width: 100%;
            height: auto;
            max-width: 250px;
            padding-top: 20px;
            
            &.even {
              width: auto;
              height: 100%;
              max-height: 180px;
            }
          }
        }
        .content-title,
        .content-title a {
          color: #f01266;
          font-size: 1.3rem;
          font-weight: bold;
        }
        .est-time,
        .content-description {
          color: #999;
          width: 100%;
          max-width: 300px;
          height: 50px;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  
  @media (max-width: 1440px) {
    .list .items {
      .content-description,
      .media {
        width: 290px;
      }
    }
  }
  
  @media (max-width: 1366px) {
    padding: 20px 50px;
    
    .list .items {
      .content-description,
      .media {
        width: 285px;
      }
    }
  }
  
  @media (max-width: 1280px) {   
    padding: 20px;
     
    .list .items {
      .content-description,
      .media {
        width: 280px;
      }
    }
  }
  
  @media (max-width: 1200px) {
    .list .items {
      .content-description,
      .media {
        width: 350px;
      }
    }
  }
  
  @media (max-width: 1024px) {
    .list .items {
      .content-description,
      .media {
        width: 300px;
      }
    }
  }
  
  @media (max-width: 990px) {
    .list {
      justify-content: space-around;
      
      .content-description,
      .items .media {
        width: 340px;
      }
    }
  }
`;
