import React from 'react';
import axios from 'axios';
import * as Constant from '../constants/Constant';
import t from '../i18n/translate';

const user = localStorage.getItem("user");
const parsedUser = JSON.parse(user);
const lang = localStorage.getItem('lang') || 'en';
const token = localStorage.getItem('token');

const addItem = (item_id, cartType = 'normal', quantity = 1, confirm = 0) => {
  return new Promise(async (resolve, reject) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const isExpress = cartType === 'express' ? 1 : 0;
    const errorMessage = {
      error: true,
      message: "We are sorry , item is not available now",
      success: false
    };

    let body = {
      store_id: 1,
      item_id: item_id,
      type: isExpress,
      confirm,
      quantity
    };

    try {
      const api = Constant.baseUrl+'v1/cart/addItemToCart?_locale='+lang;
      const doAddItemResp = await axios.post(api, body, { cancelToken: source.token, headers: { Authorization: token } });

      if(doAddItemResp.status === 200) {
        resolve(doAddItemResp.data);
      } else {
        resolve(errorMessage);
      }
      source.cancel('Operation canceled by the user.'); // cancel the request (the message parameter is optional)
    }
    catch(e) {
      resolve(e.response.data);
    }
  })
};

const removeItemFromCart = (item, cartType = 'normal') => {
  return new Promise(async (resolve, reject) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const isExpress = cartType === 'express' ? 1 : 0;
    const errorMessage = {
      error: true,
      message: t('shop_order_update_item_from_basket_error'),
      success: false
    };

    try {
      const body = {
        item_id: item["item_id"],
        item_price_id: item["item_price_id"],
        order_id: localStorage.getItem("order_id"),
        type: isExpress,
      };
      const api = Constant.baseUrl+'v1/cart/removeItemFromCart';
      const itemResp = await axios.post(api, body, { cancelToken: source.token, headers: { Authorization: token } });

      if(itemResp.status === 200) {
        resolve(itemResp.data);
      } else {
        resolve(errorMessage);
      }
      source.cancel('Operation canceled by the user.'); // cancel the request (the message parameter is optional)
    }
    catch(e) {
      resolve(errorMessage);
    }
  })
};

const reOrder = (orderId) => {
  return new Promise(async (resolve, reject) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const errorMessage = {
      error: true,
      message: t('shop_order_update_item_from_basket_error'),
      success: false
    };

    try {
      const body = {
        "old_order_id": orderId,
        "store_id": 1,
        "cust_id": parsedUser.cust_id,
        lang
      };
      const api = Constant.baseUrl+'v1/web/basket/reorder?_locale=' + lang;
      const itemResp = await axios.post(api, body, { cancelToken: source.token, headers: { Authorization: token } });

      resolve(itemResp);
      source.cancel('Operation canceled by the user.'); // cancel the request (the message parameter is optional)
    }
    catch(e) {
      resolve(e.response);
    }
  })
}

const updateQuantity = (body) => {
  return new Promise(async (resolve, reject) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const errorMessage = {
      error: true,
      message: t('shop_order_update_item_from_basket_error'),
      success: false
    };

    try {
      const api = Constant.baseUrl+'v1/cart/addItemToCart?_locale=' + lang;
      const itemResp = await axios.post(api, body, { cancelToken: source.token, headers: { Authorization: token } });

      resolve(itemResp);
      source.cancel('Operation canceled by the user.'); // cancel the request (the message parameter is optional)
    }
    catch(e) {
      resolve(e.response);
    }
  })
}

export default {
  addItem,
  removeItemFromCart,
  reOrder,
  updateQuantity,
};
