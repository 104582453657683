import React, {useEffect, useState, Suspense} from "react";
import { connect } from "react-redux";
import {useHistory} from "react-router-dom";
import {useDeviceType} from "../../helper/useDeviceType";
// Cart management component
import CartPopUp from "../../containers/Cart/CartPopUp";
import {BrandPageWrapper} from "../../containers/AllBrands/AllBrands.style";
import Products from '../../containers/Products/Products';
import InfiniteScroll from 'react-infinite-scroller';
import {
  productCategory,
  ProductModalData,
  initCategoryExpressStore,
  searchSuggestions,
  searchStoreProduct,
  getProductsMappedwithSubCat,
  getProductsMappedwithCat,
  getProductOutOfStock,
  getProductOutOfStockCat,
  getProductBrands,
  getSortProductsName,
  getSortProductsPrice,
  sortByNameProduct, SearchStoreProductByBrandRef, Toggle_Suggestions, changePage, resetPage, initCategoryFilter,
  sortProducts, initProductBrand,
} from "../../store/actions/productAction";

import {
  initExpressStoreProducts,
  initExpressStoreSelected, initExtraBrands,
} from "../../store/actions/expressAction";

import expressService from '../../services/expressService';
import {useCart} from "../../contexts/cart/use-cart";
import {initBasket} from "../../store/actions/cartAction";
import {ContentSection, MainContentArea} from "../../styled/pages.style";
import LazyLoader from "../../components/LazyLoader/LazyLoader";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {BreadcrumbItem} from "react-bootstrap";
import {offerSlider, oosModified} from "../../store/actions/globalAction";
import Spinner from 'react-bootstrap/Spinner';
import {SpinnerCustom, SpinnerCustomWeapper, SupplierDetail} from './express.style';
import ExpressSearchBox from "./express-supplier-search-box";
import Image from 'react-bootstrap/Image';
import * as Icons from "../../components/AllSvgIcon";
import SimpleMessageDialog from "../../components/SimpleMessageDialog/SimpleMessageDialog";
import moment from 'moment';
import t from "../../i18n/translate";
import defaultMissingImage from '../../assets/images/product-placeholder.png';
import {ExpressProvider} from "../../contexts/expressContext";

const PAGE_TYPE = "grocery";
const PlusIcon = Icons["Plus"];
const MinusIcon = Icons["Minus"];
const ArrowUp = Icons["ArrowUp"];

const ExpressSingleStore = (props, {userAgent}) => {
  const {fetchOrderItems, cartclose} = useCart();
  const user = localStorage.getItem('user');
  const deviceType = useDeviceType(userAgent);
  const history = useHistory();
  // Load More Variable
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [allowLoadMore, setAllowLoadMore] = useState(false);
  const limit = 50;
  const [offset, setOffset] = useState(0);
  const [extraOffset, setExtraOffset] = useState(0);
  // Get Store ID
  const storeID = history.location.pathname.split('/').splice(-1)[0];
  // Category Management
  const [categoryID, setCategoryID] = useState(null);
  const [subCategoryID, setSubCategoryID] = useState(null);
  const [search, setSearch] = useState('');
  const [showError, setShowError] = useState(false);
  const [defaultSetting, setDefaultSetting] = useState(null);
  // Current supplier no item to load more
  const [isSupplierNoItemToLoad, setIsSupplierNoItemToLoad] = useState(false);
  const [supplierData, setSupplierData] = useState();

  let mql = window.matchMedia("all and (min-width: 769px)");
  const targetRef = React.useRef(null);

  // Clear basket when close windows
  window.onbeforeunload = () => cartclose();

  // Show scroll to top button
  const btnScrollToTop = document.getElementById("scrollToTop");
  window.onscroll = function(e) {
    if(btnScrollToTop) {
      if(this.scrollY >= 1000) {
        btnScrollToTop.classList.add('show');
      }
      else {
        btnScrollToTop.classList.remove('show');
      }
    }
  }

  const initData = async (storeId = null) => {
    const [itemsStore, category] = await Promise.all([
      expressService.storeItems(storeId),
      expressService.getSubCategoryAndCategoryOfStore(storeId)
    ]);

    return await Promise.all([
      itemsStore,
      category,
    ]);
  }
  // First initialize data
  useEffect(async () => {
    // Hide basket menu
    document.getElementsByClassName("product-cart")[0].style.visibility = "hidden";
    // Retrieve Deli-Express Setting
    const expressStatus = await expressService.getExpressStatus();
    const shopSettingResp = await expressService.getShopSetting();
    if(expressStatus.status === 200 && !expressStatus.data.data.enable_express) { // If deli-express disabled
      window.location.href = '/';
    }

    props.initBasket('express');
    props.initCategoryExpressStore({data: []});
    localStorage.setItem('expressMode', true);

    setDefaultSetting(shopSettingResp.data);
  }, []);
  // Is logged in
  useEffect(() => {
    if (user) {
      fetchOrderItems('express');
    }
  }, [user]);
  // Get store object
  useEffect(async () => {
    if (typeof storeID !== 'undefined') {
      const store = await expressService.getExpressStore(storeID);
      if(store.data !== null) {
        const newStoreObject = {
          brand_id: store.id,
          name: store.name,
          brand_ref_id: (store.name !== null && store.name !== '') ? store.name.replace(' ', '-').toLowerCase() : 'N/A',
          description: store.description,
          image: store.supplier_logo,
          logo: store.supplier_logo,
          delivery_time: store.delivery_time,
          close_delivery_time: store.close_delivery_time,
          address: store.address || t('shop_new_address_address_title')+': n/a',
          estimate_delivery_minute: store.estimate_delivery_minute,
          is_available: 1,
          nextAvailableDay: store.nextAvailableDay,
          allow_multi_cart: store.allow_multi_cart
        }
        setSupplierData(store);
        props.initExpressStoreSelected(newStoreObject);
        window.scrollTo(0, 0);
      }
    }
  }, [storeID]);
  // Init products lists
  useEffect(async () => {
    if (props.storeSelected !== null) {
      const dataResp = await initData([props.storeSelected.brand_id]);
      const itemsStore = dataResp[0].list;
      const brandList = dataResp[0].brandList.map(item => {
        return {value: item.brand_id, label: item.name}
      });
      const categoryList = dataResp[1];
      setIsLoading(false);
      setAllowLoadMore(true);

      props.initProductExpressStore(itemsStore);
      props.initCategoryExpressStore({data: categoryList});
      props.initProductBrand(dataResp[0].brandList);
      props.initCategoryFilter(brandList);
    }
  }, [props.storeSelected]);
  // Load items by category and sub category
  useEffect( async () => {
    if(props.storeSelected !== null) {
      // Retrieve new items list
      const items = await expressService.storeItems([props.storeSelected.brand_id], [], [categoryID], [subCategoryID], 1, search);
      if(items.list.length) {
        // reset offset of item
        setOffset(0);
        setExtraOffset(0);

        // Init brand icon for each new items
        props.initExtraBrands(items.brandList);
        // Reload new items
        props.initProductExpressStore(items.list);
      }
      else {
        const selector = document.getElementById('isExtraData');

        // Display label of Extra item of multi store
        if(selector) {
          let container = document.createElement("div"); // Create div tag
          container.classList.add('clearFlex'); // Assign clearFlex class to div tag
          let span = document.createElement("span"); // Create span tag
          span.textContent = t('shop_extra_supplier_item_title'); // Add localize text into span tag
          container.appendChild(span); // Add span tag into div tag
          selector.parentNode.insertBefore(container, selector); // Do insert before
        }
        setShowError(true);
        setSubCategoryID(null);

        if(categoryID !== null && subCategoryID === null) {
          setCategoryID(null);
        }
      }
    }

    // Remove label of Extra item of multi store
    const extraDataLabel = document.querySelector('.clearFlex');
    if(extraDataLabel) {
      extraDataLabel.remove();
      setIsSupplierNoItemToLoad(false);
    }
  }, [categoryID, subCategoryID]);
  // Go to express homepage
  const handleClick = () => history.push("/express");
  // Enable / Disable scroll
  const handleLoadMore = async (page) => {
    // isLoadMore(true);
    if (allowLoadMore && props.storeSelected !== null) {
      let sort = '';
      let categorySelected = categoryID !== null ? [categoryID] : [];
      let subCategorySelected = subCategoryID !== null ? [subCategoryID] : [];
      const offsetItem = offset + limit;

      // Disable Load More
      setAllowLoadMore(false);

      if (!Array.isArray(props.filteredProducts) && Object.keys(props.filteredProducts).length) {
        sort = props.filteredProducts.sort;
      } else {
        categorySelected = props.filteredProducts.categorySelected;
      }

      setIsLoading(true);
      if(!isSupplierNoItemToLoad) {
        const dataResp = await expressService.storeItems([props.storeSelected.brand_id], [], categorySelected, subCategorySelected, 1, search, sort, offsetItem, limit);
        const noItemNext = dataResp.next === null;

        setIsSupplierNoItemToLoad(noItemNext);
        if(noItemNext && categoryID !== "EXTRA") {
          await loadExtraItems();
        }
        else {
          updateItems(dataResp, offsetItem, noItemNext);
        }
        setIsLoading(false);
        setAllowLoadMore(true);
      }
      else {
        if(categoryID !== "EXTRA") {
          await loadExtraItems();
        }
        setIsLoading(false);
        setAllowLoadMore(true);
      }
    }
  }
  // Load extra items if supplier no item to loadmore
  const loadExtraItems = async () => {
    const extraOffsetItem = extraOffset + limit;
    const extraItems = await expressService.getExtraStoreItems(storeID, extraOffset);
    // Remove label of Extra item of multi store
    if(extraOffset === 0 && document.querySelector('.clearFlex')) {
      document.querySelector('.clearFlex').remove();
    }

    updateItems(extraItems, extraOffsetItem, true);
    props.initExtraBrands(extraItems.brand_list);
    // If have no extra items for load more
    if (extraItems.next === null) {
      setHasMore(false); // Disable load more
      setAllowLoadMore(false); // Disable load more
    } else {
      setHasMore(true);
    }
  }
  // Migration data to store
  const updateItems = (dataResp, offsetItem = 0, isExtra = false) => {
    let newProducts = props.products;

    // Merge Items to store
    dataResp.list.forEach((item, index) => {
      if(isExtra && categoryID !== "EXTRA") {
        item.isExtraIndex = index;
      }
      newProducts.push(item);
    });

    if(!isExtra)
      setOffset(offsetItem);
    else
      setExtraOffset(offsetItem);

    if (dataResp.list.length < limit) {
      setHasMore(props.storeSelected.allow_multi_cart)
    }

    props.initProductExpressStore(newProducts); // Update product store
  };
  // Category and Sub Category Click
  const handleCategoryClick = async (e, category, type = 'category') => {
    if(type === 'category') {
      setSubCategoryID(null);
      // setSearch('');

      setCategoryID(prev => {
        if(prev === category.cat_id) {
          return null;
        }
        else {
          return category.cat_id;
        }
      });

      props.sortProducts({
        brandIdList: [],
        categorySelected: [category.cat_id],
        subCategorySelected: [],
        search: search,
        sort: props.filteredProducts.sort,
      });
    }
    else if (type === 'sub-category') {
      setSubCategoryID(category.list_sub_categories[0].sub_cat_id);

      props.sortProducts({
        brandIdList: [],
        categorySelected: [category.cat_id],
        subCategorySelected: [category.list_sub_categories[0].sub_cat_id],
        search: search,
        sort: props.filteredProducts.sort,
      });
    }
  }
  // Search item of supplier
  const handleSearch = (eventData) => {
    // reset pagination
    setOffset(0);
    setExtraOffset(0);

    setSearch(eventData);
    props.sortProducts({
      brandIdList: [],
      categorySelected: categoryID !== null ? [categoryID] : [],
      subCategorySelected: subCategoryID !== null ? [subCategoryID] : [],
      search: eventData,
      sort: props.filteredProducts.sort,
    });

    // Remove label of Extra item of multi store
    const extraDataLabel = document.querySelector('.clearFlex');
    if(extraDataLabel) {
      setTimeout(() => {
        extraDataLabel.remove();
        setIsSupplierNoItemToLoad(false);
      }, 250);
    }
  }
  // Close Error Modal
  const handleErrorModalClose = () => setShowError(false);
  return (
    <SupplierDetail>
      <InfiniteScroll pageStart={page}
                      loadMore={handleLoadMore}
                      className="infinite-scroll-custom"
                      initialLoad={false}
                      hasMore={hasMore}
                      threshold={500}
      >
        <MainContentArea style={{paddingTop: mql.matches ? "9vh" : "0px"}}>
          <ContentSection id="productBlock" style={{width: "100%"}}>
            <div ref={targetRef}>
              <Suspense fallback={<LazyLoader/>}>
                <Breadcrumb>
                  <BreadcrumbItem onClick={handleClick} className={props.cartType}>{ t('shop_category_list_express_store_back_redirection') }</BreadcrumbItem>
                  <Breadcrumb.Item active>
                    {props.storeSelected?.name || 'N/A'}
                  </Breadcrumb.Item>
                </Breadcrumb>

                {props.storeSelected
                  ? <div className="header">
                    <div className="supplier-info">
                      <div className="media">
                        <Image src={ props.storeSelected?.logo || defaultMissingImage }
                               alt={`${ props.storeSelected.name } Logo`}
                               width="220"
                               height="auto"
                               onError={(e)=>{
                                 e.target.onerror = null;
                                 e.target.src = defaultMissingImage
                               }}
                        />
                      </div>
                      <div className="info">
                        <p className="title">{ props.storeSelected.name }</p>
                        <address>{ props.storeSelected.address }</address>

                        {supplierData.isShopOpen
                          ? <div className="open-hours">
                            { t('shop_category_list_delivery_time', true, {
                              path: moment(`2022-07-15 ${props.storeSelected.delivery_time}`).format('h:mma') +' - '+ moment(`2022-07-15 ${props.storeSelected.close_delivery_time}`).format('h:mma')
                            }) }
                          </div>
                          : <p className="shop-closed">{ props.storeSelected.nextAvailableDay }</p>
                        }

                        <div className="delivery-time">
                          { t('shop_category_list_delivery_estimate_time', true, {
                            path: props.storeSelected.estimate_delivery_minute || defaultSetting?.defaultEstimateDeliveryMinute
                          }) }
                          mn
                        </div>
                      </div>
                    </div>
                    <div className="search-bar">
                      <ExpressSearchBox props={props}
                                        categoryID={categoryID}
                                        subCategoryID={subCategoryID}
                                        textSearch={search}
                                        handleSearch={handleSearch}
                      />
                    </div>
                  </div>
                  : null
                }

                <BrandPageWrapper className="express">
                  <div className="category-menu">
                    {props.category.length
                      ? <ul>
                        {props.category.map(item =>
                          <li key={ item.cat_id }>
                            <div className={`menu-parent ${ categoryID === item.cat_id ? 'active' : '' }`}
                                 onClick={(e) => handleCategoryClick(e, item)}
                            >
                              <div className="menu-item">
                                <Image src={ item.image } alt={ item.name } width="30" height="30" />
                                { item.name }
                              </div>
                              {item.map_categories_sub_cats.length
                                ? categoryID === item.cat_id ? <MinusIcon /> : <PlusIcon />
                                : null
                              }
                            </div>
                            <ul className={`sub-category ${ categoryID === item.cat_id ? 'show' : '' }`}>
                              {item.map_categories_sub_cats.length
                                ? item.map_categories_sub_cats.map((subItem, index) =>
                                  <li key={ index }
                                      className={ subCategoryID === subItem.list_sub_categories[0].sub_cat_id ? 'active' : '' }
                                      onClick={(e) => handleCategoryClick(e, subItem, 'sub-category')}
                                  >
                                    { subItem.list_sub_categories[0].name }
                                  </li>
                                )
                                : null
                              }
                            </ul>
                          </li>
                        )}
                      </ul>
                      : null
                    }
                  </div>
                  <div className="product-list">
                    {props.products.length
                      ? <ExpressProvider value={supplierData}>
                        <Products
                          type={PAGE_TYPE}
                          deviceType={deviceType}
                          fetchLimit={16}
                          storeId={props.storeSelected !== null ? props.storeSelected.brand_id : null}
                          props={props}
                          handleLoadMore={handleLoadMore}
                          textSearch={search}
                          isExtra={isSupplierNoItemToLoad}
                        />
                      </ExpressProvider>
                      : null
                    }
                    {isLoading
                      ? <div style={SpinnerCustomWeapper}>
                        <Spinner animation="grow" style={SpinnerCustom} />
                      </div>
                      : null
                    }
                  </div>
                </BrandPageWrapper>
              </Suspense>
            </div>
          </ContentSection>
        </MainContentArea>

        <CartPopUp deviceType={deviceType}/>
      </InfiniteScroll>

      <SimpleMessageDialog show={showError}
                           message="Not found"
                           onClose={handleErrorModalClose}
                           modalBodyClass="text-center"
                           buttonClass="pull-center"
                           cartType={props.cartType}
      />

      <div id="scrollToTop"
           className="scroll-to-top"
           onClick={(e) => { window.scrollTo({ top: 0, behavior: 'smooth' }) }}
      >
        <ArrowUp />
      </div>
    </SupplierDetail>
  )
};

ExpressSingleStore.getInitialProps = async (appContext) => {
  const {req} = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return {userAgent};
};

const mapStateToProps = (state) => {
  return {
    cartType: state.cartReducer.cartType,
    success: state.authReducer.success,
    modalData: state.productReducer.modalData,
    products: state.expressReducer.products,
    allproducts: state.productReducer.allproducts,
    category: state.productReducer.category,
    sliders: state.globalReducer.sliders,
    loading: state.globalReducer.loading,
    brands: state.productReducer.brands,
    extraBrands: state.expressReducer.extraBrands,
    filterBrands: state.productReducer.filterBrands,
    FilteredNameProducts: state.productReducer.FilteredNameProducts,
    filteredProducts: state.productReducer.filteredProducts,
    suggested_subcats: state.productReducer.suggested_subcats,
    all_suggested_subcats: state.productReducer.all_suggested_subcats,
    oos: state.globalReducer.oos,
    page: state.productReducer.page,
    cat_refs: state.productReducer.cat_refs,
    storeSelected: state.expressReducer.storeSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productCategory: (data) => productCategory(data, dispatch),
    ProductModalData: (data) => ProductModalData(data, dispatch),
    initProductExpressStore: (data) => initExpressStoreProducts(data, dispatch),
    initCategoryExpressStore: (data) => initCategoryExpressStore(data, dispatch),
    initCategoryFilter: (data) => initCategoryFilter(data, dispatch),
    initBasket: (data) => initBasket(data, dispatch),
    sortProducts: (data) => sortProducts(data, dispatch),
    initProductBrand: (data) => initProductBrand(data, dispatch),
    initExpressStoreSelected: (data) => initExpressStoreSelected(data, dispatch),
    initExtraBrands: (data) => initExtraBrands(data, dispatch),

    searchSuggestions: (data) => searchSuggestions(data, dispatch),
    searchStoreProduct: (data) => searchStoreProduct(data, dispatch),
    getProductsMappedwithSubCat: (data) => getProductsMappedwithSubCat(data, dispatch),
    getProductsMappedwithCat: (data) => getProductsMappedwithCat(data, dispatch),
    offerSlider: (data) => offerSlider(data, dispatch),
    getProductOutOfStock: (body) => getProductOutOfStock(body, dispatch),
    getProductOutOfStockCat: (body) => getProductOutOfStockCat(body, dispatch),
    getProductBrands: () => getProductBrands(dispatch),
    getSortProductsName: (type, products) => getSortProductsName(type, products, dispatch),
    getSortProductsPrice: (type, products) => getSortProductsPrice(type, products, dispatch),
    sortByNameProduct: (type, products) => sortByNameProduct(type, products, dispatch),
    SearchStoreProductByBrandRef: (body) => SearchStoreProductByBrandRef(body, dispatch),
    oosModified: (data) => oosModified(data, dispatch),
    Toggle_Suggestions: (data) => Toggle_Suggestions(data, dispatch),
    changePage: (data) => changePage(data, dispatch),
    resetPage: () => resetPage(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExpressSingleStore);
