import React, { Component, PropTypes } from 'react'
import { Icon } from '../containers/LayoutContainer/Header/Menu/LeftMenu/LeftMenu.style';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';

const SidebarData = [
    /*{
        name: "Dashboard",
        icon: <DashboardIcon />,
        link: "/supplier/dashboard"
    },*/
    // {
    //     name: "Products",
    //     icon: <DesktopWindowsIcon />,
    //     link: "/admin/products"
    // },
    // {
    //     name: "Categories",
    //     icon: <DesktopWindowsIcon />,
    //     link: "/admin/categories"
    // },
    // {
    //     name: "SubCategories",
    //     icon: <DesktopWindowsIcon />,
    //     link: "/admin/subCategories"
    // },
    // {
    //     name: "Brand",
    //     icon: <DesktopWindowsIcon />,
    //     link: "/admin/brands"
    // },
    // {
    //     name: "Bulk Uploads",
    //     icon: <DesktopWindowsIcon />,
    //     link: "/admin/bulkUploads"
    // },
    // {
    //     name: "Bulk Images Upload",
    //     icon: <DesktopWindowsIcon />,
    //     link: "/admin/bulkImageUploads"
    // },
    {
        name: "Order",
        icon: <DesktopWindowsIcon />,
        link: "/supplier/orders"
    }


];

export default SidebarData;