import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Form, Button, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import API from "../network/dashboardAPI"

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 130px 0 60px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  @media (min-width: 990px) {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 60px;
  }
`;

const ErrorPanel = styled.div`
  padding: 10px 20px;
  margin: 20px 0;
  margin-bottom: 10px;
  border: 1px solid #eee;
  border-left-color: #ff6464;
  border-left-width: 5px;
  border-radius: 3px;
  background-color: rgba(255,100,100,.3);
  color: #ff6464;
  
  &.hidden {display: none}
  &.show {display: block}
`;

const SucceedPanel = styled.div`
  padding: 10px 20px;
  margin: 20px 0;
  margin-bottom: 10px;
  border: 1px solid #eee;
  border-left-color: #28a745;
  border-left-width: 5px;
  border-radius: 3px;
  background-color: #d4edda;
  color: #155724;
  
  &.hidden {display: none}
  &.show {display: block}
`;

const ResetPasswordOTP = (props) => {

  const _otpInputRef= useRef(null);
  const _passwordInputRef = useRef(null);
  const _confirmPasswordInputRef = useRef(null);
  const [otpInput, setOtpInput] = useState(null);
  const [isInvalid, setIsInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isSucceed, setIsSucceed] = useState(false);

  const doOTPResetPassword = (e) => {
    e.preventDefault();

    clearError();

    if(_passwordInputRef.current.value !== _confirmPasswordInputRef.current.value) {
      onError("Confirm password not matching");
    } else {
      if(Object.keys(props.location.state).length && typeof props.location.state.email !== "undefined") {
        if(otpInput === null) {
          onError("Email not found, go back and enter email again.");
        } else {
          const credentials = {
            email: props.location.state.email,
            password: _passwordInputRef.current.value,
            otp: otpInput
          };

          API.updateSupplierPassword(credentials)
            .then(res => res.json())
            .then(res => {
              if(!res.success) {
                onError(res.message || res.error);
              } else {
                if(res.message === "Wrong Otp") {
                  onError(res.message);
                } else {
                  setIsSucceed(true)
                }
              }
            })
        }
      } else {
        // Email not found than set invalid
        onError("Email not found, go back and enter email again.");
      }
    }
  };

  const clearError = () => {
    setErrorMessage(null);
    setIsInvalid(false);
    setIsSucceed(false);
  };
  const onError = (message) => {
    setErrorMessage(message);
    setIsInvalid(true);
    setIsSucceed(false);
  };

  return (
    <>
      <HelpPageWrapper>
        <HelpPageContainer>
          <Card >
            <Card.Body>

              <Heading>Check your email for the OTP</Heading>

              <SucceedPanel className={(isSucceed) ? "show" : "hidden"}>
                Your pasword has been reset. Please <Link to={{pathname: "/supplier/login"}}>Click here to login.</Link>
              </SucceedPanel>

              <Form onSubmit={(e) => doOTPResetPassword(e)}>
                <Form.Group controlId="formOTP">
                  <OtpInput
                    value={otpInput}
                    ref={_otpInputRef}
                    numInputs={4}
                    isInputSecure
                    separator={<span style={{ margin: "0 10px" }}>-</span>}
                    inputStyle={{ padding: 5, width: "30px" }}
                    containerStyle={{padding: 15, justifyContent: "center"}}
                    onChange={(number) => {setOtpInput(parseInt(number))}}
                  />
                </Form.Group>

                <Form.Group controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password"
                                ref={ _passwordInputRef }
                                required
                                isInvalid={ isInvalid }
                  />
                </Form.Group>
                <Form.Group controlId="formConfirmPassword">
                  <Form.Label>Confirm password</Form.Label>
                  <Form.Control type="password"
                                ref={ _confirmPasswordInputRef }
                                required
                                isInvalid={ isInvalid }
                  />
                </Form.Group>

                <Form.Group controlId="formBasicCheckbox">
                  <Link to={{
                    pathname: "/supplier/login",
                    hash: "#forgot-password"
                  }}
                  >Back to forgot password</Link>
                </Form.Group>

                <Button variant="success" type="submit">Submit</Button>
              </Form>

              <ErrorPanel className={(isInvalid) ? "show" : "hidden"}>{ errorMessage }</ErrorPanel>

            </Card.Body>
          </Card>
        </HelpPageContainer>
      </HelpPageWrapper>
    </>
  );
};

export default ResetPasswordOTP;