import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const BrandPageWrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 100px 70px 40px;
  flex-direction: column;
  
  &.express {
    padding: 0 20px 0;
    margin-top: -65px;
    flex-direction: row;
    
    .category-menu {
      flex: 1;
      width: 100%;
      max-width: 320px;
      height: 100%;
      padding-top: 105px;
      position: sticky;
      top: 0;
      
      ul {
        li .menu-parent {
          display: flex;
          gap: 10px;
          align-items: center;
          color: #000;
          font-weight: bold;
          font-size: 1rem;
          white-space: nowrap;
          margin-bottom: 20px;
          justify-content: space-between;
          width: 100%;
          max-width: 280px;
          cursor: pointer;
          
          &.active,
          &:hover {
            color: #f01266;
          }
          
          .menu-item {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .icon {
            display: block;
          }
        }
        
        .sub-category {
          margin-bottom: 20px;
          margin-top: -15px;
          font-weight: bold;
          font-size: 0.9rem;
          
          opacity: 0;
          display: none;
          transition: display 0.25s ease-out;
          
          &.show {
            opacity: 1;
            display: block;
          }
          
          li {
            display: flex;
            gap: 10px;
            color: #495057;
            margin-bottom: 5px;
            cursor: pointer;
            
            &:before {
              content: '-';
            }
            &.active,
            &:hover{
              color: #f01266;
            }
          }
        }
      }
    }
    .product-list {
      flex: 1;
      position: relative;
    }
    
    #productList {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  @media only screen and (max-width: 990px) {
    padding: 100px 0 60px;
    
    &.express {
      flex-direction: column;
    
      .category-menu { position: unset; }
  }

  @media only screen and (min-width: 991px) and (max-width: 1280px) {
    padding: 110px 15px 60px;
  }
`;

export const BrandsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  background-color: #f7f7f7;
  position: relative;
  z-index: 1;
  margin: 0 -15px;

  @media (max-width: 768px) {
    margin-left: -7.5px;
    margin-right: -7.5px;
    margin-top: 15px;
  }
`;

export const BrandsCol = styled.div`
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 30px;
  margin-bottom: 30px;

  .book-card {
    border-radius: 0;
  }

  &.food-col {
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (min-width: 1501px) {
    &:nth-child(5n + 1) {
      .book-card {
        border-radius: 6px 0 0 6px;
      }
    }

    &:nth-child(5n) {
      .book-card {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  @media (min-width: 1301px) and (max-width: 1500px) {
    flex: 0 0 25%;
    max-width: 25%;

    &.food-col {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }

    &:nth-child(4n + 1) {
      .book-card {
        border-radius: 6px 0 0 6px;
      }
    }

    &:nth-child(4n) {
      .book-card {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1300px) {
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;

    &.food-col {
      flex: 0 0 33.3333333%;
      max-width: 33.3333333%;
      padding-left: 7.5px;
      padding-right: 7.5px;
      margin-bottom: 15px;
    }

    &:nth-child(3n + 1) {
      .book-card {
        border-radius: 6px 0 0 6px;
      }
    }

    &:nth-child(3n) {
      .book-card {
        border-radius: 0 6px 6px 0;
      }
    }
  }
  @media (max-width: 1199px) and (min-width: 991px) {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;

    &.food-col {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  @media (max-width: 768px) {
    padding-left: 7.5px;
    padding-right: 7.5px;
    margin-bottom: 15px;
  }
  @media (max-width: 767px) {
    flex: 0 0 50%;
    max-width: 50%;

    &.food-col {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &:nth-child(2n + 1) {
      .book-card {
        border-radius: 6px 0 0 6px;
      }
    }

    &:nth-child(2n) {
      .book-card {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  @media (max-width: 480px) {
    &.food-col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;

export const BrandsHeader = styled.h2`
   font-weight: 700;
   padding-bottom: 25px;
   text-align: center;
   
   &.express { color: #f01266; }
`;