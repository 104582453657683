import React from "react";
import Carousel from "react-multi-carousel";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { ArrowNext, ArrowPrev } from "../AllSvgIcon";
import { CarouselImage } from "./Carousel.style";
import "../../index.css";
import Loader from "../../components/Loader/Loader";
import * as constant from "../../constants/Constant";
import { flex } from "styled-system";
import { propTypes } from "react-bootstrap/esm/Image";

const ButtonPrev = styled("button")`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.2);
  color: ${themeGet("colors.primary", "#389244")};
  padding: 0;
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 0;
  outline: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 40px;
  margin-top: -20px;
  z-index: 99;
  
  @media (max-width: 990px) {
    display: none;
  }
`;

const ButtonNext = styled("button")`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.2);
  color: ${themeGet("colors.primary", "#389244")};
  padding: 0;
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 0;
  outline: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 40px;
  margin-top: -20px;
  z-index: 99;
  
  @media (max-width: 990px) {
    display: none;
  }
`;

const ButtonGroupWrapper = styled("div")``;

const PrevButton = ({ onClick, children }) => {
  return (
    <ButtonPrev
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className="prevButton"
    >
      {children}
    </ButtonPrev>
  );
};
const NextButton = ({ onClick, children }) => {
  return (
    <ButtonNext
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className="nextButton"
    >
      {children}
    </ButtonNext>
  );
};

const ButtonGroup = ({ next, previous }) => {
  return (
    <ButtonGroupWrapper>
      <>
        <PrevButton onClick={() => previous()}>
          <ArrowPrev />
        </PrevButton>
        <NextButton onClick={() => next()}>
          <ArrowNext />
        </NextButton>
      </>

      {/* if prop isRtl true swap prev and next btn */}
    </ButtonGroupWrapper>
  );
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  },
};
const CustomCarousel = ({
  props,
  deviceType: { mobile, tablet, desktop },
  component,
  autoPlay = true,
  infinite = true,
  customLeftArrow,
  customRightArrow,
  isRtl,
}) => {
  let deviceType = "desktop";
  if (mobile) {
    deviceType = "mobile";
  }
  if (tablet) {
    deviceType = "tablet";
  }
  return (
    <div className={`margin-from-sticky ${props.cartType}`} dir="ltr">
      <Carousel
        arrows={false}
        responsive={responsive}
        ssr={true}
        showDots={true}
        // slidesToSlide={1}
        infinite={infinite}
        containerClass="container-with-dots"
        // itemClass={itemClass}
        deviceType={deviceType}
        autoPlay={autoPlay}
        autoPlaySpeed={4000}
        renderButtonGroupOutside={true}
        additionalTransfrom={0}
        customButtonGroup={<ButtonGroup />}
        props={null}
        // use dir ltr when rtl true
      >
        {props.sliders && props.sliders.length > 0 ? props.sliders.map((item, index) => {
          if (component) return component(item);
          return (
            <div
              style={{
                padding: "0px",
                overflow: "hidden",
                position: "relative",
                // height: "200px",
              }}
              key={item.slider_id}
            >
              <a
                href={item.web_link !== null ? item.web_link : null}
                style={{ display: "flex", cursor: "pointer" }}
              >
                <CarouselImage
                  key={item.slider_id}
                  src={item.image_link}
                  alt={item.title}
                  onError={(e)=>{
                    e.target.onerror = null;
                    e.target.src = require('../../image/Background.png');
                  }}
                />
                {/*<div
                  style={
                    {
                      // position: "absolute",
                      // bottom: 10,
                      // marginLeft: 20,
                      // left: 5,
                    }
                  }
                >
                  <div
                    style={{ color: "white", fontSize: 22, fontWeight: "bold" }}
                  >
                    {" "}
                    {item.title}
                  </div>
                  <div style={{ color: "white", fontSize: 12 }}>
                    {item.description}
                  </div>
                </div>*/}
              </a>
            </div>
          );
        }) : 
        [0,1].map((elem,index) => {
           return <div
              style={{
                padding: "0px",
                overflow: "hidden",
                position: "relative",
                // height: "200px",
              }}
              key={elem}
            >
              <a
                href={null}
                style={{ display: "flex", cursor: "pointer" }}
              >
                <CarouselImage
                  key={elem}
                  src={"https://delishop.s3-ap-southeast-1.amazonaws.com/Delishop_LOGO+banner.jpg"}
                  alt={"logo"}
                  onError={(e)=>{
                    e.target.onerror = null;
                    e.target.src = require('../../image/Background.png');
                  }}
                />
                <div
                  style={
                    {
                      // position: "absolute",
                      // bottom: 10,
                      // marginLeft: 20,
                      // left: 5,
                    }
                  }
                >
                  <div
                    style={{ color: "white", fontSize: 22, fontWeight: "bold" }}
                  >
                    {" "}
                    {null}
                  </div>
                  <div style={{ color: "white", fontSize: 12 }}>
                    {null}
                  </div>
                </div>
              </a>
            </div>
          })
      }
      </Carousel>
    </div>
  )
};

export default CustomCarousel;
