import React from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Dropdown from 'react-bootstrap/Dropdown'
import "./AppSideBar.css";

function AdminHeader () {

    const clearData = (() => {
      localStorage.removeItem('admin');
      localStorage.removeItem('token');
      window.location.href = "/";
    });
    let admin = localStorage.getItem("admin");
    if(!admin) {
      window.location.href = "/";
    }

  return (
    <div className="Header">
      <Row>
        <Col sm={1}>
          <h3>DeliShop</h3>
        </Col>
        <Col sm={11}>
          <Dropdown className="float-right" navbar={false}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
            <AccountCircleIcon></AccountCircleIcon>
            </Dropdown.Toggle>

            <Dropdown.Menu>
            <Dropdown.Item onClick={clearData}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
        
    </div>
  );
};

export default AdminHeader;
