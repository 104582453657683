import * as actionTypes from "../ActionTypes";
import API from "../../network/redAPI";
import { checkOnlineStatus } from "../../helper/constant";

export const doSignup = async (body, dispatch) => {
  // if(await checkOnlineStatus()){
  dispatch({
    type: actionTypes.Loading_Enable,
  });
  API.Register(body)
    .then((response) => response.json())
    .then(async (data) => {
      dispatch({
        type: actionTypes.Loading_Disable,
      });
      if (data.success) {
        dispatch({
          type: actionTypes.Auth_Signup,
          payload: data.success,
          responsemsg: data.message
        });
      } else {
        // alert(data.error);
      }
    });
  // }
  // else{
  //   alert("You seem offline for now. Connect to the internet for uninterrupted experience.");
  // }
};

export const doLogin = async (body, dispatch) => {
  // if(await checkOnlineStatus()){
  dispatch({
    type: actionTypes.Loading_Enable,
  });
  // console.log(body,"body");
  API.Login(body)
    .then((response) => response.json())
    .then(async (data) => {
      dispatch({
        type: actionTypes.Loading_Disable,
      });
      if (data.success && data.message !== "Your account is not verified yet. We have sent you an email for verification. Please check your inbox or spam.") {
        await localStorage.setItem("token", data.token);
        await localStorage.setItem("user", JSON.stringify(data.user));
        let parsedUser = JSON.parse(localStorage.getItem("user"));
        let {
          cust_id,
          first_name,
          last_name,
          phone,
          email,
          gender,
          createdAt,
        } = parsedUser;
        window.analytics && window.analytics.track("User signed in", {
          cust_id,
          first_name,
          gender,
          email,
          phone,
          joined_on: createdAt,
        });
        dispatch({
          type: actionTypes.Auth_Login,
          payload: data.user,
          success: data.success,
          responsemsg: data.message
        });
        if(window.location.pathname !== "/register"){
          window.location.reload();
        }
        else{
          window.location.pathname = "/";
        }
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.Loading_Disable,
        });
        dispatch({
          type: actionTypes.Not_Yet_Registered,
          payload: data.message ? data.message : data.error
        })
      }
    });
  // }
  // else{
  //   alert("You seem offline for now. Connect to the internet for uninterrupted experience.");
  // }
};

export const doOTPVerify = async (body, dispatch) => {
  // if(await checkOnlineStatus()){
  API.OTPVerify(body)
    .then((response) => response.json())
    .then(async (data) => {
      await localStorage.setItem("token", data.token);
      await localStorage.setItem("user", JSON.stringify(data.user));
      if (data.success) {
        dispatch({
          type: actionTypes.OTP_Verify,
          payload: data.user,
          success: data.success,
        });
        // window.location.reload();
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      }
    });
  // }
  // else{
  //   alert("You seem offline for now. Connect to the internet for uninterrupted experience.");
  // }
};

export const doPasswordRequest = async (body, dispatch) => {
  // if(await checkOnlineStatus()){
  API.PasswordRequest(body)
    .then((response) => response.json())
    .then(async (data) => {
      dispatch({
        type: actionTypes.Loading_Disable,
      });
      if (data.success) {
        // alert(data.message);
        // dispatch({
        //   type: actionTypes.Password_Request,
        //   payload: data.success
        // });
        console.log("Response for password request is ", data);
        dispatch({
          type: actionTypes.Password_Request_Success,
          passwordrequest: true,
          email: body.email,
          error: ""
        })
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.Password_Request_Success,
          passwordrequest: false,
          email: body.email,
          error: data.error
        })
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      }
    });
  // }
  // else{
  //   alert("You seem offline for now. Connect to the internet for uninterrupted experience.");
  // }
};

export const doPasswordChange = async (body, dispatch) => {
  // if(await checkOnlineStatus()){
  API.PasswordChange(body)
    .then((response) => response.json())
    .then(async (data) => {
      dispatch({
        type: actionTypes.Loading_Disable,
      });
      if (data.success) {
        // alert(data.message);
        dispatch({
          type: actionTypes.Password_Reset,
          payload: data.success,
          // email: body.email,
        });
        if(window.location.pathname !== "/register"){
          window.location.reload();
        }
        else{
          window.location.pathname = "/";
        }
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      }
    });
  // }
  // else{
  //   alert("You seem offline for now. Connect to the internet for uninterrupted experience.");
  // }
};

export const doLoggedInPasswordChange = async (body, dispatch) => {
  // if(await checkOnlineStatus()){
  API.ChangeLoggedUserPassword(body)
    .then((response) => response.json())
    .then(async (data) => {
      // dispatch({
      //   type: actionTypes.Loading_Disable,
      // });
      console.log("Response of password change ", data.data);
      if (data.success) {
        // alert(data.message);
        dispatch({
          type: actionTypes.Change_Logged_User_Password,
          payload: data.success,
        });
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      }
    });
  // }
  // else{
  //   alert("You seem offline for now. Connect to the internet for uninterrupted experience.");
  // }
};

export const resetmessages = (dispatch) => { 
  dispatch({
    type: actionTypes.Reset_Response_Messages,
  });
};

export const doSupplierLogin = (body, dispatch) => {
  dispatch({
    type: actionTypes.Loading_Enable,
  });
  console.log(body,"body");
  API.SupplierLogin(body)
    .then((response) => response.json())
    .then(async (data) => {
      dispatch({
        type: actionTypes.Loading_Disable,
      });
      if (data.success) {
        if(data.user.is_manager == 0) {
          await localStorage.setItem("token", data.token);
          await localStorage.setItem("admin", JSON.stringify(data.user));
          let parsedUser = JSON.parse(localStorage.getItem("admin"));
          let {
            id,
            first_name,
            phone,
            email,
            gender,
            createdAt,
          } = parsedUser;
          window.analytics && window.analytics.track("Admin signed in", {
            id,
            gender,
            email,
            phone,
            joined_on: createdAt,
          });
          dispatch({
            type: actionTypes.Auth_Login,
            payload: data.user,
            success: data.success,
            responsemsg: data.message
          });
          window.location.href = "/admin/orders";
        } else {
          await localStorage.setItem("token", data.token);
          await localStorage.setItem("supplier", JSON.stringify(data.user));
          let parsedUser = JSON.parse(localStorage.getItem("supplier"));
          let {
            id,
            first_name,
            phone,
            email,
            gender,
            createdAt,
          } = parsedUser;
          window.analytics && window.analytics.track("Supplier signed in", {
            id,
            first_name,
            gender,
            email,
            phone,
            joined_on: createdAt,
          });
          dispatch({
            type: actionTypes.Auth_Login,
            payload: data.user,
            success: data.success,
            responsemsg: data.message
          });
          window.location.href = "/supplier/orders";
        }
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.Auth_Login,
          payload: data.error,
          success: data.success,
          responsemsg: data.error
        });
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      }
    });
};
