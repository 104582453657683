import React, { useEffect, useState } from "react";
import { BrandButton, BrandFilterWrapper } from "./BrandFilter.style";
import BrandPopup from "./BrandPopup";
import BrandFilterPopupButton from "./BrandFilterPopupButton";

const BrandFilter = ({
  brands,
  setSelectedBrands,
  isModal,
  ModalOpen,
  ModalClose,
}) => {
  let divRef = null;
  const [isMore, setMore] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const [brandSelected, setBrandSelected] = useState([]);
  const [selected, setSelected] = useState({});
  const [initialMount, setMount] = useState(true);
  useEffect(() => {
    if (divRef) {
      if (divRef.offsetHeight > 34) {
        divRef.style.height = "34px";
        divRef.style.overflow = "hidden";
        divRef.style.visibility = "visible";
        if (!isMore) {
          setMore(true);
        }
      } else if (isMore) {
        setMore(false);
      }
    }
  }, [divRef]);

  useEffect(() => {
    if (!initialMount) {
      if (!isPopup) {
        setSelectedBrands(brandSelected);
      }
    }
  }, [brandSelected]);
  useEffect(() => {
    setMount(false);
  }, []);

  const clearAllBrands = () => {
    setBrandSelected([]);
    setSelected({});
  };

  const handleSelection = (item) => {
    const isBrandPresent = brandSelected.length
      ? brandSelected.find((brand, index) => brand.value === item.value)
      : null;
    if (!isBrandPresent) {
      setSelected((pre) => ({ ...pre, [`${item.value}`]: true }));
      setBrandSelected((pre) => [...pre, item]);
    } else {
      const newArr = brandSelected.filter(
        (brand) => brand.value !== item.value
      );
      setSelected((pre) => {
        delete pre[`${item.value}`];
        return pre;
      });
      setBrandSelected(newArr);
    }
  };
  return (
    <>
      <BrandFilterWrapper>
        {window.screen.width >= 700 ? (
          <div className="_1q59bef" aria-hidden="true"></div>
        ) : null}
        <div
          className="brandsConainer"
          //  style={{minHeight:'34px',
          //  width:'100%',maxHeight:'37px',overflow:'hidden',display: 'flex',flexWrap: 'wrap'}}
        >
          {brands &&
            brands.map((item, index) => {
              return (
                <BrandButton
                  key={index}
                  style={
                    selected && selected[`${item.value}`] !== undefined
                      ? { backgroundColor: "#f2f2f2", color: "#000" }
                      : null
                  }
                  onClick={() => handleSelection(item)}
                >
                  <span className="_36rlri"> {item.label}</span>
                  <span className="_1lz9hno">
                    <span className="_a7a5sx">
                      {selected && selected[`${item.value}`] === undefined ? (
                        <svg
                          aria-hidden="true"
                          role="presentation"
                          focusable="false"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            display: "block",
                            fill: "none",
                            height: "12px",
                            width: "12px",
                            stroke: "currentcolor",
                            strokeWidth: "5.33333",
                            overflow: "visible",
                          }}
                        >
                          <path d="m2 16h28m-14-14v28"></path>
                        </svg>
                      ) : (
                        <svg
                          aria-hidden="true"
                          role="presentation"
                          focusable="false"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            display: "block",
                            fill: "none",
                            height: "12px",
                            width: "12px",
                            stroke: "currentcolor",
                            strokeWidth: "5.33333",
                            overflow: "visible",
                          }}
                        >
                          <path fill="none" d="m4 16.5 8 8 16-16"></path>
                        </svg>
                      )}
                    </span>
                  </span>
                </BrandButton>
              );
            })}
        </div>
        <BrandFilterPopupButton
          onOpen={ModalOpen}
          onClick={() => setPopup((pre) => !pre)}
        />

        {isPopup ? (
          <BrandPopup
            isOpen={isPopup}
            onClose={() => setPopup(false)}
            clearAllBrands={clearAllBrands}
            handleSelection={handleSelection}
            brands={brands}
            selected={selected}
            brandSelected={brandSelected}
            setSelectedBrands={setSelectedBrands}
          />
        ) : null}

        <div className="_1q59bef1" aria-hidden="true"></div>
      </BrandFilterWrapper>
    </>
  );
};

export default BrandFilter;
