import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Wrapper,
  Container,
  LogoWrapper,
  Heading,
  SubHeading,
  HelperText,
  Offer,
  Input,
  Divider,
  LinkButton,
} from "./SignInOutForm.style";
import {connect} from "react-redux";
import { AuthContext } from "../../contexts/auth/auth.context";
import { FormattedMessage } from "react-intl";
import {
 resetmessages
} from "../../store/actions/authAction";
const NotYetVerified = (props) => {
  const { authDispatch } = useContext(AuthContext);
  const toggleSignInForm = () => {
    authDispatch({
      type: "SIGNIN",
    });
  };

  const clearresponses = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect({
      clearresponses
  },[]);

  return (
    <Wrapper>
      <Container>
        <Heading>
          <FormattedMessage
            id="verifyOTP"
            defaultMessage="Your account is not verified yet. We have sent you an email for verification. Please check your inbox or spam."
          />
        </Heading>
        <br />
        <br />
        <br />
        {/* <Offer style={{ padding: "10px 0px 15px 0" }}>
          <FormattedMessage
            id="alreadyHaveAccount"
            defaultMessage="Back to Login?"
          />{" "}
          <LinkButton onClick={toggleSignInForm}>
            <FormattedMessage id="loginBtnText" defaultMessage="Login" />
          </LinkButton>
        </Offer> */}
      </Container>
    </Wrapper>
  );
};

export default NotYetVerified;

