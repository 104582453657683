import React from "react";
import { Router } from "react-router-dom";
import { I18nProvider } from "./i18n";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { AuthProvider } from "././contexts/auth/auth.provider";
import { AppProvider } from "./contexts/app/app.provider";
import { SearchProvider } from "./contexts/search/search.provider";
import { HeaderProvider } from "./contexts/header/header.provider";

import AppLayout from "./containers/LayoutContainer/AppLayout";
import { useDeviceType } from "./helper/useDeviceType";
import { CartProvider } from "./contexts/cart/use-cart";

// External CSS import here
import "rc-drawer/assets/index.css";
import "rc-table/assets/index.css";
import "rc-collapse/assets/index.css";
import "react-multi-carousel/lib/styles.css";
// import 'components/MultiCarousel/MultiCarousel.style.css';
import "@redq/reuse-modal/lib/index.css";
import { GlobalStyle } from "./styled/global.style";
import history from "./services/history";
import configureStore from "./store/ConfigureStore";
import { Provider } from "react-redux";
import Routes from "./routes";
const store = configureStore();

const App = ({ Component, pageProps, userAgent, query }) => {
  const deviceType = useDeviceType(userAgent);
  const lang = localStorage.getItem('lang');
  localStorage.removeItem("mode");
  localStorage.removeItem("sub_cat_id");
  localStorage.removeItem("subcatname");
  localStorage.removeItem("cat_id");
  localStorage.removeItem("reordered");
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CartProvider>
          <SearchProvider query={query}>
            <I18nProvider locale={lang}>
              <HeaderProvider>
                <AppProvider>
                  <AuthProvider>
                    <Router history={history}>
                      <AppLayout deviceType={deviceType} {...pageProps}>
                        <Routes {...pageProps} />
                      </AppLayout>
                      <GlobalStyle />
                    </Router>
                  </AuthProvider>
                </AppProvider>
              </HeaderProvider>
            </I18nProvider>
          </SearchProvider>
        </CartProvider>
      </ThemeProvider>
    </Provider>
  );
};
App.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { req, query } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { ...appProps, userAgent, query };
};
export default App;
