import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import expressService from "../../services/expressService";
import SearchWrapper, { SearchBoxWrapper } from "../../components/SearchBox/SearchBox.style";
import SimpleMessageDialog from "../../components/SimpleMessageDialog/SimpleMessageDialog";
import {SearchIcon} from "../../components/AllSvgIcon";
import {
  FormCustom,
} from './express.style';
import t from '../../i18n/translate';

const ExpressSearchBox = ({props, categoryID, subCategoryID, textSearch, handleSearch}) => {

  const [showError, setShowError] = useState(false);
  const [search, setSearch] = useState(textSearch);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const supplierObj = props.storeSelected;
    let filteredProducts = {brandIdList: [], categorySelected: [], subCategorySelected: [], outOfStock: 0, search: textSearch, sort: ''};
    if(!Array.isArray(props.filteredProducts) && Object.keys(props.filteredProducts).length) {
      filteredProducts.brandIdList = props.filteredProducts.brandIdList;
      filteredProducts.categorySelected = categoryID !== null ? [categoryID] : [];
      filteredProducts.subCategorySelected = subCategoryID !== null ? [subCategoryID] : [];
      filteredProducts.outOfStock = props.filteredProducts.outOfStock;
      filteredProducts.sort = props.filteredProducts.sort;
    }

    const searchResp = await expressService.storeItems(
      [supplierObj.brand_id],
      filteredProducts.brandIdList,
      filteredProducts.categorySelected,
      filteredProducts.subCategorySelected,
      1,
      search,
    );

    handleSearch(search);

    if(searchResp.list.length) {
      props.initProductExpressStore(searchResp.list);
      props.sortProducts(filteredProducts);

    }
    else {
      setShowError(true)
    }
  }

  const handleErrorModalClose = () => setShowError(false);

  return (
    <>
      <SearchWrapper>
        <SearchBoxWrapper>
          <FormCustom onSubmit={handleSubmit}>
            <Form.Row className="align-items-center">
              <Col xs="auto" className="btn-submit" style={{ padding: 0 }}>
                <Button type="submit" className="mb-2">
                  <SearchIcon />
                </Button>
              </Col>
              <Col xs="auto" className="input-box">
                <Form.Label htmlFor="textSearch" srOnly>{ t('search_home_screen_input_hint') }</Form.Label>
                <Form.Control
                  className="mb-2"
                  id="textSearch"
                  placeholder={ t('search_supplier_item_hint', true, {path: props.storeSelected.name}) }
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Col>
            </Form.Row>
          </FormCustom>
        </SearchBoxWrapper>
      </SearchWrapper>

      <SimpleMessageDialog show={showError}
                           message="Not found"
                           onClose={handleErrorModalClose}
                           modalBodyClass="text-center"
                           buttonClass="pull-center"
      />
    </>
  )
};

export default ExpressSearchBox;