import React from "react";
import { useSelector } from 'react-redux';
import { BrandButton, BrandPopupWrapper } from "./BrandFilter.style";
import ClickOutsideWrapper from "../ClickOutsideWrapper";
import t from '../../i18n/translate';

const BrandPopup = ({
  isOpen,
  onClose,
  brands,
  clearAllBrands,
  brandSelected,
  setSelectedBrands,
  selected,
  handleSelection,
}) => {
  const cartType = useSelector(state => state.cartReducer.cartType);

  return (
    <BrandPopupWrapper>
      <div
        style={{
          maxHeight: "35vh",
          overflow: "auto",
          padding: "8px",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {brands &&
          brands.map((item, index) => (
            <BrandButton
              style={
                selected && selected[`${item.value}`] !== undefined
                  ? {
                      backgroundColor: "#f2f2f2",
                      color: "#000",
                      marginBottom: "4px",
                    }
                  : { marginBottom: "4px" }
              }
              onClick={() => handleSelection(item)}
            >
              <span style={{ whiteSpace: "nowrap" }} class="_36rlri">
                {" "}
                {item.label}
              </span>
              <span className="_1lz9hno">
                <span className="_a7a5sx">
                  {selected && selected[`${item.value}`] === undefined ? (
                    <svg
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        display: "block",
                        fill: "none",
                        height: "12px",
                        width: "12px",
                        stroke: "currentcolor",
                        strokeWidth: "5.33333",
                        overflow: "visible",
                      }}
                    >
                      <path d="m2 16h28m-14-14v28"></path>
                    </svg>
                  ) : (
                    <svg
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        display: "block",
                        fill: "none",
                        height: "12px",
                        width: "12px",
                        stroke: "currentcolor",
                        strokeWidth: "5.33333",
                        overflow: "visible",
                      }}
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  )}
                </span>
              </span>
            </BrandButton>
          ))}
      </div>
      <div className="_pwbsgx">
        <div className="_1qbrhv4">
          <button
            onClick={clearAllBrands}
            disabled={Object.keys(selected).length ? false : true}
            type="button"
            className="_gby1jkw"
          >
            { t('shop_item_filter_clear_button') }
          </button>
          <div className="_ls0e43">
            <button
              onClick={() => {
                onClose();
                setSelectedBrands(brandSelected);
              }}
              type="button"
              className={`_ajuxzjo ${cartType}`}
            >
              { t('shop_item_filter_save_button') }
            </button>
          </div>
        </div>
      </div>
    </BrandPopupWrapper>
  );
};

export default BrandPopup;
