import * as actionTypes from "../ActionTypes";
import API from "../../network/redAPI";
import { checkAuthError, logoutuser } from "../../helper/constant";

export const placeOrder = (body, dispatch) => {
  API.PlaceOrder(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
       throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Place_Order,
          payload: data.data,
          success: data.success,
        });
      } else {
        console.log(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};

export const expressCheckout = (data, dispatch) => {
  dispatch({
    type: actionTypes.Place_Order,
    payload: data.data,
    success: data.success,
  });
}

export const addItemToCart = (body, dispatch) => {
  API.AddItemToCart(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
        throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Add_Item_To_Cart,
          payload: data.data,
          success: data.success,
        });
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};

export const removeItemFromCart = (body, dispatch) => {
  API.RemoveItemFromCart(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
        throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Remove_Item_From_Cart,
          payload: data.data,
          success: data.success,
        });
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};

export const updateItemQuantityCart = (body, dispatch) => {
  API.UpdateItemQuantityCart(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
        throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Update_Item_Quantity_Cart,
          payload: data.data,
          success: data.success,
        });
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });

};

export const clearCart = (body, dispatch) => {
  API.ClearCart(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
        throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Clear_Cart,
          payload: data.data,
          success: data.success,
        });
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};

export const fetchCart = (body, dispatch) => {
  API.FetchCart(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
        throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Fetch_Cart,
          payload: data.data,
          success: data.success,
        });
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};

export const changeOrderStatus = (dispatch) => {
  dispatch({
    type: actionTypes.Payment_Dialog_Close_Without_Payment,
    success: false,
  });
};

export const initBasket = (data, dispatch) => {
  dispatch({
    type: actionTypes.INIT_BASKET,
    payload: data,
  });
};

// Wallet
export const initWallet = (data, dispatch) => {
  dispatch({
    type: actionTypes.INIT_WALLET,
    payload: data
  });
}