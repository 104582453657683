import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const GoogleMaps = ({ google, latitude, longitude, onMarkerDragEnd, onMyLocation, fetchAddress}) => {
  var bounds = google.maps.LatLngBounds();
  return (
    <>
      <div
        style={{
          width: "100%",
          paddingTop: "10px",
          marginBottom: "10px",
          height: "70vh",
          position: "relative"
        }}
      >
        <Map
          streetViewControl={false}
          mapTypeControl={false}
          keyboardShortcuts={false}
          scrollwheel={false}
          center={{ lat: latitude, lng: longitude }}
          google={google}
          bounds={bounds}
          style={{
            width: "100%",
            height: "70vh",
          }}
          zoom={14}
          initialCenter={{ lat: latitude, lng: longitude}}
          fullscreenControl={false}
        >
          <Marker
            position={{ lat: latitude, lng: longitude }}
            draggable={true}
            onDragend={(t, map, coord) => onMarkerDragEnd(t, map, coord)}
            name={"Location Marker"}
          />
        </Map>
      </div>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBNXfan5vWyKWSbFUnnVOtjhcPdXafxQEk",
})(GoogleMaps);
