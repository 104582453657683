import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Sticky from "react-stickynode";
import { Scrollbars } from "react-custom-scrollbars";
import PopOver from "../../components/Popover/Popover";
import { ArrowDropDown, CategoryIcon } from "../../components/AllSvgIcon";
import { SearchContext } from "../../contexts/search/search.context";
import { AppContext } from "../../contexts/app/app.context";
import expressService from "../../services/expressService";
import {
  CategoryWrapper,
  TreeWrapper,
  PopoverHandler,
  PopoverWrapper,
  SidebarWrapper,
  RequestMedicine,
} from "./Sidebar.style";

import { TreeMenu } from "../../components/TreeMenu/TreeMenu";
import t from '../../i18n/translate';

const SidebarCategory = (props, deviceType, filterBrands) => {
  const { mobile, tablet, desktop } = deviceType;
  const { state, dispatch } = useContext(SearchContext);
  const data = useContext(AppContext);
  let { pathname, search } = useLocation();
  let history = useHistory();

  let string = pathname.split("/");
  let mql = window.matchMedia("all and (min-width: 769px)");
  let mqs = window.matchMedia("all and (max-width: 768px)");
  const selectedQueries = string[string.length - 1];
  const [hoveredCat, sethoveredCat] = useState("");
  const lang = localStorage.getItem('lang') || 'en';

  const handleHover = (
    cat_name,
    sub_cat_name,
    id,
    cat_ref_id,
    sub_cat_ref_id,
    cat_id
  ) => {
    sethoveredCat(cat_ref_id);
  };
  const handleCategorySelection = async (cat_name, sub_cat_name, id, cat_ref_id, sub_cat_ref_id, cat_id) => {
    if(props.props.cartType && props.props.cartType === 'express') {
      let filteredProducts = {brandIdList: [], categorySelected: [], subCategorySelected: [], outOfStock: 0, sort: ''};
      const categoryObj = new_array.find(item => item.cat_ref_id === cat_ref_id);
      let itemsStore = null;
      filteredProducts.categorySelected = [categoryObj.cat_id];
      if(props.props.storeSelected !== null) {
        const storeId = props.props.storeSelected.brand_id;
        if(!Array.isArray(props.props.filteredProducts) && Object.keys(props.props.filteredProducts).length) {
          filteredProducts.brandIdList = props.props.filteredProducts.brandIdList;
          filteredProducts.outOfStock = props.props.filteredProducts.outOfStock;
          filteredProducts.subCategorySelected = props.props.filteredProducts.subCategorySelected;
          filteredProducts.sort = props.props.filteredProducts.sort;
        }

        itemsStore = await expressService.storeItems(
          [storeId],
          filteredProducts.brandIdList,
          [categoryObj.cat_id],
          filteredProducts.subCategorySelected,
          filteredProducts.outOfStock,
          '',
          filteredProducts.sort
        );

        props.props.initProductExpressStore(itemsStore.list);
        props.props.sortProducts(filteredProducts);
      }
    }
    else {
      window.scrollTo(0,0);
      localStorage.setItem("cat_id",cat_id);
      localStorage.setItem("subcatname","");
      localStorage.setItem("sub_cat_id",-1);
      if(props.oos === true){
        localStorage.setItem("mode","catoos");
        props.props.resetPage();
        if(mqs.matches){
          props.props.getProductOutOfStockCat({ cat_id: cat_id, store_id: 1, page: 1, lang });
        }
      }
      else{
        localStorage.setItem("mode","cat");
        props.props.resetPage();
        if(mqs.matches){
          props.props.getProductsMappedwithCat({ cat_id: cat_id, store_id: 1, page: 1 });
        }
      }

      history.push({pathname: `/${cat_ref_id}`, state: { cat_name: cat_name },});
      sethoveredCat(cat_ref_id);
      window.location.href = `/${cat_ref_id}`;
    }
  };
  const isSidebarSticky = data.state.isSidebarSticky;

  if (props.categoreis) {
    var new_array = [];
    for (let idx = 0; idx < props.categoreis.length; idx++) {
      let sub_array = [];
      for (
        let idx1 = 0;
        idx1 < props.categoreis[idx].map_categories_sub_cats.length;
        idx1++
      ) {
        sub_array.push({
          cat_id: props.categoreis[idx].cat_id,
          cat_name: props.categoreis[idx].name,
          cat_ref_id: props.categoreis[idx].cat_ref_id,
          name:
            props.categoreis[idx].map_categories_sub_cats[idx1]
              .list_sub_categories[0].name,
          image:
            props.categoreis[idx].map_categories_sub_cats[idx1]
              .list_sub_categories[0].image,
          sub_cat_id:
            props.categoreis[idx].map_categories_sub_cats[idx1]
              .list_sub_categories[0].sub_cat_id,
          sub_cat_ref_id:
            props.categoreis[idx].map_categories_sub_cats[idx1]
              .list_sub_categories[0].sub_cat_ref_id,
        });
      }

      new_array.push({
        cat_id: props.categoreis[idx].cat_id,
        cat_ref_id: props.categoreis[idx].cat_ref_id,
        name: props.categoreis[idx].name,
        image: props.categoreis[idx].image,
        list_sub_categories: sub_array,
      });
    }
  }

  return (
    <CategoryWrapper>
      <PopoverWrapper className={`${mobile || tablet ? "mobileView" : ""}`}>
        {!window.location.pathname.includes('/express/store')
          ? <PopOver
            handler={
              <PopoverHandler>
                <div>
                  <CategoryIcon />
                  { t('profile_user_category_menu_label') }
                </div>
                <div>
                  <ArrowDropDown />
                </div>
              </PopoverHandler>
            }
            className="category-popover"
            content={
              <>
                {props.categoreis && (
                  <TreeMenu
                    data={new_array}
                    onClick={handleCategorySelection}
                    onHover={handleHover}
                    active={selectedQueries}
                    hoveredactive={hoveredCat}
                    props={props}
                  />
                )}
              </>
            }
          />
          : null
        }
      </PopoverWrapper>

      <SidebarWrapper
        className={`${mobile || tablet ? "mobileView" : ""}`}
        style={{ paddingTop: 10}}
      >
        <Sticky enabled={isSidebarSticky} top={89}>
          <Scrollbars
            universal
            autoHide
            autoHeight
            autoHeightMax={window.innerHeight - 120}
            renderView={(props) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  marginLeft: 0,
                  marginRight: props.style.marginRight,
                }}
              />
            )}
            renderTrackVertical={props => <div {...props} style={{display: 'none', overflowY: 'hidden'}} className="track-horizontal"/>}
          >
            <TreeWrapper>
              {props.categoreis && (
                <TreeMenu
                  data={new_array}
                  onClick={handleCategorySelection}
                  onHover={handleHover}
                  active={selectedQueries}
                  hoveredactive={hoveredCat}
                  props={props}
                />
              )}
            </TreeWrapper>
          </Scrollbars>
        </Sticky>
      </SidebarWrapper>
    </CategoryWrapper>
  );
};

export default SidebarCategory;
