import React, {useState, useEffect} from "react";

// ------------ Lib ------------------
import _ from "lodash";

// ------------ Components ------------
import Modal from 'react-bootstrap/Modal'
import {
    Row,
    Col,
    Button,
    Form,
    ListGroup,
    Table,
    Toast,
    Pagination,
    InputGroup,
    Image,
    FormControl,
    Spinner
} from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import EditIcon from '@material-ui/icons/Edit';
import { Tab, Tabs } from "react-bootstrap";
import { Info, Photo } from "@material-ui/icons";

// -------------- APIs ---------------
import API from "../../network/dashboardAPI";

// -------------- Style --------------
import styled from "styled-components";
import "react-datetime/css/react-datetime.css";
import UploadService from "../../services/FileUploadService";

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const labelStyle = {
    lineHeight: "2rem",
    minWidth: "140px"
}

const logo = {
    height: "50Px",
    width: "70Px"
}

const appPort = window.location.port.toString();
const appProtocol = window.location.protocol.toString();
const appHost = window.location.hostname.toString();
const appUrl = `${appProtocol}//${appHost}:${appPort}`

const SlideBanner = (props) => {
    /*
    *   Reference
    * */
    const _sliderImageInputFile = React.useRef(null);


    /*
    *   =================== States ==========================
    * */
    const [isRequesting, setIsRequesting] = useState(false);
    const [isRequestingUpsert, setIsRequestingUpsert] = useState(false);
    const [isUploadingImage, setIsUploadingImage] = useState(false);

    // table states
    const [slideList, setSlideList] = useState([]);

    // filter states
    const [selectedStatus, setSelectedStatus] = useState(1);

    // pagination states
    const [next, setNext] = useState(0);
    const [limit, setLimit] = useState(10);
    const [prev, setPrev] = useState(0);
    const [pager, setPager] = useState(1);
    const [sliderCount, setSliderCount] = useState(0);

    // modal states
    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowImageModal, setIsShowImageModal] = useState(false);
    const [imageUrlToView, setImageUrlToView] = useState("");

    // form states
    const [validated, setValidated] = useState(false);
    const [slideDataToUpdate, setSlideDataToUpdate] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [webLink, setWebLink] = useState("");
    const [slideId, setSlideId] = useState("");
    const [slideNumber, setSlideNumber] = useState("");
    const [formType, setFormType] = useState("ADD");

    // Form validation
    const [formError, setFormError] = useState({
        title: false,
        description: false,
        imageUrl: false,
        webLink: false,
        slideNumber: false
    });
    const [webLinkFormatError, setWebLinkFormatError] = useState(false);

    // toast states
    const [toastBgColor, setToastBgColor] = useState('bg-success');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("Delete successful");

    // Taps states
    const [type, setType] = useState(0);

    // confirm
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
    const [slideDataToUpdateStatus, setSlideDataToUpdateStatus] = useState({});
    const [isRequestingUpdateStatus, setIsRequestingUpdateStatus] = useState(false);

    /*
    *   =================== Methods =========================
    * */
    const getSlideList = async ($next) => {
        setIsRequesting(true);
        let params = {next: $next, limit}
        let query = {
            isDeleted: 0,
            type,
            isActive: selectedStatus
        }

        if (selectedStatus === 1 || selectedStatus === 0) {
            query.isActive = selectedStatus
        }

        await API.getSliderList(params, query)
            .then(res => res.json())
            .then(res => {
                if (res.success === true && !_.isEmpty(res.data)) {
                    setSlideList(res.data.list);
                    setSliderCount(res.data.total);

                    if (res.data.next) {
                        setNext(res.data.next);
                    } else if (res.data.list.length) {
                        setNext($next > 0?next + res.data.list.length: res.data.list.length);
                    } else {
                        setNext(0);
                    }

                    setIsRequesting(false);
                } else {
                    setIsRequesting(false);
                }
            });
    }

    const updateSlide = async () => {
        setIsRequestingUpsert(true);

        let params = {
            slider_id: slideId
        }

        let body = {
            image_link: imageUrl,
            is_active: slideDataToUpdate.is_active,
            type,
            title,
            description,
            order: slideNumber,
            web_link: webLink,
            app_link: slideDataToUpdate.app_link,
            store_id: slideDataToUpdate.store_id
        }

        await API.updateSlide(params, body)
            .then(res => res.json())
            .then(res => {
                if (res.success === true && !_.isEmpty(res.data)) {
                    let updatedSlideIndex = slideList.findIndex(x => x.slider_id === slideId);
                    if(updatedSlideIndex > -1){
                        slideList[updatedSlideIndex] = _.clone(res.data);
                    }

                    handleShowToast("SUCCESS");
                    setIsRequestingUpsert(false);
                    handleCloseModal();
                } else {
                    handleShowToast("ERROR");
                    setIsRequestingUpsert(false);
                }
            });

    }

    const createSlide = async () => {
        setIsRequestingUpsert(true);

        let body = {
            image_link: imageUrl,
            is_active: 1,
            type,
            title,
            description,
            order: 1,
            web_link: webLink,
            is_deleted: 0,
            app_link: "",
            store_id: 1
        }

        await API.createSlide(body)
            .then((res) => res.json())
            .then((res) => {
                if(res.success){
                    handleShowToast("SUCCESS");
                    getSlideList(0);
                }else{
                    handleShowToast("ERROR");
                }

                // reset paginate
                resetPagination();

                setIsRequestingUpsert(false);
                handleCloseModal();
            })
    }

    const setFormData = (banner) => {
        setSlideDataToUpdate(banner);
        setTitle(banner.title);
        setDescription(banner.description);
        setImageUrl(banner.image_link);
        setSlideId(banner.slider_id);
        setWebLink(banner.web_link);
        setSlideNumber(banner.order);
    }

    const resetFormData = () => {
        setSlideDataToUpdate({});
        setTitle("");
        setDescription("");
        setImageUrl("");
        setSlideId("");
        setWebLink("");
        setSlideNumber("");
        setIsUploadingImage(false);
    }

    const resetPagination = () => {
        setPager(1);
        setPrev(0);
    }

    /*
    *   ==================== Compute Data ========================
    * */

    useEffect(() => {
        getSlideList(0);
    }, [selectedStatus, type]);


    /*
    *  ===================== Event handlers ======================
    * */

    const handleOpenModal = (formType, data) => {
        if(formType === "UPDATE"){
            setFormData(data);
        }

        setFormType(formType);
        setIsShowModal(true);
    }

    // modal event
    const handleCloseModal = () => {
        setIsShowModal(false);
        resetFormData();
        resetFormError();
    }

    const resetFormError = () => {
        setFormError({
            title: false,
            description: false,
            imageUrl: false,
            webLink: false,
            slideNumber: false
        })

        setWebLinkFormatError(false);
    }

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    }

    const handleDescription = (e) => {
        setDescription(e.target.value);
    }

    const handleChangeSlideNumber = (e) => {
        setSlideNumber(e.target.value);
    }

    const handleUpsertSlider = async () => {
        if(checkFormValidate()){
            if(slideId){
                await updateSlide();
            }else{
                await createSlide();
            }
        }
    }

    const checkFormValidate =() => {
        let tmpFormError = {
            title: false,
            description: false,
            imageUrl: false,
            webLink: false,
            slideNumber: false
        }

        if(!title) tmpFormError.title = true;
        if(!description) tmpFormError.description = true;
        if(!imageUrl) tmpFormError.imageUrl = true;
        if(!webLink) tmpFormError.webLink = true;
        if(!slideNumber && formType === "UPDATE") tmpFormError.slideNumber = true;

        setFormError(tmpFormError);

        let linkExpressValidate = true
        if(webLink){
            linkExpressValidate = checkValidateWebLink()
        }

        return Object.values(tmpFormError).every(el => el === false) && linkExpressValidate;
    }

    const checkValidateWebLink = () => {
        let linkExpressValidate = webLink.includes(`${appUrl}/express`);
        let linkServer = webLink.includes(`${appUrl}`);
        if(type === 0){
            setWebLinkFormatError(linkExpressValidate);
            return !linkExpressValidate;
        }else{
            setWebLinkFormatError(linkServer?!linkExpressValidate: false);
            return linkServer?linkExpressValidate: true ;
        }
    }

    // toast event
    const handleShowToast = (type) => {
        setShowToast(true);

        if (type === "SUCCESS") {
            setToastBgColor("bg-success");
            setToastMessage("Operation successful.");
        } else if (type === "ERROR") {
            setToastBgColor("bg-error");
            setToastMessage("Operation error.");
        }
    }

    // pagination handlers
    const handlePaginNext = () => {
        const {next, prev} = updatePaginationProps("NEXT");
        getSlideList(prev);
    };

    const handlePaginPrev = () => {
        const {pager, next, prev} = updatePaginationProps("PREV");
        getSlideList(prev);
    };

    const updatePaginationProps = (type) => {
        let tmpPager = pager + 1;
        if (type === "PREV") {
            tmpPager = pager - 1;
        }

        const tmpNext = limit * tmpPager;
        const tmpPrev = limit * tmpPager - limit;

        setPager(tmpPager);
        setPrev(tmpPrev);

        return {pager: tmpPager, next: tmpNext, prev: tmpPrev};
    };

    const handleFileChange = async (action) => {
        try {
            setIsUploadingImage(true);
            let formData = null;

            if(_sliderImageInputFile.current !== null && action === "home") {
                formData = new FormData();
                formData.append("image", _sliderImageInputFile.current.files[0]);

                await UploadService.uploadImageFromDashboard(formData).then(response => {
                    if(response.data.success && response.data.data.length) {
                        handleShowToast("SUCCESS");
                        setImageUrl(response.data.data[0].url);
                    }
                    setIsUploadingImage(false);
                });
            }
        } catch(e) {
            console.log("Debug: ", e);
            handleShowToast("ERROR")
            setIsUploadingImage(false);
        }
    };

    const handleInputChange = (e) => {
        setWebLink(e.target.value);
    };

    const handleTypeChange = (key) => {
        // reset pagination
        resetPagination();

        setSelectedStatus(1);

        setType(parseInt(key));
    }

    const handleShowImageModal = (url) => {
        setImageUrlToView(url)
        setIsShowImageModal(true);
    }

    const handleCloseImageModal = () => {
        setImageUrlToView("");
        setIsShowImageModal(false);
    }

    const handleCloseConfirmModal = () => {
        setIsShowConfirmModal(false);
        setIsRequestingUpdateStatus(false);
        setSlideDataToUpdateStatus({});
    }

    const handleOpenConfirmModal = (slide) => {
        setIsShowConfirmModal(true);
        setSlideDataToUpdateStatus(slide);
    }

    const handleSubmitDisActive = async () => {
        setIsRequestingUpdateStatus(true);

        let params = {
            slider_id: slideDataToUpdateStatus.slider_id
        }

        let body = {
            image_link: slideDataToUpdateStatus.image_link,
            is_active: slideDataToUpdateStatus.is_active === 1? 0: 1,
            type: slideDataToUpdateStatus.type,
            title: slideDataToUpdateStatus.title,
            description: slideDataToUpdateStatus.description,
            order: slideDataToUpdateStatus.order,
            web_link: slideDataToUpdateStatus.web_link,
            app_link: slideDataToUpdateStatus.app_link,
            store_id: slideDataToUpdateStatus.store_id
        }

        await API.updateSlide(params, body)
            .then(res => res.json())
            .then(res => {
                if (res.success === true && !_.isEmpty(res.data)) {
                    handleShowToast("SUCCESS");
                    handleCloseConfirmModal();
                    getSlideList(0);

                    // reset paginate
                    resetPagination();
                } else {
                    handleShowToast("ERROR");
                    handleCloseConfirmModal();
                }
            });

    }

    const handleChangeStatusFilter = (e) => {
        // reset pagination
        resetPagination();

        setSelectedStatus(e.target.value);
    }

    /*
    *   ==================== Functional Components ================
    * */

    const tableContent = (
        slideList.length ? slideList.map((banner, index) => (
                <tr key={index}>
                    <td className="text-center" onClick= {()=>handleShowImageModal(banner.image_link)}>
                        <img src={banner.image_link} alt="img" style={logo}/>
                    </td>
                    <td>{banner.title}</td>
                    <td>{banner.description}</td>
                    <td className="text-center">
                        <Button variant={banner.is_active === 1? "success": "danger"}
                                style={{
                                    width: "12vh",
                                    border: "none",
                                    outline: "none",
                                    boxShadow: "none"
                                }}
                                onClick={() => handleOpenConfirmModal(banner)}
                        >
                            {banner.is_active === 1? "Active": "Inactive"}
                        </Button>
                    </td>
                    <td className="text-center">
                        <Button variant="info" onClick={() => handleOpenModal("UPDATE", banner)}>
                            <EditIcon></EditIcon>
                        </Button>
                    </td>
                </tr>
            )) :
            <tr>
                <td colSpan={5} className="text-center">No data</td>
            </tr>
    )

    let pageItems = [
        <Pagination.Item disabled={next <= limit} key={0} onClick={handlePaginPrev}>
            Prev
        </Pagination.Item>,
        <Form.Group key={1} controlId="formGridPagination">
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>{`${next} / ${sliderCount}`}</InputGroup.Text>
                </InputGroup.Prepend>
            </InputGroup>
        </Form.Group>,
        <Pagination.Item
            disabled={next === sliderCount}
            key={2}
            onClick={handlePaginNext}
        >
            Next
        </Pagination.Item>,
    ];
    const PaginationBasic = () => (
        <div className="d-flex justify-content-center">
            <Pagination>{pageItems}</Pagination>
        </div>
    );


    return (
        <HelpPageWrapper>
            <SettingsFormContent>
                <Tabs
                    activeKey={type}
                    transition={false}
                    id="noanim-tab-example"
                    className="tabProfile"
                    onSelect={handleTypeChange}
                >
                    <Tab eventKey={0} title="Normal" >
                        <Row className="mb-2">
                            <Col sm={9}>
                                <Button variant="success" disabled={isRequesting || isRequestingUpsert} onClick={() => handleOpenModal("ADD", null)}>
                                    Add slide
                                </Button>
                            </Col>

                            <Col sm={3} className="d-flex justify-content-end">
                                <Form.Label style={labelStyle}>Filter status: </Form.Label>&nbsp;&nbsp;
                                <Form.Control
                                    as="select"
                                    disabled={isRequesting || isRequestingUpsert}
                                    onChange={(e) => handleChangeStatusFilter(e)}
                                    value={selectedStatus}
                                >
                                    <option value={""}>All status</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                {isRequesting ?
                                    <div style={{position: "fixed", left: "55%", top: "35%"}}><Loader color="#389244" h="100"/>
                                    </div> :
                                    [
                                        <Table striped bordered hover key={1}>
                                            <thead>
                                            <tr>
                                                <th className="text-center">Image</th>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th className="text-center">Status</th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tableContent}
                                            </tbody>
                                        </Table>,
                                        <PaginationBasic key={2}></PaginationBasic>
                                    ]
                                }
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey={1} title="Express">
                        <Row className="mb-2">
                            <Col sm={9}>
                                <Button variant="success" disabled={isRequesting || isRequestingUpsert} onClick={() => handleOpenModal("ADD", null)}>
                                    Add express slide
                                </Button>
                            </Col>

                            <Col sm={3} className="d-flex justify-content-end">
                                <Form.Label style={labelStyle}>Filter status: </Form.Label>&nbsp;&nbsp;
                                <Form.Control
                                    as="select"
                                    disabled={isRequesting || isRequestingUpsert}
                                    onChange={(e) => setSelectedStatus(e.target.value)}
                                    value={selectedStatus}
                                >
                                    <option value={""}>All status</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                </Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                {isRequesting ?
                                    <div style={{position: "fixed", left: "55%", top: "35%"}}><Loader color="#389244" h="100"/>
                                    </div> :
                                    [
                                        <Table striped bordered hover key={1}>
                                            <thead>
                                            <tr>
                                                <th className="text-center">Image</th>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th className="text-center">Status</th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tableContent}
                                            </tbody>
                                        </Table>,
                                        <PaginationBasic key={2}></PaginationBasic>
                                    ]

                                }
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </SettingsFormContent>

            <Modal
                show={isShowModal}
                onHide={handleCloseModal}
                aria-labelledby="contained-modal-title-vcenter"
                size="xl"
                animation={false}
                centered
            >
                <Form
                    noValidate
                    validated={validated}
                    autoComplete="false"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{formType === "ADD"? "Add": "Update"} Slide</Modal.Title>
                    </Modal.Header>
                    <Modal.Body key="modalBody">
                        <Row>
                            <Col sm={6}>
                                <Form.Group controlId="formTitle">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={title}
                                        placeholder="Title"
                                        disabled={isRequestingUpsert}
                                        isInvalid={formError.title}
                                        required
                                        onChange={(e) => handleTitleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">Title is required.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="formDescription">
                                    <Form.Label>Description*</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        disabled={isRequestingUpsert}
                                        value={description}
                                        isInvalid={formError.description}
                                        onChange={(e) => handleDescription(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">Description is required.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="formImage">
                                    <Form.Label>Slide Image*</Form.Label>
                                    <Form.File
                                        ref={_sliderImageInputFile}
                                        id="custom-file-translate-scss"
                                        label={imageUrl || "Select file"}
                                        isInvalid={formError.imageUrl}
                                        accept="image/png, image/gif, image/jpeg"
                                        lang="en"
                                        className="mb-2"
                                        custom
                                        disabled={isRequestingUpsert || isUploadingImage}
                                        onChange={() => handleFileChange("home")}
                                    />
                                </Form.Group>
                                <Form.Group controlId="image">
                                    <ImageLoading>
                                        {
                                            isUploadingImage?
                                                <div style={{minHeight: "220px", position: "relative"}}>
                                                    <Spinner animation="grow" style={{margin: "0",
                                                        position: "absolute",
                                                        top: "40%",
                                                        left: "40%"}}/>
                                                </div>:
                                                !imageUrl?
                                                    <div
                                                        style={{
                                                            minHeight: "300px",
                                                            background: "#F5F7FA",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        <Photo
                                                            style={{
                                                                position: "absolute",
                                                                top: "47%",
                                                                left: "47%"
                                                            }}
                                                        ></Photo>
                                                    </div>
                                                    :
                                                    <Image src={imageUrl}
                                                           rounded
                                                           style={{
                                                               width: "100%",
                                                               height: "auto"
                                                           }}
                                                    />
                                        }
                                    </ImageLoading>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                {
                                    formType === "UPDATE" &&
                                    <Form.Group controlId="formSlider">
                                        <Form.Label>Slide number*</Form.Label>
                                        <FormControl id="slide-number"
                                                     value={slideNumber}
                                                     disabled={isRequestingUpsert}
                                                     aria-describedby="slide-number"
                                                     isInvalid={formError.slideNumber}
                                                     onChange={(e) => handleChangeSlideNumber(e)}
                                        />
                                        <Form.Control.Feedback type="invalid">Slide number is required.</Form.Control.Feedback>
                                    </Form.Group>
                                }

                                <Form.Group controlId="formSlider">
                                        <Form.Label>Slide link*</Form.Label>
                                        <FormControl id="home-link"
                                                     value={webLink}
                                                     disabled={isRequestingUpsert}
                                                     aria-describedby="home-link"
                                                     isInvalid={formError.webLink || webLinkFormatError}
                                                     onChange={handleInputChange}
                                        />

                                    {/* Link format error*/}
                                    <Form.Control.Feedback type="invalid">
                                        {
                                            formError.webLink?
                                                "Slider link is required."
                                                :
                                                webLinkFormatError && type === 0?
                                                    "Slider link must not have express."
                                                    :
                                                    "Slider link must have express."
                                        }
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="formSlider">
                                    <ListGroup>
                                        <ListGroup.Item style={{backgroundColor: "#ECF8FF", border: "none"}}>
                                            <div><Info style={{
                                                width: "18px",
                                                marginRight: "6px",
                                                color: "#1989FA"
                                            }}></Info>Example redirect to:</div>

                                            {
                                                type === 0?
                                                <>
                                                    <div><b>Brand:</b> {appUrl}/brand/:brandRef</div>
                                                    <div><b>Category:</b> {appUrl}/:categoryRef</div>
                                                    <div><b>Sub category:</b> {appUrl}/:categoryRef/:subCategoryRef</div>
                                                    <div><b>Search:</b> {appUrl}/search/:keyWord</div>
                                                    <div><b>External link:</b> any link</div>
                                                </>:
                                                <>
                                                    <div><b>Supplier:</b> {appUrl}/express/store/:supplierId</div>
                                                    <div><b>Search:</b> {appUrl}/express/search/:keyWord</div>
                                                    <div><b>External link:</b> any link</div>
                                                </>
                                            }

                                        </ListGroup.Item>
                                    </ListGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={isRequestingUpsert || isUploadingImage} variant="primary"
                                onClick={() => handleUpsertSlider()}>
                            {
                                isRequestingUpsert?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />:
                                    null
                            }

                            <span className="pl-2 pr-2">
                                {formType === "ADD"? "Add": "Update"}
                            </span>
                        </Button>

                        <Button
                            disabled={isRequestingUpsert}
                            variant="secondary"
                            onClick={handleCloseModal}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/*
                ================ View image modal
            */}

            <Modal show={isShowImageModal} onHide={handleCloseImageModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Image</Modal.Title>
                </Modal.Header>
                <Modal.Body><img alt ="Product" src = {imageUrlToView} style = {{width: "100%", alignItems : "center"}}/></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseImageModal}>
                        Close
                    </Button>
                    {/* <Button variant="danger">
                 Mark out of stock
            </Button> */}
                </Modal.Footer>
            </Modal>

            {/*
                ================ Confirm active / inactive
            */}
            <Modal show={isShowConfirmModal}
                   animation={false}
                   onHide={handleCloseConfirmModal}
                   aria-labelledby="contained-modal-title-vcenter"
                   size="xs"
                   centered
            >
                <Modal.Header closeButton className="bg-danger">
                    <Modal.Title className="text-white">Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body key="modalBody" className="text-center">
                    <Form.Label>Do you want to {slideDataToUpdateStatus.is_active? "inactive": "active"} this slide?</Form.Label>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="danger" disabled={isRequestingUpdateStatus} onClick={handleSubmitDisActive}>
                        {
                            isRequestingUpsert?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />:
                                null
                        }

                        <span className="pl-2 pr-2">Ok</span>
                    </Button>

                    <Button variant="secondary" disabled={isRequestingUpdateStatus} onClick={handleCloseConfirmModal}>Cancel</Button>
                </Modal.Footer>
            </Modal>


            <Toast className={toastBgColor}
                   onClose={() => setShowToast(false)}
                   show={showToast}
                   delay={3000}
                   autohide
                   style={{
                       position: 'fixed',
                       top: 70,
                       right: 35,
                       zIndex: 1041,
                       width: "300px"
                   }}
            >
                <Toast.Header>
                    <strong className="mr-auto m-3">{toastMessage}</strong>
                </Toast.Header>
            </Toast>
        </HelpPageWrapper>

    );
}

// Style
const ImageLoading = styled.div`
  position: relative;
  
  .hide {display: none;}
  .isLoading {
    position: absolute;
    background-color: rgb(255,255,255,0.4);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const SettingsFormContent = styled.div`
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }
  .tabProfile {
    border-bottom: 1px solid #ddd !important;
    justify-content: center;
    margin-bottom: 20px;
  }
`;


export default SlideBanner;
