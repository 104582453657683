import { STATUS_FIXED } from "react-stickynode/dist/Sticky";
import * as actionTypes from "../ActionTypes";

const initialState = {
  category: [],
  modalData: [],
  allproducts: [],
  products: [],
  promoproducts: [],
  arrivalproducts: [],
  trendingproducts: [],
  brands: [],
  filterBrands: [],
  success: false,
  filteredProducts: [],
  FilteredNameProducts: false,
  suggested_subcats: [],
  all_suggested_subcats: [],
  productfetch: true,
  togglesuggestion: false,
  page: 1,
  cat_refs: [],
  togglecats: false
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.Product_Category: {
      return {
        ...state,
        category: action.payload,
        cat_refs: action.refs,
        success: action.success,
      };
    }
    case actionTypes.ProductModal: {
      return {
        ...state,
        modalData: action.payload,
      };
    }
    case actionTypes.Products_Mappedwith_SubCat: {
      // !item.store_items_price_types[0].available_quantity
      return {
        ...state,
        allproducts: state.page > 1 ? 
        [...state.allproducts, ...action.payload] : 
        action.payload,
        products: state.page > 1 ? 
        [...state.allproducts, ...action.payload]:
        action.payload,
        success: action.success,
      };
    }
    case actionTypes.Products_Mappedwith_Cat: {
      return {
        ...state,
        allproducts: state.page > 1 ? 
        [...state.allproducts, ...action.payload] : 
        action.payload,
        products: state.page > 1 ? 
        [...state.allproducts, ...action.payload] :
        action.payload,
        success: action.success,
      }
    }
    case actionTypes.Products_Mappedwith_Cat_OutOfStock: {
      return {
        ...state,
        allproducts: state.page > 1 ? 
        [...state.allproducts, ...action.payload] : 
        action.payload,
        products: state.page > 1 ? 
        [...state.allproducts, ...action.payload] :
        action.payload,
        success: action.success,
      }
    }
    case actionTypes.Search_Store_Product: {
      return {
        ...state,
        allproducts: state.page > 1 ? 
        [...state.allproducts, ...action.payload] : 
        action.payload,
        products: state.page > 1 ? 
        [...state.allproducts, ...action.payload] :
        action.payload,
        success: action.success,
        togglesuggestion: true
      };
    }
    case actionTypes.Out_Of_Stock: {
      return {
        ...state,
        allproducts: state.page > 1 ? 
        [...state.allproducts, ...action.payload] : 
        action.payload,
        products: state.page > 1 ? 
        [...state.allproducts, ...action.payload] :
        action.payload,
        success: action.success,
      };
    }
    case actionTypes.Product_Brands: {
      return {
        ...state,
        brands: action.payload,
      };
    }
    case actionTypes.Product_Filter_Brands: {
      return {
        ...state,
        products: action.payload,
      };
    }
    case actionTypes.Product_Sort_Name: {
      return {
        ...state,
        products: action.payload,
      };
    }
    case actionTypes.Product_Sort_Price: {
      return {
        ...state,
        products: action.payload,
      };
    }
    case actionTypes.Filter_Brands: {
      return {
        ...state,
        filterBrands: action.payload,
      };
    }
    case actionTypes.Filtered_Name_Products: {
      return {
        ...state,
        filteredProducts: action.payload,
      };
    }
    case actionTypes.Show_Filtered_Name_Products: {
      return {
        ...state,
        FilteredNameProducts: action.payload,
      };
    }
    case actionTypes.Suggested_SubCat: {
      return {
        ...state,
        suggested_subcats: action.payload.map((item) => {
          return item.sub_cat_name;
        }),
        all_suggested_subcats: action.payload,
      };
    }
    case actionTypes.Promotion_Products: {
      return {
        ...state,
        promoproducts: action.payload
      };
    }
    case actionTypes.Arrival_Products: {
      return {
        ...state,
        arrivalproducts: action.payload
      };
    }
    case actionTypes.Trending_Products: {
      return {
        ...state,
        trendingproducts: action.payload
      };
    }
    case actionTypes.Product_Fetch: {
      return {
        ...state,
        productfetch: action.payload
      }
    }
    case actionTypes.Brand_Products: {
      return {
        ...state,
        allproducts: action.payload,
        products: action.payload,
        success: action.success,
      }
    }
    case actionTypes.Reset_Products: {
      return {
        ...state,
        products: state.allproducts,
        success: action.success,
      }
    }
    case actionTypes.Reset_Suggestions: {
      return {
        ...state,
        all_suggested_subcats: [],
        suggested_subcats: []
      }
    }
    case actionTypes.Toggle_Suggestions: {
      return {
        ...state,
        togglesuggestion: action.payload
      }
    }
    case actionTypes.Change_Page:{
      return {
        ...state,
        page: action.payload
      }
    }
    case actionTypes.Reset_Page:{
      return {
        ...state,
        page: 1
      }
    }
    case actionTypes.Toggle_Categories:{
      return {
        ...state,
        togglecats: action.payload
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
