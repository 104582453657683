import React, { useState, useEffect, lazy, Suspense } from "react";
import { Link, Redirect } from "react-router-dom";
import { openModal, closeModal } from "@redq/reuse-modal";
import ProductCard from "../../components/ProductCard/ProductCard";
import AuthenticationForm from "../../containers/SignInOutForm/Form";
import { AuthContext } from "../../contexts/auth/auth.context";
import { useCart } from "../../contexts/cart/use-cart";
import {
  ProductsRow,
  ProductsCol,
  ButtonWrapper,
  LoaderWrapper,
  LoaderItem,
  ProductCardWrapper,
  FilterWrapper,
  FilterDiv,
  FilterModalWrapper,
  BrandName,
  BrandInfo,
  BrandImageWrapper,
  TrendingWrapper,
  PromoTitle,
  ArrivalTitle,
  TrendingTitle,
  Wrapper,
  Prev,
  Next,
  ViewAll
} from "../Products/Products.style";
import { CURRENCY } from "../../helper/constant";
import { useLocation, useHistory } from "react-router-dom";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import Fade from "react-reveal/Fade";
import NoResultFound from "../../components/NoResult/NoResult";
import QuickView from "../QuickView/QuickView";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Placeholder from "../../components/Placeholder/Placeholder";
import { Modal } from "react-bootstrap";
import Switch from "react-switch";
import DropDown from "../../components/DropDown";
import BrandFilter from "../../components/BrandFilter";
import Carousel from "react-multi-carousel";
import { BrandButton } from "../../components/BrandFilter/BrandFilter.style";
import Loader from "../../components/Loader/Loader";
import { SearchBox } from "../../components/SearchBox/SearchBox.style";
import PageLoader from "../../components/PageLoader/PageLoader";
import _ from "lodash";
import { cats } from "../../helper/constant";
import LazyLoad from "react-lazyload";
import { ArrowNext, ArrowPrev } from "../../components/AllSvgIcon";
import { PromoSection } from "../../styled/pages.style";
import {
  TrendingCard
} from "../PromoProducts/PromoProducts.style";
import t from '../../i18n/translate';

const animatedComponents = makeAnimated();

const TrendingProducts = ({
  deviceType,
  type,
  ptype = "usual",
  fetchLimit = 8,
  loadMore = true,
  props,
}) => {
  const { isloading, disableloading,updateItem } = useCart();
  //   // Quick View Modal
  const [show, setShow] = useState(false);
  const [isBrandFilter, setBrandFilter] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const [cat_name, setCat_name, search_text] = useState("");
  const [sub_cat_name, setSubCat_name] = useState("");
  const [filterShow, setFilterShow] = useState(false);
  const [brandname, setBrandName] = useState("");
  const [brandinfo, setBrandInfo] = useState("");
  const [loader, setLoader] = useState(false);

  ///////////// brand filter modal //////////////
  const [isBrandSelected, setBrandSelected] = useState({});

  const SUB_CAT_ID = localStorage.getItem("sub_cat_id");
  const CAT_ID = localStorage.getItem("cat_id");
  const onHide = () => setShow(false);

  const {
    authState: { isAuthenticated },
    authDispatch,
  } = React.useContext(AuthContext);

  const handleQuickViewModal = (modalProps, deviceType, index) => {
    props.ProductModalData(modalProps);
    setTimeout(() => {
      setShow(true);
    }, 1000);
  };

  let { pathname, search, state } = useLocation();
  let history = useHistory();

  // useEffect(() => {
  //   let isMounted = true;
  //   if(isMounted){
  //       if (isloading) {
  //       document.getElementById("root").classList.add("loading");
  //       } else {
  //       document.getElementById("root").classList.remove("loading");
  //       }
  //   }
  //   return () => {
  //       isMounted = false;
  //   }
  // }, [isloading]);
  useEffect(() => {
    if(window.location.pathname.split("/").indexOf("search") >= 0){
        window.location.reload();
    }
  },[window.location.pathname]);
  
//   if (props.loading) {
//     return (
//       <LoaderWrapper>
//         <LoaderItem>
//           <Placeholder />
//         </LoaderItem>
//         <LoaderItem>
//           <Placeholder />
//         </LoaderItem>
//         <LoaderItem>
//           <Placeholder />
//         </LoaderItem>
//       </LoaderWrapper>
//     );
//   }

  //   if (error) return <div>{error.message}</div>;
  // if (props.category.length > 0 && props.trendingproducts.length === 0) {
  //   return <NoResultFound props={props} />;
  // }

  const getbrandimage = (name) => {
    var b = props.brands.filter(elem => {
      return elem.name === name   
    });
    return b.length !== 0 ? b[0].image : null;
  };

  const PrevButton = ({ onClick, children }) => {
    return (
      <Prev
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        className="prevButton"
      >
        {children}
      </Prev>
    );
  };
  const NextButton = ({ onClick, children }) => {
    return (
      <Next
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        className="nextButton"
      >
        {children}
      </Next>
    );
  };
  
  const ButtonGroup = ({ next, previous }) => {
    return (
      <Wrapper>
        <>
          <PrevButton onClick={() => previous()}>
            <ArrowPrev />
          </PrevButton>
          <NextButton onClick={() => next()}>
            <ArrowNext />
          </NextButton>
        </>
  
        {/* if prop isRtl true swap prev and next btn */}
      </Wrapper>
    );
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <>
      {isloading && <PageLoader />}
      {<TrendingWrapper>
        <TrendingTitle>
          { t('home_popular_products_label') }
        </TrendingTitle>
        <ViewAll className="promoAll" onClick={() => {
            history.push("/best-deals/trending");
        }}>
         <a target="_blank" rel="noopener noreferrer" href="/best-deals/trending" style={{textDecoration: "none", color: "inherit"}}>
           { t('home_view_all_label') }
          </a>
        </ViewAll>
       </TrendingWrapper>}
      {props.trendingproducts.length > 0 ? <PromoSection> 
        <Carousel
          arrows={false}
          responsive={responsive}
          ssr={true}
          showDots={false}
          // slidesToSlide={1}
          infinite={true}
          containerClass="container-for-promo"
        //   itemClass={itemClass}
          deviceType={deviceType}
          autoPlay={true}
          autoPlaySpeed={4000}
          renderButtonGroupOutside={true}
          additionalTransfrom={0}
          customButtonGroup={<ButtonGroup />}
          {...props}
          // use dir ltr when rtl true
        >
          {props.FilteredNameProducts == false &&
          props.trendingproducts.map((item, index) => (
              <ProductCardWrapper style={{padding: "20px"}} key={index}>
                <ProductCard
                  title={item.name}
                  description={item.about_item}
                  image={item.default_image_url}
                  weight={
                    item.store_items_price_types[0].name !== " "
                      ? item.store_items_price_types[0].name
                      : item.store_items_price_types[0].weight +
                        " " +
                        item.store_items_price_types[0].weight_unit
                  }
                  outOfStock={
                    !item.store_items_price_types[0].available_quantity
                  }
                  // weight={item.store_items_price_types[0].name}
                  currency={CURRENCY}
                  price={item.store_items_price_types[0].mrp}
                  salePrice={item.store_items_price_types[0].price}
                  isSameDay={item.same_day}
                  discountInPercent={item.discountInPercent}
                  data={item}
                  deviceType={deviceType}
                  onClick={() => {
                    handleQuickViewModal(item, deviceType, index)
                    // item.store_items_price_types[0].available_quantity
                    //   ? handleQuickViewModal(item, deviceType, index)
                    //   : trackClick(item, index);
                  }}
                  onCartClick={() => {
                    document.getElementById("joinBTn").click();
                  }}
                  onUpdate={(quantity) => {
                    updateItem(item,quantity);
                  }}
                  promo={item.promo ? 1 : 0}
                  promoPrice={item.promo ? item.promo_price : null}
                  promoStartDate={item.promo ? item.promo_start_date : null}
                  promoEndDate={item.promo ? item.promo_end_date : null}
                  brandId={item.brand_id === 0 || item.brand_id === null ? 0 : item.brand_id}
                  brands={props.brands}
                  loading={props.loading}
                  page={props.page}
                  promoProduct={true}
                />
              </ProductCardWrapper>
          ))}
        </Carousel>
        </PromoSection> :
        (props.trendloading === true ?
        <TrendingCard className="card-height">
          <span>Loading ....</span>
        </TrendingCard> :
        <TrendingCard className="card-height">
        <span>No products found ....</span>
      </TrendingCard>)
      }
      {show && (
        <QuickView
          show={show}
          onHide={onHide}
          props={props}
          deviceType={deviceType}
          onCartClick={() => {
            document.getElementById("joinBTn").click();
          }}
        />
      )}
    </>
  );
};

export default TrendingProducts;
