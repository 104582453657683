import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import styles from './StyleSheet';
import moment from 'moment';
import t from '../../i18n/translate';
import axios from 'axios';
import * as Constant from "../../constants/Constant";

const StyledWrapper = styles;

const AvailableDeliverySlotModal = (props ) => {

  const [list, setList] = React.useState([]);
  const [slotId, setSlotId] = useState('');
  const [buttonState, setButtonState] = React.useState(null);
  const order_id = localStorage.getItem('order_id');

  useEffect(() => {
    if(props.show) {
      let isMounted = false;
      async function GetSlotDates() {
        let api = Constant.baseUrl+'v1/web/slot/getSlotDates';
        await axios.post(api, {order_id}).then((response) => {
          setList(response.data.data);
        });
      }

      if (!isMounted) {
        GetSlotDates();
      }

      if(parseInt(props.selectedSlot) !== 0) {
        setSlotId(props.selectedSlot);
      }

      return () => {
        isMounted = true;
      };
    }
  }, [props.show])

  // Set button active
  useEffect(() => {
    if(slotId !== '') {
      const findButton = document.querySelector(`button.button.slot-${slotId}`);
      if(findButton) {
        findButton.classList.add('active');
      }
    }
  }, [slotId])

  const handleCloseDialog = () => props.handleCloseDialog();

  const handleNextWeek = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let buttonElem = document.getElementsByClassName('item');
    if(buttonElem.length) {
      let buttonWidth = (document.getElementsByClassName('item')[0].clientWidth + 5) * 6;

      for(let i=0; i<buttonElem.length; i++) {
        buttonElem[i].style.left = `-${buttonWidth}px`;
      }
      setButtonState('next')
    }
  }
  const handlePrevWeek = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let buttonElem = document.getElementsByClassName('item');
    if(buttonElem.length) {
      for(let i=0; i<buttonElem.length; i++) {
        buttonElem[i].style.left = `0`;
      }
      setButtonState('prev');
    }
  }

  function GetDates(startDate, daysToAdd) {
    const aryDates = [];

    for (let i = 0; i <= daysToAdd; i++) {
      const currentDate = new Date();
      currentDate.setDate(startDate.getDate() + i);
      // Remove Sunday 0 => Sunday
      if (currentDate.getDay() !== 0) {
        aryDates.push(
          DayAsString(currentDate.getDay()) +
          ", " +
          currentDate.getDate() +
          " " +
          MonthAsString(currentDate.getMonth()) +
          " " +
          currentDate.getFullYear()
        );
      }
    }

    return aryDates;
  }

  function MonthAsString(monthIndex) {
    const month = new Array(12);
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";

    return month[monthIndex];
  }

  function DayAsString(dayIndex) {
    const weekdays = new Array(7);
    weekdays[0] = "Sunday";
    weekdays[1] = "Monday";
    weekdays[2] = "Tuesday";
    weekdays[3] = "Wednesday";
    weekdays[4] = "Thursday";
    weekdays[5] = "Friday";
    weekdays[6] = "Saturday";

    return weekdays[dayIndex];
  }

  const startDate = new Date();
  const aryDates = GetDates(startDate, list.length);

  function isSlotFull(data, time) {
    let className = '';
    if(typeof data !== "undefined") {
      className = `slot-${data.slot_id}`;
      if(parseInt(data.is_deleted) || parseInt(data.slot_expired) || parseInt(data.stop_accepting_orders)) {
        className += ' disabledButton';
      }

      const isSlotSelected = (e) => {
        if(!e.target.classList.value.includes('disabledButton')) {
          if(document.querySelector('button.button.active')) {
            document.querySelector('button.button.active').classList.remove('active');
          }

          props.onSlotSelected( data.slot_id );
          e.target.classList.add('active');
        }
      }

      return <Button variant="info"
                     className={`button ${className}`}
                     onClick={(e) => { if(props.pageName === 'checkout') { isSlotSelected(e) }}}
      >{ time }</Button>;
    }
    else {
      return <Button variant="info" className="button">{ time }</Button>;
    }
  }

  return (
    <Modal
      size="xl"
      show={props.show}
      onHide={handleCloseDialog}
      dialogClassName="custom-modal"
    >
      <StyledWrapper>
        <Modal.Header closeButton>
          <div className="align-items-center justify-content-center modalHeader">
            <div className="text-center box-container">
              <h3 className="title">{ t('shop_delivery_slot_screen_title') }</h3>
              <p className="subTitle"
                 style={{opacity: window.location.pathname.includes('/checkout') ? 1 : 0}}
              >{ t('home_delivery_slots_popup_sub_title') }</p>

              <div className="buttonIntro">
                <Button variant="success" className="disabledButton">{ t('home_delivery_slots_popup_button_disable') }</Button>
                <Button variant="info" className="button">{ t('home_delivery_slots_popup_button_enable') }</Button>
              </div>
            </div>
            <div className="nextPrevButton">
              <a href="javascript:void(0);"
                 className={buttonState === 'prev' || buttonState === null ? "btnPrev hide" : "btnPrev show"}
                 onClick={handlePrevWeek}
              >{ t('home_delivery_slots_popup_button_prev') } <span className="icon prevIcon"></span></a>
              <a href="javascript:void(0);"
                 className={buttonState === 'next' ? "btnNext hide" : "btnNext show"}
                 onClick={handleNextWeek}
              >{ t('home_delivery_slots_popup_button_next') } <span className="icon nextIcon"></span></a>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="delivery-slot-header d-flex justify-content-between box-container">
            {list.length && aryDates.length
              ? list.map((date, dateIndex) => (
                <div key={dateIndex} className="item">
                  <div className="dateWrapper">
                    <div className="day">{moment(aryDates[dateIndex]).format('ddd')}</div>
                    <div className="dayMonth">{moment(aryDates[dateIndex]).format('DD MMM')}</div>
                  </div>
                  <div className="content-list">
                    {date[`${moment(aryDates[dateIndex]).format('YYYY-MM-DD')}`].map((data, timeIndex) => (
                      <div key={timeIndex} className="tableRow">
                        <div>
                          <div className="tableColumn">
                            { timeIndex === 0 && moment(data.start_time).format('hh') !== "10"
                              ? <>
                                <Button variant="info" className="button disabledButton">
                                  10 - 11am
                                </Button>
                                { isSlotFull(data, `${moment(data.start_time).format('hh')} - ${moment(data.end_time).format('hha')}`) }
                              </>
                              : isSlotFull(data, `${moment(data.start_time).format('hh')} - ${moment(data.end_time).format('hha')}`)
                            }
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
              : null}
          </div>
        </Modal.Body>
        <Modal.Footer className="modalFooter">
          <Button variant="success" onClick={handleCloseDialog}>{ t('button_close_label') }</Button>
        </Modal.Footer>
      </StyledWrapper>
    </Modal>
  );
};

export default AvailableDeliverySlotModal;
