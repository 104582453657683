import React, { useState, useContext, useMemo } from "react";
import { Router, useLocation, useParams, useHistory } from "react-router-dom";
import { openModal, closeModal } from "@redq/reuse-modal";
import { DrawerProvider } from "../../../contexts/drawer/drawer.provider";
import { AuthContext } from "../../../contexts/auth/auth.context";
import {useCart} from "../../../contexts/cart/use-cart";
import MobileDrawer from "./MobileDrawer";
import {
  MobileHeaderWrapper,
  MobileHeaderInnerWrapper,
  DrawerWrapper,
  LogoWrapper,
  SearchWrapper,
  SearchModalWrapper,
  SearchModalClose,
} from "./Header.style";
import SearchBox from "../../../components/SearchBox/SearchBox";
import { SearchContext } from "../../../contexts/search/search.context";
import { HeaderContext } from "../../../contexts/header/header.context";
// import LogoImage from '../../../image/logo.svg';
import LogoImage from "../../../image/Background.png";
import { SearchIcon, LongArrowLeft } from "../../../components/AllSvgIcon";
import Logo from "../../../components/Logo/Logo";
import { isCategoryPage } from "../is-home-page";
import useDimensions from "../../../helper/useComponentSize";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import {
  searchStoreProduct,
  searchSuggestions,
  Reset_Suggestions,
  Toggle_Suggestions,
  getProductsMappedwithCat,
  getProductsMappedwithSubCat,
  getProductOutOfStockCat,
  getProductOutOfStock,
  changePage,
  resetPage, sortProducts
} from "../../../store/actions/productAction";
import { RightMenuBox, AccountContainer, RegisterContainer, CartContainer, CartIcon, CartLabel, BadgeContainer, LabelContainer } from '../Header/Menu/RightMenu/RightMenu.style';
import {Basket} from "../../../assets/icons/Basket";
// import {
//   changePage,
//   resetPage
// } from "../../../store/actions/globalAction";

import deliExpressLogo from '../../../assets/icons/delixpress_logo.svg';
import ExpressSearchBox from "../../../pages/express/express-supplier-search-box";
import { initExpressStoreProducts } from '../../../store/actions/expressAction';

const useRouter = () => {
  const location = useLocation();
  return useMemo(() => {
    return {
      pathname: location.pathname,
      location,
    };
  }, [location]);
};

const SearchModal = ({ props, handleSearch, searchStoreProduct, onHide }) => {
  const { state, searchdispatch } = useContext(SearchContext);
  const [text, setText] = useState(state.text || "");
  let mql = window.matchMedia("all and (max-width: 768px)");
  const handleSearchInput = (text) => {
    // searchdispatch({
    //   type: "UPDATE",
    //   payload: {
    //     ...state,
    //     text,
    //   },
    // });
    setText(text.trimStart());
    console.log("Query text is ", text);
    if(props.all_suggested_subcats.length !== 0){
      props.Reset_Suggestions();
    }
    // document.getElementsByClassName("SearchBox")[0].value;
    // history.push(`/search/${text.trim()}`);
    // setText(text);
    // if (text !== "" && text.length >= 3) {
    //   props.searchSuggestions({ search_query: text, store_id: 1 });
    // }
  };
  const { page, ...urlState } = state;
  const history = useHistory();
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (state.suggestion !== -1 && state.text !== "" && state.text.length >= 3) {
      localStorage.setItem("sub_cat_id",-1);
      localStorage.setItem("subcatname","");
      localStorage.setItem("mode","search");
      props.resetPage();
      props.searchStoreProduct({
        sub_cat_id: state.suggestion,
        search_query: state.text,
        store_id: 1,
        page: 1
      });
      // var topOfElement = document.querySelector("#sidebarBlock").offsetTop - 90;
      // window.scrollTo({ top: topOfElement, behavior: "smooth" });
    }
  }, [state.suggestion]);

  React.useEffect(() => {
    const timeout = setTimeout(async () => {
      if (text !== "" && text.length >= 3) {
        await searchdispatch({
          type: "UPDATE",
          payload: {
            ...state,
            text,
          },
        });
        // history.push(`/search/${text}`);
        // await props.searchSuggestions({ search_query: text, store_id: 1 });
      }
    }, 0);
    return () => clearTimeout(timeout);
  },[text]);

  React.useEffect(() => {
    // if(props.page != 1){
      switch(localStorage.getItem("mode")) {
      case "search":
        return props.searchStoreProduct({
          sub_cat_id: state.suggestion,
          search_query: state.text,
          store_id: 1,
          page: props.page
        });
      case "catoos":
        return props.getProductOutOfStockCat({
          cat_id: localStorage.getItem("cat_id") === null || localStorage.getItem("cat_id") === undefined || localStorage.getItem("cat_id") === -1 ? 0 : localStorage.getItem("cat_id"),
          store_id: 1,
          page: props.page
        });
      case "cat":
        return props.getProductsMappedwithCat({
          cat_id: localStorage.getItem("cat_id") === null || localStorage.getItem("cat_id") === undefined || localStorage.getItem("cat_id") === -1 ? 0 : localStorage.getItem("cat_id"),
          store_id: 1,
          page: props.page
        });
      case "subcatoos":
        return props.getProductOutOfStock({
          sub_cat_id: localStorage.getItem("sub_cat_id") === null || localStorage.getItem("sub_cat_id") === undefined || localStorage.getItem("sub_cat_id") === -1 ? 0 : localStorage.getItem("sub_cat_id"),
          store_id: 1,
          page: props.page
        }) ;
      case "subcat":
        return props.getProductsMappedwithSubCat({
          sub_cat_id: localStorage.getItem("sub_cat_id") === null || localStorage.getItem("sub_cat_id") === undefined || localStorage.getItem("sub_cat_id") === -1 ? 0 : localStorage.getItem("sub_cat_id"),
          store_id: 1,
          page: props.page
        });
      default:
        return ;
      }
    // }
  },[props.page]);

  const handleClickSearchButton = (e) => {
    if (state.suggestion !== -1 && state.text !== "" && state.text.length >= 3) {
      localStorage.setItem("sub_cat_id",-1);
      localStorage.setItem("subcatname","");
      history.push(`/search/${state.text.trimStart()}`);
      props.searchSuggestions({ search_query: state.text, store_id: 1 });
    }
  };
  return (
    <SearchModalWrapper>
      <SearchModalClose type="submit" onClick={() => onHide()} id="closeModal">
        <LongArrowLeft />
      </SearchModalClose>
      <SearchBox
        className="header-modal-search"
        suggestions={props.all_suggested_subcats}
        bordered={false}
        inputStyle={{ height: 35 }}
        buttonText=""
        placeholder="Search"
        handleSearch={(text) => handleSearchInput(text)}
        value={text}
        onClick={(e) => {handleClickSearchButton(e)}}
        pathname={pathname}
        props={props}
      />
    </SearchModalWrapper>
  );
};

const delishopLogo = '/delishop-logo.svg';
const MobileHeader = (props, { className, pathname, searchStoreProduct }) => {
  const { state, searchdispatch } = useContext(SearchContext);

  const [mobileHeaderRef, dimensions] = useDimensions();
  const { headerDispatch } = useContext(HeaderContext);

  const headerHeight = dimensions.height;
  const [searchShow, setSearch] = useState(false);
  // const searchRef = React.useRef(null);
  const {
    authState: { isAuthenticated, currentForm },
    authDispatch,
  } = React.useContext(AuthContext);
  const {
    cartItemsCount,
    calculatePrice,
  } = useCart();
  React.useEffect(() => {
    headerDispatch({
      type: "GET_MOBILE_HEIGHT",
      payload: {
        ...state,
        mobileHeight: headerHeight,
      },
    });
  }, [headerHeight]);

  const isHomePage = isCategoryPage(window.location.pathname) && !(window.location.pathname.indexOf("not-found") !== -1);
  return (
    <DrawerProvider>
      <MobileHeaderWrapper>
        <MobileHeaderInnerWrapper className={className} ref={mobileHeaderRef}>
          <DrawerWrapper>
            <MobileDrawer id="mobilesidebar" />
          </DrawerWrapper>

          <LogoWrapper>
            {props.cartType === 'express'
              ? <Logo imageUrl={deliExpressLogo} alt="shop logo" height={55} width={120} cartType={props.cartType} />
              : <Logo imageUrl={delishopLogo} alt="shop logo" height={55} width={120} cartType={props.cartType} />
            }
          </LogoWrapper>
          {isHomePage ? (
            <SearchWrapper
              onClick={() => setSearch(true)}
              className="searchIconWrapper"
            >
              <SearchIcon />
            </SearchWrapper>
          ) : null}
          {isAuthenticated && document.getElementsByClassName("product-cart").length > 0 && <CartContainer className="cart-container" style={{marginRight: "5px" , marginLeft: isHomePage && "45px"}} onClick={()=>{document.getElementsByClassName("product-cart")[0].click(); document.getElementsByClassName("product-cart")[0].style.visibility = "visible";}}>
              <CartIcon>
                  <Basket cartType={props.cartType}/>
                  <BadgeContainer className={props.cartType}>{cartItemsCount}</BadgeContainer>
              </CartIcon>
              <CartLabel>
                  <LabelContainer className={props.cartType}>${calculatePrice()}</LabelContainer>
              </CartLabel>
            </CartContainer>}
          <Modal
            show={searchShow}
            onHide={() => setSearch(false)}
            dialogClassName="modal-90w authModal"
            aria-labelledby="example-custom-modal-styling-title"
            // className= "search-modal-mobile"
          >
            {/* <Modal.Header closeButton style={{border:0}}>
        </Modal.Header> */}
            <Modal.Body style={{ padding: 0 }}>
              <SearchModal
                props={props}
                state={state}
                pathname={pathname}
                searchStoreProduct={props.searchStoreProduct}
                onHide={() => setSearch(false)}
              />
            </Modal.Body>
          </Modal>
        </MobileHeaderInnerWrapper>
      </MobileHeaderWrapper>
    </DrawerProvider>
  );
};
const mapStateToProps = (state) => {
  return {
    success: state,
    allproducts: state.productReducer.allproducts,
    products: state.productReducer.products,
    suggested_subcats: state.productReducer.suggested_subcats,
    all_suggested_subcats: state.productReducer.all_suggested_subcats,
    togglesuggestion: state.productReducer.togglesuggestion,
    page: state.productReducer.page,
    cartType: state.cartReducer.cartType,
    storeSelected: state.expressReducer.storeSelected,
    filteredProducts: state.productReducer.filteredProducts,
    // modalData :state.productReducer.modalData,
    // user_address: state.userReducer.address
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchStoreProduct: (data) => searchStoreProduct(data, dispatch),
    searchSuggestions: (data) => searchSuggestions(data, dispatch),
    getProductsMappedwithSubCat: (data) =>
      getProductsMappedwithSubCat(data, dispatch),
    getProductsMappedwithCat: (data) => getProductsMappedwithCat(data, dispatch),
    getProductOutOfStock: (body) => getProductOutOfStock(body, dispatch),
    getProductOutOfStockCat: (body) => getProductOutOfStockCat(body, dispatch),
    Reset_Suggestions: () => Reset_Suggestions(dispatch),
    Toggle_Suggestions: (data) => Toggle_Suggestions(data,dispatch),
    changePage: (body) => changePage(body, dispatch),
    resetPage: () => resetPage(dispatch),

    initProductExpressStore: (data) => initExpressStoreProducts(data, dispatch),
    sortProducts: (data) => sortProducts(data, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
// export default MobileHeader;
