import React from 'react';
import styled from "styled-components";

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const DeliveryInfoKhmer = () => {
  return (
    <React.Fragment>
      <Heading>Delivery Information</Heading>
      <b>DELIVERY TIME:</b>
      <br />
      <br />
      Any orders made on the website will be delivered the same day or 24h later
      <br />
      Our service is available from Monday to Saturday, from 10am to 8pm
      <br />
      <br />
      <br />
      <br />
      <b>SAME DAY DELIVERY:</b>
      <br />
      <br />
      You can order and get delivered the same day <b>if you order before 2pm</b> and on a selection of products.<br /><br />
      You can identify the items eligible to the same day delivery with the mention &quot;GET IT TODAY&quot;<br /><br />
      If some items in your cart are not eligible, you can not get your order today. Simply delete it from your cart to have acces to the same day delivery
      <br />
      <br />
      <br />
      <br />
      {/*<div style={{textDecoration: 'line-through'}}>
          <b>SUNDAY DELIVERY:</b>
          <br />
          <br />
          You can now get delivered on Sunday! we can deliver from 10am to 6pm. if you want a delivery on Sunday : place your order on Saturday BEFORE 1PM
          </div>
          <br />
          Please note we have limited slots open
          <br />
          <br />
          <br />
          <br />*/}
      <b>PAYMENT INFORMATION: </b>
      <br />
      <br />
      The minimum order is <b>$10</b>
      <br />
      Exchange rate : $1 = 4100 Riels
      <br />
      <br />
      We accept payment :<br />
      <ul>
        <li>In cash, in USD and/or Riels</li>
        <li>By ABA QR code Transfer</li>
        <li>ABA, VISA, Mastercard during Check Out</li>
      </ul>
      <br />
      <br />
      <br />
      <br />
      <b>DELIVERY FEE:</b>
      <br />
      <br />
      Delivery fees are set as follows:
      <br />
      <br />
      <b>From $10 to $50 :</b> 0.5/km
      <br />
      <br />
      <b>From $50 and above: Free</b>
      <br />
      <br />
      <br />
      <br />
      <b>DELIVERY CONDITION:</b>
      <br />
      <br />
      You can choose a one-hour delivery time slot during the checkout process
      Our delivery crew will bring your order at your doorstep. (if you live in an apartment, please make sure we can get into the building)
      <br /><br />
      If a case of force majeure may force us to postpone chosen delivery dates and times, a customer service representative will call you
      <br /><br />
      Alternatively, you may authorize someone to receive your order.<br />
      (building management, neighbor, security…)
      <br />
      <br />
      <br />
      <br />

    </React.Fragment>
  )
}

export default DeliveryInfoKhmer;