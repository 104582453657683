import styled from "styled-components";
import { buttonStyle, colorStyle, buttonSize } from "../helpers/customVariant";


export const BrandButton=styled.button`
    cursor: pointer !important;
    text-align: center !important;
    border: 1px solid black !important;
    background-color: #FFFFFF;
    outline: none !important;
    padding: 0px !important;
    margin: 0px ;
    margin-right:2px;
    border-color: #B0B0B0 !important;
    border-radius: 30px !important;
    color: #222222 ;
    position: relative !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    font-size: 12px !important;
    line-height: 16px !important;
    height: 34px;
    display: flex;
    align-items: center;
    ._36rlri {
        display: inline-block !important;
    };
    ._1lz9hno {
        align-self: center !important;
        margin-left: 4px !important;
    }
    ._a7a5sx {
        color: rgb(113, 113, 113) !important;
    }
`;
export const BrandFilterWrapper=styled.div`
display:flex;
justify-content:space-between;
position:relative;
._1cnw8os:hover {
    border-color: rgba(0, 0, 0, 0.08) !important;
    background-color: #FFFFFF !important;
    color: #000000 !important;
    box-shadow: 0px 0px 0px 1px transparent, 0px 0px 0px 4px transparent, 0px 6px 16px rgba(0, 0, 0, 0.12) !important;
    -webkit-transform: scale(1.04) !important;
    -ms-transform: scale(1.04) !important;
    transform: scale(1.04) !important;
};
._1cnw8os {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    border-radius: 50% !important;
    border: 0px !important;
    outline: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
    color: #222222 !important;
    background-color: rgba(255, 255, 255, 0.9) !important;
    cursor: pointer !important;
    -ms-touch-action: manipulation !important;
    touch-action: manipulation !important;
    -webkit-align-items: center !important;
    align-items: center !important;
    -webkit-justify-content: center !important;
    justify-content: center !important;
    border-style: solid !important;
    border-width: 1px !important;
    background-clip: padding-box !important;
    border-color: rgba(0, 0, 0, 0.08) !important;
    box-shadow: 0px 0px 0px 1px transparent, 0px 0px 0px 4px transparent, 0px 2px 4px rgba(0, 0, 0, 0.18) !important;
    -webkit-transition: box-shadow 0.2s ease, -webkit-transform 0.25s ease, transform 0.25s ease !important;
    -moz-transition: box-shadow 0.2s ease, transform 0.25s ease !important;
    transition: box-shadow 0.2s ease, -ms-transform 0.25s ease, -webkit-transform 0.25s ease, transform 0.25s ease !important;
    width: 32px !important;
    min-width:32px !important;
    height: 32px !important;
};
._1cnw8os1 {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    border-radius: 50% !important;
    border: 0px !important;
    outline: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
    color: #222222 !important;
    background-color: rgba(255, 255, 255, 0.9) !important;
    cursor: pointer !important;
    -ms-touch-action: manipulation !important;
    touch-action: manipulation !important;
    -webkit-align-items: center !important;
    align-items: center !important;
    -webkit-justify-content: center !important;
    justify-content: center !important;
    border-style: solid !important;
    border-width: 1px !important;
    background-clip: padding-box !important;
    border-color: rgba(0, 0, 0, 0.08) !important;
    box-shadow: 0px 0px 0px 1px transparent, 0px 0px 0px 4px transparent, 0px 2px 4px rgba(0, 0, 0, 0.18) !important;
    -webkit-transition: box-shadow 0.2s ease, -webkit-transform 0.25s ease, transform 0.25s ease !important;
    -moz-transition: box-shadow 0.2s ease, transform 0.25s ease !important;
    transition: box-shadow 0.2s ease, -ms-transform 0.25s ease, -webkit-transform 0.25s ease, transform 0.25s ease !important;
    width: 32px !important;
    min-width:32px !important;
    height: 32px !important;
};
._a7a5sx {
    color: #717171 !important;
};
._1q59bef {
    display: inline-block;
    margin-right: 8px ;
    border-left: 1px solid #DDDDDD ;
    height: 36px ;
}
._1q59bef1 {
    display: inline-block ;
    margin-left: 8px ;
    border-left: 1px solid #DDDDDD ;
    height: 36px ;
}
.brandsConainer{
    min-height: 34px;
    width: 100%;
    max-height: 34px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
}
.brand-filter-icon{
    display: block;
    fill: none;
    height: 12px;
    width: 12px;
    stroke: currentcolor;
    stroke-width: 5.33333;
    overflow: visible;
}
.brand-filter-mobile-icon{
    display:block;
    fill: none;
    height: 16px;
    width: 16px;
    stroke: rgb(113, 113, 113);
    stroke-width: 4;
    overflow: visible;
}
.brand-filter-mobile-button{
    display:none;
}

@media screen and (max-width:600px){
    .brandsConainer,
    .brand-filter-button{display:none;
    }
    .brand-filter-mobile-button{
        display:flex;       
       }
   
  }
`;



export const BrandPopupWrapper=styled.div`
    position: absolute;
    width:100%;
    z-index: 100;
    top: 45px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 37px !important;
    border-width: 0.5px !important;
    border-style: solid !important;
    border-color: rgba(118, 118, 118, 0.28) !important;
    display:flex;
    flex-direction:column;
    ._pwbsgx {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        padding-left: 14px !important;
        padding-right: 14px !important;
        border-top: 1px solid rgb(235, 235, 235) !important;
        font-size:12px;
    };
    ._1qbrhv4 {
        -webkit-box-pack: justify !important;
        -webkit-box-align: center !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
    };
    ._gby1jkw:disabled {
        cursor: not-allowed !important;
        opacity: 1 !important;
        color: rgb(221, 221, 221) !important;
        border-width: initial !important;
        border-style: none !important;
        border-color: initial !important;
        border-image: initial !important;
        background: transparent !important;
    };
    ._gby1jkw {
        cursor: pointer !important;
        display: inline-block !important;
        position: relative !important;
        text-align: center !important;
        width: auto !important;
        touch-action: manipulation !important;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
        line-height: 20px !important;
        font-weight: 600 !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        color: rgb(34, 34, 34) !important;
        margin: 0px -10px !important;
        border-radius: 8px !important;
        outline: none !important;
        transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important;
        border-width: initial !important;
        border-style: none !important;
        border-color: initial !important;
        border-image: initial !important;
        background: transparent !important;
        text-decoration: underline !important;
    };
    ._ls0e43 {
        margin-left: auto !important;
    };
    ._ajuxzjo {
        cursor: pointer !important;
        display: inline-block !important;
        position: relative !important;
        text-align: center !important;
        width: auto !important;
        touch-action: manipulation !important;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
        line-height: 18px !important;
        font-weight: 600 !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
        color: rgb(255, 255, 255) !important;
        margin: 0px !important;
        text-decoration: none !important;
        border-radius: 8px !important;
        outline: none !important;
        transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important;
        border-width: initial !important;
        border-style: none !important;
        border-color: initial !important;
        border-image: initial !important;
        background: #389244 !important;
        
        &.express { background: #f01266 !important }
    };
@media screen and (max-width:600px){
    &{left:0px;}
}
`;