import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from "@redq/reuse-modal";
import { ProfileProvider } from "../contexts/profile/profile.provider";
import SettingsContent from "../containers/Profile/Settings/Settings";
import { useDeviceType } from "../helper/useDeviceType";
import { connect } from "react-redux";
import {
  PageWrapper,
  SidebarSection,
  ContentBox,
} from "../containers/Profile/Profile.style";
// import Sidebar from '../containers/Profile/Sidebar/Sidebar';
import SiteFooter from "../components/SiteFooter/SiteFooter";
import { FormattedMessage } from "react-intl";
import {
  addAddress,
  getAddress,
  updateAddress,
  deleteAddress,
  updateProfile,
} from "../store/actions/userAction";
import { doLoggedInPasswordChange } from "../store/actions/authAction";

const ProfilePage = (props, { userAgent }) => {
  const deviceType = useDeviceType(userAgent);
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      document.getElementById("joinBTn").click();
    }
    else{
      props.getAddress();
    }
  }, []);
  return (
    <>
      <ProfileProvider initData={""}>
        <Modal>
          <PageWrapper>
            <ContentBox>
              <SettingsContent deviceType={deviceType} props={props} />
            </ContentBox>
          </PageWrapper>
        </Modal>
      </ProfileProvider>
    </>
  );
};
ProfilePage.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};
const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    modalData: state.productReducer.modalData,
    user_address: state.userReducer.address,
    loading: state.globalReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAddress: (body) => addAddress(body, dispatch),
    updateAddress: (body) => updateAddress(body, dispatch),
    deleteAddress: (body) => deleteAddress(body, dispatch),
    getAddress: () => getAddress(dispatch),
    updateProfile: (body) => updateProfile(body, dispatch),
    doLoggedInPasswordChange: (body) =>
      doLoggedInPasswordChange(body, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
