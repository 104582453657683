import React, { useState, useContext, useEffect } from "react";
import {
  Wrapper,
  Container,
  Heading,
  LinkButton,
} from "./SignInOutForm.style";
import { AuthContext } from "../../contexts/auth/auth.context";
import { FormattedMessage } from "react-intl";
const VerifyLink = () => {
  const { authDispatch } = useContext(AuthContext);

  const toggleSignInForm = () => {
    authDispatch({
      type: "SIGNIN",
    });
  };

  return (
    <Wrapper>
      <Container>
        <Heading>
          <LinkButton onClick={toggleSignInForm}>
            <FormattedMessage
              id="verifyOTP"
              defaultMessage="Registered successfully, Please login."
            />
          </LinkButton>
        </Heading>
        <br />
        <br />
        <br />
        {/* <Offer style={{ padding: "10px 0px 15px 0" }}>
          <FormattedMessage
            id="alreadyHaveAccount"
            defaultMessage="Back to Login?"
          />{" "}
          <LinkButton onClick={toggleSignInForm}>
            <FormattedMessage id="loginBtnText" defaultMessage="Login" />
          </LinkButton>
        </Offer> */}
      </Container>
    </Wrapper>
  );
};

export default VerifyLink;
