import React, { useState, useEffect, Suspense } from "react";
import { connect } from "react-redux";
import {useCart} from "../../contexts/cart/use-cart";
import InfiniteScroll from 'react-infinite-scroller';
import {initBasket} from "../../store/actions/cartAction";
import {
  changePage,
  getProductBrands,
  getProductOutOfStock,
  getProductOutOfStockCat,
  getProductsMappedwithCat,
  getProductsMappedwithSubCat,
  getSortProductsName,
  getSortProductsPrice,
  initCategoryExpressStore,
  initCategoryFilter,
  initProductBrand,
  productCategory,
  ProductModalData, resetPage,
  searchStoreProduct,
  SearchStoreProductByBrandRef,
  searchSuggestions,
  sortByNameProduct,
  sortProducts, Toggle_Suggestions
} from "../../store/actions/productAction";
import {ContentSection, MainContentArea} from "../../styled/pages.style";
import LazyLoader from "../../components/LazyLoader/LazyLoader";
import {BrandPageWrapper} from "../../containers/AllBrands/AllBrands.style";
import Products from "../../containers/Products/Products";
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import {useDeviceType} from "../../helper/useDeviceType";
import expressService from "../../services/expressService";
import {initExpressStoreProducts, initExpressStoreSelected} from "../../store/actions/expressAction";
import CartPopUp from "../../containers/Cart/CartPopUp";
import {offerSlider, oosModified} from "../../store/actions/globalAction";
import t from '../../i18n/translate';

const PAGE_TYPE = "grocery";

const ExpressSearchResult = (props, { userAgent }) => {
  const {fetchOrderItems, cartclose} = useCart();
  const user = localStorage.getItem('user');
  const deviceType = useDeviceType(userAgent);
  const [isFetchItem, setIsFetchItem] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const limit = 50;
  const textSearch = window.location.pathname.split('/').splice(-1)[0];

  let mql = window.matchMedia("all and (min-width: 769px)");
  const targetRef = React.useRef(null);

  // Clear basket when close windows
  window.onbeforeunload = () => cartclose();

  // Is logged in and init Express mode
  useEffect(() => {
    document.getElementsByClassName("product-cart")[0].style.visibility = "hidden";
    props.initBasket('express');
    localStorage.setItem('expressMode', true);

    let filteredProducts = {brandIdList: [], categorySelected: [], subCategorySelected: [], outOfStock: 0, search: textSearch, sort: ''};
    props.sortProducts(filteredProducts);

    if(user) {
      fetchOrderItems('express');
      props.initCategoryExpressStore({data: []});
    }
  }, [user]);

  useEffect( async () => {
    if(!props.products.length) {
      const dataResp = await expressService.searchItems([], [], [], [], 1, textSearch);

      if(dataResp.brandList.length) {
        const itemsStore = dataResp.list;
        const brandList = dataResp.brandList.map(item => { return {value: item.brand_id, label: item.name} });

        props.initProductExpressStore( itemsStore );
        props.initProductBrand(dataResp.brandList);
        props.initCategoryFilter(brandList);
      }
      else {
        setHasMore(false);
        setIsFetchItem(false);
      }
    }
  }, [props.products]);

  const handleLoadMore = async (page) => setIsFetchItem(true);

  // Scroll load more
  useEffect(async () => {
    if (isFetchItem) {
      let brandIdList = [];
      let isOutOfStock = 0;
      let sort = '';
      let categorySelected = [];
      let subCategorySelected = [];
      const offsetItem = offset + limit;

      if (!Array.isArray(props.filteredProducts) && Object.keys(props.filteredProducts).length) {
        brandIdList = props.filteredProducts.brandIdList;
        categorySelected = props.filteredProducts.categorySelected;
        subCategorySelected = props.filteredProducts.subCategorySelected;
        isOutOfStock = props.filteredProducts.outOfStock;
        sort = props.filteredProducts.sort;
      } else {
        categorySelected = props.filteredProducts.categorySelected;
      }

      const dataResp = await expressService.searchItems([], brandIdList, categorySelected, subCategorySelected, 1, textSearch, sort, offsetItem, limit);
      if (dataResp.list.length) {
        let newProducts = props.products;
        dataResp.list.forEach(item => newProducts.push(item)); // Merge Items to store
        setOffset(offsetItem);
        setIsFetchItem(false);
        if (dataResp.list.length < limit) {
          setHasMore(false)
        }
        props.initProductExpressStore(newProducts); // Update product store
      } else { // Disable load more
        setHasMore(false);
        setIsFetchItem(false);
      }
    }
  }, [isFetchItem])

  return (
    <InfiniteScroll pageStart={page}
                    loadMore={handleLoadMore}
                    className="infinite-scroll-custom"
                    initialLoad={false}
                    hasMore={hasMore}
                    threshold={250}
    >
      <MainContentArea style={{paddingTop: mql.matches ? "9vh" : "0px"}}>
        <ContentSection id="productBlock" style={{width: "100%"}}>
          <div ref={targetRef}>
            <Suspense fallback={<LazyLoader/>}>
              <div>
                <span style={{ fontWeight: 700, paddingLeft: 15, paddingRight: 15 }}>{ t('search_item_result_title') }</span>
              </div>

              <BrandPageWrapper className="express">
                <div className="product-list">
                  <Products
                    type={PAGE_TYPE}
                    deviceType={deviceType}
                    fetchLimit={16}
                    storeId={null}
                    props={props}
                    handleLoadMore={handleLoadMore}
                  />
                </div>
              </BrandPageWrapper>
            </Suspense>
          </div>
        </ContentSection>
      </MainContentArea>

      <CartPopUp deviceType={deviceType}/>
    </InfiniteScroll>
  )
};

ExpressSearchResult.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};

const mapStateToProps = (state) => {
  return {
    cartType: state.cartReducer.cartType,
    success: state.authReducer.success,
    modalData: state.productReducer.modalData,
    products: state.expressReducer.products,
    allproducts: state.productReducer.allproducts,
    category: state.productReducer.category,
    sliders: state.globalReducer.sliders,
    loading: state.globalReducer.loading,
    brands: state.productReducer.brands,
    filterBrands: state.productReducer.filterBrands,
    FilteredNameProducts: state.productReducer.FilteredNameProducts,
    filteredProducts: state.productReducer.filteredProducts,
    suggested_subcats: state.productReducer.suggested_subcats,
    all_suggested_subcats: state.productReducer.all_suggested_subcats,
    oos: state.globalReducer.oos,
    page: state.productReducer.page,
    cat_refs: state.productReducer.cat_refs,
    storeSelected: state.expressReducer.storeSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initBasket: (data) => initBasket(data, dispatch),
    initProductExpressStore: (data) => initExpressStoreProducts(data, dispatch),
    sortProducts: (data) => sortProducts(data, dispatch),
    initCategoryExpressStore: (data) => initCategoryExpressStore(data, dispatch),
    initCategoryFilter: (data) => initCategoryFilter(data, dispatch),
    initProductBrand: (data) => initProductBrand(data, dispatch),
    productCategory: (data) => productCategory(data, dispatch),
    ProductModalData: (data) => ProductModalData(data, dispatch),
    initExpressStoreSelected: (data) => initExpressStoreSelected(data, dispatch),
    searchSuggestions: (data) => searchSuggestions(data, dispatch),
    searchStoreProduct: (data) => searchStoreProduct(data, dispatch),
    getProductsMappedwithSubCat: (data) => getProductsMappedwithSubCat(data, dispatch),
    getProductsMappedwithCat: (data) => getProductsMappedwithCat(data, dispatch),
    offerSlider: (data) => offerSlider(data, dispatch),
    getProductOutOfStock: (body) => getProductOutOfStock(body, dispatch),
    getProductOutOfStockCat: (body) => getProductOutOfStockCat(body, dispatch),
    getProductBrands: () => getProductBrands(dispatch),
    getSortProductsName: (type, products) => getSortProductsName(type, products, dispatch),
    getSortProductsPrice: (type, products) => getSortProductsPrice(type, products, dispatch),
    sortByNameProduct: (type, products) => sortByNameProduct(type, products, dispatch),
    SearchStoreProductByBrandRef: (body) => SearchStoreProductByBrandRef(body, dispatch),
    oosModified: (data) => oosModified(data, dispatch),
    Toggle_Suggestions: (data) => Toggle_Suggestions(data, dispatch),
    changePage: (data) => changePage(data, dispatch),
    resetPage: () => resetPage(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExpressSearchResult);
