import React, { useState, useEffect } from "react";

// ------------ Lib ------------------
import _ from "lodash";

// ------------ Components ------------
import { Modal } from "@redq/reuse-modal";
import {
    Row,
    Col,
    Button,
    Form,
    ListGroup,
    Toast,
    Spinner
  } from "react-bootstrap";
import { Switch } from "@material-ui/core";

// -------------- APIs ---------------
import API from "../../network/dashboardAPI";

// -------------- Style --------------
import styled from "styled-components";
import "react-datetime/css/react-datetime.css";

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const labelStyle = {
    lineHeight: "2rem"
}

const DeliExpressSettings = (props) => {
    /*
    *   =============== States =================
    * */

    // --------------- loading states ----------
    const [isRequesting, setIsRequesting] = useState(false);


    // --------------- Express setting States ------------------
    const [expressSettingId, setExpressSettingId] = useState(null);
    const [isEnable, setIsEnable] = useState(false);
    const [deliveryMaxRange, setDeliveryMaxRange] = useState(null);
    const [expressDeliveryFeePerKm, setExpressDeliveryFeePerKm] = useState(null);
    const [itemTotalToMakeDeliveryCostFree, setItemTotalToMakeDeliveryCostFree] = useState(null);
    const [expressDeliveryFeeFixed, setExpressDeliveryFeeFixed] = useState(null);
    const [slotCount, setSlotCount] = useState(null);
    const [defaultEstimateDeliveryMinute, setDefaultEstimateDeliveryMinute] = useState(null);
    const [expressProvinceDeliveryFee, setExpressProvinceDeliveryFee] = useState(null);

    // ----------------- Normall setting states --------------------
    const [shopSettingId, setShopSettingId] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [radiusToAcceptOrders, setRadiusToAcceptOrders] = useState(null);
    const [provinceDeliveryPrice, setProvinceDeliveryPrice] = useState(0);
    const [deliveryFeeFixed, setDeliveryFeeFixed] = useState(null);
    const [deliveryFeePerKm, setDeliveryFeePerKm] = useState(null);
    const [selfDeliveryItemTotalToMakeDeliveryCostFree, setSelfDeliveryItemTotalToMakeDeliveryCostFree] = useState(null);


    const [expressMaxOrder, setExpressMaxOrder] = useState(0);


    // toast states
    const [toastBgColor, setToastBgColor] = useState('bg-success');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("Delete successful");


    // --------------- Form validate -----------
    const [expressFormError, setExpressFormError] =  useState({
        deliveryMaxRange: false,
        expressDeliveryFeePerKm: false,
        itemTotalToMakeDeliveryCostFree: false,
        expressDeliveryFeeFixed: false,
        slotCount: false,
        defaultEstimateDeliveryMinute: false,
        expressProvinceDeliveryFee: false
    });

    const [shopFormError, setShopFormError] =  useState({
        radiusToAcceptOrders: false,
        provinceDeliveryPrice: false,
        deliveryFeeFixed: false,
        deliveryFeePerKm: false,
        selfDeliveryItemTotalToMakeDeliveryCostFree: false
    });

    const [deliveryProvincePriceError, setDeliveryProvincePriceError] = useState({
        isError: false,
        message: ""
    });
    const [deliveryRangeError, setDeliveryRangeError] = useState({
        isError: false,
        message: ""
    });
    const [deliveryFeePerKhError, setDeliveryFeePerKmError] = useState({
        isError: false,
        message: ""
    });

    /*
    *   ============================ API Methods =====================
    * */

    const getExpressSetting = async () => {
        setIsRequesting(true);

        await API.getExpressSetting()
            .then(res => res.json())
            .then(res => {
                if (res.success === true && !_.isEmpty(res.data)) {
                    setExpressFormData(res.data);

                    setIsRequesting(false);
                } else {
                    setIsRequesting(false);
                }
            });
    }

    const getShopSetting = async () => {
        setIsRequesting(true);

        await API.getShopSetting()
            .then(res => res.json())
            .then(res => {
                if (res.success === true && !_.isEmpty(res.data)) {
                    setShopFormData(res.data);

                    setIsRequesting(false);
                } else {
                    setIsRequesting(false);
                }
            });
    }

    const updateExpressSetting = async () => {
        setIsRequesting(true);

        let body = getExpressFormData();

        await API.updateExpressSetting(body)
            .then(res => res.json())
            .then(res => {
                if(res.success === true && !_.isEmpty(res.data)){
                    setExpressFormData(res.data);

                    setIsRequesting(false);
                }else {
                    handleShowToast("ERROR")
                    setIsRequesting(false);
                }
            });
    }

    const updateShopSetting = async () => {
        setIsRequesting(true);

        let body = getShopFormData();

        await API.updateShopSetting(body)
            .then(res => res.json())
            .then(res => {
                if(res.success === true && !_.isEmpty(res.data)){
                    setShopFormData(res.data);

                    handleShowToast("SUCCESS");
                    setIsRequesting(false);
                }else {

                    handleShowToast("ERROR")
                    setIsRequesting(false);
                }
            });
    }

   /*
   *    ============================ Methods =========================
   * */

    const setExpressFormData = (data) => {
        const {
            id,
            delivery_max_range,
            delivery_fee_per_km,
            item_total_to_make_delivery_cost_free,
            delivery_fee_fixed,
            is_enable,
            slot_count,
            default_estimate_delivery_minute,
            province_delivery_fee
        } = data;

        setExpressSettingId(id);
        setIsEnable(is_enable);
        setExpressDeliveryFeePerKm(delivery_fee_per_km);
        setDeliveryMaxRange(delivery_max_range);
        setItemTotalToMakeDeliveryCostFree(item_total_to_make_delivery_cost_free);
        setExpressDeliveryFeeFixed(delivery_fee_fixed);
        setSlotCount(slot_count);
        setDefaultEstimateDeliveryMinute(default_estimate_delivery_minute);
        setExpressProvinceDeliveryFee(province_delivery_fee);
    }

    const getExpressFormData = () => {
        return {
            id: expressSettingId,
            delivery_max_range: convertStringToNumber(deliveryMaxRange),
            delivery_fee_per_km: convertStringToNumber(expressDeliveryFeePerKm),
            item_total_to_make_delivery_cost_free: convertStringToNumber(itemTotalToMakeDeliveryCostFree),
            delivery_fee_fixed: convertStringToNumber(expressDeliveryFeeFixed),
            is_enable: isEnable,
            slot_count: convertStringToNumber(slotCount),
            default_estimate_delivery_minute: convertStringToNumber(defaultEstimateDeliveryMinute),
            province_delivery_fee: convertStringToNumber(expressProvinceDeliveryFee)
        };
    }

    const setShopFormData = (data) => {
        const {
            Latitude,
            longitude,
            radius_to_accept_orders,
            delivery_fee_per_kms,
            self_delivery_item_total_to_make_delivery_cost_free,
            delivery_fee_fixed,
            province_delivery_price
        } = data;

        setLatitude(Latitude);
        setLongitude(longitude);
        setRadiusToAcceptOrders(radius_to_accept_orders);
        setDeliveryFeePerKm(delivery_fee_per_kms);
        setSelfDeliveryItemTotalToMakeDeliveryCostFree(self_delivery_item_total_to_make_delivery_cost_free);
        setDeliveryFeeFixed(delivery_fee_fixed);
        setProvinceDeliveryPrice(province_delivery_price);
    }

    const getShopFormData = (data) => {
        return {
            Latitude: latitude,
            longitude,
            radius_to_accept_orders: convertStringToNumber(radiusToAcceptOrders),
            delivery_fee_per_kms: convertStringToNumber(deliveryFeePerKm),
            self_delivery_item_total_to_make_delivery_cost_free: convertStringToNumber(selfDeliveryItemTotalToMakeDeliveryCostFree),
            delivery_fee_fixed: convertStringToNumber(deliveryFeeFixed),
            province_delivery_price: convertStringToNumber(provinceDeliveryPrice)
        };
    }

    const convertStringToNumber = (number) => {
        return number? parseFloat(number): 0;
    }

    const checkExpressFormValidate = () => {
        let tmpExpressFormError = {
            deliveryMaxRange: false,
            expressDeliveryFeePerKm: false,
            itemTotalToMakeDeliveryCostFree: false,
            expressDeliveryFeeFixed: false,
            slotCount: false,
            defaultEstimateDeliveryMinute: false,
            expressProvinceDeliveryFee: false
        }

        if(deliveryMaxRange < 0) tmpExpressFormError.deliveryMaxRange = true;

        if(expressDeliveryFeePerKm < 0) tmpExpressFormError.expressDeliveryFeePerKm = true;

        if(itemTotalToMakeDeliveryCostFree < 0) tmpExpressFormError.itemTotalToMakeDeliveryCostFree = true;

        if(expressDeliveryFeeFixed < 0) tmpExpressFormError.expressDeliveryFeeFixed = true;

        if(slotCount < 0) tmpExpressFormError.slotCount = true;

        if(defaultEstimateDeliveryMinute < 0) tmpExpressFormError.defaultEstimateDeliveryMinute = true;

        if(expressProvinceDeliveryFee < 0) tmpExpressFormError.expressProvinceDeliveryFee = true;

        setExpressFormError(tmpExpressFormError);

        return Object.values(tmpExpressFormError).every(el => el === false);
    }

    const checkShopFormValidate = () => {
        let tmpShopFormError = {
            radiusToAcceptOrders: false,
            provinceDeliveryPrice: false,
            deliveryFeeFixed: false,
            deliveryFeePerKm: false,
            selfDeliveryItemTotalToMakeDeliveryCostFree: false
        }

        if(radiusToAcceptOrders < 0) tmpShopFormError.radiusToAcceptOrders = true;

        if(provinceDeliveryPrice < 0) tmpShopFormError.provinceDeliveryPrice = true;

        if(deliveryFeeFixed < 0) tmpShopFormError.deliveryFeeFixed = true;

        if(deliveryFeePerKm < 0) tmpShopFormError.deliveryFeePerKm = true;

        if(selfDeliveryItemTotalToMakeDeliveryCostFree < 0) tmpShopFormError.selfDeliveryItemTotalToMakeDeliveryCostFree = true;

        setShopFormError(tmpShopFormError);

        return Object.values(tmpShopFormError).every(el => el === false);
    }


    // ---------------------------- Compute --------------------------
    useEffect(() => {
        getExpressSetting();
        getShopSetting();
    }, []);


    //--------------------------- Events handler
    const handleEnableExpress = e => setIsEnable(e.target.checked);
    const handleChangeExpressMaxOrder = e => setExpressMaxOrder(e.target.value);

    const handleSave = async e => {
        if(checkExpressFormValidate() && checkShopFormValidate()){
            await updateExpressSetting();
            await updateShopSetting();
        }else {
            console.log("not success");
        }
    }

    // toast event
    const handleShowToast = (type) => {
        setShowToast(true);

        if(type === "SUCCESS"){
            setToastBgColor("bg-success");
            setToastMessage("Operation successful.");
        }else if(type === "ERROR"){
            setToastBgColor("bg-danger");
            setToastMessage("Operation error.");
        }
    }

    return (
        <Modal>
            <HelpPageWrapper>
                <Row>
                    <Col sm={11}>
                        <h4>Settings</h4>
                    </Col>
                </Row>
                <Row>
                    <Col sm={11}>
                        <Form>
                            <Row className="mt-4">
                                <Col sm={3}>

                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Label className="font-weight-bold">Shop Settings</Form.Label>
                                    <ListGroup style={{height: "93%"}}>
                                        <ListGroup.Item style={{height: "100%"}}>
                                            <Row className="mt-2">
                                                <Col sm={6}>
                                                    <Form.Label style={labelStyle}>Maximum delivery range(meter)</Form.Label>
                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Control isInvalid={shopFormError.radiusToAcceptOrders} type="number" disabled={isRequesting} min={0} placeholder="0" value={radiusToAcceptOrders} onChange={(e) => setRadiusToAcceptOrders(e.target.value)}/>
                                                    {shopFormError.radiusToAcceptOrders && <Form.Label className="text-danger" style={{"font-size": "12px"}}>Value must be greater than 0!</Form.Label>}
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col sm={6}>
                                                    <Form.Label style={labelStyle}>Delivery fee per km($)</Form.Label>
                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Control isInvalid={shopFormError.deliveryFeePerKm} type="number" disabled={isRequesting} min={0} placeholder="0" value={deliveryFeePerKm} onChange={(e) => setDeliveryFeePerKm(e.target.value)}/>
                                                    {shopFormError.deliveryFeePerKm && <Form.Label className="text-danger" style={{"font-size": "12px"}}>Value must be greater than 0!</Form.Label>}
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col sm={6}>
                                                    <Form.Label style={labelStyle}>Total amount to make free delivery($)</Form.Label>
                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Control isInvalid={shopFormError.selfDeliveryItemTotalToMakeDeliveryCostFree} type="number" disabled={isRequesting} min={0} placeholder="0" value={selfDeliveryItemTotalToMakeDeliveryCostFree} onChange={(e) => setSelfDeliveryItemTotalToMakeDeliveryCostFree(e.target.value)}/>
                                                    {shopFormError.selfDeliveryItemTotalToMakeDeliveryCostFree && <Form.Label className="text-danger" style={{"font-size": "12px"}}>Value must be greater than 0!</Form.Label>}
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col sm={6}>
                                                    <Form.Label style={labelStyle}>Fixed delivery fee($)</Form.Label>
                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Control isInvalid={shopFormError.deliveryFeeFixed} type="number" disabled={isRequesting} min={0} placeholder="0" value={deliveryFeeFixed} onChange={(e) => setDeliveryFeeFixed(e.target.value)} />
                                                    {shopFormError.deliveryFeeFixed && <Form.Label className="text-danger" style={{"font-size": "12px"}}>Value must be greater than 0!</Form.Label>}
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col sm={6}>
                                                    <Form.Label style={labelStyle}>Delivery fee for province($):</Form.Label>
                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Control isInvalid={shopFormError.provinceDeliveryPrice} type="number" disabled={isRequesting} min={0} placeholder="0" value={provinceDeliveryPrice} onChange={(e) => setProvinceDeliveryPrice(e.target.value)} />
                                                    {shopFormError.provinceDeliveryPrice && <Form.Label className="text-danger" style={{"font-size": "12px"}}>Value must be greater than 0!</Form.Label>}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>

                                <Col sm={6}>
                                    <Form.Label className="font-weight-bold">Express Settings</Form.Label>
                                    <ListGroup>
                                        <ListGroup.Item>
                                            <Row className="mt-2">
                                                <Col sm={6}>
                                                    <Form.Label style={labelStyle}>Enable Express</Form.Label>
                                                </Col>

                                                <Col sm={6}>
                                                    <Switch
                                                        checked={isEnable}
                                                        onChange={handleEnableExpress}
                                                        name="checkedB"
                                                        color="primary"
                                                        disabled={isRequesting}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col sm={6}>
                                                    <Form.Label style={labelStyle}>
                                                        <div style={{lineHeight: "1"}}>Maximum orders accepted</div>
                                                        <div style={{fontSize: "11px", lineHeight: "2"}}>Maximum orders accepted at the same time.</div>
                                                    </Form.Label>
                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Control isInvalid={expressFormError.slotCount} type="number" disabled={isRequesting} min={0} placeholder="0" value={slotCount} onChange={(e) => setSlotCount(e.target.value)} />
                                                    {expressFormError.slotCount && <Form.Label className="text-danger" style={{"font-size": "12px"}}>Value must be greater than 0!</Form.Label>}
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col sm={6}>
                                                    <Form.Label style={labelStyle}>Maximum delivery range(meter)</Form.Label>
                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Control isInvalid={expressFormError.deliveryMaxRange} type="number" disabled={isRequesting} min={0} placeholder="0" value={deliveryMaxRange} onChange={(e) => setDeliveryMaxRange(e.target.value)}/>
                                                    {expressFormError.deliveryMaxRange && <Form.Label className="text-danger" style={{"font-size": "12px"}}>Value must be greater than 0!</Form.Label>}
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col sm={6}>
                                                    <Form.Label style={labelStyle}>Delivery fee per km($)</Form.Label>
                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Control isInvalid={expressFormError.expressDeliveryFeePerKm} type="number" disabled={isRequesting} min={0} placeholder="0" value={expressDeliveryFeePerKm} onChange={(e) => setExpressDeliveryFeePerKm(e.target.value)}/>
                                                    {expressFormError.expressDeliveryFeePerKm && <Form.Label className="text-danger" style={{"font-size": "12px"}}>Value must be greater than 0!</Form.Label>}
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col sm={6}>
                                                    <Form.Label style={labelStyle}>Total amount to make free delivery($)</Form.Label>
                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Control isInvalid={expressFormError.itemTotalToMakeDeliveryCostFree} type="number" disabled={isRequesting} min={0} placeholder="0" value={itemTotalToMakeDeliveryCostFree} onChange={(e) => setItemTotalToMakeDeliveryCostFree(e.target.value)}/>
                                                    {expressFormError.itemTotalToMakeDeliveryCostFree && <Form.Label className="text-danger" style={{"font-size": "12px"}}>Value must be greater than 0!</Form.Label>}
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col sm={6}>
                                                    <Form.Label style={labelStyle}>Fixed delivery fee($)</Form.Label>
                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Control isInvalid={expressFormError.expressDeliveryFeeFixed} type="number" disabled={isRequesting} min={0} placeholder="0" value={expressDeliveryFeeFixed} onChange={(e) => setExpressDeliveryFeeFixed(e.target.value)} />
                                                    {expressFormError.expressDeliveryFeeFixed && <Form.Label className="text-danger" style={{"font-size": "12px"}}>Value must be greater than 0!</Form.Label>}
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col sm={6}>
                                                    <Form.Label style={labelStyle}>Estimate delivery time(minute):</Form.Label>
                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Control isInvalid={expressFormError.defaultEstimateDeliveryMinute} type="number" disabled={isRequesting} min={0} placeholder="0" value={defaultEstimateDeliveryMinute} onChange={(e) => setDefaultEstimateDeliveryMinute(e.target.value)} />
                                                    {expressFormError.defaultEstimateDeliveryMinute && <Form.Label className="text-danger" style={{"font-size": "12px"}}>Value must be greater than 0!</Form.Label>}
                                                </Col>
                                            </Row>

                                            <Row className="mt-2">
                                                <Col sm={6}>
                                                    <Form.Label style={labelStyle}>Delivery fee for province($):</Form.Label>
                                                </Col>

                                                <Col sm={6}>
                                                    <Form.Control isInvalid={expressFormError.expressProvinceDeliveryFee} type="number" disabled={isRequesting} min={0} placeholder="0" value={expressProvinceDeliveryFee} onChange={(e) => setExpressProvinceDeliveryFee(e.target.value)} />
                                                    {expressFormError.expressProvinceDeliveryFee && <Form.Label className="text-danger" style={{"font-size": "12px"}}>Value must be greater than 0!</Form.Label>}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} className="d-flex justify-content-center mt-4">
                                    <Button variant="primary" disabled={isRequesting} onClick={handleSave}>
                                        {
                                            isRequesting?
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />:
                                                null
                                        }

                                        <span className="pl-2 pr-2">Save</span>
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

                <Toast className={toastBgColor}
                       onClose={() => setShowToast(false)}
                       show={showToast}
                       delay={3000}
                       autohide
                       style={{
                           position: 'fixed',
                           top: 70,
                           right: 35,
                           zIndex: 1041,
                           width: "300px"
                       }}
                >
                    <Toast.Header>
                        <strong className="mr-auto m-3">{toastMessage}</strong>
                    </Toast.Header>
                </Toast>
            </HelpPageWrapper>
        </Modal>
    );
}

export default DeliExpressSettings;
