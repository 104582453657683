import React, { useState } from "react";
import { Button, Modal } from 'react-bootstrap'
import { useSelector } from "react-redux";
import PublishSharpIcon from '@material-ui/icons/PublishSharp';
import moment from 'moment';
import * as excelJS from "exceljs";
import { saveAs } from "file-saver";

const ExportOrders = (props ) => {

  const [isLoading, setIsLoading] = useState(false);

  const supplierOrdersStore = useSelector(state => state.dashboardReducer.supplierOrders);

  const workbook = new excelJS.Workbook();
  workbook.created = new Date();
  workbook.modified = new Date();

  const doExportOrders = async () => {

    if(!supplierOrdersStore.length) {
      alert("No data to export")
    }
    else {

      const filename = moment().format('YYYYMMDDhhmmss');

      // Create a worksheet
      let worksheet = workbook.addWorksheet(`Order - ${filename}`, {
        pageSetup: {
          paperSize: 9,
          orientation:'portrait',
          showGridLines: false
        }
      });

      // Column Width
      worksheet.columns = [
        { width: 40 },
        { width: 20 },
        { width: 10 },
        { width: 15 },
        { width: 15 },
        { width: 10 },
      ];

      let rowIndex = 0;
      supplierOrdersStore.map(order => {
        let tableContent = [];
        let total = {};

        if(order.itemsLists.length) {

          rowIndex++;

          order.itemsLists.forEach(row => {
            let cost = parseFloat(row.item_MRP).toFixed(2);
            let totalCost = parseFloat(row.item_MRP * row.quantity).toFixed(2);

            tableContent.push([
              row.store_item_infos[0].name,
              row.store_item_infos[0].product_code,
              row.quantity,
              row.item_price_name,
              '$'+cost,
              '$'+totalCost
            ]);

            if(typeof total[order.order_id] === 'undefined') {
              total[order.order_id] = {
                quantity: row.quantity,
                cost: totalCost
              };
            }
            else {
              total[order.order_id].quantity += row.quantity;
              total[order.order_id].cost = (parseFloat(total[order.order_id].cost) + parseFloat(totalCost)).toFixed(2);
            }
          });

          // Merge Cells
          worksheet.mergeCells(`A${rowIndex}:F${rowIndex}`);
          // Font size
          worksheet.getRow(rowIndex).font = { bold: true };
          // Align Middle Center
          worksheet.getCell(`A${rowIndex}`).alignment = { vertical: 'middle', horizontal: 'center' };
          // Header - Order Label
          const cell1 = worksheet.getCell(`A${rowIndex}`);
          cell1.value = "Order n°";
          cell1.fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFD3D3D3'},
            bgColor:{argb:'FF0000FF'}
          };
          rowIndex += 1; // +1

          // Merge Cells
          worksheet.mergeCells(`A${rowIndex}:F${rowIndex}`);
          // Font size
          worksheet.getRow(rowIndex).font = { size: 20, bold: true };
          // Align Middle Center
          worksheet.getCell(`A${rowIndex}`).alignment = { vertical: 'middle', horizontal: 'center' };
          // Header - Order ID
          const cell2 = worksheet.getCell(`A${rowIndex}`);
          cell2.value = order.order_id;
          rowIndex += 1; // +1

          // Merge Cells
          worksheet.mergeCells(`A${rowIndex}:F${rowIndex}`);
          // Font size
          worksheet.getRow(rowIndex).font = { bold: true };
          // Align Middle Center
          worksheet.getCell(`A${rowIndex}`).alignment = { vertical: 'middle', horizontal: 'center' };
          // Header - Order Label
          const cell3 = worksheet.getCell(`A${rowIndex}`);
          cell3.value = "DELIVERY FINAL CUSTOMER";
          cell3.fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFD3D3D3'},
            bgColor:{argb:'FF0000FF'}
          };
          rowIndex += 1; // +1

          // Merge Cells
          worksheet.mergeCells(`A${rowIndex}:F${rowIndex}`);
          // Font size
          worksheet.getRow(rowIndex).font = { size: 20, bold: true };
          // Align Middle Center
          worksheet.getCell(`A${rowIndex}`).alignment = { vertical: 'middle', horizontal: 'center' };
          // Header - Delivery Date/Time
          const cell4 = worksheet.getCell(`A${rowIndex}`);
          cell4.value = moment(order.start_time).format('ll h:mm A')+' to '+moment(order.end_time).format('h:mm A');
          rowIndex += 1; // +1

          // Total
          tableContent.push(
            [
              '',
              'Total item',
              total[order.order_id].quantity,
              '',
              "Total",
              '$'+total[order.order_id].cost
            ]
          );

          // Table Content
          worksheet.addTable({
            name: `OrdersListsTable${rowIndex}`,
            ref: `A${rowIndex}`,
            headerRow: true,
            columns: [
              {name: 'Item Description', filterButton: false},
              {name: 'SKU', filterButton: false},
              {name: 'Qty', filterButton: false},
              {name: 'Weight', filterButton: false},
              {name: 'Unit price', filterButton: false},
              {name: 'Amount', filterButton: false},
            ],
            style: {

            },
            rows: tableContent
          });

          rowIndex += (order.itemsLists.length + 1);

          worksheet.getRow(rowIndex).font = { size: 12, bold: true };

          rowIndex += 2; // Create 2 space
        }
      });

      // Save File
      workbook.xlsx.writeBuffer().then(function (buffer) {
        const blob = new Blob([buffer], { type: "applicationi/xlsx" });
        saveAs(blob, `${filename}.xlsx`);
        setIsLoading(false);
      });
    }
  };

  return (
    <>
      <Button variant="primary"
              loading={isLoading.toString()}
              onClick={doExportOrders}
      >{isLoading
        ? 'Loading…'
        : <>
            <PublishSharpIcon style={{fontSize: 21}} /> { props.label }
          </>
      }</Button>
    </>
  )
};

ExportOrders.defaultProps = {
  dialog: false,
  Label: 'Export',
  status: 'pending',
  start_time: null,
  end_time: null,
  supplier_id: null,
  isNotFound: true
};

export default ExportOrders;
