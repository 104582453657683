import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { baseUrl } from "../../constants/Constant";
import { Modal, Form } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import t from '../../i18n/translate';
import axios from "axios";
import * as constant from "../../constants/Constant";

const LoadingImgContainer = styled.div`
position: absolute; 
height: 91%; 
top: 20px; 
right: 0; 
bottom: 0; 
left: 0; 
background: #fff;
border-radius: 12px;
`;

const LoadingSpinAnimation = keyframes`
0% { transform: rotate(0deg); } 
100% { transform: rotate(360deg); 
`

const ModalABACheckoutAnimation = keyframes`
0% { display: none; opacity: 0; } 
100% { display: block; opacity: 1; }
`;

const LoadingImg = styled.img`
width: 40px; 
left: 0; 
right: 0; 
position: absolute; 
margin: 0 auto; 
top: 40%; 
-webkit-animation: ${LoadingSpinAnimation} 1s linear infinite; 
animation: ${LoadingSpinAnimation} 1s linear infinite;
`

const PaywayImg = styled.img`
right: 0; 
z-index: 100; 
position: absolute; 
top: -30px;
`
const ABACloseBtn = styled.img`
cursor: pointer; right: 24px; position: absolute; top: 34px;
`

const ABACheckoutDesktop = styled.div`
top: 0; 
right: 0; 
bottom: 0; 
left: 0; 
overflow: auto; 
pointer-events: auto;
z-index: 99999;
display: block;
animation-name: ${ModalABACheckoutAnimation}; 
animation-duration: 0.5s;
`

const ABACheckoutContent = styled.div`
  min-height: 264px; 
  padding: 0px;  
  position: absolute; 
  left: 0; 
  width: 391px; 
  border-radius: 12px; 
  margin: 0 auto; 
  right: 0;
  
  .iframe-wrapper { height: 400px; }
  
  @media (max-width: 767px) {
    width: 100%;
        
    .iframe-wrapper { height: auto; }
  }
`

const ABACheckoutModalContainer = styled.div`
display: flex; 
align-items: center; 
justify-content: center;
`;

const ABAIframe = styled.iframe`
border-radius: 12px; 
transition: 
height 0.25s;
overflow: hidden; 
max-height: 562px;
background: #fff;
`


const CheckoutForm = (props) => {
  const cartType = useSelector(state => state.cartReducer.cartType);
  const _aba_checkout_baseUrl = "https://checkout.payway.com.kh";

  const handleClose = async () => {
    if (window.confirm( t('shop_checkout_popup_close_payment', false) )) {
      await unlockOrder();
    }
  }

  async function unlockOrder() {
    const token = localStorage.getItem('token');

    if(token) {
      const type = cartType === 'express' ? 1 : 0;
      const body = {order_id: localStorage.getItem("order_id")};
      const header = { headers: { Authorization: token } }
      const data = await axios.post(constant.baseUrl+"v1/web/basket/verify/aba?type="+type, body, header);

      if(data.status === 200 && !data.data.data.is_paid) {
        const doUnlockOrder = await axios.put(constant.baseUrl+"v1/web/basket/open?type="+type, body, header);
        if(doUnlockOrder.status === 200) {
          props.handleClose(false);
        }
        else {
          alert('Warning! There is an issue to unlock order');
        }
      }
    }
    else {
      alert('Please sign in')
    }
  }

  useEffect(() => {
    // ABA Bank want to remove this class name => modal-content
    const modalContentClass = document.getElementsByClassName('modal-content');
    if(modalContentClass.length) {
      // There is no background when you remove modal-content class
      modalContentClass[0].style.backgroundColor = "#fff";
      modalContentClass[0].style.borderRadius = "5px";
      modalContentClass[0].style.width = "100%";
      modalContentClass[0].style.maxWidth = "390px";
      modalContentClass[0].classList.add('modal-body-wrapper');
      modalContentClass[0].classList.remove('modal-content');
    }

    if(props.dialogStatus && localStorage.getItem("order_id") && props.placeOrderStatus) {
      document.getElementById('aba_merchant_request').submit();
    }
  },[props.dialogStatus, props.placeOrderStatus]);

  window.onmessage = (e) => {
    const resData = e.data;
    
    // remove close button  when  success screen shown autoredirect === 0
    if (resData.hasOwnProperty("close")) {
      handleClose();
    }

    if (resData.hasOwnProperty("frameHeight")) {
      document.getElementById("aba_webservice").style.height = `${resData.frameHeight}px`;
      // document.getElementById('abaCheckoutContent').style.height = `${resData.frameHeight + 80}px`;
      // document.getElementById("aba_init_view").style.height = `${resData.frameHeight + 80}px`;
      // setIsLoading(false);
      try{
        document.getElementById("aba_webservice_loading").remove();
      } catch(e) {

      }
    }

    if (resData.hasOwnProperty("openApp")) {
      window.location = resData.openApp; // to open aba mobile
    }
  };

  return (
    <>
    
      <Modal id="aba_main_modal"
             className="aba-modal"
             show={props.dialogStatus}
             onHide={handleClose}
             aria-labelledby="contained-modal-title-vcenter"
             size="md"
             centered
      >
        <Modal.Body>
          <ABACheckoutDesktop id="aba-checkout">
            <ABACheckoutModalContainer className='aba-checkout-modal-container'>
                <ABACheckoutContent className='aba-checkout-content'>
                  <LoadingImgContainer id='aba_webservice_loading'>
                    <LoadingImg src={`${_aba_checkout_baseUrl}/images/loading.svg`} className="loading-icon"/>
                  </LoadingImgContainer>
                  <PaywayImg src={`${_aba_checkout_baseUrl}/images/payway-logo-white.svg`}/>
                  <ABACloseBtn className='aba-close-button' onClick={() => handleClose()} src={`${_aba_checkout_baseUrl}/images/checkout_cross.svg`}/>
                  
                  <div className="iframe-wrapper">
                    <ABAIframe frameBorder="0" width="100%" height="264" scrolling="yes" name="aba_webservice" id="aba_webservice"></ABAIframe>
                  </div>
                </ABACheckoutContent>
            </ABACheckoutModalContainer>
            <Form id="aba_merchant_request" method="POST" target="aba_webservice" action={`${baseUrl}api/v2/payment/init`}>
              <input type="hidden" name="order_id" value={localStorage.getItem("order_id")}/>
              <input id="paymentMethodInput" type="hidden" name="aba_type" value={props.paymentMethod === 'card' ? 'cards' : props.paymentMethod}/>
              <input id="viewTypeInput" type="hidden" name="view_type" value="checkout"/>
              <input id="platformInput" type="hidden" name="platform" value={props.deviceType}/>
            </Form>
          </ABACheckoutDesktop> 
        </Modal.Body>
      </Modal>
    </>
  );
};

CheckoutForm.defaultProps = {
  dialogStatus: false,
  paymentMethod: null,
  placeOrderStatus: null,
  deviceType: 'desktop',
};

export default CheckoutForm;

