const colors = {
  transparent: "transparent", // 0
  white: "#ffffff", // 2
  darkBold: "#0D1136", // 3
  darkMedium: "#424561", // 3
  darkRegular: "#77798C", // 3
  darkLight: "#C2C3CC", // 3
  lightColor: "#F7F7F7", // 3
  lightMediumColor: "#F3F3F3", // 3
  lightDarkColor: "#E6E6E6", // 3
  labelColor: "#909090", // 4
  inactiveButton: "#b7dbdd", // 6
  borderColor: "#f1f1f1", // 7
  primary: "#389244", // 8
  primaryHover: "#019376", // 9
  secondary: "#ff5b60", // 10
  secondaryHover: "#FF282F", // 11
  yellow: "#FFAD5E", // 12
  yellowHover: "#FFB369", // 13
  blue: "#2e70fa", // 13
  lightBg: "#f4f4f4", // 14
  switch: "#028489", // 15
  darkBlue: "#161F6A", // 16
  blueRegular: "#666D92", // 16
  expressColor: "#f01266",
};

export default colors;
