import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@redq/reuse-modal";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { connect } from "react-redux";
import Alert from 'react-bootstrap/Alert'
import { doSupplierLogin } from "../../store/actions/authAction";
import { AuthContext } from "../../contexts/auth/auth.context";
import UploadService from "../../services/FileUploadService";
import * as Constants from "../../constants/Constant";
import t from '../../i18n/translate';

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  min-height: 400px;
  @media (min-width: 1100px) {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 60px;
  }
`;

const SupplierLoginScreen = (props) => {

    const { authDispatch } = useContext(AuthContext);
    let admin = localStorage.getItem("admin");
    let parsedUser = JSON.parse(admin);
    if(!admin) {
      window.location.href = "/";
    }
    

  return (
    <Modal>
      <HelpPageWrapper>
        <Row>
            <Col sm={2}>
              {t('edit', {path: 'src/locales.js'})}
            </Col>
        </Row>
      </HelpPageWrapper>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doSupplierLogin: (body) => doSupplierLogin(body, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupplierLoginScreen);
