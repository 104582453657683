import React, { useContext, useEffect, useState } from "react";
import {
  Wrapper,
  Container,
  LogoWrapper,
  Heading,
  SubHeading,
  Input,
  Button,
  LinkButton,
  Offer,
} from "./SignInOutForm.style";
import { FormattedMessage } from "react-intl";
import { AuthContext } from "../../contexts/auth/auth.context";
// import Image from 'components/Image/Image';
// import PickBazar from '../../image/PickBazar.png';
import { connect } from "react-redux";
import { doPasswordRequest, resetmessages } from "../../store/actions/authAction";
import * as Constants from "../../constants/Constant";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { Weight } from "../Cart/CartItem/CartItem.style";
import t from '../../i18n/translate';

const ForgotPassModal = (props) => {
  const { authDispatch } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [errormsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const toggleSignInForm = () => {
    authDispatch({
      type: "SIGNIN",
    });
  };
  // const didRequestFail = () => {
  //   setLoading(false);
  // };
  const validateEmail = () => {
    if (!email || email === "") {
      setErrorMsg(t('forget_password_error_empty_email', false));
      setError(true);
    }
    else if ((Constants.email_reg.test(email)) === false) {
      setErrorMsg(t('forget_password_error_invalid_email', false));
      setError(true);
    }
     else {
      axios
        .post(Constants.baseUrl+"api/checkUserExistByEmail", {
          "email": email,
        })
        .then(function (response) {
            if(response.data.status === 2){
              console.log(response.data);
              setError(true);
              setErrorMsg(response.data.message);
            }
            else{
              setError(false);
              setErrorMsg("");
              setLoading(true);
              props.doPasswordRequest({ email: email });
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            }
        })
        .catch(function (error) {
          console.log(error);
        });
      // setError(false);
    }
  };
  const forgetCallback = async (e) => {
    e.preventDefault();
    validateEmail();
  };
  useEffect(() => {
    return ()=>{
      setLoading(false);
      setError(false);
      setErrorMsg("");
      props.resetmessages();
    };
  }, []);
  useEffect(() => {
    if (props.passwordrequest && email !== "" ) {
      setLoading(false);
      authDispatch({ type: "RESETPASS" });
      // document.getElementById("resetpass").disabled = false;
    }
  }, [props.passwordrequest, email]);
  return (
    <Wrapper>
      <Container style={{ paddingBottom: 30 }}>
        {/* <LogoWrapper>
          <Image url={PickBazar} />
        </LogoWrapper> */}

        <Heading className={props.cartType}>
          { t('forget_password_title') }
        </Heading>

        <SubHeading>
          { t('forget_password_description') }
        </SubHeading>
        <form onSubmit={forgetCallback}>
          <Input
            type="text"
            className={props.cartType}
            placeholder={ t('forget_password_input_title', false) }
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onBlur={()=>{}}
          />
          {error &&
            <>
              <span style={{color: "red", marginBottom: "10px"}} >{errormsg} &nbsp;<span onClick={(e) => {
              e.preventDefault();
              authDispatch({
                type: "SIGNUP",
              });
              }} 
              style={{color: props.cartType === 'express' ? "#f01266" : "green", textDecoration: "underline", fontWeight: "700", cursor: "pointer"}}>
                . { t('forgot_password_register_link') }
                </span>
              </span>
            </>
          }
          {props.resetmessage !== "" && props.resetmessage !== "Not registered" &&
             <>
              <span style={{color: "red", marginBottom: "10px"}} >{props.resetmessage} &nbsp;
              </span> 
             </>
          }
          <Button
            id={"resetpass"}
            fullwidth
            title={ t('forget_password_button_reset_password', false) }
            intlButtonId="resetPasswordBtn"
            style={{ color: "#fff" }}
            type="submit"
            disabled={loading}
            loader={<Loader />}
            isLoading={loading}
          />
        </form>
        <Offer style={{ padding: "20px 0 0" }}>
          { t('forget_password_button_back') }{" "}
          <LinkButton className={props.cartType} onClick={toggleSignInForm}>
            { t('forget_password_login_link') }{" "}
          </LinkButton>
        </Offer>
      </Container>
    </Wrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    showOtp: state.authReducer.showOtp,
    passwordrequest: state.authReducer.passwordrequest,
    resetmessage: state.authReducer.resetmessage,
    cartType: state.cartReducer.cartType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doPasswordRequest: (body) => doPasswordRequest(body, dispatch),
    resetmessages: () => resetmessages(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassModal);
