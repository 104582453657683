import React from 'react';
import styled from 'styled-components';
import RefundPoliciesEnglish from "./html/en/refund-policies";
import RefundPoliciesKhmer from "./html/km/refund-policies";

const RefundPolicies = () => {
  const lang = localStorage.getItem('lang') || 'en';

  return <ContainerLayout>

    {lang === 'km'
      ? <RefundPoliciesKhmer />
      : <RefundPoliciesEnglish />
    }

  </ContainerLayout>
}

const ContainerLayout = styled.div`
  width: 100%;
  max-width: 870px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f7f7f7;
  position: relative;
  padding: 130px 20px 60px 20px;
  
  h3 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
`;

export default RefundPolicies;
