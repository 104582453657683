import React from "react";
import { Router } from "react-router-dom";
import { LogoBox, LogoImage } from "./Logo.style";

const Logo = ({ imageUrl, alt, onClick, height, width, cartType }) => {
  function onLogoClick() {
    window.location.href = cartType === 'express' ? "/express" : "/";
  }
  return (
    <LogoBox onClick={onLogoClick}>
      <LogoImage src={imageUrl} alt={alt} height={height} width={width ? width : 160} />
    </LogoBox>
  );
};

export default Logo;
