import React, { Component, PropTypes } from 'react'
import { Icon } from '../containers/LayoutContainer/Header/Menu/LeftMenu/LeftMenu.style';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import {CloudUpload, Image, Notifications, SportsMotorsports, Settings,LocationOn, ViewCarousel, Category, DeviceHub, ShoppingCart, LocalMallSharp, CodeOutlined, ShoppingBasket} from '@material-ui/icons';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';

const SidebarData = [
    // {
    //     name: "Dashboard",
    //     icon: <DashboardIcon />,
    //     link: "/admin/dashboard"
    // },
    {
        name: "Order Management",
        icon: <ShoppingCart />,
        link: "/admin/orders"
    },
    {
        name: "Order Map",
        icon: <LocationOn />,
        link: "/admin/order_map"
    },
    {
        name: "Customers",
        icon: <PermContactCalendarIcon />,
        link: "/admin/customers"
    },
    {
        name: "Basket",
        icon: <Notifications />,
        link: "/admin/baskets"
    },
    {
        name: "Suppliers",
        icon: <LocalShippingIcon />,
        link: "/admin/suppliers"
    },
    {
        name: "Brand",
        icon: <LocalMallSharp />,
        link: "/admin/brands"
    },
    {
        name: "Products",
        icon: <ShoppingBasket />,
        link: "/admin/products"
    },
    {
        name: "Categories",
        icon: <Category />,
        link: "/admin/categories"
    },
    {
        name: "SubCategories",
        icon: <DeviceHub />,
        link: "/admin/subCategories"
    },
    {
        name: "Driver",
        icon: <SportsMotorsports />,
        link: "/admin/drivers"
    },
    {
        name: "Bulk Extract",
        icon: <DesktopWindowsIcon />,
        link: "/admin/bulkExtract"
    },
    {
        name: "Bulk Uploads",
        icon: <DesktopWindowsIcon />,
        link: "/admin/bulkUploads"
    },
    {
        name: "Bulk Images Upload",
        icon: <CloudUpload />,
        link: "/admin/bulkImageUploads"
    },
    // {
    //     name: "Order",
    //     icon: <ShoppingCart />,
    //     link: "/admin/orders"
    // },
    {
        name: "Banner Update",
        icon: <Image />,
        link: "/admin/banner_update"
    },

    // {
    //     name: "Wallet",
    //     icon: <AttachMoneyRounded />,
    //     link: "/admin/wallets"
    // },
    {
        name: "Slider Banner",
        icon: <ViewCarousel />,
        link: "/admin/slide-banner"
    },
    {

        name: "Settings",
        icon: <Settings />,
        link: "/admin/deli_express_settings"
    }
    

];

export default SidebarData;