import React, { useState, useEffect } from "react";
import ProductCard from "../../components/ProductCard/ProductCard";
import { AuthContext } from "../../contexts/auth/auth.context";
import { useCart } from "../../contexts/cart/use-cart";
import {
  ProductCardWrapper,
  ArrivalWrapper,
  PromoTitle,
  ArrivalTitle,
  Wrapper,
  Prev,
  Next,
  ViewAll
} from "../Products/Products.style";
import { CURRENCY } from "../../helper/constant";
import { useLocation, useHistory } from "react-router-dom";
import QuickView from "../QuickView/QuickView";
import Carousel from "react-multi-carousel";
import PageLoader from "../../components/PageLoader/PageLoader";
import { ArrowNext, ArrowPrev } from "../../components/AllSvgIcon";
import { PromoSection } from "../../styled/pages.style";
import {
  PromoCard
} from "../PromoProducts/PromoProducts.style";
import t from '../../i18n/translate';

const NewProducts = ({
  deviceType,
  props,
}) => {
  const { isloading, updateItem } = useCart();
  //   // Quick View Modal
  const [show, setShow] = useState(false);
  const onHide = () => setShow(false);

  const {
    authState: { isAuthenticated },
    authDispatch,
  } = React.useContext(AuthContext);

  const handleQuickViewModal = (modalProps, deviceType, index) => {
    props.ProductModalData(modalProps);
    setTimeout(() => {
      setShow(true);
    }, 1000);
  };
  
  let { pathname, search, state } = useLocation();
  let history = useHistory();

  useEffect(() => {
    if(window.location.pathname.split("/").indexOf("search") >= 0){
        window.location.reload();
    }
  },[window.location.pathname]);

  const PrevButton = ({ onClick, children }) => {
    return (
      <Prev
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        className="prevButton"
      >
        {children}
      </Prev>
    );
  };
  const NextButton = ({ onClick, children }) => {
    return (
      <Next
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        className="nextButton"
      >
        {children}
      </Next>
    );
  };
  
  const ButtonGroup = ({ next, previous }) => {
    return (
      <Wrapper>
        <>
          <PrevButton onClick={() => previous()}>
            <ArrowPrev />
          </PrevButton>
          <NextButton onClick={() => next()}>
            <ArrowNext />
          </NextButton>
        </>
  
        {/* if prop isRtl true swap prev and next btn */}
      </Wrapper>
    );
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 5,
      slidesToSlide: 5,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 3,
      slidesToSlide: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  return (
    <>
      {isloading && <PageLoader />}
      {<ArrivalWrapper className={props.cartType}>
        <ArrivalTitle>
          { t('home_new_arrivals_label') }
        </ArrivalTitle>
        <ViewAll onClick={() => {
            history.push("/best-deals/new-arrivals");
        }}>
          <a target="_blank" rel="noopener noreferrer" href="/best-deals/new-arrivals" style={{textDecoration: "none", color: "inherit"}}>
            { t('home_view_all_label') }
          </a>
        </ViewAll>
       </ArrivalWrapper>}
      {props.arrivalproducts.length > 0 ? <PromoSection> 
        <Carousel
          arrows={false}
          responsive={responsive}
          ssr={true}
          showDots={false}
          // slidesToSlide={1}
          infinite={true}
          containerClass="container-for-promo"
        //   itemClass={itemClass}
          deviceType={deviceType}
          autoPlay={true}
          autoPlaySpeed={4000}
          renderButtonGroupOutside={true}
          additionalTransfrom={0}
          customButtonGroup={<ButtonGroup />}
          {...props}
          // use dir ltr when rtl true
        >
          {props.FilteredNameProducts == false &&
          props.arrivalproducts.map((item, index) => (
              <ProductCardWrapper style={{padding: "20px"}} key={index}>
                <ProductCard
                  title={item.name}
                  description={item.about_item}
                  image={item.default_image_url}
                  weight={
                    item.store_items_price_types[0].name !== " "
                      ? item.store_items_price_types[0].name
                      : item.store_items_price_types[0].weight +
                        " " +
                        item.store_items_price_types[0].weight_unit
                  }
                  outOfStock={
                    !item.store_items_price_types[0].available_quantity
                  }
                  // weight={item.store_items_price_types[0].name}
                  currency={CURRENCY}
                  price={item.store_items_price_types[0].mrp}
                  salePrice={item.store_items_price_types[0].price}
                  isSameDay={item.same_day}
                  discountInPercent={item.discountInPercent}
                  data={item}
                  deviceType={deviceType}
                  onClick={() => {
                    handleQuickViewModal(item, deviceType, index)
                    // item.store_items_price_types[0].available_quantity
                    //   ? handleQuickViewModal(item, deviceType, index)
                    //   : trackClick(item, index);
                  }}
                  onCartClick={() => {
                    document.getElementById("joinBTn").click();
                  }}
                  onUpdate={(quantity) => {
                    updateItem(item,quantity);
                  }}
                  promo={item.promo ? 1 : 0}
                  promoPrice={item.promo ? item.promo_price : null}
                  promoStartDate={item.promo ? item.promo_start_date : null}
                  promoEndDate={item.promo ? item.promo_end_date : null}
                  brandId={item.brand_id === 0 || item.brand_id === null ? 0 : item.brand_id}
                  brands={props.brands}
                  loading={props.loading}
                  page={props.page}
                  cartType={props.cartType}
                />
              </ProductCardWrapper>
          ))}
        </Carousel>
        </PromoSection> :
        (props.arrivalloading === true ?
          <PromoCard className="card-height">
           <span>Loading ....</span>
          </PromoCard> :
          <PromoCard className="card-height">
           <span>{ t('common_no_products_found_label') } ....</span>
          </PromoCard>)
      }
      {show && (
        <QuickView
          show={show}
          onHide={onHide}
          props={props}
          deviceType={deviceType}
          onCartClick={() => {
            document.getElementById("joinBTn").click();
          }}
        />
      )}
    </>
  );
};

export default NewProducts;
