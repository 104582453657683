import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../contexts/auth/auth.context";
import Table from 'react-bootstrap/Table';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from "react-router-dom";
import { ToggleButton } from "react-bootstrap";
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import * as Constant from "../../constants/Constant";
import {OverlayTrigger, Popover, Toast, Spinner, Modal} from "react-bootstrap"
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import API from "../../network/dashboardAPI";
import {getProduct,searchProduct,getBrands,getCategory,getSubCategory,getSuppliers,getSubCategoryMap} from '../../store/actions/dashboardAction'
import { connect } from "react-redux";
import moment from "moment";
import { FormattedNumber } from 'react-intl';

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  table tr th {
    position: sticky;
    top: 0;
    border: 1px solid #dee2e6;
    background-color: #f9f9f9;
  }

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const TableBodyWidth = styled.div`
  table tr th {
    white-space: nowrap;
    width: initial;
  }
`;

const ProductListing = (props) => {

    const { authDispatch } = useContext(AuthContext);
    let admin = localStorage.getItem("admin");
    let parsedUser = JSON.parse(admin);
    if(!admin) {
      window.location.href = "/";
    }
    let productListUrl = Constant.baseUrl+"api/admin/products";
    let productSearchUrl = Constant.baseUrl+"api/admin/products/search";
    let productEnableDisableUrl = Constant.baseUrl+"api/admin/product/enable";
    let productQuantityUpdateUrl = Constant.baseUrl+"api/admin/product/changeQuantity";
    const [productsUrl, setProductsUrl] = useState(productListUrl);
    const [progress, setProgress] = useState(0);
    const [brand, setBrand] = useState(0);
    const [cat, setCat] = useState(16);
    const [oos, setOos] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [previous, setPrevious] = useState(false);
    const [next, setNext] = useState(false);
    const [showEnable, setShowEnable] = useState(false);
    const [showDisable, setShowDisable] = useState(false);
    const [modalHead,setModalHead] = useState("");
    const [modalMessage,setModalMessage] = useState("");
    const [editId, setEditId] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError,setIsError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [available,setAvailable] = useState(0);
    const [products, setProducts] = useState({
        loading: false,
        data: null,
        error: false
    });
    const [showInStock, setShowInStock] = useState(false);
    const [showOutOfStock, setShowOutOfStock] = useState(false);
    const [modalHeadQuantityModal,setModalHeadQuantityModal] = useState("");
    const [modalMessageQuantityModal,setModalMessageQuantityModal] = useState("");
    const [	quantity,setQuantity] = useState(0);
    const [	quantityError,setQuantityError] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteItemName, setDeleteItemName] = useState('');
  const [toastBgColor, setToastBgColor] = useState('bg-success');
  const [toastMessage, setToastMessage] = useState("Delete successful");
  const [editedItem, setEditedItem] = useState({});
  const [dialogDelete, setDialogDelete] = useState(false);

    const [showImage, setShowImage] = useState(false);
    const [imgUrl , setImgUrl] = useState("")
    const [flag , setflag] = useState(true)
    const [filters, setFilters] = useState(null)
    const hnadleShowImageClose = () => {
        setShowImage(false)
    }
    const hnadleShowImageOpen = (url) => {
        setImgUrl(url)
        setShowImage(true)
    }
     


    const addProductPage = (event) => {
        window.location.href = "/admin/product/add";
    }

    const getProductsListPrevious = (event) => {
        if(page > 1) {
          //  searchProducts(productsUrl,page-1);
          //setPage(page-1)
            if(flag)getProducts(page-1)
            else searchInitiate(productSearchUrl,page-1)
        }
        else{
            alert("No record on previous page")
        }
    };

    const getProductsListNext = (event) => {
        if (next) {
           // searchProducts(productsUrl,page+1);
        //    setPage(page+1)
        if(flag)getProducts(page+1)
        else searchInitiate(productSearchUrl,page+1)
        }
        else{
            alert("No record on next page")
        }
    };
    const handleSetQuantity = (event) =>{
        setQuantity(event.target.value)
    }

    const enableModal = (proId,proName) => {
        setEditId(proId);
        setAvailable(1);
        setModalHead("Enable");
        setModalMessage("Want to enable product - '"+proName+"'?");
        setShowEnable(true);
        //console.log("here"+proId+proName)
    };

    const disableModal = (proId,proName) => {
        setEditId(proId);
        setAvailable(0);
        setModalHead("Disable");
        setModalMessage("Want to disable product - '"+proName+"' ?");
        setShowDisable(true);
       // console.log("here"+proId+proName)
    };

    const handleClose = () => {
        setShowEnable(false);
        setShowDisable(false);
    }

    const rowEvent = {
        onClick: (row) => {
          //  console.log(row)
        }
    }

    const enableProduct = () => {
        setModalHead("Updating");
        API.updatenableDisableProduct({
            item_id: editId,
            is_available: available,
        })
        .then(response => {
            setShowEnable(false);
            setShowDisable(false);
          //  console.log("falg-1", flag)
            if(flag) getProducts(page) /// 
            else searchInitiate(productSearchUrl,page)
        }).catch((error) => {
            setModalHead(error.response.data);
        });
    };

    const handleCloseQuantityModal = () => {
        setShowInStock(false);
        setShowOutOfStock(false);
    }
    const inStockQuantityModal = (proId,proName) => {
        setQuantityError(false)
        setEditId(proId);
       // setAvailable(1);
       setQuantity(0)
       setModalHeadQuantityModal("In Stock");
       setModalMessageQuantityModal("Want to make in stock product - '"+proName+"'?");
       setShowInStock(true);
       // console.log("here"+proId+proName)
    };

    const outOfStockQuantityModal = (proId,proName) => {
        setEditId(proId);
        //setAvailable(0);
        setQuantity(0);

        setModalHeadQuantityModal("Out Of Stock");
        setModalMessageQuantityModal("Want to make Out Of Stock product - '"+proName+"' ?");
        setShowOutOfStock(true);
      //  console.log("here"+proId+proName)
    };


    const inStockProduct = () => {
        
        if(modalHeadQuantityModal === "In Stock" ){
            if(quantity > 0){
                setQuantityError(false)
                setModalHeadQuantityModal("Updating");
                API.quantityUpdateProduct({
                    item_id: editId,
                    quantity: quantity,
                })
                .then(response => {
                    setShowInStock(false);
                    setShowOutOfStock(false);
                 //   console.log("falg-2", flag)

                    if(flag) getProducts(page) /// 
                    else searchInitiate(productSearchUrl,page)
                }).catch((error) => {
                    console.log(error)
                    //setModalHeadQuantityModal(error);
                });
            }else{
                setQuantityError(true)
            }
           
        }else{
            setModalHeadQuantityModal("Updating");
            API.quantityUpdateProduct({
                item_id: editId,
                quantity: quantity,
            })
            .then(response => {
                setShowInStock(false);
                setShowOutOfStock(false);
               // console.log("falg-3", flag)

                if(flag) getProducts(page) /// 
                else searchInitiate(productSearchUrl,page)
            }).catch((error) => {
               
                //setModalHeadQuantityModal(error);
            });
        }
        
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchInitiate(productSearchUrl,1)
        }
      }

    const searchInitiate = (strUrl,pageNumber) => {
        if(searchString.length > 2) {
            setflag(false)
            setProductsUrl(productSearchUrl);
            searchProducts(strUrl,pageNumber);
        } else {
            setIsError(true);
            setErrorText("Write at least 3 characters to search");
            setIsSubmitted(false);
        }
    };

    const searchProducts = (urlString,pageNumber) => {
            let postData = {
                name: searchString,
                page: pageNumber
            }; 
            setProducts({
                loading: true,
                data: null,
                error: false
            })
            API.searchProduct(postData)
            .then((response) => response.json())
            .then(response => {
                if (response.data.length == 50) {
                    setNext(true)
                } else {
                    setNext(false);
                }
                setProducts({
                    loading: false,
                    data: response.data,
                    error: false
                })
                setPage(pageNumber);
                setIsError(false);
                setIsSubmitted(false);
            }).catch((error) => {
                setProducts({
                    loading: false,
                    data: null,
                    error: false
                })
                setIsError(true);
                setErrorText(error.response.data);
                setIsSubmitted(false);
            });
    };

    const getProducts = (pageNumber) => {
        setflag(true)
      //  console.log("falg get", flag)
        API.getProduct({
            page: pageNumber,
            limit: 10,
            filter : filters
        })
        .then((response) => response.json())
        .then(response => {
            setProducts({
                loading: false,
                data: response.data.list,
                error: false
            })
            setProductsUrl(productListUrl);
            setPage(pageNumber);
            setTotalPage(response.data.total_page);

            if (response.data.list.length == 10) {
                setNext(true)
            } else {
                setNext(false);
            }
        }).catch((error) => {
         
            setProducts({
                loading: false,
                data: null,
                error: true
            })
        });
    }

    const getPromotionDateDisplay = (product) => {
        return product.promo? `${moment(product.promo_start_date).format("DD/MM/YY")} - ${moment(product.promo_end_date).format("DD/MM/YY")}`: "";
    }

    useEffect(() => {
    //    console.log("cahahahahhahah")
        setflag(true)
        setProducts({
            loading: true,
            data: null,
            error: false
        })
        getProducts(page)
// ----------------------------------- for filter ------------------
        props.getCategory({
            store_id: 1,
            get_type: 1
        })
        props.getBrands({});
        props.getSuppliers({})
// ------------------------ filter-------------------------
    },[productListUrl]);

    const changeSearchString = ((event) => {
        setSearchString(event.target.value)
    });
    const logo = {
        height : "50Px",
        width : "50Px"
  }
    let content = null;
    let message = null;
    if(products.error) {
        message = <p>Some error in response.</p>;
    }
    if (products.loading) {
        message = <p>Loading...</p>;
    }

  const softDeleteProduct = async () => {
    try {
      const item_id = editedItem.item_id;
      const itemRow = document.getElementById(`item-row-${item_id}`);

      setIsLoading(true);
      setShowToast(false);

      setTimeout(() => {
        document.body.click();
        setIsLoading(false);
        setShowToast(true);
        itemRow.remove();
      }, 750);
      await API.deleteProduct({item_id})
        .then(res => {
          setTimeout(() => {
            document.body.click();
            setIsLoading(false);
            setShowToast(true);
            itemRow.remove();
          }, 750)
        });

      setDialogDelete(false);
    } catch(error) {
      console.log(error);
      setDialogDelete(false);
    }
  };
  const handleDialogDelete = (status = false, data = null) => {
    if(data !== null) {
      setEditedItem(data);
      setDeleteItemName(data.name);
    } else {
      setDeleteItemName('');
    }

    setDialogDelete(status);

    if(!status)
      setEditedItem({});
  };


    if(products.data) {
        content = 
        products.data.map((product,key) => 
        <tr id={`item-row-`+product.item_id} key={key}>
            <td onClick= {()=>hnadleShowImageOpen(product.default_image_url)}><img src={product.default_image_url} alt="img" style = {logo} /></td>
            <td>{product? product.item_id: ""}</td>
            <td>{product? product.product_code : ""}</td>
            <td>{product? product.name:""}</td>
            <td>{product.list_categories[0] ? product.list_categories[0].name: ""}</td>
            <td>{product.list_sub_categories[0]? product.list_sub_categories[0].name: ""}</td>
            <td>{product.list_brands[0]? product.list_brands[0].name:""}</td>
            <td>{product.store_items_price_types[0]? <FormattedNumber value={product.store_items_price_types[0].mrp} style="currency" currency="USD"/>: ""}</td>
            <td>{product.store_items_price_types[0]? <FormattedNumber value={product.store_items_price_types[0].price} style="currency" currency="USD"/>: ""}</td>
            <td>{product.promo?
                <FormattedNumber value={product.promo_cost} style="currency" currency="USD"/>: "-"}</td>
            <td>{product.promo?
                <FormattedNumber value={product.promo_price}
                                 style="currency"
                                 currency="USD"
                />: "-"}</td>
            <td>{product.promo? getPromotionDateDisplay(product): "-"}</td>
            <td>{product.trending === 0? "N/A": "Yes"}</td>
            <td>{product.store_items_price_types[0].is_available == 1 ? 
                (
                    <Button variant="success" onClick={() => disableModal(product.item_id,product.name)}>
                        Enabled
                    </Button>
                ) : (
                    <Button variant="danger" onClick={() => enableModal(product.item_id,product.name)}>
                        Disabled
                    </Button>
                )}</td>
                   <td>{product.store_items_price_types[0].available_quantity == 0 ? 
                (
                    <Button variant="danger" onClick={() => inStockQuantityModal(product.item_id,product.name)}>
                        Out of Stock
                    </Button>
                ) : (
                    <Button variant="success" onClick={() => outOfStockQuantityModal(product.item_id,product.name)}>
                        In Stock
                    </Button>
                )}</td>
          <td width="120">
            <div className="d-flex flex-row" style={{height: '100%'}}>
              <Link target="_blank" to={{ pathname: '/admin/product/update/'+product.item_id  , state: {id: product.item_id} }}>
                <Button variant="info" type="submit" style={{height: '100%'}} >
                  <EditIcon></EditIcon>
                </Button>
              </Link>
              <Button className="ml-1" variant="danger" onClick={() => handleDialogDelete(true, product)}><DeleteIcon/></Button>
            </div>
          </td>
        </tr>
        )
    }
    //------------------ for filter
    const [showFilterModel, setShowFilterModal] = useState(false);
    const [itemCat, setItemCat] = useState(0);
    const [itemSubCat, setItemSubCat] = useState(0);
    const [itemBrand, setItemBrand] = useState(0);
    const [itemSupplierId, setItemSupplierId] = useState(0);
    const [itemNewArrival, setItemNewArrival] = useState(null);
    const [itemTrending, setItemTrending] = useState(null);
    const [itemPromotion, setItemPromoTion] =useState(null);

    const handleItemPromotionChange = (event) => {
        if(!event.target.checked) {
            setItemPromoTion(0);
        }else{
            setItemPromoTion(1);
        }
    }

    const handleItemTrendingChange = (event) => {
        if(!event.target.checked) {
            setItemTrending(0);
        }else{
            setItemTrending(1);
        }
    }

    const handleItemNewArrivalChange = (event) => {
        if(!event.target.checked) {
            setItemNewArrival(0);
        }else{
            setItemNewArrival(1);
        }
    }

    const handleCloseFilterModal = () => {
        setShowFilterModal(false)
    }
    const handleOpenFilterModal = () => {
        setShowFilterModal(true)
    }
    const cahngeCategory = ((event) => {
        setItemCat(event.target.value);
        props.getSubCategoryMap({cat_id: event.target.value})
    });
    const changeSubCategory = ((event) => {
        setItemSubCat(event.target.value)
    });
    const cahngeBrand = ((event) => {
        setItemBrand(event.target.value)
    });
    const changeSupplier = ((event) => {
        setItemSupplierId(event.target.value);
    });
    const applyFilter = () =>{
        let filter = {}
        if(itemCat != 0) filter.cat_id = itemCat;
        if(itemSubCat!= 0) filter.sub_cat_id = itemSubCat
        if(itemBrand!= 0) filter.brand_id = itemBrand;
        if(itemSupplierId!= 0) filter.supplier_id = itemSupplierId;
        filter.new_arrival = itemNewArrival? 1: 0;
        filter.promo = itemPromotion? 1: 0;
        filter.trending = itemTrending? 1: 0;
        setflag(true)
        setFilters(filter)
        setProducts({
            loading: true,
            data: null,
            error: false
        })
        API.getProduct({
            page: page,
            limit: 10,
            filter : filter
        })
        .then((response) => response.json())
        .then(response => {
            setShowFilterModal(false)
            setProducts({
                loading: false,
                data: response.data.list,
                error: false
            })

            setProductsUrl(productListUrl);
            if (response.data.list.length == 10) {
                setNext(true)
            } else {
                setNext(false);
            }
        }).catch(() => {
            setProducts({
                loading: false,
                data: null,
                error: true
            })
        });


    }


    let contentSupplier = null;
    let contentBrands = null;
    let contentCategory = null;
    let contentSubCategory = null;
    if(props.brands) {
        contentBrands =  props.brands.map((brand,key) => 
        <option key={key} value={brand.brand_id}>{brand.name}</option>
        )
    }
    if(props.category) {
        contentCategory =  props.category.map((cat,key) => 
        <option key={key} value={cat.cat_id}>{cat.list_categories[0].name}</option>
        )
    }
    if(props.sub_category) {
        contentSubCategory =  props.sub_category.map((subCat,key) => 
        <option key={key} value={subCat.sub_cat_id} >{subCat.list_sub_categories[0].name}</option>
        )
    }
    if(props.suppliers) {
        contentSupplier =  props.suppliers.map((supplier,key) => 
        <option key={key} value={supplier.id}>{supplier.store_users[0].first_name}</option>
        )
    }

    

  return (
    <HelpPageWrapper>
        <Row>
            <Col sm={4}>
                {isError ? (
                    <Alert variant="danger">
                        {errorText}
                    </Alert>
                ) : ("")}
                <InputGroup className="mb-3">
                    <FormControl
                    placeholder="Product name"
                    aria-label="Product name"
                    aria-describedby="basic-addon2"
                    value={searchString} onChange={changeSearchString}
                    onKeyDown={handleKeyDown}
                    />
                    <InputGroup.Append>
                        {isSubmitted ? (
                            <Spinner animation="grow" variant="success" />
                        ) : (
                            <Button variant="outline-secondary" onClick={() => searchInitiate(productSearchUrl,1)}>Search</Button>
                        )}
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            <Col sm = {2}>
            <Button variant="success" type="submit" className="pull-right" onClick={handleOpenFilterModal}>
                    Filters
                </Button>
            </Col>
            <Col sm={3}>
                <Button variant="success" type="submit" className="pull-right" onClick={addProductPage}>
                    Add product
                </Button>
            </Col>
            <Col sm={2} className="pull-right">
                <ButtonGroup toggle className="pull-right">
                    <ToggleButton
                        type="radio"
                        variant="primary"
                        name="radio"
                        onClick={getProductsListPrevious}>
                        Back
                    </ToggleButton>
                    <ToggleButton
                        type="radio"
                        variant="primary"
                        name="radio">
                        {page}/{totalPage}
                    </ToggleButton>
                    <ToggleButton
                        type="radio"
                        variant="primary"
                        name="radio"
                        onClick={getProductsListNext}>
                        Next
                    </ToggleButton>
                </ButtonGroup>
            </Col>
        </Row>
        <Row>
            <Col sm={11}>
                <hr />
                {message}

                <TableBodyWidth
                    className="table-responsive"
                    style={{height: 'calc(100vh - 250px)'}}
                >
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                            <th>Image</th>
                            <th>ID</th>
                            <th>SKU</th>
                            <th>Product Name</th>
                            <th>Category Name</th>
                            <th>SubCategory Name</th>
                            <th>Brand Name</th>
                            <th>Cost</th>
                            <th>Price</th>
                            <th>Promo Cost</th>
                            <th>Promo Price</th>
                            <th>Promo Date</th>
                            <th>Tranding</th>
                            <th>Status</th>
                            <th>Quantity</th>
                            <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {content}
                        </tbody>
                    </Table>
                </TableBodyWidth>
            </Col>
        </Row>

            <Modal show={showEnable} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{modalHead}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="success" onClick={enableProduct}>
                    Enable
                </Button>
                </Modal.Footer>
            </Modal>

        <Modal show={showDisable} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{modalHead}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalMessage}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="danger" onClick={enableProduct}>
                Disable
            </Button>
            </Modal.Footer>
        </Modal>


        <Modal show={showInStock} onHide={handleCloseQuantityModal}>
                <Modal.Header closeButton>
                <Modal.Title>{modalHeadQuantityModal}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessageQuantityModal}</Modal.Body>
                <Modal.Footer>
                <Alert show= {quantityError} variant="danger" severity="error">Please enter valid quantity!</Alert>
                <InputGroup size="sm" className="mb-3">
                
                <InputGroup.Prepend>
                <InputGroup.Text id="inputGroup-sizing-sm">Enter Quantity</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm"  value = { quantity} onChange = {handleSetQuantity} />
                </InputGroup>
                <Button variant="secondary" onClick={handleCloseQuantityModal}>
                    Close
                </Button>
                <Button variant="success" onClick={inStockProduct}>
                    In Stock
                </Button>
                </Modal.Footer>
            </Modal>

        <Modal show={showOutOfStock} onHide={handleCloseQuantityModal}>
            <Modal.Header closeButton>
            <Modal.Title>{modalHeadQuantityModal}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalMessageQuantityModal}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseQuantityModal}>
                Close
            </Button>
            <Button variant="danger" onClick={inStockProduct}>
                 Mark out of stock            
            </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showImage} onHide={hnadleShowImageClose}>
            <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
            </Modal.Header>
            <Modal.Body><img alt ="Product" src = {imgUrl} style = {{height : "400Px" , width : "400Px", alignItems : "center"}}/></Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={hnadleShowImageClose}>
                Close
            </Button>
            {/* <Button variant="danger">
                 Mark out of stock            
            </Button> */}
            </Modal.Footer>
        </Modal>



        <Modal show={showFilterModel} onHide={handleCloseFilterModal}>
            <Modal.Header closeButton>
            <Modal.Title>Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>Category</Form.Label>
                                    <Form.Control as="select" onChange={cahngeCategory} value={itemCat}>
                                        <option value={0}>Choose Category</option>
                                        {contentCategory}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>Sub Category</Form.Label>
                                    <Form.Control as="select" onChange={changeSubCategory} value={itemSubCat}>
                                        <option value={0}>Choose Sub Category</option>
                                        {contentSubCategory}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Control as="select" onChange={cahngeBrand} value={itemBrand}>
                                        <option value={0}>Choose Brand</option>
                                        {contentBrands}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>Supplier</Form.Label>
                                    <Form.Control as="select" onChange={changeSupplier} value={itemSupplierId}>
                                            <option value={0}>Select Supplier</option>
                                            {contentSupplier}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridState">
                                    {/* <Form.Label>New Arrival</Form.Label> */}
                                    <Form.Check type="checkbox" label="New Arrival" checked={itemNewArrival} onChange={handleItemNewArrivalChange} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridState">
                                    {/* <Form.Label>Sub Category</Form.Label> */}
                                    <Form.Check type="checkbox" label="Promotion" checked={itemPromotion} onChange={handleItemPromotionChange} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridState">
                                    {/* <Form.Label>Brand</Form.Label> */}
                                    <Form.Check type="checkbox" label="Trending" checked={itemTrending} onChange={handleItemTrendingChange} />
                                </Form.Group>
                            </Form.Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseFilterModal}>
                Close
            </Button>
            <Button variant="danger" onClick={applyFilter}>
                 Apply Filters          
            </Button>
            </Modal.Footer>
        </Modal>

      <Modal show={dialogDelete}
             onHide={handleDialogDelete}
             aria-labelledby="contained-modal-title-vcenter"
             centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure want to delete <strong>{deleteItemName}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleDialogDelete(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={() => softDeleteProduct()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast className={toastBgColor}
             onClose={() => setShowToast(false)}
             show={showToast}
             delay={3000}
             autohide
             style={{
               position: 'fixed',
               top: 70,
               right: 35,
               zIndex: 1041,
             }}
      >
        <Toast.Header>
          <strong className="mr-auto">{toastMessage}</strong>
        </Toast.Header>
      </Toast>

    </HelpPageWrapper>
  );
}

const mapStateToProps = state =>{
     return {
        products : state.dashboardReducer.products,
        brands : state.dashboardReducer.brands,
        category : state.dashboardReducer.category,
        sub_category : state.dashboardReducer.sub_category,
        suppliers : state.dashboardReducer.suppliers
     }
    
   }
   
   const mapDispatchToProps = dispatch => {
     return {
       getProduct : data => getProduct(data,dispatch),
       searchProduct : data => searchProduct(data,dispatch),
       getBrands : data => getBrands(data,dispatch),
       getCategory : data => getCategory(data,dispatch),
       getSubCategory : data => getSubCategory(data,dispatch),
       getSuppliers : data => getSuppliers(data,dispatch),
       getSubCategoryMap : data => getSubCategoryMap(data,dispatch),
     }
   }
   export default connect(mapStateToProps,mapDispatchToProps)(ProductListing)
