import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const ProductsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  background-color: #f7f7f7;
  position: relative;
  z-index: 1;
  margin: 0 -15px;
  padding-top: 15px;
  justify-content: space-around;
  gap: 30px;
  
  &.product-page {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 25px;
    position: relative;
    z-index: 1;
    margin: 0 -15px;
  }
  
  .clearFlex {
    width: 100%;
    padding: 20px 10px;
    font-size: 18px;
    font-weight: bold;
    
    span {
      background-color: #f01266;
      color: #ffffff;
      display: inline-block;
      padding: 5px 10px;
      width: 100%;
    }
  }
  @media (max-width: 1400px) {
    gap: 20px;
  }
  @media (max-width: 768px) {
    margin-left: -7.5px;
    margin-right: -7.5px;
    margin-top: 15px;
  }
`;

export const BoxTitle = styled.h3`
  font-size: 32px;
  font-family: "Lato", sans-serif;
  margin-bottom: 100px;
  font-weight: 400;
  text-align: center;
`;

export const FilterWrapper = styled.div`
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .filter_icon {
    padding-right: 30px;
    font-size: 20px;
    text-align: right;
    cursor: pointner;
  }
  @media (min-width: 768px) {
    .filter_icon {
      padding-right: 100px;
      font-size: 20px;
      text-align: right;
    }
  }
  .label_container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    font-size: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  /* Hide the browser's default checkbox */
  .label_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid #aaa;
  }

  /* On mouse-over, add a grey background color */
  .label_container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .label_container input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .label_container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .label_container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 8px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  #sortdropdown {
    background-color: red;
    color: #fff;
    border: 0px;
  }
  @media (max-width: 990px) {
    .col-sm-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;
export const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
`;

export const ProductsCol = styled.div`
  width: 100%;
  max-width: 250px;
  margin-bottom: 0;

  .book-card {
    border-radius: 0;
  }

  &.food-col {
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (min-width: 1501px) {
    &:nth-child(5n + 1) {
      .book-card {
        border-radius: 6px 0 0 6px;
      }
    }

    &:nth-child(5n) {
      .book-card {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  @media (min-width: 1301px) and (max-width: 1500px) {
    &.food-col {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }

    &:nth-child(4n + 1) {
      .book-card {
        border-radius: 6px 0 0 6px;
      }
    }

    &:nth-child(4n) {
      .book-card {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  @media (min-width: 900px) and (max-width: 1300px) {
    &.food-col {
      flex: 0 0 33.3333333%;
      max-width: 33.3333333%;
      padding-left: 7.5px;
      padding-right: 7.5px;
      margin-bottom: 15px;
    }

    &:nth-child(3n + 1) {
      .book-card {
        border-radius: 6px 0 0 6px;
      }
    }

    &:nth-child(3n) {
      .book-card {
        border-radius: 0 6px 6px 0;
      }
    }
  }
  @media (max-width: 1199px) and (min-width: 991px) {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;

    &.food-col {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  @media (max-width: 1400px) {
    max-width: 220px;
  }
  @media (max-width: 990px) {
    padding-left: 7.5px;
    padding-right: 7.5px;
    margin-bottom: 15px;
    max-width: 260px;
  }
  @media (max-width: 767px) {
    max-width: 300px;  
    &.food-col {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &:nth-child(2n + 1) {
      .book-card {
        border-radius: 6px 0 0 6px;
      }
    }

    &:nth-child(2n) {
      .book-card {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  @media (max-width: 480px) {
    &.food-col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;

export const MedicineCol = styled.div`
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;

  @media (max-width: 1300px) {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (max-width: 1099px) and (min-width: 1025px) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }

  @media (max-width: 767px) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
  }

  @media (max-width: 560px) {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 7.5px;
    padding-right: 7.5px;
    margin-bottom: 20px;
  }
`;

export const NoResult = styled.div`
  width: 100%;
  padding: 100px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${themeGet("fontFamily.0", "sans-serif")};
  font-size: ${themeGet("fontSizes.4", "21")}px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.darkBold", "#0D1136")};
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
`;

export const LoaderItem = styled.div`
  width: 25%;
  padding: 0 15px;
  margin-bottom: 30px;
`;

export const ProductCardWrapper = styled.div`
  height: 100%;

  > div {
    height: 100%;
  }
`;
export const QuickViewWrapper = styled.div`
  max-width: 1020px;
`;

export const ProductDetailsWrapper = styled.div`
  background-color: #fff;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  min-height: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.16);
  * {
    box-sizing: border-box;
  }
`;

export const ProductPreview = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding: 30px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 30px 47px 40px 30px;
    order: 0;
  }

  img {
    width: 100%;

    @media (max-width: 767px) {
      min-width: auto !important;
    }
  }
`;

export const SaleTag = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  background-color: ${themeGet("colors.yellow", "#FBB979")};
  padding: 0 10px;
  line-height: 24px;
  border-radius: 12px;
  display: inline-block;
  position: absolute;
  top: 40px;
  right: 30px;
`;

export const DiscountPercent = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: #ffffff;
  line-height: 24px;
  background-color: ${themeGet("colors.yellow", "#FBB979")};
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  display: inline-block;
  position: absolute;
  top: 30px;
  right: 30px;
  border-radius: 12px;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
`;

export const ProductInfoWrapper = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  border-left: 1px solid ${themeGet("colors.lightMediumColor", "#f3f3f3")};

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 10px 0 30px;
    order: 1;
  }
`;

export const ProductInfo = styled.div`
  padding: 50px;

  @media (max-width: 767px) {
    padding: 0px 30px 30px 30px;
  }
`;

export const ProductTitlePriceWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const ProductTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: ${themeGet("fontSizes.4", "21")}px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.darkBold", "#0D1136")};
  line-height: 1.5;
  display: flex;

  @media (max-width: 767px) {
    word-break: break-word;
  }
`;

export const ProductPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 25px;
  line-height: 31px;

  @media (max-width: 767px) {
    margin-left: 15px;
  }
`;

export const ProductPrice = styled.div`
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.primary", "#389244")};
`;

export const SalePrice = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.yellow", "#FBB979")};
  font-style: italic;
  padding: 0 5px;
  overflow: hidden;
  position: relative;
  margin-right: 10px;

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    display: inline-block;
    background-color: ${themeGet("colors.yellow", "#FBB979")};
    position: absolute;
    top: 50%;
    left: 0;
  }
`;

export const ProductWeight = styled.div`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.darkRegular", "#77798c")};
`;

export const ProductDescription = styled.p`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.2", "15")}px;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.darkMedium", "#424561")};
  line-height: 2;
  margin-top: 30px;
`;

export const ProductCartBtn = styled.div`
  margin-top: 60px;

  @media (max-width: 767px) {
    margin-top: 40px;
  }

  .reusecore__button {
    font-family: "Lato", sans-serif;
    font-size: ${themeGet("fontSizes.2", "15")}px;
    font-weight: ${themeGet("fontWeights.6", "700")};
    color: ${themeGet("colors.primary", "#389244")};
    height: 36px;
    border-radius: 4rem;
    width: 100%;
    max-width: 150px;

    .btn-icon {
      margin-right: 5px;
    }

    &:hover {
      color: #fff;
      background-color: ${themeGet("colors.primary", "#389244")};
      border-color: ${themeGet("colors.primary", "#389244")};
    }
  }
`;

export const ProductCartWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductMeta = styled.div`
  margin-top: 30px;
`;

export const MetaSingle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const MetaItem = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.darkBold", "#0D1136")};
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: ${themeGet("colors.lightColor", "#f7f7f7")};
  padding: 0 15px;
  border-radius: 6px;
  cursor: pointer;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalClose = styled.button`
  position: fixed;
  top: 20px;
  right: 15px;
  padding: 10px 15px;
  z-index: 1;

  cursor: pointer;
  display: block;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  border: 0;
  outline: none;
  display: inline-block;
  svg {
    width: 12px;
    height: 12px;
  }
  @media (max-width: 767px) {
    top: 5px;
    right: 0;
  }
`;

export const FilterDiv = styled.div`
  box-sizing: border-box;
  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 26px 0px;
    width: 100%;
    height: auto;
  }
  .col-sm-two {
    margin: 0px 8px;
    
    &.express {
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }
  }
  .col-sm-eight {
    flex: 1;
  }
  ._1q59bef {
    display: inline-block;
    margin-right: 8px;
    border-left: 1px solid #dddddd;
    height: 36px;
  }

  @media screen and (max-width: 600px) {
    .col-sm-eight {
      flex: 0 0 10%;
      max-width: unset;
    }
    .col-sm-two {
      flex: 0 0 25%;
      max-width: unset;
    }
    /*.sort-filter-hide{
     display:none;
   }*/
  }
`;
export const FilterModalWrapper = styled.div`
  ._pwbsgx {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 14px !important;
    padding-right: 14px !important;
    border-top: 1px solid rgb(235, 235, 235) !important;
    font-size: 12px;
  }
  ._1qbrhv4 {
    -webkit-box-pack: justify !important;
    -webkit-box-align: center !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  ._gby1jkw:disabled {
    cursor: not-allowed !important;
    opacity: 1 !important;
    color: rgb(221, 221, 221) !important;
    border-width: initial !important;
    border-style: none !important;
    border-color: initial !important;
    border-image: initial !important;
    background: transparent !important;
  }
  ._gby1jkw {
    cursor: pointer !important;
    display: inline-block !important;
    position: relative !important;
    text-align: center !important;
    width: auto !important;
    touch-action: manipulation !important;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
      "Helvetica Neue", sans-serif !important;
    line-height: 20px !important;
    font-weight: 600 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    color: rgb(34, 34, 34) !important;
    margin: 0px -10px !important;
    border-radius: 8px !important;
    outline: none !important;
    transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s,
      -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important;
    border-width: initial !important;
    border-style: none !important;
    border-color: initial !important;
    border-image: initial !important;
    background: transparent !important;
    text-decoration: underline !important;
  }
  ._ls0e43 {
    margin-left: auto !important;
  }
  ._ajuxzjo {
    cursor: pointer !important;
    display: inline-block !important;
    position: relative !important;
    text-align: center !important;
    width: auto !important;
    touch-action: manipulation !important;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
      "Helvetica Neue", sans-serif !important;
    line-height: 18px !important;
    font-weight: 600 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    color: rgb(255, 255, 255) !important;
    margin: 0px !important;
    text-decoration: none !important;
    border-radius: 8px !important;
    outline: none !important;
    transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s,
      -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important;
    border-width: initial !important;
    border-style: none !important;
    border-color: initial !important;
    border-image: initial !important;
    background: #389244 !important;
  }
`;

export const BrandName = styled.div`
    font-weight: 700; 
    font-size: 25px; 
    text-align:center;
    margin-top: 60px;
    margin-bottom: 10px;
    color:green;
    @media screen and (max-width: 500px){
      font-size: 3.4vw;
    }
`;

export const BrandInfo = styled.div`
    font-weight: 500;
    font-size: 19px;
    padding-top: 20px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    @media screen and (max-width: 500px){
      font-size: 3vw;
    }
`;

export const BrandImageWrapper = styled.div`
  height: 150px;
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 150px;
    width: auto;
  }
  .brand-image {
    max-height: 150px;
    width: auto;
  }

  @media (max-width: 767px) {
    height: 90px !important;
    img {
      max-height: 90px;
      width: auto;
    }
    .brand-image{
      max-height: 90px;
      width: auto;
    }
  }
`;
export const PromoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
  margin: 0px 10px;
`;

export const ArrivalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
  margin: 15px 10px 0px;
  
  &.express > span {
    color: #f01266;
  }
`;

export const TrendingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
  margin: 0px 10px;
`;
export const PromoTitle = styled.span`
  font-size: 26px;
  font-weight: 700;
  float: left;
  padding-left: 15px;
  // padding-bottom: 8px;
  color: rgb(115,115,115);
  @media (max-width: 767px){
    font-size: 1.2rem;
  }
`;
export const ArrivalTitle = styled.span`
  font-size: 26px;
  font-weight: 700;
  float: left;
  padding-left: 15px;
  // padding-bottom: 8px;
  color: rgb(115,115,115);
  @media (max-width: 767px){
    font-size: 1.2rem;
  }
`;
export const TrendingTitle = styled.span`
  font-size: 26px;
  font-weight: 700;
  float: left;
  padding-left: 15px;
  // padding-bottom: 8px;
  color: rgb(115,115,115);
  @media (max-width: 767px){
    font-size: 1.2rem;
  }
`;
export const BrandsTitle = styled.span`
  font-size: 26px;
  font-weight: 700;
  float: left;
  padding-left: 15px;
  padding-bottom: 8px;
  color: rgb(115,115,115);
  @media (max-width: 767px){
    font-size: 1.2rem;
  }
`;
export const ViewAll = styled.span`
  cursor: pointer;
  font-size: 26px;
  line-height: 1.5;
  font-weight: 700;
  float: right;
  padding-right: 15px;
  padding-bottom: 8px;
  color: ${themeGet("colors.primary", "#389244")};
  @media (max-width: 767px){
    font-size: 1.2rem;
  }
`;

export const Prev = styled("button")`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(250,250,250,0.8);
  color: ${themeGet("colors.primary", "#389244")};
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 0;
  outline: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 40px;
  margin-top: -20px;
  z-index: 99;
`;

export const Next = styled("button")`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(250,250,250,0.8);
  color: ${themeGet("colors.primary", "#389244")};
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 0;
  outline: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 40px;
  margin-top: -20px;
  z-index: 99;
`;

export const PrevBrand = styled("button")`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255);
  color: ${themeGet("colors.primary", "#389244")};
  padding: 0;
  // border-radius: 5px;
  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 0;
  outline: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 40px;
  margin-top: -20px;
  // z-index: 99;
`;

export const NextBrand = styled("button")`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255);
  color: ${themeGet("colors.primary", "#389244")};
  padding: 0;
  // border-radius: 5px;
  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 0;
  outline: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 40px;
  margin-top: -20px;
  // z-index: 99;
`;

export const Wrapper = styled("div")`
`;

export const ErrorModal = styled.div`
  p {
    font-size: 24px;
    margin-bottom: 20px;
  }
  button.express.cancelBtn {
    
  }
`;