import React from "react";
import styled from "styled-components";
import { Modal } from "@redq/reuse-modal";
import DeliveryInfoEnglish from "./html/en/delivery-info";
import DeliveryInfoKhmer from "./html/km/delivery-info";

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 130px 0 60px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
  
  ul {
    margin-left: 30px;
    
    li {
      margin-bottom: 5px;
      line-height: 1em;
      margin-top: 15px;
    }
    li:before {
      content: "-";
      font-size: 30px;
      position: relative;
      top: 3px;
      margin-right: 15px;
    }
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  @media (min-width: 990px) {
    width: 870px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 30px;
  }
`;

export default function DeliveryInformationScreen() {
  const lang = localStorage.getItem('lang') || 'en';

  return (
    <Modal>
      <HelpPageWrapper>
        <HelpPageContainer>

          {lang === 'km'
            ? <DeliveryInfoKhmer />
            : <DeliveryInfoEnglish />
          }

        </HelpPageContainer>
      </HelpPageWrapper>
    </Modal>
  );
}
