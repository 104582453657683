import { combineReducers } from 'redux';
import {authReducer} from './authReducer';
import {globalReducer} from './globalReducer';
import {productReducer} from './productReducer';
import {userReducer} from './userReducer';
import {cartReducer} from './cartReducer';
import {dashboardReducer} from './dashboardReducer';
import {expressReducer} from './expressReducer'
const rootReducer = combineReducers({
    authReducer,
    globalReducer,
    productReducer,
    userReducer,
    cartReducer,
    dashboardReducer,
    expressReducer
});
 export default rootReducer;