import * as actionTypes from "../ActionTypes";

const initialState = {
  stores: [],
  extraBrands: [],
  storeSelected: null,
  products: [],
  sliders: [],
};

export const expressReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXPRESS_STORES: {
      return {
        ...state,
        stores: action.payload,
      };
    }
    case actionTypes.EXPRESS_STORE_SELECTED: {
      return {
        ...state,
        storeSelected: action.payload,
      };
    }
    case actionTypes.EXPRESS_STORE_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      };
    }
    case actionTypes.EXPRESS_STORE_SLIDERS: {
      return {
        ...state,
        sliders: action.payload,
      };
    }
    case actionTypes.EXPRESS_EXTRA_BRANDS: {
      return {
        ...state,
        extraBrands: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
