import * as actionTypes from "../ActionTypes";
const initialState = {
  orderPlaced: false,
  // deliveryCharge: 0,
  cartData: [],
  pmethod: "cod",
  cartType: 'normal',
  wallet: null,
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.Place_Order: {
      return {
        ...state,
        orderPlaced: action.success,
      };
    }
    // case actionTypes.Delivery_Charges: {
    //   return {
    //     ...state,
    //     deliveryCharge: action.payload.deliveryCharges,
    //   };
    // }
    case actionTypes.Add_Item_To_Cart: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionTypes.Remove_Item_From_Cart: {
      return {
        ...action,
        ...action.payload,
      };
    }
    case actionTypes.Update_Item_Quantity_Cart: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionTypes.Fetch_Cart: {
      return {
        ...state,
        cartData: action.payload,
      };
    }
    case actionTypes.Clear_Cart: {
      return {
        ...state,
        cartData: [],
      };
    }
    case actionTypes.INIT_BASKET: {
      return {
        ...state,
        cartType: action.payload || 'normal',
      };
    }
    case actionTypes.Place_Order: {
      return {
        ...state,
        orderPlaced: action.success,
      };
    }
    case actionTypes.Payment_Dialog_Close_Without_Payment: {
      return {
        ...state,
        orderPlaced: false,
      };
    }
    case actionTypes.INIT_WALLET: {
      return {
        ...state,
        wallet: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
