import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
// import { Modal } from "@redq/reuse-modal";
import Modal from 'react-bootstrap/Modal'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { doSupplierLogin } from "../../store/actions/authAction";
import { AuthContext } from "../../contexts/auth/auth.context";
import UploadService from "../../services/FileUploadService";
import * as Constants from "../../constants/Constant";
import axios from "axios";
import { Table } from 'react-bootstrap';
import * as Constant from "../../constants/Constant";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
//import "bootstrap/js/src/collapse.js";
import {getCategory} from '../../store/actions/dashboardAction'
import { connect } from "react-redux";
import API from "../../network/dashboardAPI"

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  min-height: 400px;
  @media (min-width: 1100px) {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 60px;
  }
`;
const logo = {
  height : "50Px",
  width : "50Px"
}
const CategoryListing = (props) => {

      const { authDispatch } = useContext(AuthContext);
      let admin = localStorage.getItem("admin");
      let parsedUser = JSON.parse(admin);
      if(!admin) {
        window.location.href = "/";
      }

      const [showEnable, setShowEnable] = useState(false);
      const [showDisable, setShowDisable] = useState(false);
      const [modalHead,setModalHead] = useState("");
      const [modalMessage,setModalMessage] = useState("");
      const [editId, setEditId] = useState(null);
      const [available,setAvailable] = useState(0);
      const [showImage, setShowImage] = useState(false);
      const [imgUrl , setImgUrl] = useState("")

      const hnadleShowImageClose = () => {
          setShowImage(false)
      }

      const hnadleShowImageOpen = (url) => {
          setImgUrl(url)
          setShowImage(true)
      }

      const addcategoryPage = (event) => {
        window.location.href = "/admin/categorie/add";
      }

      const handleClose = () => {
        setShowEnable(false);
        setShowDisable(false);
      }

      const enableModal = (proId,proName) => {
        setEditId(proId);
        setAvailable(1);
        setModalHead("Enable");
        setModalMessage("Want to enable product - '"+proName+"'?");
        setShowEnable(true);
        console.log("here"+proId+proName)
      };

      const disableModal = (proId,proName) => {
        setEditId(proId);
        setAvailable(0);
        setModalHead("Disable");
        setModalMessage("Want to disable product - '"+proName+"' ?");
        setShowDisable(true);
        console.log("here"+proId+proName)
      };
      const enableProduct = () => {
          setModalHead("Updating");
          API.updatenableDisableCategory({
            id: editId,
            availability: available,
            get_type : 1
          })
          .then(response => {
              setShowEnable(false);
              setShowDisable(false);
              props.getCategory({
                "store_id" : 1,
                "get_type" : 1 
              })
          }).catch((error) => {
              setModalHead(error);
          });
        }

      useEffect(() => {
        props.getCategory({
            "store_id" : 1,
            "get_type" : 1 
          })
      },[])


      let content = null;
      if(props.categories){
          content = props.categories.map((categorie,index)=>
         
            <tr key = {index}  >
            <td onClick= {()=>hnadleShowImageOpen(categorie.list_categories[0].image)} ><img src={categorie.list_categories[0].image} alt="img" style = {logo} /></td>
            <td>{categorie.cat_id}</td>
            <td>{categorie.list_categories[0].name}</td>
            <td>{categorie.is_available === 1 ? 
                (
                    <Button variant="success" onClick={() => disableModal(categorie.cat_id,categorie.list_categories[0].name)}>
                        Enabled
                    </Button>
                ) : (
                    <Button variant="danger" onClick={() => enableModal(categorie.cat_id,categorie.list_categories[0].name)}>
                        Disabled
                    </Button>
                )}
            </td>
            <td>
                <Link to={{ pathname: '/admin/categorie/update', state: {cat_id: categorie.cat_id,name : categorie.list_categories[0].name, image : categorie.list_categories[0].image } }}>
                    <Button variant="info" type="submit">
                        <EditIcon></EditIcon>
                    </Button>
                </Link>
            </td>
        </tr>
        
      )}
    

    return (
    
      <HelpPageWrapper>
        <Row>
        <Col >
                <Button variant="success" type="submit" className="mb-5" onClick={addcategoryPage }>
                    Add category
                </Button>
            </Col>
        </Row>
        <Row>
            <Col sm={11}>
              { !content?<div style = {{position: "fixed" , left: "55%" , top : "35%"}} > <Loader   color = "#389244" h = "100"/> </div> :
              <Table striped bordered hover>
                <thead>
                      <tr>
                         <th>Image</th>
                          <th>ID</th>
                          <th>Categorie Name</th>
                          <th>Available</th>
                          <th>Edit</th>
                      </tr>
                  </thead>
                  <tbody >

                   { content}
                   </tbody>
              </Table>
            }
            </Col>
        </Row>
        <div>

        <Modal show={showEnable} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{modalHead}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="success" onClick={enableProduct}>
                    Enable
                </Button>
                </Modal.Footer>
            </Modal>

          <Modal show={showDisable} onHide={handleClose}>
              <Modal.Header closeButton>
              <Modal.Title>{modalHead}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{modalMessage}</Modal.Body>
              <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                  Close
              </Button>
              <Button variant="danger" onClick={enableProduct}>
                  Disable
              </Button>
              </Modal.Footer>
          </Modal>


          <Modal show={showImage} onHide={hnadleShowImageClose}>
            <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
            </Modal.Header>
            <Modal.Body><img alt ="Categories" src = {imgUrl} style = {{height : "400Px" , width : "400Px", alignItems : "center"}}/></Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={hnadleShowImageClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        </div>
      </HelpPageWrapper>
  
    );
}


const mapStateToProps = state =>{
   return {
    categories : state.dashboardReducer.category
   }
  
 }

 const mapDispatchToProps = dispatch => {
   return {
     getCategory : data => getCategory(data,dispatch) 
   }
 }
 
export default connect(mapStateToProps,mapDispatchToProps)(CategoryListing)