import React, { useReducer, useContext, createContext } from "react";
import { reducer, cartItemsTotalPrice } from "./cart.reducer";
import { useStorage } from "../../helper/use-storage";
import API from "../../network/redAPI";
import { checkAuthError, logoutuser } from "../../helper/constant";

export const CartContext = createContext({});
const INITIAL_STATE = {
  isOpen: false,
  items: [],
  isRestaurant: false,
  coupon: null,
  isloading: false,
  carttotal: 0.00,
};

const useCartActions = (initialCart = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialCart);

  const addItemToCartHandler = async (item, cartType = 'normal', quantity = 1) => {
    const currentItemClass = "pr"+item["item_id"];
    for(let i=0; i<=document.getElementsByClassName(currentItemClass).length-1;i++){
      document.getElementsByClassName(currentItemClass)[i].classList.add("disabLed");
    }

    const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);
    const item_details = item;
    item_details["quantity"] = 1;
    item_details["item_price_id"] = item_details.store_items_price_types[0].id;

    let body = {
      item_details,
      store_id: 1,
      cust_id: parsedUser.cust_id,
      order_id:
        localStorage.getItem("order_id") !== null
          ? localStorage.getItem("order_id")
          : 0,
    };
    if(cartType === 'express') {
      body = {
        store_id: 1,
        item_id: item["item_id"],
        type: 1,
        quantity,
        confirm: 0
      };
    }
    API.AddItemToCart(body)
      .then((response) => {
        if(checkAuthError(response)){
        //logout 
        throw new Error("Unauthorized")
        }
        else{
          return response.json() 
        }
      })
      .then((data) => {
        if (data.success) {
          for(let i=0; i<=document.getElementsByClassName(currentItemClass).length-1;i++){
            document.getElementsByClassName(currentItemClass)[i].classList.remove("disabLed");
          }
          localStorage.setItem("order_id", data.data.order_id);
          window.analytics && window.analytics.track("Product Added", {
            product_id: item.item_id,
            sku: item.product_id,
            category: item.cat_id,
            name: item.name,
            brand:
              item.list_brands &&
              item.list_brands[0] &&
              item.list_brands[0].brand_name
                ? item.list_brands[0].brand_name
                : "",
            variant: item.name,
            price: item.item_price,
            quantity: 1,
          });
          fetchOrderDetails(cartType);
        } else {
          for(let i=0; i<=document.getElementsByClassName(currentItemClass).length-1;i++){
            document.getElementsByClassName(currentItemClass)[i].classList.remove("disabLed");
          }
          if(cartType !== 'express') {
            if(data.message){
              alert("We have encountered error while adding item to your cart. We will refresh your cart now.");
            }
            else{
              alert("We encountered an error while adding item to your cart , please retry");
            }
          }
        }
      })
      .catch(err => {
        if (err.message === "Unauthorized"){
          alert("Your login session is invalid, please login again");
          logoutuser();
        }
      });
  };

  const removeItemFromCartHandler = async (item, cartType = 'normal') => {
    dispatch({
      type: "ISLOADING",
    });
    const user = localStorage.getItem("user");
    const item_details = item;
    item_details["quantity"] = 0;
    const body = {
      item_id: item["item_id"],
      item_price_id: item["item_price_id"],
      order_id: localStorage.getItem("order_id"),
      type: 1,
    };

    API.RemoveItemFromCart(body)
      .then((response) => {
        if(checkAuthError(response)){
        //logout 
        throw new Error("Unauthorized")
        }
        else{
          return response.json() 
        }
      })
      .then((data) => {
        if (data.success) {
          dispatch({
            type: "NOTLOADING",
          });
          window.analytics && window.analytics.track("Product Removed", {
            product_id: item.item_id,
            sku: item.product_id,
            category: item.cat_id,
            name: item.name,
            brand:
              item.list_brands &&
              item.list_brands[0] &&
              item.list_brands[0].brand_name
                ? item.list_brands[0].brand_name
                : "",
            variant: item.name,
            price: item.item_price,
            quantity: 1,
          });
          console.log("Removed Item is ", item);
          // dispatch({ type: "CLEAR_ITEM_FROM_CART", payload: item });
          fetchOrderDetails(cartType);
        } else {
          dispatch({
            type: "NOTLOADING",
          });
          if(data.message){
            alert("We have encountered error while removing item to your cart. We will refresh your cart now.");
          }
          else{
            alert("We encountered an error while removing item to your cart , please retry");
          }
        }
      })
      .catch(err => {
        if (err.message === "Unauthorized"){
          alert("Your login session is invalid, please login again");
          logoutuser();
        }
      });
  };

  // This function is for increase the quantity of item
  const addItemHandler = (item, quantity = 1, cartType = 'normal') => {
    const currentItemClass = "pr"+item["item_id"];
    for(let i=0; i<=document.getElementsByClassName(currentItemClass).length-1;i++){
      document.getElementsByClassName(currentItemClass)[i].classList.add("disabLed");
    }

    const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);
    const item_details = item;
    item_details["quantity"] = item.quantity + 1;
    let body = {
      item_details,
      store_id: 1,
      cust_id: parsedUser.cust_id,
      order_id: localStorage.getItem("order_id"),
      type: cartType === 'express' ? 1 : 0,
    };
    if(cartType === 'express') {
      body = {
        store_id: 1,
        item_id: item["item_id"],
        type: 1,
        quantity: item.quantity,
      };
    }

    API.AddItemToCart(body)
      .then((response) => {
        if(checkAuthError(response)){
        //logout
        throw new Error("Unauthorized")
        }
        else{
          return response.json()
        }
      })
      .then((data) => {
        if (data.success) {
          for(let i=0; i<=document.getElementsByClassName(currentItemClass).length-1;i++){
            document.getElementsByClassName(currentItemClass)[i].classList.remove("disabLed");
          }
          window.analytics && window.analytics.track("Product Added", {
            product_id: item.item_id,
            sku: item.product_id,
            category: item.cat_id,
            name: item.name,
            brand:
              item.list_brands &&
              item.list_brands[0] &&
              item.list_brands[0].brand_name
                ? item.list_brands[0].brand_name
                : "",
            variant: item.name,
            price: item.item_price,
            quantity: 1,
          });
          fetchOrderDetails(cartType);
        } else {
          for(let i=0; i<=document.getElementsByClassName(currentItemClass).length-1;i++){
            document.getElementsByClassName(currentItemClass)[i].classList.remove("disabLed");
          }
          if(cartType !== 'express') {
            if(data.message){
              alert("We have encountered error while updating item to your cart. We will refresh your cart now.");
            }
            else{
              alert("We encountered an error while updating item to your cart , please retry");
            }
          }
        }
      })
      .catch(err => {
        if (err.message === "Unauthorized"){
          alert("Your login session is invalid, please login again");
          logoutuser();
        }
      });
  };

  const removeItemHandler = async(item, quantity = 1, cartType = 'normal') => {
    const currentItemClass = "pr"+item["item_id"];
    for(let i=0; i<=document.getElementsByClassName(currentItemClass).length-1;i++){
      document.getElementsByClassName(currentItemClass)[i].classList.add("disabLed");
    }
    const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);
    const item_details = item;
    item_details["quantity"] = item.quantity - 1;
    let body = {
      item_details,
      store_id: 1,
      cust_id: parsedUser.cust_id,
      order_id: localStorage.getItem("order_id"),
    };
    if(cartType === 'express') {
      body = {
        store_id: 1,
        item_id: item["item_id"],
        type: 1,
        quantity: item_details["quantity"]
      };
    }

    API.AddItemToCart(body)
      .then((response) => {
        if(checkAuthError(response)){
        //logout 
        throw new Error("Unauthorized")
        }
        else{
          return response.json() 
        }
      })
      .then((data) => {
        if (data.success) {
          /*for(let i=0; i<=document.getElementsByClassName(currentItemClass).length-1;i++){
            document.getElementsByClassName(currentItemClass)[i].classList.remove("disabLed");
          }*/
          window.analytics && window.analytics.track("Product Removed", {
            product_id: item.item_id,
            sku: item.product_id,
            category: item.cat_id,
            name: item.name,
            brand:
              item.list_brands &&
              item.list_brands[0] &&
              item.list_brands[0].brand_name
                ? item.list_brands[0].brand_name
                : "",
            variant: item.name,
            price: item.item_price,
            quantity: 1,
          });
          fetchOrderDetails(cartType);
        } else {
          for(let i=0; i<=document.getElementsByClassName(currentItemClass).length-1;i++){
            document.getElementsByClassName(currentItemClass)[i].classList.remove("disabLed");
          }
          if(data.message){
            alert("We have encountered error while updating item to your cart. We will refresh your cart now.");
          }
          else{
            alert("We encountered an error while updating item to your cart , please retry");
          }
          console.log(data.error);
        }
      })
      .catch(err => {
        if (err.message === "Unauthorized"){
          alert("Your login session is invalid, please login again");
          logoutuser();
        }
      });
  };

  const updateItemQuantityHandler = (item, quantity= 1, cartType = 'normal') => {
    const currentItemClass = "pr"+item["item_id"];
    for(let i=0; i<=document.getElementsByClassName(currentItemClass).length-1;i++){
      document.getElementsByClassName(currentItemClass)[i].classList.add("disabLed");
    }

    const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);
    const item_details = item;
    item_details["quantity"] = quantity;
    let body = {
      item_details,
      store_id: 1,
      cust_id: parsedUser.cust_id,
      order_id: localStorage.getItem("order_id"),
      type: 0,
    };
    if(cartType === 'express') {
      body = {
        store_id: 1,
        item_id: item["item_id"],
        type: 1,
        quantity: quantity,
      };
    }

    API.AddItemToCart(body)
      .then((response) => {
        if(checkAuthError(response)){
        //logout 
        throw new Error("Unauthorized")
        }
        else{
          return response.json() 
        }
      })
      .then((data) => {
        if (data.success) {
          for(let i=0; i<=document.getElementsByClassName(currentItemClass).length-1;i++){
            document.getElementsByClassName(currentItemClass)[i].classList.remove("disabLed");
          }
          fetchOrderDetails(cartType);
        } else {
          for(let i=0; i<=document.getElementsByClassName(currentItemClass).length-1;i++){
            document.getElementsByClassName(currentItemClass)[i].classList.remove("disabLed");
          }
          if(data.message){
            alert("We have encountered error while updating item to your cart. We will refresh your cart now.");
          }
          else{
            alert("We encountered an error while updating item to your cart , please retry");
          }
        }
      })
      .catch(err => {
        if (err.message === "Unauthorized"){
          alert("Your login session is invalid, please login again");
          logoutuser();
        }
      });
  };

  const clearItemFromCartHandler = (item, cartType = 'normal') => {
    dispatch({
      type: "ISLOADING",
    });
    const body = {
      item_id: item["item_id"],
      item_price_id: item["item_id"],
      type: 2,
      order_id: localStorage.getItem("order_id"),
      quantity: 0,
    };

    API.AddItemToCart(body)
      .then((response) => {
        if(checkAuthError(response)){
        //logout 
        throw new Error("Unauthorized")
        }
        else{
          return response.json() 
        }
      })
      .then((data) => {
        console.log(data.data);
        if (data.success) {
          dispatch({
            type: "NOTLOADING",
          });
          fetchOrderDetails(cartType);
        } else {
          dispatch({
            type: "NOTLOADING",
          });
          console.log(data.error);
        }
      })
      .catch(err => {
        if (err.message === "Unauthorized"){
          alert("Your login session is invalid, please login again");
          logoutuser();
        }
      });
  };

  const clearCartHandler = async () => {
    dispatch({ type: "CLEAR_CART"});
    localStorage.setItem("order_id", 0);
  };

  const resetCartHandler = () => {
    const body = {order_id: localStorage.getItem("order_id")};

    API.ClearCart(body).then((response) => {
      if(checkAuthError(response)){
      //logout 
       throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        localStorage.setItem("order_id", 0);
        dispatch({ type: "CLEAR_CART"});
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
  };

  const fetchOrderDetails = (cartType = 'normal') => {
    localStorage.setItem("order_id",0);
    const lang = localStorage.getItem('lang') || 'en';
    const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);
    const body = {
      order_id: localStorage.getItem("order_id") ,
      cust_id: parsedUser.cust_id,
      type: (cartType === 'express') ? 1 : 0,
      lang
    };

    API.FetchCart(body)
      .then((response) => {
        if(checkAuthError(response)){
        //logout 
        throw new Error("Unauthorized")
        }
        else{
          return response.json() 
        }
      })
      .then((data) => {
        if (data.success) {
          if(data.data.orderdata === null){localStorage.setItem("order_id", 0)}
          else{localStorage.setItem("order_id", data.data.order_id)}
          dispatch({
            type: "FETCH_CART",
            payload: data.data.orderdata !== null ? data.data.orderdata.order_item_details : [],
            total: data.data.orderdata !== null ? data.data.item_total : 0
          });
        }
      })
      .catch(err => {
        if (err.message === "Unauthorized"){
          alert("Your login session is invalid, please login again");
          logoutuser();
        }
      });
  };
  const disableloading = async () => {
      dispatch({ type: "NOTLOADING" });
  };
  const toggleCartHandler = () => {
    dispatch({ type: "TOGGLE_CART" });
    document.getElementsByClassName("product-cart")[0].style.visibility = "hidden";
  };
  const cartclose = () => {
    dispatch({ type: "CLOSECART"});
  }
  const couponHandler = (coupon) => {
    dispatch({ type: "APPLY_COUPON", payload: coupon });
  };
  const removeCouponHandler = () => {
    dispatch({ type: "REMOVE_COUPON" });
  };
  const rehydrateLocalState = (payload) => {
    dispatch({ type: "REHYDRATE", payload });
  };
  const toggleRestaurant = () => {
    dispatch({ type: "TOGGLE_RESTAURANT" });
  };
  const isInCartHandler = (item_id) => {
    return state.items?.some((item) => item.item_id === item_id);
  };
  const getItemHandler = (item_id) => {
    return state.items?.find((item) => item.item_id === item_id);
  };
  const getCartItemsPrice = () =>
    cartItemsTotalPrice(state.items, state.coupon).toFixed(2);
  const getCartItemsTotalPrice = () =>
    cartItemsTotalPrice(state.items, state.coupon).toFixed(2);

  const getDiscount = () => {
    const total = cartItemsTotalPrice(state.items);
    console.log("Coupon code used ", state.coupon);
    const discount = state.coupon
      ? (total * Number(state.coupon?.percentage)) / 100
      : 0;
    return discount.toFixed(2);
  };
  const getItemsCount = state.items?.reduce(
    (acc, item) => acc + item.quantity,
    0
  );
  return {
    state,
    getItemsCount,
    rehydrateLocalState,
    addItemToCartHandler,
    removeItemFromCartHandler,
    addItemHandler,
    removeItemHandler,
    updateItemQuantityHandler,
    clearItemFromCartHandler,
    clearCartHandler,
    resetCartHandler,
    isInCartHandler,
    getItemHandler,
    toggleCartHandler,
    cartclose,
    disableloading,
    getCartItemsTotalPrice,
    getCartItemsPrice,
    fetchOrderDetails,
    couponHandler,
    removeCouponHandler,
    getDiscount,
    toggleRestaurant,
  };
};

export const CartProvider = ({ children }) => {
  const {
    state,
    rehydrateLocalState,
    getItemsCount,
    addItemToCartHandler,
    removeItemFromCartHandler,
    addItemHandler,
    removeItemHandler,
    updateItemQuantityHandler,
    clearCartHandler,
    resetCartHandler,
    isInCartHandler,
    getItemHandler,
    toggleCartHandler,
    cartclose,
    disableloading,
    getCartItemsTotalPrice,
    fetchOrderDetails,
    couponHandler,
    removeCouponHandler,
    getCartItemsPrice,
    getDiscount,
    toggleRestaurant,
  } = useCartActions();
  const { rehydrated, error } = useStorage(state, rehydrateLocalState);
  return (
    <CartContext.Provider
      value={{
        state: state,
        isloading: state.isloading,
        isOpen: state.isOpen,
        items: state.items,
        coupon: state.coupon,
        isRestaurant: state.isRestaurant,
        cartItemsCount: state.items?.length,
        itemsCount: getItemsCount,
        carttotal: state.carttotal,
        fetchOrderItems: fetchOrderDetails,
        addItemToCart: addItemToCartHandler,
        addItem: addItemHandler,
        removeItem: removeItemHandler,
        removeItemFromCart: removeItemFromCartHandler,
        updateItem: updateItemQuantityHandler,
        clearCart: clearCartHandler,
        resetCart: resetCartHandler,
        isInCart: isInCartHandler,
        getItem: getItemHandler,
        toggleCart: toggleCartHandler,
        cartclose: cartclose,
        disableloading: disableloading,
        calculatePrice: getCartItemsTotalPrice,
        calculateSubTotalPrice: getCartItemsPrice,
        applyCoupon: couponHandler,
        removeCoupon: removeCouponHandler,
        calculateDiscount: getDiscount,
        toggleRestaurant,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
