import React, { useEffect } from "react";
import { SearchContext } from "../../contexts/search/search.context";
import {
  SearchResultWrapper,
  ItemWrapper,
  SubmenuHeading,
} from "./SearchBox.style";
import t from "../../i18n/translate";

const SearchResults = ({
  suggestions,
  itemStyle,
  wrapperStyle,
  setSuggestionValue,
  clearSearch,
}) => {
  const setSuggestion = (suggestion) => {
    console.log("Selected sub cat ", suggestion);
    searchdispatch({
      type: "SUGGESTION",
      payload: {
        ...state,
        suggestion,
      },
    });
    setSuggestionValue(suggestion);
  };
  const { state, searchdispatch } = React.useContext(SearchContext);

  return (
    <SearchResultWrapper {...wrapperStyle}>
      <SubmenuHeading>
        <h3>{ t('search_item_suggestion_label') }</h3><button onClick={clearSearch}>{ t('shop_item_filter_clear_button') }</button>
      </SubmenuHeading>
      {suggestions &&
        state.text !== "" &&
        suggestions.map((suggestion) => (
          <ItemWrapper
            {...itemStyle}
            key={suggestion.sub_cat_id}
            onClick={() => setSuggestion(suggestion.sub_cat_id)}
          >
            <strong>`{state.text}`</strong> {`${ t('search_item_suggestion_in_label', false) } `}
            <strong>`{suggestion.sub_cat_name}`</strong>
          </ItemWrapper>
        ))}
    </SearchResultWrapper>
  );
};

export default SearchResults;
