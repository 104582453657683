import React from 'react';
import styled from "styled-components";

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const TermsEnglish = () => {
  return (
    <React.Fragment>
      <Heading>Terms And Conditions</Heading>
      <p><b>User agreement</b></p>
      <p> By using the Delishop.asia (&ldquo;Website&rdquo;) and our social media pages (&ldquo;Social Media&rdquo;) you accept these terms and conditions (&quot;Agreement&quot;) and our Privacy Policy. We may change these terms at any time, and changes will be posted on the Website. By continuing to use the Website, you agree to be bound by the changes.</p>

      <p></p>

      <br />
      <p><b>Registration</b><p>
      </p> You must be a registered member to make orders and access some features of the Website. You will provide us with personal information including your name, address, and a valid email address. You must ensure this information is accurate and current.</p>

      <p>By using the Website and its associated functionality, you grant your express consent to us to send you direct marketing communications to the email address you provide, from which you may unsubscribe at any time. Your consent to receipt of certain communications may be implied from the use of certain functional aspects of our service, such as receiving reminders that items are in your shopping cart if you leave the page during a transaction.</p>

      <p>To register an account and use the Website you must be at least 18 years old and have capacity to enter into a legally binding agreement with us.</p>

      <p></p>

      <br />
      <p><b>Access and use of the Website</b> </p>
      <p>You must only use the Website through the interfaces provided by us and in accordance with these terms and any applicable law.</p>

      <p>You must not (or attempt to):</p>

      <p>interfere (or attempt to interfere) or disrupt (or attempt to disrupt) our site or the servers or networks that host our site; use (or attempt to use) data mining, robots, screen scraping or similar data gathering and extraction tools on our site; interfere (or attempt to interfere) with security-related or other features of our site; and/or use, copy or distribute (or attempt to use, copy or distribute) content without our express permission. We may refer fraudulent or abusive or illegal activity to the relevant authorities. You are solely responsible for the activity that occurs on your account (including orders placed using your account), and you must keep your account password secure. We are not responsible for any unauthorised activity on your account if you fail to keep your account login information secure.</p>

      <p>You must not use another member's account without our, and/or the other user&rsquo;s, express permission. If you suspect or become aware of any unauthorised use of your account or that your password is no longer secure, you must notify us immediately and take immediate steps to re-secure your account (including by changing your password).</p>

      <p>We do not warrant that the Website will be available at all times and without disruption and we provide no warranties in relation to the content of any other website linked to or from our Website.&#8232;</p>

      <p></p>

      <br />
      <p><b>Information on this Website</b></p>
      <p>Information about goods on the Website is based on material provided by suppliers and product manufacturers. Except as required by law we cannot be held responsible for inaccuracies or errors caused by incorrect information supplied to us or by these third parties.</p>

      <p></p>

      <br />
      <p><b>Disclaimer and Liability</b></p>
      <p>To the fullest extent possible at law, we exclude all liability to you or anyone else for loss or damage of any kind or nature relating in any way to the Website including, but not limited to, loss or damage you might suffer as a result of:</p>

      <p>errors, mistakes or inaccuracies on the Website or our social media pages; you acting or not acting, on any information contained on or referred to on the Website and/or any linked Website or our social media pages; personal injury or property damage of any nature resulting from your access to or use of the Website; any unauthorised access to or use of our secure servers and/or personal information; any interruption or cessation of transmission to or from the Website; any bugs, viruses, trojan horses or other harmful code or communications which may be transmitted to or through our Website by any third party; and/or the quality of any product or service of any linked sites. Where any law provides a guarantee which may not be lawfully excluded, our liability will be limited to that provided by law.</p>

      <p>Except as required by law, in no event shall we be liable for any loss or any special, incidental or consequential damages arising out of or in connection with our Website or this Agreement (however arising, including negligence). You agree to accept sole responsibility for the legality of your actions under the laws that apply to you. You agree that we have no responsibility for the legality of your actions.</p>

      <p></p>

      <br />
      <p><b>Order Acceptance &amp; Pricing</b></p>
      <p>You are responsible for all orders placed on your account, including for any discrepancies or errors in your order caused by you.</p>

      <p>Please note that in case when an order cannot be processed for various reasons. The site reserves the right to refuse or cancel any order for any reason at any time. You may be asked to provide additional verifications or information, including but not limited to phone number and address before we accept the order.</p>

      <p>We try, strive or are determined to provide the most accurate pricing information on the site to our users; however, errors may still occur, such as in case when the price of an item is not displayed correctly on the site. So, we reserve the right to refuse or cancel any order. In the event that an item is mispriced, we may, at our own discretion, either contact you for instructions or cancel your order and notify you of such cancellation. We shall have the right to refuse or cancel any such orders whether or not the order has been confirmed and your credit card or bank account charged. You must review your order carefully before placing it. Once an order is confirmed, you are unable to cancel or change it. If you have any problems with your order, then you must go through the normal returns process outlined in in this Agreement. Please note that change of mind returns are given at our absolute discretion. As a general rule, we will not change or replace your order where you have made an error.</p>

      <p></p>

      <br />
      <p><b>Supply and Delivery of Goods</b></p>
      <p> Subject to this Agreement, we will supply the goods shown on your order confirmation email. We will use our best endeavours to meet stated time-slot for delivery, however we cannot guarantee delivery time-slot will be met. Once the order is successfully delivered, the status of the order will be marked as “delivered” in your account section. You will have 12 hours from the time the order status is marked as "delivered" to raise any issue about the delivery service or delivered products' quality.</p>

      <p></p>

      <br />
      <p><b>Packaging and Labelling </b></p>
      <p> We endeavour to depict goods available for order using accurate images of the goods. At times, however, goods actually delivered may to a small extent differ in appearance and packaging from their appearance in images and photographs on the Website.</p>

      <p>We recommend that you read the labels and instructions on foods or other consumables prior to consumption or use.</p>

      <p></p>

      <br />
      <p><b>Goods Out of Stock </b></p>
      <p>We reserve the right to notify you that goods for which you placed orders have become unavailable for reasons beyond our control. You may receive a full refund or coupon credit (at your election) in the event we cannot fulfil your order.&#8232;</p>

      <p></p>

      <br />
      <p><b>Social Media and Content </b></p>
      <p>You understand that all information, such as comments, messages, text, files, images, photos, video, sounds and other materials (&quot;content&quot;) posted on, transmitted through or linked from the Website, our Facebook page, Twitter feed, or forum or other like application or site that allows for the publication of user generated material (&ldquo;Social Media&rdquo;), is the sole responsibility of the person from whom such content originated.</p>

      <p>You understand that we do not control and are not responsible for content made available through the Website or Social Media unless it originates from us. Consequently, by using our Social Media pages you may be exposed to content provided by third parties that is offensive, indecent, inaccurate, misleading or otherwise objectionable. You use Social Media at your own risk and to the extent permissible at law we do not accept liability in this regard.</p>

      <p>As a member or participant on our Social Media pages, you agree that you are responsible for any content submitted, posted or made available through the Website via your account and you must not post (or allow) content to be posted through your account that:</p>

      <p>you do not have the right to post; is defamatory or in contempt of any legal or other proceedings; is misleading or deceptive; is offensive or discriminates against any group of persons being a group defined by reference to colour, race, sex, origin, nationality or ethnic or national origins; denounces religious or political beliefs; contains religious or political material; is indecent, obscene, vulgar, pornographic or offensive; infringes any copyright, trade mark, patent or other intellectual property right of another person; contains any unsolicited or unauthorised advertising or promotional material; contains or links to viruses, malware, spyware or similar software; impersonates any person or misrepresents your relationship with any person;  We reserve the right, in our absolute discretion, to pre-screen, refuse or remove any content from our Social Media pages without giving any reasons. You understand and agree that we may retain server and backup copies of your submitted content even if you have altered, removed or deleted your content from public display.</p>

      <p></p>

      <br />
      <p><b>Termination</b></p>
      <p>In addition to any other legal or equitable remedies, we may, without prior notice to you, immediately terminate the Terms and Conditions or revoke any or all of your rights granted under the Terms and Conditions. Upon any termination of this agreement, you shall immediately cease all access to and use of the site and we shall, in addition to any other legal or equitable remedies, immediately revoke all password(s) and account identification issued to you and deny your access to and use of this site in whole or in part. Any termination of this agreement shall not affect the respective rights and obligations (including but not limited to payment obligations) of the parties arising before the date of termination. You furthermore agree that the site shall not be liable to you or to any other person as a result of any such suspension or termination. If you are dissatisfied with the site or with any terms, conditions, rules, policies, guidelines, or practices of Delishop, in operating the site, your sole and exclusive remedy is to discontinue using the site.</p>

      <p></p>

      <br />
      <p><b>Intellectual Property </b></p>
      <p>All intellectual property rights, whether registered or unregistered, content on the site and all the website design, including, but not limited to, text, graphics, software, photos, video, music, sound, and their selection and arrangement, and all software compilations, underlying source code and software shall remain our property. The entire contents of the site also are protected by copyright and International conventions. All rights are reserved.</p>

      <p>You may not: &#8232;</p>

      <p>modify or copy the layout or appearance of the Website nor any computer software or code contained in the Website; and/or Decompile or disassemble, reverse engineer or otherwise attempt to discover or access any source code related to the Website. If you correspond or otherwise communicate with us, you automatically grant to us an irrevocable, perpetual, non-exclusive, royalty-free, world-wide licence to use, copy, display and distribute the content of your correspondence or communication and to prepare derivative works of the content or incorporate the content into other works in order to publish and promote such content. This may include, but is not limited to, publishing testimonials on our Website and developing your ideas and suggestions for improved goods or services we provide.</p>

      <p></p>

      <br />
      <p><b>Transfer and Assignment </b></p>

      <p>You agree and acknowledge that, in the event that we merge, sell or otherwise change control of our company, our business or the Website to a third-party:</p>

      <p>We shall be permitted without giving notice or seeking prior consent from you, to disclose the personal information and other data that we have collected from you to the third party; and&#8232; We shall be entitled to assign the benefit of any agreements we have with you to the third party. </p>

      <br />
      <p><b>General </b></p>

      <p>We will not be liable for any delay in performing any of our obligations under this Agreement if such delay is caused by circumstances beyond our reasonable control.</p>

      <p>If any part of this Agreement is found to be void, unlawful or unenforceable then that part will be deemed to be severable from the balance of this Agreement and the severed part will not affect the validity and enforceability of any remaining provisions.</p>

      <p></p>
      <br />
      <p><b>Privacy and Personal information </b></p>
      <p> If you provide us with any personal information our Privacy Policy will govern how we will use or disclose that information. Please review and understand our Privacy Policy.</p>
    </React.Fragment>
  )
}

export default TermsEnglish;