import React from "react";
export const Account = ({
    color = "currentColor",
    width = "12px",
    height = "12px",
  }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 31.123 32.65">
            <path id="Path_348" data-name="Path 348" d="M16.877,32.651a59.609,59.609,0,0,1-13.09-1.306,13.451,13.451,0,0,1-2.4-.832A1.861,1.861,0,0,1,.11,28a8.074,8.074,0,0,1,4.929-6.168c1.954-.815,3.88-1.708,5.779-2.646a1.458,1.458,0,0,0,.5-2.086c-.563-1.173-1.062-2.377-1.6-3.563a1.106,1.106,0,0,0-.36-.5A2.718,2.718,0,0,1,8.389,9a1.334,1.334,0,0,0,.138-.736c-.037-.948-.1-1.9-.163-2.845a4.293,4.293,0,0,1,1.259-3.2c.212-.238.533-.575.775-.553,1.009.091,1.558-.726,2.345-1.039a8.9,8.9,0,0,1,2.345-.6,10.245,10.245,0,0,1,4.85,1.011,3.235,3.235,0,0,1,2.111,2.3c.34,1.586.468,3.217.712,4.824a2.533,2.533,0,0,0,.333,1.019,3.154,3.154,0,0,1-.937,3.772,1.844,1.844,0,0,0-.61.746c-.535,1.114-.974,2.28-1.56,3.364s-.578,1.682.517,2.245a47.821,47.821,0,0,0,5.076,2.3,8.152,8.152,0,0,1,5.54,7.29c.11.948-.641,1.373-1.328,1.6a32.735,32.735,0,0,1-5.1,1.367C22.106,32.264,19.484,32.4,16.877,32.651Z" transform="translate(-0.009 -0.001)" fill="#707070"/>
        </svg>
//         <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width={width}
//         height={height}
//         viewBox="0 0 35.979 35.979">
//    <g>
//        <path style={{fill: "#FFFFFF"}} d="M26.84,6.252l-14.046,14.23L2.464,15.12l-1.98,3.815c-1.094,2.106-0.272,4.701,1.836,5.794
//            l12.094,6.277C14.46,31.03,35.979,9.232,35.979,9.232L32.92,6.211C31.23,4.545,28.509,4.563,26.84,6.252z"/>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    <g>
//    </g>
//    </svg>
   
    );
  };