import React, { useState, useEffect } from "react";

// ------------ Components ------------
import { Modal as CustomModal } from "@redq/reuse-modal";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Loader from "../../components/Loader/Loader";
import { Switch, Tooltip } from "@material-ui/core";
import { Table, Modal, Form, Pagination, InputGroup, ListGroup, Toast, Spinner } from 'react-bootstrap';
import { Edit, RemoveCircle, Reply } from '@material-ui/icons';

// -------------- APIs ---------------
import API from "../../network/dashboardAPI";

// -------------- Style --------------
import styled from "styled-components";
import "react-datetime/css/react-datetime.css";
import { set } from "lodash";

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const labelStyle = {
    lineHeight: "2rem"
}

let typingTimer = null;

const DriverList = (props) => {
    // --------------- States ------------------
    const [isRequesting, setIsRequesting] = useState(false);
    const [driverList, setDriverList] = useState([]);
    const [isShowAddModal, setIsShowAddModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formError, setFormError] = useState({
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        phoneNumber: false,
        confirmPassword: false
    });
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [isEmailAlreadyTaken, setIsEmailAlreadyTaken] = useState(false);
    const [isPhoneAlreadyTaken, setIsPhoneAlreadyTaken] = useState(false);
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
    const [showType, setShowType] = useState(false);

    // form props
    const [isRequestingUpsert, setIsRequestingUpsert] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordNotMatch, setIsPasswordNotMatch] = useState(false);
    const [driverCount, setDriverCount] = useState(0);
    const [updateDoc, setUpdateDoc] = useState({});
    const [modalType, setModalType] = useState("Add");
    const [removeDoc, setRemoveDoc] = useState({});

    // form ref
    const _firstNameInputRef = React.useRef('');
    const _lastNameInputRef = React.useRef('');
    const _phoneInputRef = React.useRef('');
    const _emailInputRef = React.useRef('');
    const _passwordInputRef = React.useRef('');
    const _confirmPasswordInputRef = React.useRef('');

    // pagination props
    const [next, setNext] = useState(0);
    const [limit, setLimit] = useState(10);
    const [prev, setPrev] = useState(0);
    const [pager, setPager] = useState(1);

    // toast states
    const [toastBgColor, setToastBgColor] = useState('bg-success');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("Delete successful");

    // --------------- Methods -----------------
    const getDriverList = async ($next, showType) => {
        setIsRequesting(true);
        let tmpShowType = JSON.stringify(showType)
        await API.getDriverList({ next: $next, limit, showType: tmpShowType })
            .then((response) => response.json())
            .then((response) => {
                setIsRequesting(false);
                if (response.success) {
                    setDriverList(response.data.list);
                    setDriverCount(response.data.total);

                    if (response.data.next) {
                        setNext(response.data.next);
                    } else if (response.data.list.length) {
                        setNext($next > 0?next + response.data.list.length: response.data.list.length);
                        // setNext(next + response.data.list.length);
                    } else {
                        setNext(0);
                    }
                }
            })
    }

    const resetFormData = () => {
        setFirstName("");
        setLastName("");
        setPhoneNumber("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
    }

    const resetPagination = () => {
        setPrev(0);
        setPager(1);
    }

    const setFormData = (props) => {
        setFirstName(props.firstName);
        setLastName(props.lastName);
        setPhoneNumber(props.phone);
        setEmail(props.email);
        setPassword(props.password);
        setConfirmPassword(props.confirmPassword);
    }

    const resetFormError = () => {
        setFormError({
            firstName: false,
            lastName: false,
            email: false,
            password: false,
            phoneNumber: false,
            confirmPassword: false
        });
        setIsInvalidEmail(false);
        setIsPasswordNotMatch(false);
        setIsEmailAlreadyTaken(false);
        setIsPhoneAlreadyTaken(false);
    }

    const checkFormValidate = () => {
        let error = {};
        if (!firstName) {
            error.firstName = true;
        }

        if (!lastName) {
            error.lastName = true;
        }

        if (!phoneNumber) {
            error.phoneNumber = true;
        }

        if (!email) {
            error.email = true;
        }

        if (!password) {
            error.password = true;
        }

        if (!confirmPassword || (password && password !== confirmPassword)) {
            error.confirmPassword = true;
        }

        setFormError(error);

        return !error.firstName && !error.lastName && !error.phoneNumber && !error.email && !error.password && !error.confirmPassword ? true : false;
    }

    const checkEmailValidate = (email) => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email || regex.test(email) === false) {
            return false;
        }
        return true;
    }

    const updatePaginationProps = (type) => {
        let tmpPager = pager + 1;
        if (type === "PREV") {
            tmpPager = pager - 1;
        }

        const tmpNext = limit * tmpPager;
        const tmpPrev = (limit * tmpPager) - limit;

        setPager(tmpPager);
        setPrev(tmpPrev);

        return { pager: tmpPager, next: tmpNext, prev: tmpPrev };
    }

    const updateDriverStatus = (id, body) => {
      setIsRequestingUpsert(true);
      API.updateDriver(id, body)
          .then((response) => response.json())
          .then((response) => {
            setIsRequestingUpsert(false);

            if (response.success) {
              handleShowToast("SUCCESS");

              resetPagination();
              getDriverList(0, showType);
            }else {
              handleShowToast("ERROR")
            }
          })
    }
    // --------------- Compute data -----------
    useEffect(() => {
        getDriverList(next, showType);
    }, [])

    useEffect(() => {
        return () => {
            clearTimeout(typingTimer);
        }
    }, [])

    // --------------- Event change ----------
    const handleOpenModal = (e) => {
        setIsShowAddModal(true);
    }

    const handleCloseModal = () => {
        resetFormData();
        resetFormError();
        setIsShowAddModal(false);

        if (modalType === "Update") {
            setModalType("Add");
        }
    }

    const handleSubmit = () => {
        checkFormValidate();

        if (checkFormValidate()) {
            setIsEmailAlreadyTaken(false);
            setIsPhoneAlreadyTaken(false);
            setIsRequestingUpsert(true);

            if (modalType === "Add") {
                const body = {
                    email: [email],
                    first_name: firstName,
                    last_name: lastName,
                    password: password,
                    phone: phoneNumber
                }

                API.createDriver(body)
                    .then((response) => response.json())
                    .then((response) => {
                        setIsRequestingUpsert(false);
                        if (response.success) {
                            resetPagination();
                            getDriverList(0, showType);
                            handleCloseModal();
                            handleShowToast("SUCCESS");

                        }
                        else if(response.error){
                            if(response.message === "Email already taken!"){
                                setIsEmailAlreadyTaken(true);
                            }else if(response.message === "Phone number already taken!"){
                                setIsPhoneAlreadyTaken(true);
                            }
                            
                            handleShowToast("ERROR");
                        }
                    })
            } else {
                const body = {
                    email: [email],
                    first_name: firstName,
                    last_name: lastName,
                    password: password,
                    phone: phoneNumber,
                    status: updateDoc.active
                }

                const driverId = updateDoc.id
                API.updateDriver(driverId, body)
                    .then((response) => response.json())
                    .then((response) => {
                        setIsRequestingUpsert(false);
                        if (response.success) {
                            resetPagination();
                            getDriverList(0, showType);

                            handleCloseModal();
                            setUpdateDoc({});
                            handleShowToast("SUCCESS")
                        }else if(response.error){
                            if(response.message === "Email already taken!"){
                                setIsEmailAlreadyTaken(true);
                            }else if(response.message === "Phone number already taken!"){
                                setIsPhoneAlreadyTaken(true);
                            }

                            handleShowToast("ERROR");
                        }
                    })
            }
        }
    }

    const handleUpdate = (driver) => {
        setFormData({
            firstName: driver.first_name,
            lastName: driver.last_name,
            phone: driver.phone,
            email: driver.email,
            password: driver.password,
            confirmPassword: driver.confirmPassword || ""
        })
        setUpdateDoc(driver);
        setModalType("Update");
        handleOpenModal();
    }

    const handleRemove = () => {
        console.log("remove");
    }

    const handlePaginNext = () => {
        const { next, prev } = updatePaginationProps("NEXT");
        getDriverList(prev, showType);
    };

    const handlePaginPrev = () => {
        const { pager, next, prev } = updatePaginationProps("PREV");
        getDriverList(prev, showType);
    };


    const handleEmailChange = (e) => {
        var email = e.target.value
        setEmail(email);

        clearTimeout(typingTimer);
        typingTimer = setTimeout(function () {
            if (!checkEmailValidate(email)) {
                formError.email = true
                setIsInvalidEmail(true)
                setFormError(formError);
            } else {
                formError.email = false
                setIsInvalidEmail(false)
                setFormError(formError);
            }
        }, 1000)
    }

    const handleConfirmPasswordChange = (e) => {
        var confirmPassword = e.target.value
        setConfirmPassword(confirmPassword);

        clearTimeout(typingTimer);
        typingTimer = setTimeout(function () {
            if (confirmPassword !== password) {
                formError.confirmPassword = true
                setIsPasswordNotMatch(true)
                setFormError(formError);
            } else {
                formError.confirmPassword = false
                setIsPasswordNotMatch(false)
                setFormError(formError);
            }
        }, 1000)
    }

    const handleDisActiveDriver = (driver) => {
        setIsShowConfirmModal(true);
        setRemoveDoc(driver);
    }

    const handleActiveDriver = (driver) => {
      const driverId = driver.id;
      const body = {
        email: [driver.email],
        first_name: driver.first_name,
        last_name: driver.last_name,
        phone: driver.phone,
        password: driver.password,
        active: 1
      }

      updateDriverStatus(driverId, body);
    }

    const handleCloseConfirmModal = () => {
        setIsShowConfirmModal(false);
        setRemoveDoc({});
    }

    const handleSubmitDisActive = () => {
      const driverId = removeDoc.id;
      const body = {
        email: [removeDoc.email],
        first_name: removeDoc.first_name,
        last_name: removeDoc.last_name,
        phone: removeDoc.phone,
        password: removeDoc.password,
        active: 0
      }

      updateDriverStatus(driverId, body);
      handleCloseConfirmModal();
    }

    const handleSwithShowTypeChange = (e) => {
        setShowType(e.target.checked);

        let showType = e.target.checked;
        setNext(0);

        resetPagination();
        getDriverList(0, showType);
    }

    // toast event
    const handleShowToast = (type) => {
        setShowToast(true);

        if(type === "SUCCESS"){
            setToastBgColor("bg-success");
            setToastMessage("Operation successful.");
        }else if(type === "ERROR"){
            setToastBgColor("bg-danger");
            setToastMessage("Operation error.");
        }
    }

    // ------------------ Custom components ----------------
    // Pagination
    let pageItems = [
        <Pagination.Item disabled={next <= limit} key={0} onClick={handlePaginPrev}>Prev</Pagination.Item>,
        <Form.Group key={1} controlId="formGridPagination">
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>{`${next} / ${driverCount}`}</InputGroup.Text>
                </InputGroup.Prepend>
            </InputGroup>
        </Form.Group>,
        <Pagination.Item disabled={next === driverCount} key={2} onClick={handlePaginNext}>Next</Pagination.Item>,
    ];
    const PaginationBasic = () => (
        <div className="d-flex justify-content-center">
            <Pagination>{pageItems}</Pagination>
        </div>
    );

    return (
        <CustomModal>
            <HelpPageWrapper>
                <Row className="mb-3">
                    <Col sm={6} md={4}>
                        <Button variant="success" onClick={handleOpenModal}>
                            New
                        </Button>
                    </Col>
                    <Col sm={8} md={8}>
                        <Form.Row className="mt-2 d-flex justify-content-end">
                            <Col sm={2} className="d-flex justify-content-end">
                                <Form.Label style={labelStyle}>Show inactive driver:</Form.Label>
                            </Col>

                            <Col sm={2}>
                                <Switch
                                    checked={showType}
                                    onChange={handleSwithShowTypeChange}
                                    name="checkedB"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </Col>
                        </Form.Row>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        {
                            isRequesting ?
                                <div style={{ position: "fixed", left: "55%", top: "35%" }} > <Loader color="#389244" h="100" /> </div>
                                : <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>First name</th>
                                            <th>Last name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Status</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {
                                            !driverList.length ?
                                                <tr>
                                                    <td colSpan={6} className="text-center">No data</td>
                                                </tr>
                                                :
                                                driverList.map((driver, index) => (
                                                    <tr key={index}>
                                                        <td>{driver.first_name}</td>
                                                        <td>{driver.last_name}</td>
                                                        <td>{driver.email}</td>
                                                        <td>{driver.phone}</td>
                                                        <td>{driver.active === 1 ? "Active" : "Inactive"}</td>
                                                        <td className="text">
                                                            <Row>
                                                                <Col sm={6} className="text-right">
                                                                    {
                                                                        driver.active === 1 ?
                                                                            <Tooltip title="Click to inactive driver" placement="top" arrow>
                                                                              <Button variant="danger" onClick={(e) => handleDisActiveDriver(driver)}>
                                                                                <RemoveCircle></RemoveCircle>
                                                                              </Button>
                                                                            </Tooltip> :
                                                                            <Tooltip title="Click to active driver" placement="top" arrow>
                                                                              <Button variant="success" onClick={(e) => handleActiveDriver(driver)}>
                                                                                <Reply></Reply>
                                                                              </Button>
                                                                            </Tooltip>
                                                                    }
                                                                </Col>
                                                                <Col sm={6}>
                                                                  <Tooltip title="Click to edit driver" placement="top" arrow>
                                                                    <Button variant="info" onClick={(e) => handleUpdate(driver)}>
                                                                      <Edit></Edit>
                                                                    </Button>
                                                                  </Tooltip>
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                ))

                                        }
                                    </tbody>
                                </Table>
                        }

                        <Modal show={isShowAddModal}
                            animation={false}
                            onHide={handleCloseModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            size="xs"
                            centered
                        >
                            <Form noValidate validated={validated} autoComplete="false">
                                <Modal.Header closeButton>
                                    <Modal.Title>{modalType} driver</Modal.Title>
                                </Modal.Header>
                                <Modal.Body key="modalBody">
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label>First name</Form.Label>
                                        <InputGroup hasvalidation="true">
                                            <Form.Control type="text"
                                                value={firstName}
                                                isInvalid={formError.firstName}
                                                placeholder="First name"
                                                disabled={isRequestingUpsert}
                                                required
                                                ref={_firstNameInputRef}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group controlId="formLastName">
                                        <Form.Label>Last name</Form.Label>
                                        <InputGroup hasvalidation="true">
                                            <Form.Control type="text"
                                                value={lastName}
                                                placeholder="Last name"
                                                required
                                                disabled={isRequestingUpsert}
                                                ref={_lastNameInputRef}
                                                isInvalid={formError.lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group controlId="formPhone">
                                        <Form.Label>Phone number</Form.Label>
                                        <InputGroup hasvalidation="true">
                                            <Form.Control type="text"
                                                value={phoneNumber}
                                                placeholder="Phone number"
                                                required
                                                ref={_phoneInputRef}
                                                disabled={isRequestingUpsert}
                                                isInvalid={formError.phoneNumber || isPhoneAlreadyTaken}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                            />
                                        </InputGroup>
                                        {
                                            isPhoneAlreadyTaken && <Form.Label className="text-danger">Phone number already taken!</Form.Label>
                                        }
                                    </Form.Group>

                                    <Form.Group controlId="formEmail">
                                        <Form.Label>Email</Form.Label>
                                        <InputGroup hasvalidation="true">
                                            <Form.Control type="email"
                                                value={email}
                                                placeholder="Email address"
                                                isInvalid={formError.email || isInvalidEmail || isEmailAlreadyTaken}
                                                required
                                                disabled={isRequestingUpsert}
                                                ref={_emailInputRef}
                                                onChange={handleEmailChange}
                                            />
                                        </InputGroup>
                                        {
                                            isInvalidEmail && <Form.Label className="text-danger">Email is not valid!</Form.Label>
                                        }
                                        {
                                            isEmailAlreadyTaken && <Form.Label className="text-danger">Email already taken!</Form.Label>
                                        }
                                    </Form.Group>

                                    <ListGroup>
                                        <ListGroup.Item>
                                            <Form.Group controlId="formPassword">
                                                <Form.Label>Password</Form.Label>
                                                <InputGroup hasvalidation="true">
                                                    <Form.Control type="password"
                                                        autoComplete="off"
                                                        required
                                                        ref={_passwordInputRef}
                                                        value={password}
                                                        isInvalid={formError.password}
                                                        disabled={isRequestingUpsert}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        placeholder="Password"
                                                    />
                                                </InputGroup>
                                            </Form.Group>

                                            <Form.Group controlId="formConfirmPassword">
                                                <Form.Label>Confirm password</Form.Label>
                                                <InputGroup hasvalidation="true">
                                                    <Form.Control type="password"
                                                        autoComplete="off"
                                                        onChange={handleConfirmPasswordChange}
                                                        required
                                                        ref={_confirmPasswordInputRef}
                                                        value={confirmPassword}
                                                        isInvalid={formError.confirmPassword}
                                                        placeholder="Confirm password"
                                                        disabled={isRequestingUpsert}
                                                    />
                                                </InputGroup>
                                                {
                                                    isPasswordNotMatch && <Form.Label className="text-danger">Confirm password is not match with password!</Form.Label>
                                                }
                                            </Form.Group>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" disabled={isRequestingUpsert} onClick={handleSubmit}>
                                        {
                                            isRequestingUpsert?
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />:
                                                null
                                        }

                                        <span className="pl-2 pr-2">Save</span>
                                    </Button>
                                    <Button variant="secondary" disabled={isRequestingUpsert} onClick={handleCloseModal}>Close</Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>

                        <Modal show={isShowConfirmModal}
                            animation={false}
                            onHide={handleCloseConfirmModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            size="xs"
                            centered
                        >
                            <Modal.Header closeButton className="bg-danger">
                                <Modal.Title className="text-white">Warning</Modal.Title>
                            </Modal.Header>
                            <Modal.Body key="modalBody" className="text-center">
                                <Form.Label>Do you want to inactive this driver?</Form.Label>
                            </Modal.Body>
                            <Modal.Footer className="d-flex justify-content-center">
                                <Button variant="danger" disabled={isRequestingUpsert} onClick={handleSubmitDisActive}>
                                    {
                                        isRequestingUpsert?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />:
                                            null
                                    }

                                    <span className="pl-2 pr-2">Save</span>
                                </Button>

                                <Button variant="secondary" disabled={isRequestingUpsert} onClick={handleCloseConfirmModal}>Close</Button>
                            </Modal.Footer>
                        </Modal>

                        <Toast className={toastBgColor}
                               onClose={() => setShowToast(false)}
                               show={showToast}
                               delay={3000}
                               autohide
                               style={{
                                   position: 'fixed',
                                   top: 70,
                                   right: 35,
                                   zIndex: 1041,
                                   width: "300px"
                               }}
                        >
                            <Toast.Header>
                                <strong className="mr-auto m-3">{toastMessage}</strong>
                            </Toast.Header>
                        </Toast>

                    </Col>
                </Row>

                {
                    !isRequesting &&
                    (
                        <Row>
                            <Col sm={12}>
                                <PaginationBasic />
                            </Col>
                        </Row>
                    )
                }

            </HelpPageWrapper>
        </CustomModal>
    );
}

export default DriverList;
