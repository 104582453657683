import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Brand from "../../components/Brand/Brand";
import { 
    PromoWrapper, 
    BrandsTitle,
    ViewAll,
    Wrapper,
    PrevBrand,
    NextBrand,
} from "../Products/Products.style";
import { PromoSection } from "../../styled/pages.style";
import Carousel from "react-multi-carousel";
import { ArrowNext, ArrowPrev } from "../../components/AllSvgIcon";
import t from '../../i18n/translate';

const BrandsHome = ({
    deviceType,
    props
  }) => {
    const history = useHistory();
    const PrevButton = ({ onClick, children }) => {
        return (
          <PrevBrand
            onClick={(e) => {
              e.preventDefault();
              onClick();
            }}
            className="prevButton"
          >
            {children}
          </PrevBrand>
        );
      };
      const NextButton = ({ onClick, children }) => {
        return (
          <NextBrand
            onClick={(e) => {
              e.preventDefault();
              onClick();
            }}
            className="nextButton"
          >
            {children}
          </NextBrand>
        );
      };
      
      const ButtonGroup = ({ next, previous }) => {
        return (
          <Wrapper>
            <>
              <PrevButton onClick={() => previous()}>
                <ArrowPrev />
              </PrevButton>
              <NextButton onClick={() => next()}>
                <ArrowNext />
              </NextButton>
            </>
      
            {/* if prop isRtl true swap prev and next btn */}
          </Wrapper>
        );
      };
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
        },
    };
    return (<>
        <PromoSection style={{padding: "20px 0px", backgroundColor: "white"}}>
        <PromoWrapper>
            <BrandsTitle>
              { t('home_brands_label') }
            </BrandsTitle>
            <ViewAll onClick={() => {
                history.push('/brands');
            }}>
              <a target="_blank" rel="noopener noreferrer" href="/brands" style={{textDecoration: "none", color: "inherit"}}>
                { t('home_view_all_label') }
              </a>
            </ViewAll>
        </PromoWrapper>
        <Carousel
        arrows={false}
        responsive={responsive}
        ssr={true}
        showDots={false}
        slidesToSlide={1}
        infinite={true}
        containerClass="container-for-promo"
        deviceType={deviceType}
        autoPlay={true}
        autoPlaySpeed={4000}
        renderButtonGroupOutside={true}
        additionalTransfrom={0}
        customButtonGroup={<ButtonGroup />}
        {...props}
        >
          {props.brands && props.brands.length > 0 ?
            props.brands.map((item, index) => {
                return (
                    <Brand 
                        key={index}
                        item={item} 
                        handleSelection={()=>{
                            history.push(
                            `/brand/${item.brand_ref_id}`
                            )
                        }}
                        homePage={true}
                    />
                )
            }) : 
            [0,1].map((elem,index) => {
              return <Brand 
                        key={elem}
                        item={{name: "Loading..."}} 
                        handleSelection={()=>{}}
                        homePage={true}
                     />
             })
          }
        </Carousel>
        </PromoSection>
    </>);
  };
  
  export default BrandsHome;
  