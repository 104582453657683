import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Modal } from "@redq/reuse-modal";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../contexts/auth/auth.context";
import * as Constant from "../../constants/Constant";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import {addProduct,getBrands,getCategory,getSubCategory,getSuppliers,getSubCategoryMap,setError,setLoading} from '../../store/actions/dashboardAction'
import { connect } from "react-redux";
import UploadService from "../../services/FileUploadService";

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const AddProduct = (props) => {

    const { authDispatch } = useContext(AuthContext);
    let admin = localStorage.getItem("admin");
    let parsedUser = JSON.parse(admin);
    if(!admin) {
      window.location.href = "/";
    }
    // All fields
    const [itemName, setItemName] = useState("");
    const [itemCode, setItemCode] = useState("");
    const [itemCat, setItemCat] = useState(0);
    const [itemSubCat, setItemSubCat] = useState(0);
    const [itemBrand, setItemBrand] = useState(0);
    const [itemSameDay, setItemSameDay] = useState(0);
    const [itemAbout, setItemAbout] = useState("");
    const [itemSupplierId, setItemSupplierId] = useState(0);
    const [itemPromo,setItemPromo] = useState(0);
    const [itemPromoPrice, setItemPromoPrice] = useState(0);
    const [itemPromoStartDate, setItemPromoStartDate] = useState(null);
    const [itemPromoEndDate, setItemPromoEndDate] = useState(null);
    const [itemNewArrival, setItemNewArrival] = useState(0);
    const [itemArrivalLimit, setItemArrivalLimit] = useState(null);
    const [itemTrending, setItemTrending] = useState(0);
    const [itemPrice, setItemPrice] = useState("");
    const [itemMrp, setItemMrp] = useState("");
    const [itemStoreId, setItemStoreId] = useState(1);
    const [itemPriceName, setItemPriceName] = useState("");
    const [itemAvailable, setItemAvailable] = useState(0);
    const [itemQuantity, setItemQuantity] = useState("");
    const [itemPriceId, setItemPriceId] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError,setIsError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [image , setImage] = useState(null);
    const [file , setFile] = useState(null);
    const [isUploded, setIsUploded] = useState(false);
    const [itemNameKhmer, setItemNameKhmer] = useState("");
    // End All fields

    useEffect(() => {

        props.getCategory({
            store_id: 1,
            get_type: 1
        })
        props.getBrands({});
        props.getSuppliers({})

    },[]);
    let contentSupplier = null;
    let contentBrands = null;
    let contentCategory = null;
    let contentSubCategory = null;
    if(props.brands) {
        contentBrands =  props.brands.map((brand,key) => 
        <option key={key} value={brand.brand_id}>{brand.name}</option>
        )
    }
    if(props.category) {
        contentCategory =  props.category.map((cat,key) => 
        <option key={key} value={cat.cat_id}>{cat.list_categories[0].name}</option>
        )
    }
    if(props.sub_category) {
        contentSubCategory =  props.sub_category.map((subCat,key) => 
        <option key={key} value={subCat.sub_cat_id} >{subCat.list_sub_categories[0].name}</option>
        )
    }
    if(props.suppliers) {
        contentSupplier =  props.suppliers.map((supplier,key) => 
        <option key={key} value={supplier.store_user_id}>{supplier.store_users[0].first_name}</option>
        )
    }

    // change handlers
    const handleFileChange = e => {
        console.log(e.target.files[0])
        setFile(e.target.files[0])
    }

    const changeName = ((event) => {
         setItemName(event.target.value)
    });
    const changeNameKhmer = ((event) => {
        setItemNameKhmer(event.target.value)
    });
    const changeCode = ((event) => {
        setItemCode(event.target.value)
    });
    const changeAbout = ((event) => {
        setItemAbout(event.target.value)
    });
    const cahngeCategory = ((event) => {
        setItemCat(event.target.value);
        props.getSubCategoryMap({cat_id: event.target.value})
    });
    const changeSubCategory = ((event) => {
        setItemSubCat(event.target.value)
    });
    const cahngeBrand = ((event) => {
        setItemBrand(event.target.value)
    });
    const changePrice = ((event) => {
        setItemPrice(event.target.value)
    });
    const changeMrp = ((event) => {
        setItemMrp(event.target.value)
    });
    const changeItemQuantity = ((event) => {
        setItemQuantity(event.target.value)
    });
    const changePriceName = ((event) => {
        setItemPriceName(event.target.value)
    });
    const changeSameDay = ((event) =>{
        setItemSameDay(event.target.checked)
    });
    const changeAvailable = ((event) => {
        if(!event.target.checked){
            setItemAvailable(0)
        } else {
            setItemAvailable(1)
        }
    });
    const changeNewArrival = ((event) => {
        if(!event.target.checked) {
            setItemNewArrival(0)
            setItemArrivalLimit(null)
        } else {
            setItemNewArrival(1)
            setItemArrivalLimit("")
        }
    });
    const changeArrivalLimit = ((event) => {
        setItemArrivalLimit(moment(event).format('YYYY-MM-DD hh:mm:ss'))
    });
    const changeTrending = ((event) => {
        if(!event.target.checked){
            setItemTrending(0)
        } else {
            setItemTrending(1)
        }
    });
    const changePromo = ((event) => {
        if(!event.target.checked) {
            setItemPromo(0)
            setItemPromoPrice(null)
            setItemPromoStartDate(null)
            setItemPromoEndDate(null)
        } else {
            setItemPromo(1)
            setItemPromoPrice("")
            setItemPromoStartDate("")
            setItemPromoEndDate("")
        }
    });
    const changePromoPrice = ((event) => {
        setItemPromoPrice(event.target.value)
    });
    const changePromoStartDate = ((event) => {
        setItemPromoStartDate(moment(event).format('YYYY-MM-DD hh:mm:ss'))
    });
    const changePromoEndDate = ((event) => {
        setItemPromoEndDate(moment(event).format('YYYY-MM-DD hh:mm:ss'))
    });
    const changeSupplier = ((event) => {
        setItemSupplierId(event.target.value);
    });
    const updateProductDate = () => {
       // setIsSubmitted(true);
        let postData = {
            image : image,
            name: itemName,
            cat_id: itemCat,
            sub_cat_id: itemSubCat,
            brand_id: itemBrand,
            about_item: itemAbout,
            store_id: itemStoreId,
            prices:[{
                price_id: itemPriceId,
                store_id: 1,
                name: itemPriceName,
                price: itemPrice,
                mrp: itemMrp,
                is_available: itemAvailable,
                available_quantity: parseInt(itemQuantity, 10 ),
                weight_unit: 0,
            }],
            max_quantity_to_be_added: 1000000,
            product_code: itemCode,
            same_day: itemSameDay,
            supplier_id: itemSupplierId,
            promo: itemPromo,
            promo_price: itemPromoPrice,
            promo_start_date: itemPromoStartDate,
            promo_end_date: itemPromoEndDate,
            new_arrival: itemNewArrival,
            arrival_limit: itemArrivalLimit,
            trending: itemTrending,
            name_km: itemNameKhmer
        };

        props.addProduct(postData)
    }

    const uploadProductImage = () =>{
        if(file){
            setIsUploded(true)
            let formData = new FormData();
            formData.append("image", file);
            UploadService.uploadImageFromDashboard(formData)
            .then(response => {
            if(response.data.success) {
                setIsUploded(false)
                console.log(response.data.data[0].url)
               setImage(response.data.data[0].url)
            }
          });
        }
      }

   
  return (
    <Modal>
        <HelpPageWrapper>
            <Row>
                <Col sm={11}>
                    <h4>Product Add</h4>
                </Col>
            </Row>
            <Row>
                <Col sm={11}>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" value={itemName} onChange={changeName} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>SKU</Form.Label>
                            <Form.Control type="text" placeholder="SKU/Product code" value={itemCode} onChange={changeCode} />
                            </Form.Group>
                        </Form.Row>

                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridNameInKhmer">
                              <Form.Label>Name in Khmer</Form.Label>
                              <Form.Control type="text" placeholder="Name in Khmer" value={itemNameKhmer} onChange={changeNameKhmer} />
                            </Form.Group>
                          </Form.Row>

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>About Item</Form.Label>
                            <Form.Control as="textarea" rows={3} value={itemAbout} onChange={changeAbout} />
                        </Form.Group>

                        <Form.Label>Image</Form.Label>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridFile">
                            <Form.Control type="file"  onChange={handleFileChange} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                            {isUploded ? (
                            <Spinner animation="grow" variant="success" />
                              ) :
                            <Button variant="primary" onClick={uploadProductImage}>
                                Uplode image
                            </Button>
                            }   
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Category</Form.Label>
                                <Form.Control as="select" onChange={cahngeCategory} value={itemCat}>
                                    <option value={0}>Choose Category</option>
                                    {contentCategory}
                                </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Sub Category</Form.Label>
                                <Form.Control as="select" onChange={changeSubCategory} value={itemSubCat}>
                                    <option value={0}>Choose Sub Category</option>
                                    {contentSubCategory}
                                </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Brand</Form.Label>
                                <Form.Control as="select" onChange={cahngeBrand} value={itemBrand}>
                                    <option value={0}>Choose Brand</option>
                                    {contentBrands}
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>


                        <hr />

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Varient name</Form.Label>
                            <Form.Control type="text" placeholder="Varient name" value={itemPriceName} onChange={changePriceName} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Available Quantity</Form.Label>
                            <Form.Control type="number" placeholder="0" value={itemQuantity} onChange={changeItemQuantity} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Supplier</Form.Label>
                            <Form.Control as="select" onChange={changeSupplier} value={itemSupplierId}>
                                    <option value={0}>Select Supplier</option>
                                    {contentSupplier}
                            </Form.Control>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Price (Selling price - USD)</Form.Label>
                            <Form.Control type="number" placeholder="00.00" step="0.01" value={itemPrice} onChange={changePrice} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>COST (USD)</Form.Label>
                            <Form.Control type="number" placeholder="00.00" step="0.01" value={itemMrp} onChange={changeMrp} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Add to New Arrival" checked={itemNewArrival} onChange={changeNewArrival} />
                        </Form.Group>
                        {itemNewArrival ? (
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Till</Form.Label>
                                <Datetime value={itemArrivalLimit} dateFormat="YYYY-MM-DD" timeFormat="hh:mm:ss" onChange={changeArrivalLimit}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail"></Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail"></Form.Group>
                            </Form.Row>
                        ): ("")}

                        <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Add to promotion" checked={itemPromo} onChange={changePromo} />
                        </Form.Group>
                        {itemPromo ? (
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Promo selling price </Form.Label>
                                <Form.Control type="text" placeholder="00.00 USD" value={itemPromoPrice} onChange={changePromoPrice} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>From</Form.Label>
                                <Datetime value={itemPromoStartDate} dateFormat="YYYY-MM-DD" timeFormat="hh:mm:ss" onChange={changePromoStartDate}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>To</Form.Label>
                                <Datetime value={itemPromoEndDate} dateFormat="YYYY-MM-DD" timeFormat="hh:mm:ss" onChange={changePromoEndDate}/>
                                </Form.Group>
                            </Form.Row>
                            
                        ): ("")}

                        <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Same day" checked={itemSameDay} onChange={changeSameDay} />
                        </Form.Group>

                        <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Available" checked={itemAvailable} onChange={changeAvailable} />
                        </Form.Group>

                        <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Add to Trending" checked={itemTrending} onChange={changeTrending} />
                        </Form.Group>

                        { !props.success ? (
                            <Alert variant="danger">
                                {props.error_message}
                            </Alert>
                        ) : ("")}
                            {(props.loading  || isUploded) ? (
                            <Spinner animation="grow" variant="success" />
                        ) : (
                            <Button variant="primary" onClick={updateProductDate}>
                                Add
                            </Button>
                        )}
                        
                    </Form>
                </Col>
            </Row>
        </HelpPageWrapper>
    </Modal>
  );
}

const mapStateToProps = state =>{
    return {
       brands : state.dashboardReducer.brands,
       category : state.dashboardReducer.category,
       sub_category : state.dashboardReducer.sub_category,
       suppliers : state.dashboardReducer.suppliers,
       success : state.dashboardReducer.success,
       loading : state.dashboardReducer.loading,
       error_message : state.dashboardReducer.error_message
    }
   
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      addProduct : data => addProduct(data,dispatch),
     // searchProduct : data => searchProduct(data,dispatch),
      getBrands : data => getBrands(data,dispatch),
      getCategory : data => getCategory(data,dispatch),
      getSubCategory : data => getSubCategory(data,dispatch),
      getSuppliers : data => getSuppliers(data,dispatch),
      getSubCategoryMap : data => getSubCategoryMap(data,dispatch),
      setError : data => setError(data,dispatch),
       setLoading : data => setLoading(data,dispatch)
    }
  }
  export default connect(mapStateToProps,mapDispatchToProps)(AddProduct)
