import React from 'react';
import styled from "styled-components";

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const AboutEnglish = () => {
  return (
    <React.Fragment>
      <Heading>About us</Heading>

      <p><b>Welcome to Delishop.asia, your first online supermarket in Phnom-Penh!</b></p><br />

      <p>You can now sit at your desk and shop for a wide range of quality products and grocery. We’ll deliver them straight to your door as soon as next-day, from Monday to Saturday, from 10 AM to 8 PM!</p><br />

      <p>We are collaborating with more than 80 different suppliers, with more than 14000 products in a wide range of categories.</p><br />

      <p>We are offering a unique selection of deli items to make your everyday life easier.</p><br />

      <p>We do not stock anything, when you place an order, our team will go to the different suppliers to collect your products.</p><br />

      <p>Simply choose a one hour window that is convenient for you and pay online or upon delivery.</p><br />

      <p>We appreciate your support in telling us which products you feel are missing or what should be improved.</p><br /><br />

      <p>To give us your feedback, please send us an email at contact@delishop.asia or call us at 081 999 405.</p><br /><br />

      <p>Enjoy your shopping !</p><br />
      <p>The Delishop Team</p><br />

    </React.Fragment>
  )
}

export default AboutEnglish;