import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import {WelcomePopupDialog, customModal} from "./WelcomePopup.style";
import Image from 'react-bootstrap/Image';
import delishopExpressIcon from '../../assets/icons/delixpress_logo.svg';
import delishopIcon from '../../assets/icons/delishop_logo.svg';
import { CloseIcon } from '../AllSvgIcon';
import t from '../../i18n/translate';
import welcomePicture from '../../assets/images/welcomepopup-homepage-02.jpg';
import Spinner from "react-bootstrap/Spinner";

export const WelcomePopup = (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => { setShow(props.openDialog) }, [props.openDialog]);

  return (
    <Modal show={show}
           onHide={() => setShow(false)}
           centered
           size="xl"
           dialogClassName="modal-dialog-welcome"
    >
      <Modal.Body style={customModal}>
        <WelcomePopupDialog>
          <div className="icon-close-modal" onClick={() => { setShow(false) }}><CloseIcon /></div>

          <div className="box-wrapper">
            <img src={welcomePicture} alt="delishop online" className="full-picture" />
            <div className="fake-link-wrapper">
              <div className="custom-link" onClick={(e) => { setShow(false); }}></div>
              <div className="custom-link" onClick={(e) => { window.location.href = '/express' }}></div>
            </div>
            <div className="spinner-wrapper">
              <Spinner animation="border" variant="success" />
            </div>
            {/*<div className="col-left custom-link" onClick={(e) => { setShow(false); }}>
              <div className="header">
                <p className="sub-title">{ t('home_welcome_left_header_sub_title_popup') }</p>
                <div className="divider">=</div>
                <p className="title">{ t('home_welcome_left_header_title_popup') }</p>
              </div>
              <div className="media">
                <Image src={delishopIcon} alt="Delishop Logo" />
              </div>
              <ul className="content">
                <li>- { t('home_welcome_left_content_list_shopping_text') }</li>
                <li>- { t('home_welcome_left_content_list_product_text') }</li>
                <li>- { t('home_welcome_left_content_list_total_supplier_text') }</li>
                <li>- { t('home_welcome_left_content_list_delivery_hours_text') }</li>
                <li>- { t('home_welcome_left_content_list_option_missing_item_text') }</li>
                <li>- { t('home_welcome_left_content_list_same_day_order_text') }</li>
              </ul>
            </div>
            <div className="col-right custom-link" onClick={(e) => { window.location.href = '/express' }}>
              <div className="header">
                <p className="sub-title">{ t('home_welcome_right_header_sub_title_popup') }</p>
                <div className="divider">=</div>
                <p className="title">{ t('home_welcome_right_header_title_popup') }</p>
              </div>
              <div className="media">
                <Image src={delishopExpressIcon} alt="Delishop Express Logo" />
              </div>
              <ul className="content">
                <li>- { t('home_welcome_right_content_list_shopping_text') }</li>
                <li>- { t('home_welcome_right_content_list_suppliers_text') }</li>
                <li>- { t('home_welcome_right_content_list_delivery_text') }</li>
                <li>- { t('home_welcome_right_content_list_time_order_text') }</li>
              </ul>
            </div>*/}
          </div>
        </WelcomePopupDialog>
      </Modal.Body>
    </Modal>
  );
};

WelcomePopup.defaultProps = {
  openDialog: false,
  paymentMethod: null,
  placeOrderStatus: null,
  deviceType: 'desktop',
};

export const MemoizedWelcomePopup = React.memo(WelcomePopup);
