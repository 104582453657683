import * as Constant from "../constants/Constant";

// const baseURL = 'http://test-store-api.shoocal.com/';//Staging
// export const baseUrl = "http://api.delishop.asia/";
var api = {
  async request(url, method, body) {
    var Body = body === null || body === undefined ? {} : body ;
   // Body["from"] = "website";
    let token = localStorage.getItem("token");
    return await fetch(Constant.baseUrl + url, {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token ? token : null,
      },
      body: JSON.stringify(Body),
      // body: body === null ? null : JSON.stringify(body),
    });
  },

  async putRequest(url, method, body) {
    var Body = body === null || body === undefined ? {} : body ;
    let token = localStorage.getItem("token");

    return await fetch(Constant.baseUrl + url, {
      method: method,
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token ? token : null
      },
      body: JSON.stringify(Body),
      // body: body === null ? null : JSON.stringify(body),
    });
  },

  async getRequest(url, method) {
    let token = localStorage.getItem("token");
    return await fetch(Constant.baseUrl + url, {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token ? token : null,
      },
    });
  },
};
export default api;
