import React, { useContext, useState, useEffect, useRef } from "react";
import localForage from 'localforage';
import { useSelector } from "react-redux";
import ReactDOM from "react-dom";
import Iframe from "react-iframe";
import * as constant from "../../constants/Constant";
import axios from "axios";
import {
  AxiosProvider,
  Request,
  Get,
  Delete,
  Head,
  Post,
  Put,
  Patch,
  withAxios,
} from "react-axios";
import { Link, Router } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import Button from "../../components/Button/Button";
import RadioCard from "../../components/RadioCard/RadioCard";
import OOSRadioCard from "../../components/OOSRadioCard/OOSRadioCard";
import RadioGroup from "../../components/RadioGroup/RadioGroup";
import PaymentGroup from "../../components/PaymentGroup/PaymentGroup";
import Loader from "../../components/Loader/Loader";
import UpdateAddress from "./Update/UpdateAddress";
import UpdateContact from "./Update/UpdateContact";
import ButtonGroupRn from "react-bootstrap/ButtonGroup";
import ButtonRn from "react-bootstrap/Button";
import ToggleButton from "react-bootstrap/ToggleButton";
import * as actionTypes from "../../store/ActionTypes";
import DropDown from "../../components/DropDown";
// import StripePaymentForm from "../Payment/StripePaymentForm";
import { Table} from "react-bootstrap";
import { CURRENCY, allslots } from "../../helper/constant";
import { openModal } from "@redq/reuse-modal";
import { Scrollbars } from "react-custom-scrollbars";
import CheckoutWrapper, {
  CheckoutContainer,
  CheckoutInformation,
  InformationBox,
  DeliverySchedule,
  Heading,
  SubHeading,
  ButtonGroup,
  CheckoutSubmit,
  HaveCoupon,
  CouponBoxWrapper,
  CouponInputBox,
  Input,
  CouponCode,
  RemoveCoupon,
  ErrorMsg,
  TermConditionText,
  TermConditionLink,
  CartWrapper,
  CalculationWrapper,
  OrderInfo,
  Title,
  ItemsWrapper,
  Items,
  Quantity,
  Multiplier,
  ItemInfo,
  Price,
  TextWrapper,
  Text,
  Bold,
  Small,
  NoProductMsg,
  CaseOnDeliveryBtn,
  PaymentMethodWrapper,
  InfoText,
  IsSameDay,
  EditItem,
  PayWithWallet,
} from "./CheckoutWithSidebar.style";

import { Plus } from "../../components/AllSvgIcon";

import Sticky from "react-stickynode";
import { HeaderContext } from "../../contexts/header/header.context";

import { ProfileContext } from "../../contexts/profile/profile.context";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useCart } from "../../contexts/cart/use-cart";
import { convertDate, convertTime24to12, compDate, isTodayApplicable } from "../../constants/Constant";
// The type of props Checkout Form receives
import Animation from "react-lottie";
import anim from "../../assets/icons/success-tick.json";
import { Modal, Card, ListGroup } from "react-bootstrap";
import TextField from "../../components/TextField/TextField";
import { reducer } from "../../contexts/cart/cart.reducer";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { PencilIcon } from "../../components/AllSvgIcon";
import { Errormsg } from "../Cart/CartItem/CartItem.style";
import API from "../../network/dashboardAPI"
import AvailableDeliverySlotModal from "../../components/AvailableDeliverySlot/AvailableDeliverySlotModal";
import expressService from "../../services/expressService";
import Form from 'react-bootstrap/Form';
import checkoutService from "../../services/checkoutService";
import checkout from "../../pages/checkout";
import SimpleMessageDialog from "../../components/SimpleMessageDialog/SimpleMessageDialog";

import abaPayIcon from '../../assets/icons/abapay.png';
import bakongIcon from '../../assets/icons/aba-bakong.png';
import abaCardsIcon from '../../assets/icons/abacards.svg';
import creditDebitCard from '../../assets/icons/credit-debit-card.png';
import CheckoutForm from '../../components/PayWay/CheckoutForm';
import t from '../../i18n/translate';
import reactStringReplace from 'react-string-replace';
import itemToCartService from "../../services/itemToCartService";

const OrderItem = ({ product, props, address_id, modify_total, cartType }) => {
  const { id, quantity, title, name, unit, price, salePrice } = product;
  const {
    updateItem,
    carttotal,
    items,
    clearCart,
    fetchOrderItems,
  } = useCart();
  const [show, setShow] = useState(false);
  const [newquantity, setNewQuantity] = useState(product.quantity);
  const [updating, setUpdating] = useState(false);
  const [msg, setMsg] = useState(false);

  // const displayPrice = product.promo === 1 && product.promo_start_date !== null && product.promo_end_date !== null && constant.compDate(product.promo_start_date, product.promo_end_date) ? product.promo_price : product.store_items_price_types[0].price;
  const displayPrice = product.item_price;
  const handleQuantityUpdate = (quant) => {
    setNewQuantity(quant);
    // setCouponCode(couponCode);
  };
  useEffect(() => {
    setUpdating(true);
    // props.fetchCart({ order_id: localStorage.getItem("order_id") });
  },[product.quantity]);

  useEffect(() => {
    setUpdating(false);
    let user = localStorage.getItem("user");
    let parsedUser = JSON.parse(user);
    let cust_id = parsedUser.cust_id;
    modify_total();
    if(localStorage.getItem("order_id") !== undefined && (address_id !== undefined || true)) {
      props.moveToCheckout({
        cust_id: cust_id,
        store_id: 1,
        order_id: localStorage.getItem("order_id"),
        address_id: selectDefaultAddress(),
        type: cartType === "express" ? 1 : 0,
      });
    }
    else{
      alert( t('shop_checkout_order_not_exist', false) );
    }
  }, [carttotal]);

  const selectDefaultAddress = () => {
    let defaultAddressID = address_id;
    if (props.user_address.length) {
      defaultAddressID = props.user_address.find(item => item.is_default);
      if(defaultAddressID) {
        return defaultAddressID.address_id;
      }
      else {
        return address_id;
      }
    }
    else {
      return address_id;
    }
  }

  const quantityFormDialog = async (product, newquantity) => {
    if(parseInt(newquantity) >= 1) {
      let body = {
        store_id: 1,
        item_id: product["item_id"],
        type: cartType === 'express' ? 1 : 0,
        quantity: newquantity,
      };
      await handleSubmit(body);
      await fetchOrderItems(cartType);
    }
    // If quantity is 1
    else {
      // setMsg(true);
      // setShow(false);
    }
  }

  const handleSubmit = async (body) => {
    const dataResp = await itemToCartService.updateQuantity(body);
    if(dataResp.status !== 200) {
      const pattern = ['multiSupplier', 'paymentInProgress'];
      if(pattern.includes(dataResp.data.error)) {
        setMsg(true);
        setShow(false);
      }
    }
    else {
      setShow(false);
      setUpdating(true);
    }
  }

  return (<>
    {/* {product.store_item_infos[0].same_day === 1 ? <IsSameDay>
      <span className="ribbon3">Available Today</span>
    </IsSameDay> : null} */}
    <Items key={product.item_id}>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setNewQuantity(quantity);
          // props.resetmessages();
        }}
        dialogClassName="modal-90w authModal"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
      >
        <Modal.Header closeButton>
        <Modal.Title>{ t('home_basket_update_quantity_popup_title') }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input 
           onUpdate={handleQuantityUpdate}
           value={newquantity}/>
           {msg && <Errormsg>{ t('home_basket_error_value_greater_then_zero') }</Errormsg>}
        </Modal.Body>
        <Modal.Footer>
        <Button
          title={ t('shop_item_detail_confirm_button', false) }
          variant="primary"
          onClick={() => {
            quantityFormDialog(product, newquantity)
          }}
        />
        </Modal.Footer>
      </Modal>
      <Modal
          show={updating}
          onHide={() => {
            setUpdating(false);
          }}
          // dialogClassName="modal-lg"
          aria-labelledby="example-custom-modal-styling-title"
          size="sm"
          animation={true}
          centered
          backdrop="static"
        >
          {/* <Animation options={defaultOptions} height={180} width={180} /> */}
          <div
            style={{
              fontSize: 20,
              textAlign: "center",
              margin: 10,
              marginTop: 18,
              color: "#222",
            }}
          >
            { t('shop_checkout_update_cart') }
          </div>
        </Modal>
      <Quantity>{product.quantity}</Quantity>
      <Multiplier>x</Multiplier>
      <ItemInfo>
        {product.item_name ? product.item_name : title}{" "}
        ({product.item_price_name})
      </ItemInfo>
      <Price>
        {CURRENCY}
        {(displayPrice * quantity).toFixed(2)}
      </Price>
      <EditItem className={cartType} onClick={(e)=>{e.preventDefault(); e.stopPropagation(); setNewQuantity(quantity); setShow(true);}}>
        <PencilIcon />
      </EditItem>
    </Items>
    </>
  );
};

const CheckoutWithSidebar = ({ token, deviceType, props }) => {
  const [hasCoupon, setHasCoupon] = useState(false);
  const [couponId, setCouponId] = useState(0);
  const [searchCoupon, setSearchCoupon] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cod");
  const [tid, setTid] = useState("");
  const [couponError, setError] = useState("");
  const [twodslots, settwodslots] = useState([]);
  const { state, dispatch } = useContext(ProfileContext);
  const {
    items,
    carttotal,
    removeCoupon,
    // coupon,
    applyCoupon,
    clearCart,
    cartItemsCount,
    calculatePrice,
    fetchOrderItems,
    calculateDiscount,
    calculateSubTotalPrice,
    isRestaurant,
    toggleRestaurant,
    addItem,
    removeItem,
    updateItem
  } = useCart();
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { address, contact, card, schedules } = state;
  const { headerState } = useContext(HeaderContext);
  const [isChecked, setChecked] = useState(0);
  const [oosoption, setOOSOption] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [isSlotChecked, setSlotChecked] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedSlot, setSelectedSlot] = useState(0);
  const [grossTotal, setGrossTotal] = useState(0.00);
  const [modalshow, setModalShow] = useState(false);
  const [comment, setComment] = useState("");
  const [show, setShow] = useState(false);
  const [selectedCode, setSelectedCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [allSLOTS, setAllSlots] = useState([]); 
  const [showSchedule, setShowSchedule] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selfdelivery, setSelfDelivery] = useState(0);
  const [showSlot, setShowSlot] = useState(true);
  const [showError, setShowError] = useState(false);
  const [dialogHeaderLabel, setDialogHeaderLabel] = useState('');
  const [errorMessage, setErrorMessage] = useState(t('shop_checkout_out_of_rang', false));
  const [isError, setIsError] = useState(null);
  const [addressIsInRange, setAddressIsInRange] = useState(null);
  const [hideCashOnDelivery, setHideCashOnDelivery] = useState(false);
  // const [src, setSrc] = useState('../pg.html');

  // Payemnt modal
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [checkedABAPay, setCheckedABAPay] = useState(false);
  const [checkedBakong, setCheckedBakong] = useState(false);
  const [checkedCard, setCheckedCard] = useState(false);
  const [checkedDelivery, setCheckedDelivery] = useState(true);
  const [walletChecked, setWalletChecked] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [contacted, setContacted] = useState(false);
  const handlePaymentClose = () => setShowPaymentModal(false);
  const handlePayemntShow = () => setShowPaymentModal(true);
  const [checkout_banner, setCheckoutBanner] = useState()
  const [checkoutLink, setCheckoutLink] = useState()
  const [cartType, setCartType] = useState('normal');
  const isExpress = localStorage.getItem('expressMode') ? 'express' : 'normal';
  const [deliveryColor, setDeliveryColor] = useState('green');
  const [checkPaymentIntervalId, setCheckPaymentIntervalId] = useState(null);

  //Wallet amount remain
  const [totalWalletAmount, setTotalWalletAmount] = useState(0);
  const [isDisplayWallet, setIsDisplayWallet] = useState(false);
  useEffect( () => {
    if(props.wallet !== null && typeof props.wallet.amount !== 'undefined' && props.wallet.amount !== 0) {
      setTotalWalletAmount(props.wallet.amount);
      setIsDisplayWallet(true);
    }
    else { setTotalWalletAmount(0) }

  }, [props.wallet])
  const [enableCheckOutButton, setEnableCheckOutButton] = useState(false);
  const placeOrderStatus = useSelector(state => state.cartReducer.orderPlaced);

  useEffect(()=>{
    API.getBannerImages().then(res => res.json())
    .then(res=>{
      setCheckoutBanner(res.data[0].checkout);
      setCheckoutLink(res.data[0].checkoutLink);
    })
  },[])

  const paymentOptionChoose = async (value) => {
    if(value !== 'wallet') {
      setPaymentMethod(value);
    }

    if (value === "card") {
      setCheckedCard(true);
      setCheckedABAPay(false);
      setCheckedDelivery(false);
      setCheckedBakong(false);
    } else if (value === "abapay") {
      setCheckedABAPay(true);
      setCheckedCard(false);
      setCheckedDelivery(false);
      setCheckedBakong(false);
    } else if (value === "bakong") {
      setCheckedCard(false);
      setCheckedABAPay(false);
      setCheckedDelivery(false);
      setCheckedBakong(true);
    } else if (value === "cod") {
      setCheckedCard(false);
      setCheckedABAPay(false);
      setCheckedDelivery(true);
      setCheckedBakong(false);
    }
    else if(value === 'wallet') {
      if(typeof paymentMethod === 'undefined' && checkedDelivery) {
        setPaymentMethod('cod');
      }

      setWalletChecked(prev => {
        const updatePrev = !prev;
        const currentTotalToPay = parseFloat(grossTotal).toFixed(2);

        if(updatePrev) {
          if(currentTotalToPay >= totalWalletAmount) {
            setTotalWalletAmount(0);
          }
          else {
            setTotalWalletAmount( props.wallet.amount - currentTotalToPay);
          }
        }
        else {
          setTotalWalletAmount(props.wallet.amount);
        }

        return updatePrev
      });
    }
    trackCheckoutStep5(value);
  };

  useEffect(()=>{
    if(selectedSlot && props.user_address.length && paymentMethod !== "") {
      setEnableCheckOutButton(true);
    } else {
      setEnableCheckOutButton(false);
    }
  },[selectedSlot, paymentMethod, props.user_address])


  const htmlContent =
    "<html><head>" +
    '<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">' +
    '<script src="https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n" crossorigin="anonymous"></script>' +
    '<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>' +
    '<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>' +
    '</head><body width="100%" style="margin-top: 100px;">' +
    '<div class="d-flex justify-content-center">' +
    '<button class="btn btn-success" type="button" disabled>' +
    '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>' +
    "Loading..." +
    "</button>" +
    "</div>" +
    '<form method=post action="'+constant.baseUrl+'api/v2/make/payment" name="paymentSubmit">' +
    '<input type=hidden name="order_id" value="' +
    localStorage.getItem("order_id") +
    '">' +
    "</form>" +
    '<script>window.onload = function(){document.forms["paymentSubmit"].submit();}</script></body></html>';

  const totalHeight =
    headerState?.desktopHeight > 0 ? headerState.desktopHeight + 30 : 76 + 30;

  const generatetransactionid = () =>
    ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );

  const trackCheckoutStep2 = (address) => {
    window.analytics && window.analytics.track("Checkout Step Completed", {
      checkout_id: tid,
      step: 1,
      address_id: address,
    });
  };

  const trackCheckoutStep4 = (cmt) => {
    window.analytics && window.analytics.track("Checkout Step Completed", {
      checkout_id: tid,
      step: 3,
      comment: cmt,
    });
  };
  const trackCheckoutStep5 = (pm) => {
    window.analytics && window.analytics.track("Checkout Step Completed", {
      checkout_id: tid,
      step: 4,
      payment_method: pm,
    });
  };

  const trackPaymentInfo = () => {
    window.analytics && window.analytics.track("Payment Info Entered", {
      checkout_id: tid,
      order_id: localStorage.getItem("order_id"),
    });
  };
  const trackPurchase = () => {
    const cartItems = items.map((item, index) => {
      return {
        name: item.name,
        sku: item.product_code,
        product_id: item.item_id,
        price: item.item_price,
        category: item.cat_id,
        position: index + 1,
      };
    });

    window.analytics && window.analytics.track("Order Completed", {
      checkout_id: tid,
      order_id: localStorage.getItem("order_id"),
      value: calculatePrice(),
      revenue: calculatePrice(),
      shipping: props.deliveryCharge,
      tax: 0.0,
      discount: discount,
      currency: "USD",
      products: cartItems,
    });
  };

  useEffect( async () => {
    setTid(generatetransactionid);
    if(localStorage.getItem('expressMode')) {
      setCartType('express');
      setDeliveryColor('#f01266')
      props.initBasket('express');
    }
    setTimeout(() => {
      if(localStorage.getItem('expressMode')) {
        fetchOrderItems(isExpress);
      }
      else {
        fetchOrderItems();
      }
    }, 1000);

    let user = localStorage.getItem("user");
    let parsedUser = JSON.parse(user);
    if (parsedUser && localStorage.getItem("order_id") !== undefined) {
      let cust_id = parsedUser.cust_id;
      let order_id = localStorage.getItem("order_id");
      props.getAddress();
      props.allCouponCodes({
        order_id: order_id,
        cust_id: cust_id,
        string: "0",
      });
      let body = {};
      body["order_id"] = order_id;
      props.alldeliverySlots(body);
    }
    else{
      alert( t('shop_checkout_no_associated_order', false) );
    }
  }, []);

  useEffect(() => {
    if(props.delivery_slots && props.delivery_slots.length > 0 && props.all_timings && props.all_timings.length > 0){
      setLoading(false);
    }
    else{
      setLoading(true);
    }
  },[props.delivery_slots,props.all_timings]);

  useEffect(() => {
    if(showPaymentModal === false){
      setLoading(false);
    }
  },[showPaymentModal]);

  useEffect(() => {
    if (
      props.all_slots !== undefined &&
      props.all_slots[0] !== undefined
    ) {

      setAllSlots(allslots(props.calendar_response));
      const newArr = [];
      const allSlots = props.all_slots;
      while(allSlots.length) newArr.push(allSlots.splice(0,props.delivery_slots.length));
      settwodslots(newArr);
    }
  }, [props.delivery_slots]);
  useEffect(() => {
    if (
      props.slots_for_date !== undefined &&
      props.slots_for_date[0] !== undefined
    ) {
      // setSelectedSlot(props.slots_for_date[0].slot_id);
      // trackCheckoutStep3(props.slots_for_date[0].slot_id);
    }
  }, [props.slots_for_date]);

  const selectDefaultAddress = () => {
    let defaultAddressID = selectedAddress;
    if (props.user_address.length) {
      defaultAddressID = props.user_address.find(item => item.is_default);

      if(cartType !== "express" && (defaultAddressID && defaultAddressID.city.toLowerCase() === "province")) {
        setHideCashOnDelivery(true);
        if(paymentMethod === "cod") {
          setPaymentMethod("");
        }
      } else {
        setHideCashOnDelivery(false);
      }

      if(defaultAddressID) {
        return defaultAddressID.address_id;
      }
      else {
        return selectedAddress;
      }
    }
    else {
      return selectedAddress;
    }
  }

  useEffect(() => {
    if(selectedAddress !== 0 && props.user_address.length) {
      const findAddressSelected = props.user_address.find(item => item.address_id === selectedAddress);
      if(findAddressSelected.isInRange === false) {
        alert( t('shop_checkout_alert_selected_address_out_of_range') )
      }
      setAddressIsInRange(findAddressSelected.isInRange);
    }
  }, [props.user_address, selectedAddress])

  useEffect(() => {
    if (items.length > 0 && props.user_address && props.user_address.length > 0) {
      setChecked(1);
      if (selectedAddress === 0) {
        setSelectedAddress(selectDefaultAddress());
      } else {
        let user = localStorage.getItem("user");
        let parsedUser = JSON.parse(user);
        let cust_id = parsedUser.cust_id;

        props.moveToCheckout({
          cust_id: cust_id,
          store_id: 1,
          order_id: localStorage.getItem("order_id"),
          address_id: selectedAddress,
          type: cartType === "express" ? 1 : 0,
        });
      }
      trackCheckoutStep2(props.user_address[0]["address_id"]);
    }
  }, [items, props.user_address]);

  useEffect(() => {
    if (items.length > 0 && props.deliveryCharge !== undefined) {
      let amountTotal = parseFloat(carttotal ? carttotal : 0.00) - parseFloat(discount) + parseFloat(props.deliveryCharge);
      setGrossTotal(amountTotal);
    } else {
      let amountTotal =
        parseFloat(carttotal ? carttotal : 0.00) +
        parseFloat(props.deliveryCharge) -
        parseFloat(discount);
        setGrossTotal(0.00);
    }
  }, [items, props.deliveryCharge]);
  const modifyTotal = () => {
    if (items.length > 0 && props.deliveryCharge !== undefined) {
      let amountTotal =
        parseFloat(carttotal ? carttotal : 0.00) -
        parseFloat(discount) +
        parseFloat(props.deliveryCharge);
      setGrossTotal(amountTotal);
    } else {
      let amountTotal =
        parseFloat(carttotal ? carttotal : 0.00) +
        parseFloat(props.deliveryCharge) -
        parseFloat(discount);
        setGrossTotal(0.00);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (cartItemsCount == 0 || carttotal == 0 || carttotal === NaN) {
      alert( t('shop_checkout_error_cart_is_empty', false) );
      setLoading(false);
    }
    else if(carttotal < 10){
      alert( t('shop_checkout_alert_minimum_cart_amount_error', false, {path: '$10'}) );
      setLoading(false);
    }
    else if(selectedSlot === 0 && cartType !== 'express'){
      alert( t('shop_checkout_select_schedule', false) );
      setLoading(false);
    }
    else if (props.user_address.length > 0) {
      if (!selectedAddress) {
        alert( t('shop_checkout_alert_no_address_selected_error', false) );
        setLoading(false);
      }
      if (!paymentMethod) {
        alert( t('shop_checkout_forget_select_payment_error', false) );
        setLoading(false);
      }
      if (localStorage.getItem("order_id") === undefined) {
        alert( t('shop_checkout_order_not_exist', false) );
        setLoading(false);
      }
       else {
        let user = localStorage.getItem("user");
        let parsedUser = JSON.parse(user);
        let cust_id = parsedUser.cust_id;
        let slot = selectedSlot;
        if (selectedSlot === 0 && cartType !== 'express') {
          alert( t('shop_checkout_select_schedule', false) );
          setLoading(false);
        }
        let sendData = {};
        let order_details = {
          total_kms: props.distanceBetweenUserStore,
          address_id: selectedAddress,
          item_total: carttotal ? carttotal : 0.00,
          slot_id: slot,
          delivery_fees: props.deliveryCharge,
          comments: comment,
          coupon_discount: (couponId != 0) ? parseFloat(discount) : 0,
        };
        if(walletChecked) {
          order_details.wallet_amount = props.wallet.amount;
        }

        sendData["order_details"] = order_details;
        sendData["store_id"] = 1;
        sendData["order_status"] = 2;
        sendData["order_id"] = localStorage.getItem("order_id");
        sendData["coupon_code_id"] = couponId != 0 ? couponId : null;
        sendData["p_method"] = paymentMethod !== "cod" ? "card" : paymentMethod;
        sendData["from"] = "website";
        sendData["oos_option"] = selfdelivery === 1 ? "Cancel Item" : oosoption;
        sendData["type"] = cartType === 'express' ? 1 : 0;
        sendData["is_apply_wallet"] = walletChecked;

        if(addressIsInRange === false) {
          alert( t('shop_checkout_alert_selected_address_out_of_range') )
          setLoading(false);
        }
        else {
          if(selectedAddress !== 0) {
            await initCheckout(sendData);
          }
        }
      }
    } else {
      alert( t('shop_address_list_empty_message', false) );
      setLoading(false);
    }
  };

  const onCheckOutError = (checkoutResp) => {
    if(Array.isArray(checkoutResp.data.types) && checkoutResp.data.types.length) {
      setIsError(checkoutResp.data.types[0]);
      const pattern = [
        'deliveryFeeChanged',
        'invalidCoupon',
        'slotNotAvailable',
        'expressSlotFull',
        'itemChanged',
        'itemRemoved',
        'noItemAvailable',
        'outOfRange',
        'walletAmountChanged'
      ];

      setDialogHeaderLabel( t('shop_checkout_alert_error') );
      if(pattern.includes(checkoutResp.data.types[0])) {
        setErrorMessage(reactStringReplace(checkoutResp.message, /(\n)/g, () => <br />));
        setShowPaymentModal(false);
        setShowError(true);

        // On item price change and out of stock
        // Just re-load the basket
        if(['itemChanged', 'itemRemoved'].includes(checkoutResp.data.types[0])) {
          fetchOrderItems(cartType);
        }
        // All items in basket are out of stock or has been disabled/deleted by admin
        // so we have to move current order to cancel
        else if(checkoutResp.data.types[0] === 'noItemAvailable') {
          clearCart();
          setTimeout(() => { window.location.href = "/all-orders" }, 4000);
        }
      }
    } else {
      setDialogHeaderLabel( t('shop_checkout_alert_error') );
      setErrorMessage( t('shop_item_update_error') );
      setShowPaymentModal(false);
      setShowError(true);
    }
  };

  const initCheckout = async (sendData) => {
    if(paymentMethod === 'cod') {
      const checkoutResp = await checkoutService.doCheckout(sendData, cartType);

      if(checkoutResp.data.success) {
        setModalShow(true);
        trackPurchase();
        trackPaymentInfo();
        clearCart();
        localStorage.setItem("order_id", 0);
        setTimeout(() => {setLoading(false);window.location.href = "/all-orders";}, 4000);
      }
      else {
        onCheckOutError(checkoutResp);
      }
    }
    else if(["card", "abapay", "bakong"].includes(paymentMethod)) {
      const checkoutResp = await checkoutService.doCheckout(sendData, cartType);

      if(checkoutResp.data.success) {
        handlePayemntShow();
        props.expressCheckout({
          data: {},
          success: true,
        })
      }
      else {
        onCheckOutError(checkoutResp);
      }
    }
  }

  const handleHideShowCheckOutDialog = (status) => {
    clearInterval(checkPaymentIntervalId); // It's to avoid duplicate invoice.
    setLoading(false);
    setShowPaymentModal(false)
    setPaymentMethod("");
    setEnableCheckOutButton(false);

    props.changeOrderStatus();

    setTimeout(() => {
      document.querySelector('.payment-option label.active').classList.remove('active')
      document.querySelector('.payment-option label input[type="radio"]:checked').checked = false;
    }, 50);
  }

  useEffect(() => {
    if (props.orderPlaced === true && paymentMethod === "cod") {
      setModalShow(true);
      trackPurchase();
      trackPaymentInfo();
      // localStorage.setItem("order_id", 0);
      clearCart();
      localStorage.setItem("order_id", 0);
      setTimeout(() => {
        setLoading(false);
        window.location.href = "/all-orders";
      }, 4000);
    } else if (props.orderPlaced === true && (paymentMethod === "card" || paymentMethod === "abapay" || paymentMethod === "bakong")) {
      if(localStorage.getItem("order_id") && (localStorage.getItem("order_id") !== 0 || localStorage.getItem("order_id") !== "0")){
       handlePayemntShow();
       handlePaySubmit();
      }
      else{
        alert( t('login_re_login_error', false) );
      }
      // alert(htmlContent);
    }
  }, [props.orderPlaced]);

  // ---- Payment checker -----
  const handlePaySubmit = async (e) => {
    let token = localStorage.getItem("token");

    if(token) {
      function getAlerts() {
        const type = cartType === 'express' ? 1 : 0;
        const data = {order_id: localStorage.getItem("order_id")};
        const header = { headers: { Authorization: token } }
        axios
          .post(constant.baseUrl+"v1/web/basket/verify/aba?type="+type, data, header)
          .then(function (response) {
            if (response.data.success && response.data.data.is_paid) {
              checkPaymentDate();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      const intervalId = setInterval(() => getAlerts(), 5000);
      setCheckPaymentIntervalId(intervalId);
    }
    else {
      alert('Please sign in');
    }
  };
  const checkPaymentDate = async (e) => {
    setModalShow(true);
    trackPurchase();
    trackPaymentInfo();
    // localStorage.setItem("order_id", 0);
    handlePaymentClose();
    clearCart();
    localStorage.setItem("order_id", 0);
    setTimeout(() => {
      setLoading(false);
      window.location.href = "/all-orders";
    }, 4000);
  };
  // ---- Payment checker -----

  // Add or edit modal
  const handleModal = (
    modalComponent,
    modalProps = {},
    type,
    className = "add-address-modal"
  ) => {
    // document.body.style.overflow = "hidden";
    openModal({
      Modal: true,
      config: {
        // width: 360,
        height: "auto",
        enableResizing: false,
        disableDragging: true,
        className: className,
      },
      closeOnClickOutside: false,
      component: modalComponent,
      componentProps: { item: modalProps, type, props },
    });
  };

  const handleEntailmentRequest = async (e) => {
    e.preventDefault();
    //do something...
  };

  const handleEditDelete = async (item, type, name) => {
    if (type === "edit") {
      const modalComponent = name === "address" ? UpdateAddress : UpdateContact;
      handleModal(modalComponent, item, "edit");
    } else if (type === "delete") {
      if (item.is_default) {
        alert( t('shop_checkout_cant_delete_default_address_error', false) );
      } else {
        let response = window.confirm( t('shop_checkout_confirm_delete_address', false) );
        if (response === true) {
          props.deleteAddress({ address_id: item.address_id });
        }
      }
    } else {
    }
  };

  const handleApplyCoupon = async (item, name) => {
    // const { data } = await appliedCoupon({
    //   variables: { code: couponCode },
    // });
    // if (data.applyCoupon && data.applyCoupon.percentage) {
    //   applyCoupon(data.applyCoupon);
    //   setCouponCode("");
    // } else {
    //   setError("Invalid Coupon");
    // }
  };
  const handleOnUpdate = (search) => {
    console.log("Search Value is ", search);
    setSearchCoupon(search);
    if(localStorage.getItem("order_id") !== undefined){
      props.allCouponCodes({
        order_id: localStorage.getItem("order_id"),
        cust_id: 1,
        string: search,
      });
    }
    // setCouponCode(couponCode);
  };
  const getName = (ele) => {
    // return ele.start_time;
    let str =
      // convertDate(ele.start_time.split(' ')[0]) +
      // ' | ' +
      convertTime24to12(ele.start_time.split(" ")[1]) +
      " - " +
      convertTime24to12(ele.end_time.split(" ")[1]);
    return str;
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const restrictedrange = (start, end) => {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  };

  const getslot = (d,t) => {
    const res = allSLOTS.filter((elem) => {
      return String(elem.start_time) === String(t) && String(elem.date) === String(d) && elem.stop_accepting_orders === 0 && elem.is_deleted === 0 && elem.slot_expired === "0";
    });
    return res;
  };

  const formatdateheader = (d) => {
    const slotDate = new Date(d);
    return (slotDate.toDateString()).split(" ")[0] + " " +  slotDate.getDate() + "/" + (slotDate.getMonth() + 1 );
  };

  const formattimeheader = (t) => {
    var times = t.split(":")[0];
    var timee = Number(times)+1;
    var prefix = "am";
    if(times>=11){
      prefix = "pm";
    }
    times = times%12 !== 0 ? times%12 : times ;
    timee = timee%12 !== 0 ? timee%12 : timee ;
    return String(times) + "-" + String(timee) + prefix;
  };
  
  const formatschedule = () => {
    const res = allSLOTS.filter((elem) => {
      return selectedSlot === elem.slot_id;
    });
    const dt = res[0].date
    const slot = new Date(dt);
    const time = formattimeheader(res[0].start_time);
    return (slot.toDateString()).split(" ")[0] + " " +  (slot.toDateString()).split(" ")[1] + " " + (slot.toDateString()).split(" ")[2] + " , " + time ;
  };

  const handleDeliverySlotCloseModal = (status = false) => setShowSchedule(status);
  const onSlotSelected = (slot_id) => setSelectedSlot(slot_id);

  const handleErrorModalClose = () => {
    setShowError(false);
    setLoading(false);

    if(isError === 'noItemAvailable') {
      clearCart();
      localStorage.setItem("order_id", 0);
      window.location.href = "/express";
    }
  }

  const handleRemoveCoupon = (e) => {
    e.preventDefault();
    removeCoupon();
    setSelectedCode("");
    setCouponId(0);
    setDiscount(0);
    setHasCoupon(false);
    let amountTotal = parseFloat(carttotal ? carttotal : 0.00) + parseFloat(props.deliveryCharge);
    if(walletChecked){
      if(amountTotal >= props.wallet.amount) {
        setTotalWalletAmount(0);
      }
      else {
        setTotalWalletAmount(props.wallet.amount - amountTotal);
      }
    }
    else {
      setTotalWalletAmount(props.wallet.amount);
    }
    setGrossTotal(amountTotal);
  }

  const handleCouponSelected = (e, coupon) => {
    if (coupon.is_valid != 0 && coupon.applicable != "no") {
      setSelectedCode(coupon.code);
      setCouponId(coupon.id);
      setHasCoupon(true);
      setDiscount(coupon.discountPrice);
      e.currentTarget.classList.add("active");
      let amountTotal = parseFloat(carttotal ? carttotal : 0.00) + parseFloat(props.deliveryCharge) - parseFloat(coupon.discountPrice);

      if(walletChecked){
        if(amountTotal >= props.wallet.amount) {
          setTotalWalletAmount(0);
        }
        else {
          setTotalWalletAmount(props.wallet.amount - amountTotal);
        }
      }
      else {
        setTotalWalletAmount(props.wallet.amount);
      }

      setGrossTotal(amountTotal);
      setShow(false);
    } else {
      alert( t('shop_checkout_error_coupon_code',  false) );
    }
  }

  const calcWalletAmount = () => {
    let result = 0.00;
    if((grossTotal - props.wallet.amount) > 0){
      result = grossTotal - props.wallet.amount
    }

    return result
  }

  return (
    <form>
      <CheckoutWrapper>
        <CheckoutContainer>
          <CartWrapper>
            <Sticky enabled={false} top={totalHeight} innerZ={999}>
              <OrderInfo>
                <Title>
                  { t('shop_checkout_your_order_label') }
                </Title>

                <Scrollbars
                  universal
                  autoHide
                  autoHeight
                  autoHeightMax="390px"
                  renderView={(props) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        marginLeft: props.style.marginRight,
                        marginRight: 0,
                        paddingLeft: 15,
                        paddingRight: 0,
                      }}
                    />
                  )}
                >
                  <ItemsWrapper>
                    {carttotal > 0 && items && <NoProductMsg>
                      <span style={{fontWeight:"700", color:"black"}}>
                        { t('shop_checkout_products_not_available') }
                      </span>
                    </NoProductMsg>
                    }
                    {carttotal > 0 && items ? (
                      items.map((item) => (
                        <OrderItem
                          key={`cartItem-${item.item_id}`}
                          product={item}
                          props={props}
                          address_id={selectedAddress}
                          modify_total={modifyTotal}
                          cartType={cartType}
                        />
                      ))
                    ) : (
                      <NoProductMsg>
                        <span>
                          {reactStringReplace(`${ t('shop_checkout_error_cart_empty', false) }}`, /({path})/g, (item, index) =>
                            <span key={index} onClick={() => {window.location.href = "/";}}
                                  style={{textDecoration: "none", color: "unset", cursor: "pointer"}}
                            >{ t('shop_checkout_browse_our_shop_redirection') }</span>
                          )}
                        </span>
                      </NoProductMsg>
                    )}
                  </ItemsWrapper>
                </Scrollbars>

                <CalculationWrapper>
                  <TextWrapper>
                    <Text>
                      { t('shop_checkout_subtotal') }
                    </Text>
                    <Text>
                      {CURRENCY}
                      {parseFloat(carttotal && items ? carttotal : 0.00).toFixed(2)}
                    </Text>
                  </TextWrapper>

                  <TextWrapper>
                    <Text>
                      { t('shop_checkout_delivery') }
                    </Text>
                    <Text>
                      {CURRENCY}
                      {props.deliveryCharge
                        ? parseFloat(props.deliveryCharge).toFixed(2)
                        : parseFloat(0.0).toFixed(2)}
                    </Text>
                  </TextWrapper>

                  <TextWrapper>
                    <Text>
                      { t('shop_checkout_discount') }
                    </Text>
                    <Text>
                      {CURRENCY}
                      {parseFloat(discount).toFixed(2)}
                    </Text>
                  </TextWrapper>

                  <TextWrapper>
                    <Text>
                      { t('shop_checkout_wallet') }
                    </Text>
                    <Text>
                      {CURRENCY}
                      {walletChecked
                        ? props.wallet !== null && props.wallet.amount >= grossTotal // If wallet bigger or equal to amount to pay
                          ? parseFloat(grossTotal).toFixed(2)
                          : parseFloat(props.wallet.amount).toFixed(2)
                        : parseFloat(0).toFixed(2)
                      }
                    </Text>
                  </TextWrapper>

                  <TextWrapper style={{ marginTop: 20 }}>
                    <Bold>
                      { t('shop_order_list_total_title') }
                    </Bold>
                    <Bold>
                      {CURRENCY}
                      {parseFloat(grossTotal !== undefined && grossTotal !== NaN && localStorage.getItem("order_id") !== undefined
                        ? walletChecked
                          ? calcWalletAmount()
                          : grossTotal
                        : 0.00).toFixed(2)
                      }
                    </Bold>
                  </TextWrapper>
                  <Button
                    style={{float: "right", marginTop: "8px"}}
                    title={ t('button_edit_cart', false) }
                    variant="primary"
                    size="small"
                    onClick={() => {localStorage.setItem("reordered",true); window.location.href = "/";}}
                  >
                    { t('button_edit_cart') }
                  </Button>
                </CalculationWrapper>
                {checkout_banner
                  ? <a target="_blank" rel="noopener noreferrer" href={checkoutLink}>
                    <img src={checkout_banner} alt="wdw" width={"100%"} height={"auto"} style={{margin: "20px auto"}} />
                  </a>
                  : null
                }
              </OrderInfo>
            </Sticky>
          </CartWrapper>
          <CheckoutInformation>
            {/* DeliveryAddress */}
            <InformationBox>
              <Heading className={cartType}>
                { t('setting_shop_addresses') }
              </Heading>
              <ButtonGroup>
                <RadioGroup
                  items={
                    props.user_address != undefined ? props.user_address : []
                  }
                  containerClassName=""
                  component={(item) => (
                    <RadioCard
                      id={item.address_id}
                      key={item.address_id ? item.address_id : item.id}
                      title={item.nick_name}
                      firstName={item.first_name}
                      lastName={item.last_name ? item.last_name : null}
                      value={item.address_id}
                      content={item.area_details}
                      house_number={item.house_number}
                      apartment_name={item.apartment_name}
                      street_details={item.street_details}
                      mobile_number={item.mobile_number}
                      country_code={item.country_code}
                      city={item.city}
                      landmark={item.landmark}
                      pin_code={item.pin_code}
                      delivery_instructions={item.delivery_instructions}
                      name="address"
                      checked={selectedAddress === item.address_id}
                      onChange={() => {
                        setChecked(1);
                        setAddressIsInRange(item.isInRange);
                        if(cartType !== "express" && (item && item.city.toLowerCase() === "province")) {
                          setHideCashOnDelivery(true);
                          if(paymentMethod === "cod") {
                            setPaymentMethod("");
                          }
                        } else {
                          setHideCashOnDelivery(false);
                        }
                        setSelectedAddress(item.address_id);
                        let user = localStorage.getItem("user");
                        let parsedUser = JSON.parse(user);
                        let cust_id = parsedUser.cust_id;
                        props.moveToCheckout({
                          cust_id: cust_id,
                          store_id: 1,
                          order_id: localStorage.getItem("order_id"),
                          address_id: item.address_id,
                          type: cartType === "express" ? 1 : 0,
                        });
                      }}
                      isSelected={isChecked}
                      onEdit={() => handleEditDelete(item, "edit", "address")}
                      onDelete={() =>
                        handleEditDelete(item, "delete", "address")
                      }
                    />
                  )}
                  secondaryComponent={
                    <Button
                      className={`addButton ${cartType} no-bg`}
                      title={ t('button_add_new', false) }
                      icon={<Plus width="10px" />}
                      iconPosition="left"
                      colors="primary"
                      size="small"
                      variant="textButton"
                      type="button"
                      intlButtonId="addNew"
                      onClick={() =>
                        handleModal(UpdateAddress, "add-address-modal", "add")
                      }
                    />
                  }
                />
              </ButtonGroup>
            </InformationBox>

            {/* DeliverySchedule */}
            {showSlot && cartType !== 'express' && <InformationBox>
              <DeliverySchedule>
                <Heading className={cartType}>
                  { t('shop_checkout_delivery_schedule_title') }
                </Heading>
                {
                <SubHeading style={{color: "red", fontWeight: "700", marginTop: "-10px", fontSize: "15px"}}>
                  { t('shop_checkout_covid19_note') }
                </SubHeading>
                }
                {selectedSlot ?
                 <>
                 <div onClick={(e) => {
                  e.preventDefault();
                  setShowSchedule(true);
                  }}
                  style={{border:"1px dotted green", borderRadius: "25px", padding:"30px 30px", color: deliveryColor, fontWeight: "700", cursor: "pointer"}}>
                  <SubHeading
                  style={{cursor: "pointer"}}
                  onClick={(e) => {
                   e.preventDefault();
                   setShowSchedule(true);
                  }}>
                    { t('shop_checkout_modify_schedule_label') }
                  </SubHeading>
                  <span>{formatschedule()}</span>
                 </div>
                 </>
                 : <SubHeading
                style={{cursor: "pointer"}}
                onClick={(e) => {
                 e.preventDefault();
                 setShowSchedule(true);
                }}>
                  <Button
                    title={ t('shop_checkout_delivery_schedule_title', false) }
                    variant="primary"
                    onClick={() => setShowSchedule(false)}
                  >
                    { t('shop_checkout_delivery_schedule_title', false) }
                  </Button>
               </SubHeading>
                }
                <AvailableDeliverySlotModal show={showSchedule}
                                            handleCloseDialog={handleDeliverySlotCloseModal}
                                            delivery_slots={props.delivery_slots}
                                            twodslots={twodslots}
                                            allSLOTS={allSLOTS}
                                            selectedSlot={selectedSlot}
                                            onSlotSelected={onSlotSelected}
                                            all_timings={props.all_timings}
                                            pageName="checkout"
                />
              </DeliverySchedule>
            </InformationBox>}

            {/* Contact number */}
            <InformationBox>
              <Heading className={cartType}>
                { t('shop_checkout_comment_title') }
              </Heading>
              <ButtonGroup className={cartType}>
                <TextField
                  id="Comments"
                  as="textarea"
                  placeholder={ t('shop_checkout_comment_hint', false) }
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                    trackCheckoutStep4(comment);
                  }}
                />
              </ButtonGroup>
            </InformationBox>

            {/* PaymentOption */}
            <InformationBox>
              <Heading className={cartType}>
                { t('shop_checkout_payment_title') }
              </Heading>
              <ButtonGroupRn
                vertical
                className="payment-option"
                onClick={(e) => {
                  paymentOptionChoose(e.target.value);
                }}
              >
                {totalWalletAmount !== null && isDisplayWallet
                  ? <div className="wallet-pay-wrapper">
                    <div className={`wallet-icon-wrapper ${cartType}`}>
                      { t('shop_checkout_payment_wallet_title') }
                    </div>
                    <ToggleButton
                      variant="success"
                      className="wallet-pay-btn"
                      type="radio"
                      checked={walletChecked}
                      value="wallet"
                    >
                      <div className="btn-title">
                        { t('shop_checkout_payment_adjust_wallet_title') }
                        <span className="btn-sub-title">
                          { t('shop_checkout_payment_wallet_balance_title') } <FormattedNumber value={totalWalletAmount || 0} style="currency" currency="USD" />
                        </span>
                      </div>
                      <Form.Check type="switch"
                                  id="walletPayment"
                                  label=""
                                  checked={walletChecked}
                                  onChange={() => {}}
                      />
                    </ToggleButton>
                  </div>
                  : null
                }
                {!hideCashOnDelivery
                  ? <CaseOnDeliveryBtn>
                    <ToggleButton
                      variant="success"
                      type="radio"
                      className={`case-on-delivery ${isExpress}`}
                      checked={checkedDelivery}
                      value="cod"
                    >
                      { t('shop_checkout_cash_on_delivery_option') }
                    </ToggleButton>
                  </CaseOnDeliveryBtn>
                  : null
                }
                <PaymentMethodWrapper>
                  <ToggleButton
                    variant="info"
                    className={`abapay ${isExpress}`}
                    type="radio"
                    checked={checkedABAPay}
                    value="abapay"
                  >
                    <img src={abaPayIcon} alt="ABA PAY" />
                    <div className="info">
                      ABA PAY
                      <span className="subtitle">Scan to pay with ABA Mobile</span>
                    </div>
                  </ToggleButton>
                  <ToggleButton
                    variant="info"
                    className={`card ${isExpress}`}
                    type="radio"
                    checked={checkedCard}
                    value="card"
                  >
                    <img src={creditDebitCard} alt="Credit - Debit card" />
                    <div className="info">
                      Credit/Debit Card
                      <img src={abaCardsIcon} alt="Credit - Debit card" />
                    </div>
                  </ToggleButton>
                  <ToggleButton
                    variant="info"
                    className={`bakong ${isExpress}`}
                    type="radio"
                    checked={checkedBakong}
                    value="bakong"
                  >
                    <img src={bakongIcon} alt="KHQR" />
                    <div className="info">
                      KHQR
                      <span className="subtitle">Scan to pay with member bank app</span>
                    </div>
                  </ToggleButton>
                </PaymentMethodWrapper>
              </ButtonGroupRn>
            </InformationBox>
            <Modal
              show={show}
              onHide={() => {
                setShow(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>{ t('shop_checkout_popup_coupon_title_label') }</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Input
                  onUpdate={handleOnUpdate}
                  value={searchCoupon}
                  intlPlaceholderId="couponPlaceholder"
                />
                <br />
                <Card>
                  <Card.Body>
                    <ListGroup variant="flush">
                      {props.all_coupon_codes !== undefined && 
                        props.all_coupon_codes.map((coupon, idx) => {
                          let user = localStorage.getItem("user");
                          let parsedUser = JSON.parse(user);
                          let cust_id = parsedUser.cust_id;
                          let restricted_coupons = restrictedrange(1753,1903);
                          if(cust_id === 228 && restricted_coupons.indexOf(coupon.id)){
                            return null;
                          }else{
                          return (
                            <ListGroup.Item
                              key={coupon.name}
                              id={coupon.code}
                              action
                              onClick={(e) => handleCouponSelected(e, coupon)}
                            >
                              <p style={{ color: "red" }}>{coupon.code}</p>
                              <p>{coupon.name}</p>
                              <p style={{ color: deliveryColor }}>
                                {coupon.description}
                              </p>
                            </ListGroup.Item>
                          );}
                        })}
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  title={ t('home_basket_update_quantity_popup_button_save', false) }
                  variant="primary"
                  onClick={() => setShow(false)}
                >
                  { t('home_basket_update_quantity_popup_button_save') }
                </Button>
              </Modal.Footer>
            </Modal>
            {selectedCode ? (
              <CouponBoxWrapper>
                <CouponCode>
                  { t('shop_checkout_popup_coupon_applied') }
                  <span className={cartType}>{selectedCode}</span>

                  <RemoveCoupon onClick={handleRemoveCoupon}>
                    { t('shop_checkout_remove_coupon_button') }
                  </RemoveCoupon>
                </CouponCode>
              </CouponBoxWrapper>
            ) : (
              <CouponBoxWrapper>
                {!hasCoupon && (
                  <HaveCoupon
                    className={cartType}
                    onClick={(e) => {
                      e.preventDefault();
                      setShow(true);
                      // couponId !== 0 ? setHasCoupon(true) : setHasCoupon(false);
                    }}
                  >
                    { t('shop_checkout_input_coupon_title') }
                  </HaveCoupon>
                )}
              </CouponBoxWrapper>
            )}
            <CheckoutSubmit className={enableCheckOutButton ? "btn-checkout enable" : "btn-checkout"}>
              <Button
                onClick={(e) => {
                    handleSubmit(e);
                }}
                type="button"
                disabled={loading}
                title={ t('shop_checkout_pay_button', false) }
                intlButtonId="proceesCheckout"
                loader={<Loader className={cartType} />}
                isLoading={loading}
              />
            </CheckoutSubmit>
            {/* </InformationBox> */}
          </CheckoutInformation>
        </CheckoutContainer>
      </CheckoutWrapper>
      <>
        <Modal
          show={modalshow}
          onHide={() => {
            setModalShow(false);
          }}
          // dialogClassName="modal-lg"
          aria-labelledby="example-custom-modal-styling-title"
          size="sm"
          animation={true}
          centered
        >
          <Animation options={defaultOptions} height={180} width={180} />
          <div
            style={{
              fontSize: 20,
              textAlign: "center",
              margin: 10,
              marginTop: 18,
              color: "#222",
            }}
          >
            { t('shop_checkout_alert_success_order') }
          </div>
        </Modal>

        <SimpleMessageDialog show={showError}
                             message={errorMessage}
                             showHeader={true}
                             headerLabel={dialogHeaderLabel}
                             onClose={handleErrorModalClose}
        />
        <CheckoutForm dialogStatus={showPaymentModal}
              paymentMethod={paymentMethod}
              placeOrderStatus={placeOrderStatus}
              handleClose={handleHideShowCheckOutDialog}
        />
      </>
    </form>
  );
};

export default CheckoutWithSidebar;
