import styled from "styled-components";
import css from "@styled-system/css";
import { style, variant, width } from "styled-system";
export const CounterBox = styled.div(
  css({
    width: '100% !important',
    maxWidth: '140px !important',
    border: 1,
    borderColor: '#e1e1e1',
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#7e7e7e',
  }),
  {
    borderRadius: 0,
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    flexShrink: 0,
    "&:focus": {
      outline: "none",
    },

    "&.express": {
      backgroundColor: '#f01266',
      color: '#FFFFFF',

      "input": {
        borderRadius: 0,
      },
    },
  },
  variant({
    variants: {
      vertical: {
        flexDirection: "column-reverse",
      },
      lightHorizontal: {
        backgroundColor: "lightColor",
        color: "darkBold",
      },

      lightVertical: {
        flexDirection: "column-reverse",
        backgroundColor: "lightColor",
        color: "darkBold",
      },
    },
  })
);

export const CounterButton = styled.button(
  {
    fontSize: 18,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    cursor: 'pointer',
    fontWeight: 'bold',
    backgroundColor: 'transparent !important',
    border: 'none',
    color: '#7e7e7e',

    "&.express": {
      color: '#ffffff'
    },

    "@media (max-width: 480px)": {
      paddingLeft: 10,
      paddingRight: 10,
    }
  },
  variant({
    variants: {
      lightHorizontal: {
        color: "darkRegular",
      },
      lightVertical: {
        color: "darkRegular",
      },
    },
  })
);

export const CounterValue = styled.span({
//   pointerEvents: "none",
});
CounterValue.displayName = "CounterValue";
CounterButton.displayName = "CounterButton";
CounterBox.displayName = "CounterBox";
CounterBox.defaultProps = {
  variant: "horizontal",
};
