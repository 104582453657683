import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";

const Icon = styled.span`
  min-width: 16px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavLink = ({
  href,
  label,
  intlId,
  icon,
  className,
  onClick,
  iconClass,
}) => {
  const { pathname } = useLocation();
  const isCurrentPath = pathname === href;
  return (
    <div onClick={onClick} className={className ? className : ""}>
      <a
        onClick={() => {
          window.location.href = href;
        }}
        className={isCurrentPath ? " current-page" : ""}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        {icon ? <Icon className={iconClass}>{icon}</Icon> : ""}

        <span className="label">
          {intlId ? (
            <FormattedMessage
              id={intlId ? intlId : "defaultNavLinkId"}
              defaultMessage={label}
            />
          ) : (
            label
          )}
        </span>
      </a>
    </div>
  );
};

export default withRouter(NavLink);
