import React, {useState, useEffect} from "react";
import { useDispatch } from 'react-redux';
import Table from "rc-table";
import {
  DeliveryInfo,
  DeliveryAddress,
  Address,
  CostCalculation,
  PriceRow,
  Price,
  ProgressWrapper,
  OrderTableWrapper,
  OrderTable,
  Reorder
} from "./OrderDetails.style";
import Progress from "../../../../components/ProgressBox/ProgressBox";
import { CURRENCY } from "../../../../helper/constant";
import { FormattedMessage } from "react-intl";
import { Title } from "../Order.style";
import { Status } from "../OrderCard/OrderCard.style";
import t from '../../../../i18n/translate';
import SimpleMessageDialog from "../../../../components/SimpleMessageDialog/SimpleMessageDialog";
import * as actionTypes from "../../../../store/ActionTypes";
import {useCart} from "../../../../contexts/cart/use-cart";
import itemToCartService from "../../../../services/itemToCartService";

const components = {
  table: OrderTable,
};
const OrderDetails = ({
  tableData,
  columns,
  address,
  progressBox,
  progressStatus,
  subtotal,
  discount,
  deliveryFee,
  grandTotal,
  name,
  phone,
  delivery_slot,
  country_code,
  comments,
  pageType,
  status,
  onError,
  onSuccess,
  type,
  props,
  orderId
}) => {
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const lang = localStorage.getItem('lang') || 'en';
  const [errorMessage, setErrorMessage] = useState('');
  const { items } = useCart();

  const [closeLabel, setCloseLabel] = useState('');
  const [confirmLabel, setConfirmLabel] = useState('');
  const [dialogHeaderLabel, setDialogHeaderLabel] = useState(t('shop_checkout_alert_error'));
  const [simpleDialogBackdrop, setSimpleDialogBackdrop] = useState(true);
  const [isConfirmButton, setIsConfirmButton] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();
  const handleReOrder = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const currentOrder = props[type].find(item => item.order_id === orderId);

    const isExpressMode = props.cartType === 'express';
    setCloseLabel( t('button_close_label') );

    if(currentOrder.type && !isExpressMode) {
      setErrorMessage( t('shop_order_detail_switch_to_express_mode_message_for_reorder') )
      setShowErrorDialog(true);
    }
    else if(isExpressMode && !currentOrder.type) {
      setErrorMessage( t('shop_order_detail_switch_to_normal_mode_message_for_reorder') )
      setShowErrorDialog(true);
    }
    else {
      // If user have some items in current basket
      if(items.length) {
        setErrorMessage( t('shop_order_detail_re_order_error') );
        setConfirmLabel( t('shop_item_detail_confirm_button') );
        setCloseLabel( t('shop_tracking_order_cancel_button') );
        setIsConfirmButton(true);
        setShowErrorDialog(true);
      }
      else {
        setIsFetching(true);
        await reOrderValidator();
      }
    }
  }

  const reOrderValidator = async () => {
    if(!isFetching) {
      const dataResp = await itemToCartService.reOrder(orderId);
      if(dataResp.status === 401) {
        setErrorMessage( dataResp.data.message );
        setIsConfirmButton(false);
        setShowErrorDialog(true);
      }
      else if(dataResp.status === 400) {
        onError(dataResp.data.message);
        setShowErrorDialog(false);
      }
      else if(dataResp.status === 200) {
        if(!props.reordersuccess) {
          dispatch({
            type: actionTypes.Reorder,
            reordersuccess: true
          });
        }
        onSuccess(dataResp.data);
        setShowErrorDialog(false);
      }
      setIsFetching(false);
    }
  }

  const onConfirm = async () => {
    setIsLoadingDialog(true);
    await reOrderValidator();
    setIsLoadingDialog(false);
  }
  const closeSimpleDialog = () => {
    setShowErrorDialog(false);
    setIsConfirmButton(false);
    setIsLoadingDialog(false);
    setErrorMessage('');
    setConfirmLabel('');
    setCloseLabel('');
  }

  useEffect(() => {
    if(props.reordersuccess || props.reordersuccess === 'error') {
      dispatch({
        type: actionTypes.Reorder,
        reordersuccess: false
      });

      closeSimpleDialog();
    }
  }, [props.reordersuccess]);

  return (
    <>
      <div>
        <Title style={{ padding: "0 20px", width: "50%", display: "inline-block" }}>
          {props.fetch_particular_order?.type === 1
            ? <>{ t('shop_order_list_order_details_title') } <span style={{color: '#f01266'}}>(Express)</span></>
            : t('shop_order_list_order_details_title')
          }
        </Title>
        <div style={{padding: "0 20px", width: "50%", display: "inline-block", textAlign: "right",}}>
          {localStorage.getItem("user")
            ? <Reorder style={{cursor: "pointer", marginRight: "15px"}} onClick={handleReOrder}>{ t('shop_order_list_re_order_label') }</Reorder>
            : null
          }
          <Status>
            {typeof progressBox !== 'undefined'
              ? t(`shop_order_list_status_${ progressBox[progressStatus].replace(' ', '_').toLowerCase() }`)
              : ''
            }
          </Status>
        </div>
      </div>
      <DeliveryInfo>
        <DeliveryAddress>
          <h3>{ t('setting_shop_addresses') }</h3>
          <Address>{ t('profile_user_full_name_input_title') }:{name}</Address>
          <Address>{ t('shop_address_list_phone_abbreviation_label') }: {"+" + country_code + "-" + phone}</Address>
          <Address>{address}</Address>
        </DeliveryAddress>

        <DeliveryAddress>
          <h3>{ t('shop_order_list_delivery_slot_label') }</h3>
          <Address>{delivery_slot}</Address>
          {comments ? (
            <>
              <h3 className="mt-2">{ t('shop_order_list_comment_label') }</h3>
              <Address>{comments}</Address>
            </>
          ) : null}
        </DeliveryAddress>

        <CostCalculation>
          <PriceRow>
            { t('shop_checkout_subtotal') }
            <Price>
              {CURRENCY}
              {parseFloat(subtotal).toFixed(2)}
            </Price>
          </PriceRow>
          <PriceRow>
            { t('shop_checkout_delivery') }
            <Price>
              {CURRENCY}
              {parseFloat(deliveryFee).toFixed(2)}
            </Price>
          </PriceRow>
          {props.fetch_particular_order.order_payment_details[0].coupon_code_id > 0
            ? <PriceRow>
              { t('shop_checkout_discount') }
              <Price>
                {CURRENCY}
                {parseFloat(props.fetch_particular_order.order_payment_details[0].coupon_discount_amount).toFixed(2)}
              </Price>
            </PriceRow>
            : null
          }
          {props.fetch_particular_order.order_payment_details[0].wallet_discount_amount > 0
            ? <PriceRow>
              { t('shop_checkout_wallet') }
              <Price>
                {CURRENCY}
                {parseFloat(props.fetch_particular_order.order_payment_details[0].wallet_discount_amount).toFixed(2)}
              </Price>
            </PriceRow>
            : null
          }
          <PriceRow className="grandTotal">
            { t('shop_checkout_grand_total') }
            <Price>
              {CURRENCY}
              {parseFloat(grandTotal).toFixed(2)}
            </Price>
          </PriceRow>
        </CostCalculation>
      </DeliveryInfo>

      {/*Order Stepper Status*/}
      {pageType == "current-order"
        ? <ProgressWrapper>
          <Progress data={progressBox}
                    status={progressStatus}
                    cartType={props.fetch_particular_order?.type === 1
                      ? 'express'
                      : 'normal'
                    }
          />
        </ProgressWrapper>
        : null
      }

      <OrderTableWrapper>
        <Table
          columns={columns}
          data={tableData}
          rowKey={(record) => record.order_item_id}
          components={components}
          className={`orderDetailsTable ${props.fetch_particular_order?.type === 1
            ? 'express'
            : 'normal'
          }`}
          // scroll={{ y: 350 }}
        />
      </OrderTableWrapper>

      <SimpleMessageDialog show={showErrorDialog}
                           message={errorMessage}
                           showHeader={true}
                           closeLabel={closeLabel}
                           confirmLabel={confirmLabel}
                           headerLabel={dialogHeaderLabel}
                           isConfirmButton={isConfirmButton}
                           onClose={closeSimpleDialog}
                           onConfirm={onConfirm}
                           loading={isLoadingDialog}
                           backdrop={simpleDialogBackdrop}
                           cartType={props.cartType}
      />
    </>
  );
};

OrderDetails.defaultProps = {
  delivery_slot: null,
}

export default OrderDetails;
