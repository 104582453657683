import React, { useEffect, useState } from "react";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  InfoWindow,
  GoogleApiOptionsFunc,
} from "google-maps-react";

import GoogleMapReact from "google-map-react";
import _, { set } from "lodash";
import moment from 'moment';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const myStyles = [
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];
const GoogleMapInfos = ({
  google,
  latitude,
  longitude,
  orderList,
  onMarkerDragEnd,
  onMyLocation,
  fetchAddress,
}) => {
  var bounds = google.maps.LatLngBounds();

  //-------------State
  const [selectedElement, setSelectedElement] = useState(null);
  const [showInfoWindow, setInfoWindowFlag] = useState(true);
  const [coordinates, setCoordinates] = useState({
    lat: 11.5564,
    lng: 104.9282,
  });
  const [marker, setMarker] = useState([]);
  const [activeMarker, setActiveMarker] = useState({});
  const [showingInfoWindow, setShowingInforWindow] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState({});


  // ======================== Methods ====================

  const onMarkerClick = (props, marker) => {
    setActiveMarker(marker);
    setShowingInforWindow(true);
  }

  const onInfoWindowClose = () =>{
    setActiveMarker({});
    setShowingInforWindow(false);
  }

  const onMapClicked = () => {
    if (showingInfoWindow){
      setActiveMarker(null);
      setShowingInforWindow(false);    
    };
  };

  useEffect(() => {
    setMarker([{ lat: 11.562178, lon: 104.894288, name: "Test now" }]);
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          paddingTop: "10px",
          marginBottom: "10px",
          height: "88vh",
          position: "relative",
        }}
      >
        <Map
          disableDefaultUI={true}
          streetViewControl={false}
          mapTypeControl={true}
          keyboardShortcuts={false}
          scrollwheel={false}
          center={{ lat: latitude, lng: longitude }}
          google={google}
          bounds={bounds}
          style={{
            width: "100%",
            height: "80vh",
          }}
          styles={myStyles}
          draggable={true}
          zoom={14}
          initialCenter={{ lat: latitude, lng: longitude }}
          fullscreenControl={false}
          zoomControl={true}
          onClick={onMapClicked}
        >
          {orderList.length &&
           orderList.map((order, index) => {
            const startDate = order && order.store_on_going_slot && order.store_on_going_slot.start_time ? moment(order.store_on_going_slot.start_time, "YYYY-MM-DD hh:mm:ss").format("hh a"): "";
            const endDate = order && order.store_on_going_slot && order.store_on_going_slot.end_time ? moment(order.store_on_going_slot.end_time, "YYYY-MM-DD hh:mm:ss").format("hh a"): "";
    
            let markerIconUrl = require("../assets/new-location-marker.png");
            let textColor = "#277203"
            
            if(order.status === 3){
              markerIconUrl = require("../assets/accepted-location-marker.png");
              textColor = "#ffa500";
            }else if(order.status === 4){
              markerIconUrl = require("../assets/packed-location-marker.png");
              textColor = "#b87333";
            }else if(order.status === 5){
              markerIconUrl = require("../assets/delivering-location-marker.png");
              textColor = "#d58a94";
            } else if(order.status === 8){
              markerIconUrl = require("../assets/arrived-location-marker.png");
              textColor = "#b048b5";
            }
            return <Marker key={index}
            position={{ lat: order.list_customer_address.latitude, lng: order.list_customer_address.long }}
            name={"Location Marker"}
            onClick={onMarkerClick}
          >  
          </Marker>  
           })}
          <InfoWindow marker={activeMarker}
          onClose={onInfoWindowClose}
          visible={showingInfoWindow}>
                    {/* <div>
                      <div>{order.order_id} {order.customer_detail.name}</div>
                      {
                        order && order.store_on_going_slot?
                        <div>{startDate} - {endDate}</div>
                        :<div>ASAP</div>
                      }
                    </div> */}
                    <div>Test</div>
                </InfoWindow>
        </Map>
      </div>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBNXfan5vWyKWSbFUnnVOtjhcPdXafxQEk",
})(GoogleMapInfos);
