import React, { useContext, useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { openModal } from "@redq/reuse-modal";
import { Router } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import Drawer from "../../../components/Drawer/Drawer";
import Button from "../../../components/Button/Button";
import NavLink from "../../../components/NavLink/NavLink";
import Sidebar from "../../../containers/Sidebar/Sidebar";
import { CloseIcon, ArrowDropDown } from "../../../components/AllSvgIcon";
import { DrawerContext } from "../../../contexts/drawer/drawer.context";
import { AuthContext } from "../../../contexts/auth/auth.context";
import AuthenticationForm from "../../SignInOutForm/Form";
import { FormattedMessage } from "react-intl";
import {
  HamburgerIcon,
  DrawerContentWrapper,
  DrawerClose,
  DrawerProfile,
  LogoutView,
  LoginView,
  UserAvatar,
  UserDetails,
  DrawerMenu,
  DrawerMenuItem,
  UesrOptionMenu, LanguageList,
} from "./Header.style";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import UserImage from "../../../image/user.jpg";
import { isCategoryPage, isCommonPage } from "../is-home-page";

import {
  PROCEED_TO_CHECKOUT_PAGE,
  ALTERNATIVE_CHECKOUT_PAGE,
  PROFILE_PAGE,
  YOUR_ORDER,
  ORDER_RECEIVED,
  HELP_PAGE,
  OFFER_PAGE,
} from "../../../constants/navigation";
import queryString from "query-string";
import { connect, createDispatchHook } from "react-redux";
import {
  productCategory,
  ProductModalData,
  searchSuggestions,
  searchStoreProduct,
  getProductsMappedwithSubCat,
  getProductsMappedwithCat,
  getProductOutOfStock,
  getProductOutOfStockCat,
  getProductBrands,
  getProductFilterBrands,
  getSortProductsName,
  getSortProductsPrice,
  sortByNameProduct,
  Toggle_Suggestions,
  resetPage,
  changePage
} from "../../../store/actions/productAction";
import { offerSlider } from "../../../store/actions/globalAction";
import { useDeviceType } from "../../../helper/useDeviceType";
import { cats } from "../../../helper/constant";
import arrowBack from '../../../assets/icons/fi-back.svg';
import deliShopLogo from '../../../assets/icons/delishop_logo.svg';
import t from '../../../i18n/translate';
import enFlagIcon from "../../../assets/icons/united-kingdom.svg";
import kmFlagIcon from "../../../assets/icons/cambodia.svg";

const PAGE_TYPE = "grocery";
const DrawerMenuItems = [
  {
    id: 1,
    link: "/profile",
    label: t('profile_user_profile_button'),
    intlId: "navlinkProfile",
  },
  {
    id: 2,
    link: "/all-orders",
    label: t('home_menu_all_orders'),
    intlId: "navlinkAllOrders",
  },
  {
    id: 3,
    link: PROCEED_TO_CHECKOUT_PAGE,
    label: t('shop_check_out_button'),
    intlId: "navlinkCheckout",
  },
  // {
  //   id: 7,
  //   intlLabelId: 'navlinkHelp',
  //   label: 'Help',
  //   href: HELP_PAGE,
  // },
  // {
  //   id: 8,
  //   intlLabelId: 'navlinkOffer',
  //   label: 'Offer',
  //   href: OFFER_PAGE,
  // },
];

const useRouter = () => {
  const params = useParams();

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
    };
  }, [params, match, location, history]);
};

const MobileDrawer = (props, { userAgent }) => {
  const isHomePage = isCategoryPage(window.location.pathname);
  const cartType = useSelector(state => state.cartReducer.cartType);

  const { state, dispatch } = useContext(DrawerContext);
  const { query, history } = useRouter();
  const deviceType = useDeviceType(userAgent);
  const {
    authState: { isAuthenticated },
    authDispatch,
  } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [showprofile, setShowProfile] = useState(false);
  let parsedUser = localStorage.get;
  let mql = window.matchMedia("all and (max-width: 768px)");
  if (isAuthenticated) {
    let user = localStorage.getItem("user");
    parsedUser = JSON.parse(user);
  }
  const lang = localStorage.getItem('lang') || 'en';

  // Toggle drawer
  const toggleHandler = React.useCallback(() => {
    dispatch({
      type: "TOGGLE",
    });
  }, [dispatch]);

  const handleLogout = () => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.setItem("order_id",0);
      authDispatch({ type: "SIGN_OUT" });
      window.location.href = "/";
    }
  };
  const resetSearch = () => {
    dispatch({
      type: "RESET",
    });
  };

  const signInOutForm = () => {
    dispatch({
      type: "TOGGLE",
    });

    authDispatch({
      type: "SIGNIN",
    });
    setShow(true);
  };

  const targetRef = React.useRef(null);
  useEffect(() => {
    if (mql.matches) {
      if (window.location.pathname.indexOf("search") !== -1) {
        document.title = window.location.pathname.split("/")[2] + " - Delishop";
        document
          .getElementsByTagName("meta")
          .namedItem("description")
          .setAttribute(
            "content",
            window.location.pathname.split("/")[2] + " - Delishop"
          );
        document
          .getElementsByTagName("meta")
          .namedItem("title")
          .setAttribute(
            "content",
            window.location.pathname.split("/")[2] + " - Delishop"
          );

        // document
        //   .querySelector("link[rel='canonical']")
        //   .setAttribute("href", window.location.href);
      }
      else if (cats.includes(window.location.pathname.split("/")[1])){
        document.title = window.location.pathname.split("/")[1] + " - Delishop";
        if(!document.getElementsByTagName("meta")){
        document
          .getElementsByTagName("meta")
          .namedItem("description")
          .setAttribute(
            "content",
            window.location.pathname.split("/")[1] + " - Delishop"
          );
        document
          .getElementsByTagName("meta")
          .namedItem("title")
          .setAttribute(
            "content",
            window.location.pathname.split("/")[1] + " - Delishop"
          );
        }
      }
       else if (
        window.location.pathname.split("/").length > 1 &&
        window.location.pathname.split("/").length <= 2 &&
        window.location.pathname.split("/")[1] !== "" &&
        !isCommonPage(window.location.pathname.split("/")[1])
      ) {
        // document.title = window.location.pathname.split("/")[1] + " - Delishop";
        // document
        //   .getElementsByTagName("meta")
        //   .namedItem("description")
        //   .setAttribute("content", props.modalData.name + " - Delishop");
        // document
        //   .getElementsByTagName("meta")
        //   .namedItem("title")
        //   .setAttribute("content", props.modalData.name + " - Delishop");

        // document
        //   .querySelector("link[rel='canonical']")
        //   .setAttribute("href", window.location.href);
      } else if (window.location.pathname.split("/").length > 2) {
        document.title = window.location.pathname.split("/")[2] + " - Delishop";
        // document
        //   .querySelector("link[rel='canonical']")
        //   .setAttribute("href", window.location.href);
      } else if (
        window.location.pathname.split("/").length > 1 &&
        window.location.pathname.split("/").length <= 2 &&
        window.location.pathname.split("/")[1] !== "" &&
        isCommonPage(window.location.pathname.split("/")[1])
      ) {
        document.title = "Delishop - First Online Supermarket in Cambodia";
        document
          .getElementsByTagName("meta")
          .namedItem("description")
          .setAttribute(
            "content",
            "Get all your favorite products from the best shops of Phnom Penh. More than 10,000 products to choose from"
          );
        document
          .getElementsByTagName("meta")
          .namedItem("title")
          .setAttribute(
            "content",
            "Delishop - First Online Supermarket in Cambodia"
          );
        // document
        //   .querySelector("link[rel='canonical']")
        //   .setAttribute("href", window.location.href);
      } else {
        document.title = "Delishop - First Online Supermarket in Cambodia";
        document
          .getElementsByTagName("meta")
          .namedItem("description")
          .setAttribute(
            "content",
            "Get all your favorite products from the best shops of Phnom Penh. More than 10,000 products to choose from"
          );
        document
          .getElementsByTagName("meta")
          .namedItem("title")
          .setAttribute(
            "content",
            "Delishop - First Online Supermarket in Cambodia"
          );
        // document
        //   .querySelector("link[rel='canonical']")
        //   .setAttribute("href", window.location.href);
      }
    }
  }, [window.location.pathname]);
  useEffect(() => {
    if (isAuthenticated) {
      setShow(false);
    }
  }, [isAuthenticated]);
  useEffect(() => {
    // if ((query.text || query.category) && targetRef.current && mql.match) {
    //   window.scrollTo({
    //     top: targetRef.current.offsetTop - 110,
    //     behavior: "smooth",
    //   });
    // }
  }, [query]);
  useEffect(() => {
    if (mql.matches && window.location.pathname === "/") {
      props.productCategory({ get_type: 2, store_id: 1, lang });
      props.offerSlider({ store_id: 1 });
      props.getProductBrands();
    }
  }, []);
  useEffect(() => {
    if (
      props.category.length > 0 &&
      mql.matches &&
      window.location.pathname === "/"
    ) {
      let brandArr = [];
      localStorage.setItem("sub_cat_id",props.category[0].map_categories_sub_cats[0].list_sub_categories[0]
      .sub_cat_id);
      props.getProductOutOfStock({
        sub_cat_id:
          props.category[0].map_categories_sub_cats[0].list_sub_categories[0]
            .sub_cat_id,
        store_id: 1,
        page: 1
      });
      history.push({
        state: {
          cat_name: props.category[0].name,
          sub_cat_name:
            props.category[0].map_categories_sub_cats[0].list_sub_categories[0]
              .name,
        },
      });
    }
  }, [props.category]);

  const onChangeLanguage = (lang) => {
    if(localStorage.getItem('lang') !== lang) {
      localStorage.setItem('lang', lang);
      window.location.reload();
    }
  };

  return (
    <Drawer
      className="left-header"
      width="316px"
      drawerHandler={
        <HamburgerIcon>
          <span />
          <span />
          <span />
        </HamburgerIcon>
      }
      open={state.isOpen}
      toggleHandler={toggleHandler}
      closeButton={
        <DrawerClose>
          <CloseIcon />
        </DrawerClose>
      }
    >
      <DrawerContentWrapper>
        <DrawerProfile>
          {isAuthenticated ? (
            <>
              <LoginView
                onClick={(showprofile) => {
                  setShowProfile(!showprofile);
                }}
              >
                <UserAvatar>
                  <img src={UserImage} alt="user_avatar" />
                </UserAvatar>
                {parsedUser && (
                  <UserDetails>
                    <h3>
                      {parsedUser.first_name + " " + parsedUser.last_name}
                    </h3>
                    <span>
                      {"+" + parsedUser.country_code + "-" + parsedUser.phone}
                    </span>
                  </UserDetails>
                )}
              </LoginView>
            </>
          ) : (
            <LogoutView>
              <Button
                intlButtonId="mobileSignInButtonText"
                title={ t('profile_user_login_or_register') }
                size="small"
                className="sign_in"
                // variant="primary"
                onClick={signInOutForm}
              />
            </LogoutView>
          )}

          {cartType === 'express' && !window.location.pathname.includes('/express/store')
            ? <div className="flex-box">
              <div className="link-back-to-normal-shop" onClick={() => { localStorage.removeItem('expressMode'); window.location = '/'; }}>
                <img src={arrowBack} alt="Delishop Oneline Market arrow back" width="100%" height="28" />
                <div className="logo">
                  <span>{ t('forget_password_button_back_to') }</span>
                  <img src={deliShopLogo} alt="Delishop Oneline Market" width="100%" height="44" />
                </div>
              </div>
            </div>
            : null
          }
        </DrawerProfile>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w authModal"
          aria-labelledby="example-custom-modal-styling-title"
          backdrop="static"
        >
          <Modal.Header closeButton style={{ border: 0 }}></Modal.Header>
          <Modal.Body style={{ padding: 0 }}>
            <AuthenticationForm />
          </Modal.Body>
        </Modal>
        {isAuthenticated && (
          <UesrOptionMenu className="showaccount">
            <Accordion defaultActiveKey="1">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <span style={{ paddingLeft: "5px" }}>{ t('home_menu_account') }</span>
                  <div style={{ float: "right", paddingRight: "5px" }}>
                    <ArrowDropDown />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <DrawerMenu>
                      {DrawerMenuItems.map((item) => (
                        <DrawerMenuItem key={item.id}>
                          <NavLink
                            onClick={toggleHandler}
                            href={item.link}
                            label={item.label}
                            intlId={item.intlLabelId}
                            className="drawer_menu_item"
                          />
                        </DrawerMenuItem>
                      ))}
                      <DrawerMenuItem>
                        <div
                          onClick={handleLogout}
                          className="drawer_menu_item"
                        >
                          <span className="logoutBtn">
                            { t('profile_user_logout_button') }
                          </span>
                        </div>
                      </DrawerMenuItem>

                      <LanguageList>
                        <ul className="language-list">
                          <li className={`en ${ localStorage.getItem('lang') !== 'km' ? 'active' : ''  }`}
                              onClick={() => onChangeLanguage('en')}
                          >
                            <Image src={ enFlagIcon } alt="English Language" />
                          </li>
                          <li className={`km ${ (localStorage.getItem('lang') === 'km') ? 'active' : ''  }`}
                              onClick={() => onChangeLanguage('km')}
                          >
                            <Image src={ kmFlagIcon } alt="Khmer Language" />
                          </li>
                        </ul>
                      </LanguageList>

                    </DrawerMenu>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </UesrOptionMenu>
        )}
      </DrawerContentWrapper>
      <DrawerContentWrapper>
        {props.category.length > 0 && isHomePage ? (
          <DrawerProfile>
            <Sidebar
              type={"grocery"}
              deviceType={deviceType}
              props={props}
              categoreis={props.category}
            />
          </DrawerProfile>
        ) : null}
      </DrawerContentWrapper>
      {/* </Scrollbars> */}
    </Drawer>
  );
};

MobileDrawer.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};

const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    modalData: state.productReducer.modalData,
    allproducts: state.productReducer.allproducts,
    products: state.productReducer.products,
    category: state.productReducer.category,
    sliders: state.globalReducer.sliders,
    loading: state.globalReducer.loading,
    brands: state.productReducer.brands,
    filterBrands: state.productReducer.filterBrands,
    FilteredNameProducts: state.productReducer.FilteredNameProducts,
    filteredProducts: state.productReducer.filteredProducts,
    suggested_subcats: state.productReducer.suggested_subcats,
    all_suggested_subcats: state.productReducer.all_suggested_subcats,
    togglesuggestion: state.productReducer.togglesuggestion,
    page: state.productReducer.page,
    cat_refs: state.productReducer.cat_refs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productCategory: (data) => productCategory(data, dispatch),
    ProductModalData: (data) => ProductModalData(data, dispatch),
    searchSuggestions: (data) => searchSuggestions(data, dispatch),
    searchStoreProduct: (data) => searchStoreProduct(data, dispatch),
    getProductsMappedwithSubCat: (data) =>
      getProductsMappedwithSubCat(data, dispatch),
    getProductsMappedwithCat: (data) => getProductsMappedwithCat(data, dispatch),
    offerSlider: (data) => offerSlider(data, dispatch),
    getProductOutOfStock: (body) => getProductOutOfStock(body, dispatch),
    getProductOutOfStockCat: (body) => getProductOutOfStockCat(body, dispatch),
    getProductBrands: () => getProductBrands(dispatch),
    getSortProductsName: (type, products) =>
      getSortProductsName(type, products, dispatch),
    getSortProductsPrice: (type, products) =>
      getSortProductsPrice(type, products, dispatch),
    getProductFilterBrands: (body, products, allproducts, sub_cat_id, cat_id, oos, text) =>
      getProductFilterBrands(body, products, allproducts, sub_cat_id, cat_id, oos, text, dispatch),
    sortByNameProduct: (type, products) =>
      sortByNameProduct(type, products, dispatch),
    Toggle_Suggestions: (data) => Toggle_Suggestions(data, dispatch),
    changePage: (body) => changePage(body, dispatch),
    resetPage: () => resetPage(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileDrawer);

// export default MobileDrawer;
