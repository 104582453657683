import React, { useState } from "react";
const Checkbox = ({id,bulkSelection}) => {
    const [isChecked, setChecked] = useState(false);
    const handleChange = (e) => {
       // console.log("chala")
        setChecked(e.target.checked);
         bulkSelection(id)
      }    
    return (
        <input
        type="checkbox"
        style={{ marginRight: 5 }}
        checked={isChecked}
        onChange={handleChange}
      />
    )
}
export default Checkbox;