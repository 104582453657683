import React, { useContext, useState, useEffect } from "react";
import { closeModal } from "@redq/reuse-modal";
import TextField from "../../../components/TextField/TextField";
import Button from "../../../components/Button/Button";
import { Heading } from "../../CheckoutWithSidebar/Update/Update.style";

const UpdateProfile = (props) => {
  let user = localStorage.getItem("user");
  let parsedUser = JSON.parse(user);
  const [first_name, setFirstName] = useState(parsedUser.first_name);
  const [email, setEmail] = useState(parsedUser.email);
  const [last_name, setLastName] = useState(parsedUser.last_name);
  const [mobile_number, setMobile] = useState(parsedUser.phone);
  const [country_code, setCountry_code] = useState(parsedUser.country_code);
  const handleSubmit = async (e) => {
    e.preventDefault();

    let body = {
      cust_id: parsedUser.cust_id,
      first_name,
      last_name,
      phone: mobile_number,
      email,
      country_code,
    };
    props.props.updateProfile(body);
    closeModal();
  };

  function isNumber(evt) {
    let telephone = evt.target.value;
    let regexp = /^[0-9\+\b]+$/;
    if (regexp.test(telephone)) {
      setMobile(telephone);
    }
  }
  return (
    <>
      <form>
        <Heading>
          {props.type !== "add" ? "Edit Profile" : "Edit Profile"}
        </Heading>

        <div className="row">
          <div className="col-sm-6 pb-2 pr-0">
            <TextField
              type="text"
              placeholder="First Name"
              value={first_name}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </div>
          <div className="col-sm-6">
            <TextField
              type="text"
              placeholder="Last Name"
              value={last_name}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2 pb-2 pr-0">
            <TextField
              type="text"
              value={country_code}
              onChange={(e) => {
                setCountry_code(e.target.value);
              }}
            />
          </div>
          <div className="col-sm-4 pb-3 pr-0">
            <TextField
              type="text"
              placeholder="Mobile"
              value={mobile_number}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
              maxLength={14}
            />
          </div>
          <div className="col-sm-6">
            <TextField
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              disabled
            />
          </div>
        </div>

        <Button
          onClick={handleSubmit}
          type="submit"
          title="Save"
          size="medium"
          fullwidth={true}
          style={{ marginBottom: 15 }}
        />
      </form>
    </>
  );
};
export default UpdateProfile;
