import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const ProductCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  background-color: #fff;
  position: relative;
  font-family: "Lato", sans-serif;
  border-radius: 6px;
  cursor: pointer;
  
  h3.product-title {
    display: block;
    font-weight: bold;
  }
`;
export const ProductPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  flex-shrink: 0;
  // margin-left: 25px;
  line-height: 31px;

  @media (max-width: 767px) {
    // margin-left: 15px;
  }
`;
export const ProductPrice = styled.div`
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.primary", "#389244")};
  &.express { color: #f01266; }
  .promo-price {
    font-size: 24px !important;
    @media (max-width: 767px) {
      font-size: 19px !important;
    }
  }
  span.express { color: #f01266; }
`;

export const PromoPrice = styled.div`
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.yellow", "#FBB979")};
  // padding-left: 10px;
  .promo-price {
      
    &.express{
      font-size: 1rem !important;
      line-height: 2rem;
      color: #FBB979 !important;
    }
    
    @media (max-width: 767px) {
      font-size: 19px !important;
    }
  }
`;

export const SalePrice = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.yellow", "#FBB979")};
  font-style: italic;
  padding: 0 5px;
  overflow: hidden;
  position: relative;
  margin-right: 10px;

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    display: inline-block;
    background-color: ${themeGet("colors.yellow", "#FBB979")};
    position: absolute;
    top: 50%;
    left: 0;
  }
`;
export const BannersContainer = styled.div`
   position: relative;
  text-align: center;
  display: flex;
  // align-items: center;
  // justify-content: center;
  flex-direction: column;
`;
export const ProductImageWrapper = styled.div`
  width: 100%;
  min-height: 210px;
  padding: 0px 5px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .product-image{
    width: auto;
    max-width: 220px;
    height: 100%;
    min-height: 100px;
    max-height: 210px;
  }
  .promo-image{
    height: 100%;
    width: auto;
    max-width: 100% !important;
    max-height: 400px;
    min-height: 400px;
  }

  .ribbon {
    text-transform: uppercase;
    color: white;
  }
  .ribbon3 {
    font-size: 11px;
    width: 100%;
    max-width: 80px;
    height: 23px;
    line-height: 23px;
    padding-left: 0px;
    position: absolute;
    left: -8px;
    top: 20px;
    background: #389244;
    z-index: 2;
    
    &.full-width {
      height: 23px;
      max-width: 110px;
      background: #f78205;
      
      span.ribbon3-duplicate {
        height: 0;
        width: 0;
        right: -10.5px;
        top: 0;
        border-top: 12px solid #f78205;
        border-bottom: 11px solid #f78205;
        border-left: 0px solid #f78205;
        border-right: 11px solid transparent;
        position: absolute;
      }
      &:after {
        border-top: 12px solid #f78205;
        border-bottom: 11px solid #f78205;
        border-left: 0px solid #f78205;
      }
    }
  }
  &.express span.ribbon3 {
    background: #f01267;
    
    &.full-width span.ribbon3-duplicate {
      border-top: 12px solid #f78205;
      border-bottom: 11px solid #f78205;
      border-left: 0px solid #f78205;
    }
    
    &:after {
      border-top: 12px solid #f01267;
      border-bottom: 11px solid #f01267;
      border-left: 0px solid #f01267;
    }
  }
  
  .ribbon3:before,
  .ribbon3:after {
    content: "";
    position: absolute;
  }
  .ribbon3:before {
    height: 0;
    width: 0;
    bottom: -8.5px;
    left: 0.1px;
    border-top: 9px solid black;
    border-left: 9px solid transparent;
  }
  .ribbon3:after {
    height: 0;
    width: 0;
    right: -10.5px;
    border-top: 12px solid #389244;
    border-bottom: 11px solid #389244;
    border-left: 0px solid #389244;
    border-right: 11px solid transparent;
  }
  .ribbon4 {
    font-size: 11px;
    width: 80px;
    height: 23px;
    line-height: 23px;
    padding-left: 0px;
    position: absolute;
    left: -8px;
    top: 50px;
    background: #FF0000;

  }
  .ribbon4.custom {
    top: 20px;
  }
  .ribbon4:before,
  .ribbon4:after {
    content: "";
    position: absolute;
  }
  
  .ribbon4:before {
    height: 0;
    width: 0;
    bottom: -8.5px;
    left: 0.1px;
    border-top: 9px solid black;
    border-left: 9px solid transparent;
  }
  
  .ribbon4:after {
    height: 0;
    width: 0;
    right: -10.5px;
    border-top: 12px solid #FF0000;
    border-bottom: 11px solid #FF0000;
    border-left: 0px solid #389244;
    border-right: 11px solid transparent;
  }
  @media (max-width: 1400px) {
    .product-image{
      max-width: 200px;
    }
  }
  @media (max-width: 767px) {
    .product-image{
      height: 125px;
      width: auto;
      max-height: 125px;
    }
    .promo-image{
      height: 200px;
      width: auto;
      max-height: 200px;
      min-height: 200px;
    }
    .bigloader {
      background-color: white;
      position: absolute;
      top: calc(100% - 72px);
      right: calc(100% - 95px);
      z-index: 1000;
    }
    .loaderStyle {
      position: absolute;
      top: calc(100% - 115px);
      right: calc(100% - 120px);
      z-index: 1000;
    }
  }
`;

export const SaleTag = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: #ffffff;
  background-color: ${themeGet("colors.yellow", "#FFAD5E")};
  padding: 0 10px;
  line-height: 24px;
  border-radius: 12px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const DiscountPercent = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: #ffffff;
  line-height: 24px;
  background-color: ${themeGet("colors.yellow", "#FFAD5E")};
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 12px;
  z-index: 2;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 15px 10px;
  width: 100%;

  @media (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 170px;
  }

  .product-title {
    font-family: "Lato", sans-serif;
    color: ${themeGet("colors.darkBold", "#0D1136")};
    display: block;
    line-height: 1.5;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    overflow: hidden;
    width: 100%;
    max-width: 280px;
    height: 45px;
    text-overflow: ellipsis;

    @media (max-width: 767px) {
      font-size: 14px;
      margin: 0 0 -5px 0;
    }
  }

  .promo-title {
    font-size: 24px;
    height: 68px;
    @media (max-width: 767px) {
      font-size: 19px;
      height: 55px;
      margin: 0 0 -5px 0;
    }
  }

  .product-weight {
    margin-top: 0;
    font-family: "Lato", sans-serif;
    font-size: ${themeGet("fontSizes.1", "13")}px;
    font-weight: ${themeGet("fontWeights.3", "400")};
    color: ${themeGet("colors.darkRegular", "#77798c")};

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .promo-weight {
    font-size: 21px;
    @media (max-width: 767px) {
      font-size: 17px;
    }
  }

  .promo-price {
    font-size: 1rem !important;
    line-height: 3.3rem;
  }

  .product-meta {
    width: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-end;
    justify-content: space-between;
    
    .product-weight-and-offer {
      display: block;
    }
    
    .btn-outline-success {
      background-color: transparent;
      color: #389244;
      border: 1px solid #389244;
      border-radius: 25px;
      font-weight: 400;
    }
    
    .btn-count {
      padding-top: 0;
      padding-bottom: 2px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .btn-input {
      border-left: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
    }
    
    @media (max-width: 767px) {
      min-height: 32px;
      flex-direction: column;
      align-items: flex-start;
    }

    .productPriceWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .product-price {
        font-family: "Lato", sans-serif;
        font-size: ${themeGet("fontSizes.2", "15")}px;
        font-weight: ${themeGet("fontWeights.6", "700")};
        color: ${themeGet("colors.primary", "#389244")};

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }

      .discountedPrice {
        font-family: "Lato", sans-serif;
        font-size: ${themeGet("fontSizes.1", "13")}px;
        font-weight: ${themeGet("fontWeights.3", "400")};
        color: ${themeGet("colors.yellow", "#FBB979")};
        font-style: italic;
        padding: 0 5px;
        position: relative;
        overflow: hidden;
        position: absolute;
        top: -20px;
        left: -4px;

        &:before {
          content: "";
          width: 100%;
          height: 1px;
          display: inline-block;
          background-color: ${themeGet("colors.yellow", "#FBB979")};
          position: absolute;
          top: 50%;
          left: 0;
        }
      }
    }
    &.express .cart-button:hover,
    &.express .cart-button:active,
    &.express .cart-button:focus,
    &.express .cart-button {
      color: #FFFFFF;
      background-color: #f01266;
      border-color: #f01266;
      box-shadow: none;
    }
    &.express span[itemprop="price"] { color: #f01266; }

    .cart-button {
      border: 2px solid #f7f7f7;
      border-radius: 18px;
      width: 100%;
      max-width: 140px;
      height: 36px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: ${themeGet("fontSizes.1", "13")}px;
      font-weight: ${themeGet("fontWeights.6", "700")};

      .btn-text {
        padding: 0 0 0 6px;
        @media (max-width: 767px) {
          display: none;
        }
      }
      &:hover {
        color: #fff;
        background-color: #389244;
        border-color: #389244;
      }
      &.express:hover {
        background-color: #f01266;
        border-color: #f01266;
      }
      svg {
        fill: currentColor;
      }
    }
    
    .promo-cart-button {
      margin-bottom: 10px;
    }

    @media (max-width: 767px) {
      .quantity {
        width: 32px;
        height: 90px;
        display: block;
        flex-shrink: 0;
        position: absolute;
        bottom: 15px;
        right: 15px;
        z-index: 1;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
      }
      button {
        width: 100%;
        height: 27px;
      }
      .incBtn {
        top: 0;
        justify-content: center;
      }
      .decBtn {
        top: auto;
        bottom: 0;
        justify-content: center;
      }
      input[type="number"] {
        left: 0;
        color: #222222;
        font-size: 14px;
        height: calc(100% - 54px);
        position: absolute;
        top: 27px;
        width: 100%;
        color: #fff;
      }
    }
  }
`;

export const BookImageWrapper = styled.div`
  height: 275px;
  padding: 0;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  img {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
  }

  @media (max-width: 767px) {
    height: 215px;
  }

  ${DiscountPercent} {
    top: 0;
    right: 0;
  }
`;

export const BookInfo = styled.div`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    padding: 15px 0px 0px;
  }
`;

export const ProductName = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.2", "15")}px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.darkBold", "#0D1136")};
  margin: 0 0 7px 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  display: block;

  &:only-child {
    margin: 0;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    margin: 0 0 5px 0;
  }
`;

export const AuthorInfo = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.darkRegular", "#77798c")};

  @media (max-width: 767px) {
    font-size: ${themeGet("fontSizes.1", "13")}px;
  }
`;

// export const AddCartBox = styled.div`
//   width: calc(100% - 40px);
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   padding: 20px;
//   border-radius: 6px;
//   background-color: #ffffff;
//   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   opacity: 0;
//   transition: all 0.3s;

//   .cart-button {
//     border-radius: 18px;
//     height: 36px;
//     padding-left: 17px;
//     padding-right: 17px;
//     font-size: ${themeGet('fontSizes.1', '13')} px;
//     font-weight: ${themeGet('fontWeights.6', '700')};
//     @media (max-width: 767px) {
//       width: 32px;
//       height: 32px;
//       padding: 0;
//       border-radius: 50%;
//     }
//     .btn-text {
//       padding: 0 0 0 6px;
//       @media (max-width: 767px) {
//         display: none;
//       }
//     }
//     &:hover {
//       color: #fff;
//       background-color: #389244;
//       border-color: #389244;
//     }
//     svg {
//       fill: currentColor;
//     }
//   }
// `;

export const PriceWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
`;

export const Price = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.2", "15")} px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.primary", "#389244")};

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

export const DiscountedPrice = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")} px;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.yellow", "#FFAD5E")};
  font-style: italic;
  padding: 0 5px;
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
  margin-left: -4px;
  z-index: 2;

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    display: inline-block;
    background-color: ${themeGet("colors.yellow", "#FFAD5E")};
    position: absolute;
    top: 50%;
    left: 0;
  }
`;

export const BookCardWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 30px;
  background-color: #fff;
  position: relative;
  font-family: "Lato", sans-serif;
  border-radius: 6px;
  cursor: pointer;

  @media (max-width: 767px) {
    padding: 15px;
  }
`;

export const FoodCardWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 0;
  background-color: #fff;
  position: relative;
  font-family: "Lato", sans-serif;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

export const FoodImageWrapper = styled.div`
  height: 230px;
  padding: 0;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  @media (max-width: 767px) {
    height: 145px;
  }
`;

export const ProductMeta = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DeliveryOpt = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.darkBold", "#0D1136")};
  white-space: nowrap;
`;

export const Category = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.darkRegular", "#77798c")};
`;

export const Duration = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: #ffffff;
  background-color: ${themeGet("colors.primary", "#389244")};
  border-radius: 18px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  height: 36px;
  width: auto;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

export const BrandLogoWrapper = styled.div`
  height: 60px;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  border: 1px solid rgb(236,236,236);
  img {
    display: inline-block;
    height: 58px;
    width: 58px;
    border-radius: 50%;
  }
  .brand-image {
    max-height: 58px;
    width: auto;
  }
  .brand-promo {
    max-height: 70px;
    width: auto;
  }

  @media (max-width: 767px) {
    height: 52px;
    img {
      max-height: 50px;
      width: auto;
    }
    .brand-image{
      max-height: 50px;
      width: auto;
    }
    .brand-promo {
      max-height: 60px;
      width: auto;
    }
  }
`;
