import React , { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import Table from "rc-table";
import Collapse, { Panel } from "rc-collapse";
import Progress from "../../../../components/ProgressBox/ProgressBox";
import {
  OrderListHeader,
  TrackID,
  Status,
  Reorder,
  OrderMetas,
  Meta,
  CardWrapper,
  OrderDetail,
  DeliveryInfo,
  DeliveryAddress,
  Address,
  CostCalculation,
  PriceRow,
  Price,
  ProgressWrapper,
  OrderTable,
  OrderTableMobile, SingleOrderList,
} from "./OrderCard.style";

import { CURRENCY } from "../../../../helper/constant";
import { convertDate, convertTime24to12 } from "../../../../constants/Constant";
import t from "../../../../i18n/translate";
import SimpleMessageDialog from "../../../../components/SimpleMessageDialog/SimpleMessageDialog";
import { useCart } from "../../../../contexts/cart/use-cart";
import { FormattedNumber } from 'react-intl';
import itemToCartService from "../../../../services/itemToCartService";
import * as actionTypes from "../../../../store/ActionTypes";

const components = {
  table: OrderTable,
};
/*
 * status = 0 => Order Saved
 * status = 1 => Order Removed
 * status = 2 => Order Pending
 * status = 3 => Order Processing
 * status = 4 => Order Pick Up
 * status = 5 => Order Delivering
 * status = 6 => Order Delivered
 * status = 7 => Order Canceled
 * status = 8 => Order Arrived
 */
const statusData = ["Saved", "Removed", "Pending", "Processing", "Pick Up", "Delivering", "Delivered", "Canceled", "Arrived"];

const OrderCard = ({
  onClick,
  progressStatus,
  progressData,
  className,
  columns,
  order,
  type,
  onError,
  onSuccess,
  props,
  pageType,
}) => {
  const addAllClasses = ["accordion"];
  const { items } = useCart();

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [closeLabel, setCloseLabel] = useState('');
  const [confirmLabel, setConfirmLabel] = useState('');
  const [dialogHeaderLabel, setDialogHeaderLabel] = useState(t('shop_checkout_alert_error'));
  const [simpleDialogBackdrop, setSimpleDialogBackdrop] = useState(true);
  const [isConfirmButton, setIsConfirmButton] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  if (className) { addAllClasses.push(className) }
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // What they want to do in mobile version when user click on order detail area
    // props.fetchParticularOrder({ order_id: order.order_id });
  };

  const dispatch = useDispatch();
  const handleReOrder = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const currentOrder = props[type].find(item => item.order_id === order.order_id);

    const isExpressMode = props.cartType === 'express';
    setCloseLabel( t('button_close_label') );

    if(currentOrder.type && !isExpressMode) {
      setErrorMessage( t('shop_order_detail_switch_to_express_mode_message_for_reorder') );
      setShowErrorDialog(true);
    }
    else if(isExpressMode && !currentOrder.type) {
      setErrorMessage( t('shop_order_detail_switch_to_normal_mode_message_for_reorder') );
      setShowErrorDialog(true);
    }
    else {
      // If user have some items in current basket
      if(items.length) {
        setErrorMessage( t('shop_order_detail_re_order_error') );
        setConfirmLabel( t('shop_item_detail_confirm_button') );
        setCloseLabel( t('shop_tracking_order_cancel_button') );
        setIsConfirmButton(true);
        setShowErrorDialog(true);
      }
      else {
        setIsFetching(true);
        await reOrderValidator();
      }
    }
  }

  const reOrderValidator = async () => {
    if(!isFetching) {
      const dataResp = await itemToCartService.reOrder(order.order_id);
      if(dataResp.status === 401) {
        setErrorMessage( dataResp.data.message );
        setIsConfirmButton(false);
        setShowErrorDialog(true);
      }
      else if(dataResp.status === 400) {
        onError(dataResp.data.message);
        setShowErrorDialog(false);
      }
      else if(dataResp.status === 200) {
        if(!props.reordersuccess) {
          dispatch({
            type: actionTypes.Reorder,
            reordersuccess: true
          });
        }
        onSuccess(dataResp.data);
        setShowErrorDialog(true);
      }
      setIsFetching(true);
    }
  }

  const onConfirm = async () => {
    setIsLoadingDialog(true);
    await reOrderValidator();
    setIsLoadingDialog(false);
  }
  const closeSimpleDialog = () => {
    setShowErrorDialog(false);
    setIsConfirmButton(false);
    setIsLoadingDialog(false);
    setErrorMessage('');
    setConfirmLabel('');
    setCloseLabel('');
  }

  useEffect(() => {
    if(props.reordersuccess) {
      closeSimpleDialog();
    }
  }, [props.reordersuccess]);

  return (
    <>
      <Collapse
        accordion={true}
        className={addAllClasses.join(" ")}
        defaultActiveKey="active"
        expandIcon={() => {}}
      >
        <Panel
          header={
            <CardWrapper
              onClick={handleClick}
            >
              <OrderListHeader>
                <TrackID>
                  { t('shop_order_list_screen_title') } <span>#{order.order_id}</span>
                </TrackID>
                {localStorage.getItem("user") && <Reorder onClick={handleReOrder}>
                  { t('shop_order_list_re_order_label') }
                </Reorder>}
                <Status>
                  { t(`shop_order_list_status_${ statusData[progressStatus].replace(' ', '_').toLowerCase() }`) }
                </Status>
              </OrderListHeader>

              <OrderMetas>
                { order.type
                  ? <Meta>
                    { t('shop_order_tracking_list_full_status_title') }
                    <span style={{color: '#f01266'}}>Express</span>
                  </Meta>
                  : null
                }
                <Meta>
                  { t('shop_order_list_order_date_title') }
                  <span>
                    {convertDate(order.placedAt.split(":")[0].split(" ")[0])}
                  </span>
                </Meta>
                {order.order_payment_details[0].coupon_discount_amount
                  ? <Meta>
                    { t('shop_checkout_discount') }
                    <span>
                    <FormattedNumber value={order.order_payment_details[0].coupon_discount_amount} style="currency" currency="USD" />
                  </span>
                  </Meta>
                  : null
                }
                {order.order_payment_details[0].wallet_discount_amount
                  ? <Meta>
                    { t('shop_checkout_wallet') }
                    <span>
                    <FormattedNumber value={order.order_payment_details[0].wallet_discount_amount} style="currency" currency="USD" />
                  </span>
                  </Meta>
                  : null
                }
                {order.order_payment_details[0].coupon_discount_amount || order.order_payment_details[0].wallet_discount_amount
                  ? <Meta className="price">
                    { t('shop_checkout_subtotal') }
                    <span>
                    <FormattedNumber value={order.order_payment_details[0].item_total} style="currency" currency="USD" />
                  </span>
                  </Meta>
                  : null
                }
                <Meta className="price">
                  { t('shop_checkout_delivery') }
                  <span>
                    <FormattedNumber value={order.order_payment_details[0].delivery_fee} style="currency" currency="USD" />
                  </span>
                </Meta>
                <Meta className="price">
                  { t('shop_order_list_total_title') }
                  <span>
                    <FormattedNumber value={order.order_payment_details[0].to_pay} style="currency" currency="USD" />
                  </span>
                </Meta>
              </OrderMetas>
            </CardWrapper>
          }
          headerClass="accordion-title"
          key={order.order_id}
        >
          {order && order !== undefined && order.list_customer_addresses !== undefined && (
              <OrderDetail>
                <DeliveryInfo>
                  <DeliveryAddress>
                    <h3>{ t('setting_shop_addresses') }{" "}</h3>
                    <Address>
                      { t('shop_address_list_name_label') }{" "}
                      {order.list_customer_addresses[0]
                        .first_name +
                        " " +
                      order.list_customer_addresses[0]
                          .last_name}
                    </Address>
                    <Address>
                      { t('shop_address_list_phone_abbreviation_label') }:{" "}
                      {"+" +
                      order.list_customer_addresses[0]
                          .country_code +
                        "-" +
                      order.list_customer_addresses[0]
                          .mobile_number}
                    </Address>
                    <Address>
                      {
                        order.list_customer_addresses[0]
                          .area_details
                      }
                    </Address>
                  </DeliveryAddress>

                  <DeliveryAddress>
                    <h3>{ t('shop_order_list_delivery_slot_label') }</h3>
                    <Address>
                      {convertDate(
                        order.store_on_going_slots[0]
                          .Date
                      ) +
                        " | " +
                        convertTime24to12(
                          order.store_on_going_slots[0].start_time.split(
                            " "
                          )[1]
                        ) +
                        " - " +
                        convertTime24to12(
                          order.store_on_going_slots[0].end_time.split(
                            " "
                          )[1]
                        )}
                    </Address>
                    {order.comments ? (
                      <>
                        <h3 className="mt-2">{ t('comment_screen_title') }</h3>
                        <Address>
                          {order.comments}
                        </Address>
                      </>
                    ) : null}
                    {/* <Address>{props.fetch_particular_order.list_customer_addresses[0].area_details}</Address> */}
                  </DeliveryAddress>

                  <CostCalculation>
                    <PriceRow>
                      { t('shop_checkout_subtotal') }
                      <Price>
                        {CURRENCY}
                        {
                          order.order_payment_details[0].item_total
                        }
                      </Price>
                    </PriceRow>
                    <PriceRow>
                      { t('shop_checkout_delivery') }
                      <Price>
                        {CURRENCY}
                        {
                          order.order_payment_details[0]
                            .delivery_fee
                        }
                      </Price>
                    </PriceRow>
                    <PriceRow className="grandTotal">
                      { t('shop_order_list_total_title') }
                      <Price>
                        {CURRENCY}
                        {
                          parseFloat(order.order_payment_details[0]
                            .to_pay).toFixed(2)
                        }
                      </Price>
                    </PriceRow>
                  </CostCalculation>
                </DeliveryInfo>
                {pageType == "current-order" ? (
                  <ProgressWrapper>
                    <Progress
                      data={progressData}
                      cartType={props.cartType}
                      status={
                        (progressStatus === 7
                        ? 7 
                        : ( [2,3].includes(progressStatus)
                          ? progressStatus - 2
                          : ([5,6].includes(progressStatus)
                            ? 1 
                            : 2 
                            )
                          )
                        )
                      }
                    />
                  </ProgressWrapper>
                ) : null}

                <OrderTableMobile>
                  <Table
                    columns={columns}
                    data={props.fetch_particular_order.order_item_details}
                    rowKey={(record) => record.order_item_id}
                    components={components}
                    scroll={{ x: 450 }}
                  />
                </OrderTableMobile>
              </OrderDetail>
            )}
        </Panel>
      </Collapse>

      <SimpleMessageDialog show={showErrorDialog}
                           message={errorMessage}
                           showHeader={true}
                           closeLabel={closeLabel}
                           confirmLabel={confirmLabel}
                           headerLabel={dialogHeaderLabel}
                           isConfirmButton={isConfirmButton}
                           onClose={closeSimpleDialog}
                           onConfirm={onConfirm}
                           loading={isLoadingDialog}
                           backdrop={simpleDialogBackdrop}
                           cartType={props.cartType}
      />
    </>
  );
};

export default OrderCard;
