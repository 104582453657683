import React from "react";
import OrderRecivedPage from "../containers/OrderReceived/OrderReceived";

class OrderRecived extends React.Component {
  render() {
    return (
      <>
        <OrderRecivedPage />
      </>
    );
  }
}

export default OrderRecived;
