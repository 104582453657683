import React from 'react';
import moment from 'moment';

const groupBy = (key = null, list = []) => {
  let newList = {};
  let keyArr = [];

  if(key === null) {
    alert('No key provided');

    return list;
  } else {
    list.forEach(item => {
      let itemKey = item[key];

      if(key === 'start_time') {
        itemKey = moment(item[key]).format('yyyy-MM-DD');
      }

      if(!keyArr.includes(itemKey)) {
        keyArr.push(itemKey);
        newList[itemKey] = [];
      }

      newList[itemKey].push(item);
    });

    return newList;
  }
};

const groupByOrderIdAndStartTime = (list) => {
  const newItems = [];
  const groupByDate = groupBy('start_time', list);

  if(Object.keys(groupByDate).length) {
    const dateItem = Object.keys(groupByDate);
    dateItem.sort();
    dateItem.reverse();

    dateItem.forEach(item => {
      newItems.push(groupBy('order_id', groupByDate[item]));
    });
  }

  return newItems;
};

const filterItemsBetween = (start_date, end_date, list = []) => {

  const data = list.map(items => {
    items.start_time = moment.utc(moment(items.start_time)).format();
    items.start_time = items.start_time.replace(':00Z', ':00.000Z');

    return {
      ...items
    }
  });

  // Start Date/Time
  start_date = moment.utc(moment(start_date)).format();
  start_date = start_date.replace(':00Z', ':00.000Z');

  // End Date/Time
  end_date = moment.utc(moment(end_date)).format();
  end_date = end_date.replace(':00Z', ':00.000Z');

  const ed = new Date(end_date).getTime();
  const sd = new Date(start_date).getTime();

  return data.filter(item => {
    const time = new Date(item.start_time).getTime();
    return (sd < time && time < ed);
  });
};

const imageOrientation = (src) => {

  let orientation,
      img = new Image();
      img.src = src;

  if (img.naturalWidth > img.naturalHeight) {
    orientation = 'landscape';
  } else if (img.naturalWidth < img.naturalHeight) {
    orientation = 'portrait';
  } else {
    orientation = 'even';
  }
  return orientation;
}

export default {
  groupBy,
  groupByOrderIdAndStartTime,
  filterItemsBetween,
  imageOrientation,
};
