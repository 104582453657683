import * as actionTypes from "../ActionTypes";

export const initExpressStores = (data, dispatch) => {
  dispatch({
    type: actionTypes.EXPRESS_STORES,
    payload: data,
  });
};

export const initExpressStoreSelected = (data, dispatch) => {
  dispatch({
    type: actionTypes.EXPRESS_STORE_SELECTED,
    payload: data,
  });
};

export const initExpressStoreProducts = (data, dispatch) => {
  dispatch({
    type: actionTypes.EXPRESS_STORE_PRODUCTS,
    payload: data,
  });
};

export const initExpressStoreSliders = (data, dispatch) => {
  dispatch({
    type: actionTypes.EXPRESS_STORE_SLIDERS,
    payload: data,
  });
};

export const initCategoryExpressStore = (data, dispatch) => {
  dispatch({
    type: actionTypes.Product_Category,
    payload: data.data,
    refs: data.data.map((element, index) => {
      return element.cat_ref_id
    }),
    success: data.success,
  });
};

export const initCategoryFilter = (data, dispatch) => {
  dispatch({
    type: actionTypes.Filter_Brands,
    payload: data
  });
};

export const sortProducts = (data, dispatch) => {
  dispatch({
    type: actionTypes.Filtered_Name_Products,
    payload: data,
  });
};

export const initProductBrand = (data, dispatch) => {
  dispatch({
    type: actionTypes.Product_Brands,
    payload: data,
  });
};

export const initExtraBrands = (data, dispatch) => {
  dispatch({
    type: actionTypes.EXPRESS_EXTRA_BRANDS,
    payload: data,
  });
};
