import React, { useEffect, Suspense } from "react";
import {
    BrandPageWrapper,
} from "../containers/AllBrands/AllBrands.style";
import {
  productCategory,
  ProductModalData,
  searchSuggestions,
  searchStoreProduct,
  getProductsMappedwithSubCat,
  getProductsMappedwithCat,
  getProductOutOfStock,
  getProductOutOfStockCat,
  getProductBrands,
  getProductFilterBrands,
  getSortProductsName,
  getSortProductsPrice,
  sortByNameProduct,
  Reset_Suggestions,
  changePage,
  resetPage
} from "../store/actions/productAction";
import { offerSlider } from "../store/actions/globalAction";
import {
  oosModified,
} from "../store/actions/globalAction";
import Products from "../containers/Products/Products";
import AllBrands from "../containers/AllBrands/AllBrands";
import Sidebar from "../containers/Sidebar/Sidebar";
// import Products from "../containers/Products/Products";
import CartPopUp from "../containers/Cart/CartPopUp";
import LazyLoader from "../components/LazyLoader/LazyLoader";
import {
  MainContentArea,
  SidebarSection,
  ContentSection
} from "../styled/pages.style";
// import { SearchContext } from "../contexts/search/search.context";
import { useDeviceType } from "../helper/useDeviceType";
import { connect } from "react-redux";
const PAGE_TYPE = "grocery";
const BrandsPage = (props, { userAgent }) => {
  const deviceType = useDeviceType(userAgent);
  let mql = window.matchMedia("all and (min-width: 769px)");
  let mqs = window.matchMedia("all and (max-width: 768px)");
  // const { state, searchdispatch } = React.useContext(SearchContext);
  const targetRef = React.useRef(null);
  const lang = localStorage.getItem('lang') || 'en';

  useEffect(()=>{
    props.productCategory({ get_type: 2, store_id: 1, lang });
    props.getProductBrands();
  },[]);
  return (
    <>
      {window.location.pathname === "/brands" && 
        <BrandPageWrapper>
          <AllBrands props={props}>
          </AllBrands>
        </BrandPageWrapper>
      }
      
      {window.location.pathname.split("/").indexOf("search") >= 0 && 
        <div>
        {deviceType.desktop ? (
          <>
            <MainContentArea
              style={{ paddingTop: mql.matches ? "9vh" : "0px" }}
            >
              <SidebarSection id="sidebarBlock">
                {props.category.length > 0 ? (
                  <Sidebar
                    type={PAGE_TYPE}
                    deviceType={deviceType}
                    props={props}
                    categoreis={props.category}
                  />
                ) : null}
              </SidebarSection>
              <ContentSection id="productBlock">
                <div ref={targetRef}>
                  <Suspense fallback={<LazyLoader/>}>
                  <Products
                    type={PAGE_TYPE}
                    deviceType={deviceType}
                    fetchLimit={16}
                    props={props}
                  />
                  </Suspense>
                </div>
              </ContentSection>
            </MainContentArea>
          </>
        ) : (
          <MainContentArea style={{ paddingTop: mqs.matches ? "9vh" : "0px" }}>
            {/* <StoreNav items={storeType} /> */}
            {/* {props.sliders.length > 0 ? (
              <OfferSection>
                <div className="offer-mobile">
                  <Carousel deviceType={deviceType} data={props.sliders} />
                </div>
              </OfferSection>
            ) : null} */}
            <ContentSection style={{ width: "100%" }}>
            <Suspense fallback={<LazyLoader/>}>
              <Products
                type={PAGE_TYPE}
                deviceType={deviceType}
                fetchLimit={16}
                props={props}
              />
              </Suspense>
            </ContentSection>
          </MainContentArea>
        )}
        <CartPopUp deviceType={deviceType} />
      </div>
      }
    </>
  );
};
BrandsPage.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};
const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    modalData: state.productReducer.modalData,
    products: state.productReducer.products,
    allproducts: state.productReducer.allproducts,
    category: state.productReducer.category,
    sliders: state.globalReducer.sliders,
    loading: state.globalReducer.loading,
    brands: state.productReducer.brands,
    filterBrands: state.productReducer.filterBrands,
    FilteredNameProducts: state.productReducer.FilteredNameProducts,
    filteredProducts: state.productReducer.filteredProducts,
    suggested_subcats: state.productReducer.suggested_subcats,
    oos: state.globalReducer.oos,
    togglesuggestion: state.productReducer.togglesuggestion,
    page: state.productReducer.page,
    cat_refs: state.productReducer.cat_refs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productCategory: (data) => productCategory(data, dispatch),
    ProductModalData: (data) => ProductModalData(data, dispatch),
    searchSuggestions: (data) => searchSuggestions(data, dispatch),
    searchStoreProduct: (data) => searchStoreProduct(data, dispatch),
    getProductsMappedwithSubCat: (data) =>
      getProductsMappedwithSubCat(data, dispatch),
    getProductsMappedwithCat: (data) => getProductsMappedwithCat(data, dispatch),
    offerSlider: (data) => offerSlider(data, dispatch),
    getProductOutOfStock: (body) => getProductOutOfStock(body, dispatch),
    getProductOutOfStockCat: (body) => getProductOutOfStockCat(body, dispatch),
    getProductBrands: () => getProductBrands(dispatch),
    getSortProductsName: (type, products) =>
      getSortProductsName(type, products, dispatch),
    getSortProductsPrice: (type, products) =>
      getSortProductsPrice(type, products, dispatch),
    getProductFilterBrands: (body, products, allproducts, sub_cat_id, cat_id, oos, text) =>
      getProductFilterBrands(body, products, allproducts, sub_cat_id, cat_id, oos, text, dispatch),
    sortByNameProduct: (type, products) =>
      sortByNameProduct(type, products, dispatch),
    oosModified: (data) => oosModified(data, dispatch),
    Reset_Suggestions: () => Reset_Suggestions(dispatch),
    changePage: (body) => changePage(body, dispatch),
    resetPage: () => resetPage(dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BrandsPage);
