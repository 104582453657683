import styled from "styled-components";

export const DropDownWrapper=styled.div`
   position:relative;
   .dropdownButton{
    cursor: pointer !important;
    text-align: center !important;
    border: 1px solid black !important;
    background-color: #FFFFFF !important;
    outline: none !important;
    padding: 0px !important;
    margin: 0px !important;
    border-color: #B0B0B0 !important;
    border-radius: 30px !important;
    color: #222222 !important;
    position: relative !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    font-size: 12px !important;
    line-height: 16px !important;
   };
  .dropdownlist{
      cursor:pointer;
      min-width: 25vh;
      position:absolute;
      top:35px;
      right:0px;
      font-size: 12px;
      z-index:100;
      background-color:#fff;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 37px;
      border-radius:8px;
      border:1px solid #f2f2f2;
  };
   .dropdownitem{
     padding:5px 16px;
     white-space: nowrap;
     border-bottom:1px solid #f2f2f2;
  };
  .dropdownitem:last-child{
      border-bottom:0px;
  };
  .dropdownitem:hover{
    background-color:#f2f2f2;
  }
  @media screen and (max-width:600px){
    
  }
`;