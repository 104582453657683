/************ CONSTANTS ***********/
import _ from "lodash";
export const CURRENCY = "$";
export const cats = ["veggies-fruits", "best-deals", "dairy-products", "butcher-corner", "from-the-sea", "savory-deli", "asian-food", "sweet-corner", "breakfast-bakery", "ready-meal-caterer", "beverage-water", "wine-alcohol", "personal-care", "household-essentials", "pharmacy-by-ucare", "babys-world", "happy-pets", "homewares-accessories", "electronic-computer", "covid-protection", "pharmacy-parapharmacy", "nutrition-product", 'delishop-fresh'];


//get query values
export const getValue = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get(param);
  return myParam;
};

export const checkOnlineStatus = async () => {
  try {
    const online = await fetch("https://api.staging.delishop.asia/");
    return online.status >= 200 && online.status < 300; // either true or false
  } catch (err) {
    return false; // definitely offline
  }
};

export const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export const browSer = () => { 
  if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) 
 {
     return 'Opera';
 }
 else if(navigator.userAgent.indexOf("Chrome") != -1 )
 {
     return 'Chrome';
 }
 else if(navigator.userAgent.indexOf("Safari") != -1)
 {
     return 'Safari';
 }
 else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
 {
      return 'Firefox';
 }
 else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
 {
      return 'IE'; 
 }  
 else 
 {
    return '';
 }
 };

export const checkAuthError = (response) => {
  //  if(response.status === 401 && response.json().message && response.json().message === "Unauthorized"){
  //    return true;
  //  }
  //  else return false;
  return false;
};

export const logoutuser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("supplier");
    localStorage.setItem("order_id",0);
    window.location.href = "/";
};

export const dates = (data) => {
  //return dates
  var dates = [];
  Object.keys(data.map(element => {
    for (var key in element) { 
      if (element.hasOwnProperty(key)) {
        dates.push(key);
      } 
    }  
  })
  );
  return dates;
};

export const allslots = (data) => {
  //return allslots
  var slots = [];
  Object.keys(data.map(element => {
    for (var key in element) { 
      if (element.hasOwnProperty(key)) { 
        element[key].map(elem => {
          slots.push(elem);
        })
      } 
    }  
  })
  );
  return slots;
};

export const alltimings = (data) => {
  //return alltimings
  var slots = [];
  Object.keys(data.map(element => {
    for (var key in element) { 
      if (element.hasOwnProperty(key)) { 
        element[key].map(elem => {
          var e = elem;
          e.start_time = e.start_time.toString().split(" ")[1];
          e.end_time = e.end_time.toString().split(" ")[1];
          slots.push(e);
        })
      } 
    }  
  })
  );
  var alltimings = slots.map( (value) => value.start_time).filter( (value, index, _arr) => _arr.indexOf(value) == index);
  return alltimings.sort((a,b) => a.localeCompare(b) );
};