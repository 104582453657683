import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { doSupplierLogin } from "../../store/actions/authAction";
import { AuthContext } from "../../contexts/auth/auth.context";
import UploadService from "../../services/FileUploadService";
import * as Constants from "../../constants/Constant";
import axios from "axios";
import { Table } from 'react-bootstrap';
import * as Constant from "../../constants/Constant";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import EditIcon from '@material-ui/icons/Edit';
import Modal from 'react-bootstrap/Modal'
import {getBrands} from '../../store/actions/dashboardAction'
import { connect } from "react-redux";
import Card from 'react-bootstrap/Card'
import API from "../../network/dashboardAPI";
import { interpolate } from "react-spring/renderprops";




const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  min-height: 400px;
  @media (min-width: 1100px) {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 60px;
  }
`;
const logo = {
  height : "50Px",
  width : "50Px"
}

const OrderDetails = (props) => {

    const { authDispatch } = useContext(AuthContext);
    let supplier = localStorage.getItem("supplier");
    let parsedUser = JSON.parse(supplier);

    if(!supplier) {
      window.location.href = "/";
    }
    supplier = JSON.parse(supplier)


    console.log(supplier )
    const orderId = props.match.params.id
    const [details,setDetails] = useState(null)
    let total_quantities = 0
    let total_price =0

    const getStatus = status => {
      if(status == 2) return(<p>New</p>)
      else if ( status == 3) return ( <p  >Accepted</p>)
      else if ( status == 4 ) return (<p>Packed</p>)
      else if ( status == 5 ) return (<p>Dispatched</p>)
      else if ( status == 6 ) return (<p>Delivered</p>)
      else if ( status == 7 ) return (<p>Cancelled</p>)
      else if ( status == 0 ) return (<p>Saved</p>)
      else if ( status == 1 ) return (<p>Removed</p>)
    }
    const changeStatus = (statu) => {
      API.changeOrderStatus({
          order_id : orderId,
          order_status: statu,
          store_id : 1
      }).then(()=>{
        API.fatchOrder({order_id : orderId })
        .then((response) => response.json())
        .then(response => {
          console.log(response.data)
            if(response.success){
              //console.log(response.data)
              setDetails(response.data)
            }
            })
      })
  }

    useEffect(() => {
       // props.getBrands();
        API.fatchOrder({order_id : orderId })
        .then((response) => response.json())
        .then(response => {
          console.log(response.data)
            if(response.success){
              //console.log(response.data)
              setDetails(response.data)
            }
            })
      },[])

    let content = null;

    if(details){
          content = details.order_item_details.filter(order => order.store_item_infos[0].supplier_id == supplier.id ).map((order,index)=>{
            total_quantities += order.quantity;
            total_price += order.item_total_price;

          return   (<tr key = {index}  >
                <td>{order.store_item_infos[0].product_code}</td>
                <td>{order.item_name + " (" + order.item_price_name + ")"}</td>
                <td>{order.quantity}</td>
                <td>{order.item_price_name}</td>
                <td>{order.item_price}</td>
                <td>{order.item_total_price}</td>
          </tr>)

          })

        }

  return (
    
   details &&     <HelpPageWrapper>
        <Row>
                <Col sm={11}>
                    <h4>Order Details</h4>
                </Col>
            </Row>
        <Row>
        <Col >
        <Card>
            <Card.Header><b>ORDER INFO </b> </Card.Header>
                <div >
                    <Table bordered hover>
                          <tbody>
                                <tr>
                                  <td sm= {2}>Order ID</td>
                                  <td>{details.order_id}</td>
                                </tr>
                                <tr>
                                  <td sm= {2}>Status</td>
                                  <td>{getStatus( details.status)}</td>
                                </tr>
                                <tr>
                                  <td sm= {2}>Placed At</td>
                                  <td>{details.placedAt}</td>
                                </tr>
                                {/* <tr>
                                  <td sm= {2}>Subtotal</td>
                                  <td>{details.order_payment_details[0].item_total}</td>
                                </tr> */}
                                <tr>
                                  <td sm= {2}>Delivery Fees</td>
                                  <td>{details.order_payment_details[0].delivery_fee}</td>
                                </tr>
                        </tbody>
                    </Table>
                </div>

            {/* </Card.Body> */}
        </Card>
            </Col>
        </Row>
        {/* <Row>
          <Col>
            <Card>
            <Card.Header><b>CUSTOMER DETAILS</b></Card.Header>
            <div >
                    <Table bordered hover>
                          <tbody>
                                <tr>
                                  <td sm= {2}>Name</td>
                                  <td>{details.customer_details[0].name}</td>
                                </tr>
                                <tr>
                                  <td sm= {2}>Email</td>
                                  <td>{details.customer_details[0].email}</td>
                                </tr>
                                <tr>
                                  <td sm= {2}>Phone</td>
                                  <td>{details.customer_details[0].phone}</td>
                                </tr>
                                <tr>
                                  <td sm= {2}>Address</td>
                                  <td>{ details.list_customer_addresses[0].house_number + ", " + details.list_customer_addresses[0].apartment_name + ", " + details.list_customer_addresses[0].street_details + ", " +  details.list_customer_addresses[0].area_details }</td>
                                </tr>
                        </tbody>
                    </Table>
                </div>
            </Card>
          </Col>
        </Row> */}
        <Row>
            <Col >
            <Card>
            <Card.Header><b>ORDER DETAILS</b></Card.Header>
            <div >
              { !content?<div style = {{position: "fixed" , left: "55%" , top : "35%"}} > <Loader   color = "#389244" h = "100"/> </div> : <div>
              <Table striped bordered hover>
                <thead>
                      <tr>
                        <th>SKU</th>
                      <th>Item Name</th>
                          <th>Quantity</th>
                          <th>Weight</th>
                          <th>Unit Price</th>
                          <th>Price</th>
                          {/* <th>Image</th>
                          <th>Edit</th> */}
                      </tr>
                  </thead>
                  <tbody >

                   { content}
                   <tr >
                    <th colspan="2" > Total </th>
                    <th colspan="3" >{total_quantities}</th>
                    <th> { total_price } </th>
                 </tr>
                   </tbody>
              </Table>
              </div>
            }
            </div>
            </Card>
            </Col>
        </Row>
       {/* { ( details.status == 2 || details.status == 3 ||  details.status == 4 ) &&  
       <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Row className = "mt-3">
              <Col sm>
                 <Button variant="success" onClick={ () =>changeStatus(7)}> Cancel </Button>
              </Col>
            {  details.status == 2 &&  <Col sm>
                 <Button variant="success" onClick={ () =>changeStatus(3)}> Accepte </Button>
              </Col>}
              { ( details.status == 3 ||  details.status == 4 ) &&  <Col sm>
                 <Button variant="success" onClick={ () =>changeStatus(5)}> Dispatch </Button>
              </Col>}
            </Row>
        </div>}         */}
      </HelpPageWrapper>
  
  );
}
const mapStateToProps = state =>{
  return {
    brands : state.dashboardReducer.brands
  }
 
}

const mapDispatchToProps = dispatch => {
  return {
    getBrands : data => getBrands(data,dispatch)
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(OrderDetails)