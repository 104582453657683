import React, {useContext, useState} from "react";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Button from "../../components/Button/Button";
import Spinner from 'react-bootstrap/Spinner';
import {
  QuickViewWrapper,
  ProductDetailsWrapper,
  ProductPreview,
  DiscountPercent,
  ProductInfoWrapper,
  ProductInfo,
  ProductTitlePriceWrapper,
  ProductTitle,
  ProductWeight,
  ProductDescription,
  ProductMeta,
  ProductCartWrapper,
  ProductPriceWrapper,
  ProductPrice,
  SalePrice,
  ButtonAddToCart,
  Favorite,
  ImagesViewWrap,
  ProductCartBtn,
  MetaSingle,
  MetaItem,
  PromoPrice,
  ModalClose,
} from "./QuickView.style";
import { CURRENCY } from "../../helper/constant";
import { CloseIcon, CartIcon } from "../../components/AllSvgIcon";
import ReadMore from "../../components/Truncate/Truncate";
import CarouselWithCustomDots from "../../components/MultiCarousel/MultiCarousel";
import { useCart } from "../../contexts/cart/use-cart";
import { Counter } from "../../components/Counter/Counter";
import { QuantityModifier } from "../../components/QuantityModifier/QuantityModifier";
import { Modal } from "react-bootstrap";
import { cartAnimation } from "../../helper/cart-animation";
import { compDate, isTodayApplicable } from "../../constants/Constant";
import itemToCartService from "../../services/itemToCartService";
import delishopExpressIcon from '../../assets/icons/delixpress_logo.svg';
import delishopIcon from '../../assets/icons/delishop_logo.svg';
import t from '../../i18n/translate';

const outOfStockGray = {}
/*const outOfStockGray = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: 'rgb(247 247 247 / 60%)',
  zIndex: 1,
};*/

const QuickView = ({
  modalProps,
  deviceType,
  onModalClose,
  props,
  show,
  onHide,
  onCartClick
}) => {
  const {
    addItemToCart,
    addItem,
    removeItem,
    removeItemFromCart,
    updateItem,
    isInCart,
    getItem,
    fetchOrderItems,
  } = useCart();
  const {
    item_id,
    type,
    name,
    unit,
    price,
    discountInPercent,
    salePrice,
    description,
    default_image_url,
    categories,
  } = props.modalData;
  // Update quantity
  const [isLoading, setIsLoading] = useState(false);
  const cartType = useSelector(state => state.cartReducer.cartType);

  const handleAddClick = async (e) => {
    e.stopPropagation();
    if(!localStorage.getItem("user")){
      document.getElementById("joinBTn").click();
    }
    else {
      let itemQuantity = 1;
      setIsLoading(true);

      if (!isInCart(props.modalData.item_id)) {
        cartAnimation(e);
      }
      // if user click on button increase item quantity
      else {
        itemQuantity = getItem(props.modalData.item_id).quantity + 1;
      }

      const itemResp = await itemToCartService.addItem(item_id, cartType, itemQuantity);
      if(itemResp.success) {
        fetchOrderItems(cartType);
      }
      else {
        if(typeof itemResp.error === 'string' && itemResp.error === "multiSupplier") {
          alert( t('shop_add_item_to_cart_different_shop_error_title', false) )
        }
      }
      setIsLoading(false);
    }
  };

  const handleRemoveClick = async (e) => {
    e.stopPropagation();
    let itemResp = null;

    if (!localStorage.getItem("user")) {
      document.getElementById("joinBTn").click();
    }
    else{
      if(typeof getItem(props.modalData.item_id) !== 'undefined') {
        setIsLoading(true);
        // Remove item from cart When user click on button decrease item quantity <= 1
        if (getItem(props.modalData.item_id).quantity === 1) {
          itemResp = await itemToCartService.removeItemFromCart(getItem(props.modalData.item_id), cartType);
        }
        // When user click on button decrease item quantity
        else {
          itemResp = await itemToCartService.addItem(props.modalData.item_id, cartType, getItem(props.modalData.item_id).quantity - 1);
        }

        if(itemResp.success) {
          fetchOrderItems(cartType);
        }
        else {
          alert(itemResp.message);
        }
        setIsLoading(false);
      }
    }
  };

  const handleInputUpdateQuantity = async (data, quantity) => {
    if(parseInt(quantity) >= 1) {
      setIsLoading(true);
      const itemResp = await itemToCartService.addItem(item_id, cartType, parseInt(quantity));
      if(itemResp.success) {
        fetchOrderItems(cartType);
      }
      else {
        alert(itemResp.message);
      }
      setIsLoading(false);
    }
  }

  let history = useHistory();
  function onCategoryClick(slug) {}

  const addDefaultSrc = (e) => e.target.src = props.cartType === 'express' ? delishopExpressIcon : delishopIcon;

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        animation={true}
        centered
      >
        <Modal.Body style={{ padding: 0 }}>
          <QuickViewWrapper>
            <button type="button" className="close" onClick={() => onHide()}
                    style={{position: "absolute", right: "20px", top: "20px", zIndex: 1,}}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">{ t('button_close_label') }</span>
            </button>
            <ProductDetailsWrapper className="product-card" dir="ltr">
              <ProductPreview>
                {props.modalData && props.modalData.store_items_price_types && props.modalData.store_items_price_types[0].available_quantity
                  ? <React.Fragment>
                    <div className="text-center w-100" style={{ color: "#000", marginBottom: 20, fontSize: '1.2rem' }}>
                      {props.cartType !== 'express'
                        ? props.modalData.same_day === 1
                          ? <span className={`bg-success get-same-day pb-1 px-1 rounded text-white ${props.cartType}`}>
                            { t('shop_item_get_same_day') }
                          </span>
                          : props.modalData.available_tommorrow && <span className={`bg-warning get-same-day pb-1 px-1 rounded full-width text-white ${props.cartType}`}>
                            { t('shop_item_get_tomorrow') }
                          </span>
                        : props.modalData.is_shop_open
                          ? <span className={`bg-success get-same-day pb-1 px-1 rounded text-white ${props.cartType}`}>
                            { t('shop_item_get_same_day') }
                          </span>
                          : null
                      }
                      &nbsp;&nbsp;
                      {props.modalData.promo === 1 && props.modalData.promo_start_date !== null && props.modalData.promo_end_date !== null && compDate(props.modalData.promo_start_date, props.modalData.promo_end_date) && <span className="bg-danger pb-1 px-1 rounded text-white">
                      { t('shop_item_promo_label') }
                    </span>}
                    </div>
                  </React.Fragment>
                  : <div style={outOfStockGray}></div>
                }
                <img src={props.modalData.default_image_url}
                     className="product-image"
                     alt="product_image"
                     onError={addDefaultSrc}
                />
                {!!discountInPercent && (
                  <>
                    <DiscountPercent>{discountInPercent}%</DiscountPercent>
                  </>
                )}
                {/*<ImagesViewWrap>
                  {props.modalData && props.modalData.default_image_url &&
                  <>
                    <a className="active" href="javascript:void(0);"><img src={props.modalData.default_image_url} alt="product_image"/></a>
                    <a href="javascript:void(0);"><img src={props.modalData.default_image_url} alt="product_image"/></a>
                  </>
                  }
                </ImagesViewWrap>*/}
              </ProductPreview>
              <ProductInfoWrapper dir={"ltr"}>
                <ProductInfo>
                  {props.modalData.list_brands && props.modalData.list_brands.length > 0 && (
                    <div>{props.modalData.list_brands[0].brand_name}</div>
                  )}
                  <ProductTitlePriceWrapper>
                    <ProductTitle>
                      {props.modalData.name !== " "
                        ? props.modalData.name
                        : props.modalData.weight +
                          " " +
                          props.modalData.weight_unit}
                    </ProductTitle>

                    <div className={`availability ${props.cartType}`}>
                      <p>{ t('shop_item_stock_availability_label') }:&nbsp;
                        {props.modalData && props.modalData.store_items_price_types &&
                         props.modalData.store_items_price_types[0].available_quantity
                          ? <span className="stock-label">{ t('shop_item_in_stock_label') }</span>
                          : <span className="stock-label out-of-stock">{ t('shop_item_out_of_stock') }</span>
                        }
                        <span className="product-code">{ t('shop_item_sku') }: {props.modalData.product_code}</span>
                      </p>
                    </div>

                    {/*Product Price*/}
                    <ProductPriceWrapper>
                      {props.modalData.store_items_price_types && props.modalData.store_items_price_types[0].mrp !==
                      props.modalData.store_items_price_types[0].price && (
                        <ProductPrice className={`${cartType} ${props.modalData.promo === 1 && props.modalData.promo_start_date !== null && props.modalData.promo_end_date !== null && compDate(props.modalData.promo_start_date, props.modalData.promo_end_date) ? "strike-through" : ""}`}>
                          {CURRENCY}
                          {parseFloat(props.modalData.store_items_price_types[0].price).toFixed(2)}
                        </ProductPrice>
                      )}
                      {props.modalData.promo === 1 && props.modalData.promo_start_date !== null && props.modalData.promo_end_date !== null && compDate(props.modalData.promo_start_date, props.modalData.promo_end_date) && (
                        <PromoPrice>
                          {CURRENCY}
                          {parseFloat(props.modalData.promo_price).toFixed(2)}
                        </PromoPrice>
                      )}
                    </ProductPriceWrapper>

                    {/*<div className="review">
                      0 Review
                    </div>*/}

                  </ProductTitlePriceWrapper>
                  {/*Button add item to cart*/}
                  {props.modalData && props.modalData.store_items_price_types &&
                  <ProductCartWrapper>
                    <ProductCartBtn>
                      {props.cartType !== 'express'
                        ? !props.modalData.store_items_price_types[0].available_quantity
                          ? <div style={{ color: "red" }}>{ t('shop_item_out_of_stock') }</div>
                          : <>
                            <QuantityModifier
                              className={"pr"+item_id}
                              value={getItem(item_id) ? getItem(item_id).quantity : 0}
                              onDecrement={handleRemoveClick}
                              onIncrement={handleAddClick}
                              onUpdate={(quantity) => { handleInputUpdateQuantity(getItem(item_id), quantity) }}
                              isLoading={isLoading}
                            />
                            {!getItem(item_id)
                              ? <Button variant="success"
                                        intlButtonId="addCartButton"
                                        className={`btn btn-outline-success pr${item_id}`}
                                        onClick={handleAddClick}
                                        title={!isLoading ? t('button_add_to_cart', false) : null}
                                        iconPosition="left"
                                        icon={isLoading
                                          ? <Spinner animation="border" role="status" size="sm">
                                            <span className="sr-only">{ t('home_item_popup_Loading') }</span>
                                          </Spinner>
                                          : <CartIcon />
                                        }
                              />
                              : null
                            }
                          </>
                        : props.modalData.is_shop_open
                          ? !props.modalData.store_items_price_types[0].available_quantity
                            ? <div style={{ color: "red" }}>{ t('shop_item_out_of_stock') }</div>
                            : <>
                              <QuantityModifier
                                className={"pr"+item_id}
                                value={getItem(item_id) ? getItem(item_id).quantity : 0}
                                onDecrement={handleRemoveClick}
                                onIncrement={handleAddClick}
                                onUpdate={(quantity) => { handleInputUpdateQuantity(getItem(item_id), quantity) }}
                                isLoading={isLoading}
                              />
                              {!getItem(item_id)
                                ? <Button variant="success"
                                          intlButtonId="addCartButton"
                                          className={`btn btn-outline-success pr${item_id}`}
                                          onClick={handleAddClick}
                                          title={!isLoading ? t('button_add_to_cart', false) : null}
                                          iconPosition="left"
                                          icon={isLoading
                                            ? <Spinner animation="border" role="status" size="sm">
                                              <span className="sr-only">{ t('home_item_popup_Loading') }</span>
                                            </Spinner>
                                            : <CartIcon />
                                          }
                                />
                                : null
                              }
                            </>
                          : null
                      }
                    </ProductCartBtn>
                  </ProductCartWrapper>
                  }
                  <ProductWeight>
                    {props.modalData.store_items_price_types && props.modalData.store_items_price_types[0].name}
                  </ProductWeight>

                  {props.modalData.about_item && (
                    <>
                      <div className="productInfoTitle">{ t('home_item_popup_about_label') }</div>
                      <ProductDescription>
                        <ReadMore character={600}>
                          {props.modalData.about_item}
                        </ReadMore>
                      </ProductDescription>
                    </>
                  )}
                  {props.modalData.more_item_info && (
                    <>
                      <div className="productInfoTitle">{ t('home_item_popup_item_info') }</div>
                      <ProductDescription>
                        <ReadMore character={600}>
                          {props.modalData.more_item_info}
                        </ReadMore>
                      </ProductDescription>
                    </>
                  )}
                  {props.modalData.how_to_use && (
                    <>
                      <div className="productInfoTitle">{ t('home_item_popup_how_to_use') }</div>
                      <ProductDescription>
                        <ReadMore character={600}>
                          {props.modalData.how_to_use}
                        </ReadMore>
                      </ProductDescription>
                    </>
                  )}
                  {props.modalData.ingredients && (
                    <>
                      <div className="productInfoTitle">{ t('home_item_popup_ingredient_label') }</div>
                      <ProductDescription>
                        <ReadMore character={600}>
                          {props.modalData.ingredients}
                        </ReadMore>
                      </ProductDescription>
                    </>
                  )}
                  <ProductMeta>
                    <MetaSingle>
                      {categories
                        ? categories.map((item) => (
                            <MetaItem
                              onClick={() => onCategoryClick(item.slug)}
                              key={item.id}
                            >
                              {item.title}
                            </MetaItem>
                          ))
                        : ""}
                    </MetaSingle>
                  </ProductMeta>
                  {props.cartType !== 'express'
                    ? <p className="full-view"
                            onClick={()=>{
                              history.push({pathname: `/${props.modalData.item_ref_id}`});
                              window.scrollTo(0, 0);
                              onHide();
                            }}
                    >{ t('home_item_popup_button_full_view_label') }</p>
                    : null
                  }
                </ProductInfo>
              </ProductInfoWrapper>
            </ProductDetailsWrapper>
          </QuickViewWrapper>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuickView;
