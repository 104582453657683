import React from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 76378263871;
  border: 4px solid #ffffff;
  /* border-top: 4px solid #389244; */
  border-top: 3px solid ${(props) => (props.color ? props.color : "#389244")};
  border-radius: 50%;
  transition-property: transform;
  animation-name: ${rotate};
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const PageLoader = (color) => {
  return <Spinner {...color} />;
};

export default PageLoader;
