import React from "react";
import { FormattedMessage } from "react-intl";
import CartPopUp from "../containers/Cart/CartPopUp";
import { Modal } from "@redq/reuse-modal";
import { useDeviceType } from "../helper/useDeviceType";
import {
  OfferPageWrapper,
  ProductsRow,
  MainContentArea,
  ProductsCol,
} from "../styled/pages.style";
import GiftCard from "../components/GiftCard/GiftCard";
import SiteFooter from "../components/SiteFooter/SiteFooter";
import * as OfferData from "./offer.json";

const data = OfferData.default;
const GiftCardPage = (props, { userAgent }) => {
  const deviceType = useDeviceType(userAgent);
  return (
    <Modal>
      <OfferPageWrapper>
        <MainContentArea>
          <div style={{ width: "100%" }}>
            <ProductsRow>
              {data && data.coupons
                ? data.coupons.map((coupon) => (
                    <ProductsCol key={coupon.id}>
                      <GiftCard image={coupon.image} code={coupon.code} />
                    </ProductsCol>
                  ))
                : null}
            </ProductsRow>
          </div>
        </MainContentArea>

        <SiteFooter style={{ marginTop: 50 }}>
          <FormattedMessage
            id="siteFooter"
            defaultMessage="Pickbazar is a product of"
          />
          &nbsp; <a href="/">Redq, Inc.</a>
        </SiteFooter>
      </OfferPageWrapper>
      <CartPopUp deviceType={deviceType} />
    </Modal>
  );
};
GiftCardPage.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};
export default GiftCardPage;
