import React, { useRef, useEffect } from "react";

export default function ClickOutsideWrapper({children,isClicked,setClickOutside}) {
    const wrapperRef = useRef(null);
    useEffect(() => {
        if(wrapperRef&&isClicked){
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                console.log("You clicked outside of me!");
                setClickOutside()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }
    }, [wrapperRef,isClicked]);

    return (<div ref={wrapperRef}>
       {children}
    </div>);
}
