import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  CartPopupBody,
  PopupHeader,
  PopupItemCount,
  CloseButton,
  CheckoutButtonWrapper,
  CheckoutButton,
  Title,
  PriceBox,
  NoProductMsg,
  ItemWrapper,
  ClearButton
} from "./CartItemCard.style";
import { CloseIcon } from "../../components/AllSvgIcon";
import { ShoppingBagLarge } from "../../components/AllSvgIcon";
import { CURRENCY } from "../../helper/constant";
import { FormattedMessage } from "react-intl";

import { Scrollbars } from "react-custom-scrollbars";
import { useCart } from "../../contexts/cart/use-cart";
import { CartItem } from "./CartItem/CartItem";
import PageLoader from "../../components/PageLoader/PageLoader";
import Sticky from "react-stickynode";
import t from '../../i18n/translate';
import itemToCartService from "../../services/itemToCartService";
import SimpleMessageDialog from "../../components/SimpleMessageDialog/SimpleMessageDialog";

const Cart = ({ style, className, onCloseBtnClick, scrollbarHeight }) => {
  const {
    items,
    getItem,
    addItem,
    removeItem,
    removeItemFromCart,
    updateItem,
    cartItemsCount,
    calculatePrice,
    isloading,
    resetCart,
    fetchOrderItems,
  } = useCart();

  const cartType = useSelector( state => state.cartReducer.cartType );
  const [quantity, setQuantity] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Simple Message Dialog
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [closeLabel, setCloseLabel] = useState('');
  const [confirmLabel, setConfirmLabel] = useState('');
  const [dialogHeaderLabel, setDialogHeaderLabel] = useState(t('shop_checkout_alert_error'));
  const [simpleDialogBackdrop, setSimpleDialogBackdrop] = useState(true);
  const [isConfirmButton, setIsConfirmButton] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);

  const trackCartViewed = () => {
    const cartItems = items.map((item, index) => {
      return {
        name: item.name,
        sku: item.product_code,
        product_id: item.item_id,
        price: item.item_price,
        category: item.cat_id,
        position: index + 1,
      };
    });
    window.analytics && window.analytics.track("Cart Viewed", {
      products: cartItems,
    });
  };

  const trackCheckoutStep1 = () => {
    const cartItems = items.map((item, index) => {
      return {
        name: item.name,
        sku: item.product_code,
        product_id: item.item_id,
        price: item.item_price,
        category: item.cat_id,
        position: index + 1,
      };
    });
    window.analytics && window.analytics.track("Checkout Started", {
      order_id: localStorage.getItem("order_id"),
      value: calculatePrice(),
      revenue: calculatePrice(),
      shipping: 0.0,
      tax: 0.0,
      discount: 0.0,
      currency: "USD",
      products: cartItems,
    });
  };
  const checkoutValid = () => {
    if(calculatePrice() < 10){
      alert( t('shop_checkout_alert_minimum_cart_amount_error', false, {path: '10$'}) );
    }
    else{
    let user = JSON.parse(localStorage.getItem("user"));
    onCloseBtnClick();
    if (!user) {
      document.getElementById("joinBTn").click();
    } else {
      trackCheckoutStep1();
      window.location.href = "/checkout";
    }
  }
  };

  useEffect(() => {
    trackCartViewed();
  }, []);

  const onIncrement = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);
    if(!localStorage.getItem("user")){
      document.getElementById("joinBTn").click();
    }
    else {
      if(!isLoading) {
        let quantity = item.quantity;

        if(quantity >= 1) {
          let body = {
            store_id: 1,
            item_id: item["item_id"],
            type: cartType === 'express' ? 1 : 0,
            quantity: quantity + 1
          };
          await handleSubmit(body);
          await fetchOrderItems(cartType);
          setQuantity(quantity);
          setIsLoading(false);
        }
        // If quantity is 1
        else {
          setIsLoading(false);
        }
      }
    }
  }

  const onDecrement = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);
    if (!localStorage.getItem("user")) {
      await document.getElementById("joinBTn").click();
    }
    else {
      if(!isLoading) {
        let quantity = item.quantity;

        if(quantity > 1) {
          let body = {
            store_id: 1,
            item_id: item["item_id"],
            type: cartType === 'express' ? 1 : 0,
            quantity: quantity - 1
          };
          await handleSubmit(body);
          await fetchOrderItems(cartType);
          setQuantity(quantity);
          setIsLoading(false);
        }
        // If quantity is 1
        else {
          setIsLoading(false);
        }
      }
      // Keep in mind
      /*if (getItem(item.item_id).quantity == 1 &&  !isLoading) {
        await removeItemFromCart(item, cartType);
      } else {
        if(!isLoading) {
          await removeItem(item, item.quantity, cartType);
        }
      }*/
    }
  }

  const handleSubmit = async (body) => {
    const dataResp = await itemToCartService.updateQuantity(body);
    if(dataResp.status !== 200) {
      setCloseLabel(t('button_close_label'));

      if(dataResp.data.error === 'multiSupplier') {
        setConfirmLabel( t('shop_item_detail_confirm_button') );
        setCloseLabel( t('shop_tracking_order_cancel_button') );
        setErrorMessage( dataResp.data.message );
        setIsConfirmButton(true);
      }
      else if (dataResp.data.error === 'paymentInProgress') {
        setErrorMessage( t('shop_popup_payment_locked_label') );
      }

      setShowErrorDialog(true);
    }
    else {
      // On success
    }
  }

  const quantityFormDialog = async (item, amount) => {
    if (!localStorage.getItem("user")) {
      document.getElementById("joinBTn").click();
    }
    else{
      setIsLoading(true);
      if(parseInt(amount) >= 1) {
        let body = {
          store_id: 1,
          item_id: item["item_id"],
          type: cartType === 'express' ? 1 : 0,
          quantity: amount,
        };
        await handleSubmit(body);
        await fetchOrderItems(cartType);
        setQuantity(amount);
        setIsLoading(false);
      }
      // If quantity is 1
      else {
        setIsLoading(false);
      }
    }
  }

  const onClose = () => {
    setShowErrorDialog(false);
    setIsConfirmButton(false);
    setIsLoadingDialog(false);
    setErrorMessage('');
    setConfirmLabel('');
    setIsLoading(false);
  }
  const onConfirm = () => {
    onClose();
  }

  return (
    <CartPopupBody className={className} style={style}>
      {isloading && <PageLoader color={cartType === 'express' ? '#f01266' : '#389244'} />}
      <Sticky enabled={true}>
        <PopupHeader className={"popupcart"}>
          <PopupItemCount className={cartType}>
            <ShoppingBagLarge width="19px" height="24px" />
            <span>
              {cartItemsCount}
              &nbsp;
              {cartItemsCount > 1
                ? t('home_basket_items_label')
                : t('home_basket_item_label')
              }
            </span>
          </PopupItemCount>

          <CloseButton id={"closecart"} onClick={onCloseBtnClick}>
            <CloseIcon />
          </CloseButton>
        </PopupHeader>
        <ClearButton>

           {!!cartItemsCount ? (
             <>
              <div style={{marginRight: "50px", marginTop: "20px", marginBottom: "10px", color: "black", fontWeight: "bold"}}>
                { t('home_basket_order_minimum_amount', true, {path: '10$'}) }
              </div>
              <CloseButton style={{marginRight: "30px", marginTop: "20px", marginBottom: "10px"}} onClick={()=>{
                if(!localStorage.getItem("user")){
                  document.getElementById("joinBTn").click();
                }
                else{
                  let response = window.confirm( t('home_basket_alert_delete_cart', false) );
                  if (response === true) {
                    resetCart();
                  }
                }
                }
                }>
                <img src={require('../../assets/icons/Cancle-Cart.png')} style={{width: "40px", height: "40px"}} alt="Clear Cart" title="Clear Cart"/>
              </CloseButton>
              </>
            ) : null}
          </ClearButton>
      </Sticky>
      <Scrollbars
        universal
        autoHide
        autoHeight
        autoHeightMax={scrollbarHeight}
        renderView={(props) => (
          <div
            {...props}
            style={{
              ...props.style,
              marginLeft: 0,
              marginRight: props.style.marginRight,
            }}
          />
        )}
      >
        <ItemWrapper className="items-wrapper">
          {!!cartItemsCount ? (
            items.map((item) => (
              <CartItem
                key={`cartItem-${item.item_id}`}
                cartType={cartType}
                onIncrement={(e) => onIncrement(e, item)}
                onDecrement={(e) => onDecrement(e, item)}
                onRemove={() => {
                  if (!localStorage.getItem("user")) {
                    document.getElementById("joinBTn").click();
                  } 
                  else{
                    removeItemFromCart(item, cartType);
                  }
                }}
                onUpdate={(quantity) => { quantityFormDialog(item, quantity) }}
                data={item}
                isLoading={isLoading}
              />
            ))
          ) : (
            <NoProductMsg>
              { t('home_basket_no_product_added') }
            </NoProductMsg>
          )}
        </ItemWrapper>
      </Scrollbars>

      <CheckoutButtonWrapper>
        {cartItemsCount !== 0 ? (
          <CheckoutButton className={cartType}
            onClick={() => {
              checkoutValid();
            }}
            style={{ color: "white" }}
          >
            <>
              <Title>
                { t('shop_check_out_button') }
              </Title>
              <PriceBox className={cartType}>
                {CURRENCY}
                {parseFloat(calculatePrice()).toFixed(2)}
              </PriceBox>
            </>
          </CheckoutButton>
        ) : (
          <CheckoutButton className={cartType} style={{ color: "white" }}>
            <>
              <Title>
                <Title>
                  { t('shop_check_out_button') }
                </Title>
              </Title>
              <PriceBox className={cartType}>
                {CURRENCY}
                {calculatePrice()}
              </PriceBox>
            </>
          </CheckoutButton>
        )}
      </CheckoutButtonWrapper>

      <SimpleMessageDialog show={showErrorDialog}
                           message={errorMessage}
                           showHeader={true}
                           closeLabel={closeLabel}
                           confirmLabel={confirmLabel}
                           headerLabel={dialogHeaderLabel}
                           isConfirmButton={isConfirmButton}
                           onClose={onClose}
                           onConfirm={onConfirm}
                           loading={isLoadingDialog}
                           backdrop={simpleDialogBackdrop}
                           cartType={cartType}
      />
    </CartPopupBody>
  );
};

export default Cart;
