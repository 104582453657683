import React, {useState} from "react";
import {Table, Form, Modal, Button, Spinner, ButtonGroup, ToggleButton} from 'react-bootstrap'
import styled from "styled-components";
import moment from "moment";
import {FormattedNumber} from 'react-intl';
import DateTimePicker from 'react-datetime';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .btn-secondary.active.completed {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const TableWrapper = styled.div`
  position: relative;

  .express-background{
    background: "#dc3545";
  }
  .item-list .item h5 { display: none; }
  .item-list .item:first-child h5 { display: block; }
  .table-bordered {
    border-color: transparent
  }
  .item-list .item:last-child table {
    margin-bottom: 45px;
  }
  tbody tr:nth-child(1).completed {
    background-color: #3ccb10;
  }
  tbody tr:nth-child(1).pending {
    background-color: #ea9015;
  }
  tbody tr:nth-child(1){
    cursor: pointer;
  }
  .item .form-check {
    height: 50px;
    padding-left: 8px;
  }
  .item .form-check input {
    width: 50px;
    height: 26px;
    cursor: pointer;
  }
  .item-list.hidden,
  tbody:not(.show) tr {
    display: none;
  }
  tbody:not(.show) tr td {
    width: 100%;
    display: block;
  }
  tbody:not(.show) tr:nth-child(1){
    display: block;
  }
  tbody td.noborder {
    border: 0;
  }
  tbody tr.footer td {
    font-weight: bold;
    font-size: 16px;
  }
  tbody tr.footer td:not(.noborder) {
    background-color: #dee2e6;
  }
  .table-bordered tr:not(:first-child) td:nth-child(1),
  .table-bordered tr:not(:first-child) th:nth-child(1) {
    width: 35%
  }
  .table-bordered td:nth-child(2),
  .table-bordered th:nth-child(2){
    width: 15%;
    min-width: 200px;
  }
  .table-bordered td:nth-child(3),
  .table-bordered th:nth-child(3),
  .table-bordered td:nth-child(4),
  .table-bordered th:nth-child(4),
  .table-bordered td:nth-child(5),
  .table-bordered th:nth-child(5),
  .table-bordered td:nth-child(6),
  .table-bordered th:nth-child(6) {
    width: 10%
    min-width: 100px;
  }
  
  form label {
    cursor: pointer;
    text-transform: capitalize;
    min-width: 130px;
  }
  .table-overlay.show {
    display: block;
    background-color: rgb(247 247 247 / 45%);
    position: absolute;
    width: 100%;
    height: calc(100% - 50px);
    left: 0;
    top: 0;
  }
`;

const OrdersListsDialog = (props) => {

  const [showConfirm, setShowConfirm] = useState(false);
  const [editedItem, setEditedItem] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [orderIDs, setOrderIDs] = useState([]);
  const [moveOrderToCompletedModal, setMoveOrderToCompletedModal] = useState(false);

  const [radioValue, setRadioValue] = useState('pending');
  const buttonFilter = [
    {name: 'Pending', value: 'pending'},
    {name: 'Completed', value: 'completed'},
    {name: 'Show All', value: 'showAll'}
  ];

  const handleClose = () => {
    setRadioValue('pending');
    setOrderIDs([]);
    props.onClick(false);
  };

  const btnMarkToCompleted = (e, order) => {
    e.preventDefault();
    e.stopPropagation();

    setEditedItem({
      order_id: order.order_id
    });
    setShowConfirm(true)
  };

  const handleDatePicker = (e, action) => {
    if (typeof e !== 'string') {
      if (action === 'start_date') {
        setStartDate(e.format('YYYY-MM-DD') + ' 00:00');
        setEndDate(null);

        setTimeout(() => {
          document.querySelector('.end-date-input .form-control').value = '';
        }, 5)
      }
      else
        setEndDate(e.format('YYYY-MM-DD') + ' 23:59');
    }
  };

  const expandCollapse = (e) => {
    e.preventDefault();

    const $this = e.target;
    const tbody = $this.parentElement.closest("tbody");

    if (tbody.classList.contains("show")) {
      tbody.classList.remove("show");
    } else {
      tbody.classList.add("show");
    }
  };

  const handleFilter = () => {
    if (startDate !== null && endDate !== null) {
      props.onFilter({
        type: radioValue,
        start_time: startDate,
        end_time: endDate,
        supplier: props.supplier
      });
    } else {
      alert('Oppp! Date filter is invalid.')
    }
  };

  const clearFilter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setStartDate(null);
    setEndDate(null);

    props.clearFilter({
      type: radioValue,
      supplier: props.supplier
    });
  };

  const selectableDate = (current) => {
    const dateTime = moment(startDate).subtract(1, 'day');
    return current.isAfter(dateTime);
  };

  const handleRadioChange = (e) => {
    e.preventDefault();
    setRadioValue(e.currentTarget.value);
    clearDateTimeInput(startDate, endDate);
    props.onFilter({
      type: e.currentTarget.value,
      start_time: startDate,
      end_time: endDate,
      supplier: props.supplier
    });
  };

  const handleCloseConfirm = () => {
    setEditedItem({});
    setShowConfirm(false);
  };

  const openOrderDetail = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const orderID = Number(e.target.innerHTML);

    window.open(`/admin/order/details/${orderID}`, '_blank');
  }

  const moveToCompleteCheckbox = (e) => {
    let $this = e.target;

    setOrderIDs(prev => {
      let newValue = [...prev, Number($this.value)];

      if (!$this.checked) {
        newValue = newValue.filter(item => item !== Number($this.value));
      }

      return newValue;
    });
  }

  // Handle move all order selected to complete
  const updateOrder = (action = 'one') => {
    const list = (action === 'many') ? orderIDs : editedItem.order_id;
    clearDateTimeInput(startDate, endDate);
    props.updateOrder({
      type: "completed",
      order_id: list,
      supplier: props.supplier,
    });
    setMoveOrderToCompletedModal(false);
    setShowConfirm(false);
    setOrderIDs([])
  }

  // Handle close move order to complete modal
  const handleCloseConfirmOfMoveAllOrderToComplete = () => setMoveOrderToCompletedModal(false);

  // Handle Load more
  const handleOrderListLoadMore = () => {
    clearDateTimeInput(startDate, endDate);
    props.onLoadMore({
      type: radioValue,
      start_time: startDate,
      end_time: endDate,
      supplier: props.supplier
    });
  }

  const clearDateTimeInput = (start_time = null, end_time = null) => {
    if (start_time === null && end_time === null) {
      setTimeout(() => {
        document.querySelector('.start-date-input .form-control').value = '';
        document.querySelector('.end-date-input .form-control').value = '';
      }, 5);
    }
  }

  const renderNormalOrder = (orders, items) => {
    const normalOrders = orders[items].filter(x => x.type === 0);

    return normalOrders.length ?
        normalOrders.map((order, orderIndex) =>
             <div key={orderIndex} className="item d-flex justify-content-center">
                  {order.status === 'pending' && radioValue !== 'showAll' &&
                  <Form.Check type="checkbox"
                              className="d-flex align-items-center"
                              value={order.order_id}
                              onChange={moveToCompleteCheckbox}
                  />
                  }
                  <Table bordered>
                    <tbody className="table-toggle show">
                    <tr className={order.status.toLowerCase()} onClick={(e) => expandCollapse(e)}>
                      <td colSpan={12}>
                        <div className="d-flex justify-content-start">
                          Order ID:&nbsp;<a href="#" onClick={openOrderDetail}>{order.order_id}</a>
                          &nbsp;-&nbsp;
                          Time to deliver to customer:&nbsp;
                          <b>
                            {moment(order.start_time).format('ll hh:mm A')}
                            &nbsp;to&nbsp;
                            {moment(order.end_time).format('hh:mm A')}
                          </b>

                          <div style={{marginTop: -3, paddingLeft: 50}}>
                            {order.status === 'pending' && radioValue === 'pending' && !orderIDs.length && !props.fetchingData &&
                            <Button variant="success"
                                    size='sm'
                                    className='mr-3'
                                    onClick={(e) => btnMarkToCompleted(e, order)}
                            >Mark complete</Button>
                            }
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th>Name</th>
                      <th>SKU</th>
                      <th>Weight</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                      <th>Total amount</th>
                    </tr>

                    {order.itemsLists && order.itemsLists.map((item, index) =>
                        <tr key={index}>
                          <td>{item.store_item_info.name}</td>
                          <td>{item.store_item_info.product_code}</td>
                          <td>{item.item_price_name || 'N/A'}</td>
                          <td>{item.quantity}</td>
                          <td>
                            <FormattedNumber value={item.item_MRP}
                                             style="currency"
                                             currency="USD"/>
                          </td>
                          <td>
                            <FormattedNumber value={parseFloat(item.item_MRP * item.quantity).toFixed(2)}
                                             style="currency"
                                             currency="USD"/>
                          </td>
                        </tr>
                    )}
                    <tr className="footer">
                      <td className="noborder"></td>
                      <td className="noborder"></td>
                      <td className="noborder"></td>
                      <td className="noborder"></td>
                      <td>Total</td>
                      <td><TotalCost items={order.itemsLists}/></td>
                    </tr>

                    </tbody>
                  </Table>
                </div>) :
        <div style={radioValue !== 'pendding' ? {paddingLeft: "35px"} : {}}>
          <Table bordered>
            <tbody className="table-toggle show">
            <tr className="pending" style={{cursor: "auto"}}>
              <td colSpan={12}>
                <div className="d-flex justify-content-start">
                  None
                </div>
              </td>
            </tr>

            <tr>
              <th>Name</th>
              <th>SKU</th>
              <th>Weight</th>
              <th style={{width: "10%"}}>Quantity</th>
              <th style={{width: "10%"}}>Amount</th>
              <th style={{width: "16%"}}>Total amount</th>
            </tr>

            <tr>
              <td colSpan={12} style={{textAlign: "center"}}>No data</td>
            </tr>

            <tr className="footer">
              <td className="noborder" colSpan={3}></td>
              <td className="noborder" style={{width: "10%"}}></td>
              <td style={{width: "10%"}}>Total</td>
              <td style={{width: "16%"}}>$0.00</td>
            </tr>

            </tbody>
          </Table>
        </div>
                // check to show only first time

  }

  const renderExpressOrder = (orders, items) => {
    const expressOrders = orders[items].filter(x => x.type === 1);

    return expressOrders.length ? expressOrders.map((order, orderIndex) =>
        <div key={orderIndex} className="item d-flex justify-content-center">
              {order.status === 'pending' && radioValue !== 'showAll' &&
              <Form.Check type="checkbox"
                          className="d-flex align-items-center"
                          value={order.order_id}
                          onChange={moveToCompleteCheckbox}
              />
              }
              <Table bordered>
                <tbody className="table-toggle show">
                <tr style={{background: "#f01266"}} onClick={(e) => expandCollapse(e)}>
                  <td colSpan={12}>
                    <div className="d-flex justify-content-start">
                      Order ID:&nbsp;<a href="#" onClick={openOrderDetail}>{order.order_id}</a>
                      &nbsp;-&nbsp;
                      Time to deliver to customer:&nbsp;
                      <b>
                        {moment(order.start_time).format('ll hh:mm A')}
                        &nbsp;to&nbsp;
                        {moment(order.end_time).format('hh:mm A')}
                      </b>

                      <div style={{marginTop: -3, paddingLeft: 50}}>
                        {order.status === 'pending' && radioValue === 'pending' && !orderIDs.length && !props.fetchingData &&
                        <Button variant="success"
                                size='sm'
                                className='mr-3'
                                onClick={(e) => btnMarkToCompleted(e, order)}
                        >Mark complete</Button>
                        }
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>Name</th>
                  <th>SKU</th>
                  <th>Weight</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                  <th>Total amount</th>
                </tr>

                {order.itemsLists && order.itemsLists.map((item, index) =>
                    <tr key={index}>
                      <td>{item.store_item_info.name}</td>
                      <td>{item.store_item_info.product_code}</td>
                      <td>{item.item_price_name || 'N/A'}</td>
                      <td>{item.quantity}</td>
                      <td>
                        <FormattedNumber value={item.item_MRP}
                                         style="currency"
                                         currency="USD"/>
                      </td>
                      <td>
                        <FormattedNumber value={parseFloat(item.item_MRP * item.quantity).toFixed(2)}
                                         style="currency"
                                         currency="USD"/>
                      </td>
                    </tr>
                )}
                <tr className="footer">
                  <td className="noborder"></td>
                  <td className="noborder"></td>
                  <td className="noborder"></td>
                  <td className="noborder"></td>
                  <td>Total</td>
                  <td><TotalCost items={order.itemsLists}/></td>
                </tr>

                </tbody>
              </Table>
            </div>
    ):
  <div
      style={radioValue === 'pending' ? {paddingLeft: "35px"} : {}}>
    <Table bordered>
      <tbody className="table-toggle show">
      <tr style={{cursor: "auto", background: "#f01266"}}>
        <td colSpan={12}>
          <div className="d-flex justify-content-start">
            None
          </div>
        </td>
      </tr>

      <tr>
        <th>Name</th>
        <th>SKU</th>
        <th>Weight</th>
        <th style={{width: "10%"}}>Quantity</th>
        <th style={{width: "10%"}}>Amount</th>
        <th style={{width: "16%"}}>Total amount</th>
      </tr>

      <tr>
        <td colSpan={12} style={{textAlign: "center"}}>No data</td>
      </tr>

      <tr className="footer">
        <td className="noborder" colSpan={3}></td>
        <td className="noborder" style={{width: "10%"}}></td>
        <td style={{width: "10%"}}>Total</td>
        <td style={{width: "16%"}}>$0.00</td>
      </tr>

      </tbody>
    </Table>
  </div>
  }


return (
    <HelpPageWrapper>
      <Modal show={props.dialog}
             onHide={handleClose}
             aria-labelledby="contained-modal-title-vcenter"
             size="xl"
             centered
      >
        <Modal.Header closeButton
                      style={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#fff',
                        zIndex: 1
                      }}
        >
          <Modal.Title> Supplier name:&nbsp;
            <strong><i>{(props.supplier !== null && Object.keys(props.supplier).length)
                ? props.supplier.first_name || "N/A"
                : ""
            }</i></strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body key="modalBody">
          <div className="d-flex justify-content-between mb-3"
               style={{position: 'sticky', top: 80, zIndex: 1, height: 40}}>
            <div className="mr-3 d-flex">
              <div>
                < DateTimePicker id="datetimepicker"
                                 dateFormat="ll"
                                 closeOnSelect={true}
                                 className='start-date-input'
                                 inputProps={{placeholder: 'Start Date'}}
                                 timeFormat={false}
                                 onChange={(e) => handleDatePicker(e, 'start_date')}/>
              </div>
              <div className="ml-2">
                <DateTimePicker id="datetimepicker"
                                dateFormat="ll"
                                isValidDate={selectableDate}
                                className='end-date-input'
                                closeOnSelect={true}
                                inputProps={{placeholder: 'End Date'}}
                                timeFormat={false}
                                onChange={(e) => handleDatePicker(e, 'end_date')}/>
              </div>
              <div>
                <Button variant="primary"
                        className='mr-3 ml-3'
                        onClick={handleFilter}
                ><PollOutlinedIcon/> Filter</Button>
                <Button variant="info"
                        className='mr-3'
                        onClick={clearFilter}
                >Clear</Button>
              </div>
            </div>

            <div className="d-flex">
              <ButtonGroup toggle>
                {buttonFilter.map((btn, idx) => (
                    <ToggleButton key={idx}
                                  type="radio"
                                  variant="info"
                                  name="radio"
                                  value={btn.value}
                                  checked={radioValue === btn.value}
                                  onChange={(e) => handleRadioChange(e)}
                    >{btn.name}</ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </div>

          <TableWrapper>
            {props.fetchingData && !Object.keys(props.orders).length
                ? <table style={{width: '100%'}}>
                  <tbody>
                  <tr>
                    <td colSpan={12} className="text-center"><Spinner animation="grow"/></td>
                  </tr>
                  </tbody>
                </table>
                : null
            }
            {!props.fetchingData && !Object.keys(props.orders).length
                ? <table style={{width: '100%'}}>
                  <tbody>
                  <tr>
                    <td colSpan={12} style={{border: '1px solid #e7e7e7', padding: 10}} className="text-center">No
                      data
                    </td>
                  </tr>
                  </tbody>
                </table>
                : null
            }

            {Object.keys(props.orders) && Object.keys(props.orders).map((items, index) =>
                <div key={index} className="item-list">
                  <h5 className="mb-2">{moment(items).format('MMM DD')} <span className="h6 p-3">Normal</span></h5>

                  {renderNormalOrder(props.orders, items)}

                  <h5 className="mb-2">{moment(items).format('MMM DD')} <span
                      className="h6 text-danger p-3">Express</span></h5>

                  {renderExpressOrder(props.orders, items)}
                </div>
            )}
            {(props.totalOrder && props.currentTotal < props.totalOrder)
                ? <div className="text-center mt-3">
                  <Button variant="primary"
                          isloading={`${props.fetchingData}`}
                          onClick={handleOrderListLoadMore}
                  >
                    {props.fetchingData
                        ? 'Loading...'
                        : 'Load more'
                    }
                  </Button>
                </div>
                : null
            }
            <div className={props.fetchingData ? 'table-overlay show' : 'table-overlay'}></div>
          </TableWrapper>
        </Modal.Body>
        <Modal.Footer style={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: '#fff',
          zIndex: 0
        }}
        >
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirm}
             onHide={handleCloseConfirm}
             aria-labelledby="contained-modal-title-vcenter"
             centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Order ID: {editedItem.order_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body key="modalBody">
          Are you sure you want to mark this order as completed.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={updateOrder}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleCloseConfirm}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      {orderIDs.length
          ? <Button variant="success"
                    size='md'
                    style={{
                      position: 'fixed',
                      bottom: '3.5vh',
                      right: '11vh',
                      zIndex: 9991,
                    }}
                    onClick={() => setMoveOrderToCompletedModal(true)}
          >Mark all as complete</Button>
          : null
      }
      <Modal show={moveOrderToCompletedModal}
             onHide={handleCloseConfirmOfMoveAllOrderToComplete}
             aria-labelledby="contained-modal-title-vcenter"
             centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change order status to completed</Modal.Title>
        </Modal.Header>
        <Modal.Body key="modalBody">
          Mark as complete the <b className="text-success">{orderIDs.length}</b> selected order ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => updateOrder('many')}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleCloseConfirmOfMoveAllOrderToComplete}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </HelpPageWrapper>
);
}
;

const TotalCost = (props) => {
  let eachTotalCostOfItem = 0;

  if (props.items.length) {
    props.items.map(item => {
      eachTotalCostOfItem = eachTotalCostOfItem + (item.item_MRP * item.quantity);
    });
  }

  return (
      <span>{'$' + parseFloat(eachTotalCostOfItem).toFixed(2)}</span>
  );
};

TotalCost.defaultProps = {
  items: []
};

OrdersListsDialog.defaultProps = {
  dialog: false,
  supplier: {},
  orders: [],
  orderId: null,
  supplier_id: null
};

export default OrdersListsDialog;
