import React, {useEffect, useState} from 'react';
import walletIcon from '../../assets/icons/wallet.svg'
import WalletManagementStyle from './WalletManagement.style';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FormattedNumber } from 'react-intl';
import moment from 'moment';
import axios from 'axios';
import * as Constant from '../../constants/Constant';
import t from '../../i18n/translate';

const token = localStorage.getItem("token");
const heading = [
  t("profile_user_wallet_management_admin_heading"),
  t("profile_user_wallet_management_type_heading"),
  t("profile_user_wallet_management_reason_heading"),
  t("profile_user_wallet_management_order_number_heading"),
  t("profile_user_wallet_management_comment_heading"),
  t("profile_user_wallet_management_creation_date_time_heading"),
  t("profile_user_wallet_management_amount_heading")
];
const limit = 10;

const WalletManagement = () => {
  const [walletAmount, setWalletAmount] = useState(0);
  const [transactionHistoric, setTransactionHistoric] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const initData = async () => {
    const [amount, transaction] = await Promise.all([
      getWalletAmount(),
      getWalletTransactionHistoric(),
    ]);

    return await Promise.all([
      amount,
      transaction
    ])
  }

  const getWalletAmount = async () => {
    const walletAmountAPI = Constant.baseUrl+'v1/wallet/get/current/amount';
    const amountResponse = await axios.get(walletAmountAPI, { headers: { Authorization: token } });
    if(amountResponse.status === 200) {
      return amountResponse.data.amount
    }
  }

  const getWalletTransactionHistoric = async (nextPage = page) => {
    const api = `${Constant.baseUrl}v1/get/wallet/transaction/${nextPage}/${limit}`;
    const listResp = await axios.get(api, { headers: { Authorization: token } });
    if(listResp.status === 200) {
      return listResp.data;
    }
  }

  useEffect(async () => {
    const [amount, transaction] = await initData();
    setWalletAmount(amount);
    setTransactionHistoric(transaction.list);
  }, []);

  async function handleLoadMore() {
    const nextPage = page + 1;
    let currentData = transactionHistoric;

    if(isLoadMore && !isLoading) {
      setIsLoading(true);
      const listResp = await getWalletTransactionHistoric(nextPage);

      if(listResp.next !== null) {
        listResp.list.map(item => {
          currentData.splice(currentData.length, 0, item);
        });

        setTransactionHistoric(currentData);
        setPage(nextPage);
      }
      else {
        setIsLoadMore(false)
      }
      setIsLoading(false);
    }
  }

  return <WalletManagementStyle>
    <div className="wallet-heading">
      <h3>{ t("profile_user_wallet_management_heading") }</h3>
    </div>
    <div className="content">
      <div className="media">
        <img src={walletIcon} alt={"profile_user_wallet_management_heading"} width="auto" height="60" />
      </div>
      <div className="info">
        <p>
          <b>
            <span className="title">{ t("profile_user_wallet_management_title_label") }</span><br />
            <span className="text-success">
              <FormattedNumber value={walletAmount}
                               style="currency"
                               currency="USD"
              />
            </span> { t("profile_user_wallet_management_currency") }
          </b>
        </p>
        <span className="text-capitalize">{ t("profile_user_wallet_management_sub_title_label") }</span>
      </div>
    </div>

    <div className="last-transactions">
      <h3 className="heading text-capitalize">{ t("profile_user_wallet_management_table_title") }</h3>

      <Table className="transaction-table"
             striped
             bordered
             responsive
             hover
             size="md"
      >
        <thead>
          <tr>
            {heading.map(item => <th key={item}>{ item }</th>)}
          </tr>
        </thead>
        <tbody>
          {transactionHistoric.length
            ? transactionHistoric.map((item, index) => <tr key={index}>
              <td width="20%">
                { item.adminInfo }
              </td>
              <td width="15%">
                { item.transactionType }
              </td>
              <td width="15%">
                { item.reason }
              </td>
              <td width="5%">
                { item.orderNumber }
              </td>
              <td width="30%">
                { item.comment }
              </td>
              <td width="15%">
                { moment( item.createdAt ).format('ll h:mA') }
              </td>
              <td width="10%">
                <FormattedNumber className="text-success"
                                 value={item.amount || 0}
                                 style="currency"
                                 currency="USD"
                />
              </td>
            </tr>)
            : <tr><td colSpan={12} align="center">{ t("profile_user_wallet_management_empty") }</td></tr>
          }
        </tbody>
      </Table>

      {transactionHistoric.length && isLoadMore
        ? <div style={{ width: '100%', maxWidth: 150, margin: '0 auto', textAlign: 'center' }}>
          <Button variant="success" style={{ textTransform: 'capitalize' }} onClick={handleLoadMore}>
            {isLoading
              ? <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> { t('home_item_popup_Loading') }
              </>
              : t('comment_load_more_button')
            }
          </Button>
        </div>
        : null
      }
    </div>
  </WalletManagementStyle>
}

export default WalletManagement;