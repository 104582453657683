import api from "./api";

const lang = localStorage.getItem('lang') || 'en';

export default class API {
  //AUTH
  static async Login(body) {
    return api.request(`api/login`, "POST", body);
  }
  static async SupplierLogin(body) {
    return api.request(`api/merchantLogin`, "POST", body);
  }
  static async SupplierProducts(body) {
    return api.request(`api/upload`, "POST", body);
  }
  static async StateList() {
    return api.request(`state/list?country_id=99&isSelectBox=1`, "GET");
  }
  static async Register(body) {
    return api.request(`api/register`, "POST", body);
  }
  static async OTPVerify(body) {
    return api.request(`api/verifyUser`, "POST", body);
  }
  static async PasswordRequest(body) {
    return api.request(`api/changeUserPasswordRequest`, "POST", body);
  }
  static async PasswordChange(body) {
    return api.request(`api/changeUserPassword`, "POST", body);
  }
  static async ChangeLoggedUserPassword(body) {
    return api.request(`api/changeLoggedUserPassword`, "PATCH", body);
  }
  static async OfferSlider(body) {
    return api.request(`api/web/offerSliders/get`, "POST", body);
  }

  //PRODUCT
  static async Category(body) {
    return api.request(`api/web/category?_locale=${lang}`, "POST", body);
  }
  static async GetProductsMappedwithSubCat(body) {
    return api.request(
      `api/web/product/getAllProductMappedSubCat?_locale=${lang}`,
      "POST",
      body
    );
  }
  static async SearchStoreProduct(body) {
    return api.request(`api/web/product/searchStoreProduct?_locale=${lang}`, "POST", body);
  }
  static async SearchStoreProductWithCategory(body) {
    return api.request(
      `api/product/searchStoreProductWithCategory?_locale=${lang}`,
      "POST",
      body
    );
  }
  static async SearchStoreProductBySubCategory(body) {
    if(body.search_query) {
      body.search_query = decodeURI(body.search_query);
    }
    return api.request(
      `web/v1/global/search?_locale=${lang}`,
      "POST",
      body
    );
  }
  static async GetAllProductMappedSubCatOutofStock(body) {
    return api.request(
      `api/web/product/getAllProductMappedSubCat?_locale=${lang}`,
      "POST",
      body
    );
  }
  static async GetProductsMappedwithCat(body) {
    return api.request(`api/product/getAllProductMappedCat?_locale=${lang}`, "POST", body);
  }
  static async GetAllProductMappedCatOutofStock(body) {
    return api.request(`api/product/getAllProductMappedCat?_locale=${lang}`, "POST", body);
  }
  static async GetProductBrands() {
    return api.request(`api/web/store/getBrand`, "POST");
  }
  static async GetProductByRefId(body) {
    return api.request(`api/product/getProductByRef?_locale=${lang}`, "POST", body);
  }
  static async searchStoreProductByBrandRef(body) {
    return api.request(`api/product/searchStoreProductByBrandRef?_locale=${lang}`, "POST", body);
  }
  //USER
  static async AddAddress(body) {
    return api.request(`v1/web/address/create`, "POST", body);
  }
  static async GetAddress(body, expressMode) {
    return api.request(`api/address/getAllCustomerAddress?orderType=${expressMode ? 1 : 0}`, "POST", body);
  }
  static async UpdateAddress(body) {
    return api.request(`v1/web/address/update`, "POST", body);
  }
  static async DeleteAddress(body) {
    return api.request(`api/address/delete`, "POST", body);
  }

  static async UpdateProfile(body) {
    return api.request(`api/users/updateCustomerUser`, "POST", body);
  }
  static async PastOrders(body) {
    return api.request(`api/cart/getUserPastOrder?_locale=${lang}`, "POST", body);
  }
  static async CurrentOrders(body) {
    return api.request(`api/cart/getUserCurrentOrder `, "POST", body);
  }
  static async FetchParticularOrder(body) {
    return api.request(`api/cart/fetchOrder?_locale=${lang}`, "POST", body);
  }
  static async AddItemToCart(body) {
    return api.request(`v1/cart/addItemToCart?_locale=${lang}`, "POST", body);
  }
  static async RemoveItemFromCart(body) {
    return api.request("v1/cart/removeItemFromCart", "POST", body);
  }
  static async UpdateItemQuantityCart(body) {
    return api.request(`v1/cart/updateItemQuantityCart?_locale=${lang}`, "POST", body);
  }
  static async ClearCart(body) {
    return api.request("api/cart/clearLatestCart", "POST", body);
  }
  static async FetchCart(body) {
    return api.request(`api/cart/fetchCart?_locale=${lang}`, "POST", body);
  }
  static async MoveToCheckout(body) {
    return api.request(`v1/cart/moveToCheckout?_locale=${lang}`, "POST", body);
  }
  static async ChangeOrderStatus(body) {
    return api.request(`v1/cart/changeOrderStatus?_locale=${lang}`, "POST", body);
  }
  static async AllDeliverySlots(body) {
    return api.request(`v1/web/slot/getSlotDates`, "POST", body);
  }
  static async AllSlotsForDate(body) {
    return api.request(`api/getSlotDateTimings`, "POST", body);
  }
  static async PlaceOrder(body) {
    return api.request(`v1/cart/changeOrderStatus?_locale=${lang}`, "POST", body);
  }
  static async OpenPaymentDialog(body) {
    return api.request(`api/make/payment`, "POST", body);
  }
  static async PaymentCallback(body) {
    return api.request(`api/payment/callback`, "POST", body);
  }
  static async DeliveryCharges(body) {
    return api.request(`api/web/store/getOrderCharges`, "POST", body);
  }
  static async GetAllCouponCodes(body) {
    return api.request(`v1/coupons/order`, "POST", body);
  }
  static async Reorder(body) {
    return api.request(`v1/web/basket/reorder/?_locale=${lang}`, "POST", body);
  }
  // static async getOrderPaymentStatus(body){
  //   return api.request("/api/payment/getOrderPaymentStatus", "POST", body)
  // }
}
