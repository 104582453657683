import React from "react";
import { Comment } from "./ReviewCard.style";
import { ProductsRow, ProductsCol, CardWrapper } from "../../styled/pages.style";
import t from '../../i18n/translate';

const ReviewCard = () => {
    return (
        <ProductsRow>
            <ProductsCol style={{marginTop: "20px"}}>
                <CardWrapper>
                    <blockquote><p>{ t('home_testimonial_a_great_service') }</p></blockquote>
                    <Comment>{ t('home_testimonial_a_great_service_auth') }</Comment>
                </CardWrapper>
            </ProductsCol>
            <ProductsCol style={{marginTop: "20px"}}>
                <CardWrapper>
                <blockquote><p>{ t('home_testimonial_smooth_delivery') }</p></blockquote>
                <Comment>{ t('home_testimonial_smooth_delivery_auth') }</Comment>
                </CardWrapper>
            </ProductsCol>
            <ProductsCol style={{marginTop: "20px"}}>
                <CardWrapper>
                <blockquote><p>{ t('home_testimonial_reusable_tote_bag') }</p></blockquote>
                <Comment>{ t('home_testimonial_reusable_tote_bag_auth') }</Comment>
                </CardWrapper>
            </ProductsCol>
        </ProductsRow>
    );
  };  
  export default ReviewCard;
  