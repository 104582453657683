import React from 'react';
import styled from "styled-components";

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const RefundPoliciesKhmer = () => {
  return (
    <React.Fragment>
      <Heading>REFUND POLICIES</Heading>
      <p>All cancelled orders are automatically credited to your Delishop wallet.</p>
      <p>In the case of a product not available, or a product replacement involving a refund from us, the credit will be automatically credited to your Delishop Wallet.</p>
      <p>Wallet balance can be used for payments throughout Delishop. To pay with your balance, simply select "Your Wallet" during the checkout.</p>
      <p>If you prefer to have your order refunded back to your original payment method, please contact the Delishop Customer Service.</p>
      <p>Refund periods to the original payment method vary and depend on the payment provider policies.</p>
      <p>Refunds to ABA accounts and cards may take between 1 and 3 working days. Cross bank refunds in Cambodia take 3-5 working days, and refunds to international cards take 7 days or longer. Foreign card issuers may delay refunds for up to 30 days in some cases.</p>
    </React.Fragment>
  )
}

export default RefundPoliciesKhmer;