import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { doSupplierLogin } from "../../store/actions/authAction";
import { AuthContext } from "../../contexts/auth/auth.context";
import UploadService from "../../services/FileUploadService";
import * as Constants from "../../constants/Constant";
import axios from "axios";
import { Table } from 'react-bootstrap';
import * as Constant from "../../constants/Constant";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import EditIcon from '@material-ui/icons/Edit';
import Modal from 'react-bootstrap/Modal'
import {getOrders} from '../../store/actions/dashboardAction'
import { connect } from "react-redux";
import API from "../../network/dashboardAPI";
import CheckBox from "../../components/Checkbox/Checkboxs"
import EmailIcon from '@material-ui/icons/Email';
import { green } from '@material-ui/core/colors';




const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  min-height: 400px;
  @media (min-width: 1100px) {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 60px;
  }
`;
const logo = {
  height : "50Px",
  width : "50Px"
}

const UpArrow = "↑"
const DownArrow = "↓"

const OrderListing = (props) => {

    const { authDispatch } = useContext(AuthContext);
    let admin = localStorage.getItem("admin");
    let parsedUser = JSON.parse(admin);

    if(!admin) {
      window.location.href = "/";
    }

    const [status,setStatus] = useState(2)
    const [accepte,setAccepte] = useState(true)
    const [dispatch,setDispatch] = useState(false)
    const [orderIds, setOrderIDs] = useState([])
    const [isSelected , setIsSelected] = useState(false)

    const [sortFlag,setSortFlag] = useState(true)
    const [orderArrow, setOrderArrow] = useState(false)
    const [amountArrow, setAmountArrow] = useState(false)
    const [dateArrow , setDateArrow] = useState(false)

    const success = "success"
    const secondary = "secondary"
    

    const getOrder = (e) => {
        if(e == 2){
            setAccepte(true)
            setDispatch(false)
        }else{
            if(e == 34){
                setAccepte(false)
                setDispatch(true)
            }else{
                setAccepte(false)
                setDispatch(false)
            }
        }
         setOrderIDs([])
         setStatus(e)
        props.getOrders({
            "string" : "",
            "store_id": 1,
            "status" : e,
            "startDate" : "1947-07-15 14:17:28",
	          "endDate" : new Date()
        });
       
    }
    const changeStatus = (id,statu) => {
        API.changeOrderStatus({
            order_id : id,
            order_status: statu,
            store_id : 1
        }).then(()=>{
            props.getOrders({
                "string" : "",
                "store_id": 1,
                "status" : status,
                "startDate" : "1947-07-15 14:17:28",
                "endDate" : new Date()
            });
        })
    }
    useEffect(() => {
    props.getOrders({
            "string" : "",
            "store_id": 1,
            "status" : 2
        });    
      },[])

    let content = null;


    const bulkSelection = value =>{
     // console.log(orderIds)
      //let value = e.target.id
      let tmp = [ ...orderIds]
      if(tmp.filter(id=> id == value ).length > 0 ){
        setOrderIDs(tmp.filter(id => id !=  value ))
        if(tmp.filter(id => id !=  value ).length < 1) setIsSelected(false)
      }else{
        tmp.push(value )
        setOrderIDs(tmp)
        setIsSelected(true)
      }
    } 

    const hndleBulkSubmit = statu => {
      let idStr = "" + orderIds[0];
      for(let i=1;i<orderIds.length;++i) idStr = idStr + "|" + orderIds[i];
     // console.log(idStr)
      API.changeBulkOrderStatus({
        order_ids : idStr,
        order_status : statu
      }).then(()=>{
        setIsSelected(false)
        setOrderIDs([])
        props.getOrders({
            "string" : "",
            "store_id": 1,
            "status" : status,
            "startDate" : "1947-07-15 14:17:28",
	          "endDate" : new Date()
        });
    })

    }

    const handlePickup = () => {
      let idStr = "" + orderIds[0];
      for(let i=1;i<orderIds.length;++i) idStr = idStr + "|" + orderIds[i];
     // console.log(idStr)
      API.createPickupFile({
        order_ids : idStr,
      }).then(()=>{
        alert("Generating pickup file and Sending")
        window.location.reload();
    })

    }

    const hnadleGenerateInvoice = orderId => {
      API.generateInvoice({
        order_id : orderId
      }).then(()=>{
        alert("Generating Invoice and Sending")
      })

    }

    const handleBulkGenerateInvoice = () => {
      let idStr = "" + orderIds[0];
      for(let i=1;i<orderIds.length;++i) idStr = idStr + "|" + orderIds[i];
     // console.log(idStr)
      API.generateBulkInvoice({
        order_ids : idStr,
      }).then(()=>{
        alert("Generating Invoice and Sending")
        window.location.reload();
    })
    }

    const handleSortOrder =() =>{
      props.orders.sort(function(a,b){ 
        if(sortFlag) return b.order_id-a.order_id
        else return a.order_id-b.order_id
       })
       setOrderArrow(true)
       setAmountArrow(false)
       setDateArrow(false)
      setSortFlag(!sortFlag)
    }

    const handleAmountSort = () =>{
      props.orders.sort(function(a,b){ 
        if(sortFlag) return b.order_payment_details[0].to_pay-a.order_payment_details[0].to_pay
        else return a.order_payment_details[0].to_pay-b.order_payment_details[0].to_pay
       })
       setOrderArrow(false)
       setAmountArrow(true)
       setDateArrow(false)
      setSortFlag(!sortFlag)
    }

    const handleDateSort = () =>{
      props.orders.sort(function(a,b){ 
        if(sortFlag) return new Date(b.store_on_going_slots[0].start_time) - new Date(a.store_on_going_slots[0].start_time)
        else return new Date(a.store_on_going_slots[0].start_time) - new Date(b.store_on_going_slots[0].start_time)
       })
       setOrderArrow(false)
       setAmountArrow(false)
       setDateArrow(true)
      setSortFlag(!sortFlag)
    }

    const orderPayment = (brand) => {
      return brand.order_payment_detail ? brand.order_payment_detail : brand.order_payment_details[0];
    };

    const onGoingSlot = (brand) => {
      return brand.store_on_going_slot ? brand.store_on_going_slot : brand.store_on_going_slots[0];
    };

    if(props.orders){
          content = props.orders.map((brand,index)=>
         
            <tr key = {index}  >
              { accepte ||  dispatch ? <>
              <td>
                <CheckBox key = {brand.order_id} id={brand.order_id} bulkSelection={bulkSelection} />
              </td> </> : ""}
                <td > <Link target="_blank" to={{ pathname: '/admin/order/details/'+brand.order_id  }}>
                {brand.order_id}
                </Link></td>
                <td>{brand.placedAt}</td>
                <td>{ orderPayment(brand).payment_method_name == "Paid online (ABA)" && orderPayment(brand).p_status ? "Paid online (ABA) Payment failed/Ongoing" : orderPayment(brand).payment_method_name }</td>
                <td  >{orderPayment(brand).to_pay}</td>
                
                <td>
                    {onGoingSlot(brand).start_time}
                </td>
                { accepte ||  dispatch ? <> <td>
                <Button variant="success" onClick={ () =>changeStatus(brand.order_id,7)}>
                        Cancel
                    </Button>
                </td>
                <td>
                <Button variant="success" onClick={ () =>changeStatus(brand.order_id,accepte ? 3 : 5)}>
                       {accepte ? "Accepte": "Dispatch"}
                    </Button>
                </td>
                <td>  <EmailIcon  style={{ color: green[500], cursor : "pointer"   } } onClick = {()=>hnadleGenerateInvoice(brand.order_id) }/></td></> : ""}
          </tr>
        
          )}

  return (
    
      <HelpPageWrapper>
      { isSelected ?
        <Row className= "mb-5">
            { accepte ||  dispatch ? <> 
               <Col ><Button variant="success" onClick={ () =>hndleBulkSubmit(7)}>
                        Cancel
                    </Button>
                    </Col> 
                
                <Col >
                <Button variant="success" onClick={ () =>hndleBulkSubmit(accepte ? 3 : 5)}>
                       {accepte ? "Accepte": "Dispatch"}
                    </Button>
                  </Col>
                  <Col >
                <Button variant="success" onClick={handlePickup}>
                      Pickup
                    </Button>
                  </Col>
                  <Col >
                <Button variant="success" onClick={handleBulkGenerateInvoice}>
                Invoice/Order file
                    </Button>
                  </Col>
                </> : ""}
        </Row>
        : <Row>
            <Col >
                <Button variant= {status==2? secondary:success }  type="submit" className="mb-5" onClick={()=>getOrder(2) }>
                    New
                </Button>
            </Col>
            <Col >
                <Button variant={status==34? secondary:success } type="submit" className="mb-5" onClick={()=>getOrder(34) }>
                Accepted & Packed
                </Button>
            </Col>
            <Col >
                <Button variant={status==56? secondary:success } type="submit" className="mb-5" onClick={()=>getOrder(56) }>
                Dispatched & Delivered
                </Button>
            </Col>
            <Col >
                <Button variant={status==7? secondary:success } type="submit" className="mb-5" onClick={()=>getOrder(7) }>
                Cancelled
                </Button>
            </Col>
        </Row>
}
        <Row>
            <Col sm={11}>
              { !content?<div style = {{position: "fixed" , left: "55%" , top : "35%"}} > <Loader   color = "#389244" h = "100"/> </div> :
              <Table striped bordered hover>
                <thead>
                      <tr>
                      { accepte ||  dispatch ? <> <th>*</th>  </> : ""}
                      <th onClick={handleSortOrder}>Order ID { orderArrow ? ( sortFlag ? DownArrow: UpArrow ): ""}</th>
                          <th>Order Date </th>
                          <th>Payment Type</th>
                          <th onClick={handleAmountSort}>Amount { amountArrow ? ( sortFlag ? DownArrow: UpArrow ): ""}</th>
                          <th onClick = {handleDateSort}>Delivery Date/Time { dateArrow ? ( sortFlag ? DownArrow: UpArrow ): ""}</th>
                        { accepte ||  dispatch ? <>
                        <th>Cancel</th>
                        <th> {accepte ? "Accepte": "Dispatch" }</th> 
                        <th>Invoice/Order file</th>
                        </> : ""}
                      </tr>
                  </thead>
                  <tbody >

                   { content}
                   </tbody>
              </Table>
            }
            </Col>
        </Row>
        
      </HelpPageWrapper>
  
  );
}
const mapStateToProps = state =>{
 // console.log(state);
  return {
    orders : state.dashboardReducer.orders
  }
 
}

const mapDispatchToProps = dispatch => {
  return {
    getOrders : data => getOrders(data,dispatch)
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(OrderListing)