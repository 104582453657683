import React, { useContext, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import {
  Table,
  Row,
  Col,
  Spinner,
  Badge,
  Button,
  Modal,
  Form,
  ListGroup,
  InputGroup,
  Toast
} from "react-bootstrap";
import "react-datetime/css/react-datetime.css";
import API from "../../network/dashboardAPI";
import OrdersListsDialog from "../../components/Supplier/ordersListsDialog";
import _ from "lodash";
import jsUtility from "../../services/jsUtility";
import ToastNotification from "../../components/ToastNotification/ToastNotification";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, RadioGroup, FormControlLabel, Radio, Tooltip} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import {
  initSuppliersLists,
  initSupplierOrders,
  updateSupplierOrders,
  updateSuppliersLists,
} from "../../store/actions/dashboardAction";
import { AuthContext } from "../../contexts/auth/auth.context";

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  table.drag-drop tbody tr td:first-child {
    cursor: all-scroll;
  }

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const SupplierOpeningHours = styled.div`
  .item-list {
    position: relative;
    
    &.disabled:before {
      content: '';
      width: calc(100% - 26px);
      height: 0;
      border-bottom: 1px solid #000;
      display: block;
      position: absolute;
      top: 20px;
      right: 0;
      z-index: 100;
    }
  
    .item {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 5px;
      
      .form-group {
        margin-bottom: 0;
        line-height: 1.8rem;
        width: 100%;
        max-width: 120px;
        
        .form-check-input {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
        .form-check-label {
          margin-bottom: 0;
          padding-left: 5px;
          font-weight: bold;
          cursor: pointer;
          text-transform: capitalize;
        }
      }
      .input-group {
        p,
        .input-group-text {
          border-left: none;
          font-weight: bold;
        }
      }
    }
  }
`;

const BadgeCursor = styled.div`
  cursor: pointer;
`;

const labelStyle = {
  lineHeight: "2.5rem",
};

const dayList = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
]

const Suppliers = (props) => {
  const { authDispatch } = useContext(AuthContext);
  let admin = localStorage.getItem("admin");

  if (!admin) {
    window.location.href = "/";
  }

  const perPage = 10;

  const [show, setShow] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [editedItem, setEditedItem] = useState({});
  const [dialogOrderDetail, setDialogOrderDetail] = useState(false);
  const [itemsOrders, setItemsOrders] = useState([]);
  const [noItemsOrders, setNoItemsOrders] = useState(false);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [validated, setValidated] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("all");

  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [receiveEmail, setReceiveEmail] = useState(false);
  const [totalOrder, setTotalOrder] = useState(0);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [fetchingData, setFetchingData] = useState(false);
  const [enableExpress, setEnableExpress] = useState(false);
  const [allowMultiCart, setAllowMultiCart] = useState(false);
  const [deliveryTime, setDeliveryTime] = useState("");
  const [closeDeliveryTime, setCloseDeliveryTime] = useState("");
  const [estimateDeliveryTime, setEstimateDeliveryTime] = useState(0);
  const [address, setAddress] = useState("");
  const [textSearch, setTextSearch] = useState("");
  const [isRequestingUpsert, setIsRequestingUpsert] = useState(false);
  const [supplierIndexToUpdate, setSupplierIndexToupdate] = useState("");

  const _emailInputRef = React.useRef("");
  const _email2InputRef = React.useRef("");
  const _phoneInputRef = React.useRef("");
  const _firstNameInputRef = React.useRef("");
  const _passwordInputRef = React.useRef("");
  const _confirmPasswordInputRef = React.useRef("");
  const _switchInputRef = React.useRef(false);
  const _switchInputReceiveEmailRef = React.useRef(false);

    // Modal express setting
  const [isOpenModalUpdateExpressSetting, setIsOpenModalUpdateExpressSetting] = useState(false);
  const [allowEnableExpressSetting, setAllowEnableExpressSetting] = useState("");
  const [allowMultiCartExpressSetting, setAllowMultiCartExpressSetting] = useState("");
  const [isRequestingUpdateExpressSeeting, setIsRequestingUpdateExpressSetting] = useState(false);

    // toast states
    const [toastBgColor, setToastBgColor] = useState('bg-success');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("Delete successful");

    const suppliersListsState = useSelector(
    (state) => state.dashboardReducer.suppliersLists
  );
  const supplierOrdersStore = useSelector(
    (state) => state.dashboardReducer.supplierOrders
  );

  // Edit supplier form validator
  const [formError, setFormError] = useState({
    deliveryTime: false,
    closeDeliveryTime: false,
    estimateDeliveryTime: false,
    address: false,
  });

  // useEffect(() => {
  //   setIsLoading(true);

  //   if(!suppliersListsState.length) {
  //     getSupplierList();
  //   } else {
  //     setIsLoading(false);
  //   }
  // },[suppliersListsState, selectedStatus]);

  // allowMultiCart will be enable only of enableExpress is set to true.
  useEffect(() => {
    if (!enableExpress) {
      setAllowMultiCart(false);
    }
  }, [enableExpress]);

  useEffect(() => {
    if (!allowEnableExpressSetting) {
      setAllowMultiCartExpressSetting(false);
    }
  }, [allowEnableExpressSetting]);

  useEffect(() => {
    getSupplierList();
  }, [selectedStatus, textSearch]);

  // Get supplier list
  const getSupplierList = () => {
    setIsLoading(true);
    const query = {
      selectedStatus,
      search: textSearch
    };
    API.getSuppliersLists(query, { status: "pending" })
      .then((res) => res.json())
      .then((res) => {
        if (res.data) {
          props.initSuppliersLists(res.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };

  // Get order list
  const getOrderList = async (
    supplier_id = null,
    status = "pending",
    limit = perPage,
    offset = 0,
    start_time = null,
    end_time = null,
    action = null
  ) => {
    let $params = { supplier_id, status, limit, offset, start_time, end_time };
    setFetchingData(true);
    const supplierOrder = await API.getItemsOrdersListsBySupplier($params).then(
      (res) => res.json()
    );

    setTotalOrder(supplierOrder.total);
    if (supplierOrder.data.length) {
      let ordersLists = supplierOrder.data;

      if (action !== null && action === "loadMore") {
        ordersLists = supplierOrdersStore;
        supplierOrder.data.forEach((order) => ordersLists.push(order));
      }

      setNoItemsOrders(false);
      setCurrentTotal(ordersLists.length);
      setItemsOrders(jsUtility.groupBy("start_time", ordersLists));
      props.initSupplierOrders(ordersLists);
    } else {
      setNoItemsOrders(true);
      props.initSupplierOrders([]);
      setItemsOrders([]);
    }
    setFetchingData(false);
  };
  // Update order
  const updateOrder = (eventData) => {
    const $params = {
      order_id: eventData.order_id,
      supplier_id: eventData.supplier.id,
      status: "completed",
    };
    API.markOrderAs($params)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          let newOrderList = null;
          const orderIDs = eventData.order_id;
          const supplierID = eventData.supplier.id;

          if (Array.isArray(orderIDs)){
            setTotalOrder((prev) => {return prev - orderIDs.length});
            newOrderList = supplierOrdersStore.filter(
                (item) => !orderIDs.includes(item.order_id)
            );
          }
          else{
            newOrderList = supplierOrdersStore.filter(
                (item) => item.order_id !== orderIDs
            );
            setTotalOrder((prev) => {return prev - 1});
          }

          setItemsOrders(jsUtility.groupBy("start_time", newOrderList));

          document
            .querySelectorAll("input[type=checkbox]")
            .forEach((el) => (el.checked = false));

          props.initSupplierOrders(newOrderList);
          props.updateSuppliersLists({
            supplier_id: supplierID,
            order_id: orderIDs,
            data: newOrderList,
          });
          handleShowToast("SUCCESS");
        } else {
          handleShowToast("ERROR")
          // alert("Error! Can not move your order to complete");
        }
      });
  };

  const viewOrders = (status = false, item = null) => {
    setDialogOrderDetail(status);

    if (item !== null && item.totalPending > 0) {
      setEditedItem(item);
      getOrderList(item.id);
    } else {
      setNoItemsOrders(false);
      setEditedItem([]);
      setItemsOrders([]);
    }
  };

  const handleChangeEnableExpress = (e) => {
    setAllowEnableExpressSetting(e.target.value === "true"? true: false);
  }

  const handleChangeAllowMultiCart = (e) => {
    setAllowMultiCartExpressSetting(e.target.value === "true"? true: false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      setIsLoading(true);
      const form = event.currentTarget;
      const email2Arr = email2.split(",");
      let emailValid = true;

      if (email2Arr.length) {
        for (let i = 0; i <= email2Arr.length; i++) {
          if (typeof email2Arr[i] !== "undefined") {
            let email = email2Arr[i].trim();

            if (
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                email
              )
            ) {
              emailValid = true;
            } else {
              emailValid = false;
            }
          }
        }
      }

      if (email2Arr.length > 6) {
        document.getElementById("formEmail2").classList.add("is-invalid");
        emailValid = false;
        alert("Maximum email address is 6");
      } else if (!emailValid) {
        document.getElementById("formEmail2").classList.add("is-invalid");
        alert("Invalid email address");
      } else {
        document.getElementById("formEmail2").classList.remove("is-invalid");
      }

      if (!deliveryTime) {
        setFormError((prevState) => ({
          ...prevState,
          deliveryTime: true,
        }));
      }

      if (!closeDeliveryTime) {
        setFormError((prevState) => ({
          ...prevState,
          closeDeliveryTime: true,
        }));
      }

      if (!estimateDeliveryTime) {
        setFormError((prevState) => ({
          ...prevState,
          estimateDeliveryTime: true,
        }));
      }

      if (!address) {
        setFormError((prevState) => ({
          ...prevState,
          address: true,
        }));
      }

      // opening hours validate when all open hour < close hour
      const isValidOpeningHour = openingHours.every(x => (x.is_available && x.start_time <= x.end_time) || !x.is_available);
      if (form.checkValidity() && emailValid && isValidOpeningHour) {
        if (passwordNotMatch) {
          setIsLoading(false);
          alert("Password and confirm password not match.");
        } else {
          const dataInput = {
            id: editedItem.id,
            first_name: _firstNameInputRef.current.value,
            email: _emailInputRef.current.value,
            phone: _phoneInputRef.current.value,
            password: _passwordInputRef.current.value,
            receive_email: receiveEmail,
            enable_express: enableExpress,
            allow_multi_cart: allowMultiCart,
            delivery_time: deliveryTime ? deliveryTime : "",
            close_delivery_time: closeDeliveryTime ? closeDeliveryTime : "",
            estimate_delivery_minute: estimateDeliveryTime
              ? estimateDeliveryTime
              : 0,
            address,
            express_supplier_open_hours: openingHours
          };

          const newSuppliers = _.cloneDeep(suppliersListsState);

          newSuppliers[supplierIndexToUpdate].email = _emailInputRef.current.value;
          newSuppliers[supplierIndexToUpdate].phone = _phoneInputRef.current.value;
          newSuppliers[supplierIndexToUpdate].first_name =
            _firstNameInputRef.current.value;


          // Hot fix update the state
          for (let i = 0; i < email2Arr.length; i++) {
            dataInput[`email_${i + 2}`] = email2Arr[i].trim();
            newSuppliers[supplierIndexToUpdate][`email_${i + 2}`] = email2Arr[i].trim();
          }
          if (email2Arr.length < 2) newSuppliers[supplierIndexToUpdate].email_3 = null;
          if (email2Arr.length < 3) newSuppliers[supplierIndexToUpdate].email_4 = null;
          if (email2Arr.length < 4) newSuppliers[supplierIndexToUpdate].email_5 = null;
          if (email2Arr.length < 5) newSuppliers[supplierIndexToUpdate].email_6 = null;
          if (email2Arr.length < 6) newSuppliers[supplierIndexToUpdate].email_7 = null;
          //End hot fix

          // new props
            newSuppliers[supplierIndexToUpdate].receive_email = receiveEmail;
            newSuppliers[supplierIndexToUpdate].enable_express = enableExpress ? 1 : 0;
            newSuppliers[supplierIndexToUpdate].allow_multi_cart = allowMultiCart ? 1 : 0;
            newSuppliers[supplierIndexToUpdate].delivery_time = deliveryTime ? deliveryTime : "";
            newSuppliers[supplierIndexToUpdate].close_delivery_time = closeDeliveryTime ? closeDeliveryTime : "";
            newSuppliers[supplierIndexToUpdate].estimate_delivery_minute = estimateDeliveryTime
                ? estimateDeliveryTime
                : 0;
            newSuppliers[supplierIndexToUpdate].address = address ? address : "";
            newSuppliers[supplierIndexToUpdate].express_supplier_open_hours = openingHours? openingHours: [];

          API.updateSupplierCredential(dataInput)
            .then((res) => res.json())
            .then((res) => {
              setShow(false);
              setIsDisabled(true);
              setPasswordNotMatch(false);

              setEditedItem({});
              resetEditSupplierForm();
              resetEditSupplierFormError();
              setIsLoading(false);
              props.initSuppliersLists(newSuppliers);
              handleShowToast("SUCCESS");
            });
        }
      }else{
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      handleShowToast("ERROR");
      console.log("Debug: ", error);
    }
  };

  const handlePasswordChange = () => {
    if (
      _passwordInputRef.current.value !== _confirmPasswordInputRef.current.value
    ) {
      setPasswordNotMatch(true);
    } else {
      setPasswordNotMatch(false);
    }
  };

  const editSupplier = (e, item, index) => {
    e.stopPropagation();

    try {
      setShow(true);
      setEditedItem(item);
      setFirstName(item.first_name);
      setPhone(item.phone);
      setEmail(item.email);
      setSupplierIndexToupdate(index)
      setEmail2((prev) => {
        let newValue = [];
        if (item.email_2 !== null && item.email_2 !== "") {
          newValue.push(item.email_2);
        }
        if (item.email_3 !== null && item.email_2 !== "") {
          newValue.push(item.email_3);
        }
        if (item.email_4 !== null && item.email_4 !== "") {
          newValue.push(item.email_4);
        }
        if (item.email_5 !== null && item.email_5 !== "") {
          newValue.push(item.email_5);
        }
        if (item.email_6 !== null && item.email_6 !== "") {
          newValue.push(item.email_6);
        }
        if (item.email_7 !== null && item.email_7 !== "") {
          newValue.push(item.email_7);
        }

        return (prev = newValue.join(", "));
      });

      setReceiveEmail(item.receive_email);

      if (_.has(item, "enable_express")) {
        setEnableExpress(item.enable_express? true: false);
      }

      if (_.has(item, "allow_multi_cart")) {
        setAllowMultiCart(item.allow_multi_cart? true: false);
      }

      if ((_.has(item), "delivery_time")) {
        setDeliveryTime(item.delivery_time);
      }

      if (_.has(item, "close_delivery_time")) {
        setCloseDeliveryTime(item.close_delivery_time);
      }

      if (_.has(item, "estimate_delivery_minute")) {
        setEstimateDeliveryTime(item.estimate_delivery_minute);
      }

      if (_.has(item, "address")) {
        setAddress(item.address);
      }

      if(_.has(item, "express_supplier_open_hours")){
        let tmpOpeningHours = _.cloneDeep(item.express_supplier_open_hours);
        setOpeningHours(tmpOpeningHours);
      }
    } catch (error) {
      console.log("Debug: ", error);
    }
  };

  const isChangePassword = () => {
    if (!isDisabled) setPasswordNotMatch(false);

    setIsDisabled(!isDisabled);
  };

  const handleClose = () => {
    setIsDisabled(true);
    setPasswordNotMatch(false);
    setShow(false);
    setReceiveEmail(false);
    resetEditSupplierForm();
    resetEditSupplierFormError();
  };

  const resetEditSupplierForm = () => {
    setShow(false);
    setEditedItem({});
    setFirstName("");
    setPhone("");
    setEmail("");
    setEmail2("");
    setReceiveEmail("");
    setEnableExpress(false);
    setAllowMultiCart(false);
    setDeliveryTime("");
    setCloseDeliveryTime("");
    setEstimateDeliveryTime(0);
    setAddress("");
    setOpeningHours([]);
    setOpeningHoursValidationErrors(openingHoursErrorPattern);
  };

  const resetEditSupplierFormError = () => {
    setFormError({
      deliveryTime: false,
      closeDeliveryTime: false,
      estimateDeliveryTime: false,
      address: false,
    });
  };

  // Handle Date and Status filter from child component
  const handleFilter = async (eventData) => {
    if (["pending", "completed", "showAll"].includes(eventData.type)) {
      await getOrderList(
        eventData.supplier.id,
        eventData.type,
        perPage,
        0,
        eventData.start_time,
        eventData.end_time
      );
      clearDateTimeInput(eventData.start_time, eventData.end_time);
    }
  };

  const clearDateTimeInput = (start_time = null, end_time = null) => {
    if (start_time === null && end_time === null) {
      setTimeout(() => {
        document.querySelector(".start-date-input .form-control").value = "";
        document.querySelector(".end-date-input .form-control").value = "";
      }, 5);
    }
  };

  // Handle load more order
  const handleOrderListLoadMore = async (eventData) => {
    const id = eventData.supplier.id;
    const status = eventData.type;
    const startTime = eventData.start_time;
    const endTime = eventData.end_time;

    await getOrderList(
      id,
      status,
      perPage,
      currentTotal,
      startTime,
      endTime,
      "loadMore"
    );
    clearDateTimeInput(startTime, endTime);
  };

  const clearFilter = async (eventData) => {
    await getOrderList(eventData.supplier.id, eventData.type);
    clearDateTimeInput();
  };

  const handleReceiveEmail = () => {
    if (_switchInputReceiveEmailRef.current.checked) {
      setReceiveEmail(true);
    } else {
      setReceiveEmail(false);
    }
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const setExpressSettingForm = () =>{
    setAllowMultiCartExpressSetting(allowMultiCart);
    setAllowEnableExpressSetting(enableExpress);
  }

  const resetExpressSettingForm = () => {
    setAllowMultiCartExpressSetting("");
    setAllowEnableExpressSetting("");
  }

  const handleOpenModalUpdateExpressSetting = () => {
    setIsOpenModalUpdateExpressSetting(true);

    setExpressSettingForm();
  }

  const handleCloseModalUpdateExpressSetting = () => {
    setIsOpenModalUpdateExpressSetting(false);

    resetExpressSettingForm();
  }

  const handleUpdateExpressSetting = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsRequestingUpdateExpressSetting(true);
    try {
      const dataInput = {
        id: editedItem.id,
        first_name: editedItem.first_name,
        email: editedItem.email,
        phone: editedItem.phone,
        password: editedItem.password,
        receive_email: editedItem.receive_email,
        enable_express: allowEnableExpressSetting,
        allow_multi_cart: allowMultiCartExpressSetting,
        delivery_time: editedItem.delivery_fee_fixed,
        close_delivery_time: editedItem.close_delivery_time,
        estimate_delivery_minute: editedItem.estimate_delivery_minute,
        address: editedItem.address,
        email_2: editedItem.email_2,
        email_3: editedItem.email_3,
        email_4: editedItem.email_4,
        email_5: editedItem.email_5,
        email_6: editedItem.email_6,
        email_7: editedItem.email_7
      };

      API.updateSupplierCredential(dataInput)
          .then((res) => res.json())
          .then((res) => {
            let newSuppliers = _.cloneDeep(suppliersListsState);

            newSuppliers[supplierIndexToUpdate]['enable_express'] = allowEnableExpressSetting;
            newSuppliers[supplierIndexToUpdate]['allow_multi_cart'] = allowMultiCartExpressSetting;
            suppliersListsState[supplierIndexToUpdate]['enable_express'] = allowEnableExpressSetting;
            suppliersListsState[supplierIndexToUpdate]['allow_multi_cart'] = allowMultiCartExpressSetting;

            editedItem.enable_express = allowEnableExpressSetting;
            editedItem.allow_multi_cart = allowMultiCartExpressSetting;
            setEnableExpress(allowEnableExpressSetting);
            setAllowMultiCart(allowMultiCartExpressSetting);

            props.initSuppliersLists(newSuppliers);
            handleShowToast("SUCCESS");

            handleCloseModalUpdateExpressSetting();
            setIsRequestingUpdateExpressSetting(false);
          });

    } catch (error) {
      setIsRequestingUpdateExpressSetting(false);
      handleShowToast("ERROR");
      console.log("Debug: ", error);
    }
  };

  // set time before call api
  let typingTimer; //timer identifier
  let doneTypingInterval = 750; //time in ms (7.5 seconds)
  const handleSearch = (value) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(function () {
      setTextSearch(value);
    }, doneTypingInterval);
  };


    // toast event
    const handleShowToast = (type) => {
        setShowToast(true);

        if(type === "SUCCESS"){
            setToastBgColor("bg-success");
            setToastMessage("Operation successful.");
        }else if(type === "ERROR"){
            setToastBgColor("bg-danger");
            setToastMessage("Operation error.");
        }
    }

  // Styles
  const listGroupStyle = {
    marginBottom: "1rem",
  };

  const [openingHours, setOpeningHours] = useState([]);
  const handleEachOpeningHoursToggle = (e, index) => {
    const $this = e.target;
    const isChecked = $this.checked;

    if(isChecked) {
      setOpeningHours(prev => {
        let newData = [...prev];
        newData[index].is_available = true;
        return newData;
      })

      checkValidateOpeningHours(index);
    }
    else {
      setOpeningHours(prev => {
        let newData = [...prev];
        newData[index].is_available = false;
        return newData;
      })

      setOpeningHoursValidationErrors(prev => {
        let newValue = [...prev];
        newValue[index] = false;
        return newValue;
      })
    }
  }
  const handleOpeningHoursChange = (e, index, type) => {
    const $this = e.target;
    let inputVal = $this.value;

    setOpeningHours(prev => {
      let newData = [...prev];

      if(type === 'opening') {
        newData[index].start_time = inputVal;
      }
      else if(type === 'close') {
        newData[index].end_time = inputVal;
      }

      return newData;
    });
  }

  const openingHoursErrorPattern = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
  ];

  const [openingHoursValidationErrors, setOpeningHoursValidationErrors] = useState(openingHoursErrorPattern);
  const checkValidateOpeningHours = (index) => {
    setOpeningHoursValidationErrors(prev => {
      let newValue = [...prev];
      newValue[index] = openingHours[index].start_time && openingHours[index].end_time && openingHours[index].is_available? openingHours[index].start_time > openingHours[index].end_time: false;
      return newValue;
    })
  }
  const handleCheckValidateOpeningHours = (index) => {
    checkValidateOpeningHours(index);
  }

  let row;
  const handleDragStart = (e) => {
    row = e.target;
  }
  const handleDragDropStart = (e) => {
    e.preventDefault();

    let children= Array.from(e.target.parentNode.parentNode.children);

    if(children.indexOf(e.target.parentNode)>children.indexOf(row))
      e.target.parentNode.after(row);
    else
      e.target.parentNode.before(row);
  }

  return (
    <HelpPageWrapper>
      <Row>
        <Col sm={11}>
          <Row>
            <Col sm={9}>
              <Form.Control
                  style={{ width: "25rem" }}
                  size="md"
                  type="text"
                  placeholder="Search supplier"
                  onChange={(e) => handleSearch(e.target.value)}
                />
            </Col>

            <Col sm={3} md={3} className="d-flex justify-content-end">
              <Form.Label style={labelStyle}>Filter: </Form.Label>&nbsp;&nbsp;
              <Form.Control
                as="select"
                onChange={handleStatusChange}
                value={selectedStatus}
              >
                <option value={"all"}>All supplier</option>
                <option value={"noExpress"}>Normal supplier</option>
                <option value={"express"}>Express supplier</option>
                <option value={"multiExpress"}>Multi express</option>
              </Form.Control>
            </Col>
          </Row>

          <Table striped bordered hover className="drag-drop">
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Telegram</th>
                <th>Line</th>
                <th>Remark</th>
                <th>Pending</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {suppliersListsState.length ? (
                suppliersListsState.map((item, index) => (
                  <tr key={index}
                      draggable="true"
                      onDragStart={(e) => handleDragStart(e)}
                      onDragOver={(e) => handleDragDropStart(e)}
                  >
                    <td>{index + 1}</td>
                    <td>
                      {item.receive_email ? (
                        <b style={{ color: "green" }}>
                          {item.first_name || "N/A"}
                        </b>
                      ) : (
                        item.first_name || "N/A"
                      )}
                    </td>
                    <td>
                      {item.email ? item.email : ""}
                      {item.email_2 ? ", " + item.email_2 : ""}
                      {item.email_3 ? ", " + item.email_3 : ""}
                      {item.email_4 ? ", " + item.email_4 : ""}
                      {item.email_5 ? ", " + item.email_5 : ""}
                      {item.email_6 ? ", " + item.email_6 : ""}
                      {item.email_7 ? ", " + item.email_7 : ""}
                    </td>
                    <td>{item.phone || "N/A"}</td>
                    <td>{item.telegram || "N/A"}</td>
                    <td>{item.line || "N/A"}</td>
                    <td>{item.remark || "N/A"}</td>
                    <td>
                      {item.totalPending ? (
                        <h5>
                          <Badge
                            variant="danger"
                            style={{ cursor: "pointer" }}
                            onClick={() => viewOrders(true, item)}
                          >
                            {item.totalPending}
                          </Badge>
                        </h5>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <Button
                        variant="info"
                        onClick={(e) => editSupplier(e, item, index)}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))
              ) : isLoading ? (
                <tr>
                  <td colSpan={12} className="text-center">
                    <Spinner animation="grow" />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={12} className="text-center">
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            size="xl"
            centered
          >
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              autoComplete="false"
            >
              <Modal.Header closeButton>
                <Modal.Title>Update Supplier</Modal.Title>
              </Modal.Header>
              <Modal.Body key="modalBody">
                {Object.keys(editedItem).length ? (
                  <>
                    <Row>
                      <Col sm={6}>
                        <Form.Group controlId="checkboxReceiveEmail">
                          <Form.Check
                            type="switch"
                            ref={_switchInputReceiveEmailRef}
                            checked={receiveEmail}
                            id="checkboxReceiveEmail"
                            label="Will automatically receive order by email"
                            onChange={handleReceiveEmail}
                          />
                        </Form.Group>

                        <Form.Group controlId="formName">
                          <Form.Label>Name</Form.Label>
                          <InputGroup hasvalidation="true">
                            <Form.Control
                              type="text"
                              value={firstName}
                              ref={_firstNameInputRef}
                              placeholder="Name"
                              required
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                          <Form.Label>Email Login </Form.Label>
                          <InputGroup hasvalidation="true">
                            <Form.Control
                              type="email"
                              value={email}
                              ref={_emailInputRef}
                              isInvalid={email === ""}
                              placeholder="Email address"
                              required
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formEmail2">
                          <Form.Label>Email receiving orders</Form.Label>
                          <InputGroup hasvalidation="true">
                            <Form.Control
                              type="text"
                              value={email2}
                              ref={_email2InputRef}
                              isInvalid={email2 === "" || email2 === null}
                              placeholder="Email order"
                              required
                              onChange={(e) => setEmail2(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formPhone">
                          <Form.Label>Phone number</Form.Label>
                          <InputGroup hasvalidation="true">
                            <Form.Control
                              type="text"
                              value={phone}
                              ref={_phoneInputRef}
                              isInvalid={phone === ""}
                              placeholder="Phone number"
                              required
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="checkboxChangePassword">
                          <Form.Check
                            type="switch"
                            ref={_switchInputRef}
                            id="confirmChangePassword"
                            label="Change password?"
                            onChange={isChangePassword}
                          />
                        </Form.Group>
                        <ListGroup style={listGroupStyle}>
                          <ListGroup.Item>
                            <Form.Group controlId="formPassword">
                              <Form.Label>Password</Form.Label>
                              <InputGroup hasvalidation="true">
                                <Form.Control
                                  type="password"
                                  isInvalid={!isDisabled && password === ""}
                                  disabled={isDisabled}
                                  ref={_passwordInputRef}
                                  autoComplete="off"
                                  required
                                  onChange={handlePasswordChange}
                                  placeholder="Password"
                                />
                              </InputGroup>
                            </Form.Group>

                            <Form.Group controlId="formConfirmPassword">
                              <Form.Label>Confirm password</Form.Label>
                              <InputGroup hasvalidation="true">
                                <Form.Control
                                  type="password"
                                  isInvalid={
                                    !isDisabled && confirmPassword === ""
                                  }
                                  disabled={isDisabled}
                                  autoComplete="off"
                                  ref={_confirmPasswordInputRef}
                                  onChange={handlePasswordChange}
                                  required
                                  placeholder="Confirm password"
                                />
                              </InputGroup>
                            </Form.Group>
                          </ListGroup.Item>
                        </ListGroup>

                        <Form.Label>Express Settings</Form.Label>
                        <ListGroup style={listGroupStyle}>
                          <ListGroup.Item>
                            <Row className="mt-2">
                              <Col sm={6}>
                                Deli Express
                              </Col>
                              <Col sm={6}>
                                <Tooltip title="Click to update express setting" placement="top" arrow>
                                  {
                                    enableExpress?
                                        <Button variant="success"
                                                style={{
                                                  backgroundColor: "#49A641",
                                                  width: "12vh"
                                                }}
                                                onClick={() => handleOpenModalUpdateExpressSetting()}
                                        >
                                          Active
                                        </Button>:
                                        <Button variant="success"
                                                style={{
                                                  backgroundColor: "#49A641",
                                                  width: "12vh"
                                                }}
                                                onClick={() => handleOpenModalUpdateExpressSetting()}
                                        >
                                          Inactive
                                        </Button>
                                  }
                                </Tooltip>

                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col sm={6}>
                                Deli Express Multi
                              </Col>
                              <Col sm={6}>
                                <Tooltip title="Click to update express setting" placement="top" arrow>
                                  {
                                    allowMultiCart?
                                        <Button variant="success"
                                                style={{
                                                  backgroundColor: "#49A641",
                                                  width: "12vh"
                                                }}
                                                onClick={() => handleOpenModalUpdateExpressSetting()}
                                        >
                                          Active
                                        </Button>:
                                        <Button variant="success"
                                                style={{
                                                  backgroundColor: "#49A641",
                                                  width: "12vh"
                                                }}
                                                onClick={() => handleOpenModalUpdateExpressSetting()}
                                        >
                                          Inactive
                                        </Button>
                                  }
                                </Tooltip>

                              </Col>
                            </Row>
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col sm={6}>
                      <Form.Group controlId="formSettings">
                          <Form.Label></Form.Label>
                        </Form.Group>

                        <Form.Group controlId="formDeliveryTime">
                          <Form.Label>Delivery time:</Form.Label>
                          <InputGroup hasvalidation="true">
                            <Form.Control
                                type="time"
                                value={deliveryTime}
                                required
                                isInvalid={formError.deliveryTime}
                                onChange={(e) => setDeliveryTime(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formCloseDeliveryTime">
                          <Form.Label>Close delivery time:</Form.Label>
                          <InputGroup hasvalidation="true">
                            <Form.Control
                                type="time"
                                value={closeDeliveryTime}
                                isInvalid={formError.closeDeliveryTime}
                                required
                                onChange={(e) =>
                                    setCloseDeliveryTime(e.target.value)
                                }
                            />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="formEstimateDeliveryTime">
                          <Form.Label>Delivery estimate(minute):</Form.Label>
                          <InputGroup hasvalidation="true">
                            <Form.Control
                                isInvalid={formError.estimateDeliveryTime}
                                required
                                type="number"
                                placeholder="0"
                                value={estimateDeliveryTime}
                                onChange={(e) =>
                                    setEstimateDeliveryTime(e.target.value)
                                }
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formAddress">
                          <Form.Label>Address:</Form.Label>
                          <InputGroup hasvalidation="true">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>

                          {/*<ListGroup.Item>*/}
                            {/*<Form.Group controlId="formEnableExpress">*/}
                              {/*<Form.Check*/}
                                {/*type="switch"*/}
                                {/*id="enableExpress"*/}
                                {/*label="Deli Express"*/}
                                {/*checked={enableExpress}*/}
                                {/*onChange={(e) =>*/}
                                  {/*setEnableExpress(!enableExpress)*/}
                                {/*}*/}
                              {/*/>*/}
                            {/*</Form.Group>*/}

                            {/*<Form.Group controlId="formAllowMultiCart">*/}
                              {/*<Form.Check*/}
                                {/*type="switch"*/}
                                {/*disabled={!enableExpress}*/}
                                {/*checked={allowMultiCart}*/}
                                {/*id="allowMultiCart"*/}
                                {/*label="Deli Express Multi"*/}
                                {/*onChange={(e) =>*/}
                                  {/*setAllowMultiCart(!allowMultiCart)*/}
                                {/*}*/}
                              {/*/>*/}
                            {/*</Form.Group>*/}
                          {/*</ListGroup.Item>*/}

                        <Form.Label>Opening hours</Form.Label>
                        <ListGroup style={listGroupStyle}>
                          <ListGroup.Item>
                            <SupplierOpeningHours>
                              <div className="opening-hours">
                                <div className="item-list">
                                  <div className="item">
                                    <Form.Group></Form.Group>
                                    <InputGroup>
                                      <p>Store open:</p>
                                    </InputGroup>
                                    <InputGroup>
                                      <p>Store close:</p>
                                    </InputGroup>
                                  </div>
                                </div>
                                {openingHours.length? openingHours.map((item, index) =>
                                    <div key={index} className="item-list">
                                      <div className="item">
                                        <Form.Group controlId={`${dayList[item.day].toLowerCase()}Checkbox`}>
                                          <Form.Check type="checkbox"
                                                      id={dayList[item.day]}
                                                      label={dayList[item.day]}
                                                      value={item.is_available}
                                                      checked={item.is_available}
                                                      onChange={(e) => handleEachOpeningHoursToggle(e, index)}
                                          />
                                        </Form.Group>
                                        <InputGroup>
                                          <Form.Control size="md"
                                                        name={`${dayList[item.day].toLowerCase()}OpeningInput`}
                                                        type="time"
                                                        placeholder="00:00"
                                                        maxlength={5}
                                                        value={item.start_time || ''}
                                                        disabled={!item.is_available}
                                                        isInvalid={openingHoursValidationErrors[index]}
                                                        onBlur={(e) => handleCheckValidateOpeningHours(index)}
                                                        onChange={(e) => handleOpeningHoursChange(e, index, 'opening')}
                                          />
                                        </InputGroup>
                                        <InputGroup>
                                          <Form.Control size="md"
                                                        type="time"
                                                        name={`${dayList[item.day].toLowerCase()}CloseInput`}
                                                        placeholder="00:00"
                                                        maxlength={5}
                                                        value={item.end_time || ''}
                                                        disabled={!item.is_available}
                                                        isInvalid={openingHoursValidationErrors[index]}
                                                        onBlur={(e) => handleCheckValidateOpeningHours(index)}
                                                        onChange={(e) => handleOpeningHoursChange(e, index, 'close')}
                                          />
                                        </InputGroup>
                                      </div>
                                    </div>
                                ): <></>}
                              </div>
                            </SupplierOpeningHours>
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <p className="text-center">Supplier not found.</p>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button disabled={isLoading} variant="primary" type="submit">
                  Update
                </Button>
                <Button
                  disabled={isLoading}
                  variant="secondary"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {/*
            Modal udpate express setting
          */}
          <Dialog open={isOpenModalUpdateExpressSetting} onClose={handleCloseModalUpdateExpressSetting}>
            <DialogTitle>Update express setting</DialogTitle>
            <DialogContent dividers>
              <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  row
                  value={allowEnableExpressSetting}
                  onChange={handleChangeEnableExpress}
              >
                <label style={{...labelStyle, width: "25vh"}}>Deli Express</label>
                <FormControlLabel value={true} control={<Radio disabled={isRequestingUpdateExpressSeeting} color='primary'/>} label="Active" />
                <FormControlLabel value={false} control={<Radio disabled={isRequestingUpdateExpressSeeting} color='primary'/>} label="Inactive" />
              </RadioGroup>
              <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  row
                  value={allowMultiCartExpressSetting}
                  onChange={handleChangeAllowMultiCart}
              >
                <label style={{...labelStyle, width: "25vh"}}>Deli Express Multi</label>
                <FormControlLabel value={true} control={<Radio disabled={isRequestingUpdateExpressSeeting || !allowEnableExpressSetting} color='primary'/>} label="Active" />
                <FormControlLabel value={false} control={<Radio disabled={isRequestingUpdateExpressSeeting || !allowEnableExpressSetting} color='primary'/>} label="Inactive" />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button variant="primary" disabled={isRequestingUpdateExpressSeeting} onClick={handleUpdateExpressSetting}>
                {
                  isRequestingUpdateExpressSeeting?
                      <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                      />:
                      null
                }

                <span className="pl-2 pr-2">Update</span>
              </Button>

              <Button disabled={isRequestingUpdateExpressSeeting} variant="secondary" onClick={handleCloseModalUpdateExpressSetting}>Close</Button>
            </DialogActions>
          </Dialog>

          <OrdersListsDialog
            dialog={dialogOrderDetail}
            supplier={editedItem}
            orders={itemsOrders}
            noItemsOrders={noItemsOrders}
            clearFilter={clearFilter}
            onFilter={handleFilter}
            currentTotal={currentTotal}
            totalOrder={totalOrder}
            updateOrder={updateOrder}
            fetchingData={fetchingData}
            onLoadMore={handleOrderListLoadMore}
            onClick={() => viewOrders(false)}
          />

            <Toast className={toastBgColor}
                   onClose={() => setShowToast(false)}
                   show={showToast}
                   delay={3000}
                   autohide
                   style={{
                       position: 'fixed',
                       top: 70,
                       right: 35,
                       zIndex: 1041,
                       width: "300px"
                   }}
            >
                <Toast.Header>
                    <strong className="mr-auto m-3">{toastMessage}</strong>
                </Toast.Header>
            </Toast>
        </Col>
      </Row>

    </HelpPageWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    suppliersLists: state.dashboardReducer.suppliersLists,
    supplierOrders: state.dashboardReducer.supplierOrders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    initSuppliersLists: (data) => initSuppliersLists(data, dispatch),
    initSupplierOrders: (data) => initSupplierOrders(data, dispatch),

    updateSuppliersLists: (data) => updateSuppliersLists(data, dispatch),
    updateSupplierOrders: (data) => updateSupplierOrders(data, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);
