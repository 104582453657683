import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const PromoCard = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 253px;
    font-size: 23px;
    .card-height{
        height: 253px;
    }

    @media (max-width: 767px) {
        height: 168px;
    }
`;

export const TrendingCard = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 468px;
    font-size: 30px;
    .card-height{
        height: 468px;
    }

    @media (max-width: 767px) {
        height: 255px;
    }
`;