import React from "react";
import { Route, Switch, BrowserRouter, Router, Redirect, useHistory } from "react-router-dom";
import HomePage from "../pages/grocery";
import Profile from "../pages/profile";
import CheckoutPage from "../pages/checkout";
import OrderPage from "../pages/order";
import Help from "../pages/help";
import GiftCardPage from "../pages/offer";
import OrderRecived from "../pages/order-received";
import CurrentOrderPage from "../pages/current-order";
import AllOrderPage from "../pages/all-orders";
import PaymentScreen from "../pages/payment";
import AboutScreen from "../pages/about";
import ContactScreen from "../pages/contact";
import TermsScreen from "../pages/terms";
import Updates from "../pages/updates";
import DeliveryInformationScreen from "../pages/delivery-info";
import SearchedResults from "../pages/searchresults";
import ProductData from "../pages/productinfo";
import SupplierLoginScreen from "../pages/supplier/supplier-login";
import SupplierLoginOnlyScreen from "../pages/supplier/supplier-login-only";
import ResetPasswordOTP from "../components/ResetPasswordOTP";
import supplierDashboardScreen from "../pages/supplier/supplier-dashboard";
import adminDashboardScreen from "../pages/admin/dashboard";
import adminSuppliersScreen from "../pages/admin/suppliers";
import adminProducts from "../pages/admin/productList";
import adminCustomers from "../pages/admin/customers";
import adminProductUpdate from "../pages/admin/productUpdate";
import adminProductAdd from "../pages/admin/productAdd";
import adminCategoriesScreen from "../pages/admin/categories";
import adminCategorieUpdate from "../pages/admin/categoriesUpdate";
import adminSubCategoriesScreen from "../pages/admin/subCategories";
import adminCategorieAdd from "../pages/admin/categoriesAdd";
import adminSubCategoriesAdd from "../pages/admin/subCategoriesAdd"
import adminSubCategorieUpdate from "../pages/admin/subCategoriesUpdate";
import BulkUploads from "../pages/admin/bulkUploads"
import BulkImageUploads from "../pages/admin/bulkImageUplodes"
import adminBrands from "../pages/admin/brands"
import adminBrandAdd from "../pages/admin/brandAdd"
import adminBrandUpdate from "../pages/admin/brandUpdate"
import OrderList from "../pages/admin/orderList"
import OrderDetails from "../pages/admin/orderDetails"
import SupplierOrderList from "../pages/supplier/supplier-orderList"
import supplierOrderDetails from "../pages/supplier/supplier-orderDetails"
import NotFound from "../pages/notfound";
import BrandPage from "../pages/brands";
import { connect } from "react-redux";
import { cats } from "../helper/constant";
import BulkExtract from "../pages/admin/bulkExtract";
import UpdateBanner from "../pages/admin/banner_images_update"
import ExpressPage from '../pages/express/express';
import ExpressSingleStore from '../pages/express/express-single-store';
import ExpressSearchResult from "../pages/express/express-search-result";
import DeliExpressSettings from "../pages/admin/deliExpressSettings";
import Order from "../pages/admin/order";
import DriverList from "../pages/admin/driver";
import WalletList from "../pages/admin/wallet";
import BasketList from "../pages/admin/basket";
import OrderMap from "../pages/admin/orderMap";
import SlideBanner from "../pages/admin/slide-banner";
import { orderBy } from "lodash";
import RefundPolicies from "../pages/refund-policies";

let admin = localStorage.getItem("admin");
let supplier = localStorage.getItem("supplier");

const Routes = (props,{userAgent}) => {
  const history = useHistory();

  if(history.location.search !== '') {
    let query = history.location.search;
    query = query.split('=');
    if(query[0].includes('_locale'))
      localStorage.setItem('lang', query[1])
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route key="Home" exact path="/" name="Home" component={() => (admin? <Redirect to="/admin/orders"/>: supplier? <Redirect to="/supplier/orders"/>: <HomePage />)} />
        <Route key="Register" exact path="/register" name="Register" component={() => <HomePage />} />
        <Route key="Profile" exact path="/profile" name="Profile" component={Profile} />
        <Route key="BrandPage" exact path="/brand/:brandName" name="BrandPage" component={() => <SearchedResults />} />
        <Route
          exact
          key="supplierLogin"
          path="/manager/login"
          name="supplierLogin"
          component={SupplierLoginScreen} />
        <Route
          exact
          key="supplierLoginOnly"
          path="/supplier/login"
          name="supplierLoginOnly"
          component={SupplierLoginOnlyScreen} />
        <Route
          exact
          key="supplierResetPassword"
          path="/supplier/reset-password"
          name="supplierResetPassword"
          component={ResetPasswordOTP} />
        <Route
          exact
          key="supplierDashboard"
          path="/supplier/dashboard"
          name="supplierDashboard"
          component={supplierDashboardScreen} />
           ## Supplier dashboard ##


        <Route exact key="orderList" path="/supplier/orders" name="orderList" component={SupplierOrderList} />
        <Route exact key="supplierOrderDetails" path="/supplier/order/details/:id" name="supplierOrderDetails" component={supplierOrderDetails} />


          ## ADMIN dashboard ##
        <Route exact key="supplierDashboard" path="/admin/dashboard" name="supplierDashboard" component={adminDashboardScreen} />
        <Route exact key="suppliersDashboard" path="/admin/suppliers" name="supplierDashboard" component={adminSuppliersScreen} />
        <Route exact key="adminProducts" path="/admin/products" name="adminProducts" component={adminProducts} />
        <Route exact key="adminCustomers" path="/admin/customers" name="adminCustomer" component={adminCustomers} />
        <Route exact key="adminProductAdd" path="/admin/product/add" name="adminProductAdd" component={adminProductAdd} />
        <Route exact key="adminProductUpdate" path="/admin/product/update/:id" name="adminProductUpdate" component={adminProductUpdate} />
        <Route exact key="supplierCategories" path="/admin/categories" name="supplierCategories" component={adminCategoriesScreen} />
        <Route exact key="adminCategorieUpdate" path="/admin/categorie/update" name="adminCategorieUpdate" component={adminCategorieUpdate} />
        <Route exact key="supplierSubCategories" path="/admin/subCategories" name="supplierSubCategories" component={adminSubCategoriesScreen} />
        <Route exact key="adminSubCategorieUpdate" path="/admin/subCategorie/update" name="adminSubCategorieUpdate" component={adminSubCategorieUpdate} />
        <Route exact key="adminCategorieAdd" path="/admin/categorie/add" name="adminCategorieAdd" component={adminCategorieAdd} />
        <Route exact key="adminSubCategoriesAdd" path="/admin/subCategories/add" name="adminSubCategoriesAdd" component={adminSubCategoriesAdd} />
        <Route exact key="adminBrand" path="/admin/brands" name="adminBrand" component={adminBrands} />
        <Route exact key="adminBrandAdd" path="/admin/brand/add" name="adminBrandAdd" component={adminBrandAdd} />
        <Route exact key="adminBrandUpdate" path="/admin/brand/update" name="adminBrandUpdate" component={adminBrandUpdate} />
        <Route exact key="adminBulkUpload" path="/admin/bulkUploads" name="adminBulkUpload" component={BulkUploads} />
        <Route exact key="adminBulkImageUpload" path="/admin/bulkImageUploads" name="adminBulkImageUpload" component={BulkImageUploads} />
        {/* <Route exact key="orderList" path="/admin/orders" name="orderList" component={OrderList} /> */}
        <Route exact key="order" path="/admin/orders" name="order" component={Order} />
        <Route exact key="adminOrderDetails" path="/admin/order/details/:id" name="adminOrderDetails" component={OrderDetails} />
        <Route exact key="adminBulkExtract" path="/admin/bulkExtract" name="adminBulkExtract" component={BulkExtract} />
        <Route exact key="adminBannerUpdate" path="/admin/banner_update" name="adminBannerUpdate" component={UpdateBanner} />
        <Route exact key="deliExpressSettings" path="/admin/deli_express_settings" name="deliExpressSetting" component={DeliExpressSettings} />
        <Route exact key="driverList" path="/admin/drivers" name="driverList" component={DriverList} />
        <Route exact key="walletList" path="/admin/wallets" name="walletList" component={WalletList} />
        <Route exact key="basketList" path="/admin/baskets" name="basketList" component={BasketList} />
        <Route exact key="orderMap" path="/admin/order_map" name="orderMap" component={OrderMap} />
        <Route exact key="slideBanner" path="/admin/slide-banner" name="slideBanner" component={SlideBanner} />
        {cats.map(path => (
          <Route exact key={"/"+path} path={"/"+path} name="SearchResults" component={SearchedResults}/>
        ))}
        <Route
          key="Search"
          exact
          path="/search/:text"
          name="SearchResults"
          component={() => <SearchedResults />} />
        <Route
          key="CatSubCat"
          exact
          path="/:catrefid/:subcatrefid"
          name="SearchResults"
          component={() => <SearchedResults />} />
        <Route
          exact
          key="Checkout"
          path="/checkout"
          name="Checkout"
          component={CheckoutPage} />

        {/*Delishop Express Route*/}
        <Route exact
               key="ExpressPage"
               path="/express"
               name="ExpressPage"
               component={ExpressPage}
        />
        <Route exact
               key="ExpressSearchResult"
               path="/express/search/:any"
               name="ExpressSearchResult"
               component={ExpressSearchResult}
        />
        <Route exact
               key="ExpressSingleStore"
               path="/express/store/:name"
               name="ExpressSingleStore"
               component={ExpressSingleStore}
        />
        {/*End Delishop Express Route*/}

        <Route exact path="/order" name="Order" component={OrderPage} />
        <Route
          exact
          key="CurrentOrderPage"
          path="/ongoing-orders"
          name="CurrentOrderPage"
          component={CurrentOrderPage} />
        <Route key="help" exact path="/help" name="Help" component={Help} />
        <Route key="Offer" exact path="/offer" name="Offer" component={GiftCardPage} />
        <Route
          exact
          key="orderReceived"
          path="/order-received"
          name="orderRecieved"
          component={OrderRecived} />
        <Route
          exact
          key="AllOrderPage"
          path="/all-orders"
          name="AllOrderPage"
          component={AllOrderPage} />
        <Route
          exact
          key="PaymentScreen"
          path="/payments"
          name="PaymentScreen"
          component={PaymentScreen} />
        <Route
          exact
          key="RefundPolicies"
          path="/refund-policies"
          name="RefundPolicies"
          component={RefundPolicies} />
        <Route key="About" exact path="/about" name="AboutScreen" component={AboutScreen} />
        <Route key="Contact" exact path="/contact" component={ContactScreen} />
        <Route key="Terms" exact path="/terms" component={TermsScreen} />
        {/*<Route key="Updates" exact path="/updates" component={Updates} />*/}
        <Route key="Delivery" exact path="/delivery" component={DeliveryInformationScreen} />
        <Route key="AllBrands" exact path="/brands" component={BrandPage} />
        <Route key="NotFound" exact path="/not-found" component={NotFound} />
        <Route
          exact
          key="Product"
          path="/:pid"
          name="ProductPage"
          component={(props) => <ProductData props={props} />}
        />
         <Route key="NF" component={NotFound} />
        {/* <Route render={() => <Redirect to={{pathname: "/"}} />} /> */}
      </Switch>
    </BrowserRouter>
  );
}

Routes.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};
const mapStateToProps = (state) => {
  return {
    cat_refs: state.productReducer.cat_refs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
