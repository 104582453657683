import React from "react";

import {Toast} from "react-bootstrap";
import "react-datetime/css/react-datetime.css";

const ToastNotification = (props) => {

  const {toastBgColor, toastMessage, stylesheet, showToast} = props;

  const hideToast = () => props.onClose(false);

  return (
    <>
      <Toast className={toastBgColor}
             onClose={() => hideToast()}
             show={showToast}
             delay={3000}
             autohide
             style={stylesheet}
      >
        <Toast.Header>
          <strong className="mr-auto">{toastMessage}</strong>
        </Toast.Header>
      </Toast>
    </>
  )
};

ToastNotification.defaultProps = {
  toastBgColor: "bg-success",
  toastMessage: "Update success",
  show: false,
  stylesheet: {
    position: 'fixed',
    top: 70,
    right: 35,
    zIndex: 1051,
  }
};

export default ToastNotification

