import styled from "styled-components";

export const BrandWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fff;
  position: relative;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  padding: 5px 40px;
`;

export const BrandImageWrapper = styled.div`
  // width: 100%;
  height: 210px;
  padding: 5px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .product-image{
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 210px;
    
    &.express { width: 100%; max-width: 150px; max-height: 100px; }
  }

  @media (max-width: 767px) {
    // height: 145px;

    .product-image{
      height: 100%;
      width: auto;
      max-width: 100%;
      max-height: 115px;
    }
  }
  // img {
  //   max-width: 100%;
  //   max-height: 100%;
  //   height: 100%;
  //   display: inline-block;
  // }

  @media (max-width: 767px) {
    height: 125px;
  }

  // &:after {
  //   content: " ";
  //   display: block;
  //   position: absolute;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   background: rgba(0, 0, 0, 0.3);
  // }
  .ribbon {
    text-transform: uppercase;
    color: white;
  }
  .ribbon3 {
    font-size: 11px;
    width: 80px;
    height: 23px;
    line-height: 23px;
    padding-left: 0px;
    position: absolute;
    left: -8px;
    top: 20px;
    background: #389244;
  }
  .ribbon3:before,
  .ribbon3:after {
    content: "";
    position: absolute;
  }
  // .ribbon3:before {
  //   height: 0;
  //   width: 0;
  //   top: -8.5px;
  //   left: 0.1px;
  //   border-bottom: 9px solid black;
  //   border-left: 9px solid transparent;
  // }
  .ribbon3:before {
    height: 0;
    width: 0;
    bottom: -8.5px;
    left: 0.1px;
    border-top: 9px solid black;
    border-left: 9px solid transparent;
  }
  // .ribbon3:after {
  //   height: 0;
  //   width: 0;
  //   right: -14.5px;
  //   border-top: 11px solid transparent;
  //   border-bottom: 12px solid transparent;
  //   border-left: 15px solid #389244;
  // }
  .ribbon3:after {
    height: 0;
    width: 0;
    right: -10.5px;
    border-top: 12px solid #389244;
    border-bottom: 11px solid #389244;
    border-left: 0px solid #389244;
    border-right: 11px solid transparent;
  }
  @media (max-width: 767px) {
    .loaderStyle {
      position: absolute;
      top: calc(100% - 72px);
      right: calc(100% - 95px);
      z-index: 1000;
    }
  }
  @media (min-width: 767px) {
    .loaderStyle {
      position: absolute;
      top: calc(100% - 115px);
      right: calc(100% - 120px);
      z-index: 1000;
    }
  }
`;

export const BrandName =  styled.div`
    color: #000000;
    font-size: 18px;
    font-weight: 750;
    text-align: center;
    padding-bottom: 5px;
`;