import React,{useState, useEffect} from "react";
import { Plus, Minus } from "../../assets/icons/plus-minus";
import { CounterBox, CounterButton, CounterValue } from "./Counter.style";
import Spinner from 'react-bootstrap/Spinner';

export const Counter = ({
  onDecrement,
  onIncrement,
  onUpdate,
  value,
  variant,
  className,
  id,
  isLoading
}) => {
  const [val, setVal] = useState(value);
  return (
    <CounterBox variant={variant} className={className}>
      <CounterButton onClick={(e)=>{onDecrement(e); setVal(val-1);}} variant={variant} disabled={isLoading}>
        {isLoading
          ? <Spinner animation="border" size="sm" />
          : <Minus />
        }
      </CounterButton>
      <CounterValue>
        {/* <InputGroup size="sm">
          <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" type="tel" value={value} onInput ={(e) => {
            e.stopPropagation();
            if(e.target.value <= 0){
             setVal(e.target.value);
             onUpdate(e.target.value);
            }
            else{
             setVal(e.target.value);
             onUpdate(e.target.value);
            }
          } 
          } />
        </InputGroup> */}
        {value}
      </CounterValue>
      <CounterButton onClick={(e)=>{
        onIncrement(e); 
        setVal(val+1);
      //   setTimeout(() => {
      //   val !== value ? setVal(value+1) : setVal(val)
      // }, 2000); 
      }} variant={variant} disabled={isLoading}>
        {isLoading
          ? <Spinner animation="border" size="sm" />
          : <Plus />
        }
      </CounterButton>
    </CounterBox>
  );
};
