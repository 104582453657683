import React, { useEffect} from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import QuickView from "../containers/QuickView/QuickView";
import CartPopUp from "../containers/Cart/CartPopUp";
import { useDeviceType } from "../helper/useDeviceType";
import { connect } from "react-redux";
import ReadMore from "../components/Truncate/Truncate";
import PageLoader from "../components/PageLoader/PageLoader";
import { CloseIcon, CartIcon } from "../components/AllSvgIcon";
import { cartAnimation } from "../helper/cart-animation";
import { useCart } from "../contexts/cart/use-cart";
import Button from "react-bootstrap/Button";
import BrandsHome from "../containers/BrandsHome/BrandsHome";
import { Counter } from "../components/Counter/Counter";
import { QuantityModifier } from "../components/QuantityModifier/QuantityModifier";
import { AuthContext } from "../contexts/auth/auth.context";
import { SearchContext } from "../contexts/search/search.context";
import {
  productCategory,
  ProductModalData,
  ResetModalData,
  searchSuggestions,
  searchStoreProduct,
  getProductsMappedwithSubCat,
  getProductsMappedwithCat,
  getProductOutOfStock,
  getProductOutOfStockCat,
  getProductBrands,
  getProductFilterBrands,
  getSortProductsName,
  getSortProductsPrice,
  sortByNameProduct,
  resetPage,
  changePage
} from "../store/actions/productAction";
import {
  oosModified,
  // resetPage,
  // changePage
} from "../store/actions/globalAction";
import Sidebar from "../containers/Sidebar/Sidebar";
import Products from "../containers/Products/Products";
import StoreNav from "../components/StoreNav/StoreNav";
import storeType from "../constants/storeType";
import {
  MainContentArea,
  SidebarSection,
  ContentSection,
  OfferSection,
  MobileCarouselDropdown,
} from "../styled/pages.style";
import { compDate, isTodayApplicable } from "../constants/Constant";
import LazyLoad from "react-lazyload";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Carousel from "react-multi-carousel";
import Brand from "../components/Brand/Brand";
import t from '../i18n/translate';

const ProductData = (props, { userAgent }) => {
  let mql = window.matchMedia("all and (min-width: 769px)");
  let mqs = window.matchMedia("all and (max-width: 768px)");
  const deviceType = useDeviceType(userAgent);
  const history = useHistory();
  const { pid } = props.props.match.params;
  const {
    addItemToCart,
    addItem,
    removeItem,
    removeItemFromCart,
    updateItem,
    isInCart,
    getItem,
    fetchOrderItems,
    cartclose
  } = useCart();
  const { state, searchdispatch } = React.useContext(SearchContext);
  const targetRef = React.useRef(null);
  const PAGE_TYPE = "grocery";
  const {
    authState: { isAuthenticated },
    authDispatch,
  } = React.useContext(AuthContext);
  const lang = localStorage.getItem('lang') || 'en';

  window.onbeforeunload = () => {
    cartclose();
  };
  const handleAddClick = (e) => {
    e.stopPropagation();
    if (!localStorage.getItem("user")){
      document.getElementById("joinBTn").click();
    }
    else{
    if (!isInCart(props.modalData.item_id)) {
      addItemToCart(props.modalData);
      cartAnimation(e);
    } else {
      addItem(getItem(props.modalData.item_id));
    }
  }
  };

  const handleRemoveClick = (e) => {
    e.stopPropagation();
    if (!localStorage.getItem("user")){
      document.getElementById("joinBTn").click();
    }
    else{
      if (getItem(props.modalData.item_id).quantity == 1) {
        removeItemFromCart(props.modalData);
      } else {
        removeItem(getItem(props.modalData.item_id));
      }
    }
  };

  const productKeywords = () => {
    var pname = (props.modalData.name).split("-").join(" ");
    return pname.split(" ").join(", ");
  };

  useEffect(() => {
    document.getElementsByClassName("product-cart")[0].style.visibility = "hidden";
    if(!(props.category.length > 0 && props.brands.length > 0)){
      props.productCategory({ get_type: 2, store_id: 1, lang });
      props.getProductBrands();
    }
  }, []);

  useEffect(() => {
    if (props.modalData && props.category !== undefined && props.products.length === 0) {
      window.scrollTo(0, 0);
      localStorage.setItem("sub_cat_id", props.modalData.sub_cat_id);
      if(!props.oos){
        localStorage.setItem("mode","subcatoos");
        props.resetPage();
        props.getProductOutOfStock({
          sub_cat_id: props.modalData.sub_cat_id,
          store_id: 1,
          page: 1,
          oos: props.oos,
          brand_id: 0,
          lang
        });
      }
      else{
        localStorage.setItem("mode","subcat");
        props.resetPage();
        props.getProductsMappedwithSubCat({
          sub_cat_id: props.modalData.sub_cat_id,
          store_id: 1,
          page: 1,
          oos: props.oos,
          brand_id: 0,
          lang
        });
      }
      if(localStorage.getItem("user")){
        fetchOrderItems();
      }
    }
  }, [props.modalData]);

  useEffect(() => {
    if (pid !== undefined && props.modalData !== null && props.category.length > 0) {
      let body = {};
      body["item_ref_id"] = pid;
      body["lang"] = lang;
      props.ProductModalData(body);
    }
  }, [pid]);

  useEffect(() => {
    if(props.productfetch == false){
      history.push(`/not-found`);
    }
  },[props.productfetch]);

  return (
    <>
     { props.modalData ? (
    <>
      <Helmet defer={false}>
        <link 
          rel="canonical" 
          href={"https://delishop.asia"+window.location.pathname} 
        />
        <title>{props.modalData.name ? props.modalData.name : 'Delishop'}</title>
        <meta 
          name="title" 
          content={props.modalData.name ? props.modalData.name : 'Delishop'} 
        /> 
        <meta 
          property="og:title" 
          content={props.modalData.name ? props.modalData.name : 'Delishop'} 
        />
        <meta 
          property="og:image" 
          content={props.modalData.default_image_url ? props.modalData.default_image_url : ''} 
        />
        <meta 
          property="og:url" 
          content={"https://delishop.asia"+window.location.pathname} 
        />
        <meta 
          property="og:description" 
          content={ props.modalData.about_item ? props.modalData.about_item : props.modalData.name } 
        />
        <meta 
          name="description" 
          content={ props.modalData.about_item ? props.modalData.about_item : props.modalData.name } 
        />
        <meta
          name="keywords"
          content={props.modalData.name ? productKeywords() : ""}
          data-react-helmet="true"
        />
      </Helmet>
     </> ) : null
    }
    {/* {props.isloading && <PageLoader />} */}
      {window.location.pathname.split("/").length > 1 &&
      window.location.pathname.split("/").length <= 2 &&
      window.location.pathname.split("/")[1] !== "" && props.modalData && props.modalData.name ? (
        <QuickViewWrapper className="product-page" itemScope itemType="http://schema.org/Product" style={{ paddingTop: mql.matches ? "70px" : "10vh" }}>

          <div className="bread-crumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item href="/">{ t('shop_address_home_label') }</Breadcrumb.Item>
              <Breadcrumb.Item href={"/"+pid} active>
                {props.modalData.name}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <ProductDetailsWrapper className="product-card" dir="ltr">
            <ProductPreview>
              <LazyLoad height={180} placeholder={<img src={require('../image/Background.png')} style={{position: "relative", height: "180px", width: "auto"}} />}>
                  <img src={props.modalData.default_image_url} className="product-image" alt="product_image"/>
              </LazyLoad>
            </ProductPreview>
            <ProductInfoWrapper dir={"ltr"}>
              <ProductInfo>
                {props.modalData &&
                  props.modalData.list_brands &&
                  props.modalData.list_brands.length > 0 && (
                    <div itemProp={"brand"}>{props.modalData.list_brands[0].brand_name}</div>
                  )}
                <meta itemProp="sku" content={props.modalData.product_code} />
                <ProductTitlePriceWrapper>
                  <ProductTitle itemProp={"name"}>
                    {props.modalData.name !== " "
                      ? props.modalData.name
                      : props.modalData.weight +
                        " " +
                        props.modalData.weight_unit}
                  </ProductTitle>
                </ProductTitlePriceWrapper>
                <ProductPriceWrapper itemProp={"offers"} itemScope itemType={"https://schema.org/Offer"}>
                  {props.modalData &&
                    props.modalData.store_items_price_types &&
                    props.modalData.store_items_price_types[0].mrp !=
                      props.modalData.store_items_price_types[0].price && (
                      <ProductPrice className={ props.modalData && props.modalData.promo === 1 && props.modalData.promo_start_date !== null && props.modalData.promo_end_date !== null && compDate(props.modalData.promo_start_date, props.modalData.promo_end_date)  ? "strike-through" : "" }>
                        <span itemProp={"priceCurrency"} content={"USD"}>{"$"}</span>
                        <span itemProp={"price"} content={props.modalData.store_items_price_types[0].price}>{props.modalData &&
                          parseFloat(props.modalData.store_items_price_types[0].price).toFixed(2)}</span>
                          
                      </ProductPrice>
                    )}
                    { props.modalData && props.modalData.promo === 1 && props.modalData.promo_start_date !== null && props.modalData.promo_end_date !== null && compDate(props.modalData.promo_start_date, props.modalData.promo_end_date) && (
                       <PromoPrice>
                          <span itemProp={"priceCurrency"} content={"USD"}>{"$"}</span>
                          <span itemProp={"price"} content={props.modalData.promo_price}>
                            {parseFloat(props.modalData.promo_price).toFixed(2)}
                          </span>
                          <meta itemProp="priceValidUntil" content={props.modalData.promo_end_date} />
                       </PromoPrice>
                    )}
                    <meta itemProp="url" content={"https://delishop.asia"+window.location.pathname} />
                </ProductPriceWrapper>

                {props.modalData.same_day === 1
                  ? <div className="w-100" style={{ color: "#000", fontSize: "1.5rem", marginBottom: 15 }}>
                    <span className="bg-success pb-1 px-1 rounded text-white">{ t('shop_item_get_same_day') }</span>
                    &nbsp;&nbsp;
                    {props.modalData.promo === 1 && props.modalData.promo_start_date !== null && props.modalData.promo_end_date !== null && compDate(props.modalData.promo_start_date, props.modalData.promo_end_date) && <span className="bg-danger pb-1 px-1 rounded text-white">
                      { t('shop_item_promo_label') }
                    </span>}
                  </div>
                  : <div className="w-100" style={{ color: "#000", fontSize: "1.5rem", marginBottom: 15 }}>
                    <span className="bg-warning pb-1 px-1 rounded text-white">{ t('shop_item_get_tomorrow') }</span>
                    &nbsp;&nbsp;
                    {props.modalData.promo === 1 && props.modalData.promo_start_date !== null && props.modalData.promo_end_date !== null && compDate(props.modalData.promo_start_date, props.modalData.promo_end_date) && <span className="bg-danger pb-1 px-1 rounded text-white">
                      { t('shop_item_promo_label') }
                    </span>}
                  </div>
                }

                {props.modalData && props.modalData.store_items_price_types
                  ? <ProductWeight itemProp={"weight"}>{props.modalData.store_items_price_types[0].name}</ProductWeight>
                  : null
                }

                <div className={`availability ${props.cartType}`}>
                  <p>{ t('shop_item_stock_availability_label') }:&nbsp;
                    { !props.modalData.store_items_price_types[0].available_quantity
                      ? <span className="stock-label out-of-stock">{ t('shop_item_out_of_stock') }</span>
                      : <span className="stock-label">{ t('shop_item_in_stock_label') }</span>
                    }
                  </p>
                  <p className="product-code">{ t('shop_item_sku') }: {props.modalData.product_code}</p>
                </div>

                {props.modalData && props.modalData.store_items_price_types
                  ? <ProductCartWrapper>
                    <ProductCartBtn>
                      { !props.modalData.store_items_price_types[0].available_quantity
                        ? <div style={{ color: "red" }}>{ t('shop_item_out_of_stock') }</div>
                        : <>
                          <QuantityModifier
                            className={"pr"+props.modalData.item_id}
                            value={isInCart(props.modalData.item_id) ? getItem(props.modalData.item_id).quantity : 0}
                            onDecrement={handleRemoveClick}
                            onIncrement={handleAddClick}
                            onUpdate={(quantity)=>{updateItem(props.modalData,quantity)}}
                          />
                          <Button variant="success"
                                  className={`btn btn-outline-success pr${props.modalData.item_id}`}
                                  onClick={handleAddClick}
                          ><span style={{ position: 'relative', top: -2, marginRight: 5 }}><CartIcon /></span>{ t('button_add_to_cart') }</Button>
                        </>
                      }
                    </ProductCartBtn>
                  </ProductCartWrapper>
                  : null
                }

                <div className="product-info-wrapper">
                  <div className="product-content">
                    <div className="product-detail">
                      {props.modalData.about_item && (
                        <>
                          <div className="productInfoTitle">
                            <strong>{ t('home_item_popup_about_label') }</strong>
                          </div>
                          <ProductDescription itemProp={"description"}>
                            <ReadMore character={600}>
                              {props.modalData.about_item}
                            </ReadMore>
                          </ProductDescription>
                        </>
                      )}
                      {props.modalData.more_item_info && (
                        <>
                          <div className="productInfoTitle">{ t('home_item_popup_item_info') }</div>
                          <ProductDescription>
                            <ReadMore character={600}>
                              {props.modalData.more_item_info}
                            </ReadMore>
                          </ProductDescription>
                        </>
                      )}
                      {props.modalData.how_to_use && (
                        <>
                          <div className="productInfoTitle">
                            <strong>{ t('home_item_popup_how_to_use') }</strong>
                          </div>
                          <ProductDescription>
                            <ReadMore character={600}>
                              {props.modalData.how_to_use}
                            </ReadMore>
                          </ProductDescription>
                        </>
                      )}
                      {props.modalData.ingredients && (
                        <>
                          <div className="productInfoTitle">
                            <strong>{ t('home_item_popup_ingredient_label') }</strong>
                          </div>
                          <ProductDescription>
                            <ReadMore character={600}>
                              {props.modalData.ingredients}
                            </ReadMore>
                          </ProductDescription>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </ProductInfo>
            </ProductInfoWrapper>
          </ProductDetailsWrapper>
        </QuickViewWrapper>
      ) : null}
      {props.modalData && props.modalData.name && deviceType.desktop ? (
        <>
          <MainContentArea style={{ paddingTop: mql.matches
              ? <>
                { window.location.pathname.split("/").length > 1 &&
                  window.location.pathname.split("/").length <= 2 &&
                  window.location.pathname.split("/")[1] !== "" && props.modalData && props.modalData.name
                    ? '1vh'
                    : '9vh'
                }
              </>
              : "1vh" }}>
            <ContentSection id="productBlock" style={{width: "100%"}}>
              <div ref={targetRef} style={{
                overflow: 'hidden',
                borderTop: '1px solid #e1e1e1',
                paddingTop: 10,
              }}>
                <Products
                  type={PAGE_TYPE}
                  deviceType={deviceType}
                  fetchLimit={16}
                  props={props}
                />
                <BrandsHome deviceType={deviceType} props={props} />
              </div>
            </ContentSection>
          </MainContentArea>
        </>
      ) : (
        <MainContentArea style={{ paddingTop: "9vh" }}>
          <ContentSection style={{ width: "100%" }}>
            <Products
              type={PAGE_TYPE}
              deviceType={deviceType}
              fetchLimit={16}
              props={props}
            />
          </ContentSection>
        </MainContentArea>
      )}
      <CartPopUp deviceType={deviceType} />
    </>
  );
};

ProductData.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};
const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    modalData: state.productReducer.modalData,
    products: state.productReducer.products,
    allproducts: state.productReducer.allproducts,
    category: state.productReducer.category,
    sliders: state.globalReducer.sliders,
    loading: state.globalReducer.loading,
    brands: state.productReducer.brands,
    filterBrands: state.productReducer.filterBrands,
    FilteredNameProducts: state.productReducer.FilteredNameProducts,
    filteredProducts: state.productReducer.filteredProducts,
    suggested_subcats: state.productReducer.suggested_subcats,
    all_suggested_subcats: state.productReducer.all_suggested_subcats,
    productfetch: state.productReducer.productfetch,
    isloading: state.globalReducer.isloading,
    oos: state.globalReducer.oos,
    cat_refs: state.productReducer.cat_refs,
    cartType: state.cartReducer.cartType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productCategory: (data) => productCategory(data, dispatch),
    ProductModalData: (data) => ProductModalData(data, dispatch),
    ResetModalData: (data) => ProductModalData(data, dispatch),
    searchSuggestions: (data) => searchSuggestions(data, dispatch),
    searchStoreProduct: (data) => searchStoreProduct(data, dispatch),
    getProductsMappedwithSubCat: (data) => getProductsMappedwithSubCat(data, dispatch),
    getProductsMappedwithCat: (data) => getProductsMappedwithCat(data, dispatch),
    // offerSlider: (data) => offerSlider(data, dispatch),
    getProductOutOfStock: (body) => getProductOutOfStock(body, dispatch),
    getProductOutOfStockCat: (body) => getProductOutOfStockCat(body, dispatch),
    getProductBrands: () => getProductBrands(dispatch),
    getSortProductsName: (type, products) =>
      getSortProductsName(type, products, dispatch),
    getSortProductsPrice: (type, products) =>
      getSortProductsPrice(type, products, dispatch),
    getProductFilterBrands: (body, products, allproducts, sub_cat_id, cat_id, oos, text) =>
      getProductFilterBrands(body, products, allproducts, sub_cat_id, cat_id, oos, text, dispatch),
    sortByNameProduct: (type, products) =>
      sortByNameProduct(type, products, dispatch),
    oosModified: (data) => oosModified(data, dispatch),
    changePage: (data) => changePage(data, dispatch),
    resetPage: () => resetPage(dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductData);

const QuickViewWrapper = styled.div`
  margin: 0 auto;
  background-color: #FFFFFF;
  
  .bread-crumb-wrapper {
    width: 100%;
    padding: 0 30px;
    margin-top: 50px;
  }
`;

const ProductDetailsWrapper = styled.div`
  background-color: #fff;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  min-height: 140px;
  overflow: hidden;
  box-sizing: border-box;
  
  .product-info-wrapper {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    .product-navigation {
      display: flex;
      color: #424561;
      gap: 80px;
      font-size: 18px;
      justify-content: center;
      margin-bottom: 20px;
    }
    .product-content {
      padding-bottom: 30px;
    }
    .product-review {
      display: none;
      &.show { display: block }
    }
  }
  
  * {
    box-sizing: border-box;
  }
  @media (max-width: 767px) {
    .product-info-wrapper { order: 2 }
  }
`;

const ProductPreview = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 30px 47px 40px 30px;
    order: 0;
  }
  
  .product-image {
    height: 100%;
    width: auto;
    max-height: 300px;
    max-width: 100% !important;
    @media (max-width: 767px) {
        height: 100%;
        width: auto;
        max-height: 280px;
        max-width: 95% !important;
    }
  }

  img {
    width: 100%;
    height: auto;
    @media (max-width: 767px) {
      max-width: 300px !important;
    }
  }
`;

const SaleTag = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  background-color: ${themeGet("colors.yellow", "#FBB979")};
  padding: 0 10px;
  line-height: 24px;
  border-radius: 12px;
  display: inline-block;
  position: absolute;
  top: 40px;
  right: 30px;
`;

const DiscountPercent = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: #ffffff;
  line-height: 24px;
  background-color: ${themeGet("colors.yellow", "#FBB979")};
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  display: inline-block;
  position: absolute;
  top: 30px;
  right: 30px;
  border-radius: 12px;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
`;

const ProductInfoWrapper = styled.div`
  vertical-align: bottom;
  flex: 0 0 50%;
  max-width: 50%;
  
  .productInfoTitle {
    font-size: 1.5rem;
  }

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 10px 0 30px;
    order: 1;
  }
`;

const ProductInfo = styled.div`
  padding: 30px;
  
  .availability {
    margin-top: 10px;
    padding-bottom: 0;
    
    p {
      font-size: 1rem;
      color: #999;
      margin-bottom: 10px;
    }
  }
  .stock-label {
    padding-right: 20px;
    color: #389244;
    font-size: 1.2rem;
    
    &.out-of-stock {
      color: #FFAD5E;
    }
  }
  .review {
    color: #999;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .bg-warning { background-color: #f78205 !important; }

  @media (max-width: 767px) {
    padding: 0px 30px 30px 30px;
  }
`;

const ProductTitlePriceWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

const ProductTitle = styled.h1`
  font-size: 1.8rem;
  font-family: "Lato", sans-serif;
  font-weight: 400;
`;

const ProductPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  line-height: 31px;
  margin-bottom: 15px;
  margin-top: 10px;
`;

const ProductPrice = styled.h2`
  font-family: "Lato", sans-serif;
  font-size: 1.8rem;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.primary", "#389244")};
`;

export const PromoPrice = styled.span`
  font-family: "Lato", sans-serif;
  font-size: 32px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.yellow", "#FBB979")};
  display: inline-block;
  padding-left: 10px;
`;

const SalePrice = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.yellow", "#FBB979")};
  font-style: italic;
  padding: 0 5px;
  overflow: hidden;
  position: relative;
  margin-right: 10px;

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    display: inline-block;
    background-color: ${themeGet("colors.yellow", "#FBB979")};
    position: absolute;
    top: 50%;
    left: 0;
  }
`;

const ProductWeight = styled.div`
  font-family: "Lato", sans-serif;
  font-size: 1.3rem;
  color: ${themeGet("colors.darkRegular", "#77798c")};
  margin-bottom: 20px;
`;

const ProductDescription = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.darkMedium", "#424561")};
  line-height: 2;
  margin-top: 0px;
  .productInfoTitle {
    font-weight: bold;
  }
`;
const ProductCartBtn = styled.div`
  display: flex;
  width: 100%;
  max-width: 340px;
  gap: 30px;
  
  .btn-outline-success {
    background-color: transparent;
    color: #389244;
    border: 1px solid #389244;
    border-radius: 25px;
    font-weight: 400;
    padding: 0 20px;
  }

  @media (max-width: 767px) {
    margin-top: 40px;
  }

  .reusecore__button {
    font-family: "Lato", sans-serif;
    font-size: ${themeGet("fontSizes.2", "15")}px;
    font-weight: ${themeGet("fontWeights.6", "700")};
    color: ${themeGet("colors.primary", "#389244")};
    height: 36px;
    border-radius: 4rem;
    width: 100%;
    max-width: 150px;

    .btn-icon {
      margin-right: 5px;
    }

    &:hover {
      color: #fff;
      background-color: ${themeGet("colors.primary", "#389244")};
      border-color: ${themeGet("colors.primary", "#389244")};
    }
  }
`;

const ProductCartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  margin-top: 30px;
  margin-bottom: 35px;

  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

const ProductMeta = styled.div`
  margin-top: 30px;
`;

const MetaSingle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const MetaItem = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.darkBold", "#0D1136")};
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: ${themeGet("colors.lightColor", "#f7f7f7")};
  padding: 0 15px;
  border-radius: 6px;
  cursor: pointer;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
