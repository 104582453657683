import React from 'react';
import axios from 'axios';
import * as Constant from '../constants/Constant';
import API from "../network/redAPI";

const user = localStorage.getItem("user");
const parsedUser = JSON.parse(user);
const token = localStorage.getItem('token');
const lang = localStorage.getItem('lang') || 'en';

const checkoutValidation = (body) => {
  return new Promise(async (resolve, reject) => {
    API.PlaceOrder(body).then(response => response.json()).then(res => {
      resolve(res);
    })
  })
}

const doCheckout = (body, cartType = 'normal') => {
  return new Promise(async (resolve, reject) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const api = Constant.baseUrl+'v1/cart/changeOrderStatus?_locale='+lang;

    try {
      const dataResp = await axios.post(api, body, { cancelToken: source.token, headers: { Authorization: token } });
      resolve(dataResp);
      source.cancel('Operation canceled by the user.'); // cancel the request (the message parameter is optional)
    }
    catch (e) {
      resolve(e.response.data)
    }
  })
};

export default {
  checkoutValidation,
  doCheckout,
};
