import React, { useContext, useState } from "react";
import { Col } from "react-styled-flexboxgrid";
import { openModal } from "@redq/reuse-modal";
import { Modal } from "react-bootstrap";
import RadioCard from "../../../components/RadioCard/RadioCard";
import { ProfileContext } from "../../../contexts/profile/profile.context";
// import StripePaymentForm from '../../Payment/StripePaymentForm';
import {
  SettingsForm,
  SettingsFormContent,
  HeadingSection,
  Title,
  Input,
  Row,
  ButtonGroup,
} from "./Settings.style";
import RadioGroup from "../../../components/RadioGroup/RadioGroup";
// import PaymentGroup from '../../../components/PaymentGroup/PaymentGroup';
import UpdateAddress from "../../CheckoutWithSidebar/Update/UpdateAddress";
import UpdateProfile from "./UpdateProfile";
import Button from "../../../components/Button/Button";
import PageLoader from "../../../components/PageLoader/PageLoader";
import { FormattedMessage } from "react-intl";
import { Plus } from "../../../components/AllSvgIcon";
import { Tab, Tabs } from "react-bootstrap";
import t from '../../../i18n/translate';
import _ from 'lodash';
import WalletManagementComponent from "../../../components/WalletManagement/WalletManagement";

const SettingsContent = ({ deviceType, props }) => {
  const { state, dispatch } = useContext(ProfileContext);
  const [active, setActive] = useState("details");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const handleChange = (value, field) => {
    dispatch({
      type: "HANDLE_ON_INPUT_CHANGE",
      payload: { value, field },
    });
  };
  let user = localStorage.getItem("user");
  let parsedUser = JSON.parse(user);
  if (!parsedUser) {
    // window.location.href = "/";
  }
  // Add or edit modal
  const handleModal = (
    modalComponent,
    modalProps = {},
    type,
    className = "add-address-modal"
  ) => {
    openModal({
      show: true,
      config: {
        // width: 360,
        height: "auto",
        enableResizing: false,
        disableDragging: true,
        className: className,
      },
      closeOnClickOutside: false,
      component: modalComponent,
      componentProps: { item: modalProps, type, props },
    });
  };

  const handleEditDelete = async (item, type, name) => {
    if (type === "edit") {
      const modalComponent = name === "address" ? UpdateAddress : UpdateProfile;
      handleModal(modalComponent, item, "edit");
    } else if (type === "delete") {
      if (item.is_default) {
        alert( t('shop_checkout_cant_delete_default_address_error', false) );
      } else {
        let response = window.confirm( t('shop_checkout_confirm_delete_address', false) );
        if (response === true) {
          props.deleteAddress({ address_id: item.address_id });
        }
      }
    } else {
    }
  };

  const handleSave = async () => {
    const { name, email } = state;
    // await updateMeMutation({
    //   variables: { meInput: JSON.stringify({ name, email }) },
    // });
  };

  function onChangePassword() {
    if (_.isEmpty(newpassword) || _.isEmpty(confirmpassword)) {
      alert( t('change_password_empty_error', false) );
    } else if (newpassword !== confirmpassword) {
      alert( t('change_password_and_confirm_didnt_match', false) );
    } else {
      props.doLoggedInPasswordChange({newpassword, confirmpassword, email: parsedUser.email,});
      alert( t('change_password_success_message', false) );
    }
  }

  return (
    <SettingsForm>
      <SettingsFormContent>
        {parsedUser && <Tabs
          id="controlled-tab-example"
          activeKey={active}
          onSelect={(k) => setActive(k)}
          className="tabProfile"
        >
          <Tab eventKey={"details"} title={ t('profile_user_section_info_title', false) }>
            <HeadingSection>
              <Title>{ t('profile_user_title') }</Title>
            </HeadingSection>
            <Row style={{ alignItems: "flex-end", marginBottom: "50px" }}>
              <Col xs={12} sm={4} md={3} lg={3}>
                <Input
                  type="email"
                  label={ t('profile_user_full_name_input_title', false) }
                  value={parsedUser.first_name + " " + parsedUser.last_name}
                  onUpdate={(value) => handleChange(value, "name")}
                  style={{ backgroundColor: "#F7F7F7" }}
                  intlInputLabelId="profileNameField"
                  disabled
                />
              </Col>

              <Col xs={12} sm={4} md={3} lg={3}>
                <Input
                  type="email"
                  label={ t('profile_user_email_title', false) }
                  value={parsedUser.email}
                  onUpdate={(value) => handleChange(value, "email")}
                  style={{ backgroundColor: "#F7F7F7" }}
                  intlInputLabelId="profileEmailField"
                  disabled
                />
              </Col>
              <Col xs={12} sm={4} md={3} lg={3}>
                <Input
                  type="email"
                  label={ t('profile_user_phone_title', false) }
                  value={"+" + parsedUser.country_code + "-" + parsedUser.phone}
                  onUpdate={(value) => handleChange(value, "mobile")}
                  style={{ backgroundColor: "#F7F7F7" }}
                  intlInputLabelId="profileMobileField"
                  disabled
                />
              </Col>

              <Col xs={12} sm={2} md={2} lg={2}>
                <Button
                  title={ t('profile_user_change_button', false) }
                  className="addButton"
                  type="button"
                  onClick={() =>
                    handleModal(UpdateProfile, "add-profile-modal", "add")
                  }
                  style={{ width: "100%" }}
                  intlButtonId="profileSaveBtn"
                />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey={"password"} title={ t('profile_user_section_password_title', false) }>
            <HeadingSection>
              <Title>{ t('change_password_new_password_title') }</Title>
            </HeadingSection>
            <Row style={{ alignItems: "flex-end", marginBottom: "50px" }}>
              <Col xs={12} sm={4} md={3} lg={3}>
                <Input
                  type="password"
                  label={ t('change_password_new_password_input_hint', false) }
                  value={""}
                  onUpdate={(value) => setNewPassword(value)}
                  style={{ backgroundColor: "#F7F7F7" }}
                  intlInputLabelId="newPasswordField"
                />
              </Col>
              <Col xs={12} sm={4} md={3} lg={3}>
                <Input
                  type="password"
                  label={ t('change_password_confirm_password_input_hint', false) }
                  value={""}
                  onUpdate={(value) => {
                    setConfirmPassword(value);
                    if (confirmpassword !== undefined) {
                      // enter a password !
                    } else if (confirmpassword !== newpassword) {
                      alert( t('change_password_and_confirm_didnt_match', false) );
                      // password not matched !
                    } else {
                      // no error
                      alert( t('change_password_and_confirm_matched', false) );
                    }
                  }}
                  style={{ backgroundColor: "#F7F7F7" }}
                  intlInputLabelId="confirmPasswordField"
                />
              </Col>
              <Col xs={12} sm={2} md={2} lg={2}>
                <Button
                  title={ t('change_password_update_button') }
                  className="addButton"
                  type="button"
                  onClick={onChangePassword}
                  style={{ width: "100%" }}
                  intlButtonId="passwordSaveBtn"
                />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey={"address"} title={ t('profile_user_section_address_title', false) }>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ position: "relative" }}
              >
                <SettingsFormContent>
                  {props.loading && <PageLoader />}
                  <HeadingSection>
                    <Title>{ t('setting_shop_addresses') }</Title>
                  </HeadingSection>
                  <ButtonGroup>
                    <RadioGroup
                      items={
                        props.user_address != undefined
                          ? props.user_address
                          : []
                      }
                      component={(item) => (
                        <RadioCard
                          id={item.address_id}
                          key={item.address_id ? item.address_id : item.id}
                          title={item.nick_name}
                          firstName={item.first_name}
                          lastName={item.last_name ? item.last_name : null}
                          value={item.address_id}
                          content={item.area_details}
                          house_number={item.house_number}
                          apartment_name={item.apartment_name}
                          street_details={item.street_details}
                          mobile_number={item.mobile_number}
                          country_code={item.country_code}
                          city={item.city}
                          landmark={item.landmark}
                          pin_code={item.pin_code}
                          delivery_instructions={item.delivery_instructions}
                          name="address"
                          checked={item.is_default == 1}
                          onChange={() => {}}
                          onEdit={() =>
                            handleEditDelete(item, "edit", "address")
                          }
                          onDelete={() =>
                            handleEditDelete(item, "delete", "address")
                          }
                        />
                      )}
                      secondaryComponent={
                        <Button
                          className="addButton"
                          title={ t('profile_user_add_address_button',  false) }
                          icon={<Plus width="10px" />}
                          iconPosition="left"
                          colors="primary"
                          size="small"
                          variant="textButton"
                          type="button"
                          intlButtonId="addNew"
                          onClick={() =>
                            handleModal(
                              UpdateAddress,
                              "add-address-modal",
                              "add"
                            )
                          }
                        />
                      }
                    />
                  </ButtonGroup>
                </SettingsFormContent>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey={"wallet"} title={ t('profile_user_section_wallet_title', false) }>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ position: "relative" }}
              >
                <SettingsFormContent>
                  {props.loading && <PageLoader />}
                  <WalletManagementComponent />
                </SettingsFormContent>
              </Col>
            </Row>
          </Tab>
        </Tabs>}
      </SettingsFormContent>
    </SettingsForm>
  );
};

export default SettingsContent;
