import * as actionTypes from "../ActionTypes";
import API from "../../network/redAPI";
import { checkAuthError, logoutuser, dates, allslots } from "../../helper/constant";
let user = localStorage.getItem("user");

const expressMode = localStorage.getItem('expressMode') || 0;
export const getAddress = (dispatch) => {
  let parsedUser = JSON.parse(user);
  let cust_id = parsedUser.cust_id;
  let body = { cust_id };
  dispatch({
    type: actionTypes.Loading_Enable,
  });
  API.GetAddress(body, expressMode)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
      throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      dispatch({
        type: actionTypes.Loading_Disable,
      });
      if (data.success) {
        dispatch({
          type: actionTypes.Get_Address,
          payload: data.data,
          success: data.success,
        });
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      dispatch({
        type: actionTypes.Loading_Disable,
      });
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};

export const addAddress = (body, dispatch) => {
  API.AddAddress(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
      throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Add_Address,
          payload: data.data,
          success: data.success,
        });
        getAddress(dispatch);
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};

export const updateAddress = (body, dispatch) => {
  API.UpdateAddress(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
      throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Update_Address,
          payload: data.data,
          success: data.success,
        });
        getAddress(dispatch);
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};
export const deleteAddress = (body, dispatch) => {
  API.DeleteAddress(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
      throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Delete_Address,
          payload: data.data,
          success: data.success,
        });
        getAddress(dispatch);
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};

export const updateProfile = (body, dispatch) => {
  API.UpdateProfile(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
      throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        await localStorage.setItem("user", JSON.stringify(data.data));

        let parsedUser = JSON.parse(localStorage.getItem("user"));
        let {
          cust_id,
          first_name,
          last_name,
          phone,
          email,
          gender,
          createdAt,
        } = parsedUser;
        window.analytics && window.analytics.track("Profile updated by " + email, {
          cust_id,
          first_name,
          gender,
          email,
          phone,
          joined_on: createdAt,
        });

        dispatch({
          type: actionTypes.Update_Profile,
          payload: data.data,
          success: data.success,
        });
        window.location.reload();
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};
export const currentOrders = (body, dispatch) => {
  API.CurrentOrders(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
      throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Current_Orders,
          payload: data.data,
          success: data.success,
        });
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};
export const pastOrders = (body, dispatch) => {
  API.PastOrders(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
      throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Past_Orders,
          payload: data.data,
          success: data.success,
        });
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      // alert("Your login session is invalid, please login again");
      // logoutuser();
    });
};
export const fetchParticularOrder = (body, dispatch) => {
  API.FetchParticularOrder(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
      throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Fetch_Particular_Order,
          payload: data.data,
          success: data.success,
        });
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};
export const alldeliverySlots = (body, dispatch) => {
  API.AllDeliverySlots(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
      throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then((data) => {
      if (data.success) {
        // const dates = [];
        // const slots = [];
        // Object.keys(data.data.forEach(element => {
        //   for (var key in element) { 
        //     if (element.hasOwnProperty(key)) { 
        //       dates.push(key);
        //       element[key].forEach(elem => {
        //         slots.push(elem);
        //       })
        //     } 
        //   }  
        // })
        // );
        // dispatch({
        //   type: actionTypes.Set_All_Slots,
        //   payload: data.data,
        //   success: data.success
        // });
        dispatch({
          type: actionTypes.Delivery_Slots,
          payload: data.data,
          success: data.success,
        });
      } else {
        // alert(data.error)
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};
export const slotsForDate = (body, dispatch) => {
  API.AllSlotsForDate(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
      throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Delivery_Slots_By_Date,
          payload: data.data,
          success: data.success,
        });
      } else {
        // alert(data.error)
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};
export const allCouponCodes = (body, dispatch) => {
  API.GetAllCouponCodes(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
      throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      if (data.success) {
        if (body["search"] != "") {
          data.data.sort((a, b) => {
            if (
              a.code.indexOf(body["search"]) > b.code.indexOf(body["search"])
            ) {
              return 1;
            } else if (
              a.code.indexOf(body["search"]) < b.code.indexOf(body["search"])
            ) {
              return -1;
            } else {
              if (a.code > b.code) return 1;
              else return -1;
            }
          });
        }
        dispatch({
          type: actionTypes.Get_All_Coupons,
          payload:
            body["search"] != ""
              ? data.data.sort((a, b) => {
                  return (
                    a.code.indexOf(body["search"]) -
                    b.name.indexOf(body["search"])
                  );
                })
              : data.data,
          success: data.success,
        });
      } else {
        // alert(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};

export const moveToCheckout = (body, dispatch) => {
  API.MoveToCheckout(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
      throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then((data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Move_To_Checkout,
          payload: [data.data],
          success: data.success,
        });
      } else {
        console.log(data.error);
      }
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};

export const makePayment = (body, dispatch) => {
  API.OpenPaymentDialog(body)
    .then((response) => {
      if(checkAuthError(response)){
      //logout 
      throw new Error("Unauthorized")
      }
      else{
        return response.json() 
      }
    })
    .then(async (data) => {
      console.log("Payment Response ", data.data);
      dispatch({
        type: actionTypes.Pay_By_Card,
        payload: data.data,
        success: data.success,
      });
    })
    .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
    });
};

export const reOrder = (body, dispatch) => {
  API.Reorder(body)
      .then((response) => {
        if(checkAuthError(response)){
        //logout 
        throw new Error("Unauthorized")
        }
        else{
          return response.json() 
        }
      })
     .then(async (data) => {
        console.log("response for reorder ", data);
        if(data.success){
          localStorage.setItem("order_id",data.data);
          dispatch({
            type: actionTypes.Reorder,
            reordersuccess: data.success
          });
          document.getElementById("root").classList.remove("loading");
        }
        else{
          document.getElementById("root").classList.remove("loading");
          alert(data.message);
        }
     })
     .catch(err => {
      if (err.message === "Unauthorized"){
        alert("Your login session is invalid, please login again");
        logoutuser();
      }
     });
};

export const resetreordersuccess = (dispatch) => {
  dispatch({
    type: actionTypes.Reset_Reorder_Success
  });
};
