import React, {useEffect} from "react";
import { useSelector } from 'react-redux';
import "./footer.css";
import { checkOnlineStatus} from "../../../helper/constant";
import {useCart} from "../../../contexts/cart/use-cart";
import abaFooterLogos from '../../../assets/icons/FooterLogos.svg';
import delishopIcon from '../../../assets/icons/delishop_logo.svg';
import abaBakongIcon from '../../../assets/icons/aba-bakong.svg';
import delishopExpressIcon from '../../../assets/icons/delixpress_logo.svg';
import t from '../../../i18n/translate';

const Footer = () => {
  const {
    disableloading,
    fetchOrderItems
  } = useCart();
  const cartType = useSelector(state => state.cartReducer.cartType);

  const authValid = (page) => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      document.getElementById("joinBTn").click();
    } else {
      if (page == "profile") {
        window.location.href = "/profile";
      } else if (page == "orders") {
        window.location.href = "/all-orders";
      }
    }
  };
  // useEffect(async () => {
    // if(!(await checkOnlineStatus())){
    //   alert("You seem offline for now. Connect to the internet for uninterrupted experience."); 
    // }
    // disableloading();
    // return (() => {
    //     disableloading();
    // });
  // }, []);
  return (
    <>
      <div className="footer-static-container">
        <div className="container">
          <div className="wrapper_footer_static">
            <div className="row">
              <div className="col-sm-12 col-md-9">
                <div className="footer_static">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="footer-links">
                        <h2 className="title">{ t('common_footer_title_menu_about') }</h2>
                        <ul>
                          <li>
                            <a href="/contact">{ t('common_footer_menu_contact_us') }</a>
                          </li>
                          <li>
                            <a href="/about">{ t('common_footer_menu_about_us') }</a>
                          </li>
                          <li>
                            <a href="/terms">{ t('common_footer_menu_terms_conditions') }</a>
                          </li>
                          {/*<li>
                            <a href="/updates">Updates</a>
                          </li>*/}
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="footer-links">
                        <h2 className="title">{ t('common_footer_title_menu_help') }</h2>
                        <ul>
                          <li>
                            <a href="/payments">{ t('common_footer_menu_payment') }</a>
                          </li>
                          <li>
                            <a href="/delivery">{ t('common_footer_menu_delivery_information') }</a>
                          </li>
                          <li>
                            <a href="/help">{ t('common_footer_menu_faq') }</a>
                          </li>
                          <li>
                            <a href="/refund-policies">{ t("common_footer_menu_refund_policies") }</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="footer-links">
                        <h2 className="title">{ t('common_footer_title_menu_account') }</h2>
                        <ul>
                          <li>
                            <a style={{ cursor: "pointer" }} onClick={() => authValid("profile")}>
                              { t('common_footer_menu_my_profile') }
                            </a>
                          </li>
                          <li>
                            <a style={{ cursor: "pointer" }} onClick={() => authValid("orders")}>{ t('common_footer_menu_my_orders') }</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3">
                <div className="section-instagram">
                  <div>
                    <div className="cp-ftitle">
                      <h2 style={{ cursor: "pointer" }} onClick={() => window.location.href="/brands"}>{ t('common_footer_title_menu_our_brands') }</h2>
                      <a
                        className="visible-xs cp-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#cpcolumninstagram"
                      >
                        <span></span>
                      </a>
                    </div>
                    <div id="cpcolumninstagram" className="cp-fcontent"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer_introduce">
            <p style={{ textAlign: "center" }}>
              {cartType === 'express'
                ? <img src={delishopExpressIcon}
                       alt="delishop"
                       width="227"
                       height="79"
                />
                : <img src={delishopIcon}
                       alt="delishop"
                       width="227"
                       height="79"
                />
              }
            </p>
            <div className={`social-link ${cartType}`}>
              <div className={`widget block block-static-block ${cartType}`}>
                <ul>
                  <li>
                    <a
                      title="Face-book"
                      href="https://www.facebook.com/Delishop.asia/?modal=admin_todo_tour"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="fa fa-facebook"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      title="Instagram"
                      href="https://www.instagram.com/delishop.asia/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="fa fa-instagram"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      title="Telegram"
                      href="https://t.me/DelishopAsia"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="fa fa-telegram"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      title="Youtube"
                      href="https://www.youtube.com/channel/UCNgnBUeyzY_4TrFBQAViO7w/featured?view_as=subscriber"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="fa fa-youtube"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <ul className={`footer-contact ${cartType}`}>
              <li className="fa fa-phone">081 999 405</li>
              <li className="fa fa-clock-o">{ t('common_footer_day_hours_open') }</li>
              <li className="fa fa-envelope-o">
                <a href="mailto:contact@delishop.asia">contact@delishop.asia</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="footer_address_payment">
            <div
              style={{ margin: "2rem", paddingTop: "2rem", marginTop: "-2rem" }}
            >
              { t('common_footer_accept_payment_method') } <br />
              <img
                style={{ maxHeight: 40 }}
                src="https://delishop.s3-ap-southeast-1.amazonaws.com/wingpay-logo.svg"
                alt="WING"
                width="114"
                height="40"
              />
              <img
                style={{ maxHeight: 40, marginLeft: 10 }}
                src={abaFooterLogos}
                alt="ABA Pay, Visa, MasterCard, UPI"
                width="277"
                height="40"
              />
              <img
                style={{ maxHeight: 40, marginLeft: 10 }}
                src={abaBakongIcon}
                alt="ABA Bakong"
                height="24"
              />
            </div>
            <p>{ t('common_footer_delishop_address') }</p>
          </div>
          <small className="copyright">
            <span>
              © {new Date().getFullYear()} { t('common_footer_delishop_rights_resrved') }
            </span>
          </small>
        </div>
      </div>
    </>
  );
};

export default Footer;
