import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BrandsRow, BrandsCol, BrandsHeader } from "./AllBrands.style";
import Brand from "../../components/Brand/Brand";
import t from '../../i18n/translate';

const AllBrands = ({
    props
  }) => {
    const history = useHistory();
    return (<>
        <BrandsHeader>{ t('common_footer_title_menu_our_brands') }</BrandsHeader>
        <BrandsRow>
        {props.brands && props.brands.length > 0 &&
            props.brands.map((item, index) => {
                return (
                <BrandsCol key={item.brand_id}>
                   <Brand item={item} handleSelection={()=>{
                       history.push(
                          `/brand/${item.brand_ref_id}`
                       )
                   }} />
                </BrandsCol>
                )
            }) 
        }
        </BrandsRow>
        </>
    );
  };
  
  export default AllBrands;
  