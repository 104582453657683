import * as actionTypes from "../ActionTypes";
import API from "../../network/redAPI";
let user = localStorage.getItem("user");

export const offerSlider = (body, dispatch) => {
  API.OfferSlider(body)
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Offer_Slider,
          payload: data.data,
          success: data.success,
        });
      } else {
        // alert(data.error)
      }
    });
};

export const expressSliderAction = (data, dispatch) => {
  dispatch({
    type: actionTypes.Offer_Slider,
    payload: data.data,
    success: data.success,
  });
}

export const oosModified = (body, dispatch) => {
  dispatch({
    type: actionTypes.OOS_Option,
    payload: body.status
  });
};