import { Clear_Cart } from "../../store/ActionTypes";
import { compDate } from "../../constants/Constant";

export const cartItemsTotalPrice = (items, coupon = null) => {
  let total = items.reduce((price, product) => {
    return price + product.item_price * product.quantity;
  }, 0);
  const discount = 0;
  return total - discount;
};
// cartItems, cartItemToAdd
const addItemToCart = (state, action) => {
  const existingCartItemIndex = state.items.findIndex(
    (item) => item.item_id === action.payload.item_id
  );
  if (existingCartItemIndex > -1) {
    const newState = [
      ...state.items.slice(0, existingCartItemIndex),
      {
        ...state.items[existingCartItemIndex],
        quantity: state.items[existingCartItemIndex].quantity + 1,
      },
      ...state.items.slice(existingCartItemIndex + 1),
    ];
    return newState;
  }
  return [...state.items, action.payload];
};

// cartItems, cartItemToRemove
const removeItemFromCart = (state, action) => {
  return state.items.reduce((acc, item) => {
    if (item.item_id === action.payload.item_id) {
      const newQuantity = item.quantity - action.payload.quantity;
      console.log(newQuantity);
      return newQuantity > 0
        ? [...acc, { ...item, quantity: newQuantity }]
        : [...acc];
    }
    return [...acc, item];
  }, []);
};

const clearItemFromCart = (state, action) => {
  return state.items.filter((item) => item.item_id !== action.payload.item_id);
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "REHYDRATE":
      return { ...state, ...action.payload };
    case "TOGGLE_CART":
      return { ...state, isOpen: !state.isOpen };
    case "ADD_ITEM":
      return { ...state, items: addItemToCart(state, action) };
    case "REMOVE_ITEM":
      return { ...state, items: removeItemFromCart(state, action) };
    case "CLEAR_ITEM_FROM_CART":
      return { ...state, items: clearItemFromCart(state, action) };
    case "CLEAR_CART":
      return { ...state, items: [] };
    case "FETCH_CART": 
      return { ...state, items: action.payload, carttotal: action.total };
    case "APPLY_COUPON":
      return { ...state, coupon: action.payload };
    case "REMOVE_COUPON":
      return { ...state, coupon: null };
    case "TOGGLE_RESTAURANT":
      return { ...state, isRestaurant: !state.isRestaurant };
    case "ISLOADING":
      return { ...state, isloading: true };
    case "NOTLOADING":
      return { ...state, isloading: false };
    case "CLOSECART":
      return { ...state, isOpen: false};
    case "":
      return { ...state };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
