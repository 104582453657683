import React from "react";
import styled from "styled-components";
import { Modal } from "@redq/reuse-modal";

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 130px 0 60px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  @media (min-width: 990px) {
    width: 870px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 30px;
  }
`;

export default function Updates() {
  return (
    <Modal>
      <HelpPageWrapper>
        <HelpPageContainer>
          <Heading>8 April 2021 Updates</Heading>
          

            <b>In order to be able to to serve you to the best of our capabilities and address everyone’s need we are implementing some changes in our service, especially:</b>
            <ul>
                <li>
                    • No order editing is possible once the order is placed, make sure to double check you have all you need before placing your order.
                </li>
                <li>
                    • Beverages limit : Maximum of 12 bottles and 24 cans (Water, juice and soda). 
                </li>
                <li>
                    • Minimum cart size $10 to place order.
                </li>
                <li>
                    • Delivery fees are adjusted per distance, free above $50.
                </li>
            </ul><br />

            <i>In addition to that please note that we might start using third party delivery services for some orders.</i><br /><br />

            <i>Thank you all for your understanding and have a great day</i>
        </HelpPageContainer>
      </HelpPageWrapper>
    </Modal>
  );
}
