import React, {useMemo, useState, useEffect} from "react";
import {Router, useLocation, useParams, useHistory, withRouter} from "react-router-dom";
import SearchBox from "../../../components/SearchBox/SearchBox";
import {SearchContext} from "../../../contexts/search/search.context";
import {AuthContext} from "../../../contexts/auth/auth.context";
import AuthenticationForm from "../../SignInOutForm/Form";
import {RightMenu} from "./Menu/RightMenu/RightMenu";
import {LeftMenu} from "./Menu/LeftMenu/LeftMenu";
import HeaderWrapper, {LanguageList} from "./Header.style";
import LogoImage from "../../../image/Background.png";
import UserImage from "../../../image/user.jpg";
import {Basket} from "../../../assets/icons/Basket";
import {isCategoryPage, isCommonPage} from "../is-home-page";
import Modal from "react-bootstrap/Modal";
import {connect} from "react-redux";
import {
  searchSuggestions,
  searchStoreProduct,
  Reset_Suggestions,
  Toggle_Suggestions,
  getProductOutOfStock,
  getProductOutOfStockCat,
  getProductsMappedwithSubCat,
  getProductsMappedwithCat,
  changePage,
  resetPage, sortProducts
} from "../../../store/actions/productAction";

import {
  initExpressStoreProducts,
} from "../../../store/actions/expressAction";
// import {
//   changePage,
//   resetPage
// } from "../../../store/actions/globalAction";
import {
  resetmessages
} from "../../../store/actions/authAction";
import {debounce} from "lodash";
import Button from "../../../components/Button/Button";
import {setNestedObjectValues} from "formik";
import {cats} from "../../../helper/constant";
import {useCart} from "../../../contexts/cart/use-cart";
import ExpressSearchBox from "../../../pages/express/express-supplier-search-box";
import Image from 'react-bootstrap/Image';
import enFlagIcon from '../../../assets/icons/united-kingdom.svg';
import kmFlagIcon from '../../../assets/icons/cambodia.svg';

const delishopLogo = '/delishop-logo.svg';

const useRouter = () => {
  const location = useLocation();
  const params = useParams();
  return useMemo(() => {
    return {
      pathname: location.pathname,
      location,
      query: {
        // ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
    };
  }, [params, location]);
};
const Header = (props, {className, searchSuggestions}) => {
  const {
    cartItemsCount,
    calculatePrice,
  } = useCart();
  const {
    authState: {isAuthenticated, currentForm},
    authDispatch,
  } = React.useContext(AuthContext);
  const {state, searchdispatch} = React.useContext(SearchContext);
  const router = useRouter();
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);
  let mql = window.matchMedia("all and (min-width: 769px)");
  const lang = localStorage.getItem('lang') || 'en';

  useEffect(() => {
    if (mql.matches) {
      var link = !!document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
      link.setAttribute('rel', 'canonical');

      if (window.location.pathname.indexOf("search") !== -1) {
        document.title = window.location.pathname.split("/")[2] + " - Delishop";
        document
          .getElementsByTagName("meta")
          .namedItem("description")
          .setAttribute(
            "content",
            window.location.pathname.split("/")[2] + " - Delishop"
          );
        document
          .getElementsByTagName("meta")
          .namedItem("title")
          .setAttribute(
            "content",
            window.location.pathname.split("/")[2] + " - Delishop"
          );

        // document
        //   .querySelector("link[rel='canonical']")
        //   .setAttribute("href", window.location.href);
      } else if (cats.includes(window.location.pathname.split("/")[1])) {
        document.title = window.location.pathname.split("/")[1] + " - Delishop";
        if (!document.getElementsByTagName("meta")) {
          document
            .getElementsByTagName("meta")
            .namedItem("description")
            .setAttribute(
              "content",
              window.location.pathname.split("/")[1] + " - Delishop"
            );
          document
            .getElementsByTagName("meta")
            .namedItem("title")
            .setAttribute(
              "content",
              window.location.pathname.split("/")[1] + " - Delishop"
            );
        }
      } else if (
        window.location.pathname.split("/").length > 1 &&
        window.location.pathname.split("/").length <= 2 &&
        window.location.pathname.split("/")[1] !== "" &&
        !isCommonPage(window.location.pathname.split("/")[1])
      ) {
        // document.title = window.location.pathname.split("/")[1] + " - Delishop";
        // document
        //   .getElementsByTagName("meta")
        //   .namedItem("description")
        //   .setAttribute("content", props.modalData.name + " - Delishop");
        // document
        //   .getElementsByTagName("meta")
        //   .namedItem("title")
        //   .setAttribute("content", props.modalData.name + " - Delishop");

        // document
        //   .querySelector("link[rel='canonical']")
        //   .setAttribute("href", window.location.href);
      } else if (window.location.pathname.split("/").length > 2) {
        document.title = window.location.pathname.split("/")[2] + " - Delishop";
        // document
        //   .querySelector("link[rel='canonical']")
        //   .setAttribute("href", window.location.href);
      } else if (
        window.location.pathname.split("/").length > 1 &&
        window.location.pathname.split("/").length <= 2 &&
        window.location.pathname.split("/")[1] !== "" &&
        isCommonPage(window.location.pathname.split("/")[1])
      ) {
        document.title = "Delishop - First Online Supermarket in Cambodia";
        document
          .getElementsByTagName("meta")
          .namedItem("description")
          .setAttribute(
            "content",
            "Get all your favorite products from the best shops of Phnom Penh. More than 10,000 products to choose from"
          );
        document
          .getElementsByTagName("meta")
          .namedItem("title")
          .setAttribute(
            "content",
            "Delishop - First Online Supermarket in Cambodia"
          );
        // document
        //   .querySelector("link[rel='canonical']")
        //   .setAttribute("href", window.location.href);
      } else {
        document.title = "Delishop - First Online Supermarket in Cambodia";
        document
          .getElementsByTagName("meta")
          .namedItem("description")
          .setAttribute(
            "content",
            "Get all your favorite products from the best shops of Phnom Penh. More than 10,000 products to choose from"
          );
        document
          .getElementsByTagName("meta")
          .namedItem("title")
          .setAttribute(
            "content",
            "Delishop - First Online Supermarket in Cambodia"
          );
        // document
        //   .querySelector("link[rel='canonical']")
        //   .setAttribute("href", window.location.href);
      }
    }
  }, [window.location.pathname]);
  useEffect(() => {
    if (isAuthenticated) {
      setShow(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (text !== "" && text.length >= 3) {
        searchdispatch({
          type: "UPDATE",
          payload: {
            ...state,
            text,
          },
        });
      }
    }, 0);
    return () => clearTimeout(timeout);
  }, [text]);

  useEffect(() => {
    if (
      props.all_suggested_subcats &&
      props.all_suggested_subcats[0]
    ) {
      const pathname = window.location.pathname;
      let textSearched = state.text;
      if(pathname.includes('/search/')) {
        textSearched = window.location.pathname.split('/').pop();
      }

      searchdispatch({
        type: "SUGGESTION",
        payload: {
          ...state,
          suggestion: props.all_suggested_subcats[0].sub_cat_id,
        },
      });
      localStorage.setItem("sub_cat_id", -1);
      localStorage.setItem("subcatname", "");
      localStorage.setItem("mode", "search");
      props.resetPage();
      props.searchStoreProduct({
        sub_cat_id: 0,
        search_query: textSearched,
        store_id: 1,
        page: 1
      });
    }
  }, [props.all_suggested_subcats]);

  useEffect(() => {
    if (state.suggestion !== -1 && state.text && state.text.length >= 3) {
      localStorage.setItem("sub_cat_id", -1);
      localStorage.setItem("subcatname", "");
      localStorage.setItem("mode", "search");
      props.resetPage();
      props.searchStoreProduct({
        sub_cat_id: state.suggestion,
        search_query: state.text,
        store_id: 1,
        page: 1
      });
    }
  }, [state.suggestion]);
  useEffect(() => {
    // if(props.page != 1){
    switch (localStorage.getItem("mode")) {
      case "search":
        return props.searchStoreProduct({
          sub_cat_id: state.suggestion,
          search_query: state.text,
          store_id: 1,
          page: props.page
        });
      case "catoos":
        return props.getProductOutOfStockCat({
          cat_id: localStorage.getItem("cat_id") === null || localStorage.getItem("cat_id") === undefined || localStorage.getItem("cat_id") === -1 ? 0 : localStorage.getItem("cat_id"),
          store_id: 1,
          page: props.page
        });
      case "cat":
        return props.getProductsMappedwithCat({
          cat_id: localStorage.getItem("cat_id") === null || localStorage.getItem("cat_id") === undefined || localStorage.getItem("cat_id") === -1 ? 0 : localStorage.getItem("cat_id"),
          store_id: 1,
          page: props.page
        });
      case "subcatoos":
        return props.getProductOutOfStock({
          sub_cat_id: localStorage.getItem("sub_cat_id") === null || localStorage.getItem("sub_cat_id") === undefined || localStorage.getItem("sub_cat_id") === -1 ? 0 : localStorage.getItem("sub_cat_id"),
          store_id: 1,
          page: props.page
        });
      case "subcat":
        return props.getProductsMappedwithSubCat({
          sub_cat_id: localStorage.getItem("sub_cat_id") === null || localStorage.getItem("sub_cat_id") === undefined || localStorage.getItem("sub_cat_id") === -1 ? 0 : localStorage.getItem("sub_cat_id"),
          store_id: 1,
          page: props.page,
          lang
        });
      default:
        return;
    }
    // }
  }, [props.page]);

  const {pathname, query} = router;
  const handleLogout = () => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("supplier");
      localStorage.setItem("order_id", 0);
      authDispatch({type: "SIGN_OUT"});
      window.location.href = "/";
      // Router.push('/');
    }
  };

  const handleJoin = () => {
    authDispatch({
      type: "SIGNIN",
    });
    setShow(true);
  };
  const onSearch = (text) => {
    setText(text.trim());
    if (props.all_suggested_subcats.length !== 0) {
      props.Reset_Suggestions();
    }
  };

  const history = useHistory();
  const onClickHandler = () => {
    if (state.suggestion !== -1 && state.text !== "" && state.text.length >= 3) {
      localStorage.setItem("sub_cat_id", -1);
      localStorage.setItem("subcatname", "");
      history.push(`/search/${state.text.trim()}`);
      props.searchSuggestions({search_query: state.text, store_id: 1});
      if (props.page === 1) {
        props.Toggle_Suggestions({toggle: true});
      }
    }
    if (document.querySelector("#sidebarBlock")) {
      var topOfElement = document.querySelector("#sidebarBlock").offsetTop - 90;
      window.scrollTo({top: topOfElement, behavior: "smooth"});
    }
  };
  const showSearch =
    isCategoryPage(pathname) &&
    window.location.pathname.split("/").length > 1 &&
    window.location.pathname.split("/").length <= 3 && !(window.location.pathname.indexOf("not-found") !== -1);

  const onChangeLanguage = (lang) => {
    if(localStorage.getItem('lang') !== lang) {
      localStorage.setItem('lang', lang);
      window.location.reload();
    }
  };

  return (
    <HeaderWrapper className={className}>
      <LeftMenu props={props} logo={"https://delishop.s3-ap-southeast-1.amazonaws.com/Delishop_LOGO+banner.jpg"}/>
      {showSearch || window.location.pathname.includes('express')
        ? <>
          <SearchBox
            className="headerSearch"
            suggestions={props.all_suggested_subcats}
            handleSearch={(value) => onSearch(value)}
            onClick={onClickHandler}
            placeholder="Search anything..."
            hideType={true}
            minimal={true}
            showSvg={false}
            props={props}
          />
        </>
        : null
      }
      <div className="user-action">
        <LanguageList>
          <ul className="language-list">
            <li className={`en ${ localStorage.getItem('lang') !== 'km' ? 'active' : ''  }`}
                onClick={() => onChangeLanguage('en')}
            >
              <Image src={ enFlagIcon } alt="English Language" />
            </li>
            <li className={`km ${ (localStorage.getItem('lang') === 'km') ? 'active' : ''  }`}
                onClick={() => onChangeLanguage('km')}
            >
              <Image src={ kmFlagIcon } alt="Khmer Language" />
            </li>
          </ul>
        </LanguageList>
        <RightMenu
          isAuthenticated={isAuthenticated}
          onJoin={handleJoin}
          onLogout={handleLogout}
          avatar={UserImage}
        />
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          // props.resetmessages();
        }}
        dialogClassName="modal-90w authModal"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
      >
        <Modal.Header closeButton style={{border: 0}}></Modal.Header>
        <Modal.Body style={{padding: 0}}>
          <AuthenticationForm/>
        </Modal.Body>
      </Modal>
    </HeaderWrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    success: state,
    storeSelected: state.expressReducer.storeSelected,
    suggested_subcats: state.productReducer.suggested_subcats,
    allproducts: state.productReducer.allproducts,
    all_suggested_subcats: state.productReducer.all_suggested_subcats,
    modalData: state.productReducer.modalData,
    togglesuggestion: state.productReducer.togglesuggestion,
    filteredProducts: state.productReducer.filteredProducts,
    page: state.productReducer.page,
    cat_refs: state.productReducer.cat_refs,
    oos: state.globalReducer.oos,
    category: state.productReducer.category,
    cartType: state.cartReducer.cartType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchSuggestions: (data) => searchSuggestions(data, dispatch),
    searchStoreProduct: (data) => searchStoreProduct(data, dispatch),
    Reset_Suggestions: () => Reset_Suggestions(dispatch),
    Toggle_Suggestions: (body) => Toggle_Suggestions(body, dispatch),
    changePage: (body) => changePage(body, dispatch),
    resetPage: () => resetPage(dispatch),
    getProductsMappedwithSubCat: (data) => getProductsMappedwithSubCat(data, dispatch),
    getProductsMappedwithCat: (data) => getProductsMappedwithCat(data, dispatch),
    getProductOutOfStock: (body) => getProductOutOfStock(body, dispatch),
    getProductOutOfStockCat: (body) => getProductOutOfStockCat(body, dispatch),
    initProductExpressStore: (data) => initExpressStoreProducts(data, dispatch),
    sortProducts: (data) => sortProducts(data, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
// export default Header;
