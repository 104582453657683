import styled from "styled-components";

export const customModal = {
  /*backgroundColor: '#389244',
  borderRadius: 5,*/
  padding: 0,
}

export const WelcomePopupDialog = styled.div`  
  .icon-close-modal {
    display: none;
    width: 30px;
    height: 30px;
    background-color: rgb(56, 146, 68);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 2rem;
    right: 3rem;
    color: #fff;
    box-shadow: 0 0 2px #389244;
  }
    
  .box-wrapper {
    display: flex;
    gap: 20px;
    position: relative;
    height: 100%;
    min-height: 100px;
    
    .full-picture {
      width: 100%;
      height: auto;
      position: relative;
      z-index: 2;
    }
    .fake-link-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      z-index: 3;
      
      .custom-link {
        width: 50%;
        height: 100%;
        cursor: pointer;
      }
    }
    .spinner-wrapper {
      width: 100%;
      height: 100%;
      min-height: 100px;
      background-color: #fff;
      opacity: 0.5;
      display: flex;
      justify-content: center;
      align-items: center;
      
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    
    .col-left,
    .col-right {
      background-color: #ffffff;
      flex: 1;
      box-shadow: 0px 1px 5px 3px #3e5642;
      border-radius: 5px;
      cursor: pointer;
      
      .header{
        padding: 20px;
        text-align: center;
        width: 100%;
        max-width: 330px;
        margin: 0 auto;
        height: 100%;
        max-height: 150px;
      }
      .content {
        margin-left: 20px;
        margin-bottom: 50px;
        
        li { font-weight: bold; font-size: 1.1rem; }
      }
    }
    
    .col-left .media img {
      max-width: 280px;
    }
    .col-right .content {
      width: 100%;
      max-width: 240px;
      margin-left: auto;
      margin-right: auto;
    }
    
    .sub-title { font-size: 1.1rem; }
    .title { font-weight: bold; font-size: 1.1rem; }
    .divider { margin: 5px 0; }
    .media {
      display: flex;
      align-items: end;
      height: 100%;
      max-height: 120px;
      margin-bottom: 20px;
      
      img {
        display: block;
        width: 100%;
        max-width: 240px;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    } 
  }
  @media screen and (max-width: 767px) {
    .box-wrapper{
      flex-direction: column;
    }
    .icon-close-modal { display: flex; }
  }
`;