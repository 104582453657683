import React from "react";
import styled from "styled-components";
import { Modal } from "@redq/reuse-modal";
import t from '../i18n/translate';
import delishopOnlineSupportIMG from '../assets/images/delishop-online-support.png';

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 130px 0 60px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  
  p { margin-bottom: 5px; }
  
  @media (min-width: 990px) {
    width: 870px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 30px;
  }
`;

export default function ContactScreen() {
  return (
    <Modal>
      <HelpPageWrapper>
        <HelpPageContainer>
          <Heading>{ t('common_footer_menu_contact_us') }</Heading>
          
          <p><b>Delishop.asia</b></p>
          <p>{ t('contact_us_address_label') }</p>
          <p>{ t('contact_us_phone_label') } 081 999 405</p>
          <p>Telegram : <a href="https://t.me/delishopasiabot" target="_blank">https://t.me/delishopasiabot</a></p>
          <p>{ t('contact_us_email_label') } <a href="mailto: contact@delishop.asia">contact@delishop.asia</a></p>
          <p>{ t('contact_us_social_share_label') } <a href="https://www.facebook.com/Delishop.asia" target="_blank">https://www.facebook.com/Delishop.asia</a></p>
          <p>Or directly on the chat through the website :</p>
          <p>
            <img src={delishopOnlineSupportIMG}
                  alt="Delishop online support"
                  style={{
                    width: 'auto',
                    height: '100%',
                    maxHeight: 600,
                    display: 'block',
                    marginTop: 5
                  }}
            />
          </p>
 
        </HelpPageContainer>
      </HelpPageWrapper>
    </Modal>
  );
}
