import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import {
  OrderBox,
  OrderListWrapper,
  OrderList,
  OrderDetailsWrapper,
  ItemWrapper,
  ItemDetails,
  ItemName,
  ItemSize,
  ItemPrice,
  NoOrderFound,
} from "./Order.style";
import { convertDate, convertTime24to12 } from "../../../constants/Constant";
import OrderDetails from "./SingleOrderDetails/OrderDetails";
import OrderCard from "./OrderCard/OrderCard";
import OrderCardMobile from "./OrderCard/orderCardMobile";
import useComponentSize from "../../../helper/useComponentSize";
import t from '../../../i18n/translate';
import reactStringReplace from 'react-string-replace';
import Button from "../../../components/Button/Button";
import Modal from 'react-bootstrap/Modal';
import {useCart} from "../../../contexts/cart/use-cart";

const orderTableColumns = [
  {
    title: t('home_basket_items_label', false),
    dataIndex: "",
    key: "items",
    width: 250,
    ellipsis: true,
    render: (text, record) => {
      return (
        <ItemWrapper>
          <ItemDetails>
            <ItemName>{record.item_name}</ItemName>
            <ItemSize>
              {record.weight != null
                ? record.weight + " " + (record.weight_unit != null)
                  ? record.weight_unit
                  : ""
                : record.item_price_name}
            </ItemSize>
            <ItemPrice className="item-price">${parseFloat(record.item_price).toFixed(2)}</ItemPrice>
          </ItemDetails>
        </ItemWrapper>
      );
    },
  },
  {
    title: t('shop_order_list_quantity_label', false),
    dataIndex: "quantity",
    key: "quantity",
    align: "center",
    width: 100,
    render: (text, record) => {
      return <p>{record.quantity}</p>;
    },
  },
  {
    title: t('shop_order_list_price_label', false),
    dataIndex: "",
    key: "price",
    align: "right",
    width: 100,
    render: (text, record) => {
      return <p>${parseFloat(record.quantity * record.item_price).toFixed(2)}</p>;
    },
  },
];

/*
 * status = 0 => Order Saved
 * status = 1 => Order Removed
 * status = 2 => Order Pending
 * status = 3 => Order Processing
 * status = 4 => Order Pick Up
 * status = 5 => Order Delivering
 * status = 6 => Order Delivered
 * status = 7 => Order Canceled
 * status = 8 => Order Arrived
 */
const statusData = ["Saved", "Removed", "Pending", "Processing", "Pick Up", "Delivering", "Delivered", "Canceled", "Arrived"];

const OrdersContent = ({
  deviceType: { mobile, tablet, desktop },
  props,
  currentorders,
  currentorderDetails,
  tabactive,
}) => {
  const { fetchOrderItems } = useCart();
  const [order, setOrder] = useState(null);
  const [active, setActive] = useState("");
  const lang = localStorage.getItem('lang') || 'en';
  const [targetRef, size] = useComponentSize();
  const orderListHeight = size.height - 79;
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(t('shop_order_list_re_order_success_content_dialog'));
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (currentorders && currentorders.length !== 0) {
      if (tabactive == "currentOrder") {
        props.fetchParticularOrder({ order_id: currentorders[0].order_id, lang });
        setOrder(currentorderDetails);
        setActive(currentorders[0].order_id);
      }
    }
  }, [currentorders, tabactive]);

  useEffect(() => {
    if (currentorders && currentorders.length !== 0) {
      setOrder(currentorderDetails);
    }
  }, [currentorderDetails, tabactive]);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parsedUser = JSON.parse(user);
    if (parsedUser) {
      let cust_id = parsedUser.cust_id;
      props.currentOrders({ cust_id: parsedUser.cust_id, lang });
    }
  }, [tabactive]);

  const handleClick = (order) => {
    props.fetchParticularOrder({ order_id: order.order_id, lang });
    setOrder(currentorderDetails);
    setActive(order.order_id);
  };

  const handleOnError = (eventData) => {
    setMessage(reactStringReplace(eventData, /(\n)/g, () => <br />));
    setIsError(true);
    setShow(true);
  }
  const handleOnSuccess = (eventData) => {
    setShow(true);
  }

  const handleModalClose = () => {
    setShow(false);
    setIsError(false);
    props.resetreordersuccess();
    localStorage.setItem("reordered", true);
    fetchOrderItems( props.cartType );
    if(!document.getElementsByClassName("cartPopupFixed").length) {
      document.getElementsByClassName("product-cart")[0].click();
    }
  };

  return (
    <OrderBox>
      {window.innerWidth > 768 ? (
        <>
          {/*Order list right side panel*/}
          <OrderListWrapper style={{ height: size.height }}>
            <Scrollbars
              universal
              autoHide
              autoHeight
              autoHeightMin={420}
              autoHeightMax={isNaN(orderListHeight) ? 500 : orderListHeight}
            >
              <OrderList>
                {currentorders != undefined && currentorders.length !== 0 ? (
                  currentorders.map((order) => (
                    <OrderCard
                      progressBox={statusData}
                      props={props}
                      key={order.order_id}
                      orderId={order.order_id}
                      className={
                        `${order.type ? "express" : "normal"} ${order && order.order_id === active ? "active" : ""}`
                      }
                      status={order.status}
                      date={convertDate(
                        order.placedAt.split(":")[0].split(" ")[0]
                      )}
                      // deliveryTime={order.deliveryTime}
                      amount={order.order_payment_details[0].to_pay}
                      onClick={() => {
                        handleClick(order);
                      }}
                      type="current_orders"
                      onError={handleOnError}
                      onSuccess={handleOnSuccess}
                    />
                  ))
                ) : (
                  <NoOrderFound>
                    { t('shop_order_list_end_of_orders_historic') }
                  </NoOrderFound>
                )}
              </OrderList>
            </Scrollbars>
          </OrderListWrapper>

          <OrderDetailsWrapper ref={targetRef}>
            {order && order.order_id && (
              <OrderDetails
                progressStatus={order.status}
                progressBox={statusData}
                pageType={"current-order"}
                name={
                  order.list_customer_addresses[0]?.first_name +
                  " " +
                  order.list_customer_addresses[0]?.last_name
                }
                phone={order.list_customer_addresses[0]?.mobile_number}
                country_code={order.list_customer_addresses[0]?.country_code}
                delivery_slot={
                  typeof order.store_on_going_slots !== 'undefined'
                    ? convertDate(order.store_on_going_slots[0].Date) +
                    " | " +
                    convertTime24to12(
                      order.store_on_going_slots[0].start_time.split(" ")[1]
                    ) +
                    " - " +
                    convertTime24to12(
                      order.store_on_going_slots[0].end_time.split(" ")[1]
                    )
                    : null
                }
                address={order.list_customer_addresses[0]?.area_details}
                subtotal={order.order_payment_details[0]?.item_total}
                discount={
                  order.order_payment_details[0].discount
                    ? order.order_payment_details[0].discount
                    : "0.00"
                }
                deliveryFee={order.order_payment_details[0].delivery_fee}
                grandTotal={order.order_payment_details[0].to_pay}
                comments={order.comments}
                tableData={order.order_item_details}
                columns={orderTableColumns}
                props={props}
                orderId={order.order_id}
                type="current_orders"
                onError={handleOnError}
                onSuccess={handleOnSuccess}
              />
            )}
          </OrderDetailsWrapper>
        </>
      ) : (
        <OrderList className={props.cartType}>
          {currentorders != undefined && currentorders.length !== 0 ? (
            currentorders.map((order) => (
              <OrderCardMobile
                key={order.order_id}
                order={order}
                orderId={order.order_id}
                className={`${props.cartType} ${order && order.order_id === active ? "active" : ""}`}
                progressStatus={order.status}
                tableData={order.order_item_details}
                columns={orderTableColumns}
                pageType={"current-order"}
                props={props}
                onClick={() => {
                  handleClick(order);
                }}
                type="current_orders"
                onError={handleOnError}
                onSuccess={handleOnSuccess}
              />
            ))
          ) : (
            <NoOrderFound>
              { t('shop_order_list_end_of_orders_historic') }
            </NoOrderFound>
          )}
        </OrderList>
      )}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{isError
            ? t('shop_checkout_alert_error')
            : t('shop_order_list_re_order_success_title_dialog')
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={isError ? {} : {color: "green"} }>
          { message }
        </Modal.Body>
        <Modal.Footer>
          <Button
            title={ t('shop_order_list_button_ok_dialog', false) }
            variant="primary"
            onClick={handleModalClose}
          >
            { t('shop_order_list_button_ok_dialog') }
          </Button>
        </Modal.Footer>
      </Modal>
    </OrderBox>
  );
};

export default OrdersContent;
