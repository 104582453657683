import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import {
  SingleOrderList,
  OrderListHeader,
  TrackID,
  Status,
  Reorder,
  OrderMetas,
  Meta,
} from "./OrderCard.style";
import { CURRENCY } from "../../../../helper/constant";
import t from '../../../../i18n/translate';
import SimpleMessageDialog from "../../../../components/SimpleMessageDialog/SimpleMessageDialog";
import {useCart} from "../../../../contexts/cart/use-cart";
import * as actionTypes from "../../../../store/ActionTypes";
import itemToCartService from "../../../../services/itemToCartService";

const OrderCard = ({
  orderId,
  onClick,
  className,
  status,
  date,
  progressBox,
  deliveryTime,
  amount,
  onError,
  onSuccess,
  type,
  props
}) => {
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { items } = useCart();

  const [closeLabel, setCloseLabel] = useState('');
  const [confirmLabel, setConfirmLabel] = useState('');
  const [dialogHeaderLabel, setDialogHeaderLabel] = useState(t('shop_checkout_alert_error'));
  const [simpleDialogBackdrop, setSimpleDialogBackdrop] = useState(true);
  const [isConfirmButton, setIsConfirmButton] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();
  const handleReOrder = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const currentOrder = props[type].find(item => item.order_id === orderId);
    const isExpressMode = props.cartType === 'express';
    setCloseLabel( t('button_close_label') );

    if(currentOrder.type && !isExpressMode) {
      setErrorMessage( t('shop_order_detail_switch_to_express_mode_message_for_reorder') );
      setShowErrorDialog(true);
    }
    else if(isExpressMode && !currentOrder.type) {
      setErrorMessage( t('shop_order_detail_switch_to_normal_mode_message_for_reorder') );
      setShowErrorDialog(true);
    }
    else {
      // If user have some items in current basket
      if(items.length) {
        setErrorMessage( t('shop_order_detail_re_order_error') );
        setConfirmLabel( t('shop_item_detail_confirm_button') );
        setCloseLabel( t('shop_tracking_order_cancel_button') );
        setIsConfirmButton(true);
        setShowErrorDialog(true);
      }
      else {
        setIsFetching(true);
        await reOrderValidator();
      }
    }
  }

  const reOrderValidator = async () => {
    if(!isFetching) {
      const dataResp = await itemToCartService.reOrder(orderId);
      if(dataResp.status === 401) {
        setErrorMessage( dataResp.data.message );
        setIsConfirmButton(false);
        setShowErrorDialog(true);
      }
      else if(dataResp.status === 400) {
        onError(dataResp.data.message);
        setShowErrorDialog(false);
      }
      else if(dataResp.status === 200) {
        if(!props.reordersuccess) {
          dispatch({
            type: actionTypes.Reorder,
            reordersuccess: true
          });
        }
        onSuccess(dataResp.data);
        setShowErrorDialog(false);
      }
      setIsFetching(false);
    }
  }

  const onConfirm = async () => {
    setIsLoadingDialog(true);
    await reOrderValidator();
    setIsLoadingDialog(false);
  }
  const closeSimpleDialog = () => {
    setShowErrorDialog(false);
    setIsConfirmButton(false);
    setIsLoadingDialog(false);
    setErrorMessage('');
    setConfirmLabel('');
    setCloseLabel('');
  }

  useEffect(() => {
    if(props.reordersuccess) {
      closeSimpleDialog();
    }
  }, [props.reordersuccess]);

  return (
    <>
      <SingleOrderList onClick={onClick} className={className}>
        <OrderListHeader>
          <TrackID>
            { t('shop_order_list_screen_title') } <span>#{orderId}</span>
          </TrackID>
          {localStorage.getItem("user")
            ? <Reorder onClick={handleReOrder}>
              { t('shop_order_list_re_order_label') }
            </Reorder>
            : null
          }
          <Status>
            {typeof progressBox !== 'undefined'
              ? t(`shop_order_list_status_${ progressBox[status].replace(' ', '_').toLowerCase() }`)
              : ''
            }
          </Status>
        </OrderListHeader>

        <OrderMetas>
          <Meta>
            { t('shop_order_list_order_date_title') }: <span>{date}</span>
          </Meta>
          <Meta className="price">
            { t('shop_order_list_total_title') }
            <span>
              {CURRENCY}
              {parseFloat(amount).toFixed(2)}
            </span>
          </Meta>
        </OrderMetas>
      </SingleOrderList>

      <SimpleMessageDialog show={showErrorDialog}
                           message={errorMessage}
                           showHeader={true}
                           closeLabel={closeLabel}
                           confirmLabel={confirmLabel}
                           headerLabel={dialogHeaderLabel}
                           isConfirmButton={isConfirmButton}
                           onClose={closeSimpleDialog}
                           onConfirm={onConfirm}
                           loading={isLoadingDialog}
                           backdrop={simpleDialogBackdrop}
                           cartType={props.cartType}
      />
    </>
  );
};

export default OrderCard;
