import React, { useState, useEffect, lazy, Suspense } from "react";
import { Link, Redirect } from "react-router-dom";
import { openModal, closeModal } from "@redq/reuse-modal";
import ProductCard from "../../components/ProductCard/ProductCard";
import AuthenticationForm from "../../containers/SignInOutForm/Form";
import { AuthContext } from "../../contexts/auth/auth.context";
import { SearchContext } from "../../contexts/search/search.context";
import { useCart } from "../../contexts/cart/use-cart";
import {
  ProductsRow,
  ProductsCol,
  ButtonWrapper,
  LoaderWrapper,
  LoaderItem,
  ProductCardWrapper,
  FilterWrapper,
  FilterDiv,
  FilterModalWrapper,
  BrandName,
  BrandInfo,
  BrandImageWrapper,
  PromoWrapper,
  PromoTitle,
  NewArrivalTitle,
  Wrapper,
  Prev,
  Next,
  BoxTitle,
  ErrorModal,
  ViewAll
} from "./Products.style";
import { CURRENCY } from "../../helper/constant";
import { useLocation, useHistory } from "react-router-dom";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import Fade from "react-reveal/Fade";
import NoResultFound from "../../components/NoResult/NoResult";
import QuickView from "../QuickView/QuickView";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Placeholder from "../../components/Placeholder/Placeholder";
import { Modal } from "react-bootstrap";
import Switch from "react-switch";
import DropDown from "../../components/DropDown";
import BrandFilter from "../../components/BrandFilter";
import Carousel from "react-multi-carousel";
import { BrandButton } from "../../components/BrandFilter/BrandFilter.style";
import Loader from "../../components/Loader/Loader";
import { SearchBox } from "../../components/SearchBox/SearchBox.style";
import PageLoader from "../../components/PageLoader/PageLoader";
import _ from "lodash";
import { cats } from "../../helper/constant";
import LazyLoad from "react-lazyload";
import { ArrowNext, ArrowPrev } from "../../components/AllSvgIcon";
import {getCookie, setCookie} from "../../helper/constant";
import expressService from "../../services/expressService";
import t from '../../i18n/translate';

const animatedComponents = makeAnimated();

export const Products = ({
  deviceType,
  type,
  // ptype = "usual",
  fetchLimit = 8,
  loadMore = true,
  props,
  storeId,
  textSearch,
  isExtra,
}) => {
  const { isloading, disableloading,updateItem } = useCart();
  //   // Quick View Modal
  const [show, setShow] = useState(false);
  const [isBrandFilter, setBrandFilter] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const [cat_name, setCat_name, search_text] = useState("");
  const [sub_cat_name, setSubCat_name] = useState("");
  const [filterShow, setFilterShow] = useState(false);
  const [brandname, setBrandName] = useState("");
  const [brandinfo, setBrandInfo] = useState("");
  const [loader, setLoader] = useState(false);
  const [bvalues, setBValues] = useState([]);
  const [showbwpopup, setShowBWPopup] = useState(true);
  const [itemCategory, setItemCategory] = useState([]);
  const [showFilterNotFound, setShowFilterNotFound] = useState(false);

  ///////////// brand filter modal //////////////
  const [isBrandSelected, setBrandSelected] = useState({});

  const SUB_CAT_ID = localStorage.getItem("sub_cat_id");
  const CAT_ID = localStorage.getItem("cat_id");
  const onHide = () => setShow(false);
  const onClose = () => setBrandFilter(false);
  const onOpen = () => setBrandFilter(true);
  const filterOnHide = () => setFilterShow(false);
  const [isOutOfStock, setIsOutOfStock] = useState(0);
  const [brandId, setBrandId] = useState([]);
  const [sort, setSort] = useState('');
  const lang = localStorage.getItem('lang') || 'en';
  const [displayExpressMultiLabel, setDisplayExpressMultiLabel] = useState(false);

  const {
    authState: { isAuthenticated },
    authDispatch,
  } = React.useContext(AuthContext);
  const { state, searchdispatch } = React.useContext(SearchContext);
  const [showform, setShowForm] = useState(false);
  const handleJoin = () => {
    authDispatch({
      type: "SIGNIN",
    });
    setShowForm(true);
  };
  const handleQuickViewModal = (modalProps, deviceType, index) => {
    props.ProductModalData(modalProps);

    setShow(true);
    trackClick(modalProps, index);
    trackDetails(modalProps, index);
  };
  const trackClick = (item, index) => {
    //track click
    window.analytics && window.analytics.track("Product Clicked", {
      product_id: item.item_id,
      sku: item.product_code,
      category: item.cat_id,
      name: item.name,
      brand:
        item.list_brands &&
        item.list_brands[0] &&
        item.list_brands[0].brand_name
          ? item.list_brands[0].brand_name
          : "",
      variant: item.name,
      price: item.store_items_price_types[0].price,
      quantity: 1,
      position: index + 1,
    });
  };
  const trackDetails = (item, index) => {
    //track click
    window.analytics && window.analytics.track("Product Viewed", {
      product_id: item.item_id,
      sku: item.product_code,
      category: item.cat_id,
      name: item.name,
      brand:
        item.list_brands &&
        item.list_brands[0] &&
        item.list_brands[0].brand_name
          ? item.list_brands[0].brand_name
          : "",
      variant: item.name,
      price: item.store_items_price_types[0].price,
      quantity: 1,
      position: index + 1,
      currency: "USD",
      value: item.store_items_price_types[0].price,
    });
  };
  const trackPromotionClick = (item, index) => {
    //track click
    window.analytics && window.analytics.track("Promotion Clicked", {
      promotion_id: item.item_id,
      creative: "",
      name: item.name,
      position: index + 1,
    });
  };
  let { pathname, search } = useLocation();
  let history = useHistory();

  const freezeClicFn = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const isInViewport = () => {
    const wrappedElement = document.getElementById('productList');
    if (wrappedElement && wrappedElement.getBoundingClientRect().bottom <= window.innerHeight && window.location.pathname.split("/")[1] !== "brand" && props.page >= 1) {
      if(document.getElementById("showMore")){
        setLoader(true);
        document.getElementById("showMore").click();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", isInViewport);
    return () => {
      document.removeEventListener("scroll", isInViewport);
    };
  },[]);

  useEffect(() => {
    if (isloading) {
      document.getElementById("root").classList.add("loading");
    } else {
      document.getElementById("root").classList.remove("loading");
    }
  }, [isloading]);
  useEffect(() => {
    if(props.oos === 0 || !props.oos) {
      setChecked(false);
    }
    else {
      setChecked(true);
    }
  }, [props.oos]);

  useEffect(() => {
    if (isAuthenticated) {
      setShowForm(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (props.brands.length > 0) {
      for (let i = 0; i < props.brands.length; i++) {
        if(window.location.pathname.split("/")[1] === "brand" && window.location.pathname.split("/")[2] === props.brands[i]["brand_ref_id"]){
          setBrandName(props.brands[i]["name"] !== null ? props.brands[i]["name"] : "");
          setBrandInfo(props.brands[i]["description"] !== null ? props.brands[i]["description"] : "" );
          document.title = props.brands[i]["name"];
        }
        props.brands[i].label = props.brands[i]["name"];
        props.brands[i].value = props.brands[i]["brand_id"];
      }
    }
  }, [props.brands]);

  useEffect(() => {
    const selector = document.getElementById('isExtraData');

    if(selector === null) {
      setDisplayExpressMultiLabel(false);
    }
    else if(selector && !displayExpressMultiLabel) {
      createExtraLabel(selector);
    }
    else if(!document.querySelector('.clearFlex') && selector) {
      createExtraLabel(selector);
    }
  })

  const createExtraLabel = (selector, ) => {
    setDisplayExpressMultiLabel(true);
    let container = document.createElement("div"); // Create div tag
    container.classList.add('clearFlex'); // Assign clearFlex class to div tag
    let span = document.createElement("span"); // Create span tag
    span.textContent = t('shop_extra_supplier_item_title'); // Add localize text into span tag
    container.appendChild(span); // Add span tag into div tag
    selector.parentNode.insertBefore(container, selector); // Do insert before
  };

  const bannerPickers = () =>{
    let banner_image= "gg"
    if(parseInt( localStorage.getItem("sub_cat_id")) != 0 && localStorage.getItem("sub_cat_id") ){
      props.category.forEach(element => {
        element.map_categories_sub_cats.forEach(e => {
          
          if( e.list_sub_categories[0].sub_cat_id == parseInt(localStorage.getItem("sub_cat_id")) ) banner_image = e.list_sub_categories[0].banner_image
        }); 
      });
    }else if(localStorage.getItem("cat_id")){
      props.category.forEach(e => {
        if(e.cat_id == parseInt(localStorage.getItem("cat_id")) ) banner_image = e.banner_image;
      })
    }
   // return banner_image;
    if(banner_image && banner_image !== 'gg') {
      return <img src={banner_image} alt="Delishop banner" width={"100%"} height={"auto"} style={{margin: "20px auto"}} />
    }
  }

  const toggleCheckbox = async () => {
    setFilterShow(false);
    let filteredProducts = {brandIdList: [], categorySelected: [], subCategorySelected: [], outOfStock: 0, search: '', sort: ''};
    if(!Array.isArray(props.filteredProducts) && Object.keys(props.filteredProducts).length) {
      filteredProducts.brandIdList = props.filteredProducts.brandIdList;
      filteredProducts.categorySelected = props.filteredProducts.categorySelected;
      filteredProducts.subCategorySelected = props.filteredProducts.subCategorySelected;
      filteredProducts.search = props.filteredProducts.search;
      filteredProducts.sort = props.filteredProducts.sort;
    }

    if (!checked === true) {
      setChecked(true);
      setIsOutOfStock(1);
      props.oosModified({status: true});

      if(props.cartType === 'express') {
        filteredProducts.outOfStock = 1;
        const itemsStore = await expressService.storeItems(
          [storeId],
          filteredProducts.brandIdList,
          filteredProducts.categorySelected,
          filteredProducts.subCategorySelected,
          1,
          filteredProducts.search,
        );
        props.initProductExpressStore(itemsStore.list);
        props.sortProducts(filteredProducts);
      }
      else {
        if (!((cats).includes(window.location.pathname.split("/")[1]) && window.location.pathname.split("/").length === 2)){
          localStorage.setItem("mode","subcatoos");
          localStorage.setItem("sub_cat_id", window.location.pathname === "/" || window.location.pathname === "/best-deals/promotions" ? 187 : (localStorage.getItem("sub_cat_id") ? localStorage.getItem("sub_cat_id") : props.products[0].sub_cat_id));
          props.resetPage();
          props.getProductOutOfStock({
            sub_cat_id: window.location.pathname === "/" ? 187 : localStorage.getItem("sub_cat_id"),
            store_id: 1,
            oos: 1,
            page: 1
          });
        }
        else{
          localStorage.setItem("mode","catoos");
          localStorage.setItem("cat_id", window.location.pathname === "/" || window.location.pathname === "/best-deals" ? 18 : props.products[0].cat_id);
          props.resetPage();
          props.getProductOutOfStockCat({
            cat_id:
              window.location.pathname === "/" || window.location.pathname === "/best-deals"
                ? 18
                : props.products[0].cat_id,
            store_id: 1,
            oos: 1,
            page: 1
          });
        }
      }
    }
    else {
      setChecked(false);
      setIsOutOfStock(0);
      props.oosModified({status: false});

      if(props.cartType === 'express') {
        filteredProducts.outOfStock = 0;
        const itemsStore = await expressService.storeItems(
          [storeId],
          filteredProducts.brandIdList,
          filteredProducts.categorySelected,
          filteredProducts.subCategorySelected,
          0,
          filteredProducts.search,
        );
        props.initProductExpressStore(itemsStore.list);
        props.sortProducts(filteredProducts);
      }
      else {
        if (!((cats).includes(window.location.pathname.split("/")[1]) && window.location.pathname.split("/").length === 2)) {
          localStorage.setItem("sub_cat_id", window.location.pathname === "/" ? 187 : (localStorage.getItem("sub_cat_id") ? localStorage.getItem("sub_cat_id") : props.products[0].sub_cat_id));
          localStorage.setItem("mode","subcat");
          props.resetPage();
          props.getProductsMappedwithSubCat({
            sub_cat_id: window.location.pathname === "/" ? 187 : localStorage.getItem("sub_cat_id"),
            store_id: 1,
            page: 1,
            lang
          });
        }
        else {
          localStorage.setItem("cat_id", window.location.pathname === "/" || window.location.pathname === "/best-deals" ? 18 : props.products[0].cat_id);
          localStorage.setItem("mode","cat");
          props.resetPage();
          props.getProductsMappedwithCat({
            cat_id:
              window.location.pathname === "/" || window.location.pathname === "/best-deals"
                ? 18
                : props.products[0].cat_id,
            store_id: 1,
            page: 1
          });
        }
      }
    }
  };

  const sortName = async (type) => {

    if(props.cartType && props.cartType === 'express') {
      let filteredProducts = {brandIdList: [], categorySelected: [], subCategorySelected: [], outOfStock: 0, search: '', sort: ''};
      if(!Array.isArray(props.filteredProducts) && Object.keys(props.filteredProducts).length) {
        filteredProducts.brandIdList = props.filteredProducts.brandIdList;
        filteredProducts.categorySelected = props.filteredProducts.categorySelected;
        filteredProducts.subCategorySelected = props.filteredProducts.subCategorySelected;
        filteredProducts.search = props.filteredProducts.search;

        if(textSearch !== '' && window.location.pathname.includes('/express/store')) {
          filteredProducts.search = textSearch;
        }
      }

      let itemsStore = {};
      let sortBy = type === 1 ? 'a-z' : 'z-a';
      setSort(sortBy);

      if(history.location.pathname.includes('/express/search')) {
        itemsStore = await expressService.searchItems(
          [storeId],
          brandId,
          filteredProducts.categorySelected,
          filteredProducts.subCategorySelected,
          1,
          filteredProducts.search,
          sortBy
        );
      }
      else {
        itemsStore = await expressService.storeItems(
          [storeId],
          brandId,
          filteredProducts.categorySelected,
          filteredProducts.subCategorySelected,
          1,
          filteredProducts.search,
          sortBy
        );
      }

      // Remove label of Extra item of multi store
      const extraDataLabel = document.querySelector('.clearFlex');
      if(extraDataLabel) {
        extraDataLabel.remove();
      }
      if(Object.keys(itemsStore).length) {
        props.initProductExpressStore(itemsStore.list);
      }
      props.sortProducts(filteredProducts);
    }
    else {
      props.getSortProductsName(type, props.products);
    }
    setFilterShow((pre) => !pre);
  };
  const sortPrice = async (type) => {
    if(props.cartType && props.cartType === 'express') {
      let filteredProducts = {brandIdList: [], categorySelected: [], subCategorySelected: [], outOfStock: 0, search: '', sort: ''};
      if(!Array.isArray(props.filteredProducts) && Object.keys(props.filteredProducts).length) {
        filteredProducts.brandIdList = props.filteredProducts.brandIdList;
        filteredProducts.categorySelected = props.filteredProducts.categorySelected;
        filteredProducts.subCategorySelected = props.filteredProducts.subCategorySelected;
        filteredProducts.search = props.filteredProducts.search;
        filteredProducts.sort = props.filteredProducts.sort;

        if(textSearch !== '' && window.location.pathname.includes('/express/store')) {
          filteredProducts.search = textSearch;
        }
      }
      let itemsStore = {};
      let sortBy = type === 3 ? 'low-high' : 'high-low';
      setSort(sortBy);
      if(history.location.pathname.includes('/express/search')) {
        itemsStore = await expressService.searchItems(
          [storeId],
          filteredProducts.brandIdList,
          filteredProducts.categorySelected,
          filteredProducts.subCategorySelected,
          1,
          filteredProducts.search,
          sortBy
        );
      }
      else {
        itemsStore = await expressService.storeItems(
          [storeId],
          filteredProducts.brandIdList,
          filteredProducts.categorySelected,
          filteredProducts.subCategorySelected,
          1,
          filteredProducts.search,
          sortBy
        );
      }

      // Remove label of Extra item of multi store
      const extraDataLabel = document.querySelector('.clearFlex');
      if(extraDataLabel) {
        extraDataLabel.remove();
      }

      props.initProductExpressStore(itemsStore.list);
      props.sortProducts(filteredProducts);
    }
    else {
      props.getSortProductsPrice(type, props.products);
    }
    setFilterShow((pre) => !pre);
  };

  const sortByNameProduct = (e) => {
    props.sortByNameProduct(e.target.value, props.products);
  };

  const sortItems = [
    { name: t('shop_item_sort_by_name_asc_title', false), onClick: () => sortName(1) },
    { name: t('shop_item_sort_by_name_desc_title', false), onClick: () => sortName(2) },
    { name: t('shop_item_sort_by_price_low_to_high_title'), onClick: () => sortPrice(3) },
    { name: t('shop_item_sort_by_price_high_to_low_title'), onClick: () => sortPrice(4) },
  ];

  if (props.category.length > 0 && props.products.length === 0 && window.location.pathname.split("/").indexOf("search") >= 0 && props.loading === false) {
    return <NoResultFound props={props} />;
  }

  const setSelectedBrands = async (values = selected) => {
    setFilterShow(false);
    let b = values.map((el) => {
      return el.value;
    });
    setBValues(b);
    setItemCategory(b);
    if(props.cartType && props.cartType === 'express') {
      let filteredProducts = {brandIdList: [], categorySelected: [], subCategorySelected: [], outOfStock: 0, search: '', sort: ''};
      filteredProducts.brandIdList = b;
      if(!Array.isArray(props.filteredProducts) && Object.keys(props.filteredProducts).length) {
        filteredProducts.categorySelected = props.filteredProducts.categorySelected;
        filteredProducts.subCategorySelected = props.filteredProducts.subCategorySelected;
        filteredProducts.search = props.filteredProducts.search;
        filteredProducts.sort = props.filteredProducts.sort;
      }

      const itemsList = await expressService.storeItems(
        [storeId],
        b,
        filteredProducts.categorySelected,
        filteredProducts.subCategorySelected,
        isOutOfStock,
        filteredProducts.search,
        sort
      );

      if(itemsList.list.length) {
        setBrandId(b);
        props.initProductExpressStore(itemsList.list);
        props.sortProducts(filteredProducts);
      }
      else {
        setShowFilterNotFound(true)
      }
    }
    else {
      props.getProductFilterBrands(
        values,
        props.products,
        props.allproducts,
        SUB_CAT_ID,
        localStorage.getItem("cat_id"),
        props.oos,
        state.text,
      );
    }
  };

  const handleSelection = (item) => {
    const isBrandPresent =
      selected && selected.length
        ? selected.find((brand, index) => brand.value === item.value)
        : null;
    if (!isBrandPresent) {
      setBrandSelected((pre) => ({ ...pre, [`${item.value}`]: true }));
      setSelected((pre) => {
        let a = pre;
        a.push(item);
        return a;
      });
    } else {
      const newArr = selected.filter((brand) => brand.value !== item.value);
      setBrandSelected((pre) => {
        delete pre[`${item.value}`];
        return pre;
      });
      setSelected(newArr);
    }
  };
  const clearAllBrand = () => {
    setBrandSelected({});
    setSelected([]);
  };

  const getbrandimage = (name) => {
    var b = props.brands.filter(elem => {
      return elem.name === name   
    });
    return b.length !== 0 ? b[0].image : null;
  };

  const PrevButton = ({ onClick, children }) => {
    return (
      <Prev
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        className="prevButton"
      >
        {children}
      </Prev>
    );
  };
  const NextButton = ({ onClick, children }) => {
    return (
      <Next
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        className="nextButton"
      >
        {children}
      </Next>
    );
  };
  
  const ButtonGroup = ({ next, previous }) => {
    return (
      <Wrapper>
        <>
          <PrevButton onClick={() => previous()}>
            <ArrowPrev />
          </PrevButton>
          <NextButton onClick={() => next()}>
            <ArrowNext />
          </NextButton>
        </>
  
        {/* if prop isRtl true swap prev and next btn */}
      </Wrapper>
    );
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const searchCategory = () => {
    if (props.products.length > 0) {
      let cat_id =
        window.location.pathname === "/" ? 18 : props.products[0].cat_id;

      if (!((cats).includes(window.location.pathname.split("/")[1]) && window.location.pathname.split("/").length === 2) || !(window.location.pathname.split("/")[1] === "search") || !(window.location.pathname.split("/")[1] === "brand")){
        // localStorage.setItem("sub_cat_id", sub_cat_id);
      }
    
      let cat_data = null;
      let cat_name = null;
      let sub_cat_name = null;
      if (window.location.pathname.split("/")[1] !== "search" && window.location.pathname.split("/")[1] !== "brand"){
        props.category.map((element, index) => {
          if (element.cat_id == cat_id) {
            cat_data = element;
            cat_name = element.name;
          } else {
            
          }
        });
      }

      return (
        <div>
          {window.location.pathname.split("/")[1] === "brand" ? 
          <>
          <BrandImageWrapper>
          <LazyLoad height={70}>
          <img
              src={getbrandimage(brandname)}
              className="brand-image"
              // style={{ height: "80%", width: "auto", maxHeight: "100%" }}
              alt={brandname}
              onError={(e)=>{
                e.target.onerror = null;
                e.target.src = require('../../image/Background.png');
              }}
              // onLoad={() => setLoaded(true)}
              // onLoad={()=> {return <Loader />}}
            />
            </LazyLoad>
          </BrandImageWrapper>
          <BrandInfo>
            {brandinfo}
          </BrandInfo>
          </> : null}
          {window.location.pathname.split("/")[1] === "search" ? <span style={{fontWeight: "700"}}>{ t('shop_current_location_search_result_label') }</span> : null}
        </div>
      );
    } else {
      return (
        <div>
          {window.location.pathname.split("/")[1] === "brand" ? <div style={{fontWeight: "500" , margin: "10px 0 10px 0"}}>{brandinfo}</div> : null}
          {window.location.pathname.split("/")[1] === "search" ? <span style={{fontWeight: "700"}}>{ t('shop_current_location_search_result_label') }</span> : null}
        </div>
      );
    }
  };
  return (
    <>
      <FilterWrapper>
        <div className="row" style={{ marginBottom: "15px", width: "100%" }}>
          <div className="col-sm-12" style={{ alignSelf: "center", width: "100%" }}>
            {searchCategory()}
          </div>
        </div>
        <FilterDiv>
          <div className="row">
            {window.screen.width >= 700 && props.cartType !== 'express' ? (
              <div className="col-sm-two">
                { window.location.pathname.split("/")[1] !== "search" && window.location.pathname.split("/")[1] !== "brand" && <label>
                  <span style={{marginTop: "5px"}}>{ t('shop_item_show_out_of_stock_option_title') }</span>
                  <Switch className={`react-switch ${!!checked ? 'active' : ''}`} onClick={toggleCheckbox} onChange={toggleCheckbox} checked={ !!checked } />
                </label>}
              </div>
            ) : null}
            {props.filterBrands.length > 0 && props.cartType !== 'express' ? (
              <div style={{ padding: "0px" }} className="col-sm-eight">
                <BrandFilter
                  brands={props.filterBrands}
                  setSelectedBrands={setSelectedBrands}
                  isModal={isBrandFilter}
                  ModalOpen={onOpen}
                  ModalClose={onClose}
                />
              </div>
            ) : window.screen.width < 700 && props.cartType !== 'express' ? (
              <BrandFilter
                brands={props.filterBrands}
                setSelectedBrands={setSelectedBrands}
                isModal={isBrandFilter}
                ModalOpen={onOpen}
                ModalClose={onClose}
              />
            ) : props.cartType !== 'express' ? <div className="_1q59bef"></div> : null
            }
            <div className={`col-sm-two sort-filter-hide ${props.cartType}`}>
              <DropDown title={ t('shop_item_sort_title', false) } items={sortItems} />
            </div>

            {props.cartType !== 'express'
              ? <>
                <div className="_1q59bef"></div>
                <div className="col-sm-two sort-name-filter">
                  <input
                    type="text"
                    style={{
                      borderColor: "rgb(176, 176, 176) !important",
                      padding: "4px 10px",
                      width: "120px",
                      borderRadius: "20px",
                      borderWidth: "1px",
                      borderTopColor: "rgb(176, 176, 176) !important",
                      background: "white",
                      borderStyle: "double",
                      outline: "none",
                    }}
                    placeholder={ t('shop_item_filter_placeholder', false) }
                    value={props.search_text}
                    name="type to filter"
                    onChange={(e) => sortByNameProduct(e)}
                  />
                </div>
              </>
              : null
            }
          </div>
        </FilterDiv>
        {bannerPickers()}
      </FilterWrapper>

      {<ProductsRow id={"productList"}>
        {props.FilteredNameProducts == false
          ? props.products.length
            ? props.products.map((item, index) =>
              <ProductsCol key={index}
                           id={typeof item.isExtraIndex !== 'undefined' && item.isExtraIndex === 0 ? `isExtraData` : null }
              >
                <ProductCard
                  title={item.name}
                  description={item.about_item}
                  image={item.default_image_url}
                  weight={
                    item.store_items_price_types[0].name !== " "
                      ? item.store_items_price_types[0].name
                      : item.store_items_price_types[0].weight +
                      " " +
                      item.store_items_price_types[0].weight_unit
                  }
                  outOfStock={
                    !item.store_items_price_types[0].available_quantity
                  }
                  currency={CURRENCY}
                  price={item.store_items_price_types[0].mrp}
                  salePrice={item.store_items_price_types[0].price}
                  isSameDay={item.same_day}
                  discountInPercent={item.discountInPercent}
                  data={item}
                  deviceType={deviceType}
                  onClick={() => {
                    handleQuickViewModal(item, deviceType, index)
                  }}
                  onCartClick={() => {
                    document.getElementById("joinBTn").click();
                  }}
                  onUpdate={(quantity) => {
                    updateItem(item,quantity);
                  }}
                  promo={item.promo ? 1 : 0}
                  promoPrice={item.promo ? item.promo_price : null}
                  promoStartDate={item.promo ? item.promo_start_date : null}
                  promoEndDate={item.promo ? item.promo_end_date : null}
                  brandId={item.brand_id === 0 || item.brand_id === null ? 0 : item.brand_id}
                  brands={props.brands}
                  loading={props.loading}
                  page={props.page}
                  productCode={item.product_code}
                />
              </ProductsCol>
            )
            : <p>{ t('error_product_not_found') }</p>
          : null
        }
        {props.FilteredNameProducts == true &&
          props.filteredProducts.map((item, index) => (
            <ProductsCol key={index}>
              <ProductCardWrapper>
                <ProductCard
                  title={item.name}
                  description={item.about_item}
                  image={item.default_image_url}
                  weight={
                    item.store_items_price_types[0].name !== " "
                      ? item.store_items_price_types[0].name
                      : item.store_items_price_types[0].weight +
                        " " +
                        item.store_items_price_types[0].weight_unit
                  }
                  outOfStock={
                    !item.store_items_price_types[0].available_quantity
                  }
                  // weight={item.store_items_price_types[0].name}
                  currency={CURRENCY}
                  price={item.store_items_price_types[0].mrp}
                  salePrice={item.store_items_price_types[0].price}
                  isSameDay={item.same_day}
                  discountInPercent={item.discountInPercent}
                  data={item}
                  deviceType={deviceType}
                  onClick={() => {
                    handleQuickViewModal(item, deviceType, index)
                  }}
                  onCartClick={() => {
                    document.getElementById("joinBTn").click();
                  }}
                  promo={item.promo ? 1 : 0}
                  promoPrice={item.promo ? item.promo_price : null}
                  promoStartDate={item.promo ? item.promo_start_date : null}
                  promoEndDate={item.promo ? item.promo_end_date : null}
                  brandId={item.brand_id === 0 || item.brand_id === null ? 0 : item.brand_id}
                  brands={props.brands}
                  loading={props.loading}
                  page={props.page}
                  productCode={item.product_code}
                  isExtra={isExtra}
                />
                {/* </Fade> */}
              </ProductCardWrapper>
            </ProductsCol>
          ))}
      </ProductsRow>}

      {window.location.pathname.split("/")[1] !== "brand" && props.products.length % 50 === 0 && props.products.length !== 0 &&
        <center>
          <Button
            onClick={() => {const body = { page: props.page + 1}; props.changePage(body); setLoader(false);}}
            type="button"
            disabled={props.loading}
            title={ t('common_show_more_label', false) }
            size='small'
            intlButtonId="proceesCheckout"
            loader={<Loader />}
            isLoading={props.loading || loader}
            id={"showMore"}
            style={{display: props.loading || loader ? "block" : "none", backgroundColor: "#E6E6E6"}}
          />
        </center>
      }
      
      {show && (
        <QuickView
          show={show}
          onHide={onHide}
          props={props}
          deviceType={deviceType}
          onCartClick={() => {
            handleJoin();
          }}
        />
      )}
      <Modal
        show={showform}
        onHide={() => setShowForm(false)}
        dialogClassName="modal-90w authModal"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
      >
        <Modal.Header closeButton style={{ border: 0 }}></Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <AuthenticationForm />
        </Modal.Body>
      </Modal>
      <Modal
        show={isBrandFilter}
        onHide={onClose}
        // dialogClassName="modal-lg"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        animation={true}
        centered
      >
        <Modal.Header closeButton style={{ padding: ".4rem 1rem" }}>
          {/* <Modal.Title>Filters</Modal.Title> */}
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <FilterModalWrapper>
            <div style={{ padding: "8px", display: "flex", flexWrap: "wrap" }}>
              {props.filterBrands &&
                props.filterBrands.map((item, index) => (
                  <BrandButton 
                    key={index}
                    style={
                      isBrandSelected &&
                      isBrandSelected[`${item.value}`] !== undefined
                        ? {
                            backgroundColor: "#f2f2f2",
                            color: "#000",
                            marginBottom: "4px",
                          }
                        : { marginBottom: "4px" }
                    }
                    onClick={() => handleSelection(item)}
                  >
                    <span style={{ whiteSpace: "nowrap" }} className="_36rlri">
                      {" "}
                      {item.label}
                    </span>
                    <span className="_1lz9hno">
                      <span className="_a7a5sx">
                        {isBrandSelected &&
                        isBrandSelected[`${item.value}`] === undefined ? (
                          <svg
                            aria-hidden="true"
                            role="presentation"
                            focusable="false"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              display: "block",
                              fill: "none",
                              height: "12px",
                              width: "12px",
                              stroke: "currentcolor",
                              strokeWidth: "5.33333",
                              overflow: "visible",
                            }}
                          >
                            <path d="m2 16h28m-14-14v28"></path>
                          </svg>
                        ) : (
                          <svg
                            aria-hidden="true"
                            role="presentation"
                            focusable="false"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              display: "block",
                              fill: "none",
                              height: "12px",
                              width: "12px",
                              stroke: "currentcolor",
                              strokeWidth: "5.33333",
                              overflow: "visible",
                            }}
                          >
                            <path fill="none" d="m4 16.5 8 8 16-16"></path>
                          </svg>
                        )}
                      </span>
                    </span>
                  </BrandButton>
                ))}
              {window.screen.width < 700 ? (
                <div className="col-sm-2">
                  <BrandButton
                    style={{
                      marginTop: "15px",
                      marginBottom: "10px",
                      whiteSpace: "nowrap",
                      marginLeft: window.screen.width <= 500 ? "0" : "auto",
                      marginRight: window.screen.width <= 500 ? "auto" : "0",
                      ...(checked
                        ? { background: "#f2f2f2", color: "#000" }
                        : {}),
                    }}
                    onClick={() => toggleCheckbox()}
                  >
                    { window.location.pathname.split("/")[1] !== "search" && window.location.pathname.split("/")[1] !== "brand" && <label>
                    <span style={{marginTop: "5px"}}>{ t('shop_item_show_out_of_stock_option_title') }</span>
                    <Switch className="react-switch" onClick={toggleCheckbox} onChange={toggleCheckbox} checked={!!checked} />
                    </label>}
                  </BrandButton>
                </div>
              ) : null}
            </div>
            <div className="_pwbsgx">
              <div className="_1qbrhv4">
                <button
                  onClick={clearAllBrand}
                  disabled={
                    isBrandSelected && Object.keys(isBrandSelected).length
                      ? false
                      : true
                  }
                  type="button"
                  className="_gby1jkw"
                >
                  { t('shop_item_filter_clear_button') }
                </button>
                <div className="_ls0e43">
                  <button
                    onClick={() => setSelectedBrands()}
                    type="button"
                    className="_ajuxzjo"
                  >
                    { t('shop_item_filter_save_button') }
                  </button>
                </div>
              </div>
            </div>
          </FilterModalWrapper>
        </Modal.Body>
      </Modal>

      <Modal show={showFilterNotFound} onHide={() => setShowFilterNotFound(false)} centered>
        <Modal.Body className="text-center">
          <ErrorModal>
            <p>{ t('common_no_products_found_label') }</p>
            <Button size='sm btn-sm'
                    title={ t('button_close_label') }
                    className="cancelBtn express"
                    variant="success"
                    onClick={() => setShowFilterNotFound(false)}
            />
          </ErrorModal>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Products;
