import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@redq/reuse-modal";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../contexts/auth/auth.context";
import * as Constant from "../../constants/Constant";
import Spinner from 'react-bootstrap/Spinner';
import UploadService from "../../services/FileUploadService";
import {getSuppliers,setError,setLoading} from '../../store/actions/dashboardAction'
import { connect } from "react-redux";
import Alert from 'react-bootstrap/Alert';
import Switch from '@material-ui/core/Switch';


import "react-datetime/css/react-datetime.css";


const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const AddBrand = (props) => {

    const { authDispatch } = useContext(AuthContext);
    let admin = localStorage.getItem("admin");
    let parsedUser = JSON.parse(admin);
    if(!admin) {
      window.location.href = "/";
    }

    const [itemSupplierId, setSupplierId] = useState(0);
    const [file , setFile] = useState(null);
    const [message, setMessage] = useState("");
    const [isUploded, setIsUploded] = useState(false)
    const [update_supplier, setUpdateSupplier] = useState(false)

    const handleUpdateSupplier = e=> setUpdateSupplier(e.target.checked) 

    const handleFileChange = e => {
        console.log(e.target.files[0])
        setFile(e.target.files[0])
    }

    const changeSupplierId = ((event) => {
        setSupplierId(event.target.value)
    });

    useEffect(()=>{
        props.getSuppliers({});
    },[])

    let contentSupplier = null;
    if(props.suppliers) {
        contentSupplier =  props.suppliers.map((supplier,key) => 
        <option key={key} value={supplier.store_user_id}>{supplier.store_users[0].first_name}</option>
        )
    }

    const uploadFile = () =>{
        if(file){          
            let formData = new FormData();
            formData.append("file", file);
            formData.append("supplier_id", itemSupplierId);
            formData.append("update_supplier", update_supplier? 1 : 0);
            UploadService.bulkUploads(formData)
            .then(response => {
                if(response.data.success) {
                   // window.location.href = "/admin/dashboard";
                   setMessage(response.data.data)
                   setIsUploded(true)
            }
          });
        }
      }

   
  return (
    <Modal>
        <HelpPageWrapper>
            <Row>
                <Col sm={11}>
                    <h4>Bulk Upload</h4>
                </Col>
            </Row>
            <Row>
                <Col sm={11}>
                    <Form>
                        <Form.Row>
                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Supplier</Form.Label>
                            <Form.Control as="select" onChange={changeSupplierId} value={itemSupplierId}>
                                    <option value={0}>Select Supplier</option>
                                    {contentSupplier}
                            </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Label>Update Supplier</Form.Label>
                            <span style = {{width : "100Px"}}></span>
                            <Switch
                                checked={update_supplier}
                                onChange={handleUpdateSupplier}
                                name="checkedB"
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Form.Row>
                        <Form.Label>File</Form.Label>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridFile">
                            <Form.Control type="file" accept=".xlsx"  onChange={handleFileChange} />
                            </Form.Group>
                            </Form.Row>
                            { isUploded? (
                            <Alert variant="success">
                                {message}
                            </Alert>
                        ) : ("")}
                            {( !file  || !itemSupplierId ) ? (
                            <Button variant="primary"  disabled>
                            Upload
                            </Button>
                            
                        ) : (
                            <Button variant="primary" onClick={uploadFile}>
                                Upload
                            </Button>
                        )}
                        
                    </Form>
                </Col> 
            </Row>
        </HelpPageWrapper>
    </Modal>
  );
}

const mapStateToProps = state =>{
    // console.log(state);
     return {
      // brands : state.dashboardReducer.brands
      suppliers : state.dashboardReducer.suppliers,
      success : state.dashboardReducer.success,
      loading : state.dashboardReducer.loading,
      error_message : state.dashboardReducer.error_message
     }
    
   }
   
   const mapDispatchToProps = dispatch => {
     return {
        getSuppliers : data => getSuppliers(data,dispatch),
       setError : data => setError(data,dispatch),
       setLoading : data => setLoading(data,dispatch)
     }
   }
   export default connect(mapStateToProps,mapDispatchToProps)(AddBrand)
