import React, {useEffect, useState} from "react";
import { Counter } from "../../../components/Counter/Counter";
import { CURRENCY } from "../../../helper/constant";
import { CloseIcon } from "../../../assets/icons/close";
import { PencilIcon } from "../../../components/AllSvgIcon";
import { Modal } from "react-bootstrap";
import {
  ItemBox,
  Image,
  Information,
  Name,
  Price,
  Weight,
  Total,
  RemoveButton,
  EditButton,
  Input,
  BannerImageContainer,
  Banner,
  Errormsg
} from "./CartItem.style";
import Button from "../../../components/Button/Button";
import { isTodayApplicable } from "../../../constants/Constant";
import t from '../../../i18n/translate';

export const CartItem = ({ data, onDecrement, onIncrement, onRemove, onUpdate, cartType, isLoading }) => {
  const { quantity } = data;
  const displayPrice = data.item_price;
  const totalPrice = quantity * displayPrice;
  const [newquantity, setNewQuantity] = useState(quantity);
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState(false);
  const handleQuantityUpdate = (quant) => {
    setNewQuantity(quant);
  };
  return (
    <ItemBox>
      <Counter
        className={`pr${data.item_id} ${cartType}`}
        value={quantity}
        onDecrement={onDecrement}
        onIncrement={onIncrement}
        onUpdate={(quantity)=>onUpdate(quantity)}
        variant="lightVertical"
        isLoading={isLoading}
      />
      <BannerImageContainer>
        {data.store_item_infos[0].same_day === 1
          ? <Banner className={`bg-success pb-1 px-1 rounded text-white ${cartType}`}>
            { t('shop_item_get_same_day') }
          </Banner>
          : cartType !== 'express'
            ? <Banner className={`bg-warning pb-1 px-1 rounded text-white ${cartType}`}>
              { t('shop_item_get_tomorrow') }
            </Banner>
            : null
        }
        <Image src={data.store_item_infos[0].default_image_url} alt={"product image"} />
      </BannerImageContainer>
      <Information>
        <Name className={cartType}>{data.item_name}</Name>
        <Price className={cartType}>
          {CURRENCY}
          {parseFloat(displayPrice).toFixed(2)}
        </Price>
        <Weight>
          {quantity} X{" "}
          {data.item_price_name}
        </Weight>
      </Information>
      <Total>
        {CURRENCY}
        {parseFloat(quantity * displayPrice).toFixed(2)}
      </Total>
      <EditButton onClick={()=>{setNewQuantity(quantity); setShow(true);}}>
        <PencilIcon cartType={cartType} />
      </EditButton>
      <RemoveButton onClick={()=>{
        let response = window.confirm( t('home_basket_alert_remove_item', false) );
        if (response === true) {
          onRemove();
        }
        }}>
        <CloseIcon />
      </RemoveButton>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setNewQuantity(quantity);
        }}
        dialogClassName="modal-90w authModal"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
      >
        <Modal.Header closeButton>
        <Modal.Title>{ t('home_basket_update_quantity_popup_title') }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input 
           onUpdate={handleQuantityUpdate}
           value={newquantity}/>
           {msg && <Errormsg>{ t('home_basket_error_value_greater_then_zero', false) }</Errormsg>}
        </Modal.Body>
        <Modal.Footer>
        <Button
          title={ t('home_basket_update_quantity_popup_button_save', false) }
          variant="primary"
          onClick={() => {
            if(newquantity > 0){
              onUpdate(newquantity);
              setShow(false);
              setMsg(false);
            }
            else{
              setMsg(true);
            }
          }}
        >
          { t('home_basket_update_quantity_popup_button_save', false) }
        </Button>
        </Modal.Footer>
      </Modal>
    </ItemBox>
  );
};
