import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Modal } from "@redq/reuse-modal";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AuthContext } from "../../contexts/auth/auth.context";
import * as Constant from "../../constants/Constant";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import API from "../../network/dashboardAPI";
import {updateProduct,getBrands,getCategory,getSubCategory,getSuppliers,getSubCategoryMap,setError,setLoading} from '../../store/actions/dashboardAction'
import { connect } from "react-redux";
import UploadService from "../../services/FileUploadService";

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const UpdateProduct = (props) => {

    const { authDispatch } = useContext(AuthContext);
    let admin = localStorage.getItem("admin");
    let parsedUser = JSON.parse(admin);
    if(!admin) {
      window.location.href = "/";
    }
    const pageData = props.match.params;
    // All fields
    const [itemName, setItemName] = useState("");
    const [itemCode, setItemCode] = useState("");
    const [itemCat, setItemCat] = useState("");
    const [itemSubCat, setItemSubCat] = useState("");
    const [itemBrand, setItemBrand] = useState("");
    const [itemSameDay, setItemSameDay] = useState(0);
    const [itemAbout, setItemAbout] = useState();
    const [itemSupplierId, setItemSupplierId] = useState(0);
    const [itemPromo,setItemPromo] = useState(false);
    const [itemPromoPrice, setItemPromoPrice] = useState(0);
    const [itemPromoStartDate, setItemPromoStartDate] = useState("");
    const [itemPromoEndDate, setItemPromoEndDate] = useState("");
    const [itemNewArrival, setItemNewArrival] = useState(false);
    const [itemArrivalLimit, setItemArrivalLimit] = useState("");
    const [itemTrending, setItemTrending] = useState(false);
    const [itemPrice, setItemPrice] = useState(0);
    const [itemMrp, setItemMrp] = useState(0);
    const [itemStoreId, setItemStoreId] = useState(1);
    const [itemPriceName, setItemPriceName] = useState("");
    const [itemAvailable, setItemAvailable] = useState(false);
    const [itemWeight, setItemWeight] = useState(0);
    const [itemQuantity, setItemQuantity] = useState(0);
    const [itemWeightUnit, setItemWeightUnit] = useState("");
    const [itemPriceId, setItemPriceId] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError,setIsError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [image , setImage] = useState("");
    const [file , setFile] = useState(null);
    const [isUploded, setIsUploded] = useState(false);
    const [itemNameKhmer, setItemNameKhmer] = useState("");
    // End All fields
    const [product, setProduct] = useState({
        loading: false,
        data: null,
        error: false
    });

    useEffect(() => {
        setProduct({
            loading: true,
            data: null,
            error: false
        })
        API.getProductById({item_id: pageData.id})
        .then((response) => response.json())
        .then(response => {
            setProduct({
                loading: false,
                data: response.data,
                error: false
            })
            if (response.data) {
               
                setImage(response.data.default_image_url)
                setItemName(response.data.name);
                setItemCode(response.data.product_code);
                setItemCat(response.data.cat_id);
                setItemSubCat(response.data.sub_cat_id);
                setItemBrand(response.data.brand_id);
                setItemAbout(response.data.about_item);
                if(response.data && response.data.name_km){
                    setItemNameKhmer(response.data.name_km);
                }

                if(response.data.new_arrival){
                   
                    setItemNewArrival(1);
                    setItemArrivalLimit(response.data.arrival_limit);
                } else {
                   
                    setItemNewArrival(0);
                    setItemArrivalLimit(null);
                }
                if(response.data.promo){
                    setItemPromo(1);
                    setItemPromoPrice(response.data.promo_price);
                    setItemPromoStartDate(response.data.promo_start_date);
                    setItemPromoEndDate(response.data.promo_end_date);
                } else {
                    setItemPromo(0);
                    setItemPromoPrice(null);
                    setItemPromoStartDate(null);
                    setItemPromoEndDate(null);
                }
                if(response.data.trending){
                    setItemTrending(1);
                } else {
                    setItemTrending(0);
                }
                setItemSameDay(response.data.same_day);
                setItemArrivalLimit(response.data.arrival_limit);
                setItemSupplierId(response.data.supplier_id);
                setItemPriceId(response.data.store_items_price_types[0].id)
                setItemPriceName(response.data.store_items_price_types[0].name);
                setItemAvailable(response.data.store_items_price_types[0].is_available);
                setItemPrice(response.data.store_items_price_types[0].price);
                setItemMrp(response.data.store_items_price_types[0].mrp);
                setItemQuantity(response.data.store_items_price_types[0].available_quantity);
            }
            props.getSubCategoryMap({cat_id: response.data.cat_id})
        }).catch(() => {
            setProduct({
                loading: false,
                data: null,
                error: true
            })
        });
     //  props.getSubCategoryMap({cat_id: itemCat})
        props.getCategory({
            store_id: 1,
            get_type: 1
        })
        props.getBrands({});
        props.getSuppliers({})


    },[]);
    let content = null;
    let contentSupplier = null;
    let contentBrands = null;
    let contentCategory = null;
    let contentSubCategory = null;
    if(product.error) {
        content = <p>Some error in response.</p>;
    }
    if (product.loading) {
        content = <p>Loading...</p>
    }
    if(props.brands) {
        contentBrands =  props.brands.map((brand,key) => 
        <option key={key} value={brand.brand_id} selected={(itemBrand == brand.brand_id  ? true : false)} >{brand.name}</option>
        )
    }
    if(props.category) {
        contentCategory =  props.category.map((cat,key) => 
        <option key={key} value={cat.cat_id} selected={(itemCat == cat.cat_id  ? true : false)} >{cat.list_categories[0].name}</option>
        )
    }
    if(props.sub_category) {
        contentSubCategory =  props.sub_category.map((subCat,key) => 
        <option key={key} value={subCat.sub_cat_id} selected={(itemSubCat == subCat.sub_cat_id  ? true : false) }>{subCat.list_sub_categories[0].name}</option>
        )
    }
    if(props.suppliers) {
        contentSupplier =  props.suppliers.map((supplier,key) => 
        <option key={key} value={supplier.store_user_id} selected={(itemSupplierId == supplier.store_user_id ? true : false) }>{supplier.store_users[0].first_name}</option>
        )
    }


    // change handlers

    const handleFileChange = e => {
      //  console.log(e.target.files[0])
        setFile(e.target.files[0])
    }
    const changeName = ((event) => {
         setItemName(event.target.value)
    });
    const changeCode = ((event) => {
        setItemCode(event.target.value)
    });
    const changeAbout = ((event) => {
        setItemAbout(event.target.value)
    });
    const cahngeCategory = ((event) => {
        setItemCat(event.target.value);
        props.getSubCategoryMap({cat_id: event.target.value})
    });
    const changeSubCategory = ((event) => {
        setItemSubCat(event.target.value)
    });
    const cahngeBrand = ((event) => {
        setItemBrand(event.target.value)
    });
    const changePrice = ((event) => {
        setItemPrice(event.target.value)
    });
    const changeMrp = ((event) => {
        setItemMrp(event.target.value)
    });
    const changeItemQuantity = ((event) => {
        setItemQuantity(event.target.value)
    });
    const changePriceName = ((event) => {
        setItemPriceName(event.target.value)
    });
    const changeSameDay = ((event) =>{
        setItemSameDay(event.target.checked)
    });
    const changeAvailable = ((event) => {
        setItemAvailable(event.target.checked)
    });
    const changeNewArrival = ((event) => {
        if(!event.target.checked) {
            setItemNewArrival(0)
            setItemArrivalLimit(null)
        } else {
            
            setItemNewArrival(1)
            setItemArrivalLimit("")
        }
    });
    const changeArrivalLimit = ((event) => {
        setItemArrivalLimit(moment(event).format('YYYY-MM-DD hh:mm:ss'))
    });
    const changeTrending = ((event) => {
        setItemTrending(event.target.checked)
    });
    const changePromo = ((event) => {
        if(!event.target.checked) {
            setItemPromo(0)
            setItemPromoPrice(null)
            setItemPromoStartDate(null)
            setItemPromoEndDate(null)
        } else {
            setItemPromo(1)
            setItemPromoPrice("")
            setItemPromoStartDate("")
            setItemPromoEndDate("")
        }
    });
    const changePromoPrice = ((event) => {
        setItemPromoPrice(event.target.value)
    });
    const changePromoStartDate = ((event) => {
        setItemPromoStartDate(moment(event).format('YYYY-MM-DD hh:mm:ss'))
    });
    const changePromoEndDate = ((event) => {
        setItemPromoEndDate(moment(event).format('YYYY-MM-DD hh:mm:ss'))
    });
    const changeSupplier = ((event) => {
        setItemSupplierId(event.target.value);
    });
    const updateProductDate = () => {
       // setIsSubmitted(true);
        let postData = {
            image : [{
                image_url : image
            }],
            item_id: pageData.id,
            name: itemName,
            cat_id: itemCat,
            sub_cat_id: itemSubCat,
            brand_id: itemBrand,
            about_item: itemAbout,
            prices:[{
                price_id: itemPriceId,
                store_id: 1,
                item_id: pageData.id,
                name: itemPriceName,
                price: itemPrice,
                mrp: itemMrp,
                is_available: itemAvailable,
                available_quantity: itemQuantity,
                weight_unit: 0,
            }],
            max_quantity_to_be_added: 1000000,
            product_code: itemCode,
            same_day: itemSameDay,
            supplier_id: itemSupplierId,
            promo: itemPromo,
            promo_price: itemPromoPrice,
            promo_start_date: itemPromoStartDate,
            promo_end_date: itemPromoEndDate,
            new_arrival: itemNewArrival,
            arrival_limit: itemArrivalLimit,
            trending: itemTrending,
            name_km: itemNameKhmer
        };
        props.updateProduct(postData)

    }
    
    const uploadProductImage = () =>{
        if(file){
            setIsUploded(true)
            let formData = new FormData();
            formData.append("image", file);
            UploadService.uploadImageFromDashboard(formData)
            .then(response => {
            if(response.data.success) {
                setIsUploded(false)
               // console.log(response.data.data[0].url)
               setImage(response.data.data[0].url)
            }
          }); 
        }      
      }
   
  return (
    <Modal>
        <HelpPageWrapper>
            <Row>
                <Col sm={11}>
                    <h4>Product update</h4>
                </Col>
            </Row>
            <Row>
                <Col sm={11}>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" value={itemName} onChange={changeName} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>SKU</Form.Label>
                            <Form.Control type="text" placeholder="SKU/Product code" value={itemCode} onChange={changeCode} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridNameKhmer">
                              <Form.Label>Name in Khmer</Form.Label>
                              <Form.Control type="text" placeholder="Name in khmer" value={itemNameKhmer} onChange={(e) => setItemNameKhmer(e.target.value)} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>About Item</Form.Label>
                            <Form.Control as="textarea" rows={3} value={itemAbout} onChange={changeAbout} />
                        </Form.Group>

                        <Form.Label>Image</Form.Label>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridFile">
                            <Form.Control type="file"  onChange={handleFileChange} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                            {isUploded ? (
                            <Spinner animation="grow" variant="success" />
                              ) :
                            <Button variant="primary" onClick={uploadProductImage}>
                                Update image
                            </Button>}
                            </Form.Group>
                        </Form.Row>


                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Category</Form.Label>
                                <Form.Control as="select" defaultValue={itemCat} onChange={cahngeCategory}>
                                    <option value={0}>Choose Category</option>
                                    {contentCategory}
                                </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Sub Category</Form.Label>
                                <Form.Control as="select" defaultValue={itemSubCat} onChange={changeSubCategory}>
                                    <option value={0}>Choose Sub Category</option>
                                    {contentSubCategory}
                                </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Brand</Form.Label>
                                <Form.Control as="select" defaultValue={itemBrand} onChange={cahngeBrand}>
                                    <option value={0}>Choose Brand</option>
                                    {contentBrands}
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>

                        <hr />

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Varient name</Form.Label>
                            <Form.Control type="text" placeholder="Varient name" value={itemPriceName} onChange={changePriceName} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Available Quantity</Form.Label>
                            <Form.Control type="number" placeholder="0" value={itemQuantity} onChange={changeItemQuantity} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Supplier</Form.Label>
                            <Form.Control as="select" onChange={changeSupplier} defaultValue={itemSupplierId}>
                                    <option value={0}>Select Supplier</option>
                                    {contentSupplier}
                            </Form.Control>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Price (Selling price - USD)</Form.Label>
                            <Form.Control type="text" placeholder="00.00 USD" value={itemPrice} onChange={changePrice} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>COST (USD)</Form.Label>
                            <Form.Control type="text" placeholder="00.00 USD" value={itemMrp} onChange={changeMrp} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Add to New Arrival" checked={itemNewArrival} onChange={changeNewArrival} />
                        </Form.Group>
                        {itemNewArrival ? (
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Till</Form.Label>
                                <Datetime value={moment(itemArrivalLimit).format('YYYY-MM-DD hh:mm:ss')} dateFormat="YYYY-MM-DD" timeFormat="hh:mm:ss" onChange={changeArrivalLimit}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail"></Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail"></Form.Group>
                            </Form.Row>
                        ): ("")}

                        <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Add to promotion" checked={itemPromo} onChange={changePromo} />
                        </Form.Group>
                        {itemPromo ? (
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Promo selling price </Form.Label>
                                <Form.Control type="text" placeholder="00.00 USD" value={itemPromoPrice} onChange={changePromoPrice} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>From</Form.Label>
                                <Datetime value={ moment(itemPromoStartDate).format('YYYY-MM-DD hh:mm:ss')} dateFormat="YYYY-MM-DD" timeFormat="hh:mm:ss" onChange={changePromoStartDate}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>To</Form.Label>
                                <Datetime value={ moment(itemPromoEndDate).format('YYYY-MM-DD hh:mm:ss') } dateFormat="YYYY-MM-DD" timeFormat="hh:mm:ss" onChange={changePromoEndDate}/>
                                </Form.Group>
                            </Form.Row>
                            
                        ): ("")}

                        <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Same day" checked={itemSameDay} onChange={changeSameDay} />
                        </Form.Group>

                        <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Available" checked={itemAvailable} onChange={changeAvailable} />
                        </Form.Group>

                        <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Add to Trending" checked={itemTrending} onChange={changeTrending} />
                        </Form.Group>

                        { !props.success ? (
                            <Alert variant="danger">
                                {props.error_message}
                            </Alert>
                        ) : ("")}
                            {(props.loading || isUploded) ? (
                            <Spinner animation="grow" variant="success" />
                        ) : (
                            <Button variant="primary" onClick={updateProductDate}>
                                Update
                            </Button>
                        )}
                        
                    </Form>
                </Col>
            </Row>
        </HelpPageWrapper>
    </Modal>
  );
}

const mapStateToProps = state =>{
    return {
       brands : state.dashboardReducer.brands,
       category : state.dashboardReducer.category,
       sub_category : state.dashboardReducer.sub_category,
       suppliers : state.dashboardReducer.suppliers,
       success : state.dashboardReducer.success,
       loading : state.dashboardReducer.loading,
       error_message : state.dashboardReducer.error_message
    }
   
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      updateProduct : data => updateProduct(data,dispatch),
     // searchProduct : data => searchProduct(data,dispatch),
      getBrands : data => getBrands(data,dispatch),
      getCategory : data => getCategory(data,dispatch),
      getSubCategory : data => getSubCategory(data,dispatch),
      getSuppliers : data => getSuppliers(data,dispatch),
      getSubCategoryMap : data => getSubCategoryMap(data,dispatch),
      setError : data => setError(data,dispatch),
       setLoading : data => setLoading(data,dispatch)
    }
  }
  export default connect(mapStateToProps,mapDispatchToProps)(UpdateProduct)
