import React, { useEffect } from "react";
import Order from "../containers/Profile/Order/Order";
import {
  PageWrapper,
  SidebarSection,
} from "../containers/Profile/Profile.style";
import { Modal } from "@redq/reuse-modal";
import { useDeviceType } from "../helper/useDeviceType";
import { connect } from "react-redux";
import { pastOrders, fetchParticularOrder } from "../store/actions/userAction";
const OrderPage = (props, { userAgent }) => {
  const deviceType = useDeviceType(userAgent);
  return (
    <>
      <Modal>
        <PageWrapper>
          <Order
            deviceType={deviceType}
            props={props}
            orders={props.past_orders}
            orderDetails={props.fetch_particular_order}
          />
        </PageWrapper>
      </Modal>
    </>
  );
};
OrderPage.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};
const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    modalData: state.productReducer.modalData,
    user_address: state.userReducer.address,
    past_orders: state.userReducer.past_orders,
    fetch_particular_order: state.userReducer.fetch_particular_order,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pastOrders: (data) => pastOrders(data, dispatch),
    fetchParticularOrder: (data) => fetchParticularOrder(data, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
