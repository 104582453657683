import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import useOnClickOutside from "./useOnClickOutside.js";
import PopoverWrapper from "./Popover.style";
import {CloseIcon} from "../../assets/icons/close";
import arrowBack from '../../assets/icons/fi-back.svg';
import deliShopLogo from '../../assets/icons/delishop_logo.svg';
import MenuIcon from '@material-ui/icons/Menu';
import t from '../../i18n/translate';

const PopOver = ({ className, handler, content, direction }) => {
  // Popover State
  let cN = className;
  let initialValue = false;
  if(!window.location.pathname.includes('/express')) {
    initialValue = (window.innerWidth < 420 || window.location.pathname !== "/") && cN !== "user-pages-dropdown" && window.location.pathname.indexOf("brands") === -1 && window.location.pathname.indexOf("brand") === -1 && window.location.pathname.indexOf("search") === -1 ? true : false;
  }
  const [openstate, setOpenState] = useState(initialValue);
  const category = useSelector(state => state.productReducer.category);
  const cartType = useSelector(state => state.cartReducer.cartType);

  // Ref
  const ref = useRef();

  // Add all classs to an array
  const addAllClasses = ["popover-wrapper"];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  // Add direction class on popover content
  if (direction) {
    addAllClasses.push(direction);
  }

  // Toggle Popover content
  const handleToggle = (e) => {
    e.stopPropagation();
    if (window.innerWidth < 420) {
      setOpenState((openstate) => true);
    } else {
      setOpenState((openstate) => !openstate);
    }
  };

  // Handle document click
  const handleDocumentClick = (e) => {
    e.stopPropagation();
    if (openstate) {
      handleToggle(e);
    }
  };

  // Handle window event listener
  useEffect(() => {
    window.addEventListener("click", handleDocumentClick);
    return () => {
      window.removeEventListener("click", handleDocumentClick);
    };
  });

  // Close popover on click outside
  useOnClickOutside(ref, () => setOpenState((popstate) => false));

  const handleBackToNormal = () => {
    localStorage.removeItem('expressMode');
    window.location = '/';
  }

  return (
    <PopoverWrapper className={addAllClasses.join(" ")} ref={ref}>
      {direction === 'left'
        ? <div className={className==="left" ? "left-category popover-handler" : "popover-handler"} onClick={handleToggle}>
          <div className={`burger-menu ${cartType}`}>
            <MenuIcon />
            <span className="menu-link">{ t('common_header_button_menu') }</span>
          </div>
        </div>
        : <div className="popover-handler" onClick={handleToggle}>
          {handler}
        </div>
      }
      {window.location.pathname.includes('/express/store') && direction === 'left'
        ? null
        : openstate && window.location.pathname !== "/register" && (
        <div className={`popover-content ${cartType} ${category.length ? '' : 'no-pd'}`}>
          {className === 'category' && cartType === 'express' && window.location.pathname === '/express'
            ? <div className="flex-box">
              <div className="link-back-to-normal-shop" onClick={() => handleBackToNormal()}>
                <img src={arrowBack} alt="Delishop Oneline Market arrow back" width="100%" height="28" />
                <div className="logo">
                  <span>{ t('forget_password_button_back_to') }</span>
                  <img src={deliShopLogo} alt="Delishop Oneline Market" width="100%" height="44" />
                </div>
              </div>
            </div>
            : null
          }
          {content
            ? <div className="inner-wrap" onClick={handleToggle}>
              {content}
            </div>
            : null
          }
        </div>
      )
      }
    </PopoverWrapper>
  );
};

export default PopOver;
