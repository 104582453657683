import React from 'react';
import { useSelector } from "react-redux";
import PopOver from '../../../../../components/Popover/Popover';
import { AuthorizedMenu } from '../AuthorizedMenu';
import { RightMenuBox, AccountContainer, RegisterContainer, CartContainer, CartIcon, CartLabel, BadgeContainer, LabelContainer } from './RightMenu.style';
import {Account} from "../../../../../assets/icons/Account";
import {Basket} from "../../../../../assets/icons/Basket";
import {useCart} from "../../../../../contexts/cart/use-cart";
import t from '../../../../../i18n/translate';

export const RightMenu = ({
  onLogout,
  avatar,
  isAuthenticated,
  onJoin
}) => {
  const {
    cartItemsCount,
    calculatePrice,
  } = useCart();

  const cartType = useSelector( state => state.cartReducer.cartType );

  const handleCart = () => {
    document.getElementsByClassName("product-cart")[0].click();
    document.getElementsByClassName("product-cart")[0].style.visibility = "visible";
  }

  return (
    <RightMenuBox>
      {!isAuthenticated ? (
        <RegisterContainer id={"joinBTn"} onClick={onJoin}>
          <Account /><div style={{fontSize: "15px"}}>{ t('login_title') }</div>
        </RegisterContainer>
      ) : (
        <PopOver
          direction="right"
          className="user-pages-dropdown"
          handler={
           <AccountContainer>
            <Account /><div style={{fontSize: "15px"}}>{ t('home_menu_account') }</div>
           </AccountContainer>
          }
          content={<AuthorizedMenu onLogout={onLogout} />}
        />
      )}
      {isAuthenticated && document.getElementsByClassName("product-cart").length > 0 &&
        <CartContainer className="cart-container" onClick={handleCart}>
          <CartIcon>
              <Basket cartType={cartType} />
              <BadgeContainer className={cartType}>{cartItemsCount}</BadgeContainer>
          </CartIcon>
          <CartLabel>
              <LabelContainer className={cartType}>${calculatePrice()}</LabelContainer>
          </CartLabel>
        </CartContainer>}
    </RightMenuBox>
  );
};
