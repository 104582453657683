import {
  HOME_PAGE,
  GROCERY_PAGE,
  PRODUCT_INFO,
  ALL_ORDERS_PAGE,
  CONTACT_INFO,
  HELP_PAGE,
  PROCEED_TO_CHECKOUT_PAGE,
  PROFILE_PAGE,
  TERMS_INFO,
  PAYMENTS_INFO,
  DELIVERY_PAGE,
  ABOUT_INFO,
  ALL_BRANDS_PAGE
} from "../../constants/navigation";
import { addAddress } from "../../store/actions/userAction";
const arr = [
  // HOME_PAGE,
  // GROCERY_PAGE,
  // PRODUCT_INFO,
  ALL_ORDERS_PAGE,
  CONTACT_INFO,
  HELP_PAGE,
  PROCEED_TO_CHECKOUT_PAGE,
  PROFILE_PAGE,
  TERMS_INFO,
  PAYMENTS_INFO,
  DELIVERY_PAGE,
  ABOUT_INFO
];
export function isCategoryPage(pathname) {
  return !arr.includes(pathname);
}

export function isCommonPage(pathname) {
  return arr.includes(pathname);
}
