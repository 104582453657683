import * as actionTypes from "../ActionTypes";
import API from "../../network/redAPI";
import { BAGS_PAGE } from "../../constants/navigation";

import { getValue } from "../../helper/constant";

export const productCategory = (data, dispatch) => {
  API.Category(data)
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Product_Category,
          payload: data.data,
          refs: data.data.map((element, index) => {
            return element.cat_ref_id
          }),
          success: data.success,
        });
      } else {
        // alert(data.error)
      }
    });
};

export const ResetModalData = (data, dispatch) => {
  dispatch({
    type: actionTypes.ProductModal,
    payload: data.data,
    success: data.success,
  });
};

export const ProductModalData = (data, dispatch) => {
  API.GetProductByRefId(data)
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success && data.data !== null) {
        dispatch({
          type: actionTypes.ProductModal,
          payload: data.data,
          success: data.success,
        });
      } else {
        dispatch({
          type: actionTypes.Product_Fetch,
          payload: false
        });
      }
    });
};

const getFilterBrands = (data) => {
  let brandArr = [];
  const getIdAndName = (value) => {
    if (
      value.brand_id &&
      value.name
    ) {
      return { value: value.brand_id, label: value.name };
    }
    return null;
  };
  data &&
    data.length &&
    data.forEach((item, index) => {
      if (!brandArr.length) {
        let data = getIdAndName(item);
        if (data) {
          brandArr.push(data);
        }
      } else {
        const duplicate = brandArr.find(
          (brand) => brand.value === item.brand_id
        );
        if (!duplicate) {
          let data = getIdAndName(item);
          if (data) {
            brandArr.push(data);
          }
        }
      }
    });
  return brandArr;
};

export const getProductsMappedwithCat = (body, dispatch) => {
  localStorage.setItem("mode","cat");
  dispatch({
    type: actionTypes.Loading_Enable,
  });
  let cid = 0;
  API.GetProductsMappedwithCat(body)
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        const pi = data.data.map((item, index) => {
          cid = item.cat_id;
          return {
            name: item.name,
            product_id: item.item_id,
            sku: item.product_code,
            price: item.store_items_price_types[0].price,
            category: item.cat_id,
            position: index + 1,
          };
        });
        // Products Searched
        window.analytics && window.analytics.track("Products Searched", {
          query:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
        });
        // Product List Viewed
        window.analytics && window.analytics.track("Product List Viewed", {
          list_id: window.location.pathname === "/" ? 18 : cid,
          category:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
          products: pi,
        });
        dispatch({
          type: actionTypes.Filter_Brands,
          payload: getFilterBrands(data.brands),
        });
        dispatch({
          type: actionTypes.Products_Mappedwith_Cat,
          payload: data.data,
          success: data.success,
        });
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      }
    });
};

export const getProductOutOfStockCat = (body, dispatch) => {
  localStorage.setItem("mode","catoos");
  body.oos = 1;
  dispatch({
    type: actionTypes.Loading_Enable,
  });
  API.GetAllProductMappedCatOutofStock(body)
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        let cid = 0;
        //searchedproducts
        const pi = data.data.map((item, index) => {
          cid = item.cat_id;
          return {
            name: item.name,
            product_id: item.item_id,
            sku: item.product_code,
            price: item.store_items_price_types[0].price,
            category: item.cat_id,
            position: index + 1,
          };
        });
        // Products Searched
        window.analytics && window.analytics.track("Products Searched", {
          query:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
        });
        // Product List Viewed
        window.analytics && window.analytics.track("Product List Viewed", {
          list_id: window.location.pathname === "/" ? 18 : cid,
          category:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
          products: pi,
        });
        dispatch({
          type: actionTypes.Filter_Brands,
          payload: getFilterBrands(data.brands),
        });
        dispatch({
          type: actionTypes.Products_Mappedwith_Cat_OutOfStock,
          payload: data.data,
          success: data.success,
        });
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      }
    });
};

export const getProductsMappedwithSubCat = (body, dispatch) => {
  localStorage.setItem("mode","subcat");
  if(body.sub_cat_id){
  dispatch({
    type: actionTypes.Loading_Enable,
  });
  let cid = 0;
  API.GetProductsMappedwithSubCat(body)
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        const pi = data.data.map((item, index) => {
          cid = item.cat_id;
          return {
            name: item.name,
            product_id: item.item_id,
            sku: item.product_code,
            price: item.store_items_price_types[0].price,
            category: item.cat_id,
            position: index + 1,
          };
        });
        // Products Searched
        window.analytics && window.analytics.track("Products Searched", {
          query:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
        });
        // Product List Viewed
        window.analytics && window.analytics.track("Product List Viewed", {
          list_id: window.location.pathname === "/" ? 18 : cid,
          category:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
          products: pi,
        });

        dispatch({
          type: actionTypes.Filter_Brands,
          payload: getFilterBrands(data.brands),
        });
        dispatch({
          type: actionTypes.Products_Mappedwith_SubCat,
          payload: data.data,
          success: data.success,
        });
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      }
    });
  }
  else{
    //
  }
};

export const searchSuggestions = (body, dispatch) => {
  API.SearchStoreProductWithCategory(body)
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Suggested_SubCat,
          payload: data.data,
          success: data.success,
        });
      }
    });
};

export const searchStoreProduct = (body, dispatch) => {
  body.oos = 1;
  localStorage.setItem("mode","search");
  dispatch({
    type: actionTypes.Loading_Enable,
  });

  API.SearchStoreProductBySubCategory(body)
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        let cid = 0;
        window.analytics && window.analytics.track("Products Searched", {
          query:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[2],
        });
        dispatch({
          type: actionTypes.Filter_Brands,
          payload: getFilterBrands(data.brands),
        });
        dispatch({
          type: actionTypes.Search_Store_Product,
          payload: data.data,
          success: data.success,
        });
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      }
    });
};

export const getProductOutOfStock = (body, dispatch) => {
  localStorage.setItem("mode","subcatoos");
  // body.oos = 1;
  if(body.sub_cat_id){
  dispatch({
    type: actionTypes.Loading_Enable,
  });
  API.GetAllProductMappedSubCatOutofStock(body)
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        let cid = 0;
        //searchedproducts
        const pi = data.data.map((item, index) => {
          cid = item.cat_id;
          return {
            name: item.name,
            product_id: item.item_id,
            sku: item.product_code,
            price: item.store_items_price_types[0].price,
            category: item.cat_id,
            position: index + 1,
          };
        });
        // Products Searched
        window.analytics && window.analytics.track("Products Searched", {
          query:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
        });
        // Product List Viewed
        window.analytics && window.analytics.track("Product List Viewed", {
          list_id: window.location.pathname === "/" ? 18 : cid,
          category:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
          products: pi,
        });
        dispatch({
          type: actionTypes.Filter_Brands,
          payload: getFilterBrands(data.brands),
        });
        dispatch({
          type: actionTypes.Out_Of_Stock,
          payload: data.data,
          success: data.success,
        });
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      }
    });
  }
  else{
    //
  }
};

export const SearchStoreProductByBrandRef = (body,dispatch) => {
  localStorage.setItem("mode","brand");
  dispatch({
    type: actionTypes.Loading_Enable,
  });
  API.searchStoreProductByBrandRef(body)
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Brand_Products,
          payload: data.data,
          success: data.success,
        });
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      } else {
        dispatch({
          type: actionTypes.Loading_Disable,
        });
      }
    });
};

export const getProductBrands = (dispatch) => {
  API.GetProductBrands()
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        dispatch({
          type: actionTypes.Product_Brands,
          payload: data.data,
          success: data.success,
        });
      } else {
        // alert(data.error);
      }
    });
};

export const getProductFilterBrands = (
  body,
  products,
  allproducts,
  sub_cat_id,
  cat_id,
  oos,
  text,
  dispatch
) => {
  if (body) {
    if (body.length > 0) {
      let b = body.map((el) => {
        return el.value;
      });
      body.brand_id = b;
      dispatch({
        type: actionTypes.Loading_Enable,
      });
      if(!oos){
        if(window.location.pathname.split("/")[1] === "search"){
          API.SearchStoreProductBySubCategory({
            sub_cat_id: sub_cat_id === "-1" ? "0" : sub_cat_id,
            search_query: text,
            store_id: 1,
            page: 1,
            brand_id: b
          })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              let cid = 0;
              // Products Searched
              window.analytics && window.analytics.track("Products Searched", {
                query:
                  window.location.pathname === "/"
                    ? "best-deals"
                    : window.location.pathname.split("/")[2],
              });
              dispatch({
                type: actionTypes.Filter_Brands,
                payload: getFilterBrands(data.brands),
              });
              dispatch({
                type: actionTypes.Search_Store_Product,
                payload: data.data,
                success: data.success,
              });
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            } else {
              // alert(data.error);
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            }
          });
        }
        else if(sub_cat_id === "0" || sub_cat_id === "-1"){
          localStorage.setItem("mode","catoos");
          API.GetAllProductMappedCatOutofStock({ cat_id: cat_id, store_id: 1, page: 1, oos: 0, brand_id: b})
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              dispatch({
                type: actionTypes.Filter_Brands,
                payload: getFilterBrands(data.brands),
              });
              dispatch({
                type: actionTypes.Products_Mappedwith_Cat_OutOfStock,
                payload: data.data,
                success: data.success,
              });
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            } else {
              // alert(data.error);
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            }
          });
        }
        else{
          localStorage.setItem("mode","subcatoos");
          API.GetAllProductMappedSubCatOutofStock({ sub_cat_id: sub_cat_id, store_id: 1, page: 1, oos: 0, brand_id: b })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              dispatch({
                type: actionTypes.Filter_Brands,
                payload: getFilterBrands(data.brands),
              });
              dispatch({
                type: actionTypes.Out_Of_Stock,
                payload: data.data,
                success: data.success,
              });
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            } else {
              // alert(data.error);
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            }
          });
        }
      }
      else{
        if(window.location.pathname.split("/")[1] === "search"){
          API.SearchStoreProductBySubCategory({
            sub_cat_id: sub_cat_id === "-1" ? "0" : sub_cat_id,
            search_query: text,
            store_id: 1,
            page: 1,
            brand_id: b
          })
          .then((response) => response.json())
          .then(async (data) => {
            console.log("data response is ", data.data);
            if (data.success) {
              let cid = 0;
              //searchedproducts
              // const pi = data.data.map((item, index) => {
              //   cid = item.cat_id;
              //   return {
              //     name: item.name,
              //     product_id: item.item_id,
              //     sku: item.product_code,
              //     price: item.store_items_price_types[0].price,
              //     category: item.cat_id,
              //     position: index + 1,
              //   };
              // });
              // Products Searched
              window.analytics && window.analytics.track("Products Searched", {
                query:
                  window.location.pathname === "/"
                    ? "best-deals"
                    : window.location.pathname.split("/")[2],
              });
              // Product List Viewed
              // window.analytics.track("Product List Viewed", {
              //   list_id: window.location.pathname === "/" ? 18 : cid,
              //   category:
              //     window.location.pathname === "/"
              //       ? "best-deals"
              //       : window.location.pathname.split("/")[2],
              //   products: pi,
              // });
              dispatch({
                type: actionTypes.Filter_Brands,
                payload: getFilterBrands(data.brands),
              });
              dispatch({
                type: actionTypes.Search_Store_Product,
                payload: data.data,
                success: data.success,
              });
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            } else {
              // alert(data.error);
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            }
          });
        }
        else if(sub_cat_id === "0" || sub_cat_id === "-1"){
          localStorage.setItem("mode","cat");
          API.GetProductsMappedwithCat({ cat_id: cat_id, store_id: 1, page: 1, brand_id: b })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              dispatch({
                type: actionTypes.Filter_Brands,
                payload: getFilterBrands(data.brands),
              });
              dispatch({
                type: actionTypes.Products_Mappedwith_Cat,
                payload: data.data,
                success: data.success,
              });
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            } else {
              // alert(data.error);
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            }
          });
        }
        else{
          localStorage.setItem("mode","subcat");
          API.GetProductsMappedwithSubCat({ sub_cat_id: sub_cat_id, store_id: 1, page: 1, brand_id: b })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              dispatch({
                type: actionTypes.Products_Mappedwith_SubCat,
                payload: data.data,
              });
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            } else {
              // alert(data.error);
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            }
          });
        }
      }
    } else if (body.length === 0) {
      dispatch({
        type: actionTypes.Loading_Enable,
      });
      if(!oos){
        if(window.location.pathname.split("/")[1] === "search"){
          API.SearchStoreProductBySubCategory({
            sub_cat_id: sub_cat_id === "-1" ? "0" : sub_cat_id,
            search_query: text,
            store_id: 1,
            page: 1,
            brand_id: 0
          })
          .then((response) => response.json())
          .then(async (data) => {
            console.log("data response is ", data.data);
            if (data.success) {
              let cid = 0;
              //searchedproducts
              // const pi = data.data.map((item, index) => {
              //   cid = item.cat_id;
              //   return {
              //     name: item.name,
              //     product_id: item.item_id,
              //     sku: item.product_code,
              //     price: item.store_items_price_types[0].price,
              //     category: item.cat_id,
              //     position: index + 1,
              //   };
              // });
              // Products Searched
              window.analytics && window.analytics.track("Products Searched", {
                query:
                  window.location.pathname === "/"
                    ? "best-deals"
                    : window.location.pathname.split("/")[2],
              });
              // Product List Viewed
              // window.analytics.track("Product List Viewed", {
              //   list_id: window.location.pathname === "/" ? 18 : cid,
              //   category:
              //     window.location.pathname === "/"
              //       ? "best-deals"
              //       : window.location.pathname.split("/")[2],
              //   products: pi,
              // });
              dispatch({
                type: actionTypes.Filter_Brands,
                payload: getFilterBrands(data.brands),
              });
              dispatch({
                type: actionTypes.Search_Store_Product,
                payload: data.data,
                success: data.success,
              });
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            } else {
              // alert(data.error);
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            }
          });
        }
        else if(sub_cat_id === "0" || sub_cat_id === "-1"){
          localStorage.setItem("mode","catoos");
          API.GetAllProductMappedCatOutofStock({ cat_id: cat_id, store_id: 1, page: 1, oos: 1, brand_id: 0 })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              dispatch({
                type: actionTypes.Filter_Brands,
                payload: getFilterBrands(data.brands),
              });
              dispatch({
                type: actionTypes.Products_Mappedwith_Cat_OutOfStock,
                payload: data.data,
                success: data.success,
              });
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            } else {
              // alert(data.error);
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            }
          });
        }
        else{
          localStorage.setItem("mode","subcatoos");
          API.GetAllProductMappedSubCatOutofStock({ sub_cat_id: sub_cat_id, store_id: 1, page: 1, oos: 1, brand_id: 0 })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              dispatch({
                type: actionTypes.Filter_Brands,
                payload: getFilterBrands(data.brands),
              });
              dispatch({
                type: actionTypes.Out_Of_Stock,
                payload: data.data,
                success: data.success,
              });
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            } else {
              // alert(data.error);
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            }
          });
        }
      }
      else{
        if(window.location.pathname.split("/")[1] === "search"){
          API.SearchStoreProductBySubCategory({
            sub_cat_id: sub_cat_id === "-1" ? "0" : sub_cat_id,
            search_query: text,
            store_id: 1,
            page: 1,
            brand_id: 0
          })
          .then((response) => response.json())
          .then(async (data) => {
            console.log("data response is ", data.data);
            if (data.success) {
              let cid = 0;
              //searchedproducts
              // const pi = data.data.map((item, index) => {
              //   cid = item.cat_id;
              //   return {
              //     name: item.name,
              //     product_id: item.item_id,
              //     sku: item.product_code,
              //     price: item.store_items_price_types[0].price,
              //     category: item.cat_id,
              //     position: index + 1,
              //   };
              // });
              // Products Searched
              window.analytics && window.analytics.track("Products Searched", {
                query:
                  window.location.pathname === "/"
                    ? "best-deals"
                    : window.location.pathname.split("/")[2],
              });
              // Product List Viewed
              // window.analytics.track("Product List Viewed", {
              //   list_id: window.location.pathname === "/" ? 18 : cid,
              //   category:
              //     window.location.pathname === "/"
              //       ? "best-deals"
              //       : window.location.pathname.split("/")[2],
              //   products: pi,
              // });
              dispatch({
                type: actionTypes.Filter_Brands,
                payload: getFilterBrands(data.brands),
              });
              dispatch({
                type: actionTypes.Search_Store_Product,
                payload: data.data,
                success: data.success,
              });
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            } else {
              // alert(data.error);
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            }
          });
        }
        else if(sub_cat_id === "0" || sub_cat_id === "-1"){
          localStorage.setItem("mode","cat");
          API.GetProductsMappedwithCat({ cat_id: cat_id, store_id: 1, page: 1, brand_id: 0 })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              dispatch({
                type: actionTypes.Filter_Brands,
                payload: getFilterBrands(data.brands),
              });
              dispatch({
                type: actionTypes.Products_Mappedwith_Cat,
                payload: data.data,
                success: data.success,
              });
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            } else {
              // alert(data.error);
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            }
          });
        }
        else{
          localStorage.setItem("mode","subcat");
          API.GetProductsMappedwithSubCat({ sub_cat_id: sub_cat_id, store_id: 1, page: 1, brand_id: 0 })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              dispatch({
                type: actionTypes.Products_Mappedwith_SubCat,
                payload: data.data,
              });
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            } else {
              // alert(data.error);
              dispatch({
                type: actionTypes.Loading_Disable,
              });
            }
          });
        }
      }
    }
  }
};

export const getPromotionProducts = (body, dispatch) => {
  localStorage.setItem("mode","subcatoos");
  body["sub_cat_id"] = 187 ;
  dispatch({
    type: actionTypes.PLoading_Enable,
  });
  API.GetAllProductMappedSubCatOutofStock(body)
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        let cid = 0;
        //searchedproducts
        const pi = data.data.map((item, index) => {
          cid = item.cat_id;
          return {
            name: item.name,
            product_id: item.item_id,
            sku: item.product_code,
            price: item.store_items_price_types[0].price,
            category: item.cat_id,
            position: index + 1,
          };
        });
        // Products Searched
        window.analytics && window.analytics.track("Products Searched", {
          query:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
        });
        // Product List Viewed
        window.analytics && window.analytics.track("Product List Viewed", {
          list_id: window.location.pathname === "/" ? 18 : cid,
          category:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
          products: pi,
        });
        dispatch({
          type: actionTypes.Promotion_Products,
          payload: data.data,
          success: data.success,
        });
        dispatch({
          type: actionTypes.PLoading_Disable,
        });
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.PLoading_Disable,
        });
      }
    });
};

export const getArrivalProducts = (body, dispatch) => {
  localStorage.setItem("mode","subcatoos");
  // body["sub_cat_id"] = process.env.REACT_APP_BASE_URL === "https://api.delishop.asia/" ? 214 : 210;
  body["sub_cat_id"] = 214;
  dispatch({
    type: actionTypes.NLoading_Enable,
  });
  API.GetAllProductMappedSubCatOutofStock(body)
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        let cid = 0;
        //searchedproducts
        const pi = data.data.map((item, index) => {
          cid = item.cat_id;
          return {
            name: item.name,
            product_id: item.item_id,
            sku: item.product_code,
            price: item.store_items_price_types[0].price,
            category: item.cat_id,
            position: index + 1,
          };
        });
        // Products Searched
        window.analytics && window.analytics.track("Products Searched", {
          query:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
        });
        // Product List Viewed
        window.analytics && window.analytics.track("Product List Viewed", {
          list_id: window.location.pathname === "/" ? 18 : cid,
          category:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
          products: pi,
        });
        dispatch({
          type: actionTypes.Arrival_Products,
          payload: data.data,
          success: data.success,
        });
        dispatch({
          type: actionTypes.NLoading_Disable,
        });
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.NLoading_Disable,
        });
      }
    });
};

export const getTrendingProducts = (body, dispatch) => {
  localStorage.setItem("mode","subcatoos");
  // body["sub_cat_id"] = process.env.REACT_APP_BASE_URL === "https://api.delishop.asia/" ? 215 : 211;
  body["sub_cat_id"] = 215;
  dispatch({
    type: actionTypes.TLoading_Enable,
  });
  API.GetAllProductMappedSubCatOutofStock(body)
    .then((response) => response.json())
    .then(async (data) => {
      if (data.success) {
        let cid = 0;
        //searchedproducts
        const pi = data.data.map((item, index) => {
          cid = item.cat_id;
          return {
            name: item.name,
            product_id: item.item_id,
            sku: item.product_code,
            price: item.store_items_price_types[0].price,
            category: item.cat_id,
            position: index + 1,
          };
        });
        // Products Searched
        window.analytics && window.analytics.track("Products Searched", {
          query:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
        });
        // Product List Viewed
        window.analytics && window.analytics.track("Product List Viewed", {
          list_id: window.location.pathname === "/" ? 18 : cid,
          category:
            window.location.pathname === "/"
              ? "best-deals"
              : window.location.pathname.split("/")[1],
          products: pi,
        });
        dispatch({
          type: actionTypes.Trending_Products,
          payload: data.data,
          success: data.success,
        });
        dispatch({
          type: actionTypes.TLoading_Disable,
        });
      } else {
        // alert(data.error);
        dispatch({
          type: actionTypes.TLoading_Disable,
        });
      }
    });
};

export const getSortProductsName = (type, products, dispatch) => {
  if (type == 1) {
    let data = products.sort((a, b) => {
      var productA = a.name.toLowerCase(),
        productB = b.name.toLowerCase();
      if (productA > productB) {
        return 1;
      }

      if (productA < productB) {
        return -1;
      }
      return 0;
    });
    dispatch({
      type: actionTypes.Product_Sort_Name,
      payload: data,
    });
  } else if (type == 2) {
    let data = products.sort((a, b) => {
      var productA = a.name.toLowerCase(),
        productB = b.name.toLowerCase();
      if (productA < productB) {
        return 1;
      }

      if (productA > productB) {
        return -1;
      }
      return 0;
    });
    dispatch({
      type: actionTypes.Product_Sort_Name,
      payload: data,
    });
  }
};

export const sortByNameProduct = (text, products, dispatch) => {
  let data = products.filter(function (ele) {
    console.log("ele", ele.name.includes(text), text);
    return ele.name.toLowerCase().includes(text.toLowerCase());
  });
  dispatch({
    type: actionTypes.Filtered_Name_Products,
    payload: data,
  });
  if (text.length == 0) {
    dispatch({
      type: actionTypes.Show_Filtered_Name_Products,
      payload: false,
    });
  } else {
    dispatch({
      type: actionTypes.Show_Filtered_Name_Products,
      payload: true,
    });
  }
};

export const getSortProductsPrice = (type, products, dispatch) => {
  if (type == 3) {
    let data = products.sort((a, b) => {
      return (
        parseFloat(a.store_items_price_types[0].price) -
        parseFloat(b.store_items_price_types[0].price)
      );
    });
    dispatch({
      type: actionTypes.Product_Sort_Price,
      payload: data,
    });
  } else if (type == 4) {
    let data = products.sort((a, b) => {
      return (
        parseFloat(b.store_items_price_types[0].price) -
        parseFloat(a.store_items_price_types[0].price)
      );
    });
    dispatch({
      type: actionTypes.Product_Sort_Price,
      payload: data,
    });
  }
};

export const Reset_Suggestions = (dispatch) => {
  dispatch({
    type: actionTypes.Reset_Suggestions
  });
};

export const Toggle_Suggestions = (body,dispatch) => {
  dispatch({
    type: actionTypes.Toggle_Suggestions,
    payload: body.toggle
  });
};

export const changePage = (body, dispatch) => {
  dispatch({
    type: actionTypes.Change_Page,
    payload: body.page
  });
};

export const resetPage = (dispatch) => {
  dispatch({
    type: actionTypes.Reset_Page
  });
};

export const toggleCats = (body, dispatch) => {
  dispatch({
    type: actionTypes.Toggle_Categories,
    payload: body.toggle
  });
};

/*Delishop Express*/
export const initExpressStore = (data, dispatch) => {
  dispatch({
    type: actionTypes.Product_Brands,
    payload: data,
    success: data.success,
  });
};

export const initProductExpressStore = (data, dispatch) => {
  dispatch({
    type: actionTypes.Product_Filter_Brands,
    payload: data,
  });
};

export const initCategoryExpressStore = (data, dispatch) => {
  dispatch({
    type: actionTypes.Product_Category,
    payload: data.data,
    refs: data.data.map((element, index) => {
      return element.cat_ref_id
    }),
    success: data.success,
  });
};

export const initCategoryFilter = (data, dispatch) => {
  dispatch({
    type: actionTypes.Filter_Brands,
    payload: data
  });
};

export const sortProducts = (data, dispatch) => {
  dispatch({
    type: actionTypes.Filtered_Name_Products,
    payload: data,
  });
};

export const initProductBrand = (data, dispatch) => {
  dispatch({
    type: actionTypes.Product_Brands,
    payload: data,
  });
};
