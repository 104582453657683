import * as actionTypes from "../ActionTypes";

const initialState = {
    brands : [],
    success : true,
    error_message : "",
    loading : false,
    category : [],
    sub_category : [],
    products : [],
    suppliers : [],
    suppliersLists: [],
    supplierOrders: [],
    orders : []
  };
  
  export const dashboardReducer = (state = initialState, action)=>{
      switch(action.type){
          case actionTypes.GET_BRANDS : {
              return {
                  ...state,
                  brands : action.payload
              }
          }
          case actionTypes.ADD_BRANDS : {
            return {
                ...state,
                success : action.success
                 
            }
        }
        case actionTypes.UPDATE_BRANDS : {
            return {
                ...state,
            }
         }
         case actionTypes.SET_SUCCESS : {
             return{
                 ...state,
                 success : action.success
             }
         }
         case actionTypes.SET_ERROR : {
             return{
                 ...state,
                 error_message : action.payload,
                 success : action.success
             }
         }
         case actionTypes.SET_LOADING : {
             return {
                 ...state,
                 loading : action.payload
             }
         }
         case actionTypes.GET_CATEGORY : {
             return{
                 ...state,
                 category : action.payload
             }
         }
         case actionTypes.ADD_CATEGORY : {
            return {
                ...state,
                success : action.success
                 
            }
        }
        case actionTypes.UPDATE_CATEGORY : {
            return {
                ...state,
                success : action.success
                 
            }
        }
        case actionTypes.GET_SUB_CATEGORY : {
            return{
                ...state,
                sub_category : action.payload
            }
        }
        case actionTypes.ADD_SUB_CATEGORY : {
           return {
               ...state,
               success : action.success
                
           }
       }
       case actionTypes.UPDATE_SUB_CATEGORY : {
           return {
               ...state,
               success : action.success
                
           }
       }
       case actionTypes.GET_PRODUCTS : {
           return {
               ...state,
               products : action.payload
           }
       }
       case actionTypes.SEARCH_PRODUCTS : {
        return {
            ...state,
            products : action.payload
            }
        }
        case actionTypes.GET_SUPPLIERS : {
            return {
                ...state,
                suppliers : action.payload
            }
        }
        case actionTypes.INIT_SUPPLIERS_LISTS : {
          return {
            ...state,
            suppliersLists : action.payload
          }
        }
        case actionTypes.INIT_SUPPLIER_ORDERS : {
          return {
            ...state,
            supplierOrders : action.payload
          }
        }
        case actionTypes.UPDATE_SUPPLIER_ORDERS : {
          const orderID = action.payload;
          const updateStatus = state.supplierOrders.map(items => {
            if(items.order_id === parseInt(orderID))
              items.status = 'completed';

            return {
              ...items
            }
          });

          return {
            ...state,
            supplierOrders : updateStatus
          }
        }
        case actionTypes.UPDATE_SUPPLIERS_LISTS : {
          let suppliersLists = state.suppliersLists;
          const payload = action.payload;
          let totalOrderCompleted = (Array.isArray(payload.order_id)) ? payload.order_id.length : 1;

          let currentSupplier = suppliersLists.find(items => items.id === payload.supplier_id);
          if(currentSupplier){
            currentSupplier.totalPending = (currentSupplier.totalPending) ? (currentSupplier.totalPending - totalOrderCompleted) : 0;
          }

          const itemIndex = suppliersLists.indexOf(items => items.id === payload.supplier_id);

          if(itemIndex > 0){
            suppliersLists[itemIndex] = currentSupplier;
          }
          return {
            ...state,
            suppliersLists : suppliersLists
          }
        }
        case actionTypes.GET_SUB_CATEGORY_BY_CATEGORY : {
            return{
                ...state,
                sub_category : action.payload
            }
        }
        case actionTypes.ADD_PRODUCTS : {
            return {
                ...state,
                success : action.success
                 
            }
        }
        case actionTypes.UPDATE_PRODUCTS : {
            return {
                ...state,
                success : action.success
                 
            }
        }
        case actionTypes.GET_ORDERS : {
            return {
                ...state,
                orders : action.payload,
                success : action.success
                 
            }
        }
        case actionTypes.UPDATE_ORDERS : {
          return {
            ...state,
            orders : [],
            success : action.success

          }
        }
        default : {
              return {
                  ...state
              }
          }
      }
  }