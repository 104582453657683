import React, { useEffect, useState } from "react";
import PastOrder from "../containers/Profile/Order/Order";
import CurrentOrder from "../containers/Profile/Order/CurrentOrder";
import CartPopUp from "../containers/Cart/CartPopUp";
import {useCart} from "../contexts/cart/use-cart";
import {
  PageWrapper,
  SidebarSection,
} from "../containers/Profile/Profile.style";
import { Modal } from "@redq/reuse-modal";
import { Tab, Tabs } from "react-bootstrap";
import { useDeviceType } from "../helper/useDeviceType";
import { connect } from "react-redux";
import {
  currentOrders,
  pastOrders,
  fetchParticularOrder,
  reOrder,
  resetreordersuccess
} from "../store/actions/userAction";
import "./all-order.css";
import t from '../i18n/translate';
import {initBasket} from "../store/actions/cartAction";

const AllOrderPage = (props, { userAgent }) => {
  const deviceType = useDeviceType(userAgent);
  const [active, setActive] = useState("currentOrder");
  const { fetchOrderItems, cartclose } = useCart();
  const isExpress = localStorage.getItem('expressMode') ? 'express' : 'normal';

  useEffect(() => {
    props.initBasket(isExpress);
    fetchOrderItems(isExpress);
  }, []);

  window.onbeforeunload = () => {cartclose()};

  return (
    <>
      <Modal>
        <PageWrapper>
          <Tabs
            id="controlled-tab-example"
            activeKey={active}
            onSelect={(k) => setActive(k)}
          >
            <Tab eventKey={"currentOrder"} title={ t('shop_order_list_screen_current_order_tab', false) }>
              <CurrentOrder
                deviceType={deviceType}
                props={props}
                currentorders={props.current_orders}
                currentorderDetails={props.fetch_particular_order}
                tabactive={active}
              />
            </Tab>
            <Tab eventKey={"pastOrder"} title={ t('shop_order_list_screen_past_order_tab', false) }>
              <PastOrder
                deviceType={deviceType}
                props={props}
                pastorders={props.past_orders}
                pastorderDetails={props.fetch_particular_order}
                tabactive={active}
              />
            </Tab>
          </Tabs>
        </PageWrapper>
      </Modal>
      <CartPopUp deviceType={deviceType} />
    </>
  );
};
AllOrderPage.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};
const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    modalData: state.productReducer.modalData,
    user_address: state.userReducer.address,
    past_orders: state.userReducer.past_orders,
    current_orders: state.userReducer.current_orders,
    fetch_particular_order: state.userReducer.fetch_particular_order,
    reordersuccess: state.userReducer.reordersuccess,
    cartType: state.cartReducer.cartType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    currentOrders: (data) => currentOrders(data, dispatch),
    pastOrders: (data) => pastOrders(data, dispatch),
    fetchParticularOrder: (data) => fetchParticularOrder(data, dispatch),
    reOrder: (data) => reOrder(data, dispatch),
    resetreordersuccess: () => resetreordersuccess(dispatch),
    initBasket: (data) => initBasket(data, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllOrderPage);
