import React, {useRef, useEffect, useState} from "react";
import "./AppSideBar.css";
import SidebarData from "./SidebarData";
import {Link} from "react-router-dom";

function Sidebar() {
    const url = window.location.pathname;
    const useInterval = (callback, delay) => {
        const savedCallback = useRef();

        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        useEffect(() => {
            if(!isAlreadyHideCrisp){
                const tick = () => {
                    savedCallback.current();
                }
                if (delay !== null) {
                    let id = setInterval(tick, delay);
                    return () => clearInterval(id);
                }
            }
        }, [delay]);
    };

    // using interval to hide crisp floating button
    const [isAlreadyHideCrisp, setIsAlreadyHideCrisp] = useState(false);
    const [delay] = useState(1000);
    useInterval(() => {
        if(!isAlreadyHideCrisp){
            if(window && window.CRISP_WEBSITE_ID){
                window.$crisp.push(['do', 'chat:hide']);
                setIsAlreadyHideCrisp(true);
            }
        }
    //  stop interval after hide button
    }, !isAlreadyHideCrisp? delay: null);

    return (
        <div col="2" id="mySidenav" className="Sidebar">
            <ul className="SidebarList">
                {SidebarData.map((val, key) => {
                    if (val.link === "/admin/order_map") {
                        return (
                            <li
                                key={key}
                                className={url == val.link ? "row bg-secondary" : "row"}
                            >
                                <Link
                                    target="_blank"
                                    to={{pathname: val.link}}
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        display: "inline-flex",
                                        width: "100%",
                                        height: "100%",
                                        lineHeight: "3"
                                    }}
                                >
                                    <div id="icon">{val.icon}</div>
                                    <div id="name">{val.name}</div>
                                </Link>
                            </li>
                        );
                    } else {
                        return (
                            <li
                                key={key}
                                className={url == val.link ? "row bg-secondary" : "row"}
                                onClick={() => {
                                    window.location.pathname = val.link;
                                }}
                            >
                                {" "}
                                <div id="icon">{val.icon}</div>
                                <div id="name">{val.name}</div>
                            </li>
                        );
                    }
                })}
            </ul>
        </div>
    );
}

export default Sidebar;
