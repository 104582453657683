import React from "react";
import styled from "styled-components";
import { Modal } from "@redq/reuse-modal";
import t from '../i18n/translate';

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 130px 0 60px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  @media (min-width: 990px) {
    width: 870px;
    margin-left: auto;
    margin-right: auto;
  }
  
  ul {
    margin-left: 30px;
    
    li {
      margin-bottom: 5px;
      line-height: 1em;
      margin-top: 15px;
    }
    li:before {
      content: "-";
      font-size: 30px;
      position: relative;
      top: 3px;
      margin-right: 15px;
    }
  }

  @media (max-width: 989px) {
    padding: 30px;
  }
`;

export default function PaymentScreen() {
  return (
    <Modal>
      <HelpPageWrapper>
        <HelpPageContainer>
          <Heading>{ t('payment_heading_title') }</Heading>

          { t('payment_minimum_order', false, {path: '$10'}) }<br />
          { t('payment_exchange_rate') }<br /><br />

          { t('payment_accept_payment') }<br />
          <ul>
            <li>{ t('payment_in_cash') }</li>
            <li>{ t('payment_aba_qr') }</li>
            <li>{ t('payment_aba_card') }</li>
          </ul>
          <br />
          <br />

          { t('payment_delivery_price') }<br /><br />
          <b>{ t('payment_price_from', true, {path: '$10', path2: '$50'}) } :</b> 0.5/km<br />
          <b>{ t('payment_price_above', true, {path: '$50'}) }</b><br />


        </HelpPageContainer>
      </HelpPageWrapper>
    </Modal>
  );
}
