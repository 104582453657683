import React, { useContext, useEffect } from "react";
import {
  LinkButton,
  Button,
  Wrapper,
  Container,
  LogoWrapper,
  Heading,
  SubHeading,
  OfferSection,
  Offer,
  Input,
  Divider,
} from "./SignInOutForm.style";
import { AuthContext } from "../../contexts/auth/auth.context";
import { FormattedMessage } from "react-intl";
import Modal from "react-bootstrap/Modal";
// import Image from 'components/Image/Image';
// import PickBazar from '../../image/PickBazar.png';
import { connect } from "react-redux";
import { doLogin } from "../../store/actions/authAction";
import Loader from "../../components/Loader/Loader";
import * as Constants from "../../constants/Constant";
import axios from "axios";
import history from "../../services/history";
import t from '../../i18n/translate';
import reactStringReplace from 'react-string-replace';

const signUpLinkStyle = {
  color: "green",
  textDecoration: "underline",
  fontWeight: "700",
  cursor: "pointer",
  marginLeft: 5
};

const SignInModal = (props) => {
  const { authDispatch } = useContext(AuthContext);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [cerror, setCError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const toggleSignUpForm = () => {
    authDispatch({
      type: "SIGNUP",
    });
  };

  const toggleForgotPassForm = () => {
    authDispatch({
      type: "FORGOTPASS",
    });
  };

  const didLoginFail = () => {
    if(!props.success){
      setCError(true);
    }
    setLoading(false);
  };

  const loginCallback = (e) => {
    e.preventDefault();
    if (!error) {
      setLoading(true);
      props.doLogin({ email: email, password: password });
      setTimeout(() => {
        didLoginFail();
      }, 1000);
    }
  };
  useEffect(()=>{
    if(window.location.pathname === "/register"){
      authDispatch({
        type: "SIGNUP",
      })
    }
  },[]);
  useEffect(() => {
    if (props.success) {
      // if(props.loginresponsemsg === "Your account is not verified yet. We have sent you an email for verification. Please check your inbox or spam."){
      //   alert("Your account is not verified yet. We have sent you an email for verification. Please check your inbox or spam.");
      // }
      setLoading(false);
      setCError(false);
      // authDispatch({ type: "SIGNIN_SUCCESS" });
    }
  }, [props.success, props.loginresponsemsg]);

  const linkToSignUpComponent = (e) => {
    e.preventDefault();
    authDispatch({type: "SIGNUP",});
  }

  const validateEmail = async () => {
    if (!email) {
      const errorEmailTran = t('login_error_enter_an_email_label', false);
      const errorEmailTranReplace = reactStringReplace(`${errorEmailTran}`, /({path})/g, () =>
        <span onClick={(e) => { linkToSignUpComponent(e) }} style={signUpLinkStyle}>
          { t('login_enter_email_to_register_redirection_label') }
        </span>
      );
      setErrorMsg(errorEmailTranReplace);

      setError(true);
      return false;
    }
    else if ((Constants.email_reg.test(email)) === false) {
      setErrorMsg(t('login_error_incorrect_email'));
      setError(true);
      return false;
    }
    else {
      axios
        .post(Constants.baseUrl+"api/checkUserExistByEmail", {
          "email": email,
        })
        .then(function (response) {
            if(response.data.status === 2){
              const errorAccountNotExistTran = t('login_error_account_not_exist_label', false);
              const errorEmailTranReplace = reactStringReplace(`${errorAccountNotExistTran}`, /({path})/g, () =>
                <span onClick={(e) => { linkToSignUpComponent(e) }} style={signUpLinkStyle}>
                  { t('login_enter_email_to_register_redirection_label') }
                </span>
              );
              setError(true);
              setErrorMsg(errorEmailTranReplace);
              return false;
            }
            else{
              setError(false);
              setErrorMsg("");
              return true;
            }
        })
        .catch(function (error) {
          console.log(error);
        });
      // setError(false);
    }
    // if ((await Constants.email_reg.test(email)) === false || !email || email === "") {
    //   setError(true);
    // } else {
    //   setError(false);
    // }
  };
  return (
    <Wrapper>
      <Container>
        {/* <LogoWrapper>
          <Image url={PickBazar} />
        </LogoWrapper> */}

        <Heading className={props.cartType}>
          { t('login_welcome_back_label') }
        </Heading>

        <SubHeading>
          { t('login_login_here_label') }
        </SubHeading>
        <form onSubmit={loginCallback}>
          <Input
            type="email"
            placeholder={ t('login_input_email_title', false) }
            value={email}
            className={props.cartType}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={validateEmail}
            required
          />
          <Input
            type="password"
            placeholder={ t('login_input_password_title', false) }
            value={password}
            className={props.cartType}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <Button
            fullwidth
            title={ t('login_button_login', false) }
            intlButtonId="continueBtn"
            type="submit"
            style={{ color: "#fff" }}
            disabled={loading}
            loader={<Loader />}
            isLoading={loading}
          />
          {errorMsg !== ""
            ? <span style={{color: "red", marginBottom: 10}}>{errorMsg}</span>
            : null
          }
          {cerror && props.loginresponsemsg === "invalid password" ? <span className="text-danger">
            { t('login_error_wrong_password_label') } { t('common_or_label') } <span onClick={(e) => {
              e.preventDefault();
              authDispatch({
                type: "FORGOTPASS",
              });
              }} 
              style={{color: props.cartType === 'express' ? "#f01266" : "green", textDecoration: "underline", fontWeight: "700", cursor: "pointer"}}>{ t('login_button_forgot_password_reset_link') }</span></span> : null}
          {cerror && props.loginresponsemsg !== "" && props.loginresponsemsg !== "invalid password"
            ? <span className="text-success" style={{fontWeight: "700"}}>{ t('login_error_password', false) }</span>
            : null
          }
        </form>
        <Divider>
          <span>
            { t('common_or_label') }
          </span>
        </Divider>

        <Offer style={{ padding: "20px 0" }}>
          { t('login_dont_have_account_description') }{" "}
          <LinkButton className={props.cartType} onClick={toggleSignUpForm}>
            { t('login_button_sign_up') }
          </LinkButton>
        </Offer>
      </Container>

      <OfferSection>
        <Offer className={props.cartType}>
          { t('login_forgot_password_label') }{" "}
          <LinkButton className={props.cartType} onClick={toggleForgotPassForm}>
            { t('login_button_forgot_password_reset_link') }
          </LinkButton>
        </Offer>
      </OfferSection>
    </Wrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    loginresponsemsg: state.authReducer.loginresponsemsg,
    cartType: state.cartReducer.cartType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doLogin: (body) => doLogin(body, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignInModal);
