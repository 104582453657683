import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
export const RightMenuBox = styled.div`
  margin-left: 25px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .menu-icon {
    min-width: 14px;
    margin-right: 7px;
  }

  .menu-item {
    a {
      font-family: "Lato", sans-serif;
      font-size: ${themeGet("fontSizes.2", "15")}px;
      font-weight: ${themeGet("fontWeights.6", "700")};
      color: ${themeGet("colors.darkBold", "#0D1136")};
      line-height: 1.2em;
      display: block;
      transition: 0.15s ease-in-out;
      display: flex;
      align-items: center;
      margin-right: 45px;

      @media (max-width: 1400px) {
        margin-right: 35px;
        font-size: 15px;
      }
      &:hover {
        color: ${themeGet("colors.primary", "#389244")};
      }
      &.current-page {
        color: ${themeGet("colors.primary", "#389244")};
      }
    }
  }

  .user-pages-dropdown {
    .popover-handler {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      display: block;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .popover-content {
      .inner-wrap {
        /* padding: ; */
      }
    }
  }
`;

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const AccountContainer = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  position: absolute;
  z-index: 1;
  top: -.2125rem;
  right: -.975rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CartContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CartIcon = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 6px;
`;

export const CartLabel = styled.div`
  margin-top: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: rgb(0, 151, 0);
  font-size: 13px;
  color: rgb(255, 255, 255);
  position: absolute;
  z-index: 1;
  top: -.2225rem;
  right: -.975rem;
  
  &.express {
    background-color: ${themeGet("colors.expressColor")};
    border: 3px solid #fff;
    width: 23px;
    height: 23px;
  }
`;

export const LabelContainer = styled.span`
  padding-top: 5px;
  font-size: 13px;
  color: rgb(0, 151, 0);
  
  &.express { color: ${themeGet("colors.expressColor")}; }
`;

