import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import localForage from "localforage";
const isObjectLiked = (value) =>
  value.constructor.name === "Array" || value.constructor.name === "Object";

const rehydrate = (value, defaultValue) => {
  if (!value) return defaultValue;
  try {
    const parse = JSON.parse(value);
    return parse;
  } catch (err) {
    return defaultValue;
  }
};

const hydrate = (value) => {
  if (!isObjectLiked(value)) {
    return value;
  }
  return JSON.stringify(value);
};
const createMigration = (opts, data) => {
  return new Promise((resolve, reject) => {
    const key = `${opts.key}-version`;
    localForage.getItem(key, (err, version) => {
      if (version !== opts.version) {
        data = opts.migrate(data);
        localForage.setItem(opts.key, rehydrate(data), (err) => {
          if (err) return reject(err);
          localForage.setItem(key, opts.version, (err) => {
            if (err) return reject(err);
            return resolve(data);
          });
        });
      } else {
        resolve(data);
      }
    });
  });
};

const config = {
  key: "@session",
  version: 1,
  migrate: (state) => {
    return { ...state };
  },
};

const expressConfig = {
  key: "@expressSession",
  version: 1,
  migrate: (state) => {
    return { ...state };
  },
};

export const useStorage = (state, setState) => {
  const [rehydrated, setRehydrated] = useState(false);
  const [error, setError] = useState(null);
  const basketType = useSelector(storeState => storeState.cartReducer.cartType);

  useEffect(() => {
    async function init() {
      let localForageConfig = (basketType !== 'express') ? config : expressConfig;

      await localForage.getItem(localForageConfig, (err, value) => {
        if (err) {
          setRehydrated(true);
          return setError(err);
        }
        // Migrate persisted data
        const restoredValue = rehydrate(value);
        if (typeof localForageConfig.migrate === "function") {
          createMigration(localForageConfig, restoredValue)
            .then((data) => setState(data))
            .then(() => setRehydrated(true));
        } else {
          setState(restoredValue);
          setRehydrated(true);
        }
      });
    }
    init();
  }, []);

  useEffect(() => {
    let localForageConfig = (basketType !== 'express') ? config : expressConfig;
    localForage.setItem(localForageConfig.key, hydrate(state));
  }, [state, basketType]);

  return {
    rehydrated,
    error,
  };
};
