import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  background-color: #f7f7f7;
  box-sizing:border-box;
  .reuseModalHolder {
    padding: 0;
    overflow: auto;
    border-radius: 3px 3px 0 0;
    border: 0;
    
  }
`;
