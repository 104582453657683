// export const baseUrl = 'http://test-store-api.shoocal.com/';
// export const baseUrl = "https://api.delishop.asia/";
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const sompomAPI = process.env.SOMPOM_APP_BASE_URL;
export const email_reg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const mediumRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
);
export const strongPassRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);
export const buttonColor = "#e1613f";
var month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
var week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const changeTimezone = (date, ianatz) => {
  
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: ianatz
  }));

  var diff = date.getTime() - invdate.getTime();

  return new Date(date.getTime() - diff);

};

export const compDate = (promoStart, promoEnd) => {
  const psd = changeTimezone(new Date(promoStart.replace(/-/g,'/')),"Asia/Phnom_Penh");
  const ped = changeTimezone(new Date(promoEnd.replace(/-/g,'/')),"Asia/Phnom_Penh");
  const currentDate = changeTimezone(new Date(),"Asia/Phnom_Penh");

  if (currentDate > psd && currentDate < ped){
    return true;
  }
  else{
    return false;
  }
  // return currentDate > psd && currentDate < ped ? true : false;
};

export const isTodayApplicable = () => {
  const d = (new Date()).toLocaleTimeString('en-US', {
    timeZone: "Asia/Phnom_Penh"
  });
  if(d.indexOf("AM") === -1){
    if(d.indexOf("PM") !== -1 && (d.split(":")[0] === "12" || d.split(":")[0] === "1")){
      return true
    }
    else{
      return false
    }
  }
  else{
    return true
  }
};

export const isTodayValid = () => {
  const d = (new Date()).toLocaleDateString('en-US', {
    timeZone: "Asia/Phnom_Penh"
  });
  if (d.split("/")[0] === "2"){
    if(Number(d.split("/")[1]) >= 10 && Number(d.split("/")[1]) <= 13){
      return true;
    }
    else{
      return false;
    }
  }
  else{
    return false;
  }
};

export const convertDate = (date) => {
  const convertedDate = new Date(date);
  const getParticularDay = convertedDate.getDay();
  const getParticularMonth = convertedDate.getMonth();
  const getParticularYear = convertedDate.getFullYear();
  const getParticularDate = convertedDate.getDate();

  const string =
    week[getParticularDay] +
    " " +
    getParticularDate +
    " " +
    month[getParticularMonth] +
    " " +
    getParticularYear;
  return string;
};
export const convertTime24to12 = (time24) => {
  if (time24) {
    let tmpArr = time24.split(":"),
      time12;
    if (Number(tmpArr[0]) === 12) {
      time12 = tmpArr[0] + ":" + tmpArr[1] + " PM";
    } else {
      if (Number(tmpArr[0]) === 0) {
        time12 = "12:" + tmpArr[1] + " AM";
      } else {
        if (Number(tmpArr[0]) > 12) {
          time12 = tmpArr[0] - 12 + ":" + tmpArr[1] + "PM";
        } else {
          time12 = tmpArr[0] + ":" + tmpArr[1] + "AM";
        }
      }
    }
    return time12;
  } else {
    return "";
  }
};
// export const gradientColor=['#1C0C59','#3693D0', '#0455A4' ];
// medium password requirements
// (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
// (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
// (?=.*[0-9])	The string must contain at least 1 numeric character
// (?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
// (?=.{8,})	The string must be eight characters or longer
