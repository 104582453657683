export const HOME_PAGE = "";
export const GROCERY_PAGE = "/";
export const PRODUCT_INFO = "/products";
export const MAKEUP_PAGE = "/makeup";
export const CLOTHING = "/clothing";
export const BAGS_PAGE = "/bags";
export const BOOK_PAGE = "/book";
export const FURNITURE_PAGE = "/furniture";
export const MEDICINE_PAGE = "/medicine";
export const RESTAURANT_PAGE = "/restaurant";
export const DETAILS_PAGE = "/detail";
export const PROCEED_TO_CHECKOUT_PAGE = "/checkout";
export const ALTERNATIVE_CHECKOUT_PAGE = "/checkout-alternative";
export const REQUEST_MEDICINE_PAGE = "/request-medicine";
export const PROFILE_PAGE = "/profile";
export const YOUR_ORDER = "/order";
export const CURRENT_ORDER = "/ongoing-orders";
export const ORDER_RECEIVED = "/order-received";
export const OFFER_PAGE = "/offer";
export const HELP_PAGE = "/help";
export const DELIVERY_PAGE = "/delivery";
export const CONTACT_INFO = "/contact";
export const TERMS_INFO = "/terms";
export const PAYMENTS_INFO = "/payments";
export const ABOUT_INFO = "/about";
export const ALL_ORDERS_PAGE = "/all-orders";
export const ALL_BRANDS_PAGE = "/brands";
