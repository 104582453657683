import React, { useState, useEffect } from "react";

// ------------ Components ------------
import { Modal as CustomModal } from "@redq/reuse-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Loader from "../../components/Loader/Loader";
import { Switch } from "@material-ui/core";
import AsyncSelect from "react-select/async";
import {
  Table,
  Modal,
  Form,
  Pagination,
  InputGroup,
  ListGroup,
  Spinner
} from "react-bootstrap";
import { Edit, RemoveCircle, Delete } from "@material-ui/icons";

// -------------- APIs ---------------
import API from "../../network/dashboardAPI";

// -------------- Style --------------
import styled from "styled-components";
import "react-datetime/css/react-datetime.css";
import { set } from "lodash";

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const labelStyle = {
  lineHeight: "2rem",
};

let typingTimer = null;

const WalletList = (props) => {
  // --------------- States ------------------
  const [isRequesting, setIsRequesting] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [countWallet, setCountWallet] = useState(0);
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formError, setFormError] = useState({
    type: false,
    walletOwner: false,
    reason: false,
    amount: false,
    comment: false
  });
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [showType, setShowType] = useState(false);
  const [modalType, setModalType] = useState("Add");
  const [updateDoc, setUpdateDoc] = useState({});
  const [removeDoc, setRemoveDoc] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [perPage, setPerPage] = useState(50);

  // form props
  const [type, setType] = useState("");
  const [walletOwner, setWalletOwner] = useState("");
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState(0);
  const [comment, setComment] = useState("");

  // form ref
  const _firstNameInputRef = React.useRef("");
  const _lastNameInputRef = React.useRef("");
  const _phoneInputRef = React.useRef("");
  const _emailInputRef = React.useRef("");
  const _passwordInputRef = React.useRef("");
  const _confirmPasswordInputRef = React.useRef("");

  // pagination props
  const [next, setNext] = useState(0);
  const [limit, setLimit] = useState(10);
  const [prev, setPrev] = useState(0);
  const [pager, setPager] = useState(1);

  // Modal customer list
  const [isOpenCustomerModal, setIsOpenCustomerModal] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState("");

  // --------------- Methods -----------------
  const getWalletList = async ($next) => {
    setIsRequesting(true);
    await API.getWalletTransactionByCustomerId({
      next: $next,
      limit,
      customerId: 6772,
    })
      .then((response) => response.json())
      .then((response) => {
        setIsRequesting(false);
        if (response.success) {
          setWalletList(response.data.list);
          setCountWallet(response.data.total);

          if (response.data.next) {
            setNext(response.data.next);
          } else if (response.data.list.length) {
            setNext(next + response.data.list.length);
          } else {
            setNext(0);
          }
        }
      });
  };

  const getCustomerList = async (search = "", fieldName = "createdAt", orderBy = "DESC", limit = perPage, offset = 0) => {
    
    try {
        setIsRequesting(true);
        const query = selectedStatus;
        API.getCustomers(query, { search, fieldName, orderBy, limit, offset })
          .then(res => res.json())
          .then(res => {
            if (res.success) {
              setCustomerList(res.result);
            } else {
              alert("Sort not found");
            }
            setIsRequesting(false)
          });
      } catch (e) {
        console.log("Error, can not find customer", e);
        setCustomerList([]);
        setIsRequesting(false)
      }
  };

  const resetFormData = () => {
    setType("");
    setWalletOwner("");
    setReason("");
    setAmount(0);
    setComment("");
  };

  const setFormData = (props) => {
    setType(props.type);
    setWalletOwner(props.walletOwner);
    setReason(props.reason);
    setAmount(props.amount);
    setComment(props.comment);
  };

  const resetFormError = () => {
    setFormError({
      type: false,
      walletOwner: false,
      reason: false,
      amount: false,
      comment: false
    });
  };

  const checkFormValidate = () => {
    let error = {};
    if (!type) {
      error.type = true;
    }

    if (!walletOwner) {
      error.walletOwner = true;
    }

    if (!reason) {
      error.reason = true;
    }

    if (!amount || amount === 0) {
      error.amount = true;
    }

    if (!comment) {
      error.comment = true;
    }

    setFormError(error);

    return !error.type &&
      !error.reason &&
      !error.walletOwner &&
      !error.amount &&
      !error.comment
      ? true
      : false;
  };

  const updatePaginationProps = (type) => {
    let tmpPager = pager + 1;
    if (type === "PREV") {
      tmpPager = pager - 1;
    }

    const tmpNext = limit * tmpPager;
    const tmpPrev = limit * tmpPager - limit;

    setPager(tmpPager);
    setPrev(tmpPrev);

    return { pager: tmpPager, next: tmpNext, prev: tmpPrev };
  };
  // --------------- Compute data -----------
  useEffect(() => {
    getWalletList(next);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(typingTimer);
    };
  }, []);

  // --------------- Event change ----------
  const handleOpenModal = (e) => {
    setIsShowAddModal(true);
  };

  const handleCloseModal = () => {
    resetFormData();
    resetFormError();
    setIsShowAddModal(false);

    if (modalType === "Update") {
      setModalType("Add");
    }
  };

  const handleSubmit = () => {
    checkFormValidate();

    if (checkFormValidate()) {
      setIsRequesting(true);

      if (modalType === "Add") {
        const body = {
          transaction_type: type,
          wallet_owner_id: walletOwner,
          reason,
          wallet_amount: amount,
          comment
        };

        API.createDriver(body)
          .then((response) => response.json())
          .then((response) => {
            setIsRequesting(false);
            if (response.success) {
              handleCloseModal();
              let tmpNext = next;
              if (next > 0) {
                tmpNext = next - limit * 2;
              }
              getWalletList(tmpNext);
            }
          });
      } else {
        const body = {
          transaction_type: type,
          wallet_owner_id: walletOwner,
          reason,
          wallet_amount: amount,
          comment
        };

        const driverId = updateDoc.id;
        API.updateDriver(driverId, body)
          .then((response) => response.json())
          .then((response) => {
            setIsRequesting(false);
            if (response.success) {
              handleCloseModal();
              let tmpNext = next;
              if (next > 0) {
                tmpNext = next - limit * 2;
              }
              getWalletList(tmpNext);
              setUpdateDoc({});
            }
          });
      }
    }
  };

  const handleUpdate = (wallet) => {
    setFormData({
      type: wallet.transaction_type,
      walletOwner: wallet.wallet_owner_id,
      reason: wallet.reason,
      amount: wallet.wallet_amount,
      comment: wallet.comment
    });
    setUpdateDoc(wallet);
    setModalType("Update");
    handleOpenModal();
  };

  const handleRemove = () => {
    console.log("remove");
  };

  const handlePaginNext = () => {
    const { next, prev } = updatePaginationProps("NEXT");
    getWalletList(prev);
  };

  const handlePaginPrev = () => {
    const { pager, next, prev } = updatePaginationProps("PREV");
    getWalletList(prev);
  };

  const handleCloseConfirmModal = () => {
    setIsShowConfirmModal(false);
    setRemoveDoc({});
  };

  const handleOpenCustomerModal = () => {
    setIsOpenCustomerModal(true);
    getCustomerList();
  };

  const handleCloseModalAddDriver = () => {
    setIsOpenCustomerModal(false);
    setCustomerList([]);
  };

  const handleSubmitDisActive = () => {
    setIsRequesting(true);
    const driverId = removeDoc.id;
    const body = {
      email: [removeDoc.email],
      first_name: removeDoc.first_name,
      last_name: removeDoc.last_name,
      phone: removeDoc.phone,
      password: removeDoc.password,
      active: 0,
    };
    API.updateDriver(driverId, body)
      .then((response) => response.json())
      .then((response) => {
        setIsRequesting(false);

        if (response.success) {
          handleCloseConfirmModal();
          let tmpNext = next;
          if (next > 0) {
            tmpNext = next - limit;
          }
          getWalletList(tmpNext);
        }
      });
  };

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer.name);
    setSelectedCustomerId(customer.cust_id);
  }

  const handleCloseCustomerModal = () => {
    setSelectedCustomer("");
    setSelectedCustomerId("");
    setIsOpenCustomerModal(false);
  }

  const handleConfirmSelectCustomer = () => {
    setWalletOwner(selectedCustomerId);
    setIsOpenCustomerModal(false);
  }

  const handleSummitAddWallet = () => {
    setIsRequesting(true);

    if(reason && amount > 0 && type && walletOwner ){
      const body = {
        transaction_type: type,
        wallet_owner_id: walletOwner,
        reason,
        wallet_amount: parseInt(amount),
        comment,
        order_number: ""
      }

      API.addWalletTransaction(body)
      .then((response) => response.json())
      .then((response) => {
        setIsRequesting(false);

        if(response.success){
            resetFormData();
        }
      })
    }else{
      if(!reason){
        setFormError((prevState) => ({
          ...prevState,
          reason: true
        }))
      }

      if(!amount || !(amount > 0)){
        setFormError((prevState) => ({
          ...prevState,
          amount: true
        }))
      }

      if(!walletOwner){
        setFormError((prevState) => ({
          ...prevState,
          walletOwner: true
        }))
      }

      if(!type){
        setFormError((prevState) => ({
          ...prevState,
          type: true
        }))
      }

      setIsRequesting(false);
    }
    
  }

  // ------------------ Custom components ----------------
  // Pagination
  let pageItems = [
    <Pagination.Item disabled={next < 20} key={0} onClick={handlePaginPrev}>
      Prev
    </Pagination.Item>,
    <Form.Group key={1} controlId="formGridPagination">
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>{`${next} / ${countWallet}`}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
    </Form.Group>,
    <Pagination.Item
      disabled={next === countWallet}
      key={2}
      onClick={handlePaginNext}
    >
      Next
    </Pagination.Item>,
  ];
  const PaginationBasic = () => (
    <div className="d-flex justify-content-center">
      <Pagination>{pageItems}</Pagination>
    </div>
  );

  return (
    <CustomModal>
      <HelpPageWrapper>
        <Row className="mb-3 d-flex justify-content-center">
          <Col sm={6}>
            <h3 className="mb-3">Add Money</h3>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col sm={6}>
            <Form noValidate validated={validated} autoComplete="false">
              <Form.Group controlId="formType">
                <Form.Label>Type</Form.Label>
                <InputGroup hasvalidation="true">
                  <Form.Control
                    as="select"
                    onChange={(e) => setType(e.target.value)}
                    value={type}
                    isInvalid={formError.type}
                    required
                    placeholder="Select"
                  >
                    <option value={""}></option>
                    <option value={"debit"}>Debit</option>
                    <option value={"credit"}>Credit</option>
                  </Form.Control>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formUser">
                <Form.Label>User</Form.Label>
                <InputGroup hasvalidation="true">
                    <Form.Control
                        onClick={handleOpenCustomerModal}
                        value={selectedCustomer}
                        isInvalid={formError.walletOwner}
                        required
                    />
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formReason">
                <Form.Label>Reason</Form.Label>
                <InputGroup hasvalidation="true">
                  <Form.Control
                    as="select"
                    onChange={(e) => setReason(e.target.value)}
                    value={reason}
                    isInvalid={formError.reason}
                    required
                    placeholder="Select"
                  >
                    <option value={""}></option>
                    <option value={"marketing"}>Marketing</option>
                    <option value={"order"}>Order</option>
                    <option value={"order_fail"}>Order Fail</option>
                    <option value={"refund"}>Refund</option>
                    <option value={"other"}>Other</option>
                  </Form.Control>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formAmount">
                <Form.Label>Amount</Form.Label>
                <InputGroup hasvalidation="true">
                  <Form.Control
                    isInvalid={formError.amount}
                    type="number"
                    placeholder="00.00 USD"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formComment">
                <Form.Label>Comment</Form.Label>
                <InputGroup hasvalidation="true">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={comment}
                    isInvalid={formError.comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>

              <Button variant="primary" onClick={handleSummitAddWallet}>
                    Add
                </Button>
            </Form>

            <Modal
              show={isOpenCustomerModal}
              onHide={handleCloseCustomerModal}
              aria-labelledby="contained-modal-title-vcenter"
              size="xl"
              centered
              className="bd-example-modal-xl"
            >
              <Form noValidate validated={validated} autoComplete="false">
                <Modal.Header closeButton>
                  <Modal.Title>Add driver</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center" key="modalBody" style={{maxHeight: "60vh", overflow: "scroll", overflowX: "hidden"}}>
                  {customerList && customerList.length ? (
                    <Row>
                        {
                            (customerList.map((customer, index) => (
                              <Col sm={3} key={customer.cust_id}>
                                <input
                                  type="radio"
                                  id={`custom-checkbox-${customer.cust_id}`}
                                  name={customer.cust_id}
                                  value={customer.cust_id}
                                  onChange={(e) => handleSelectCustomer(customer)}
                                  checked={customerList[index].cust_id === selectedCustomerId}
                                />
                                <label htmlFor={`custom-checkbox-${customerList.cust_id}`}>
                                  &nbsp;&nbsp;{customer.name}
                                </label>
                              </Col>
                            )))
                        }
                    </Row>
                  ): <Spinner animation="grow" className="d-flex justify-content-center"/>}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={handleConfirmSelectCustomer}>
                    Ok
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleCloseCustomerModal}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </Col>
        </Row>
      </HelpPageWrapper>
    </CustomModal>
  );
};

export default WalletList;
