import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";

// ------------ Lib ------------------
import _, {set} from "lodash";
import moment from "moment";

// ------------ Components ------------
import {Modal as CustomModal} from "@redq/reuse-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Table} from "react-bootstrap";
// import CheckBox from "../../components/Checkbox/Checkboxs";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import EmailIcon from "@material-ui/icons/Email";
import MotorcycleTwoTone from "@material-ui/icons/MotorcycleTwoTone";
import {green} from "@material-ui/core/colors";
import Loader from "../../components/Loader/Loader";
import {Form, Modal, Pagination, InputGroup, Spinner, Toast} from "react-bootstrap";
import {RemoveRedEye, Edit, Filter} from "@material-ui/icons";
import Datetime from 'react-datetime';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox} from '@material-ui/core';

// -------------- APIs ---------------
import API from "../../network/dashboardAPI";

// -------------- State Action -------------
import {getOrders} from "../../store/actions/dashboardAction";

// -------------- Style --------------
import styled from "styled-components";
import "react-datetime/css/react-datetime.css";

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const TableBodyWidth = styled.div`
  table tr th {
    white-space: nowrap;
    width: initial;
  }
`;

const labelStyle = {
  lineHeight: "2.5rem",
  width: "14rem",
};

const success = "success";
const secondary = "secondary";
const UpArrow = "↑";
const DownArrow = "↓";
const UpDownArrow = "↑↓";

const defaultStartDate = moment(new Date()).startOf('day').format("YYYY-MM-DD HH:mm:ss");
const defaultEndDate = moment(new Date()).endOf('day').format("YYYY-MM-DD HH:mm:ss");
const Orders = (props) => {
      const startDateFilterRef = useRef("");
      const endDateFilterRef = useRef("");

      // ------------------ Status -----------------------
      const [accept, setAccept] = useState(true);
      const [dispatch, setDispatch] = useState(true);
      const [orderIDs, setOrderIDs] = useState([]);
      const [status, setStatus] = useState(2345678);
      const [isSelected, setIsSelected] = useState(false);
      const [sortFlag, setSortFlag] = useState(true);
      const [orderArrow, setOrderArrow] = useState(false);
      const [amountArrow, setAmountArrow] = useState(false);
      const [dateArrow, setDateArrow] = useState(false);
      const [orderList, setOrderList] = useState([]);
      const [isRequesting, setIsRequesting] = useState(false);
      const [totalNumberList, setTotalNumberList] = useState(0);
      const [isOpenModalAddDriver, setIsOpenModalAddDriver] = useState(false);
      const [validated, setValidated] = useState(false);
      const [driverList, setDriverList] = useState([]);
      const [selectedDriver, setSelectedDriver] = useState("");
      const [isAllowAddDriver, setIsAllowAddDriver] = useState(true);
      const [orderUpdate, setOrderUpdate] = useState({});
      const [isShowOnlyAcceptedHasDriver, setIsShowOnlyAcceptedHasDriver] =
          useState(false);
      const [orderStatusCount, setOrderStatusCount] = useState({});
      const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
      const [orderIdToCancel, setOrderIdToCancel] = useState("");
      const [orderToViewWallet, setOrderToViewWallet] = useState({});
      const [selectedStatus, setSelectedStatus] = useState(0);
      const [orderStatusOpts, setOrderStatusOpts] = useState([
        {
          label: "All status",
          value: {status: 2345678, isShowAccptedHasDriver: false},
        },
        {label: "New", value: {status: 2, isShowAccptedHasDriver: false}},
        {label: "Accepted", value: {status: 3, isShowAccptedHasDriver: false}},
        {
          label: "Assigned to driver",
          value: {status: 3, isShowAccptedHasDriver: true},
        },
        {
          label: "Packed & On the way",
          value: {status: 45, isShowAccptedHasDriver: false},
        },
        {
          label: "Delivered",
          value: {status: 6, isShowAccptedHasDriver: false},
        },
        {label: "Arrived", value: {status: 8, isShowAccptedHasDriver: false}},
        {label: "Cancelled", value: {status: 7, isShowAccptedHasDriver: false}},
      ]);
      const [selectedBasketType, setSelectedBasketType] = useState("");
      const [textSearch, setTextSearch] = useState("");
      const [startDate, setStartDate] = useState("");
      const [endDate, setEndDate] = useState("");

      // pagination states
      const [next, setNext] = useState(0);
      const [limit, setLimit] = useState(10);
      const [prev, setPrev] = useState(0);
      const [pager, setPager] = useState(1);

      // Loading states
      const [isRequestingUpsert, setIsRequestingUpsert] = useState(false);

      // Wallet list data
      const [walletTransactionList, setWalletTransactionList] = useState([]);

      // Wallet form data
      const [transactionType, setTransactionType] = useState("credit");
      const [walletOwnerId, setWalletOwnerId] = useState("");
      const [reason, setReason] = useState("refund");
      const [walletAmount, setWalletAmount] = useState(0);
      const [comment, setComment] = useState("");
      const [orderNumber, setOrderNumber] = useState("");
      const [walletFormError, setWalletFormError] = useState({
        walletAmount: false,
        comment: false,
        reason: false,
        transactionType: false,
      });
      const [orderToAddWallet, setOrderToAddWallet] = useState({});
      const [isWalletRequesting, setIsWalletRequesting] = useState(false);

      // toast states
      const [toastBgColor, setToastBgColor] = useState('bg-success');
      const [showToast, setShowToast] = useState(false);
      const [toastMessage, setToastMessage] = useState("Delete successful");

      // Wallet modal data
      const [isShowAddWalletTranModal, setIsShowAddWalletTranModal] =
          useState(false);
      const [isShowWalletTransactionModal, setIsShowWalletTransactionModal] =
          useState(false);

      // Sort
      const [orderField, setOrderField] = useState("status");
      const [orderType, setOrderType] = useState("ASC");
      const [orderTypeOpts, setOrderTypeOpts] = useState(["DESC", "ASC"]);


      // Filter states
      const [startDateFilter, setStartDateFilter] = useState("");
      const [endDateFilter, setEndDateFilter] = useState("");

      // Modal confirm send file
      const [isOpenModalConfirmSendFile, setIsOpenModalConfirmSendFile] = useState(false);
      const [isRequestingSendFile, setIsRequestingSendFile] = useState(false);
      const [orderIdToSendFile, setOrderIdToSendFile] = useState("");

      // Modal confirm send pickup file
      const [isOpenModalConfirmPickupFile, setIsOpenModalConfirmPickupFile] = useState(false);
      const [isRequestingSendPickupFile, setIsRequestingSendPickupFile] = useState(false);

      // Modal confirm send multi invoice/order file
      const [isOpenModalConfirmSendMultiInvoiceFile, setIsOpenModalConfirmfSendMultiInvoiceFile] = useState(false);
      const [isRequestingSendMultiInvoiceFile, setIsRequestingSendMultiInvoiceFile] = useState(false);

      // ------------------ Methods ------------------
      const getOrderList = (param) => {
        setIsRequesting(true);
        let tmpStatus = _.clone(status);
        let tmpNumber = tmpStatus
            .toString()
            .split("")
            .map((x) => parseInt(x));
        let statusList = tmpNumber;

        let query = {
          statusList: JSON.stringify(statusList),
          search: textSearch,
          startDate,
          endDate,
          orderField,
          orderType
        };

        if (selectedBasketType === "0" || selectedBasketType === "1") {
          query.type = selectedBasketType;
        }

        if ([45, 6, 7, 2345678].includes(status)) {
          query.next = param.toString();
          query.limit = limit.toString();

          API.getOrderListPagination(query)
              .then((response) => response.json())
              .then((response) => {
                setIsRequesting(false);

                if (response.success) {
                  getOrderStatusCount();

                  setTotalNumberList(response.data.total);
                  setOrderList(response.data.list);

                  if (response.data.next) {
                    setNext(response.data.next);
                  } else if (response.data.list.length) {
                    setNext(Number(param) > 0 ? next + response.data.list.length : response.data.list.length);
                  } else {
                    setNext(0);
                  }
                }
              });
        } else {
          API.getOrderListWithoutPagination(query)
              .then((response) => response.json())
              .then((response) => {
                setIsRequesting(false);
                if (response.success) {
                  getOrderStatusCount();

                  if (status === 3) {
                    const tmpOrderList = response.data.list.filter(
                        (x) => !x.driver_id
                    );
                    setOrderList(tmpOrderList);
                  } else {
                    setOrderList(response.data.list);
                  }
                }
              });
        }
      };

      const getOrderListHasDriver = (params) => {
        setIsRequesting(true);
        let statusList = [status];

        let query = {
          statusList: JSON.stringify(statusList),
          search: textSearch,
          startDate,
          endDate,
          orderField,
          orderType
        };

        if (selectedBasketType === "0" || selectedBasketType === "1") {
          query.type = selectedBasketType;
        }

        API.getOrderListWithoutPagination(query)
            .then((response) => response.json())
            .then((response) => {
              setIsRequesting(false);

              if (response.success) {
                getOrderStatusCount();

                const tmpOrderList = response.data.list.filter((x) => x.driver_id);
                setOrderList(tmpOrderList);
              }
            });
      };

      const getDriverList = async () => {
        await API.getDriverList({next: 0, limit: 10000, showType: false})
            .then((response) => response.json())
            .then((response) => {
              if (response.success) {
                setDriverList(response.data.list);
                setIsOpenModalAddDriver(true);
              }
            });
      };

      const getOrderStatusCount = () => {
        setIsRequesting(true);

        API.getOrderStatusCount()
            .then((response) => response.json())
            .then((response) => {
              setIsRequesting(false);
              if (response.success) {
                setOrderStatusCount(response.data);
              }
            });
      };

      const getOrderPayment = (order) => {
        return order && order.order_payment_detail
            ? order.order_payment_detail
            : order.order_payment_details[0];
      };

      const getOnGoingSlot = (order) => {
        if (order && order.store_on_going_slot) {
          return order.store_on_going_slot;
        } else if (
            order &&
            order.store_on_going_slot &&
            order.store_on_going_slot.length
        ) {
          return order.store_on_going_slots[0];
        } else {
          return "";
        }
      };

      const getFormatDateForDisplay = (date) => {
        return moment(date).format("DD/MM/YYYY - h:mm a");
      };

      const getFormatCurrencyForDisplay = (number) => {
        return new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "USD",
        }).format(number);
      };

      const getWalletTransactionByCustomerId = (order) => {
        try {
          setIsWalletRequesting(true);
          API.getWalletTransactionByCustomerId({
            customerId: order.cust_id,
            next: 0,
            limit: 10,
          })
              .then((res) => res.json())
              .then((res) => {
                setIsWalletRequesting(false);
                if (res.success) {
                  setWalletTransactionList(res.data.list);
                }
              });
        } catch (e) {
          console.log("Error, can not find wallet transaction", e);
          setWalletTransactionList([]);
          setIsShowWalletTransactionModal(false);
        }
      };

      const resetWalletFormData = () => {
        setWalletAmount(0);
        setComment("");
        setReason("refund");
        setTransactionType("credit");
      };

      const resetWalletFormError = () => {
        setWalletFormError({
          walletAmount: false,
          comment: false,
          transactionType: false,
          reason: false,
        });
      };

      const resetDateFilter = () => {
        setStartDateFilter(null);
        setEndDateFilter(null);
        if (startDateFilterRef && startDateFilterRef.current) {
          startDateFilterRef.current.state.inputValue = "";
        }
        if (endDateFilterRef && endDateFilterRef.current) {
          endDateFilterRef.current.state.inputValue = "";
        }

        // reset query params
        setStartDate("");
        setEndDate("");
      }

      const resetPagination = () => {
        setPager(1);
        setPrev(0);
      }

      const getOrderStatusCountByLabel = (label) => {
        if (!_.isEmpty(orderStatusCount)) {
          if (label === "New") {
            return orderStatusCount.pending ? `(${orderStatusCount.pending})` : "";
          } else if (label === "Accepted") {
            return orderStatusCount.processingNoDriver
                ? `(${orderStatusCount.processingNoDriver})`
                : "";
          } else if (label === "Assigned to driver") {
            return orderStatusCount.processingDriver
                ? `(${orderStatusCount.processingDriver})`
                : "";
          } else if (label === "Packed & On the way") {
            return orderStatusCount.pickUp || orderStatusCount.delivering
                ? `(${orderStatusCount.pickUp + orderStatusCount.delivering})`
                : "";
          } else if (label === "Arrived") {
            return orderStatusCount.arrived ? `(${orderStatusCount.arrived})` : "";
          } else if (label === "Cancelled") {
            return orderStatusCount.cancel ? `(${orderStatusCount.cancel})` : "";
          } else if (label === "Delivered") {
            return orderStatusCount.delivered ? `(${orderStatusCount.delivered})` : "";
          } else {
            return orderStatusCount.all ? `(${orderStatusCount.all})` : "";
          }
        }
      };

      // ----------------- Compute data ----------------
      useEffect(() => {
        if (status === 3 && isShowOnlyAcceptedHasDriver) {
          getOrderListHasDriver(0);
        } else {
          getOrderList(0);
        }

        if (status === 3 && !isShowOnlyAcceptedHasDriver) {
          setIsAllowAddDriver(true);
        } else {
          setIsAllowAddDriver(false);
        }
      }, [status, isShowOnlyAcceptedHasDriver, selectedBasketType, textSearch, startDate, endDate, orderField, orderType]);

//   useEffect(() => {
//     if (status === 3 && isShowOnlyAcceptedHasDriver) {
//       getOrderListHasDriver(0);
//     } else {
//       getOrderList(0);
//     }
//   }, [selectedBasketType]);

      // ---------------- Event change -----------------
      const handleSelectOrderStatus = (e) => {
        // reset date filter
        resetDateFilter();

        // reset pagination
        resetPagination();

        let data = orderStatusOpts[e.target.value].value;
        setSelectedStatus(e.target.value);
        handleGetOrderByStatus(data);
      };

      const handleOrderTypeChange = (e) => {
        // reset date filter
        resetDateFilter();

        // reset pagination
        resetPagination();

        setSelectedBasketType(e.target.value);
      };

      const handleGetOrderByStatus = ({status, isShowAccptedHasDriver}) => {
        setIsShowOnlyAcceptedHasDriver(isShowAccptedHasDriver);
        if (status === 2345678) {
          setAccept(true);
          setDispatch(true);
          setIsAllowAddDriver(true);
          setSelectedStatus(0);
        }
        else if (status === 2) {
          setAccept(true);
          setDispatch(false);
          setIsAllowAddDriver(false);
          setSelectedStatus(1);
        } else {
          if (status === 4 || status === 3) {
            setAccept(false);
            setDispatch(true);
            if (status === 3 && isShowAccptedHasDriver) {
              setSelectedStatus(3);
            } else {
              setSelectedStatus(2);
            }
          } else {
            setAccept(false);
            setDispatch(false);

            if (status === 8) {
              setSelectedStatus(6);
            } else if (status === 6) {
              setSelectedStatus(5)
            }
            else if (status === 7) {
              setSelectedStatus(7);
            } else if (status === 45) {
              setSelectedStatus(4);
            }
          }
        }
        setOrderIDs([]);
        setStatus(status);
      };

      const bulkSelection = (value) => {
        let tmp = [...orderIDs];
        if (tmp.filter((id) => id == value).length > 0) {
          setOrderIDs(tmp.filter((id) => id != value));
          if (tmp.filter((id) => id != value).length < 1) setIsSelected(false);
        } else {
          tmp.push(value);
          setOrderIDs(tmp);
          setIsSelected(true);
        }
      };

      const bulkSelectionAll = () => {
        if(orderList && orderList.length){
          if(orderIDs.length){
            const isSelectedAll = orderList.every(x => orderIDs.includes(x.order_id));
            const isSelectedSome = orderList.some(x => orderIDs.includes(x.order_id));

            if(isSelectedAll || isSelectedSome){
              const orderListId = orderList.map(x => x.order_id);
              const selectedAllOrderId = orderIDs.filter(x => !orderListId.includes(x));
              setOrderIDs(selectedAllOrderId);
              if(!selectedAllOrderId.length){
                setIsSelected(false)
              }
            }else {
              const selectedAllOrderId = orderList.map(x => x.order_id);
              setOrderIDs(prevState => ([...prevState, ...selectedAllOrderId]));
              setIsSelected(true);
            }
          }else{
            const selectedAllOrderId = orderList.map(x => x.order_id);
            setOrderIDs(prevState => ([...prevState, ...selectedAllOrderId]));
            setIsSelected(true);
          }
        }
      }

      const handleChangeStatus = (id, statu) => {
        handleCloseConfirmModal();

        API.changeOrderStatus({
          order_id: id,
          order_status: statu,
          store_id: 1,
        }).then(() => {
          if (status === 3 && isShowOnlyAcceptedHasDriver) {
            getOrderListHasDriver(0);
          } else {
            resetPagination();
            getOrderList(0);
          }
        });
      };

      const handleGenerateInvoice = (orderId) => {
        setIsRequestingSendFile(true);
        API.generateInvoice({
          order_id: orderId,
        }).then(() => {
          alert("Generating Invoice and Sending");
          setIsRequestingSendFile(false);
          handleCloseModalConfirmSendFile()
        });
      };

      const handleSortOrder = (field) => {
        // reset pagination
        resetPagination();

        if (field === orderField) {
          setOrderType(orderTypeOpts.find(x => x !== orderType));
        } else {
          setOrderType("ASC");
          setOrderField(field);
        }
      };

      const handleAmountSort = () => {
        if (orderList.length) {
          orderList.sort(function (a, b) {
            const aHas =
                a && a.order_payment_detail
                    ? typeof a.order_payment_detail.to_pay !== "undefined"
                    : false;
            const bHas =
                b && b.order_payment_detail
                    ? typeof b.order_payment_detail.to_pay !== "undefined"
                    : false;
            if (sortFlag)
              return aHas && bHas
                  ? b.order_payment_detail.to_pay - a.order_payment_detail.to_pay
                  : 0;
            else
              return aHas && bHas
                  ? a.order_payment_detail.to_pay - b.order_payment_detail.to_pay
                  : 0;
          });
          setOrderArrow(false);
          setAmountArrow(true);
          setDateArrow(false);
          setSortFlag(!sortFlag);
        }
      };

      const handleDateSort = () => {
        if (orderList.length) {
          orderList.sort(function (a, b) {
            const aHas =
                a && a.store_on_going_slot
                    ? typeof a.store_on_going_slot.start_time !== "undefined"
                    : false;
            const bHas =
                b && b.store_on_going_slot
                    ? typeof b.store_on_going_slot.start_time !== "undefined"
                    : false;
            if (sortFlag) {
              return bHas && aHas
                  ? new Date(b.store_on_going_slot.start_time) -
                  new Date(a.store_on_going_slot.start_time)
                  : 0;
            } else {
              return aHas && bHas
                  ? new Date(a.store_on_going_slot.start_time) -
                  new Date(b.store_on_going_slot.start_time)
                  : 0;
            }
          });
          setOrderArrow(false);
          setAmountArrow(false);
          setDateArrow(true);
          setSortFlag(!sortFlag);
        }
      };

      const handleBulkSubmit = (statu) => {
        let idStr = "" + orderIDs[0];
        for (let i = 1; i < orderIDs.length; ++i) idStr = idStr + "|" + orderIDs[i];

        API.changeBulkOrderStatus({
          order_ids: idStr,
          order_status: statu,
        }).then(() => {
          setIsSelected(false);
          setOrderIDs([]);

          if (status === 3 && isShowOnlyAcceptedHasDriver) {
            getOrderListHasDriver(0);
          } else {
            resetPagination();
            getOrderList(0);
          }
        });
      };

      const handlePickup = () => {
        setIsRequestingSendPickupFile(true);
        let idStr = "" + orderIDs[0];
        for (let i = 1; i < orderIDs.length; ++i) idStr = idStr + "|" + orderIDs[i];

        API.createPickupFile({
          order_ids: idStr,
        }).then(() => {
          alert("Generating pickup file and Sending");
          setIsRequestingSendPickupFile(false);
          window.location.reload();
        });
      };

      const handleBulkGenerateInvoice = () => {
        setIsRequestingSendMultiInvoiceFile(true);
        let idStr = "" + orderIDs[0];
        for (let i = 1; i < orderIDs.length; ++i) idStr = idStr + "|" + orderIDs[i];

        API.generateBulkInvoice({
          order_ids: idStr,
        }).then(() => {
          alert("Generating Invoice and Sending");
          setIsRequestingSendMultiInvoiceFile(false);
          window.location.reload();
        });
      };

      const handlePaginNext = () => {
        const {next, prev} = updatePaginationProps("NEXT");

        getOrderList(prev);
      };

      const handlePaginPrev = () => {
        const {pager, next, prev} = updatePaginationProps("PREV");

        getOrderList(prev);
      };

      const updatePaginationProps = (type) => {
        let tmpPager = pager + 1;
        if (type === "PREV") {
          tmpPager = pager - 1;
        }

        const tmpNext = limit * tmpPager;
        const tmpPrev = limit * tmpPager - limit;

        setPager(tmpPager);
        setPrev(tmpPrev);

        return {pager: tmpPager, next: tmpNext, prev: tmpPrev};
      };

      const handleOpenModalAddDriver = (order) => {
        setOrderUpdate(order);
        getDriverList();
      };

      const handleOpenModalEditDriver = (order) => {
        setSelectedDriver(order.driver_id);
        setOrderUpdate(order);
        getDriverList();
      };

      const handleCloseModalAddDriver = () => {
        setIsOpenModalAddDriver(false);
        setSelectedDriver("");
        setDriverList([]);
      };

      const handleSubmit = () => {
        setIsRequestingUpsert(true);
        setIsOpenModalAddDriver(false);
        const params = {
          orderId: orderUpdate.order_id,
        };

        const body = {
          driver_id: selectedDriver,
        };
        API.updateOrder(params, body)
            .then((response) => response.json())
            .then((response) => {
              setIsRequestingUpsert(false);
              if (response.success) {
                if (status === 3 && isShowOnlyAcceptedHasDriver) {
                  getOrderListHasDriver(0);
                } else {
                  resetPagination();
                  getOrderList(0);
                }

                setOrderUpdate({});
                setSelectedDriver("");
                setDriverList([]);
                handleShowToast("SUCCESS");
              } else {
                handleShowToast("ERROR");
              }
            });
      };

      const handleSelectDriver = (driverId) => {
        setSelectedDriver(driverId);
      };

      const handleOpenConfirmModal = (orderId) => {
        setIsShowConfirmModal(true);
        setOrderIdToCancel(orderId);
      };

      const handleCloseConfirmModal = () => {
        setIsShowConfirmModal(false);
        setOrderIdToCancel("");
      };

      const handleOpenAddWalletTransactionModal = (order) => {
        setOrderToAddWallet(order);
        setIsShowAddWalletTranModal(true);
      };

      const handleCloseAddWalletTransactionModal = () => {
        setIsShowAddWalletTranModal(false);
        resetWalletFormData();
        resetWalletFormError();
        setOrderToAddWallet({});
      };

      const handleSummitAddWallet = () => {
        setIsWalletRequesting(true);

        if (walletAmount > 0) {
          const body = {
            transaction_type: transactionType,
            wallet_owner_id: orderToAddWallet.cust_id,
            reason,
            wallet_amount: Number(walletAmount),
            comment,
            order_number: orderToAddWallet.order_id,
          };

          API.addWalletTransaction(body)
              .then((response) => response.json())
              .then((response) => {
                setIsWalletRequesting(false);

                if (response.success) {
                  handleCloseAddWalletTransactionModal();
                  handleShowToast("SUCCESS");
                } else {
                  handleShowToast("ERROR");
                }
              });
        } else {
          if (!walletAmount || !(walletAmount > 0)) {
            setWalletFormError((prevState) => ({
              ...prevState,
              walletAmount: true,
            }));
          }

          setIsWalletRequesting(false);
        }
      };

      const handleViewWalletTransaction = (order) => {
        setOrderToViewWallet(order);
        handleOpenWalletTransactionModal();
        getWalletTransactionByCustomerId(order);
      };

      const handleOpenWalletTransactionModal = () => {
        setIsShowWalletTransactionModal(true);
      };

      const handleCloseWalletTransactionModal = () => {
        setWalletTransactionList([]);
        setOrderToViewWallet({});
        setIsShowWalletTransactionModal(false);
      };

      // Filters event handler
      const handleChangeStartDate = ((event) => {
        let tmpEvent = moment(event).format('YYYY-MM-DD');
        setStartDateFilter(tmpEvent);

        if (endDateFilter) {
          if (
              moment(endDateFilter, "YYYY/MM/DD").isBefore(moment(tmpEvent, "YYYY/MM/DD"))
          ) {
            setEndDateFilter("");
            endDateFilterRef.current.state.inputValue = "";
          }
        }
      });

      const handleChangeEndDate = ((event) => {
        let tmpEvent = moment(event).format('YYYY-MM-DD');
        setEndDateFilter(tmpEvent);
        endDateFilterRef.current.state.inputValue = tmpEvent;

        if (startDateFilter) {
          if (
              moment(tmpEvent, "YYYY/MM/DD").isBefore(moment(startDateFilter, "YYYY/MM/DD"))
          ) {
            setStartDateFilter("");
            startDateFilterRef.current.state.inputValue = "";
          }
        }
      });

      const handleFilter = () => {
        // reset pagination
        resetPagination();

        let cloneStartDateFilter = _.clone(startDateFilter);
        let cloneEndDateFilter = _.clone(endDateFilter);
        setStartDate(cloneStartDateFilter);
        setEndDate(cloneEndDateFilter);
      }

      const handleClearFilter = () => {
        // reset pagination
        resetPagination();

        // reset filter
        resetDateFilter();
      }

      // toast event
      const handleShowToast = (type) => {
        setShowToast(true);

        if (type === "SUCCESS") {
          setToastBgColor("bg-success");
          setToastMessage("Operation successful.");
        } else if (type === "ERROR") {
          setToastBgColor("bg-danger");
          setToastMessage("Operation error.");
        }
      }

      // modal confirm send file
      const handleOpenModalConfirmSendFile = (orderId) => {
        setIsOpenModalConfirmSendFile(true);
        setOrderIdToSendFile(orderId)
      }

      const handleCloseModalConfirmSendFile = () => {
        setIsOpenModalConfirmSendFile(false);
        setOrderIdToSendFile("");
      }

      // set time before call api
      let typingTimer; //timer identifier
      let doneTypingInterval = 1000; //time in ms (7.5 seconds)
      const handleSearch = (value) => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(function () {
          // reset date filter
          resetDateFilter();

          // reset pagination
          resetPagination();

          setTextSearch(value);
        }, doneTypingInterval);
      };

      // ---------------- Custom components -----------
      let content = null;
      if (orderList && orderList.length) {
        content = orderList.map((order, index) => (
            <tr key={index}>
              {
                status === 2345678 &&
                <td>
                  {
                    order.status === 2 ?
                        <Button variant="success"
                                className="text-dark"
                                style={{
                                  backgroundColor: "#ED9704",
                                  width: "12vh",
                                  border: "none",
                                  cursor: "auto",
                                  outline: "none",
                                  boxShadow: "none"
                                }}
                        >
                          New
                        </Button> :
                        order.status === 3 ?
                            <Button variant="success"
                                    className="text-dark"
                                    style={{
                                      backgroundColor: "#F30A0B",
                                      width: "12vh",
                                      border: "none",
                                      cursor: "auto",
                                      outline: "none",
                                      boxShadow: "none"
                                    }}
                            >
                              Accepted
                            </Button> :
                            order.status === 4 ?
                                <Button variant="success"
                                        className="text-dark"
                                        style={{
                                          backgroundColor: "#4D4C5C",
                                          width: "12vh",
                                          border: "none",
                                          cursor: "auto",
                                          outline: "none",
                                          boxShadow: "none"
                                        }}
                                >
                                  Packed
                                </Button> :
                                order.status === 5 ?
                                    <Button variant="success"
                                            className="text-dark"
                                            style={{
                                              backgroundColor: "#56E76F",
                                              width: "12vh",
                                              border: "none",
                                              cursor: "auto",
                                              outline: "none",
                                              boxShadow: "none"
                                            }}
                                    >
                                      On the way
                                    </Button> :
                                    order.status === 6 ?
                                        <Button variant="success"
                                                className="text-dark"
                                                style={{
                                                  backgroundColor: "#49A641",
                                                  width: "12vh",
                                                  border: "none",
                                                  cursor: "auto",
                                                  outline: "none",
                                                  boxShadow: "none"
                                                }}
                                        >
                                          Delivered
                                        </Button> :
                                        order.status === 7 ?
                                            <Button variant="success"
                                                    className="text-dark"
                                                    style={{
                                                      backgroundColor: "#A10559",
                                                      width: "12vh",
                                                      border: "none",
                                                      cursor: "auto",
                                                      outline: "none",
                                                      boxShadow: "none"
                                                    }}
                                            >
                                              Cancelled
                                            </Button> :
                                            order.status === 8 ?
                                                <Button variant="success"
                                                        className="text-dark"
                                                        style={{
                                                          backgroundColor: "#7E481C",
                                                          width: "12vh",
                                                          border: "none",
                                                          cursor: "auto",
                                                          outline: "none",
                                                          boxShadow: "none"
                                                        }}
                                                >
                                                  Arrived
                                                </Button> : null


                  }
                </td>
              }
              <td>
                <Checkbox
                    checked={orderIDs.includes(order.order_id)}
                    key={order.order_id}
                    onChange={() => bulkSelection(order.order_id)}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />

                {/*<CheckBox*/}
                    {/*key={order.order_id}*/}
                    {/*id={order.order_id}*/}
                    {/*bulkSelection={bulkSelection}*/}
                {/*/>*/}
              </td>
              <td>
                {order.type === 1 ?
                    <img src={require("../../assets/delixpress_logo.svg")} width="40px"/> :
                    <img src={require("../../assets/delishop_logo.svg")} width="40px"/>
                }

              </td>
              <td>
                <Link
                    target="_blank"
                    to={{pathname: "/admin/order/details/" + order.order_id}}
                >
                  {order.order_id}
                </Link>
              </td>
              < td> {order.customer_detail ? order.customer_detail.name : ""}</td>
              <td className="d-flex justify-content-center">
                <RemoveRedEye style={{cursor: "pointer"}} className="text-primary"
                              onClick={() => handleViewWalletTransaction(order)}></RemoveRedEye>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Edit style={{cursor: "pointer"}} className="text-success"
                      onClick={() => handleOpenAddWalletTransactionModal(order)}></Edit>
              </td>
              {/*{status === 45 && (*/}
              {/*<td>*/}
              {/*<span>{order.status === 4 ? "PickedUp" : "Delivering"}</span>*/}
              {/*</td>*/}
              {/*)}*/}
              {/*<td>{getFormatDateForDisplay(order.placedAt)}</td>*/}
              <td className="text-right">
                {getFormatCurrencyForDisplay(getOrderPayment(order).to_pay)}
              </td>
              <td style={{width: "22vh"}}>
                {getOrderPayment(order).payment_method_name == "Paid online (ABA)" &&
                getOrderPayment(order).p_status
                    ? "Paid online (ABA) Payment failed/Ongoing"
                    : getOrderPayment(order).payment_method_name}
              </td>
              <td>{getFormatDateForDisplay(order.placedAt)}</td>
              <td>
                {getOnGoingSlot(order)
                    ? getFormatDateForDisplay(getOnGoingSlot(order).start_time)
                    : "ASAP"}
              </td>

              {/*<td>{order.type === 1 ? "Express" : "Normal"}</td>*/}
              {status !== 3 && !(accept || dispatch) && (
                  <td>
                            <span>
                              {order && order.driver && order.driver.first_name
                                  ? order.driver.first_name
                                  : ""}{" "}
                              {order && order.driver && order.driver.last_name
                                  ? order.driver.last_name
                                  : ""}
                            </span>
                  </td>
              )}
              {accept || dispatch ? (
                  <>
                    {dispatch && (
                        <td>
                          {order.status === 3 && !order.driver ? (
                              <Button
                                  variant="success"
                                  onClick={() => handleOpenModalAddDriver(order)}
                              >
                                Assign
                              </Button>
                          ) : order.driver && order.status === 3 && (status === 3 || status === 2345678) ? (
                              <span
                                  className="text-primary"
                                  role="button"
                                  onClick={() => handleOpenModalEditDriver(order)}
                              >
                                            {order && order.driver && order.driver.first_name
                                                ? order.driver.first_name
                                                : ""}{" "}
                                {order && order.driver && order.driver.last_name
                                    ? order.driver.last_name
                                    : ""
                                }
                                        </span>
                          ) : order.driver && status !== 3 ? (
                              <span>
                    {order && order.driver && order.driver.first_name
                        ? order.driver.first_name
                        : ""}{" "}
                                {order && order.driver && order.driver.last_name
                                    ? order.driver.last_name
                                    : ""}
                  </span>
                          ) : (
                              ""
                          )}
                        </td>
                    )}
                    {accept && (
                        <td>
                          {
                            order.status === 2 ?
                                <Button
                                    variant="success"
                                    onClick={() => handleChangeStatus(order.order_id, 3)}
                                >
                                  Accept
                                </Button> :
                                null
                          }

                        </td>
                    )}
                    <td>
                      {
                        order.status === 2 || order.status === 3 ?
                            <Button
                                className="bg-danger"
                                variant="danger"
                                onClick={() => handleOpenConfirmModal(order.order_id)}
                            >
                              Cancel
                            </Button> :
                            null
                      }

                    </td>

                    <td className="text-center">
                      {
                        order.status === 2 || order.status === 3 ?
                            <EmailIcon
                                style={{color: green[500], cursor: "pointer"}}
                                onClick={() => handleOpenModalConfirmSendFile(order.order_id)}
                            /> :
                            null
                      }

                    </td>
                  </>
              ) : (
                  <></>
              )}

            </tr>
        ));
      }

      const renderSelectAllCheckBox = () => {
        if(orderIDs.length && orderList.length){
          const isSelectedAll = orderList.every(x => orderIDs.includes(x.order_id));
          const isSelectedSome = orderList.some(x => orderIDs.includes(x.order_id));

          if(isSelectedAll){
            return (<Checkbox
                checked={isSelectedAll}
                onChange={() => bulkSelectionAll()}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />)
          }else if(isSelectedSome){
            return (<Checkbox
                checked={isSelectedSome}
                indeterminate
                color="primary"
                onChange={() => bulkSelectionAll()}
                inputProps={{ 'aria-label': 'indeterminate checkbox' }}
            />);
          }else{
            return (<Checkbox
                checked={false}
                onChange={() => bulkSelectionAll()}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />);
          }
        }else{
          return <Checkbox
              checked={false}
              onChange={() => bulkSelectionAll()}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        }
      }

// Pagination
      let pageItems = [
        <Pagination.Item disabled={next <= limit} key={0} onClick={handlePaginPrev}>
          Prev
        </Pagination.Item>
        ,
        <Form.Group key={1} controlId="formGridPagination">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>{`${next} / ${totalNumberList}`}</InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
        </Form.Group>
        ,
        <Pagination.Item disabled={Number(next) === Number(totalNumberList)} key={2} onClick={handlePaginNext}>
          Next
        </Pagination.Item>
        ,
      ];
      const PaginationBasic = () => (
          <div className="d-flex justify-content-center">
            <Pagination>{pageItems}</Pagination>
          </div>
      );

      return (
          <CustomModal>
            <HelpPageWrapper>
              {isSelected ? (
                  <Row className="mb-5">
                    <Col sm={9}>
                      <Row>
                        <Col>
                          <Button variant="success" onClick={() => handleBulkSubmit(2)}>
                            Mark as New
                          </Button>
                        </Col>

                        <Col>
                          <Button
                              variant="success"
                              onClick={() => handleBulkSubmit(3)}
                          >
                            Mark as Accept
                          </Button>
                        </Col>

                        <Col>
                          <Button variant="success" onClick={() => handleBulkSubmit(4)}>
                            Mark as Packed
                          </Button>
                        </Col>

                        <Col>
                          <Button
                              variant="success"
                              onClick={() => handleBulkSubmit(5)}
                          >
                            Mark as On The Way
                          </Button>
                        </Col>

                        <Col>
                          <Button variant="success" onClick={() => handleBulkSubmit(6)}>
                            Mark as Delivered
                          </Button>
                        </Col>

                        <Col>
                          <Button variant="success" onClick={() => handleBulkSubmit(8)}>
                            Mark as Arrived
                          </Button>
                        </Col>

                        <Col>
                          <Button variant="success" onClick={() => handleBulkSubmit(7)}>
                            Mark as Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Col>

                    <Col sm={3}>
                      {accept || dispatch ? (
                          <Row>
                            <Col>
                              <Button className="bg-danger"
                                      variant="danger" onClick={() => setIsOpenModalConfirmPickupFile(true)}>
                                Send Pickup File
                              </Button>
                            </Col>

                            <Col>
                              <Button className="bg-danger"
                                      variant="danger" onClick={() => setIsOpenModalConfirmfSendMultiInvoiceFile(true)}>
                                Send Invoice/Order File
                              </Button>
                            </Col>
                          </Row>
                      ): <></>}
                    </Col>
                  </Row>
              ) : (
                  // [
                  //     <div className="d-flex justify-content-between" key={1}>
                  //         <Button
                  //             disabled={isRequesting}
                  //             variant={status === 2345678 ? secondary : success}
                  //             type="submit"
                  //             className="mb-5"
                  //             onClick={() =>
                  //                 handleGetOrderByStatus({
                  //                     status: 2345678,
                  //                     isShowAccptedHasDriver: false,
                  //                 })
                  //             }
                  //         >
                  //             All {getOrderStatusCountByLabel("All")}
                  //         </Button>
                  //         <Button
                  //             disabled={isRequesting}
                  //             variant={status === 2 ? secondary : success}
                  //             type="submit"
                  //             className="mb-5"
                  //             onClick={() =>
                  //                 handleGetOrderByStatus({
                  //                     status: 2,
                  //                     isShowAccptedHasDriver: false,
                  //                 })
                  //             }
                  //         >
                  //             New {getOrderStatusCountByLabel("New")}
                  //         </Button>
                  //         <Button
                  //             disabled={isRequesting}
                  //             variant={
                  //                 status === 3 && !isShowOnlyAcceptedHasDriver
                  //                     ? secondary
                  //                     : success
                  //             }
                  //             type="submit"
                  //             className="mb-5"
                  //             onClick={() =>
                  //                 handleGetOrderByStatus({
                  //                     status: 3,
                  //                     isShowAccptedHasDriver: false,
                  //                 })
                  //             }
                  //         >
                  //             Accepted {getOrderStatusCountByLabel("Accepted")}
                  //         </Button>
                  //         <Button
                  //             disabled={isRequesting}
                  //             variant={
                  //                 status === 3 && isShowOnlyAcceptedHasDriver
                  //                     ? secondary
                  //                     : success
                  //             }
                  //             type="submit"
                  //             className="mb-5"
                  //             onClick={() =>
                  //                 handleGetOrderByStatus({
                  //                     status: 3,
                  //                     isShowAccptedHasDriver: true,
                  //                 })
                  //             }
                  //         >
                  //             Assigned to driver{" "}
                  //             {getOrderStatusCountByLabel("Assigned to driver")}
                  //         </Button>
                  //         <Button
                  //             disabled={isRequesting}
                  //             variant={status === 45 ? secondary : success}
                  //             type="submit"
                  //             className="mb-5"
                  //             onClick={() =>
                  //                 handleGetOrderByStatus({
                  //                     status: 45,
                  //                     isShowAccptedHasDriver: false,
                  //                 })
                  //             }
                  //         >
                  //             Packed & Dispatched{" "}
                  //             {getOrderStatusCountByLabel("Packed & Dispatched")}
                  //         </Button>
                  //         <Button
                  //             disabled={isRequesting}
                  //             variant={status == 8 ? secondary : success}
                  //             type="submit"
                  //             className="mb-5"
                  //             onClick={() =>
                  //                 handleGetOrderByStatus({
                  //                     status: 8,
                  //                     isShowAccptedHasDriver: false,
                  //                 })
                  //             }
                  //         >
                  //             Arrived {getOrderStatusCountByLabel("Arrived")}
                  //         </Button>
                  //         {/* <Button disabled={isRequesting} variant={status == 6 ? secondary : success} type="submit" className="mb-5" onClick={() => handleGetOrderByStatus(6, false)}>
                  //             Delivered {orderStatusCount.delivered ? "(" + orderStatusCount.delivered + ")" : ""}
                  //         </Button> */}
                  //         <Button
                  //             disabled={isRequesting}
                  //             variant={status == 7 ? secondary : success}
                  //             type="submit"
                  //             className="mb-5"
                  //             onClick={() =>
                  //                 handleGetOrderByStatus({
                  //                     status: 7,
                  //                     isShowAccptedHasDriver: false,
                  //                 })
                  //             }
                  //         >
                  //             Cancelled {getOrderStatusCountByLabel("Cancelled")}
                  //         </Button>
                  //     </div>,
                  //     <Row className="mb-4" key={2}>
                  //         <Col sm={8}>
                  //             {/*<Form.Control*/}
                  //                 {/*style={{width: "25rem"}}*/}
                  //                 {/*size="md"*/}
                  //                 {/*type="text"*/}
                  //                 {/*placeholder="Search customers"*/}
                  //                 {/*onChange={(e) => handleSearch(e.target.value)}*/}
                  //             {/*/>*/}
                  //             <Form.Control
                  //                 as="select"
                  //                 onChange={(e) => handleSelectOrderStatus(e)}
                  //                 value={selectedStatus}
                  //                 disabled={isRequesting}
                  //             >
                  //                 {orderStatusOpts.map((oStatus, index) => (
                  //                     <option value={index} key={index}>
                  //                         {oStatus.label}{" "}
                  //                         {getOrderStatusCountByLabel(oStatus.label)}
                  //                     </option>
                  //                 ))}
                  //             </Form.Control>
                  //             &nbsp;&nbsp;
                  //             <Datetime style={{width: "300px"}}
                  //                       value={startDate}
                  //                       dateFormat="YYYY-MM-DD"
                  //             />
                  //             &nbsp;&nbsp;
                  //             <Datetime style={{width: "300px"}}
                  //                       value={endDate}
                  //                       dateFormat="YYYY-MM-DD"
                  //             />
                  //             <Button
                  //                 variant="primary"
                  //                 className="btn-primary"
                  //                 onClick={console.log()}
                  //             >
                  //                 Filter
                  //             </Button>
                  //             <Button
                  //                 variant="info"
                  //                 className="btn-info"
                  //                 onClick={console.log()}
                  //             >
                  //                 Clear
                  //             </Button>
                  //         </Col>
                  //         <Col sm={4} className="d-flex justify-content-end">
                  //             <span style={labelStyle}>Filter: </span>
                  //             &nbsp;&nbsp;
                  //             <Form.Control
                  //                 as="select"
                  //                 onChange={(e) => setSelectedBasketType(e.target.value)}
                  //                 value={selectedBasketType}
                  //             >
                  //                 <option value={""}>All Type</option>
                  //                 <option value={"0"}>Normal</option>
                  //                 <option value={"1"}>Express</option>
                  //             </Form.Control>
                  //         </Col>
                  //     </Row>,
                  // ]
                  <Row className="mb-4" key={2}>
                    <Col sm={2}>
                      <Form.Control
                          as="select"
                          onChange={(e) => handleSelectOrderStatus(e)}
                          value={selectedStatus}
                          disabled={isRequesting}
                      >
                        {orderStatusOpts.map((oStatus, index) => (
                            <option value={index} key={index}>
                              {oStatus.label}{" "}
                              {getOrderStatusCountByLabel(oStatus.label)}
                            </option>
                        ))}
                      </Form.Control>
                    </Col>

                    <Col sm={1} style={{padding: "0px"}}>
                      <Form.Control
                          as="select"
                          onChange={(e) => handleOrderTypeChange(e)}
                          value={selectedBasketType}
                          disabled={isRequesting}
                      >
                        <option value={""}>All Type</option>
                        <option value={"0"}>Normal</option>
                        <option value={"1"}>Express</option>
                      </Form.Control>
                    </Col>
                    <Col sm={2}>
                      <Datetime style={{width: "300px"}}
                                ref={startDateFilterRef}
                                disabled={isRequesting}
                                value={startDateFilter ? startDateFilter : ""}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                closeOnSelect={true}
                                inputProps={{placeholder: 'Start Delivering Date'}}
                                onChange={handleChangeStartDate}
                      />
                    </Col>

                    <Col sm={2} style={{padding: "0px"}}>
                      <Datetime style={{width: "300px"}}
                                disabled={isRequesting}
                                ref={endDateFilterRef}
                                closeOnSelect={true}
                                value={endDateFilter ? endDateFilter : ""}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                inputProps={{placeholder: 'End Delivering Date'}}
                                onChange={handleChangeEndDate}
                      />
                    </Col>
                    <Col sm={2}>
                      <Button
                          variant="primary"
                          className="btn-primary"
                          onClick={handleFilter}
                          disabled={isRequesting}
                      >
                        <Filter></Filter>&nbsp;&nbsp;Filter
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                          variant="info"
                          className="btn-info"
                          disabled={isRequesting}
                          onClick={handleClearFilter}
                      >
                        Clear
                      </Button>
                    </Col>
                    <Col sm={3} className="d-flex justify-content-end">
                      <Form.Control
                          style={{width: "18rem"}}
                          size="md"
                          type="text"
                          disabled={isRequesting}
                          placeholder="Search order id, customers or amount"
                          onChange={(e) => handleSearch(e.target.value)}
                      />
                    </Col>
                  </Row>
              )}

              <Row>
                <Col sm={12}>
                  {isRequesting ? (
                      <div style={{position: "fixed", left: "55%", top: "35%"}}>
                        {" "}
                        <Loader color="#389244" h="100"/>{" "}
                      </div>
                  ) : (
                      <TableBodyWidth
                          className="table-responsive"
                          style={{overflowY: "hidden"}}
                      >
                        <Table striped bordered hover>
                          <thead>
                          <tr>
                            {
                              status === 2345678 &&
                              <th style={{minWidth: "12vh", cursor: "pointer"}} onClick={() => handleSortOrder("status")}>
                                Status{" "}
                                {orderField === "status" ? (orderType === "DESC" ? DownArrow : UpArrow) : UpDownArrow}
                              </th>
                            }
                            <th>
                              {renderSelectAllCheckBox()}
                            </th>
                            <th></th>
                            <th onClick={() => handleSortOrder("order_id")} style={{cursor: "pointer"}}>
                              Order ID{" "}
                              {orderField === "order_id" ? (orderType === "DESC" ? DownArrow : UpArrow) : UpDownArrow}
                            </th>
                            <th onClick={() => handleSortOrder("customer_name")} style={{cursor: "pointer"}}>
                              Customer{" "}
                              {orderField === "customer_name" ? (orderType === "DESC" ? DownArrow : UpArrow) : UpDownArrow}
                            </th>
                            <th
                                style={{minWidth: "10vh"}}
                                className="text-center"
                            >
                            </th>
                            {/*{status === 45 && <th>Status</th>}*/}
                            {/*<th>Order Date</th>*/}
                            <th onClick={() => handleSortOrder("to_pay")} style={{cursor: "pointer"}}>
                              Amount{" "}
                              {orderField === "to_pay" ? (orderType === "DESC" ? DownArrow : UpArrow) : UpDownArrow}
                            </th>
                            <th style={{width: "22vh"}} onClick={() => handleSortOrder("payment_method_name")} style={{cursor: "pointer"}}>
                              Payment Type{" "}
                              {orderField === "payment_method_name" ? (orderType === "DESC" ? DownArrow : UpArrow) : UpDownArrow}
                            </th>
                            <th>Order Date</th>
                            <th onClick={() => handleSortOrder("start_time")} style={{cursor: "pointer"}}>
                              Delivery Date/Time{" "}
                              {orderField === "start_time" ? (orderType === "DESC" ? DownArrow : UpArrow) : UpDownArrow}
                            </th>
                            {/*<th>Type</th>*/}
                            {/*<th>Address</th>*/}
                            {
                              status !== 3 && !(accept || dispatch) &&
                              <th className="text-center" onClick={() => handleSortOrder("driver_name")} style={{cursor: "pointer"}}>
                                Assign To{" "}
                                {orderField === "driver_name" ? (orderType === "DESC" ? DownArrow : UpArrow) : UpDownArrow}
                              </th>
                            }
                            {accept || dispatch ? (
                                <>
                                  {dispatch && <th className="text-center">Assign To</th>}
                                  {accept && <th className="text-center">Accept</th>}
                                  <th>Cancel</th>
                                  <th className="text-center" style={{maxWidth: "10vh"}}>
                                    <span>Invoice<br/>/Order file</span></th>
                                </>
                            ) : (
                                <></>
                            )}
                            {/*{!(accept || dispatch) ? (*/}
                            {/*<>*/}
                            {/*<th*/}
                            {/*style={{minWidth: "18vh"}}*/}
                            {/*className="text-center"*/}
                            {/*>*/}
                            {/*Cust. Wallet*/}
                            {/*</th>*/}
                            {/*</>*/}
                            {/*) : (*/}
                            {/*<></>*/}
                            {/*)}*/}
                          </tr>
                          </thead>
                          <tbody>
                          {orderList && orderList.length ? (
                              content
                          ) : (
                              <tr>
                                <td colSpan={14} className="text-center">
                                  No data
                                </td>
                              </tr>
                          )}
                          </tbody>
                        </Table>
                      </TableBodyWidth>
                  )}

                  <Modal
                      show={isOpenModalAddDriver}
                      onHide={handleCloseModalAddDriver}
                      aria-labelledby="contained-modal-title-vcenter"
                      size="xl"
                      centered
                      className="bd-example-modal-xl"
                  >
                    <Form noValidate validated={validated} autoComplete="false">
                      <Modal.Header closeButton>
                        <Modal.Title>Add driver</Modal.Title>
                      </Modal.Header>
                      <Modal.Body key="modalBody">
                        {driverList.length ? (
                            <Row>
                              {driverList.map((driver, index) => (
                                  <Col sm={3} key={driver.id}>
                                    <input
                                        type="radio"
                                        id={`custom-checkbox-${driver.id}`}
                                        name={driver.id}
                                        value={driver.id}
                                        onChange={() => handleSelectDriver(driver.id)}
                                        checked={driverList[index].id === selectedDriver}
                                    />
                                    <label htmlFor={`custom-checkbox-${driver.id}`}>
                                      &nbsp;&nbsp;{driver.first_name} {driver.last_name}
                                    </label>
                                  </Col>
                              ))}
                            </Row>
                        ) : null}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={handleSubmit} disabled={isRequestingUpsert}>
                          {
                            isRequestingUpsert ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> :
                                null
                          }

                          <span className="pl-2 pr-2">Add</span>
                        </Button>
                        <Button
                            variant="secondary"
                            disabled={isRequestingUpsert}
                            onClick={handleCloseModalAddDriver}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>

                  {/* Modal confirm cancel */}
                  <Modal
                      show={isShowConfirmModal}
                      animation={false}
                      onHide={handleCloseConfirmModal}
                      aria-labelledby="contained-modal-title-vcenter"
                      size="xs"
                      centered
                  >
                    <Modal.Header closeButton className="bg-danger">
                      <Modal.Title className="text-white">Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body key="modalBody" className="text-center">
                      <Form.Label>Do you wnat to cancel the order?</Form.Label>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                      <Button
                          variant="danger"
                          onClick={(e) => handleChangeStatus(orderIdToCancel, 7)}
                      >
                        OK
                      </Button>
                      <Button variant="secondary" onClick={handleCloseConfirmModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* Modal add wallet */}
                  <Modal
                      show={isShowAddWalletTranModal}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      size="xs"
                      onHide={handleCloseAddWalletTransactionModal}
                  >
                    <Form noValidate validated={validated} autoComplete="false">
                      <Modal.Header closeButton>
                        <Modal.Title>Update funds</Modal.Title>
                      </Modal.Header>
                      <Modal.Body key="modalBody">
                        <Form.Group controlId="formTransactionType">
                          <Form.Label>Transaction Type</Form.Label>
                          <InputGroup hasvalidation="true">
                            <Form.Control
                                as="select"
                                onChange={(e) => setTransactionType(e.target.value)}
                                value={transactionType}
                                isInvalid={walletFormError.transactionType}
                                required
                                disabled={isWalletRequesting}
                                placeholder="Select"
                            >
                              <option value={"credit"}>Credit</option>
                              <option value={"debit"}>Debit</option>
                            </Form.Control>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="amount">
                          <Form.Label>Amount(USD)</Form.Label>
                          <InputGroup hasvalidation="true">
                            <Form.Control
                                isInvalid={walletFormError.walletAmount}
                                type="number"
                                placeholder="00.00 USD"
                                value={walletAmount}
                                disabled={isWalletRequesting}
                                onChange={(e) => setWalletAmount(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="reason">
                          <Form.Label>Reason</Form.Label>
                          <InputGroup hasvalidation="true">
                            <Form.Control
                                as="select"
                                onChange={(e) => setReason(e.target.value)}
                                value={reason}
                                isInvalid={walletFormError.reason}
                                required
                                disabled={isWalletRequesting}
                                placeholder="Select"
                            >
                              <option value={"marketing"}>Marketing</option>
                              <option value={"order"}>Order</option>
                              <option value={"refund"}>Refund</option>
                              <option value={"other"}>Other</option>
                            </Form.Control>
                          </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="comment">
                          <Form.Label>Comment</Form.Label>
                          <InputGroup hasvalidation="true">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                disabled={isWalletRequesting}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button disabled={isWalletRequesting}
                                variant="primary"
                                onClick={handleSummitAddWallet}>
                          {
                            isWalletRequesting ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> :
                                null
                          }

                          <span className="pl-2 pr-2">Update</span>
                        </Button>

                        <Button
                            disabled={isWalletRequesting}
                            variant="secondary"
                            onClick={handleCloseAddWalletTransactionModal}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>

                  {/* Modal display wallet list */}
                  <Modal
                      show={isShowWalletTransactionModal}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      size="lg"
                      onHide={handleCloseWalletTransactionModal}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <Row>
                          <Col sm={12}>
                            Wallet Historic of customer :{" "}
                            {orderToViewWallet && orderToViewWallet.customer_detail
                                ? orderToViewWallet.customer_detail.name
                                : ""}
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12}>
                            (
                            {orderToViewWallet && orderToViewWallet.customer_detail && orderToViewWallet.customer_detail.wallet
                                ? getFormatCurrencyForDisplay(
                                    orderToViewWallet.customer_detail.wallet
                                        .wallet_amount
                                )
                                : getFormatCurrencyForDisplay(0)}
                            )
                          </Col>
                        </Row>
                      </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <Table striped bordered hover>
                        <thead>
                        <tr>
                          <th>User</th>
                          <th>Type</th>
                          <th>Reason</th>
                          <th>Creation Date/Time</th>
                          <th className="text-center">Amount</th>
                        </tr>
                        </thead>

                        <tbody>
                        {walletTransactionList.length ? (
                            [
                              walletTransactionList.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.admin_info}</td>
                                    <td>{_.capitalize(item.transaction_type)}</td>
                                    <td>{_.capitalize(item.reason)}</td>
                                    <td>{getFormatDateForDisplay(item.createdAt)}</td>
                                    <td className="text-right">
                                      {getFormatCurrencyForDisplay(item.wallet_amount)}
                                    </td>
                                  </tr>
                              ))
                            ]
                        ) : isWalletRequesting ? (
                            <tr>
                              <td colSpan={14} className="text-center">
                                <Spinner animation="grow"/>
                              </td>
                            </tr>
                        ) : (
                            <tr>
                              <td colSpan={14} className="text-center">
                                No data
                              </td>
                            </tr>
                        )}
                        </tbody>
                      </Table>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button
                          disabled={isWalletRequesting}
                          variant="danger"
                          onClick={handleCloseWalletTransactionModal}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>
              {!isRequesting && [45, 6, 7, 2345678].includes(status) && (
                  <Row>
                    <Col sm={12}>
                      <PaginationBasic/>
                    </Col>
                  </Row>
              )}

              <Toast className={toastBgColor}
                     onClose={() => setShowToast(false)}
                     show={showToast}
                     autohide
                     style={{
                       position: 'fixed',
                       top: 70,
                       right: 35,
                       zIndex: 1041,
                       width: "300px"
                     }}
              >
                <Toast.Header>
                  <strong className="mr-auto m-3">{toastMessage}</strong>
                </Toast.Header>
              </Toast>

              {/*
                    Modal confirm invoice send file
                  */}
              <Dialog open={isOpenModalConfirmSendFile} onClose={handleCloseModalConfirmSendFile}>
                <DialogTitle>Send invoice/order file</DialogTitle>
                <DialogContent dividers>
                  <DialogContentText id="alert-dialog-description">
                    Do you want to send invoice/order file?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="primary" disabled={isRequestingSendFile}
                          onClick={(e) => handleGenerateInvoice(orderIdToSendFile)}>
                    {
                      isRequestingSendFile ?
                          <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                          /> :
                          null
                    }

                    <span className="pl-2 pr-2">Send</span>
                  </Button>

                  <Button disabled={isRequestingSendFile} variant="secondary"
                          onClick={handleCloseModalConfirmSendFile}>Close</Button>
                </DialogActions>
              </Dialog>

              {/*
                    Modal confirm send pick up
                  */}
              <Dialog open={isOpenModalConfirmPickupFile} onClose={() => setIsOpenModalConfirmPickupFile(false)}>
                <DialogTitle>Send pickup file</DialogTitle>
                <DialogContent dividers>
                  <DialogContentText id="alert-dialog-description">
                    Do you want to send pickup file?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="primary" disabled={isRequestingSendPickupFile} onClick={handlePickup}>
                    {
                      isRequestingSendPickupFile ?
                          <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                          /> :
                          null
                    }

                    <span className="pl-2 pr-2">Send</span>
                  </Button>

                  <Button disabled={isRequestingSendPickupFile} variant="secondary"
                          onClick={() => setIsOpenModalConfirmPickupFile(false)}>Close</Button>
                </DialogActions>
              </Dialog>

              {/*
                    Modal confirm send multi invoice/order file
                  */}
              <Dialog open={isOpenModalConfirmSendMultiInvoiceFile}
                      onClose={() => setIsOpenModalConfirmfSendMultiInvoiceFile(false)}>
                <DialogTitle>Send invoice/order file</DialogTitle>
                <DialogContent dividers>
                  <DialogContentText id="alert-dialog-description">
                    Do you want to send invoice/order file?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="primary" disabled={isRequestingSendMultiInvoiceFile} onClick={handleBulkGenerateInvoice}>
                    {
                      isRequestingSendMultiInvoiceFile ?
                          <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                          /> :
                          null
                    }

                    <span className="pl-2 pr-2">Send</span>
                  </Button>

                  <Button disabled={isRequestingSendMultiInvoiceFile} variant="secondary"
                          onClick={() => setIsOpenModalConfirmfSendMultiInvoiceFile(false)}>Close</Button>
                </DialogActions>
              </Dialog>
            </HelpPageWrapper>
          </CustomModal>
      );
    }
;

const mapStateToProps = (state) => {
  return {
    orders: state.dashboardReducer.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrders: (data) => getOrders(data, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Orders);
