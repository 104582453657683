import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { doSupplierLogin } from "../../store/actions/authAction";
import { AuthContext } from "../../contexts/auth/auth.context";
import UploadService from "../../services/FileUploadService";
import * as Constants from "../../constants/Constant";
import axios from "axios";
import { Table } from 'react-bootstrap';
import * as Constant from "../../constants/Constant";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Modal from 'react-bootstrap/Modal'
import {getBrands} from '../../store/actions/dashboardAction'
import { connect } from "react-redux";
import API from "../../network/dashboardAPI";
import DeleteIcon from '@material-ui/icons/Delete';
import {OverlayTrigger, Popover, Toast, Spinner} from "react-bootstrap"



const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  td > button {
    height: initial;
  }

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  min-height: 400px;
  @media (min-width: 1100px) {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 60px;
  }
`;
const logo = {
  height : "50Px",
  width : "50Px"
}

const BrandListing = (props) => {

    const { authDispatch } = useContext(AuthContext);
    let admin = localStorage.getItem("admin");
    let parsedUser = JSON.parse(admin);

    if(!admin) {
      window.location.href = "/";
    }

    const [showImage, setShowImage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [editedItem, setEditedItem] = useState({});
    const [imgUrl , setImgUrl] = useState("")
  const [brandLists, setBrandLists] = useState([]);
  const [toastBgColor, setToastBgColor] = useState('bg-success');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Delete successful");
  const [deleteItemName, setDeleteItemName] = useState('');
  const [dialogDelete, setDialogDelete] = useState(false);

    const hnadleShowImageClose = () => {
        setShowImage(false)
    }
    const hnadleShowImageOpen = (url) => {
        setImgUrl(url)
        setShowImage(true)
    }

    const addBrandPage = (event) => {
        window.location.href = "/admin/brand/add";
    }

  const brandToggleStatus = async (brand_id, is_available, e) => {
    e.preventDefault();

    try {
      let brandObject = props.brands.find(row => row.brand_id === brand_id);
      let itemIndex = props.brands.indexOf(brandObject);
      setIsLoading(true);
      is_available = is_available ? 0 : 1;
      // Change brand is_available [0 and 1]
      await API.updateBrands({brand_id, is_available: is_available, action: 'hideShowBrand'})
        .then(res => {
          if(res.status === 200) {
            brandObject.is_available = is_available;
            Object.assign(props.brands[itemIndex], brandObject);

            setIsLoading(false);
            setEditedItem(brandObject);
          }
        })
    } catch(error) {
      console.log(error)
    }
  }

    useEffect(() => {
        props.getBrands();
      },[])

  const softDeleteBrand = async () => {
    try {
      if(Object.keys(editedItem).length) {
        const brand_id = editedItem.brand_id;
        const itemRow = document.getElementById(`item-row-${brand_id}`);

        setIsLoading(true);
        setShowToast(false);
        await API.updateBrands({brand_id, action: 'softDelete'})
          .then(res => {
            setTimeout(() => {
              document.body.click();
              setIsLoading(false);
              setShowToast(true);
              itemRow.remove();
            }, 750)
          });
        setDialogDelete(false);
      }
    } catch(error) {
      console.log(error);
      setDialogDelete(false);
    }
  };
  const handleDialogDelete = (status = false, data = null) => {
    if(data !== null) {
      setEditedItem(data);
      setDeleteItemName(data.name);
    } else {
      setDeleteItemName('');
    }

    setDialogDelete(status);

    if(!status)
      setEditedItem({});
  };

    let content = null;

    if(props.brands){
      content = props.brands.map((brand,index)=>

        <tr id={`item-row-`+brand.brand_id} key = {index} >
          <td onClick= {()=>hnadleShowImageOpen(brand.logo)} ><img src={brand.logo} alt="logo" style = {logo} /></td>
          <td>{brand.brand_id}</td>
          <td>{brand.name}</td>
          <td onClick= {()=>hnadleShowImageOpen(brand.image)} ><img src={brand.image} alt="img" style = {logo} /></td>
          <td>
            <Link to={{ pathname: '/admin/brand/update', state: {brand_id : brand.brand_id, name : brand.name, description: brand.description, logo :brand.logo, image : brand.image, is_available : brand.is_available  } }}
                  className='mr-1'>
              <Button variant="info" type="submit">
                <EditIcon></EditIcon>
              </Button>
            </Link>
            <Link to='javsacript:void(0);'>
              <Button variant={brand.is_available ? 'info' : 'secondary'}
                      onClick={(event) => brandToggleStatus(brand.brand_id, brand.is_available, event)}
              >
                {
                  brand.is_available ? <VisibilityIcon></VisibilityIcon> : <VisibilityOffIcon></VisibilityOffIcon>
                }
              </Button>
            </Link>

            <Button className="ml-1"
                    variant="danger"
                    onClick={() => handleDialogDelete(true, brand)}
            >
              <DeleteIcon/>
            </Button>
          </td>
        </tr>

      )}

  return (
    
      <HelpPageWrapper>
        <Row>
        <Col >
                <Button variant="success" type="submit" className="mb-5" onClick={addBrandPage }>
                    Add brand
                </Button>
            </Col>
        </Row>
        <Row>
            <Col sm={11}>
              { !content?<div style = {{position: "fixed" , left: "55%" , top : "35%"}} > <Loader   color = "#389244" h = "100"/> </div> :
              <Table striped bordered hover>
                <thead>
                      <tr>
                      <th>Logo</th>
                          <th>ID</th>
                          <th>Brand Name</th>
                          <th>Image</th>
                          <th>Edit</th>
                      </tr>
                  </thead>
                  <tbody >

                   { content}
                   </tbody>
              </Table>
            }
            </Col>
        </Row>

        <Modal show={showImage} onHide={hnadleShowImageClose}>
            <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
            </Modal.Header>
            <Modal.Body><img alt ="Categories" src = {imgUrl} style = {{height : "400Px" , width : "400Px", alignItems : "center"}}/></Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={hnadleShowImageClose}>
                Close
            </Button>
            {/* <Button variant="danger">
                 Mark out of stock            
            </Button> */}
            </Modal.Footer>
        </Modal>

        <Modal show={dialogDelete}
               onHide={handleDialogDelete}
               aria-labelledby="contained-modal-title-vcenter"
               centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete brand</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure want to delete <strong>{deleteItemName}</strong>?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleDialogDelete(false)}>
              Close
            </Button>
             <Button variant="danger" onClick={() => softDeleteBrand()}>
                 Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Toast className={toastBgColor}
               onClose={() => setShowToast(false)}
               show={showToast}
               delay={3000}
               autohide
               style={{
                 position: 'fixed',
                 top: 70,
                 right: 35,
                 zIndex: 1041,
               }}
        >
          <Toast.Header>
            <strong className="mr-auto">{toastMessage}</strong>
          </Toast.Header>
        </Toast>
        
      </HelpPageWrapper>

  );
}
const mapStateToProps = state =>{
 // console.log(state);
  return {
    brands : state.dashboardReducer.brands
  }
 
}

const mapDispatchToProps = dispatch => {
  return {
    getBrands : data => getBrands(data,dispatch)
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(BrandListing)