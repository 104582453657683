import * as actionTypes from '../ActionTypes';
const initialState = {
    loading: false,
    promoloading: false,
    arrivalloading: false,
    trendloading: false,
    sliders: [],
    oos: 0,
    // page: 1,
 }

 export const globalReducer = (state = initialState, action) =>{
    switch(action.type){
        case actionTypes.Loading_Disable:{
            return {
                ...state,
                loading:false,
            }
        }
        case actionTypes.Loading_Enable:{
            return {
                ...state,
                loading:true,
            }
        }
        case actionTypes.PLoading_Disable:{
            return {
                ...state,
                promoloading:false,
            }
        }
        case actionTypes.PLoading_Enable:{
            return {
                ...state,
                promoloading:true,
            }
        }
        case actionTypes.NLoading_Disable:{
            return {
                ...state,
                arrivalloading: false,
            }
        }
        case actionTypes.NLoading_Enable:{
            return {
                ...state,
                arrivalloading: true,
            }
        }
        case actionTypes.TLoading_Disable:{
            return {
                ...state,
                trendloading: false,
            }
        }
        case actionTypes.TLoading_Enable:{
            return {
                ...state,
                trendloading: true,
            }
        }
        case actionTypes.Offer_Slider:{
            return {
                ...state,
                sliders: action.payload
            }
        }
        case actionTypes.OOS_Option:{
            return {
                ...state,
                oos: action.payload
            }
        }
        // case actionTypes.Change_Page:{
        //     return {
        //         ...state,
        //         page: action.payload
        //     }
        // }
        // case actionTypes.Reset_Page:{
        //     return {
        //         ...state,
        //         page: 1
        //     }
        // }
        default :{
            return{
                ...state,
               
            }
        }
    }
}