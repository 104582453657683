import React, { useEffect, useState } from "react";
import { Modal } from "@redq/reuse-modal";
import Checkout from "../containers/CheckoutWithSidebar/CheckoutWithSidebar";
import { useDeviceType } from "../helper/useDeviceType";
import { ProfileProvider } from "../contexts/profile/profile.provider";
import { connect } from "react-redux";
import {
  addAddress,
  getAddress,
  updateAddress,
  deleteAddress,
  alldeliverySlots,
  slotsForDate,
  allCouponCodes,
  moveToCheckout,
  makePayment,
} from "../store/actions/userAction";
import {
  placeOrder,
  deliveryCharges,
  fetchCart,
  expressCheckout,
  changeOrderStatus, initBasket, initWallet,
} from "../store/actions/cartAction";
import { useCart } from "../contexts/cart/use-cart";
import localForage from "localforage";
import { move } from "formik";
import * as Constant from '../constants/Constant';
import axios from 'axios';

const CheckoutPage = (props, { userAgent }) => {
  const deviceType = useDeviceType(userAgent);
  const token = "true";
  const { items, state, itemsCount } = useCart();

  useEffect(() => {
    if(!localStorage.getItem("order_id") === undefined || localStorage.getItem("order_id") === "0" || localStorage.getItem("order_id") === 0){
      alert("No order exists");
      localStorage.setItem("order_id", 0);
      window.location.href = "/";
    }
  }, []);

  //Update wallet
  useEffect( async () => {
    if(props.wallet === null) {
      const walletAPI = Constant.baseUrl+'v1/wallet/get/current/amount';
      const token = localStorage.getItem('token')
      const walletResp = await axios.get(walletAPI, { headers: { Authorization: token } });

      if(walletResp.status === 200) {
        props.initWallet(walletResp.data);
      }
    }
  }, [props.wallet])

  return (
    <>
      <ProfileProvider initData={""}>
        <Modal>
          <Checkout token={token} deviceType={deviceType} props={props} />
        </Modal>
      </ProfileProvider>
    </>
  );
};
CheckoutPage.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};
const mapStateToProps = (state) => {
  return {
    orderPlaced: state.cartReducer.orderPlaced,
    modalData: state.productReducer.modalData,
    user_address: state.userReducer.address,
    deliveryCharge: state.userReducer.deliveryCharge,
    distanceBetweenUserStore: state.userReducer.distanceBetweenUserStore,
    delivery_slots: state.userReducer.delivery_slots,
    slots_for_date: state.userReducer.slots_for_date,
    all_slots: state.userReducer.all_slots,
    all_timings: state.userReducer.all_timings,
    calendar_response: state.userReducer.calendar_response,
    all_coupon_codes: state.userReducer.all_coupon_codes,
    cartData: state.cartReducer.cartData,
    checkoutdata: state.userReducer.move_to_checkout,
    paymentResponse: state.userReducer.paymentResponse,
    wallet: state.cartReducer.wallet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAddress: (body) => addAddress(body, dispatch),
    updateAddress: (body) => updateAddress(body, dispatch),
    deleteAddress: (body) => deleteAddress(body, dispatch),
    getAddress: () => getAddress(dispatch),
    alldeliverySlots: (body) => alldeliverySlots(body, dispatch),
    slotsForDate: (body) => slotsForDate(body, dispatch),
    placeOrder: (body) => placeOrder(body, dispatch),
    changeOrderStatus: () => changeOrderStatus(dispatch),
    allCouponCodes: (body) => allCouponCodes(body, dispatch),
    fetchCart: (body) => fetchCart(body, dispatch),
    moveToCheckout: (body) => moveToCheckout(body, dispatch),
    makePayment: (body) => makePayment(body, dispatch),
    initBasket: (body) => initBasket(body, dispatch),
    expressCheckout: (body) => expressCheckout(body, dispatch),
    initWallet: (data) => initWallet(data, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
