import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import heartIcon from '../../assets/icons/heart-icon.svg';
import linkIcon from '../../assets/icons/layer-icon.svg';

export const QuickViewWrapper = styled.div`
  max-width: 1020px;
`;

export const ProductDetailsWrapper = styled.div`
  background-color: #fff;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.16);
  * {
    box-sizing: border-box;
  }
`;


export const ImagesViewWrap = styled.div`
  margin-top: 45px;
  margin-left: -115px;
  padding-left: 0;
  position: relative;
  z-index: 1;
  display: flex;
  gap: 15px;
    
  a {
    display: inline-block;
    
    &.active img {
      transform: scale(1.25);
    }
    
    img {
      display: inline-block;
      width: 100%;
      max-width: 50px !important;
    }
  }
  
  @media (max-width: 991px) {
    margin-left: 0;
  }
`;

export const ProductPreview = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 30px 47px 40px 30px;
    order: 0;
  }
  
  .bg-warning { background-color: #f78205 !important; }
  .bg-success.express { background-color: #f01266 !important; }

  .product-image {    
    @media (max-width: 767px) {
        height: 100%;
        width: auto;
        max-height: 280px;
        max-width: 95% !important;
    }
  }

  img {
    width: 100%;
    height: auto;
    @media (max-width: 767px) {
      max-width: 300px !important;
    }
  }
`;

export const SaleTag = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  background-color: ${themeGet("colors.yellow", "#FBB979")};
  padding: 0 10px;
  line-height: 24px;
  border-radius: 12px;
  display: inline-block;
  position: absolute;
  top: 40px;
  right: 30px;
`;

export const DiscountPercent = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: #ffffff;
  line-height: 24px;
  background-color: ${themeGet("colors.yellow", "#FBB979")};
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  display: inline-block;
  position: absolute;
  top: 30px;
  right: 30px;
  border-radius: 12px;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
`;

export const ProductInfoWrapper = styled.div`
  flex: 0 0 50%;
  max-width: 50%;

  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 10px 0 30px;
    order: 1;
  }
`;

export const ProductInfo = styled.div`
  padding: 30px;
  
  .full-view {
    color: #389244;
    float: right;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 15px;
  }
  
  .productInfoTitle {
    font-size: 1.2rem;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    padding: 0px 30px 30px 30px;
  }
`;

export const ProductTitlePriceWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  
  .availability {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #77798C;  
  }
  p {
    font-size: 1rem;
  }
  .stock-label {
    padding-right: 20px;
    color: #389244;
    font-size: 1rem;
    
    &.out-of-stock {
      color: #FFAD5E;
    }
  }
  .product-code {
    color: #7e7e7e;
  }
  .review {
    color: #7e7e7e;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .bg-success.express { background-color: #f01266 !important; }
`;

export const Favorite = styled.div`
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 45px;
  margin-bottom: 35px;
  padding-bottom: 45px;
    
  a {
    display: flex;
    position: relative;
    width: 50px;
    height: 50px;
    border: 1px solid green;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    
    &:hover {
      text-decoration: none;
    }
    &.add-to-favorite:before {
      content: '';
      background-image: url(${heartIcon});
      width: 18px;
      height: 18px;
      background-size: cover;
      margin-top: 2px;
    }
    &.link:before {
      content: '';
      background-image: url(${linkIcon});
      width: 18px;
      height: 18px;
      background-size: cover;
      margin-top: 2px;
    }
  }
`;

export const ButtonAddToCart = styled.div`
  display: flex;
  
  .quantity-number {
    border: 1px solid #e1e1e1;
    margin-right: 35px;
    
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #7e7e7e;
  }
  .decrease {
    font-size: 24px;
    padding: 0 20px;
    cursor: pointer;
    font-weight: bold;
  }
  .increase {
    font-size: 18px;
    padding: 0 20px;
    cursor: pointer;
    font-weight: bold;
  }
`;

export const ProductTitle = styled.h1`
  font-family: "Lato", sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  color: ${themeGet("colors.darkBold", "#0D1136")};
  line-height: 1.5;
  display: flex;

  @media (max-width: 767px) {
    word-break: break-word;
  }
`;

export const ProductPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  line-height: 31px;
  margin-bottom: 10px;
  margin-top: 5px;
`;

export const ProductPrice = styled.div`
  font-family: "Lato", sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  color: ${themeGet("colors.primary", "#389244")};
  
  &.express { color: #f01266; }
`;

export const PromoPrice = styled.span`
  font-family: "Lato", sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  color: ${themeGet("colors.yellow", "#FBB979")};
  display: inline-block;
  padding-left: 10px;
`;

export const SalePrice = styled.span`
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.yellow", "#FBB979")};
  font-style: italic;
  padding: 0 5px;
  overflow: hidden;
  position: relative;
  margin-right: 10px;

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    display: inline-block;
    background-color: ${themeGet("colors.yellow", "#FBB979")};
    position: absolute;
    top: 50%;
    left: 0;
  }
`;

export const ProductWeight = styled.div`
  text-align: left;
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.darkRegular", "#77798c")};
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const ProductDescription = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: ${themeGet("fontWeights.3", "400")};
  color: ${themeGet("colors.darkMedium", "#424561")};
  line-height: 2;
  margin-top: 0px;
  .productInfoTitle {
    font-weight: bold;
  }
`;
export const ProductCartBtn = styled.div`
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 35px;
  margin-bottom: 20px;
  display: flex;
  gap: 30px;
  position: relative;
  
  .btn-outline-success {
    background-color: transparent;
    color: #389244;
    border: 1px solid #389244;
    border-radius: 25px;
    font-weight: 400;
  }

  @media (max-width: 767px) {
    margin-top: 40px;
  }

  .reusecore__button {
    font-family: "Lato", sans-serif;
    font-size: ${themeGet("fontSizes.2", "15")}px;
    font-weight: ${themeGet("fontWeights.6", "700")};
    color: ${themeGet("colors.primary", "#389244")};
    height: 36px;
    border-radius: 4rem;
    width: 100%;
    max-width: 150px;

    .btn-icon {
      margin-right: 5px;
    }

    &:hover {
      color: #fff;
      background-color: ${themeGet("colors.primary", "#389244")};
      border-color: ${themeGet("colors.primary", "#389244")};
    }
  }
  
  &.express .cart-button {
      color: #FFFFFF;
      
      &:hover {
        background-color: #f01266;
        border-color: #f01266;
      }
    }
    &.express span[itemprop="price"] { color: #f01266; }
`;

export const ProductCartWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductMeta = styled.div`
  margin-top: 30px;
`;

export const MetaSingle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const MetaItem = styled.span`
  font-family: "Lato", sans-serif;
  font-size: ${themeGet("fontSizes.1", "13")}px;
  font-weight: ${themeGet("fontWeights.6", "700")};
  color: ${themeGet("colors.darkBold", "#0D1136")};
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: ${themeGet("colors.lightColor", "#f7f7f7")};
  padding: 0 15px;
  border-radius: 6px;
  cursor: pointer;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalClose = styled.button`
  position: fixed;
  top: 20px;
  right: 15px;
  padding: 10px 15px;
  z-index: 1;

  cursor: pointer;
  display: block;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  border: 0;
  outline: none;
  display: inline-block;
  svg {
    width: 12px;
    height: 12px;
  }
  @media (max-width: 767px) {
    top: 5px;
    right: 0;
  }
`;
