import React, { useReducer } from "react";
import { useCreateContext } from "../create-context";
import { AppContext } from "./app.context";
const initialState = {
  isSticky: true,
  isSidebarSticky: false,
};
const type = {
  SET_STICKY: "SET_STICKY",
  REMOVE_STICKY: "REMOVE_STICKY",
  SET_SIDEBAR_STICKY: "SET_SIDEBAR_STICKY",
  REMOVE_SIDEBAR_STICKY: "REMOVE_SIDEBAR_STICKY",
};
function reducer(initialState, action) {
  switch (action.type) {
    case "SET_STICKY":
      return {
        ...initialState,
        isSticky: true,
      };
    case "REMOVE_STICKY":
      return {
        ...initialState,
        isSticky: false,
      };
    case "SET_SIDEBAR_STICKY":
      return {
        ...initialState,
        isSidebarSticky: true,
      };
    case "REMOVE_SIDEBAR_STICKY":
      return {
        ...initialState,
        isSidebarSticky: false,
      };
    default: {
      return {
        ...initialState,
      };
    }
  }
}
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
