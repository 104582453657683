import React, { useState, useEffect } from "react";
import "react-datetime/css/react-datetime.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import _, { set } from "lodash";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import TextField from "../../components/TextField/TextField";

import styled from "styled-components";
import {
  Modal,
  Form,
  Table,
  Row,
  Col,
  Button,
  Pagination,
  Spinner,
  InputGroup,
  ListGroup,
  Toast
} from "react-bootstrap";
import {
  LocalGroceryStore,
  UnfoldMore,
  Edit,
  AccountBalanceWallet,
  RemoveRedEye,
  AddOutlined,
} from "@material-ui/icons";
import API from "../../network/dashboardAPI";
import REDAPI from "../../network/redAPI";

import GoogleMaps from "../../components/GoogleMaps";
import {browSer, checkAuthError, logoutuser} from "../../helper/constant";
import * as actionTypes from "../../store/ActionTypes";
import {getAddress} from "../../store/actions/userAction";

let getLat;
let getLng;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  table th {
    width: 0.8em;
  }
  table th.sortable {
    cursor: pointer;
  }

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const TableBodyWidth = styled.div`
  table tr th {
    white-space: nowrap;
    width: initial;
  }
`;

const labelStyle = {
  lineHeight: "2.5rem",
};

const Customer = (props) => {
  let admin = localStorage.getItem("admin");
  let mql = window.matchMedia("all and (min-width: 769px)");

  if (!admin) window.location.href = "/";

  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recentOrderLoading, setRecentOrderLoading] = useState(false);
  const [customerTotal, setCustomersTotal] = useState([]);
  const [perPage, setPerPage] = useState(20);
  const [customerOffset, setCustomerOffset] = useState(0);
  const [currentNumberCustomerDisplay, setCurrentNumberCustomerDisplay] =
    useState(20);
  const [recentOrders, setRecentOrders] = useState([]);
  const [sortBy, setSortBy] = useState("DESC");
  const [dialogOrder, setDialogOrder] = useState(false);
  const [recentOrderTitle, setRecentOrderTitle] = useState("Recent orders");
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [editCustom, setEditCustomer] = useState({});
  const [isRequesting, setIsRequesting] = useState(false);
  const [isShowWalletTransactionModal, setIsShowWalletTransactionModal] =
    useState(false);
  const [walletTransactions, setWalletTransactions] = useState([]);
  const [isShowAddWalletTranModal, setIsShowAddWalletTranModal] =
    useState(false);
  const [customerToViewWallet, setCustomerToViewWallet] = useState({});

  // Wallet form props
  const [amount, setAmount] = useState(0);
  const [reason, setReason] = useState("refund");
  const [comment, setComment] = useState("");
  const [transactionType, setTransactionType] = useState("credit");
  const [walletFormError, setWalletFormError] = useState({
    reason: false,
    amount: false,
    transactionType: false,
  });

  // Form update customer props
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [enableExpress, setEnableExpress] = useState(false);
  const [validated, setValidated] = useState(false);
  const [address, setAddress] = useState([]);
  const [isRequestingUpsert, setIsRequestingUpsert] = useState(false);
  const [area, setArea] = useState("");
  const [indexCustomer, setIndexCustomer] = useState("");
  // Map
  const [isShowMapModal, setIsShowMapModal] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [marker, setMarker] = useState(false);
  const [addressToUpdate, setAddressToUpdate] = useState("");
  const [indexAddress, setIndexAddress] = useState(null);
  const [modalshow, setModalShow] = useState(false);
    const initialError={
        first_name:"",
        last_name:'',
        country_code:'',
        mobile_number:'',
        street_details:'',
        area_details:'',
        city:'',
        saveAs:'',
        house_number:'',
        search_pin:''
    }
    const [error,setError]=useState(initialError);
    const [city, setCity] = useState("");
    const [isUpdateAddressReqeust, setIsUpdateAddressRequest] = useState(false);

  // toast states
  const [toastBgColor, setToastBgColor] = useState('bg-success');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Delete successful");

  // Filter states
  const [selectedStatus, setSelectedStatus] = useState("all");

  // Use ref
  const _textSearch = React.useRef("");

  // form error
    const [formError, setFormError] = useState({
        username: false,
        firstName: false,
        lastName: false,
        email: false,
        phone: false
    })

  const getCustomerBy = async (
    search = "",
    fieldName = "createdAt",
    orderBy = "DESC",
    limit = perPage,
    offset = 0
  ) => {
    try {
      setIsLoading(true);
      const query = selectedStatus;
      API.getCustomers(query, { search, fieldName, orderBy, limit, offset })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setCustomersTotal(res.total);
            setCustomers(res.result);
          } else {
            alert("Sort not found");
          }
          setIsLoading(false);
        });
    } catch (e) {
      console.log("Error, can not find customer", e);
      setCustomers([]);
      setIsLoading(false);
    }
  };

  const getRecentOrdersByCustomer = async (cust_id) => {
    try {
      setRecentOrderLoading(true);
      API.getRecentOrdersByCustomer({ cust_id })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setRecentOrders(res.result);
          }

          setRecentOrderLoading(false);
        });
    } catch (e) {
      console.log("Error, can not find customer", e);
      setRecentOrders([]);
      setRecentOrderLoading(false);
    }
  };

  const getWalletTransactionByCustomerId = (item) => {
    try {
      setIsRequesting(true);
      API.getWalletTransactionByCustomerId({
        customerId: item.cust_id,
        next: 0,
        limit: 10,
      })
        .then((res) => res.json())
        .then((res) => {
          setIsRequesting(false);
          if (res.success) {
            setWalletTransactions(res.data.list);
          }
        });
    } catch (e) {
      console.log("Error, can not find wallet transaction by customerId", e);
      setWalletTransactions([]);
      setIsShowWalletTransactionModal(false);
    }
  };

  // Map function
  const onMarkerDragEnd = async (t, map, coord) => {
    console.log("Lat before ",latitude);
    const { latLng } = coord;
    console.log("Lat after ",coord.latLng.lat());
    const lat = await latLng.lat();
    const lng = await latLng.lng();
    setLatitude(latLng.lat());
    setLongitude(latLng.lng());
    setMarker(true);

    fetchAddress();
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // Success function
        showPosition,
        // Error function
        defaultPosition,
        // Options. See MDN for details.
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = (position) => {
    getLat = position.coords.latitude;
    getLng = position.coords.longitude;
    setLatitude(getLat);
    setLongitude(getLng);
    setMarker(true);
  };

  const defaultPosition = () => {
    setLatitude(props.item.latitude ? props.item.latitude : 11.5564);
    setLongitude(props.item.long ? props.item.long : 104.9282);
    if (browSer() === "Chrome"){
      if(window.location.hostname === "dev.delishop.asia"){
        alert("Location permissions not allowed for Google Chrome , allow your location by following this link in another tab chrome://settings/content/siteDetails?site=https://dev.delishop.asia/");
      }
      else{
        alert("Location permissions not allowed for Google Chrome , allow your location by following this link in another tab chrome://settings/content/siteDetails?site=https://delishop.asia/");
      }
    }
    else if(browSer() === "Safari"){
      if(window.location.hostname === "dev.delishop.asia"){
        alert("Location permissions not allowed for Safari , allow your location by following these steps : \nSafari -> Preferences -> Websites tab -> Location -> dev.delishop.asia -> allow");
      }
      else{
        alert("Location permissions not allowed for Safari , allow your location by following these steps : \nSafari -> Preferences -> Websites tab -> Location -> delishop.asia -> allow");
      }
    }
    // latitude !== "" ? setLatitude(latitude) : setLatitude(11.5564);
    // longitude !== "" ? setLongitude(longitude) : setLongitude(104.9282);
  };

  //setup before functions
  let typingTimer; //timer identifier
  let doneTypingInterval = 750; //time in ms (7.5 seconds)
  const doSearch = (value) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(function () {
      getCustomerBy(value);
    }, doneTypingInterval);
  };

  const dialogOrderShow = async (cust_id, name) => {
    await getRecentOrdersByCustomer(cust_id);

    setRecentOrderTitle(`Recent order (${name})`);
    setDialogOrder(true);
  };
  const dialogOrderClose = () => {
    setDialogOrder(false);
  };

  const sortToggle = (item) => {
    if (sortBy === "DESC") setSortBy("ASC");
    else setSortBy("DESC");

    getCustomerBy("", item, sortBy);
  };

  const setFormData = (customer) => {
    setUsername(customer.name);
    setFirstName(customer.first_name);
    setLastName(customer.last_name);
    setEmail(customer.email);
    setPhone(customer.phone);

    if (customer && customer.enable_express) {
      setEnableExpress(customer.enable_express);
    }

    if(customer && customer.list_customer_addresses){
      setAddress(customer.list_customer_addresses);
    }
  };

  const resetFormData = () => {
    setUsername("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setAddress([]);
    setEnableExpress(false);
    setEditCustomer({});
    setIndexCustomer("");
  };

    const checkFormValidate = () => {
        let error = {};
        if (!username) {
            error.username = true;
        }else{
            error.username = false;
        }

        if (!firstName) {
            error.firstName = true;
        }else{
            error.firstName = false;
        }

        if (!lastName) {
            error.lastName = true;
        }else{
            error.lastName = false;
        }

        if (!email) {
            error.email = true;
        }else{
            error.email = false;
        }

        if (!phone) {
            error.phone = true;
        }else{
            error.phone = false
        }

        setFormError(error);

        return Object.values(error).every(el => el === false);
    };

  const resetPagination = () => {
    setCustomerOffset(0)
  }

  const handleEditCustomer = (customer, index) => {
    const cloneCustomer = Object.assign({}, customer);
    setEditCustomer(cloneCustomer);
    setFormData(cloneCustomer);
    setIndexCustomer(index);
    setIsOpenUpdateModal(true);
  };

  const handleCloseupdateModal = () => {
      resetFormError();
    setIsOpenUpdateModal(false);
  };

  const handleStatusChange = (event) => {
    //  reset pagination
    resetPagination();

    setSelectedStatus(event.target.value);
  };

  const handleViewWalletTransaction = (item) => {
    setCustomerToViewWallet(item);
    handleOpenWalletTransactionModal();
    getWalletTransactionByCustomerId(item);
  };

  const handleOpenWalletTransactionModal = () => {
    setIsShowWalletTransactionModal(true);
  };

  const handleCloseWalletTransactionModal = () => {
    setWalletTransactions([]);
    setCustomerToViewWallet({});
    setIsShowWalletTransactionModal(false);
  };

  const handleSubmit = () => {
    if(checkFormValidate()){
        setIsRequestingUpsert(true);

        const body = {
            enable_express: enableExpress,
            cust_id: editCustom.cust_id,
            name: username,
            first_name: firstName,
            last_name: lastName,
            phone: phone,
            email: email
        };
        API.updateCustomer(body)
            .then((response) => response.json())
            .then((response) => {
                setIsRequestingUpsert(false);

                if (response.success) {
                    resetFormData();
                    resetPagination();
                    resetSearch();

                    getCustomerBy();
                    setIsOpenUpdateModal(false);
                    handleShowToast("SUCCESS");
                }else{
                    handleShowToast("ERROR");
                }
            });
    }
  };

  const resetFormError = () => {
      setFormError({
          username: false,
          lastName: false,
          firstName: false,
          email: false,
          phone: false
      })
  }

  const resetSearch = () => {
      if(_textSearch && _textSearch.current && _textSearch.current.value){
          _textSearch.current.value = ""
      }
  }

  const handleOpenAddWalletTransactionModal = (customer) => {
    setIsShowAddWalletTranModal(true);
    setEditCustomer(customer);
  };

  const handleCloseAddWalletTransactionModal = () => {
    setIsShowAddWalletTranModal(false);
    setEditCustomer({});
    setWalletFormError({
      reason: false,
      amount: false,
      transactionType: false,
    });
    resetWalletFormData();
  };

  const handleSummitAddWallet = () => {
    setIsRequestingUpsert(true);

    if (reason && amount > 0) {
      const body = {
        transaction_type: transactionType,
        wallet_owner_id: editCustom.cust_id,
        reason,
        wallet_amount: Number(amount),
		comment
      };

      API.addWalletTransaction(body)
        .then((response) => response.json())
        .then((response) => {
          setIsRequestingUpsert(false);

          if (response.success) {
            _textSearch.current.value = "";
            getCustomerBy();
            handleCloseAddWalletTransactionModal();
            handleShowToast("SUCCESS");
          }else{
            handleShowToast("ERROR");
          }
        });
    } else {
      let tmpWalletFormError = {};
      if (!reason) {
        setWalletFormError((prevState) => ({
          ...prevState,
          reason: true,
        }));
      }

      if (!amount || !(amount > 0)) {
        setWalletFormError((prevState) => ({
          ...prevState,
          amount: true,
        }));
      }

      setIsRequesting(false);
    }
  };

  const resetWalletFormData = () => {
    setAmount(0);
    setReason("refund");
    setComment("");
    setTransactionType("credit");
  };

    // toast event
    const handleShowToast = (type) => {
        setShowToast(true);

        if(type === "SUCCESS"){
            setToastBgColor("bg-success");
            setToastMessage("Operation successful.");
        }else if(type === "ERROR"){
            setToastBgColor("bg-danger");
            setToastMessage("Operation error.");
        }
    }

  const initialUpdateAddress = (data) => {
      const {
          area_details,
          city,
          country_code,
          delivery_instructions,
          house_number,
          is_default,
          landmark,
          last_name,
          latitude,
          long,
          mobile_number,
          nick_name,
          street_details
      } = data;
      editCustom["list_customer_addresses"][indexAddress]["area_details"] = area_details;
      editCustom["list_customer_addresses"][indexAddress]["city"] = city;
      editCustom["list_customer_addresses"][indexAddress]["country_code"] = country_code;
      editCustom["list_customer_addresses"][indexAddress]["delivery_instructions"] = delivery_instructions;
      editCustom["list_customer_addresses"][indexAddress]["house_number"] = house_number;
      editCustom["list_customer_addresses"][indexAddress]["is_default"] = is_default;
      editCustom["list_customer_addresses"][indexAddress]["landmark"] = landmark;
      editCustom["list_customer_addresses"][indexAddress]["last_name"] = last_name;
      editCustom["list_customer_addresses"][indexAddress]["latitude"] = latitude;
      editCustom["list_customer_addresses"][indexAddress]["long"] = long;
      editCustom["list_customer_addresses"][indexAddress]["mobile_number"] = mobile_number;
      editCustom["list_customer_addresses"][indexAddress]["nick_name"] = nick_name;
      editCustom["list_customer_addresses"][indexAddress]["street_details"] = street_details;
  }


  const getFormatCurrencyForDisplay = (number) => {
    let tmpNumber = number ? number : 0;
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "USD",
    }).format(tmpNumber);
  };

  const getFormatDateForDisplay = (date) => {
    return moment(date).format("DD/MM/YYYY - h:mm a");
  };

   const handleCloseMap = () => {
       setAddressToUpdate({});
       setArea("")
       setIndexAddress("");
       setIsShowMapModal(false);
       setLatitude("");
       setLongitude("");
       setCity("")
       setModalShow(false);
   }

    const handleViewMap = (address, index) => {
        setAddressToUpdate(address.area_details);
        setArea(address.area_details)
        setIndexAddress(index);
        setIsShowMapModal(true);
        setLatitude(address.latitude);
        setLongitude(address.long);
        setCity(address.city);
        setTimeout(function () {
            setModalShow(true);
        }, 2500);
    }

   const handleUpdateAddress = () => {
     setIsUpdateAddressRequest(true);
     let tmpListCustomerAddress = editCustom["list_customer_addresses"][indexAddress];
       let body = {
           first_name: tmpListCustomerAddress.first_name,
           last_name: tmpListCustomerAddress.last_name,
           mobile_number: tmpListCustomerAddress.mobile_number,
           house_number: tmpListCustomerAddress.house_number,
           street_details: tmpListCustomerAddress.street_details,
           landmark: tmpListCustomerAddress.landmark,
           area_details: area,
           city,
           nick_name: tmpListCustomerAddress.nick_name,
           is_default: tmpListCustomerAddress.is_default,
           latitude: latitude,
           long: longitude,
           delivery_instructions: tmpListCustomerAddress.delivery_instructions,
           country_code: tmpListCustomerAddress.country_code
       };

       let addressId = tmpListCustomerAddress.address_id;

       API.UpdateAddress(addressId, body)
           .then((response) => response.json())
           .then((response) => {
               setIsUpdateAddressRequest(false);
               if (response.success) {
                   initialUpdateAddress(response.data)
                   handleShowToast("SUCCESS");
               } else {
                   handleShowToast("ERROR");
               }

               handleCloseMap();
           });

       //Call api to update
     //Show toast confirm
     //
   }

    const handleAddAdrresPress = () => {
        // setModalShow(true);
        if(!area){
            fetchAddress();
        }
        // fetchAddress();
    };

    const fetchAddress = () => {
        fetch(
            "https://maps.googleapis.com/maps/api/geocode/json?address=" +
            latitude +
            "," +
            longitude +
            "&key=" +
            "AIzaSyBNXfan5vWyKWSbFUnnVOtjhcPdXafxQEk"
            // 'AIzaSyCWJ4VxjBDgtEsyoNGvmBqkaalrC3tHl-0'   ,
        )
            .then((response) => response.json())
            .then((responseJson) => {
                console.log('response json', responseJson.results[0]);
                const getUserLocation = responseJson.results[0] && responseJson.results[0].formatted_address?responseJson.results[0].formatted_address: "";
                setArea(getUserLocation);
                setMarker(false);

                responseJson.results[0].address_components.map(async function (
                    item,
                    idx
                ) {
                    item.types.map(async function (item1, idx1) {
                        if (item1 == "administrative_area_level_1") {
                            setCity(item.long_name);
                        }
                    });
                });
            });
    };

  useEffect(() => {
    if(_textSearch && _textSearch.current && _textSearch.current.value){
        getCustomerBy(_textSearch.current.value);
    }else{
        getCustomerBy();
    }
  }, [selectedStatus]);

    useEffect(() => {
        if(marker === true){
            fetchAddress();
        }
    },[marker,latitude,longitude]);
  // Pagination
  let pageItems = [
    <Pagination.Item key={0}  onClick={() => paginPrev()}>
      Prev
    </Pagination.Item>,
    <Form.Group key={1} controlId="formGridPagination">
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>{`${
            currentNumberCustomerDisplay + customerOffset
          } / ${customerTotal}`}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
    </Form.Group>,
    <Pagination.Item key={2} onClick={() => paginNext()}>
      Next
    </Pagination.Item>,
  ];
  const paginationBasic = (
    <div className="d-flex justify-content-end">
      <Pagination>{pageItems}</Pagination>
    </div>
  );

  const paginNext = async () => {
    let offset = 0;

    if (customerOffset <= 0) offset = 20;
    else offset = customerOffset + perPage;

    setCustomerOffset(offset);
    getCustomerBy(null, null, null, null, offset);
  };
  const paginPrev = () => {
    let offset = 0;

    if (customerOffset <= 0) {
      setCustomerOffset(0);
    } else {
      offset = customerOffset - perPage;
      setCustomerOffset(offset);
    }

    getCustomerBy(null, null, null, null, offset);
  };

  return (
    <HelpPageWrapper>
      <Row>
        <Col sm={12}>
          <Row className="mb-3">
            <Col sm={9}>
              <Form.Control
                style={{ width: "25rem" }}
                size="md"
                type="text"
                ref={_textSearch}
                placeholder="Search customers"
                onChange={(e) => doSearch(e.target.value)}
              />
            </Col>

            <Col sm={3} md={3} className="d-flex justify-content-end">
              <Form.Label style={labelStyle}>Filter: </Form.Label>&nbsp;&nbsp;
              <Form.Control
                as="select"
                onChange={handleStatusChange}
                value={selectedStatus}
              >
                <option value={"all"}>All customer</option>
                <option value={"noExpress"}>Normal customer</option>
                <option value={"express"}>Express customer</option>
              </Form.Control>
            </Col>
          </Row>

          <TableBodyWidth
            className="table-responsive"
            style={{ overflowY: "hidden" }}
          >
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="sortable" onClick={() => sortToggle("name")}>
                    Username <UnfoldMore />
                  </th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th
                    className="sortable"
                    onClick={() => sortToggle("createdAt")}
                  >
                    Created date <UnfoldMore />
                  </th>
                  <th style={{ minWidth: "20vh" }} className="text-center">
                    Wallet
                  </th>
                  <th style={{ minWidth: "15vh" }} className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                {!isLoading ? (
                  customers.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item.name && item.name !== " " ? item.name : "N/A"}
                      </td>
                      <td>{item.first_name || "N/A"}</td>
                      <td>{item.last_name || "N/A"}</td>
                      <td>{item.email || "N/A"}</td>
                      <td>{item.phone || "N/A"}</td>
                      <td>
                        {item.list_customer_addresses.length
                          ? item.list_customer_addresses.map(
                              (row, subIndex) => (
                                <p key={subIndex}>
                                  &bull;&nbsp;{row.area_details}
                                </p>
                              )
                            )
                          : "No data"}
                      </td>
                      <td>{moment(item.createdAt).format("Do MMMM YYYY")}</td>
                      <td className="d-flex justify-content-center">
                        <Button
                          variant="info"
                          onClick={() => handleViewWalletTransaction(item)}
                        >
                          View
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          variant="info"
                          onClick={() =>
                            handleOpenAddWalletTransactionModal(item)
                          }
                        >
                          Update
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="info"
                          onClick={() =>
                            dialogOrderShow(item.cust_id, item.name)
                          }
                        >
                          <LocalGroceryStore></LocalGroceryStore>
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          variant="info"
                          onClick={() => handleEditCustomer(item, index)}
                        >
                          <Edit></Edit>
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12} className="text-center">
                      <Spinner animation="grow" />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </TableBodyWidth>

          <Modal
            show={dialogOrder}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            onHide={dialogOrderClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>{recentOrderTitle}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Order Date</th>
                    <th>Payment Type</th>
                    <th>Amount</th>
                    <th>Delivery Date/Time</th>
                  </tr>
                </thead>

                <tbody>
                  {recentOrders.length ? (
                    recentOrders.map((value) => {
                      return value.order_payment_details.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {!item.payment_method_name ||
                            !item.to_pay ||
                            !item.createdAt ? (
                              item.order_id
                            ) : (
                              <Link
                                target="_blank"
                                to={{
                                  pathname:
                                    "/admin/order/details/" + item.order_id,
                                }}
                              >
                                {item.order_id}
                              </Link>
                            )}
                          </td>
                          <td>
                            {moment(value.placedAt).format("ll hh:mm:ss") ||
                              "N/A"}
                          </td>
                          <td>{item.payment_method_name || "N/A"}</td>
                          <td>
                            {item.to_pay
                              ? "$" + parseFloat(item.to_pay).toFixed(2)
                              : "N/A"}
                          </td>
                          <td>
                            {moment(value.start_time).format("ll hh:mm A") +
                              " to " +
                              moment(value.end_time).format("hh:mm A") || "N/A"}
                          </td>
                        </tr>
                      ));
                    })
                  ) : recentOrderLoading ? (
                    <tr>
                      <td colSpan={12} className="text-center">
                        <Spinner animation="grow" />
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={12} className="text-center">
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={dialogOrderClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal update customer */}
          <Modal
            show={isOpenUpdateModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="xl"
            onHide={handleCloseupdateModal}
          >
            <Form
              noValidate
              validated={validated}
              autoComplete="false"
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title>Update Customer</Modal.Title>
              </Modal.Header>
              <Modal.Body key="modalBody">
                {Object.keys(editCustom).length ? (
                  <>
                    <Row>
                      <Col sm={6}>
                        <Form.Group controlId="userName">
                          <Form.Label>Username</Form.Label>
                            <Form.Control
                              type="text"
                              value={username}
                              placeholder="Username"
                              required
                              isInvalid={formError.username}
                              onChange={(e) => setUsername(e.target.value)}
                            />

                            <Form.Control.Feedback type="invalid">Username is required.</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="firstName">
                          <Form.Label>First Name</Form.Label>

                            <Form.Control
                              type="text"
                              value={firstName}
                              placeholder="First Name"
                              required
                              isInvalid={formError.firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />

                          <Form.Control.Feedback type="invalid">First name is required.</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="lastName">
                          <Form.Label>Last Name</Form.Label>

                            <Form.Control
                              type="text"
                              value={lastName}
                              placeholder="Last Name"
                              required
                              isInvalid={formError.lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />

                            <Form.Control.Feedback type="invalid">Last name is required.</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="email">
                          <Form.Label>Email</Form.Label>

                            <Form.Control
                              type="text"
                              value={email}
                              placeholder="Email"
                              required
                              isInvalid={formError.email}
                              onChange={(e) => setEmail(e.target.value)}
                            />

                            <Form.Control.Feedback type="invalid">Email is required.</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formPhone">
                          <Form.Label>Phone</Form.Label>

                            <Form.Control
                              type="text"
                              value={phone}
                              placeholder="Phone"
                              required
                              isInvalid={formError.phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />

                            <Form.Control.Feedback type="invalid">Phone is required.</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formEnableExpress">
                          <Form.Check
                            type="switch"
                            id="enableExpress"
                            label="Deli Express"
                            checked={enableExpress}
                            disabled={isRequestingUpsert}
                            onChange={(e) => setEnableExpress(!enableExpress)}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group controlId="address">
                            <Form.Label>Address</Form.Label>
                            
                              <ListGroup>
                                <ListGroup.Item>
                                  <ul style={{padding: "15px"}}>
                                    {
                                      address.length &&
                                      address.map((add, index) => (
                                        <li key={index} style={{listStyleType: "disc"}} className="text-primary"
                                          role="button" onClick={(e) => handleViewMap(add, index)}>{add.area_details}</li>
                                      ))
                                    }
                                  </ul>
                                </ListGroup.Item>
                              </ListGroup>
                            
                          </Form.Group>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <p className="text-center">Customer not found.</p>
                )}
              </Modal.Body>
              <Modal.Footer>
                  <Button disabled={isRequestingUpsert} variant="primary" onClick={handleSubmit}>
                      {
                          isRequestingUpsert?
                              <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                              />:
                              null
                      }

                      <span className="pl-2 pr-2">Update</span>
                  </Button>
                <Button variant="secondary" disabled={isRequestingUpsert} onClick={handleCloseupdateModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {/* Modal display wallet list */}
          <Modal
            show={isShowWalletTransactionModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            onHide={handleCloseWalletTransactionModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <Row>
                  <Col sm={12}>
                    Wallet Historic of customer :{" "}
                    {customerToViewWallet && customerToViewWallet.name
                      ? customerToViewWallet.name
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    (
                    {customerToViewWallet && customerToViewWallet.wallet
                      ? getFormatCurrencyForDisplay(
                          customerToViewWallet.wallet.wallet_amount
                        )
                      : getFormatCurrencyForDisplay(0)}
                    )
                  </Col>
                </Row>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Admin</th>
                    <th>Type</th>
                    <th>Reason</th>
                    <th>Creation Date/Time</th>
                    <th className="text-center">Amount</th>
                  </tr>
                </thead>

                <tbody>
                  {walletTransactions.length ? (
                    walletTransactions.map((item, index) => (
                      <tr key={index}>
                        <td>{item.admin_info}</td>
                        <td>{_.capitalize(item.transaction_type)}</td>
                        <td>{_.capitalize(item.reason)}</td>
                        <td>{getFormatDateForDisplay(item.createdAt)}</td>
                        <td className="text-right">
                          {getFormatCurrencyForDisplay(item.wallet_amount)}
                        </td>
                      </tr>
                    ))
                  ) : isRequesting ? (
                    <tr>
                      <td colSpan={12} className="text-center">
                        <Spinner animation="grow" />
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={12} className="text-center">
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="danger"
                onClick={handleCloseWalletTransactionModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal add wallet */}
          <Modal
            show={isShowAddWalletTranModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="xs"
            onHide={handleCloseAddWalletTransactionModal}
          >
            <Form noValidate validated={validated} autoComplete="false">
              <Modal.Header closeButton>
                <Modal.Title>Update funds</Modal.Title>
              </Modal.Header>
              <Modal.Body key="modalBody">
                <Form.Group controlId="formTransactionType">
                  <Form.Label>Transaction Type</Form.Label>
                  <InputGroup hasvalidation="true">
                    <Form.Control
                      as="select"
                      onChange={(e) => setTransactionType(e.target.value)}
                      value={transactionType}
                      isInvalid={walletFormError.transactionType}
                      required
                      disabled={isRequestingUpsert}
                      placeholder="Select"
                    >
                      <option value={"credit"}>Credit</option>
                      <option value={"debit"}>Debit</option>
                    </Form.Control>
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="amount">
                  <Form.Label>Amount(USD)</Form.Label>
                  <InputGroup hasvalidation="true">
                    <Form.Control
                      isInvalid={walletFormError.amount}
                      type="number"
                      placeholder="00.00 USD"
                      value={amount}
                      disabled={isRequestingUpsert}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="reason">
                  <Form.Label>Reason</Form.Label>
                  <InputGroup hasvalidation="true">
                    <Form.Control
                      as="select"
                      onChange={(e) => setReason(e.target.value)}
                      value={reason}
                      isInvalid={walletFormError.reason}
                      required
                      disabled={isRequestingUpsert}
                      placeholder="Select"
                    >
                      <option value={"marketing"}>Marketing</option>
                      <option value={"order"}>Order</option>
                      <option value={"refund"}>Refund</option>
                      <option value={"other"}>Other</option>
                    </Form.Control>
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="comment">
                  <Form.Label>Comment</Form.Label>
                  <InputGroup hasvalidation="true">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      disabled={isRequestingUpsert}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                  <Button disabled={isRequestingUpsert} variant="primary" onClick={handleSummitAddWallet}>
                      {
                          isRequestingUpsert?
                              <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                              />:
                              null
                      }

                      <span className="pl-2 pr-2">Update</span>
                  </Button>

                <Button
                  variant="secondary"
                  disabled={isRequestingUpsert}
                  onClick={handleCloseAddWalletTransactionModal}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {/* Address Modal */}
          {/*{*/}
            {/*isShowMapModal?*/}
            {/*(<GoogleMaps*/}
                {/*isMarkerShown={true}*/}
                {/*latitude={latitude}*/}
                {/*longitude={longitude}*/}
                {/*onMarkerDragEnd={onMarkerDragEnd}*/}
                {/*onMyLocation={getLocation}*/}
              {/*/>): null*/}
          {/*}*/}

          <Modal
            show={isShowMapModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            onHide={handleCloseMap}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/*<Row style={{height: "70px"}}>*/}
                {/*<div title="My Location" onClick={()=>{getLocation()}} style={{position: "absolute", top: "2%",left: "2%", zIndex: "1000000", border:"2px solid rgb(0,120,201)", borderRadius:"50%", padding: "10px", backgroundColor: "white"}}>*/}
                  {/*<img src={require('../../assets/icons/Loc.png')} style={{width: mql.matches ? "40px" : "29px", cursor: "pointer"}} alt={"my location"} />*/}
                {/*</div>*/}
              {/*</Row>*/}

                <PlacesAutocomplete
                    searchOptions={{componentRestrictions: { country: ['kh'] },
                        types: []}}
                    value={area}
                    onChange={(address) => {
                        setArea(address);
                    }}
                    onSelect={(address) => {
                        setArea(address);
                        geocodeByAddress(address)
                            .then(results => {
                                setLatitude(results[0].geometry.location.lat());
                                setLongitude(results[0].geometry.location.lng());

                                results[0].address_components.map( async function(item, idx){
                                    item.types.map(async function(item1, idx1){
                                        if (item1 == "administrative_area_level_1") {
                                            setCity(item.long_name);
                                        }
                                    })
                                })
                            })
                    }}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <span className="searchBar">
                            {area === "" && <i className="fa fa-search" aria-hidden="true"></i>}
                                <TextField
                                    {...getInputProps({
                                        placeholder: '      Search Address *',
                                        label: '',
                                        className: 'taimage',
                                        id: 'searchlocation',
                                        as: 'textarea',
                                        error: error.search_pin,
                                    })}
                                />
                            </span>

                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
                {modalshow ? <>
                    <div title="My Location" onClick={() => {
                        getLocation();
                    }
                    } style={{
                        position: "absolute",
                        top: "25%",
                        left: mql.matches ? "5%" : "7%",
                        zIndex: "1000000",
                        border: "2px solid rgb(0,120,201)",
                        borderRadius: "50%",
                        padding: "10px",
                        backgroundColor: "white"
                    }}>
                        <img src={require('../../assets/icons/Loc.png')} style={{width: mql.matches ? "40px" : "29px"}}
                             alt={"my location"}/>
                    </div>
                </> : null
                }
                <GoogleMaps
                    isMarkerShown={true}
                    latitude={latitude}
                    longitude={longitude}
                    onMarkerDragEnd={onMarkerDragEnd}
                    onMyLocation={getLocation}
                    fetchAddress={fetchAddress}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isUpdateAddressReqeust} variant="primary" onClick={handleUpdateAddress}>
                    {
                        isUpdateAddressReqeust?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />:
                            null
                    }

                    <span className="pl-2 pr-2">Update</span>
                </Button>

              <Button
                variant="secondary"
                onClick={handleCloseMap}
              >
                Close
              </Button>
            </Modal.Footer>
        </Modal>

            <Toast className={toastBgColor}
                   onClose={() => setShowToast(false)}
                   show={showToast}
                   delay={3000}
                   autohide
                   style={{
                       position: 'fixed',
                       top: 70,
                       right: 35,
                       zIndex: 1041,
                       width: "300px"
                   }}
            >
                <Toast.Header>
                    <strong className="mr-auto m-3">{toastMessage}</strong>
                </Toast.Header>
            </Toast>

          {paginationBasic}
        </Col>
      </Row>
    </HelpPageWrapper>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Customer);
