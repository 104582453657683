import React, { useReducer } from "react";
import { SearchContext } from "./search.context";
const INITIAL_STATE = {
  text: "",
  suggestion: 0,
  modalOpen: false,
};
function reducer(state, action) {
  switch (action.type) {
    case "UPDATE":
      return { ...state, text: action.payload.text };
    case "RESET":
      return { text: "" };
    case "SUGGESTION":
      return { ...state, suggestion: action.payload.suggestion };
    case "OPENMODAL":
      return { ...state, modalOpen: true };
    default:
      return state;
  }
}

export const SearchProvider = ({ children, query = { text: "" } }) => {
  const [state, searchdispatch] = useReducer(reducer, query);
  return (
    <SearchContext.Provider value={{ state, searchdispatch }}>
      {children}
    </SearchContext.Provider>
  );
};
