import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@redq/reuse-modal";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { connect } from "react-redux";
import { doSupplierLogin } from "../../store/actions/authAction";
import { AuthContext } from "../../contexts/auth/auth.context";
import * as Constants from "../../constants/Constant";
import axios from "axios";

const Heading = styled.h3`
  font-size: 21px;
  font-weight: 700;
  color: #0d1136;
  line-height: 1.2;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
`;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 130px 0 60px 0;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

export const HelpPageContainer = styled.div`
  background-color: transparent;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  @media (min-width: 990px) {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 989px) {
    padding: 60px;
  }
`;

export const ErrorContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const SupplierLoginScreen = (props) => {

  const { authDispatch } = useContext(AuthContext);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errormsg, setErrorMsg] = React.useState("");
  const [cerror, setCError] = React.useState("");
  let supplier = localStorage.getItem("supplier");
  let admin = localStorage.getItem("admin");
  
  if(supplier != null) {
    window.location.href = "/supplier/orders";
  }
  if(admin != null) {
    window.location.href = "/admin/orders";
  }

  const didLoginFail = () => {
    if(!props.success){
      setCError(true);
    }
    setLoading(false);
  };

  const loginCallback = (e) => {
    e.preventDefault();
    if (!error) {
      setLoading(true);
      props.doSupplierLogin({ email: email, password: password });
      setTimeout(() => {
        didLoginFail();
      }, 1000);
    }
  };

  useEffect(() => {
    return ()=>{
      setLoading(false);
      setError(false);
      setErrorMsg("");
      setEmail("");
      setPassword("");
      setCError("");
    }
  },[]);

  useEffect(() => {
    if (props.success) {
      authDispatch({ type: "SIGNIN_SUCCESS" });
    }
  }, [props.success]);
  const validateEmail = async () => {
    if (!email) {
      setErrorMsg("Enter an email");
      setError(true);
      return false;
    }
    else if ((Constants.email_reg.test(email)) === false) {
      setErrorMsg("Enter a valid email");
      setError(true);
      return false;
    }
  };

  return (
    <Modal>
      <HelpPageWrapper>
        <HelpPageContainer>
        <Card >
          <Card.Body>
          <Heading>Login</Heading>

          <Form onSubmit={loginCallback}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Enter email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
                onBlur={validateEmail}
                required />
              <Form.Text className="text-muted">
                Your email which is listed in our system.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)} 
                required />
            </Form.Group>

            <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Remember me" />
            </Form.Group>

            <Button variant="success" type="submit" disabled={loading}>
              Submit
            </Button>
            <br/>
            {error === true ? (
            <ErrorContainer>
              <span className="text-danger">{errormsg}</span>
            </ErrorContainer>
            ) : null}
            {cerror && props.loginresponsemsg === "invalid password" && error !== true ? <ErrorContainer><span className="text-danger">{props.loginresponsemsg}</span></ErrorContainer> : null
            }
            {/* {cerror ? <span className="text-danger">Wrong password. Please try again or <span onClick={(e) => {
              e.preventDefault();
              authDispatch({
                type: "FORGOTPASS",
              });
              }} 
              style={{color: "green", textDecoration: "underline", fontWeight: "700", cursor: "pointer"}}>RESET</span> &nbsp;it</span> : null} */}

          </Form>
          </Card.Body>
          </Card>
        </HelpPageContainer>
      </HelpPageWrapper>
    </Modal>
  );
}
const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    loginresponsemsg: state.authReducer.loginresponsemsg
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doSupplierLogin: (body) => doSupplierLogin(body, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupplierLoginScreen);