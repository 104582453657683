import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@redq/reuse-modal";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { AuthContext } from "../../contexts/auth/auth.context";
import * as Constant from "../../constants/Constant";
import Spinner from 'react-bootstrap/Spinner';
import UploadService from "../../services/FileUploadService";
import {getSuppliers,setError,setLoading} from '../../store/actions/dashboardAction'
import { connect } from "react-redux";
import Alert from 'react-bootstrap/Alert';



import "react-datetime/css/react-datetime.css";


const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const BulkImageUplodes = (props) => {

    const { authDispatch } = useContext(AuthContext);
    let admin = localStorage.getItem("admin");
    let parsedUser = JSON.parse(admin);
    if(!admin) {
      window.location.href = "/";
    }

   
    const [file , setFile] = useState(null);
    const [message, setMessage] = useState("");
    const [isUploded, setIsUploded] = useState(false)
    const [progress, setProgress] = useState()
    
    const onUploadProgress= event => {
        setProgress(Math.round((100 * event.loaded) / event.total))
      }

    const handleFileChange = e => {
        console.log(e.target.files)
        setFile(e.target.files)
    }

    const uploadFile = (e) =>{ 
        if(file.length <=50 ){          
            let formData = new FormData();
            setIsUploded(false)
            setProgress(null);
           // formData.append("file", file);
            for (const iterator of file) {
                formData.append("file", iterator);
            };
            UploadService.bulkUploadImage(formData,onUploadProgress )
            .then(response => {
                if(response.data.success) {
                   setMessage(response.data.message)
                   setIsUploded(true)
            }
          });
        }
        else{
            e.preventDefault();
            alert(`Cannot upload files more than 50`);
        }
      }

   
  return (
    <Modal>
        <HelpPageWrapper>
            <Row>
                <Col sm={11}>
                    <h4>Bulk Image Upload</h4>
                </Col>
            </Row>
            <Row>
                <Col sm={11}>
                    <Form>
                        <Form.Label>Image</Form.Label>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridFile">
                            <Form.Control type="file" accept="image/*"  onChange={handleFileChange} multiple />
                            </Form.Group>
                            </Form.Row>
                            
                            { isUploded? (
                            <Alert variant="success">
                                {message}
                            </Alert>
                        ) : progress && <ProgressBar now={progress} label={`${progress}%`} /> }
                            {( !file  ) ? (
                            <Button variant="primary"  disabled>
                            Upload
                            </Button>
                            
                        ) : (
                            <Button variant="primary" onClick={uploadFile}>
                                Upload
                            </Button>
                        )}
                        
                    </Form>
                </Col> 
            </Row>
        </HelpPageWrapper>
    </Modal>
  );
}

const mapStateToProps = state =>{
    // console.log(state);
     return {
      // brands : state.dashboardReducer.brands
      suppliers : state.dashboardReducer.suppliers,
      success : state.dashboardReducer.success,
      loading : state.dashboardReducer.loading,
      error_message : state.dashboardReducer.error_message
     }
    
   }
   
   const mapDispatchToProps = dispatch => {
     return {
        getSuppliers : data => getSuppliers(data,dispatch),
       setError : data => setError(data,dispatch),
       setLoading : data => setLoading(data,dispatch)
     }
   }
   export default connect(mapStateToProps,mapDispatchToProps)(BulkImageUplodes)
