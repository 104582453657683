import api from "./api";

export default class API {

    static async getCustomers(query, body){
        return api.request(`api/admin/get/customers?filter=${query}`, "POST", body);
    }

    static async getRecentOrdersByCustomer(body){
        return api.request("api/admin/get/order-by-customer", "POST", body);
    }

    static async getBrands(body){
        return api.request(`api/brand/getBrand`, "POST", body);
    }
    static async addBrands(body){
        return api.request(`api/brand/addBrand`, "POST", body);
    }
    static async updateBrands(body){
        return api.request(`api/brand/editBrand`, "POST", body);
    }
    static async getCategory(body){
        return api.request("api/category/getAllStoreCategories", "POST", body);
    }

    static async updatenableDisableCategory(body){
        return api.request(`api/category/merchantUpdateItemRelatedAvailability`, "POST", body);
    }

    static async addCategory(body){
        return api.request("api/category/addCategory", "POST", body);
    }

    static async updateCategory(body){
        return api.request("api/category/editCategory", "POST", body);
    }

    static async getSubCategory(body){
        return api.request("api/category/getAllStoreSubCategories", "POST", body);
    }

    static async updatenableDisableSubCategory(body){
        return api.request(`api/category/merchantUpdateItemRelatedAvailability`, "POST", body);
    }

    static async addSubCategory(body){
        return api.request("api/subCategory/addsubCategory", "POST", body);
    }

    static async updateSubCategory(body){
        return api.request("api/subCategory/editSubCategory", "POST", body);
    }

    static async getProduct(body){
        return api.request("v1/admin/get/menuitem", "POST", body);
    }

    static async searchProduct(body){
        return api.request("api/admin/products/search", "POST", body);
    }

    static async updatenableDisableProduct(body){
        return api.request("api/admin/product/enable", "POST", body);
    }

    static async quantityUpdateProduct(body){
        return api.request("api/admin/product/changeQuantity", "POST", body);
    }

    static async getSuppliers(body){
        return api.request("api/admin/suppliers", "POST", body);
    }
    
    static async getSubCategoryMap(body){
        return api.request("api/category/getAllSubCategoriesMappedCategory", "POST", body);
    }

    static async addProduct(body){
        return api.request("api/admin/product/add", "POST", body);
    }

    static async updateProduct(body){
        return api.request("api/admin/product/update", "POST", body);
    }
    static async deleteProduct(body){
      return api.request("api/admin/product/delete", "POST", body);
    }
    static async getProductById(body){
        return api.request("api/admin/product/get", "POST", body);
    }
    static async getOrders(body){
        return api.request("api/cart/getBasicInfoOrders", "POST", body);
    }
    static async changeOrderStatus(body){
        return api.request("api/cart/changeOrderStatusAdmin", "POST", body);
    }
    static async fatchOrder(body){
        return api.request("api/cart/fetchOrder", "POST", body);
    }
    static async getOrdersListsBySupplier(body){
        return api.request("api/admin/supplier/orders", "POST", body);
    }
    static async updateSupplierCredential(body){
        return api.request("api/admin/supplier/update/credential", "POST", body);
    }
    static async getItemsListsByOrder(body){
        return api.request("api/admin/supplier/order/items", "POST", body);
    }
    static async getItemsOrdersListsBySupplier(body){
        return api.request("v1/admin/get/supplier/order", "POST", body);
    }
    static async updateSupplierItemStatus(body){
        return api.request("api/admin/supplier/update/item", "POST", body);
    }
    static async getSuppliersLists(query, body){
        return api.request(`api/admin/supplier/list?filter=${query.selectedStatus}&search=${query.search}`, "POST", body);
    }
    static async markOrderAs(body){
        return api.request("api/admin/supplier/orders/mark-order-as", "POST", body);
    }
    static async exportOrders(body){
        return api.request("api/admin/supplier/orders/export", "POST", body);
    }
    static async changeBulkOrderStatus(body){
        return api.request("api/cart/changeBulkOrderStatusAdmin", "POST", body);
    }
    static async createPickupFile(body){
        return api.request("api/admin/makePickupFile", "POST", body);
    }
    static async generateInvoice(body){
        return api.request("api/payment/sendEmails", "POST", body);
    }
    static async resendPaymentReport(body){
        return api.request("api/resend/payment-report", "POST", body);
    }
    static async generateBulkInvoice(body){
        return api.request("api/payment/sendBulkEmails", "POST", body);
    }
    static async productExtract(body){
        return api.request("api/admin/product/extract/all", "POST", body);
    }
    static async orderReport(body){
        return api.request("api/admin/order/extract", "POST", body);
    }

    static async updateCatBanner(body){
        return api.request("api/category/updateCatBanner", "POST", body);
    }

    static async updateSubCatBanner(body){
        return api.request("api/subCategory/updateSubCatBanner", "POST", body);
    }

    static async updateHomepageBanner(body){
        return api.request("api/admin/update/hamepageBanner", "POST", body);
    }

    static async updateBanner(body){
        return api.request("api/admin/banner/update", "POST", body);
    }

    static async updateCheckoutBanner(body){
        return api.request("api/admin/update/checkoutBanner", "POST", body);
    }

    static async getBannerImages(body){
        return api.request("api/admin/get/banner", "POST", body);
    }

    static async changeExpressStatus(body){
        return api.request("v1/admin/shop/update", "POST", body);
    }

    // Express Setting
    static async getExpressSetting(){
        return api.getRequest(`v1/admin/get/express/setting`, "GET");
    }

    static async updateExpressSetting(body){
        return api.putRequest(`v1/admin/update/express/setting`, "PUT", body);
    }

    // Shop Setting
    static async getShopSetting() {
        return api.getRequest(`v1/admin/get/shop/setting`, "GET");
    }

    static async updateShopSetting(body){
        return api.putRequest(`v1/admin/update/shop/setting`, "PUT", body);
    }

    // Auth
    static async supplierResetPasswordRequest(body){
        return api.request("api/changeMerchantPasswordRequest", "POST", body);
    }
    static async updateSupplierPassword(body){
        return api.request("api/changeMerchantPassword", "POST", body);
    }

    //  Order API
    static async getOrderListPagination(
        params
    ){
        let query = `statusList=${params.statusList}`;
        if(params.type === "0" || params.type === "1" ){
            query += `&type=${params.type}`
        }

        if(params && params.search) {
            query += `&search=${params.search}`
        }

        if(params && params.startDate){
            query += `&startDate=${params.startDate}`
        }

        if(params && params.endDate){
            query += `&endDate=${params.endDate}`
        }

        if(params && params.orderField){
            query += `&orderField=${params.orderField}`
        }

        if(params && params.orderType){
            query += `&orderType=${params.orderType}`
        }
        return api.getRequest(`v1/admin/get/order/list/${params.next}/${params.limit}?${query}`, "GET");
    }

    static async getOrderListWithoutPagination(
        params
    ){
        let query = `statusList=${params.statusList}`;
        if(params.type === "0" || params.type === "1" ){
            query += `&type=${params.type}`
        }

        if(params && params.search) {
            query += `&search=${params.search}`
        }

        if(params && params.startDate){
            query += `&startDate=${params.startDate}`
        }

        if(params && params.endDate){
            query += `&endDate=${params.endDate}`
        }

        if(params && params.orderField){
            query += `&orderField=${params.orderField}`
        }

        if(params && params.orderType){
            query += `&orderType=${params.orderType}`
        }

        return api.getRequest(`v1/admin/get/order/list?${query}`, "GET");
    }

    static async updateOrder(params, body){
        return api.putRequest(`v1/admin/update/order/${params.orderId}`, "PUT", body, null);
    }

    static async getOrderStatusCount(){
        return api.getRequest(`v1/admin/get/status/count`, "GET");
    }

    static async getOrderBasicList(params){
        let query = `statusList=${params.statusList}&fromDate=${params.fromDate}`;
        return api.getRequest(`v1/admin/order/basic/list?${query}`)
    }

    static async getOrderDetailByOrderId(body){
        return api.request(`api/cart/fetchOrder`, "POST", body)
    }

    // Customer API
    static async updateCustomerEnableExpress(body) {
        return api.putRequest(`v1/admin/user/enableexpress`, "PUT", body, null);
    }

    static async updateCustomer(body) {
        return api.putRequest(`v1/admin/user`, "PUT", body);
    }

    // Driver API
    static async getDriverList(params){
        return api.getRequest(`v1/admin/get/driver/list/${params.next}/${params.limit}?showDisabled=${params.showType}`, "GET");
    }

    static async getInActiveDriverList(params){
        return api.getRequest(`v1/admin/get/driver/list/${params.next}/${params.limit}?showDisabled=${params.showDisabled}`, "GET");
    }

    static async createDriver(body){
        return api.request(`v1/admin/create/driver`, "POST", body);
    }

    static async updateDriver(driverId, body){
        return api.putRequest(`v1/admin/update/driver/${driverId}`, "PUT", body, null);
    }

    // Wallet
    static async getWalletTransactionByCustomerId(params){
        return api.getRequest(`v1/admin/get/wallet/transaction/customerId/${params.customerId}/${params.next}/${params.limit}`, "GET");
    }

    static async addWalletTransaction(body){
        return api.request(`v1/admin/wallet/transfer`, "POST", body);
    }

    // Basket
    static async sendCustomPushToClient(userId, body){
        return api.request(`v1/admin/send/custom/push/${userId}`, "POST", body);
    }

    // Slider
    static async getSliderList(params, query){
        let queryString = `is_deleted=${query.isDeleted}&type=${query.type}`;
        if(query.isActive){
            queryString += `&is_active=${query.isActive}`
        }
        return api.getRequest(`v1/admin/offerslide/${params.next}/${params.limit}?${queryString}`, "GET");
    }

    static async updateSlide(params, body){
        return api.putRequest(`v1/admin/offerslide/${params.slider_id}`, "PUT", body);
    }

    static async createSlide(body){
        return api.request(`v1/admin/offerslide`, "POST", body);
    }

    // Address
    static async UpdateAddress(addressId, body) {
        return api.putRequest(`v1/admin/address/customer/${addressId}`, "PUT", body);
    }

    // Supplier API
    static async getSupplierOrderBySupplierId(body){
        return api.request(`v1/admin/express/get/supplier/order`, "POST", body);
    }
}