import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import {
  OrderBox,
  OrderListWrapper,
  OrderList,
  OrderDetailsWrapper,
  ItemWrapper,
  ItemDetails,
  ItemName,
  ItemSize,
  ItemPrice,
  NoOrderFound,
} from "./Order.style";
import { convertDate, convertTime24to12 } from "../../../constants/Constant";
import OrderDetails from "./SingleOrderDetails/OrderDetails";
import OrderCard from "./OrderCard/OrderCard";
import OrderCardMobile from "./OrderCard/orderCardMobile";
import useComponentSize from "../../../helper/useComponentSize";
import { useCart } from "../../../contexts/cart/use-cart";
import {Modal} from "react-bootstrap";
import Button from "../../../components/Button/Button";
import t from '../../../i18n/translate';
import reactStringReplace from 'react-string-replace';
import * as actionTypes from "../../../store/ActionTypes";

/*
 * status = 0 => Order Saved
 * status = 1 => Order Removed
 * status = 2 => Order Pending
 * status = 3 => Order Processing
 * status = 4 => Order Pick Up
 * status = 5 => Order Delivering
 * status = 6 => Order Delivered
 * status = 7 => Order Canceled
 * status = 8 => Order Arrived
 */
const statusData = ["Saved", "Removed", "Pending", "Processing", "Pick Up", "Delivering", "Delivered", "Canceled", "Arrived"];

const orderTableColumns = [
  {
    title: t('home_basket_items_label', false),
    dataIndex: "",
    key: "items",
    width: 250,
    ellipsis: true,
    render: (text, record) => {
      return (
        <ItemWrapper>
          {/* <ImageWrapper>
            <img src={record.image} alt={record.title} />
          </ImageWrapper> */}

          <ItemDetails>
            <ItemName>{record.item_name}</ItemName>
            <ItemSize>
              {record.weight != null
                ? record.weight + " " + (record.weight_unit != null)
                  ? record.weight_unit
                  : ""
                : record.item_price_name}
            </ItemSize>
            <ItemPrice className="item-price">${parseFloat(record.item_price).toFixed(2)}</ItemPrice>
          </ItemDetails>
        </ItemWrapper>
      );
    },
  },
  {
    title: t('shop_order_list_quantity_label', false),
    dataIndex: "quantity",
    key: "quantity",
    align: "center",
    width: 100,
    render: (text, record) => {
      return <p>{record.quantity}</p>;
    },
  },
  {
    title: t('shop_order_list_price_label', false),
    dataIndex: "",
    key: "price",
    align: "right",
    width: 100,
    render: (text, record) => {
      return (
        <p>${parseFloat(record.quantity * record.item_price).toFixed(2)}</p>
      );
    },
  },
];

const OrdersContent = ({
  deviceType: { mobile, tablet, desktop },
  props,
  pastorders,
  pastorderDetails,
  tabactive,
}) => {
  const {
    fetchOrderItems
  } = useCart();
  const [order, setOrder] = useState(null);
  const [active, setActive] = useState("");
  const [modalshow, setModalShow] = useState(false);
  const lang = localStorage.getItem('lang') || 'en';
  const [message, setMessage] = useState(t('shop_order_list_re_order_success_content_dialog'));

  const [targetRef, size] = useComponentSize();
  const orderListHeight = size.height - 79;

  useEffect(() => {
    if (pastorders && pastorders.length !== 0) {
      if (tabactive == "pastOrder") {
        props.fetchParticularOrder({ order_id: pastorders[0].order_id, lang });
        setOrder(pastorderDetails);
        setActive(pastorders[0].order_id);
      }
    }
  }, [pastorders, tabactive]);

  useEffect(() => {
    if (pastorders && pastorders.length !== 0) {
      setOrder(pastorderDetails);
    }
  }, [pastorderDetails, tabactive == "pastOrder"]);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let parsedUser = JSON.parse(user);
    if (parsedUser) {
      let cust_id = parsedUser.cust_id;
      props.pastOrders({ cust_id: parsedUser.cust_id, lang });
    }
  }, [tabactive]);

  const handleClick = (order) => {
    props.fetchParticularOrder({ order_id: order.order_id, lang });
    setOrder(pastorderDetails);
    setActive(order.order_id);
  };

  const handleOnError = (eventData) => {
    setMessage(reactStringReplace(eventData, /(\n)/g, () => <br />));
    setModalShow(true);
  }
  const handleOnSuccess = (eventData) => {
    setModalShow(true);
  }

  const handleModalClose = () => {
    setModalShow(false);
    props.resetreordersuccess();
    localStorage.setItem("reordered", true);
    fetchOrderItems( props.cartType );
    if(!document.getElementsByClassName("cartPopupFixed").length) {
      document.getElementsByClassName("product-cart")[0].click();
    }
  };

  return (
    <OrderBox>
      {window.innerWidth > 768 ? (
        <>
          <OrderListWrapper style={{ height: size.height }}>
            <Modal
              show={modalshow}
              onHide={handleModalClose}
              backdrop="static"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>{ t('shop_order_list_re_order_success_title_dialog') }</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{color: "green"}}>
                { message }
              </Modal.Body>
              <Modal.Footer>
                <Button
                  title={ t('shop_order_list_button_ok_dialog', false) }
                  variant="primary"
                  onClick={handleModalClose}
                >
                  hello { t('shop_order_list_button_ok_dialog') }
                </Button>
              </Modal.Footer>
            </Modal>
            <Scrollbars
              universal
              autoHide
              autoHeight
              autoHeightMin={420}
              autoHeightMax={isNaN(orderListHeight) ? 500 : orderListHeight}
            >
              <OrderList>
                {typeof pastorders !== 'undefined' && pastorders !== null && pastorders.length !== 0 ? (
                  pastorders.map((order) => (
                    <OrderCard
                      props={props}
                      progressBox={statusData}
                      key={order.order_id}
                      orderId={order.order_id}
                      className={
                        `${order.type ? "express" : "normal"} ${order && order.order_id === active ? "active" : ""}`
                      }
                      status={order.status}
                      date={convertDate(
                        order.placedAt.split(":")[0].split(" ")[0]
                      )}
                      // deliveryTime={order.deliveryTime}
                      amount={order.order_payment_details[0].to_pay}
                      onClick={() => {
                        handleClick(order);
                      }}
                      type="past_orders"
                      onError={handleOnError}
                      onSuccess={handleOnSuccess}
                    />
                  ))
                ) : (
                  <NoOrderFound>
                    { t('shop_order_list_end_of_orders_historic') }
                  </NoOrderFound>
                )}
              </OrderList>
            </Scrollbars>
          </OrderListWrapper>

          <OrderDetailsWrapper ref={targetRef}>
            {order && order.order_id && (
              <OrderDetails
                progressStatus={order.status}
                progressBox={statusData}
                pageType={"past-order"}
                name={
                  (order.list_customer_addresses[0]?.first_name || 'n/a') +
                  " " +
                  (order.list_customer_addresses[0]?.last_name || '')
                }
                phone={order.list_customer_addresses[0]?.mobile_number || 'n/a'}
                country_code={order.list_customer_addresses[0]?.country_code || ''}
                delivery_slot={
                  typeof order.store_on_going_slots !== 'undefined'
                    ? convertDate(order.store_on_going_slots[0].Date) +
                    " | " +
                    convertTime24to12(
                      order.store_on_going_slots[0].start_time.split(" ")[1]
                    ) +
                    " - " +
                    convertTime24to12(
                      order.store_on_going_slots[0].end_time.split(" ")[1]
                    )
                    : null
                }
                // address={order.list_customer_addresses[0].house_number+' '+ order.list_customer_addresses[0].apartment_name+' '+ order.list_customer_addresses[0].street_details+ ' '+order. list_customer_addresses[0].city}
                address={order.list_customer_addresses[0]?.area_details || 'n/a'}
                subtotal={order.order_payment_details[0].item_total}
                discount={
                  order.order_payment_details[0].discount
                    ? order.order_payment_details[0].discount
                    : "0.00"
                }
                deliveryFee={order.order_payment_details[0].delivery_fee}
                grandTotal={order.order_payment_details[0].to_pay}
                comments={order.comments}
                tableData={order.order_item_details}
                columns={orderTableColumns}
                status={order.status}
                props={props}
                orderId={order.order_id}
                type="past_orders"
                onError={handleOnError}
                onSuccess={handleOnSuccess}
              />
            )}
          </OrderDetailsWrapper>
        </>
      ) : (
        <>
        <Modal
              show={modalshow}
              onHide={handleModalClose}
              backdrop="static"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>{ t('shop_order_list_re_order_success_title_dialog') }</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{color: "green"}}>
                { message }
              </Modal.Body>
              <Modal.Footer>
                <Button
                  title={ t('shop_order_list_button_ok_dialog', false) }
                  variant="primary"
                  onClick={handleModalClose}
                >
                  Hello 3 { t('shop_order_list_button_ok_dialog') }
                </Button>
              </Modal.Footer>
            </Modal>
        <OrderList>
          {pastorders != undefined && pastorders.length !== 0 ? (
            pastorders.map((order) => (
              <OrderCardMobile
                order={order}
                orderId={order.order_id}
                key={order.order_id}
                className={order && order.order_id === active ? "active" : ""}
                progressData={statusData}
                progressStatus={order.status}
                tableData={order.order_item_details}
                columns={orderTableColumns}
                pageType={"past-order"}
                props={props}
                onClick={() => {
                  handleClick(order);
                }}
                type="past_orders"
                onError={handleOnError}
                onSuccess={handleOnSuccess}
              />
            ))
          ) : (
            <NoOrderFound>
              { t('shop_order_list_end_of_orders_historic') }
            </NoOrderFound>
          )}
        </OrderList>
        </>
      )}
    </OrderBox>
  );
};

export default OrdersContent;
