import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { usePrevious, useMeasure } from "../../helper/hooks";
import { useHistory, useLocation } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { Frame, Title, Content, Header, IconWrapper } from "./TreeMenu.style";
import * as Icons from "../AllSvgIcon";
import { Popover } from 'react-bootstrap';
import expressService from "../../services/expressService";
const Tree = React.memo(
  ({
    children,
    name,
    icon,
    isOpen,
    onClick,
    onHover,
    depth,
    image,
    ref_id,
    default_ref_id,
    defaultOpen = false,
    cartType
  }) => {
    const previous = usePrevious(isOpen);
    const [bind, { height: viewHeight }] = useMeasure();
    const { height, opacity, transform } = useSpring({
      from: { height: 0, opacity: 0, transform: "translate3d(20px,0,0)" },
      to: {
        height: isOpen ? viewHeight : 0,
        opacity: isOpen ? 1 : 0,
        transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
      },
    });
    const Icon = icon
      ? Icons["Dairy"]
      : depth === "child"
      ? Icons["Minus"]
      : null;
    const PlusIcon = Icons["Plus"];
    const MinusIcon = Icons["Minus"];
    var timer ;
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Popover right</Popover.Title>
        <Popover.Content>
          And here's some <strong>amazing</strong> content. It's very engaging.
          right?
        </Popover.Content>
      </Popover>
    );
    return (
      <Frame depth={depth}>
        <Header onClick={onClick}
         open={isOpen} depth={depth} className={depth+" cat_id"+ref_id} cartType={cartType}>
          <img src={image} width="30" alt={"caticon"} />
          <Title>{name}</Title>
          {isOpen ? (
            <div
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <MinusIcon />
              {""}
            </div>
          ) : (
            <div
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <PlusIcon />{" "}
            </div>
          )}
        </Header>
        <Content
          style={{
            opacity,
            height: isOpen && previous === isOpen ? "auto" : height,
          }}
        >
          <animated.div style={{ transform }} {...bind} children={children} />
        </Content>
      </Frame>
    );
  }
);

export const TreeMenu = ({ data, className, onClick, onHover, active, hoveredactive, props }) => {
  const [subActive, setSubActive] = useState(null);
  const Icon = Icons["Minus"];
  let { pathname, search } = useLocation();
  const history = useHistory();
  let mqs = window.matchMedia("all and (max-width: 768px)");
  const lang = localStorage.getItem('lang') || 'en';
  const cartType = useSelector(state => state.cartReducer.cartType);

  const handleSubCategoryClick = async (child) => {
    if(cartType === 'express') {
      const storeId = props.props.storeSelected.brand_id;
      let itemsStore = null;
      let filteredProducts = {brandIdList: [], categorySelected: [], subCategorySelected: [], outOfStock: 0, sort: ''};
      filteredProducts.subCategorySelected = [child.sub_cat_id];

      if(!Array.isArray(props.props.filteredProducts) && Object.keys(props.props.filteredProducts).length) {
        filteredProducts.brandIdList = props.props.filteredProducts.brandIdList;
        filteredProducts.outOfStock = props.props.filteredProducts.outOfStock;
        filteredProducts.categorySelected = props.props.filteredProducts.categorySelected;
        filteredProducts.sort = props.props.filteredProducts.sort;
      }

      itemsStore = await expressService.storeItems(
        [storeId],
        filteredProducts.brandIdList,
        filteredProducts.categorySelected,
        filteredProducts.subCategorySelected,
        filteredProducts.outOfStock,
        '',
        filteredProducts.sort
      );

      props.props.initProductExpressStore(itemsStore.list);
      props.props.sortProducts(filteredProducts);
    }
    else {
      window.scrollTo(0,0);
      if (child.sub_cat_id === localStorage.getItem("sub_cat_id")) {
        setSubActive(child.sub_cat_id);
      }
      if(props.oos === 1){
        localStorage.setItem("mode","subcatoos");
        props.props.resetPage();
        if(mqs.matches){
          props.props.getProductOutOfStock({
            sub_cat_id: child.sub_cat_id,
            store_id: 1,
            page: 1,
            lang
          })
        }
      }
      else{
        localStorage.setItem("mode","subcat");
        props.props.resetPage();
        if (mqs.matches){
          props.props.getProductsMappedwithSubCat({
            sub_cat_id: child.sub_cat_id,
            store_id: 1,
            page: 1,
            oos: 0,
            lang
          });
        }
      }
      localStorage.setItem("subcatname", child.name);
      localStorage.setItem("sub_cat_id",child.sub_cat_id);
      // if(child.sub_cat_id !== 187 && window.location.pathname === "/"){
      history.push({
        pathname: `/${child.cat_ref_id}/${child.sub_cat_ref_id}`,
        state: { cat_name: child.cat_name, sub_cat_name: child.name },
      });
      // }
      if (mqs.matches){
        if(document.getElementsByClassName("drawer__close")){
          document.getElementsByClassName("drawer__close")[0].click();
        }
      }
      window.location.href = `/${child.cat_ref_id}/${child.sub_cat_ref_id}`;
    }
  }

  const handler = (children) => {
    return children.map((child) => {
      return (
        <Header
          depth={"child"}
          key={child.sub_cat_id}
          style={{
            color:
              subActive == child.sub_cat_id ||
              localStorage.getItem("sub_cat_id") == child.sub_cat_id
                ? "#389244"
                : "#77798C",
          }}
          id={"sub_cat" + child.sub_cat_id}
        >
          <IconWrapper depth={"child"}>
            <Icon />
          </IconWrapper>
          <Title className={"sub_cat_id"+child.sub_cat_id} onClick={() => handleSubCategoryClick(child)}>
            {child.name}
          </Title>
        </Header>
      );
    });
  };

  const handleShowCategoryActive = (subOption) => {
    if(cartType === 'express') {
      if(typeof props.props.filteredProducts.categorySelected !== 'undefined' && props.props.filteredProducts.categorySelected.length) {
        return subOption.cat_id === props.props.filteredProducts.categorySelected[0];
      } else {
        return false;
      }
    }
    else {
      return hoveredactive === "" ?
        active === subOption.list_sub_categories[0].sub_cat_ref_id ||
        active === subOption.list_sub_categories[0].cat_ref_id ||
        subOption.list_sub_categories.some(
          (children) => children.sub_cat_ref_id === active
        )
        : hoveredactive === subOption.list_sub_categories[0].cat_ref_id
    }
  }

  const mainHandler = (children) => {
    return children.map((subOption) => {
      if (!subOption.list_sub_categories) {
        return (
          <Tree
            key={subOption.name}
            name={subOption.name}
            icon={subOption.name}
            image={subOption.image}
            depth="child"
            onClick={() => onClick(subOption.name)}
            onHover={() => onHover(subOption.name)}
            isOpen={active === subOption.name || hoveredactive === subOption.name}
            cartType={cartType}
          />
        );
      }
      return (
       <>
         {cartType !== 'express'
           ? <Tree
             key={subOption.name}
             name={subOption.name}
             icon={subOption.name}
             image={subOption.image}
             ref_id={subOption.cat_ref_id}
             default_ref_id={subOption.list_sub_categories[0].sub_cat_ref_id}
             depth="parent"
             cartType={cartType}
             onClick={() =>
               onClick(
                 subOption.name,
                 subOption.list_sub_categories[0].name,
                 subOption.list_sub_categories[0].sub_cat_id,
                 subOption.list_sub_categories[0].cat_ref_id,
                 subOption.list_sub_categories[0].sub_cat_ref_id,
                 subOption.cat_id,
               )
             }
             onHover={() =>
               onHover(
                 subOption.name,
                 subOption.list_sub_categories[0].name,
                 subOption.list_sub_categories[0].sub_cat_id,
                 subOption.list_sub_categories[0].cat_ref_id,
                 subOption.list_sub_categories[0].sub_cat_ref_id,
                 subOption.cat_id,
               )
             }
             isOpen={handleShowCategoryActive(subOption)}
             list_sub_categories
           >
             {handler(subOption.list_sub_categories)}
           </Tree>
           : null
         }
       </>
      );
    });
  };
  return <>{mainHandler(data)}</>;
};
