import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Wrapper,
  Container,
  LogoWrapper,
  Heading,
  SubHeading,
  HelperText,
  Offer,
  Input,
  Divider,
  LinkButton,
  CheckBoxWrapperR,
  CheckBoxWrapperP
} from "./SignInOutForm.style";
import {
  ButtonGroup
} from "../../containers/CheckoutWithSidebar/CheckoutWithSidebar.style";
import RadioGroup from "../../components/RadioGroup/RadioGroup";
import OOSRadioCard from "../../components/OOSRadioCard/OOSRadioCard";
import Checkbox from "../../components/Checkbox/Checkbox";
import { AuthContext } from "../../contexts/auth/auth.context";
import { FormattedMessage } from "react-intl";
import Select from "react-select";
// import Image from 'components/Image/Image';
// import PickBazar from '../../image/PickBazar.png';
import { connect } from "react-redux";
import { doSignup } from "../../store/actions/authAction";

import * as Constants from "../../constants/Constant";
import countryCodes from "../../data/countrycodes.json";
import Loader from "../../components/Loader/Loader";
import { rewriteURIForGET } from "@apollo/client";
import { display, width } from "styled-system";
import t from '../../i18n/translate';

const SignOutModal = (props) => {
  const { authDispatch } = useContext(AuthContext);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [cemail, setCEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [country_code, setCountryCode] = useState({label:"Cambodia (+855)", value: 855});
  const [nationality, setNationality] = useState({});
  const [userUpdates, setUserUpdates] = useState(0);
  const [userPromos, setUserPromos] = useState(0);
  const [fnerror, setFNError] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [ceerror,setceerror] = React.useState(false);
  const [valid, setValid] = React.useState(true);
  const [errorPN, setErrorPN] = React.useState(false);
  const [errorCC, setErrorCC] = React.useState(false);
  const [regerror, setRegError] = React.useState("");
  const [allcodes, setAllCodes] = React.useState([]);
  const [allnations, setAllNations] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const ccodes = () => {
   const codes = countryCodes.map((country_code)=>{
      return {
        value: country_code.dial_code,
        label: country_code.name + " (+" + country_code.dial_code + ")"
      }
    });
    const nations = countryCodes.map((country_code)=>{
      return {
        value: country_code.name,
        label: country_code.name
      }
    });
    setAllCodes(codes);
    setAllNations(nations);
  };
  const toggleSignInForm = () => {
    authDispatch({
      type: "SIGNIN",
    });
  };
  const registerCallback = async(e) => {
    e.preventDefault();
    if ((Constants.email_reg.test(email)) === false || !email) {
      setError(true);
    }
    if ((Constants.email_reg.test(cemail)) === false || !cemail) {
      setceerror(true);
    }
    if(mobile === ""){
      setErrorPN(true);
    }
    if(firstName === ""){
      setFNError(true);
    }
    if (password === "" || confPassword === "" || password !== confPassword) {
      setValid(false);
    }
    if(country_code === ""){
      setErrorCC(true);
    }
    if (firstName !== "" && !error && valid && !errorPN && mobile !== "" && country_code !== "") {
      setLoading(true);
      props.doSignup({
        email: email,
        password: password,
        phone: mobile,
        last_name: lastName,
        first_name: firstName,
        country_code: country_code.label ? country_code.value : country_code,
        nationality: nationality === {} ? null : nationality.value,
        user_updates: 0,
        user_promos: 0,
      });
      // authDispatch({ type: "VERIFYLINK" });
    }
  };
  const validateEmail = async () => {
    if ((await Constants.email_reg.test(email)) === false || !email) {
      setError(true);
    } else {
      setError(false);
    }
  };
  const validateConfirmationEmail = async () => {
    if ((await Constants.email_reg.test(cemail)) === false || !cemail || email !== cemail ) {
      setceerror(true);
    } else {
      setceerror(false);
    }
  };
  const validatePassword = async () => {
    console.log(valid);
    if (password === confPassword || password === "" || confPassword === "") {
      setValid(true);
    } else {
      setValid(false);
    }
  };
  function isNumber(evt) {
    let telephone = evt.target.value;
    let isnum = /^\d+$/;
    // let regexp = /^[0-9\+\b]+$/;
    if (isnum.test(telephone) && telephone.length <= 10) {
      setMobile(telephone);
      setErrorPN(false);
    }
    else{
      setErrorPN(true);
    }
  }
  useEffect(() => {
    ccodes();
  },[]);
  useEffect(() => {
    setRegError(props.responsemsg);
    setLoading(false);
    if(props.responsemsg === "Registered successfully, Please login."){
      authDispatch({ type: "VERIFYLINK" });
    }
  }, [props.responsemsg]);
  return (
    <Wrapper>
      <Container>
        {/* <LogoWrapper>
          <Image url={PickBazar} />
        </LogoWrapper> */}

        <Heading className={props.cartType}>
          { t('sign_up_screen_title') }
        </Heading>

        <form onSubmit={registerCallback}>
          <Input
            type="text"
            placeholder={ t('sign_up_profile_first_name_input_title', false) }
            className={props.cartType}
            value={firstName}
            onChange={(e) => {
              setfirstName(e.target.value);
            }}
          />
          {fnerror == true ? (
            <span className="text-danger">{ t('sign_up_screen_error_enter_first_name_label') } </span>
          ) : null}
          <Input
            type="text"
            placeholder={ t('sign_up_profile_last_name_input_title', false) }
            className={props.cartType}
            value={lastName}
            onChange={(e) => {
              setlastName(e.target.value);
            }}
          />

          <Input
            type="text"
            placeholder={ t('sign_up_screen_email_input_title', false) }
            className={props.cartType}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onBlur={validateEmail}
          />
          {error == true ? (
            <span className="text-danger">{ t('sign_up_error_invalid_email') }</span>
          ) : null}
          <Input
            type="text"
            placeholder={ t('sign_up_screen_confirm_email_input_title', false) }
            className={props.cartType}
            value={cemail}
            onChange={(e) => {
              setCEmail(e.target.value);
            }}
            onBlur={validateConfirmationEmail}
            onPaste={(e)=>e.preventDefault()}
          />
          {ceerror == true ? (
            <span className="text-danger">{ t('sign_up_screen_error_confirm_email_msg') }</span>
          ) : null}
          <Input
            type="password"
            placeholder={ t('sign_up_screen_password_input_title', false) }
            className={props.cartType}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <Input
            type="password"
            placeholder={ t('sign_up_screen_confirm_password_input_title', false) }
            className={props.cartType}
            value={confPassword}
            onChange={(e) => {
              setConfPassword(e.target.value);
            }}
            onBlur={validatePassword}
          />
          {valid === false ? (
            <span className="text-danger">{ t('sign_up_error_password_mismatch') } </span>
          ) : null}
          <div style={{display: "flex"}}>
            <div style={{display: "block", width: "45%", height: "auto", paddingRight: "10px"}}>
              <Select
                className={`cc ${props.cartType}`}
                // classNamePrefix="select"
                defaultInputValue={country_code.label}
                defaultValue={country_code.label}
                placeholder={ t('sign_up_screen_country_code_label', false) }
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                name="ccodes"
                options={allcodes}
                onChange={(value) => {
                  // alert(value);
                  console.log("selected code is ",value.value);
                  let isnum = /^\d+$/;
                  if(value.value !== {} && isnum.test(value.value)){
                    setCountryCode(value.value)
                  }
                  else{
                    alert(t('sign_up_error_country_code_empty', false))
                  }
                }}
              />
              {errorCC === true ? (
                <span className="text-danger">{ t('sign_up_screen_error_country_code_msg') }</span>
              ) : null }
            </div>
            <div style={{display: "block"}}>
              <Input
                type="text"
                placeholder={ t('sign_up_screen_phone_title', false) }
                value={mobile}
                className={props.cartType}
                onChange={(e) => {
                  isNumber(e);
                  // setMobile(e.target.value);
                }}
                style={{ width: "100%" }}
              />
              {errorPN === true ? (
                <span className="text-danger">{ t('sign_up_error_invalid_phone') }</span>
              ) : null }
            </div>
            {regerror !== "" ? (
              <div>
                <span className={String(props.responsemsg).includes("successfully") ? "text-success" : "text-danger"}>{props.responsemsg}</span>
              </div>
            ) : null }
          </div>
          <Select
            className={`cc ${props.cartType}`}
            // classNamePrefix="select"
            placeholder={ t('sign_up_screen_nationality_title', false) }
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            name="nationality"
            options={allnations}
            onChange={(value) => {
              if(value !== ""){
                setNationality(value)
              }
            }}
          />
          <Button
            fullwidth
            type="submit"
            title={ t('sign_up_profile_continue_button', false) }
            intlButtonId="continueBtn"
            className={props.cartType}
            style={{ color: "#fff", marginTop:"10px" }}
            disabled={loading}
            loader={<Loader />}
            isLoading={loading}
          />
        </form>
        <Divider>
          <span>
            { t('common_or_label') }
          </span>
        </Divider>

        <Offer style={{ padding: "0px 0px 15px 0" }}>
          { t('sign_up_screen_already_have_account_title') }
          {" "}
          <LinkButton onClick={toggleSignInForm} className={props.cartType}>
            { t('sign_up_screen_login_redirect_label') }
          </LinkButton>
        </Offer>
      </Container>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    showOtp: state.authReducer.showOtp,
    responsemsg: state.authReducer.responsemsg,
    cartType: state.cartReducer.cartType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doSignup: (body) => doSignup(body, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignOutModal);
