import React, { useState, useContext, useEffect } from "react";
import OtpInput from "react-otp-input";
import {
  Button,
  Wrapper,
  Container,
  LogoWrapper,
  Heading,
  SubHeading,
  HelperText,
  Offer,
  Input,
  Divider,
  LinkButton,
} from "./SignInOutForm.style";
import { AuthContext } from "../../contexts/auth/auth.context";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { doOTPVerify } from "../../store/actions/authAction";
const OTPverify = (props) => {
  const [OTP, setOTP] = useState("");
  const { authDispatch } = useContext(AuthContext);
  useEffect(() => {
    if (props.otpVerified) {
      authDispatch({ type: "SIGNIN_SUCCESS" });
    }
  }, [props.otpVerified]);
  const toggleSignInForm = () => {
    authDispatch({
      type: "SIGNIN",
    });
  };
  const handleChange = (OTP) => setOTP(OTP);
  const OTPCallback = (e) => {
    e.preventDefault();
    props.doOTPVerify({ email: props.email, otp: parseInt(OTP) });
    // authDispatch({ type: 'SIGNIN_SUCCESS' });
  };
  return (
    <Wrapper>
      <Container>
        <Heading>
          <FormattedMessage
            id="verifyOTP"
            defaultMessage="Enter one time password received on email"
          />
        </Heading>
        <form onSubmit={OTPCallback}>
          <div>
            <OtpInput
              value={OTP}
              onChange={(otp) => setOTP(otp)}
              numInputs={4}
              separator={<span style={{ margin: "0 10px" }}>-</span>}
              inputStyle={{ padding: 5, width: "30px" }}
              containerStyle={{ padding: 15, justifyContent: "center" }}
            />
          </div>

          <Button
            fullwidth
            type="submit"
            title={"Continue"}
            intlButtonId="continueBtn"
            style={{ color: "#fff" }}
          />
        </form>

        <Offer style={{ padding: "0px 0px 15px 0" }}>
          <FormattedMessage
            id="alreadyHaveAccount"
            defaultMessage="Back to Login?"
          />{" "}
          <LinkButton onClick={toggleSignInForm}>
            <FormattedMessage id="loginBtnText" defaultMessage="Login" />
          </LinkButton>
        </Offer>
      </Container>
    </Wrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    email: state.authReducer.email,
    success: state.authReducer.success,
    userData: state.authReducer.userData,
    otpVerified: state.authReducer.otpVerified,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doOTPVerify: (body) => doOTPVerify(body, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OTPverify);
