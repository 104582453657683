import React, { useEffect, useState, useRef } from "react";
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import CustomCarousel from "../components/Carousel/Carousel";
import Button from "react-bootstrap/Button";
import PromoProducts from "../containers/PromoProducts/PromoProducts";
import NewProducts from "../containers/NewProducts/NewProducts";
import TrendingProducts from "../containers/TrendingProducts/TrendingProducts";
import BrandsHome from "../containers/BrandsHome/BrandsHome";
import ReviewCard from "../containers/ReviewCard/ReviewCard";
import CartPopUp from "../containers/Cart/CartPopUp";
import {
  MainContentArea,
  ContentSection,
  OfferSection,
} from "../styled/pages.style";

import delishopExpressIcon from '../assets/icons/delixpress_logo.svg';
import {ExpressStyle} from "../styled/express.style";

import { useDeviceType } from "../helper/useDeviceType";
import { setCookie, allslots } from "../helper/constant";
import queryString from "query-string";
import {connect} from "react-redux";
import {
  productCategory,
  ProductModalData,
  searchSuggestions,
  searchStoreProduct,
  getProductsMappedwithSubCat,
  getProductsMappedwithCat,
  getProductOutOfStock,
  getProductOutOfStockCat,
  getPromotionProducts,
  getArrivalProducts,
  getTrendingProducts,
  getProductBrands,
  getProductFilterBrands,
  getSortProductsName,
  getSortProductsPrice,
  sortByNameProduct,
  Reset_Suggestions,
  changePage,
  resetPage
} from "../store/actions/productAction";
import {
  slotsForDate,
  alldeliverySlots,
} from "../store/actions/userAction";
import {
 oosModified,
} from "../store/actions/globalAction";
import {
  useCart
} from "../contexts/cart/use-cart";
import { offerSlider } from "../store/actions/globalAction";
import API from "../network/dashboardAPI"
import AvailableDeliverySlotModal from '../components/AvailableDeliverySlot/AvailableDeliverySlotModal';
import { MemoizedWelcomePopup } from "../components/WelcomePopup/WelcomePopup";
import t from '../i18n/translate';
import expressService from "../services/expressService";

const PAGE_TYPE = "grocery";

const useRouter = () => {
  const params = useParams();

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  return React.useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      },
      match,
      location,
      history,
    };
  }, [params, match, location, history]);
};

const HomePage = (props, { userAgent }) => {
  const deviceType = useDeviceType(userAgent);
  const { query, history } = useRouter();
  const targetRef = React.useRef(null);
  const {
    fetchOrderItems,
    cartclose,
  } = useCart();
  const [twodslots, settwodslots] = useState([]);
  const [allSLOTS, setAllSlots] = useState([]); 
  const [showSchedule, setShowSchedule] = useState(false);
  const [enableExpress, setEnableExpress] = useState(false);

  const [homepage_banner, setHomepageBanner] = useState()
  const [homepage_link_banner, setHomepageLinkBanner] = useState();
  const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false);
  const user = localStorage.getItem('user');
  
  window.onbeforeunload = () => {
    cartclose();
  };

  const initData = async () => {
    const [shopSetting] = await Promise.all([
      expressService.getShopSetting(),
      props.offerSlider({ store_id: 1 }), // Slide show
      props.getPromotionProducts({sub_cat_id: 187, store_id: 1}), // Promotion
      props.getArrivalProducts({sub_cat_id: 187, store_id: 1}), // New Arrivals
      props.getTrendingProducts({sub_cat_id: 187, store_id: 1}), // Popular
      props.getProductBrands(), // Brand slideshow
      API.getBannerImages().then(res => res.json())
        .then(res=>{
          setHomepageBanner(res.data[0].homepage)
          setHomepageLinkBanner(res.data[0].homepageLink)
        }),
    ]);

    if(shopSetting.status === 200) {
      setEnableExpress(shopSetting.data.enableExpress);
      setOpenWelcomeDialog(shopSetting.data.enableExpress);
    }
    return await Promise.all([shopSetting]);
  }

  useEffect(async () => {
    localStorage.removeItem('expressMode');
    await initData();
  }, []);

  useEffect(() => {
    if(user) {
      fetchOrderItems()
    }
  }, [user])

  useEffect(() => {
    document.getElementsByClassName("product-cart")[0].style.visibility = "hidden";
    if(localStorage.getItem("order_id") === undefined || localStorage.getItem("order_id") === null) {
      localStorage.setItem("order_id", 0)
    }

    /*if (props.category.length === 0) {
        redAPI.Category({ get_type: 2, store_id: 1, lang })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              dispatch({
                type: actionTypes.Product_Category,
                payload: data.data,
                success: data.success,
              });
            } else {
              // alert(data.error)
            }
          })
      }*/

    if(window.location.pathname === "/register"){
      setCookie('homelockdownn','seen', 20);
      if(document.getElementById("joinBTn")){
        document.getElementById("joinBTn").click();
      }
      else{
        alert("You are already logged in!");
      }
    }
    if(localStorage.getItem("user")){
      if (localStorage.getItem("reordered")){
        localStorage.setItem("reordered",false);
        document.getElementsByClassName("product-cart")[0].click();
      }
    }
  },[]);

  useEffect(() => {
    if ((query.text || query.category) && targetRef.current) {
      window.scrollTo({
        top: targetRef.current.offsetTop - 110,
        behavior: "smooth",
      });
    }
  }, [query]);

  useEffect(() => {
    if(props.all_slots !== undefined && props.all_slots[0] !== undefined) {
      setAllSlots(allslots(props.calendar_response));
      const newArr = [];
      const allSlots = props.all_slots;
      while(allSlots.length) newArr.push(allSlots.splice(0,props.delivery_slots.length));
      settwodslots(newArr);
    }
  }, [props.delivery_slots]);

  const handleDeliverySlotCloseModal = (status = false) => setShowSchedule(status);
  const onSlotSelected = () => {};
  
  return (
    <>
      {/*{
        <>
          <Helmet defer={false}>
            <link 
              rel="canonical" 
              href={"https://delishop.asia/"} 
            />
            <title>Delishop - First Online Supermarket in Cambodia</title>
            <meta 
              name="title" 
              content='Delishop - First Online Supermarket in Cambodia'
            />
            <meta 
              property="og:title" 
              content='Delishop - First Online Supermarket in Cambodia' 
            />
            <meta 
              property="og:image" 
              content="https://delishop.s3-ap-southeast-1.amazonaws.com/ds-ad.jpeg" 
            />
            <meta 
              property="og:description" 
              content={"Get all your favorite products from the best shops of Phnom Penh. More than 10,000 products to choose from"} 
            />
            <meta 
              name="description" 
              content={"Get all your favorite products from the best shops of Phnom Penh. More than 10,000 products to choose from"} 
            />
            <meta
              name="keywords"
              content="bakery phnom penh, beef cambodia, beer cambodia, beer in cambodia, cheese cambodia, cheese phnom penh, coffee cambodia, coffee shop, cosmetics cambodia, deli market, deli shop, delimarket, delishop, delishop asia, delishop asia phnom penh, delishop cambodia, delishop phnom penh, delishop.asia, delivery phnom penh, fish phnom penh, fruit cambodia, fruit phnom penh, grocery, grocery delivery, grocery delivery phnom penh, grocery shopping, grocery store, meat cambodia, online groceries, online grocery, online grocery cambodia, online shop, online shopping, online supermarket, online supermarket phnom penh, organic, organic vegetables, phnom penh delivery, royal canin, shampoo cambodia, shampoo in cambodia, soda shop, supermarket cambodia, supermarket online shopping, tea in cambodia, tea phnom penh, the deli shop, vegetables cambodia, vegetables fresh, wine phnom penh"
              data-react-helmet="true"
            />
          </Helmet>
        </>
      }*/}
      <div>
        {deviceType.desktop
          ? <OfferSection>
            <div className="offer-desktop">
              <ExpressStyle style={{ marginTop: 20 }}>
                <Button variant="primary"
                        className="btn-check-availability"
                        onClick={() => {
                          if (localStorage.getItem("user")) {
                            setShowSchedule(true);
                            //props.alldeliverySlots({order_id: localStorage.getItem("order_id")});
                          }
                          else{
                            alert( t('login_error_not_logged_in') );
                          }
                        }}
                ><i className="fa fa-calendar"></i> {t('shop_list_slot_button_text')}</Button>
                {enableExpress
                  ? <>
                    <div className="divider">{ t('common_or_label') }</div>
                    <a href="/express" className="btnExpress">
                      <img src={delishopExpressIcon} alt="Delishop Express" width="100%" height="44" />
                    </a>
                  </>
                  : null
                }
              </ExpressStyle>

              <CustomCarousel deviceType={deviceType} props={props} />
            </div>
          </OfferSection>
          : <OfferSection>
          <div className="offer-mobile">
            <CustomCarousel deviceType={deviceType} data={props.sliders} />
          </div>
        </OfferSection>}
        <MainContentArea>
          <ContentSection id="productBlock" style={{width: "100%"}}>
            {(
              <PromoProducts
              type={PAGE_TYPE}
              deviceType={deviceType}
              fetchLimit={16}
              props={props}
              />
            )}
             <NewProducts
              type={PAGE_TYPE}
              deviceType={deviceType}
              fetchLimit={16}
              props={props}
             />
            {(homepage_banner)
              ? <a target="_blank" rel="noopener noreferrer" href={homepage_link_banner}>
                <img src={homepage_banner} alt="Delishop Fresh" width={"600"} height={"70"} style={{margin: "20px auto", width: "100%", height: "auto"}} />
              </a>
              : null
            }
            {(
            <TrendingProducts
              type={PAGE_TYPE}
              deviceType={deviceType}
              fetchLimit={16}
              props={props}
            />
            )}
            {<BrandsHome deviceType={deviceType} props={props} />}
            {<ReviewCard />}
          </ContentSection>
        </MainContentArea>

        <CartPopUp deviceType={deviceType} />
        <AvailableDeliverySlotModal show={showSchedule}
                                    handleCloseDialog={handleDeliverySlotCloseModal}
                                    delivery_slots={props.delivery_slots}
                                    twodslots={twodslots}
                                    allSLOTS={allSLOTS}
                                    selectedSlot={null}
                                    onSlotSelected={onSlotSelected}
                                    all_timings={props.all_timings}
                                    pageName="home"
        />

        <MemoizedWelcomePopup openDialog={openWelcomeDialog} />
      </div>
    </>
  );
};
HomePage.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers["user-agent"] : navigator.userAgent;
  return { userAgent };
};
const mapStateToProps = (state) => {
  return {
    success: state.authReducer.success,
    modalData: state.productReducer.modalData,
    products: state.productReducer.products,
    allproducts: state.productReducer.allproducts,
    promoproducts: state.productReducer.promoproducts,
    arrivalproducts: state.productReducer.arrivalproducts,
    trendingproducts: state.productReducer.trendingproducts,
    category: state.productReducer.category,
    sliders: state.globalReducer.sliders,
    loading: state.globalReducer.loading,
    promoloading: state.globalReducer.promoloading,
    arrivalloading: state.globalReducer.arrivalloading,
    trendloading: state.globalReducer.trendloading,
    brands: state.productReducer.brands,
    filterBrands: state.productReducer.filterBrands,
    FilteredNameProducts: state.productReducer.FilteredNameProducts,
    filteredProducts: state.productReducer.filteredProducts,
    suggested_subcats: state.productReducer.suggested_subcats,
    oos: state.globalReducer.oos,
    togglesuggestion: state.productReducer.togglesuggestion,
    page: state.productReducer.page,
    cat_refs: state.productReducer.cat_refs,
    slots_for_date: state.userReducer.slots_for_date,
    all_slots: state.userReducer.all_slots,
    all_timings: state.userReducer.all_timings,
    calendar_response: state.userReducer.calendar_response,
    delivery_slots: state.userReducer.delivery_slots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productCategory: (data) => productCategory(data, dispatch),
    ProductModalData: (data) => ProductModalData(data, dispatch),
    searchSuggestions: (data) => searchSuggestions(data, dispatch),
    searchStoreProduct: (data) => searchStoreProduct(data, dispatch),
    getProductsMappedwithSubCat: (data) => getProductsMappedwithSubCat(data, dispatch),
    getProductsMappedwithCat: (data) => getProductsMappedwithCat(data, dispatch),
    offerSlider: (data) => offerSlider(data, dispatch),
    getProductOutOfStock: (body) => getProductOutOfStock(body, dispatch),
    getProductOutOfStockCat: (body) => getProductOutOfStockCat(body, dispatch),
    getPromotionProducts: (body) => getPromotionProducts(body, dispatch),
    getArrivalProducts: (body) => getArrivalProducts(body, dispatch),
    getTrendingProducts: (body) => getTrendingProducts(body, dispatch),
    getProductBrands: () => getProductBrands(dispatch),
    getSortProductsName: (type, products) =>
      getSortProductsName(type, products, dispatch),
    getSortProductsPrice: (type, products) =>
      getSortProductsPrice(type, products, dispatch),
    getProductFilterBrands: (body, products, allproducts, sub_cat_id, cat_id, oos, text) =>
      getProductFilterBrands(body, products, allproducts, sub_cat_id, cat_id, oos, text, dispatch),
    sortByNameProduct: (type, products) =>
      sortByNameProduct(type, products, dispatch),
    oosModified: (data) => oosModified(data, dispatch),
    Reset_Suggestions: () => Reset_Suggestions(dispatch),
    changePage: (body) => changePage(body, dispatch),
    resetPage: () => resetPage(dispatch),
    slotsForDate: (data) => slotsForDate(data, dispatch),
    alldeliverySlots: (data) => alldeliverySlots(data, dispatch),
    // fetchCart: (body) => fetchCart(body, dispatch), 
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
