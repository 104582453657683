import { LOCALES } from '../locales'

export default {
  [LOCALES.km]: {
    "button_add_new": "បន្ថែម​ថ្មី",
    "button_add_to_cart": "ដាក់ក្នុងបញ្ចារទិញ",
    "button_close_label": "បិទ",
    "button_edit_cart": "កែសម្រួល",
    "call_notification_allow_camera_microphone_permission": "អនុញ្ញាតឱ្យប្រើកាមេរ៉ានិងមីក្រូហ្វូនរបស់អ្នកដើម្បីទទួលយកការហៅ",
    "call_notification_allow_camera_permission": "អនុញ្ញាតឱ្យប្រើកាមេរ៉ារបស់អ្នកដើម្បីទទួលយកការហៅ",
    "call_notification_allow_microphone_permission": "អនុញ្ញាតឱ្យប្រើមីក្រូហ្វូនរបស់អ្នកដើម្បីទទួលយកការហៅ",
    "call_notification_audio_status": "សូមចុចដើម្បីត្រលប់ទៅកាន់ហៅ {path}",
    "call_notification_end_button": "បញ្ចប់",
    "call_notification_end_group_audio_call": "{path} បញ្ចប់ការហៅ",
    "call_notification_end_group_video_call": "{path} បញ្ចប់ការហៅវីដេអូ",
    "call_notification_group_audio_status": "សូមចុចដើម្បីត្រលប់ទៅកាន់ការហៅក្នុងក្រុម {path}",
    "call_notification_group_video_status": "សូមចុចដើម្បីត្រលប់ទៅកាន់ការហៅវីដេអូក្នុងក្រុម {path}",
    "call_notification_video_status": "សូមចុចដើម្បីត្រលប់ទៅកាន់ហៅវីដេអូ {path}",
    "call_screen_answer_button": "ទទួល",
    "call_screen_audio_mute_status": "បានបិទសម្លេង",
    "call_screen_audio_video_disable_status": "សម្លេង និង វីដេអូត្រូវបានបិទ",
    "call_screen_bluetooth_button": "ប្ល៊ូធូស",
    "call_screen_busy_label": "{path} កំពុងជាប់រវល់ សូម​ព្យាយាម​ម្តង​ទៀត​នៅ​ពេល​ក្រោយ",
    "call_screen_call_end_label": "ការហៅបានបញ្ចប់",
    "call_screen_call_label": "កំពុងហៅ...",
    "call_screen_call_lost_connection_label": "ការហៅរបស់អ្នកត្រួូវបានផ្ដាច់",
    "call_screen_camera_off_button": "បិទកាមេរ៉ា",
    "call_screen_camera_on_button": "បើកកាមេរ៉ា",
    "call_screen_cancel_button": "បោះបង់",
    "call_screen_chat_button": "ជជែក",
    "call_screen_close_button": "បិទ",
    "call_screen_connect_label": "កំពុងតភ្ជាប់...​",
    "call_screen_decline_button": "បដិសេធ",
    "call_screen_deny_button": "បដិសេធ",
    "call_screen_group_incoming_call_label": "{path} ចង់ឲ្យអ្នកចូលរួមការហៅ",
    "call_screen_group_incoming_video_call_label": "{path} ចង់ឲ្យអ្នកចូលរួមការហៅវីដេអូ",
    "call_screen_hang_up_button": "បិទ",
    "call_screen_incoming_call_label": "កំពុងហៅមកអ្នក...",
    "call_screen_incoming_video_call_label": "កំពុងហៅវីដេអូមកអ្នក...",
    "call_screen_message_button": "សារ",
    "call_screen_mute_button": "បិទសម្លេង",
    "call_screen_no_answer_label": "ពុំមាន​ការឆ្លើយតបទេ",
    "call_screen_option_button": "ជម្រើស",
    "call_screen_other_device_answered_label": "អ្នកបានឆ្លើយតបនៅក្នុងឧបករណ៍ផ្សេងទៀត",
    "call_screen_out_of_work_hour_label": "អ្នកមិនអាចហៅចេញនៅក្រៅម៉ោងធ្វើការទេ",
    "call_screen_phone_button": "ទូរស័ព្ទ",
    "call_screen_receive_request_share_video_label": "ស្នើសុំអោយអ្នកបើកវីដេអូ",
    "call_screen_ring_label": "កំពុងរោទិ៍...",
    "call_screen_share_audio_label": "បើកសម្លេង",
    "call_screen_share_back_button": "អនុញ្ញាតិបើកកាមេរ៉ា",
    "call_screen_share_video_label": "បើកវីដេអូ",
    "call_screen_speaker_button": "បើកសម្លេង",
    "call_screen_tap_return_screen": "Tap to return to call",
    "call_screen_try_again_button": "ព្យាយាម​ម្តងទៀត",
    "call_screen_unmute_button": "បើកសម្លេង",
    "call_screen_video_disable_status": "បានបិទវីដេអូ",
    "call_screen_wait_request_share_video_label": "កំពុងស្នើសុំអោយបើកវីដេអូ...",
    "call_screen_waiting_label": "កំពុងរង់ចាំអ្នកផ្សេងអោយចូលរួម",
    "call_toast_already_in_call_description": "អ្នកកំពុងស្ថិតនៅក្នុងការហៅ",
    "call_toast_already_in_call_title": "មិនអាចធ្វើការហៅចេញបានទេ",
    "call_toast_join_call": "{path} បានចូលការហៅ",
    "call_toast_left_call": "{path} បានចាកចេញពីការហៅ",
    "call_toast_no_answer": "{path} មិនមានការឆ្លើយតប",
    "call_toast_no_call_feature": "អ្នកមិនមានសិទ្ធិក្នុងការប្រើមុខងារការហៅទេ",
    "call_toast_no_internet": "អ្នកត្រូវភ្ជាប់ជាមួយប្រព័ន្ទអ៊ីនធឺណិត ឬ វ៉ាយហ្វាយដើម្បីធ្វើការហៅចេញ",
    "call_toast_reject_call": "{path} បានបដិសេធការហៅ",
    "camera_screen_photo_title": "រូបថត",
    "camera_screen_save_button": "រក្សាទុក",
    "camera_screen_video_title": "វីដេអូ",
    "change_password_and_confirm_didnt_match": "ពាក្យសម្ងាត់របស់អ្នកមិនត្រូវគ្នាទេ!",
    "change_password_and_confirm_matched": "ពាក្យសម្ងាត់ត្រូវគ្នា",
    "change_password_cancel_button": "បោះបង់",
    "change_password_change_button": "ផ្លាស់ប្តូរ",
    "change_password_confirm_password_input_hint": "បញ្ជាក់​ពាក្យសម្ងាត់​ថ្មី",
    "change_password_confirm_password_title": "បញ្ជាក់​ពាក្យសម្ងាត់​ថ្មី",
    "change_password_description": "ផ្លាស់ប្តូរពាក្យសំងាត់របស់អ្នក",
    "change_password_empty_error": "empty",
    "change_password_error_change_same_password": "មិនអាចប្ដូរទៅពាក្យសម្ងាត់ដដែលទេ សូមបញ្ចូលពាក្យសម្ងាត់ថ្មី",
    "change_password_error_incorrect_password": "ពាក្យ​សម្ងាត់មិនត្រឹមត្រូវ",
    "change_password_error_password_format": "ពាក្យសម្ងាត់របស់អ្នកត្រូវមានយ៉ាងហោចណាស់ ពី៨-១៦ តួអក្សរ និងត្រូវមានយ៉ាងហោចណាស់ អក្សរធំ ១ អក្សរតូច ១ និង លេខ ១",
    "change_password_first_time_info": "ឥឡូវអ្នកប្រើពាក្យសម្ងាត់ដែលផ្តល់ដោយយើង យើងស្នើឱ្យអ្នកផ្លាស់ប្តូរពាក្យសម្ងាត់ដើម្បីភាពងាយស្រួលជាងមុន",
    "change_password_new_password_input_hint": "បញ្ចូលពាក្យសម្ងាត់ថ្មី",
    "change_password_new_password_title": "ពាក្យសម្ងាត់ថ្មី",
    "change_password_ok_continue_button": "បន្ត",
    "change_password_old_password_input_hint": "បញ្ចូលពាក្យសម្ងាត់ចាស់",
    "change_password_old_password_title": "ពាក្យសម្ងាត់ចាស់",
    "change_password_otp_input_hint": "បញ្ចូលលេខកូដ OTP ដែលផ្ញើទៅអ៊ីមែលរបស់អ្នក",
    "change_password_otp_title": "លេខកូដ OTP",
    "change_password_popup_success_reset_password_description": "ពាក្យសម្ងាត់របស់អ្នកត្រូវបានកែប្រែដោយជោគជ័យ",
    "change_password_reset_description": "ត្រួតពិនិត្យអ៊ីម៉ែលរបស់អ្នកដើម្បីទទួលបានលេខកូដ ៤ខ្ទង់",
    "change_password_reset_not_receive_otp_title": "មិនទាន់ទទួលបានទេ?",
    "change_password_reset_resend_otp_success": "លេខកូដ OTP ត្រូវបានផ្ញើទៅអ៊ីមែលរបស់អ្នកដោយជោគជ័យ",
    "change_password_reset_resend_otp_title": "ផ្ញើ OTP ឡើងវិញ",
    "change_password_reset_title": "កំណត់ពាក្យសម្ងាត់របស់អ្នក",
    "change_password_success_message": "ពាក្យសម្ងាត់បានប្តូរដោយជោគជ័យ",
    "change_password_title": "ប្ដូរលេខសំងាត់",
    "change_password_update_button": "កែប្រែ",
    "chat_delivered_status": "បានទទួល",
    "chat_failed_status": "បរាជ័យ",
    "chat_header_active_status": "សកម្ម",
    "chat_header_call_button": "ការហៅ",
    "chat_header_join_button": "ចូលរួម",
    "chat_header_offline_status": "ក្រៅបណ្ដាញ",
    "chat_header_participants": "សមាជិក",
    "chat_info_added_someone": "បានបន្ថែម",
    "chat_info_audio_called_you": "បានហៅអ្នក",
    "chat_info_badge_new": "ថ្មី",
    "chat_info_button_call": "ការហៅ",
    "chat_info_button_join": "ចូលរួម",
    "chat_info_change_group_name": "បានផ្លាស់ប្តូរឈ្មោះក្រុមទៅ {path}",
    "chat_info_change_group_picture": "បានផ្លាស់ប្តូររូបភាពក្រុម",
    "chat_info_create_new_group": "Welcome to {path}.",
    "chat_info_edited_header_message": "បានកែសម្រួល",
    "chat_info_end_group_audio_message": "ការហៅបានបញ្ចប់",
    "chat_info_end_group_video_message": "ការហៅវីដេអូបានបញ្ចប់",
    "chat_info_missed_your_call_message": "បានខកខានការហៅរបស់អ្នក",
    "chat_info_missed_your_video_call_message": "បានខកខានការហៅវីដេអូ",
    "chat_info_remove_message": "បានលុបសារ",
    "chat_info_removed_someone": "បានដក",
    "chat_info_send_file_message": "បានផ្ញើសារឯកសារ",
    "chat_info_send_gif_message": "បានផ្ញើសារជា GIF",
    "chat_info_send_n_gifs_message": "បានផ្ញើ {path} GIF",
    "chat_info_send_n_medias_message": "បានផ្ញើ {path} ឯកសារ",
    "chat_info_send_n_photos_message": "បានផ្ញើ {path} រូបភាព",
    "chat_info_send_n_videos_message": "បានផ្ញើ {path} វីដេអូ",
    "chat_info_send_photo_message": "បានផ្ញើរូបភាព",
    "chat_info_send_tenor_gif_message": "បានផ្ញើ GIF ពី Tenor Gif",
    "chat_info_send_video_message": "បានផ្ញើវីដេអូ",
    "chat_info_send_voice_message": "បានផ្ញើសារជាសំលេង",
    "chat_info_someone_and_someone": "និង",
    "chat_info_someone_from_group": "ចេញពីក្រុម",
    "chat_info_someone_to_group": "ទៅក្នុងក្រុម",
    "chat_info_start_group_audio_message": "{path} បានចាប់ផ្តើមការហៅចេញ",
    "chat_info_start_group_video_message": "{path} បានចាប់ផ្តើមការហៅចេញជាវីដេអូ",
    "chat_info_typing": "​កំពុង​វាយ​សារ...",
    "chat_info_video_called_you": "បានហៅវីដេអូជាមួយអ្នក",
    "chat_info_you_audio_called": "អ្នកបានហៅ",
    "chat_info_you_chat": "អ្នក",
    "chat_info_you_missed_a_call_message": "អ្នកបានខកខានការហៅចូលពី {path}",
    "chat_info_you_missed_a_video_call_message": "អ្នកបានខកខានការហៅជាវីដេអូពី {path}",
    "chat_info_you_remove_message": "អ្នកបានលុបសារ",
    "chat_info_you_send_file_message": "អ្នកបានផ្ញើសារជាឯកសារ",
    "chat_info_you_send_gif_message": "អ្នកបានផ្ងើសារជា GIF",
    "chat_info_you_send_n_gifs_message": "អ្នកបានផ្ញើ {path} GIF",
    "chat_info_you_send_n_medias_message": "អ្នកបានផ្ញើ {path} ឯកសារ",
    "chat_info_you_send_n_photos_message": "អ្នកបានផ្ញើ {path} រូបភាព​​",
    "chat_info_you_send_n_videos_message": "អ្នកបានផ្ញើ {path} វីដេអូ",
    "chat_info_you_send_photo_message": "អ្នកបានផ្ញើរូបភាព",
    "chat_info_you_send_tenor_gif_message": "អ្នកបានផ្ញើ GIF ពី Tenor Gif",
    "chat_info_you_send_video_message": "អ្នកបានផ្ញើវីដេអូ",
    "chat_info_you_send_voice_message": "អ្នកបានផ្ញើសារជាសំលេង",
    "chat_info_you_start_group_audio_message": "អ្នកបានចាប់ផ្តើមការហៅចេញ",
    "chat_info_you_start_group_video_message": "អ្នកបានចាប់ផ្តើមការហៅចេញជាវីដេអូ",
    "chat_info_you_video_called": "អ្នកបានហៅវីដេអូ",
    "chat_input_edit_message_title": "កែសម្រួល​សារ",
    "chat_input_hint": "វាយសារ...",
    "chat_input_voice_slide_hint": "រុញដើម្បីបោះបង់",
    "chat_message_add_or_remove_you": "អ្នក",
    "chat_message_added_someone": "បានបន្ថែម",
    "chat_message_audio_call_end": "ការហៅបានបញ្ចប់",
    "chat_message_call_again": "ហៅ​ម្តង​ទៀត",
    "chat_message_call_at": "ស្ថិតនៅ",
    "chat_message_call_back": "ហៅទៅវិញ",
    "chat_message_called_you": "បានបញ្ចប់ការហៅមួយអ្នក",
    "chat_message_change_group_name": "បានប្ដូរឈ្នោះក្រុមទៅ {path}",
    "chat_message_change_group_picture": "ផ្លាស់ប្តូររូបភាពក្រុម",
    "chat_message_file_download": "ទាញយក",
    "chat_message_file_open": "បើក",
    "chat_message_file_upload_download_progress": "{path} នៃ {path}",
    "chat_message_missed_your_call_message": "ខកខានទទួលការហៅរបស់អ្នក",
    "chat_message_missed_your_video_call_message": "ខកខានទទួលការហៅជាវីដេអូរបស់អ្នក",
    "chat_message_remove_message": "បានលុបសារ",
    "chat_message_removed_someone": "បានដក",
    "chat_message_see_more": "មើល​បន្ថែម​",
    "chat_message_someone_and_someone": "និង",
    "chat_message_someone_from_group": "ចេញពីក្រុម",
    "chat_message_someone_to_group": "ទៅក្នុងក្រុម",
    "chat_message_start_group_audio": "បានចាប់ផ្ដើមការហៅចេញ",
    "chat_message_start_group_video": "បានចាប់ផ្ដើមការហៅចេញជាវីដេអូ",
    "chat_message_typing_more_user": "more...",
    "chat_message_video_call_end": "ការហៅវីដេអូបានបញ្ចប់",
    "chat_message_video_called_you": "បានបញ្ចប់ការហៅវីដេអូជាមួយអ្នក",
    "chat_message_welcome_description": "Be the first one to start conversation...",
    "chat_message_welcome_group": "Welcome, {path}",
    "chat_message_you_called": "អ្នកបានបញ្ចប់ការហៅ",
    "chat_message_you_missed_a_call_message": "អ្នកខកខានទទួលការហៅ",
    "chat_message_you_missed_a_video_call_message": "អ្នកបានខកខានទទួលការហៅជាវីដេអូ",
    "chat_message_you_remove_message": "អ្នកបានលុបសារ",
    "chat_message_you_start_group_audio": "អ្នកបានចាប់ផ្ដើមការហៅចេញ",
    "chat_message_you_start_group_video": "អ្នកបានចាប់ផ្ដើមការហៅចេញជាវីដេអូ",
    "chat_message_you_video_called": "អ្នកបានបញ្ចប់ការហៅវីដេអូ",
    "chat_more_text_screen_title": "Message",
    "chat_notification_mention_you_in_message": "{path} បាននិយាយពីអ្នក",
    "chat_notification_reply_button": "ឆ្លើយតប",
    "chat_notification_reply_input_hint": "ឆ្លើយតបនៅទីនេះ...",
    "chat_notification_send_button": "ផ្ញើ",
    "chat_notification_send_encrypted_text": "ផ្ញើ​សារ​មួយ",
    "chat_notification_view_button": "មើលសារ",
    "chat_popup_confirm_delete_description": "តើអ្នកពិតជាចង់លុបសារនេះមែនទេ?",
    "chat_popup_confirm_delete_title": "លុប​សារ",
    "chat_popup_copy_text_title": "ចម្លង",
    "chat_popup_edit_title": "កែប្រែ",
    "chat_popup_forward_title": "បញ្ចូនបន្ត",
    "chat_popup_no_recipient": "គ្មានអ្នកទទួលដើម្បីផ្ញើសារ",
    "chat_popup_remove_title": "លុប",
    "chat_popup_reply_title": "ឆ្លើយតប",
    "chat_popup_reveal_in_finder_title": "បង្ហាញក្នុងម៊ឺនុយ",
    "chat_popup_save_all_as_title": "រក្សា​ទុក​ទាំងអស់ជា...",
    "chat_popup_save_all_image_title": "រក្សាទុក​ទាំងអស់",
    "chat_popup_save_as_title": "រក្សា​ទុក​ជា...",
    "chat_popup_save_title": "រក្សាទុក",
    "chat_popup_share_title": "ចែករំលែក",
    "chat_popup_show_in_explorer_title": "បង្ហាញ​ក្នុង​ការស្វែងរក​",
    "chat_removed_status": "បានលុប",
    "chat_reply_gif_message": "GIF",
    "chat_reply_image_message": "រូបភាព",
    "chat_reply_video_message": "វីដេអូ",
    "chat_reply_voice_message": "សារសម្លេង",
    "chat_screen_drop_file_upload": "ទម្លាក់ឯកសារនៅទីនេះដើម្បីធ្វើការផ្ញើ",
    "chat_screen_empty_message": "មិនទាន់មានការពិភាក្សានៅឡើយ",
    "chat_screen_not_select": "មិនបានជ្រើសរើសការសន្ទនា",
    "chat_screen_typing": "​កំពុង​វាយ​សារ...",
    "chat_screen_unread_message": "សារ​មិន​ទាន់អាន",
    "chat_seen_by_everyone": "everyone",
    "chat_seen_by_status": "Seen by",
    "chat_seen_status": "បានឃើញ",
    "chat_sending_status": "កំពុង​ផ្ញើ",
    "chat_sent_status": "បានផ្ញើ",
    "chat_support_welcome_description": "How can we help you?",
    "chat_support_welcome_title": "Welcome to our support",
    "chat_timestamp_active": "សកម្ម {path}",
    "chat_timestamp_plural_day_ago_label": "{path} ថ្ងៃមុន",
    "chat_timestamp_plural_hour_ago_label": "{path} ម៉ោងមុន",
    "chat_timestamp_plural_month_ago_label": "{path} ខែមុន",
    "chat_timestamp_plural_year_ago_label": "{path} ឆ្នាំមុន",
    "chat_timestamp_single_day_ago_label": "{path} ថ្ងៃមុន",
    "chat_timestamp_single_hour_ago_label": "{path} ម៉ោងមុន",
    "chat_timestamp_single_month_ago_label": "{path} ខែមុន",
    "chat_timestamp_single_year_ago_label": "{path} ឆ្នាំមុន",
    "chat_toast_cant_play_audio": "មិនអាចចាក់សម្លេងបានទេ!",
    "chat_toast_voice_record_hint": "សង្កត់ដើម្បីថតសម្លេង និងលែងដើម្បីផ្ញើ",
    "comment_edit_cancel_button": "បោះបង់",
    "comment_edit_save_button": "រក្សាទុក",
    "comment_input_hint": "សរសេរ​មតិយោបល់...",
    "comment_load_more_button": "load more",
    "comment_load_more_comment_button": "បង្ហាញមតិយោបល់មុនៗ",
    "comment_load_more_reply_button": "បង្ហាញការឆ្លើយតបមុនៗ",
    "comment_multiple_reply_label": "ឆ្លើយតប",
    "comment_popup_confirm_delete_description": "តើអ្នកពិតជាចង់លុបមាតិយោបល់នេះមែនទេ?",
    "comment_popup_confirm_delete_title": "លុបមតិយោបល់",
    "comment_popup_menu_copy": "ចម្លង",
    "comment_popup_menu_delete": "លុប",
    "comment_popup_menu_edit": "កែប្រែ",
    "comment_popup_menu_reply": "ឆ្លើយតប",
    "comment_posting_status": "កំពុងបិទផ្សាយ...",
    "comment_previous_comment_button": "load previous comments",
    "comment_previous_reply_button": "load previous replies",
    "comment_reply_input_hint": "Write a reply...",
    "comment_reply_label": "ឆ្លើយតប",
    "comment_reply_main_comment_not_found": "Comment not found",
    "comment_reply_screen_title": "ឆ្លើយតប",
    "comment_reply_to": "ឆ្លើយតបទៅ",
    "comment_reply_your_comment": "មតិយោបល់របស់អ្នក",
    "comment_reply_your_reply": "ការ​ឆ្លើយតប​របស់​អ្នក",
    "comment_screen_empty_message": "មិនទាន់មានការផ្ដល់យោបល់",
    "comment_screen_title": "មតិយោបល់",
    "comment_toast_delete_fail": "មិនអាចលុបមតិយោបល់បានទេ",
    "comment_toast_post_fail": "យោបល់របស់អ្នកមិនអាចរក្សាទុកបានទេ",
    "common_estimate_time_abbreviation_label": "ប្រហែល",
    "common_footer_accept_payment_method": "យើង​ទទួល",
    "common_footer_day_hours_open": "7 ថ្ងៃក្នុងមួយសប្តាហ៍ចាប់ពីម៉ោង 10 ព្រឹកដល់ម៉ោង 8 យប់",
    "common_footer_delishop_address": "កម្ពុជា ភ្នំពេញ កំពត សៀមរាប",
    "common_footer_delishop_rights_resrved": "រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាងដោយ Delishop.asia",
    "common_footer_menu_about_us": "អំពីពួកយើង",
    "common_footer_menu_contact_us": "ទំនាក់ទំនងពួកយើង",
    "common_footer_menu_delivery_information": "ព័ត៌មានអំពីការដឹកជញ្ជូន",
    "common_footer_menu_faq": "សំណួរផ្សេងៗ",
    "common_footer_menu_my_orders": "ការបញ្ជាទិញរបស់ខ្ញុំ",
    "common_footer_menu_my_profile": "គណនីរបស់ខ្ញុំ",
    "common_footer_menu_payment": "ការទូទាត់សាច់ប្រាក់",
    "common_footer_menu_refund_policies": "គោលនយោបាយសងប្រាក់វិញ",
    "common_footer_menu_terms_conditions": "លក្ខ័ខណ្ឌ និង ការកំណត់",
    "common_footer_title_menu_about": "អំពីពួកយើង",
    "common_footer_title_menu_account": "គណនី",
    "common_footer_title_menu_help": "ជំនួយ",
    "common_footer_title_menu_our_brands": "ម៉ាកយីហោរបស់យើង",
    "common_header_button_menu": "ម៉ឺនុយ",
    "common_no_data_available": "គ្មានព័ត៌មាន",
    "common_no_products_found_label": "រកមិនឃើញផលិតផល",
    "common_or_label": "ឬ",
    "common_show_more_label": "បង្ហាញច្រើនទៀត",
    "common_timestamp_am_symbol": "ព្រឹក",
    "common_timestamp_just_now_label": "អម្បិញមិញនេះ",
    "common_timestamp_plural_abbreviation_hour_label": "ម៉ោង",
    "common_timestamp_plural_abbreviation_minute_label": "នាទី",
    "common_timestamp_plural_abbreviation_second_label": " វិនាទី",
    "common_timestamp_plural_minute_ago_label": "{path} នាទីមុន",
    "common_timestamp_pm_symbol": "ល្ងាច",
    "common_timestamp_single_abbreviation_hour_label": "ម៉ោង",
    "common_timestamp_single_abbreviation_minute_label": "នាទី",
    "common_timestamp_single_abbreviation_second_label": " វិនាទី",
    "common_timestamp_single_minute_ago_label": "{path} នាទីមុន",
    "common_timestamp_today_label": "ថ្ងៃនេះ",
    "common_timestamp_tomorrow_label": "ថ្ងៃស្អែក",
    "contact_empty_message": "You'll see some user here, you can also call or message them from this screen. You can also contact support to add users if you don't have any in your contact list",
    "contact_empty_title": "No Active Contact",
    "contact_us_address_label": "Delishop ផ្ទះលេខ 71A ផ្លូវ 179 ភ្នំពេញ កម្ពុជា",
    "contact_us_email_label": "អ៊ីមែល:",
    "contact_us_phone_label": "ទូរស័ព្ទ",
    "contact_us_social_share_label": "ការចែករំលែកសង្គម:",
    "conversation_empty_message": "គ្មាន​ទិន្នន័យ",
    "conversation_empty_title": "No Active Conversation",
    "conversation_screen_all_title": "ទាំងអស់",
    "conversation_screen_archive_menu_title": "ទុកក្នុងប័ណ្ណសារ",
    "conversation_screen_group_title": "ក្រុម",
    "conversation_screen_message_title": "សារ",
    "conversation_screen_more_conversation_title": "ការសន្ទនាផ្សេងៗទៀត",
    "create_wall_no_right_to_create_from_external_sharing": "អ្នកពុំមានការអនុញ្ញាតក្នុងការបង្កើត",
    "create_wall_posting_notification": "កំពុងបិទផ្សាយ...",
    "create_wall_posting_notification_failed": "ការបង្ហោះរបស់អ្នកបរាជ័យ, សូមព្យាយាមម្ដងទៀត",
    "create_wall_posting_notification_in_queue": "ការប្រកាសបន្ទាប់របស់អ្នកស្ថិតនៅក្នុងជួរ",
    "create_wall_posting_notification_preparing": "កំពុងរៀបចំ...",
    "create_wall_posting_notification_progression": "កំពុងដំណើរការ {path}/{path}",
    "create_wall_posting_notification_success": "ការប្រកាសរបស់អ្នកបានជោគជ័យ",
    "create_wall_street_check_in_title": "ស្ថិតនៅ",
    "create_wall_street_description_hint": "ពណ៌នា...",
    "create_wall_street_edit_button": "កែប្រែ",
    "create_wall_street_edit_media_add_more_button": "បន្ថែម​ទៀត",
    "create_wall_street_edit_media_caption_hint": "បន្ថែមចំណងជើង",
    "create_wall_street_edit_media_done_button": "រួចរាល់",
    "create_wall_street_edit_media_title": "កែប្រែ",
    "create_wall_street_edit_title": "កែសម្រួលការប្រកាស",
    "create_wall_street_keyboard_done_button": "រួចរាល់",
    "create_wall_street_photo_button": "រូបថត / វីដេអូ",
    "create_wall_street_post_button": "បិទផ្សាយ",
    "create_wall_street_title": "បង្កើតប្រកាសថ្មី",
    "error_general_description": "មានកំហុសកើតឡើងលើកម្មវិធីទូរស័ព្ទ។ ព្យាយាមម្តងទៀតនៅពេលក្រោយ សូមទាក់ទងក្រុមជំនួយប្រសិនបើអ្នកនៅតែជួបបញ្ហានេះ។",
    "error_internet_connection_description": "គ្មានការ​តភ្ជាប់​ជាមួយបណ្ដាញ",
    "error_no_data": "គ្មាន​ទិន្នន័យ",
    "error_product_not_found": "រកមិនឃើញទំនិញទេ",
    "error_retry_button": "ព្យាយាមម្ដងទៀត",
    "forget_password_button_back": "ត្រលប់ទៅចូលគណនី",
    "forget_password_button_back_to": "ត្រឡប់ទៅ",
    "forget_password_button_continue": "បន្ត",
    "forget_password_button_resend_code": "ផ្ញើ​ម្ដង​ទៀត",
    "forget_password_button_reset_password": "ប្ដូរពាក្យ​សម្ងាត់​",
    "forget_password_confirm_button": "យល់ព្រម",
    "forget_password_continue_button": "បន្ត",
    "forget_password_description": "យើងនឹងផ្ញើរលេខសម្ងាត់បួនខ្ទង់ ដើម្បីកំណត់ពាក្យសម្ងាត់ថ្មីរបស់អ្នក",
    "forget_password_error_email_not_found": "មិនរកឃើញអ៊ីមែល",
    "forget_password_error_empty_email": "បញ្ចូលអ៊ីមែល",
    "forget_password_error_invalid_email": "បញ្ចូលអ៊ីមែលត្រឹមត្រូវ",
    "forget_password_input_hint": "បញ្ចូលអ៊ីមែលរបស់អ្នកនៅទីនេះ...",
    "forget_password_input_title": "អ៊ីម៉ែល",
    "forget_password_login_link": "ចូល",
    "forget_password_send_code_description": "យើងបានផ្ញើតំណរការប្តូរពាក្យសម្ងាត់ទៅកាន់អ៊ីមែលរបស់អ្នករួចហើយ សូមពិនិត្យអ៊ីមែលរបស់អ្នកដើម្បីធ្វើការផ្លាស់ប្ដូរពាក្យសម្ងាត់",
    "forget_password_title": "ភ្លេចពាក្យសម្ងាត់របស់អ្នក",
    "forgot_password_register_link": "ចុះឈ្មោះនៅទីនេះ",
    "forward_audio_recorded": "បានថតសំឡេង",
    "forward_done_button": "រួចរាល់",
    "forward_empty_message": "គ្មាន​ទិន្នន័យ",
    "forward_group_header": "ក្រុម",
    "forward_input_hint": "វាយសារនៅទីនេះ...",
    "forward_people_header": "មនុស្ស",
    "forward_search_hint": "ស្វែងរកមនុស្សឬក្រុម",
    "forward_send_button": "ផ្ញើ",
    "forward_sent_button": "បានផ្ញើ",
    "forward_share_button": "ចែករំលែក",
    "forward_share_direct_feed": "ចែករំលែកដោយផ្ទាល់លើផ្ទាំងប្រកាសរបស់ខ្ញុំ",
    "forward_share_direct_message": "សារផ្ទាល់",
    "forward_suggest_header": "ណៃនាំដោយយើង",
    "forward_title": "បញ្ជូន​សារ​បន្ត",
    "gallery_button_done": "រួចរាល់",
    "gallery_button_next": "បន្ទាប់",
    "gallery_privacy_description": "អនុញ្ញាតឱ្យកម្មវិធីចូលប្រើរូបថតនិងវីឌីអូរបស់អ្នក",
    "gallery_privacy_title": "ចូលប្រើរូបថតនិងវីដេអូរបស់អ្នក",
    "gallery_select_max": "អ្នកមិនអាចជ្រើសច្រើនជាង {path} នោះទេ",
    "gallery_title": "រូបភាព",
    "group_button_add_member": "បន្ថែមសមាជិកក្រុម​",
    "group_button_add_owner": "បន្ថែមម្ចាស់ក្រុម",
    "group_button_remove_member": "លុប",
    "group_button_see_more": "បង្ហាញបន្ថែម",
    "group_empty_message": "គ្មាន​ទិន្នន័យ",
    "group_menu_dialog_change_name_button_cancel": "បោះបង់",
    "group_menu_dialog_change_name_button_ok": "យល់ព្រម",
    "group_menu_dialog_change_name_input_hint": "ឈ្មោះក្រុម",
    "group_menu_dialog_change_name_title": "ផ្លាស់ប្ដូរឈ្មោះក្រុម",
    "group_menu_file": "ឯកសារ",
    "group_menu_link": "តំណភ្ជាប់",
    "group_menu_photo": "រូបថត & វីដេអូ",
    "group_menu_search": "ស្វែង​រក​ក្នុងសន្ទនា",
    "group_menu_voice": "សារសម្លេង",
    "group_popup_menu_change_name": "ផ្លាស់ប្ដូរ​ឈ្មោះក្រុម",
    "group_popup_menu_change_photo": "ផ្លាស់ប្តូររូបថតក្រុម",
    "group_title_media": "ឯកសាររបស់ក្រុម",
    "group_title_member": "សមាជិកក្រុម",
    "group_title_multiple_member": "សមាជិកក្រុម",
    "group_title_multiple_owner": "ម្ចាស់ក្រុម",
    "group_title_owner": "ម្ចាស់ក្រុម",
    "group_voice_message_at": "នៅ",
    "home_basket_alert_delete_cart": "តើអ្នកប្រាកដថាចង់លុបការបញ្ជារទិញទេ?",
    "home_basket_alert_remove_item": "តើអ្នកប្រាកដថាចង់លុបទំនិញនេះទេ?",
    "home_basket_error_value_greater_then_zero": "សូមបញ្ចូលតម្លៃដែលធំជាង 0",
    "home_basket_item_label": "ទំនិញ",
    "home_basket_items_label": "ទំនិញ",
    "home_basket_no_product_added": "មិនមានទំនិញបន្ថែមទេ។",
    "home_basket_order_minimum_amount": "ចំនួនបញ្ជាទិញអប្បបរមាគឺ {path}",
    "home_basket_update_quantity_popup_button_save": "រក្សាទុកការផ្លាស់ប្តូរ",
    "home_basket_update_quantity_popup_title": "បញ្ចូលបរិមាណនៃទំនិញ",
    "home_brands_label": "ម៉ាកយីហោ",
    "home_delivery_slots_popup_button_disable": "មិនទំនេរ!",
    "home_delivery_slots_popup_button_enable": "មានទំនេរ",
    "home_delivery_slots_popup_button_next": "ស​ប្តា​ហ៍​ក្រោយ",
    "home_delivery_slots_popup_button_prev": "សប្តាហ៍មុន",
    "home_delivery_slots_popup_sub_title": "សូមជ្រើសរើសពេលវេលាដឹកជញ្ជូនរបស់អ្នក!",
    "home_item_popup_Loading": "កំពុងផ្ទុក...",
    "home_item_popup_about_label": "អំពី",
    "home_item_popup_button_full_view_label": "បើកពេញអេក្រង់",
    "home_item_popup_how_to_use": "របៀបប្រើប្រាស់",
    "home_item_popup_ingredient_label": "គ្រឿងផ្សំ",
    "home_item_popup_item_info": "ព័ត៌មានទំនិញ",
    "home_menu_account": "គណនី",
    "home_menu_all_orders": "ការបញ្ជាទិញទាំងអស់",
    "home_new_arrivals_label": "ទំនិញមកដល់ថ្មី",
    "home_popular_products_label": "ទំនិញដែលលក់ដាច់បំផុត",
    "home_promotions_label": "ការបញ្ចុះតម្លៃ",
    "home_testimonial_a_great_service": "សេវាកម្មដ៏អស្ចារ្យជារួម ប្រាកដជាផ្សារទំនើបអនឡាញដ៏ល្អបំផុតនៅក្នុង PP ជាមួយនឹងសេវាកម្មអតិថិជនដ៏ល្អឥតខ្ចោះ។",
    "home_testimonial_a_great_service_auth": "Max",
    "home_testimonial_reusable_tote_bag": "ខ្ញុំទទួលបានគ្រឿងទេសទាំងអស់របស់ខ្ញុំនៅក្នុងកាបូបដែលអាចប្រើឡើងវិញបាន។ ផលិតផល​ពី​ហាង​ឯកទេស​ក្នុង​ទីក្រុង​ភ្នំពេញ​ធ្វើ​ឱ្យ​វា​មានភាពងាយស្រួលជា​ជាង​ផ្សារ​ទំនើប។ អរគុណក្រុមការងារ Delishop!",
    "home_testimonial_reusable_tote_bag_auth": "Jeff",
    "home_testimonial_smooth_delivery": "ការចែកចាយផលិតផលដោយរលូន ខ្ញុំមិនអាចរកកន្លែងផ្សេងទៀតនៅក្នុងទីក្រុងបានទេ ហើយសំខាន់បំផុតគឺគុណភាពកំពូល។ ខ្ញុំ​នឹង​មកវិញ។",
    "home_testimonial_smooth_delivery_auth": "Michael",
    "home_view_all_label": "ត្រួតពិនិត្យទាំងអស់",
    "home_welcome_left_content_list_delivery_hours_text": "ការដឹកជញ្ជូន 2 ម៉ោងឬការដឹកជញ្ជូននៅថ្ងៃបន្ទាប់",
    "home_welcome_left_content_list_option_missing_item_text": "ជម្រើសជំនួសប្រសិនបើបាត់ផលិតផល",
    "home_welcome_left_content_list_product_text": "15 000 ផលិតផល",
    "home_welcome_left_content_list_same_day_order_text": "ការបញ្ជាទិញនៅថ្ងៃដដែលត្រូវដាក់នៅមុនម៉ោង 2 រសៀល",
    "home_welcome_left_content_list_shopping_text": "ការទិញទំនិញច្រើនប្រភេទ",
    "home_welcome_left_content_list_total_supplier_text": "អ្នកផ្គត់ផ្គង់ 85 នាក់ត្រូវបានចែកចាយក្នុងពេលតែមួយ",
    "home_welcome_left_header_sub_title_popup": "ខ្ញុំ​មិន​ប្រញាប់​ទេ ហើយ​ចង់​ចូល​ទៅ​កាន់​កាតាឡុក​ទាំងមូល",
    "home_welcome_left_header_title_popup": "ខ្ញុំស្នាក់នៅ Delishop Supermarket",
    "home_welcome_right_content_list_delivery_text": "ការដឹកជញ្ជូន 45 នាទី",
    "home_welcome_right_content_list_shopping_text": "ការដើរទិញឥវ៉ាន់តែមួយ",
    "home_welcome_right_content_list_suppliers_text": "អ្នកផ្គត់ផ្គង់ 25",
    "home_welcome_right_content_list_time_order_text": "អាចកម្ម៉ង់បានចាប់ពីម៉ោង 10 ព្រឹក ដល់ 8 យប់",
    "home_welcome_right_header_sub_title_popup": "ខ្ញុំត្រូវការផលិតផលរបស់ខ្ញុំជាបន្ទាន់",
    "home_welcome_right_header_title_popup": "ខ្ញុំទៅ Delishop EXPRESS",
    "inboarding_screen_skip_button": "រំលង",
    "login_button_forgot_password": "ភ្លេចពាក្យសម្ងាត់មែនទេ?",
    "login_button_forgot_password_reset_link": "ផ្លាស់ប្ដូពាក្យសម្ងាត់",
    "login_button_login": "បន្ត",
    "login_button_privacy_cookies": "គោលការណ៍និង​ភាព​ឯកជន",
    "login_button_sign_up": "ចុះឈ្មោះ",
    "login_button_term_of_use": "លក្ខខណ្ឌនៃការប្រើប្រាស់",
    "login_dont_have_account_description": "មិនមានគណនីមែនទេ?",
    "login_enter_email_to_register_redirection_label": "ចុះឈ្មោះ",
    "login_error_account_not_exist_label": "មិនមានគណនីទេ សូម {path} ដើម្បីរីករាយជាមួយសេវាកម្មរបស់យើង។",
    "login_error_admin_login": "អ្នកមិនអាចចូលជាគណនីអ្នកគ្រប់គ្រងបានទេ។",
    "login_error_email_password": "អ៊ី​ម៉ែ​ល​ឬ​ពាក្យសម្ងាត់​មិន​ត្រឹមត្រូវ",
    "login_error_enter_a_valid_email_label": "បញ្ចូលអ៊ីមែលត្រឹមត្រូវ",
    "login_error_enter_an_email_label": "បញ្ចូលអ៊ីមែល។ {path} ដើម្បីរីករាយជាមួយសេវាកម្មរបស់យើង។",
    "login_error_field_required": "ប្រអប់តំរូវឱ្យបំពេញ",
    "login_error_incorrect_email": "អ៊ីមែលរបស់អ្នកមិនត្រឹមត្រូវ",
    "login_error_incorrect_phone": "លេខទូរស័ព្ទមិនត្រឹមត្រូវ សូមបញ្ចូលលេខទូរស័ព្ទត្រឹមត្រូវ។",
    "login_error_not_logged_in": "Please login and add at least 1 item in your basket to check available slots",
    "login_error_password": "ពាក្យសម្ងាត់មិនត្រឹមត្រូវ",
    "login_error_phone_password": "ទូរស័ព្ទ ឬពាក្យសម្ងាត់មិនត្រឹមត្រូវ",
    "login_error_wrong_password_label": "លេខសំងាត់​ខុស។ សូម​ព្យាយាម​ម្តង​ទៀត!",
    "login_forgot_password_label": "ភ្លេចពាក្យសម្ងាត់របស់អ្នក?",
    "login_input_email_hint": "បញ្ចូលអ៊ីម៉ែលរបស់អ្នកនៅទីនេះ...",
    "login_input_email_phone_hint": "អ៉ីមែល/ទូរស័ព្ទ",
    "login_input_email_phone_title": "អ៉ី​ម៉ែ​ល​ឬ​ទូរស័ព្ទ",
    "login_input_email_title": "អ៉ីម៉ែល",
    "login_input_password_hint": "បញ្ចូលពាក្យសម្ងាត់របស់អ្នកនៅទីនេះ...",
    "login_input_password_title": "ពាក្យសម្ងាត់",
    "login_input_phone_hint": "បញ្ចូលលេខទូរស័ព្ទរបស់អ្នក",
    "login_input_phone_title": "លេខទូរស័ព្ទ",
    "login_login_here_label": "ចូលគណនីនៅទីនេះ",
    "login_re_login_error": "សូមអភ័យទោសចំពោះភាពរអាក់រអួលជាមួយនឹងការបញ្ជាទិញរបស់អ្នក សូមចូលគណនីម្តងទៀត",
    "login_title": "ចូលគណនី",
    "login_version_label": "កំណែ",
    "login_welcome_back_label": "ស្វា​គម​ន៏​ការ​ត្រ​លប់​មក​វិញ",
    "media_preview_screen_menu_save_title": "រក្សាទុក",
    "media_preview_screen_menu_share_title": "ចែករំលែក",
    "media_preview_screen_more_button": "ជំរើស",
    "notification_comment_on_image": "ផ្ដល់យោបល់លើរូបថតរបស់អ្នក",
    "notification_comment_on_media": "comments on your media",
    "notification_comment_on_post": "ផ្តល់យោបល់លើប្រកាសរបស់អ្នក",
    "notification_comment_on_video": "ផ្ដល់យោបល់លើវីដេអូរបស់អ្នក",
    "notification_create_new_group": "create a group.",
    "notification_create_new_post": "បានបន្ថែមការប្រកាសថ្មីមួយ",
    "notification_default_text": "made an action.",
    "notification_empty_data": "គ្មាន​ទិន្នន័យ",
    "notification_like_comment": "ចូលចិត្តយោបល់របស់អ្នក",
    "notification_like_image": "ចូលចិត្តរូបថតរបស់អ្នក",
    "notification_like_media": "like your media.",
    "notification_like_post": "ចូលចិត្តប្រកាសរបស់អ្នក",
    "notification_like_reply_comment": "likes your comment",
    "notification_like_video": "ចូលចិត្តវីដេអូរបស់អ្នក",
    "notification_mention_on_comment": "បាននិយាយពីអ្នកនៅក្នុងមតិយោបល់",
    "notification_mention_on_post": "បាននិយាយពី​អ្នកនៅក្នុងការប្រកាស់",
    "notification_mention_on_reply_comment": "បាននិយាយពីអ្នកនៅក្នុងការផ្ដល់យោបល់",
    "notification_order_delivering": "is delivering your order.",
    "notification_order_processing": "is processing your order.",
    "notification_reply_on_comment": "replied to your comment",
    "notification_title": "ការជូនដំណឹង",
    "notification_title_earlier": "មុននេះ",
    "notification_title_new": "ថ្មី",
    "notification_update_button": "តម្លើងឥឡូវនេះ!",
    "notification_update_label": "ការកំណែថ្មីនៃ {path} មានហើយឥឡូវនេះ",
    "payment_aba_card": "ABA, VISA, Mastercard អំឡុងពេលបញ្ជារទិញ",
    "payment_aba_qr": "តាមរយៈ ABA QR code Transfer",
    "payment_accept_payment": "យើងទទួលយកការទូទាត់ :",
    "payment_delivery_price": "តម្លៃដឹកជញ្ជូនត្រូវបានកំណត់ដូចខាងក្រោម:",
    "payment_exchange_rate": "អត្រា​ប្តូ​រ​ប្រាក់: $1 = 4100 រៀល",
    "payment_heading_title": "ការទូទាត់",
    "payment_in_cash": "ជាសាច់ប្រាក់ជាដុល្លារ និង/ឬប្រាក់រៀល",
    "payment_minimum_order": "ចំនួនបញ្ជាទិញអប្បបរមាគឺ {path}",
    "payment_price_above": "ចាប់ពី {path} និងខ្ពស់ជាងនេះ៖ ឥតគិតថ្លៃ",
    "payment_price_from": "ពី {path} ទៅ {path}",
    "popup_ask_floating_window_permission_for_call_description": "Do you want your call to display over floating window?",
    "popup_ask_floating_window_permission_for_video_description": "Please allow floating permission to display video over floating window!",
    "popup_ask_floating_window_permission_proceed_button": "Proceed",
    "popup_ask_floating_window_permission_title": "Floating Window",
    "popup_ask_floating_window_permission_title_do_not_ask_again_label": "Don't ask again",
    "popup_camera_permission_denied": "ការចូលប្រើកាមេរ៉ាត្រូវបានបដិសេធ",
    "popup_cancel_button": "បោះបង់",
    "popup_country_code_screen_title": "លេខ​កូដ​ប្រទេស",
    "popup_country_code_search_input_hint": "វាយលេខកូដប្រទេស ឬឈ្មោះ...",
    "popup_country_code_section_common_title": "ទូទៅ",
    "popup_country_code_section_current_title": "បច្ចុប្បន្ន",
    "popup_device_notification_setting_description": "សូមពិនិត្យមើលការគ្រប់គ្រងឧបករណ៍របស់អ្នក និងអនុញ្ញាតិឲ្យ {path} បើកគ្រប់ជម្រើសទាំងអស់ដើម្បីទទួលបានការជូនដំណឺង បើទោះបីជាកម្មវិធីត្រូវបានបិទក៏ដោយ",
    "popup_device_notification_setting_do_not_ask_again_label": "រួចរាល់ ឬ សូមកុំសួរម្ដងទៀត",
    "popup_device_notification_setting_proceed_button": "បន្ត",
    "popup_device_notification_setting_title": "ឧបករណ៍របស់អ្នកអាចនឹងមិនទទួលបានការជូនដំណឹងដូចដែលបានរំពឹងទុក",
    "popup_error_notification_redirection_not_found_message": "រកមិនឃើញខ្លឹមសារគោលដៅទេ។",
    "popup_error_notification_redirection_not_found_title": "សេចក្តីជូនដំណឹង",
    "popup_error_title": "កំហុស",
    "popup_go_to_setting_button": "ចូលទៅកាន់ការកំណត់",
    "popup_location_permission_denied": "យើងត្រូវការការអនុញ្ញាតរបស់អ្នកដើម្បីយកនិងប្រើប្រាស់ទីតាំងរបស់អ្នក",
    "popup_microphone_camera_permission_denied": "បដិសេដការប្រើប្រាស់មីក្រូហ្វូននិងកាមេរ៉ា",
    "popup_microphone_permission_denied": "ការចូលប្រើមីក្រូហ្វូនត្រូវបានបដិសេធ",
    "popup_no_button": "ទេ",
    "popup_not_now_button": "ពេលក្រោយ",
    "popup_ok_button": "យល់ព្រម",
    "popup_permission_app_setting_title": "ការកំណត់កម្មវិធី",
    "popup_permission_do_not_allow_button": "មិនអនុញ្ញាត",
    "popup_permission_force_request_camera_description": "អ្នកបានបិទការប្រើប្រាស់កាមេរ៉ាក្នុង {path} ដើម្បីបើកដំណើរការនេះ សូមចូលទៅកាន់ការកំណត់កម្មវិធី រួចបើកការអនុញ្ញាតដំណើរការកាមេរ៉ាដែលបង្ហាញនៅក្នុងម៊ឺនុយ",
    "popup_permission_force_request_location_description": "អ្នកបានបិទកាបង្ហាញទីតាំងនៅលើ {path} ដើម្បីបើកដំណើរការនេះ សូមចូលទៅកាន់ការកំណត់កម្មវិធី រួចបើកការអនុញ្ញាតការបង្ហាញទីតាំងដែលមាននៅក្នុងម៊ឺនុយ",
    "popup_permission_force_request_microphone_description": "អ្នកបានបិទការប្រើប្រាស់មីក្រូហ្វូននៅលើ {path} ដើម្បីបើកដំណើរការនេះ សូមចូលទៅកាន់ការកំណត់កម្មវិធី រួចបើកការអនុញ្ញាតមីក្រូហ្វូនដែលមាននៅក្នុងម៊ឺនុយ",
    "popup_permission_force_request_storage_description": "អ្នកបានបិទការអនុញ្ញាតការផ្ទុកទិន្នន័យនៅលើ {path} ដើម្បីបើកដំណើរការនេះ សូមចូលទៅកាន់ការកំណត់កម្មវិធី រួចបើកការអនុញ្ញាតការផ្ទុកទិន្នន័យដែលមាននៅក្នុងម៊ឺនុយ",
    "popup_permission_request_enable_precise_location_description": "ដើម្បីទទួលបាននូវបទពិសោធន៍កាន់តែប្រសើរឡើងជាមួយនឹងការជ្រើសរើសអាសយដ្ឋាន សូមអនុញ្ញាតឱ្យកម្មវិធីចូលទៅកាន់ទីតាំងជាក់លាក់នៃទូរសព្ទរបស់អ្នក",
    "popup_permission_request_enable_precise_location_title": "បើកការចូលប្រើទីតាំងច្បាស់លាស់ពីការកំណត់កម្មវិធី",
    "popup_permission_request_title": "បើកដំណើរការចូលក្នុងការកំណត់កម្មវិធី",
    "popup_permission_require_all_permission_description": "ដើម្បីទទួលបានបទពិសោធន៍ប្រើប្រាស់កាន់តែប្រសើរ {path} សុំសិទ្ធដើម្បីចូលទៅកាន់ឧបករណ៍របស់អ្នក",
    "popup_photo_permission_denied": "ការចូលប្រើរូបថតត្រូវបានបដិសេធ",
    "popup_request_location_permission_description": "ដើម្បីទទួលបានបទពិសោធន៍ប្រសើរជាងមុនក្នុងការជ្រើសរើសអាសយដ្ឋាន សូមអនុញ្ញាតឱ្យកម្មវិធីចូលប្រើទីតាំងរបស់ឧបករណ៍អ្នក",
    "popup_session_expired_description": "សូមចូលគណនីម្តងទៀត",
    "popup_session_expired_title": "ការរក្សាព័ត៏មានគណនីបានផុតកំណត់",
    "popup_success_title": "ជោគ​ជ័យ",
    "popup_update_button": "កែប្រែ",
    "popup_update_screen_description": "ដើម្បីចាប់ផ្តើមប្រើប្រាស់ការកំណែថ្មី {path} ​ចាំបាច់ត្រូវចាប់ផ្តើមឡើងវិញ តើអ្នកចង់បិទដើម្បីចាប់ផ្តើមឡើងវិញឥឡូវនេះទេ?",
    "popup_update_screen_title": "ការកំណែថ្មីរបស់ {path} មានហើយឥឡូវនេះ",
    "popup_yes_button": "បាទ/ចាស",
    "post_count_comment": "មតិយោបល់",
    "post_count_like": "ចូលចិត្ត",
    "post_count_multiple_comment": "មតិយោបល់",
    "post_count_multiple_like": "ចូលចិត្ត",
    "post_create_new_hint": "ចាប់ផ្ដើមបង្កើតឥឡូវនេះ...",
    "post_description_see_more": "មើល​បន្ថែម",
    "post_detail_screen_post_deleted_message": "This post has been deleted.",
    "post_error_message": "មានអ្វីមួយមិនប្រក្រតី សូមព្យាយាមម្តងទៀត...",
    "post_location_at": "នៅ",
    "post_menu_copy_link": "ចម្លងតំណរ",
    "post_menu_delete_post_title": "លុបប្រកាស",
    "post_menu_edit_post_title": "កែប្រកាស",
    "post_popup_confirm_delete_description": "តើអ្នកពិតជាចង់លុបការប្រកាសនេះមែនទេ?",
    "post_popup_confirm_delete_title": "លុបប្រកាស",
    "post_posting": "កំពុងបិទផ្សាយ...",
    "post_screen_empty_message": "ចាប់ផ្ដើមបង្កើតការប្រកាសរបស់អ្នកនៅទីនេះ...",
    "post_user_like_screen_title": "ចូលចិត្ត",
    "post_user_view_screen_title": "មនុស្ស​",
    "privacy_corporate_description": "មានតែសមាជិកនៃក្រុមហ៊ុនទេដែលអាចមើលឃើញការប្រកាសរបស់អ្នក",
    "privacy_corporate_title": "សហការ",
    "privacy_description": "អ្នកអាចផ្លាស់ប្តូរឯកជនភាពសម្រាប់ការប្រកាសរបស់អ្នកនៅទីនេះ",
    "privacy_header_title": "ជ្រើសរើសឯកជនភាពរបស់អ្នក",
    "privacy_only_me_description": "មានតែអ្នកដែលអាចមើលឃើញការប្រកាសរបស់អ្នក",
    "privacy_only_me_title": "សំរាប់ខ្ញុំតែម្នាក់",
    "privacy_public_description": "គ្រប់គ្នាអាចឃើញប្រកាសរបស់អ្នក​",
    "privacy_public_title": "សាធារណៈ",
    "privacy_title": "អ្វីដែលមនុស្សអាចមើលឃើញពីអ្នក",
    "profile_user_add_address_button": "បន្ថែមអាសយដ្ឋាន",
    "profile_user_address_details": "ព័ត៌មានលម្អិតអំពីអាសយដ្ឋាន",
    "profile_user_call_button": "ហៅចេញ",
    "profile_user_category_menu_label": "ជ្រើសរើសប្រភេទ",
    "profile_user_change_button": "ផ្លាស់ប្តូរ",
    "profile_user_change_password_title": "ផ្លាស់ប្តូរពាក្យសម្ងាត់",
    "profile_user_change_phone_error": "លេខទូរស័ព្ទនេះមាននៅក្នុងប្រព័ន្ធរួចហើយ។ សូមប្រើមួយទៀត!",
    "profile_user_current_past_order_title": "ការបញ្ជាទិញបច្ចុប្បន្ន និងអតីតកាល",
    "profile_user_default_role": "Guest",
    "profile_user_edit_button": "កែសម្រួល",
    "profile_user_email_input_title": "អាស័យ​ដ្ឋាន​អ៊ី​ម៉េ​ល",
    "profile_user_email_title": "អ៊ីម៉ែល",
    "profile_user_first_name_title": "ឈ្មោះ",
    "profile_user_full_name_input_title": "ឈ្មោះ",
    "profile_user_id_title": "លេខសម្គាល់​អ្នក​ប្រើ",
    "profile_user_last_name_title": "នាមត្រកូល",
    "profile_user_login_or_register": "ចូលគណនី ឬចុះឈ្មោះ",
    "profile_user_logout_button": "ចេញពីគណនី",
    "profile_user_message_button": "ផ្ញើសារ",
    "profile_user_my_order_button": "ការបញ្ជាទិញរបស់ខ្ញុំ",
    "profile_user_password_title": "ពាក្យសម្ងាត់",
    "profile_user_phone_input_title": "លេខទូរសព្ទ័",
    "profile_user_phone_title": "លេខទូរសព្ទ័",
    "profile_user_popup_log_out_description": "តើអ្នកចង់ធ្វើការចេញពីគណនីនេះមែនទេ?",
    "profile_user_popup_log_out_success_description": "អ្នកបានចេញពីគណនីដោយជោគជ័យ",
    "profile_user_popup_log_out_title": "ចាកចេញ",
    "profile_user_popup_update_profile_success_description": "ពត៌មានរបស់អ្នកត្រូវបានកែប្រែថ្មី",
    "profile_user_popup_upload_photo_fail": "កំហុសមួយបានកើតឡើងកំឡុងពេលបង្ហោះ សូម​ព្យាយាម​ម្តង​ទៀត",
    "profile_user_profile_button": "ព័ត៌មានផ្ទាល់ខ្លួន",
    "profile_user_save_button": "រក្សាទុក",
    "profile_user_section_address_title": "អាស្រយ័ដ្ឋាន",
    "profile_user_section_info_title": "ពត៍មានផ្ទាល់ខ្លួន",
    "profile_user_section_localization_title": "ភាសា",
    "profile_user_section_mobile_and_password_title": "លេខទូរស័ព្ទ និងសុវត្ថិភាព",
    "profile_user_section_option_title": "ជម្រើសគណនី",
    "profile_user_section_order_title": "ការបញ្ជាទិញរបស់ខ្ញុំ",
    "profile_user_section_password_title": "ផ្លាស់ប្តូរពាក្យសម្ងាត់",
    "profile_user_section_shop_title": "ពាណិជ្ជកម្ម និងសេវាកម្ម",
    "profile_user_section_theme_title": "កែប្រែទម្រង់",
    "profile_user_section_wallet_title": "កាបូបលុយ",
    "profile_user_setting_button": "ការកំណត់",
    "profile_user_status_online": "ក្នុងបណ្ដាញ",
    "profile_user_title": "គណនីរបស់ខ្ញុំ",
    "profile_user_video_button": "ហៅវីដេអូចេញ",
    "profile_user_wallet_management_admin_heading": "អ្នកគ្រប់គ្រង",
    "profile_user_wallet_management_amount_heading": "ចំនួនទឹកប្រាក់",
    "profile_user_wallet_management_comment_heading": "មតិយោបល់",
    "profile_user_wallet_management_creation_date_time_heading": "កាលបរិច្ឆេទ/ពេលវេលាបង្កើត",
    "profile_user_wallet_management_currency": "(ដុល្លារ​អាមេរិក)",
    "profile_user_wallet_management_empty": "ទទេ",
    "profile_user_wallet_management_heading": "គ្រប់គ្រងកាបូប",
    "profile_user_wallet_management_order_number_heading": "លេខ​សម្គាល់​ បញ្ជាទិញ",
    "profile_user_wallet_management_reason_heading": "ហេតុផល",
    "profile_user_wallet_management_sub_title_label": "សមតុល្យកាបូបរបស់អ្នក",
    "profile_user_wallet_management_table_title": "ប្រតិបត្តិការចុងក្រោយ",
    "profile_user_wallet_management_title_label": "ព័ត៌មានលម្អិតកាបូប",
    "profile_user_wallet_management_type_heading": "ប្រភេទ",
    "register_login_popup_email_button": "បន្តជាមួយអ៊ីម៉ែល",
    "register_login_popup_or_label": "ឬ",
    "register_login_popup_phone_button": "បន្តជាមួយទូរស័ព្ទ",
    "register_login_popup_privacy_policy": "គោលការណ៍​ភាព​ឯកជន",
    "register_login_popup_term_condition": "លក្ខ័ខណ្ឌ និង ការកំណត់",
    "register_login_popup_term_condition_and_privacy_policy_description": "តាមរយៈការបន្ត អ្នកយល់ព្រមនឹង {path} និង {path} របស់យើង",
    "register_login_popup_title": "ចុះឈ្មោះឬចូលគណនី",
    "search_address_button_confirm": "បញ្ជាក់",
    "search_address_default_address": "ផ្លូវគ្មានឈ្មោះ",
    "search_address_empty_message": "រកមិនឃើញ",
    "search_address_input_hint": "ឈ្មោះ​ផ្លូវឬទីកន្លែង",
    "search_address_looking_for_address_title": "កំពុងស្វែងរកអាសយដ្ឋាន...",
    "search_address_map_title": "ផែនទី",
    "search_address_select_location_on_map_title": "កំណត់ទីតាំងរបស់អ្នកនៅលើផែនទី",
    "search_address_set_location_on_map": "កំណត់ទីតាំងនៅលើផែនទី",
    "search_address_title": "កំណត់ទីតាំងរបស់អ្នក",
    "search_gif_empty_message": "គ្មាន GIFs ដើម្បីបង្ហាញទេ",
    "search_gif_input_hint": "ស្វែងរក GIFs",
    "search_group_empty_message": "មិនរកឃើញ",
    "search_group_input_hint": "ស្វែង​រក​ក្នុងការសន្ទនា",
    "search_home_screen_active_title_hint": "កំពុងស្វែងរក...",
    "search_home_screen_empty_message": "រកមិនឃើញ",
    "search_home_screen_input_hint": "ស្វែងរក",
    "search_home_screen_suggested_people_title": "អ្នកដែលយើងណែនាំ",
    "search_home_screen_tab_all_title": "ទាំងអស់",
    "search_home_screen_tab_message_title": "សារ",
    "search_home_screen_tab_people_title": "មនុស្ស​",
    "search_item_home_screen_active_title_hint": "រកមើលអីវ៉ាន់...?",
    "search_item_home_screen_empty_message": "រកមិនឃើញទេ",
    "search_item_home_screen_input_hint": "ស្វែងរកផលិតផល",
    "search_item_result_title": "លទ្ធផលស្វែងរក",
    "search_item_suggestion_in_label": "ក្នុង",
    "search_item_suggestion_label": "ស្វែងរកការណែនាំ",
    "search_mention_empty_message": "មិនរកឃើញ",
    "search_mention_hint": "វាយពីរអក្សរ",
    "search_supplier_item_hint": "ស្វែងរកក្នុង {path}",
    "setting_audio_video_audio_call_description": "ហៅជាសម្លេង",
    "setting_audio_video_audio_call_disabled": "មិនអនុញ្ញាតិឱ្យហៅចេញ",
    "setting_audio_video_audio_title": "សម្លេង",
    "setting_audio_video_camera_label": "កាមេរ៉ា",
    "setting_audio_video_microphone_title": "មីក្រូហ្វូន",
    "setting_audio_video_no_camera_found": "រក​មិនឃើញ​កាមេរ៉ា",
    "setting_audio_video_no_device_found": "រក​មិនឃើញ​ឧបករណ៍",
    "setting_audio_video_speaker_title": "សំលេង",
    "setting_audio_video_video_call_description": "ហៅជាវីដេអូ",
    "setting_audio_video_video_call_disabled": "មិនអនុញ្ញាតិឱ្យហៅវីដេអូចេញ",
    "setting_audio_video_video_title": "វីដេអូ",
    "setting_change_language_dialog_cancel_button": "បោះបង់",
    "setting_change_language_dialog_description": "ផ្លាស់ប្តូរភាសា",
    "setting_change_language_dialog_ok_button": "យល់ព្រម",
    "setting_change_language_dialog_title": "ភាសា",
    "setting_change_language_title": "ផ្លាស់ប្តូរភាសារបស់អ្នក",
    "setting_change_theme_dialog_cancel_button": "បោះបង់",
    "setting_change_theme_dialog_description": "ជ្រើសរើសរចនាបទពណ៌",
    "setting_change_theme_dialog_ok_button": "យល់ព្រម",
    "setting_change_theme_dialog_title": "រចនាបទកម្មវិធី",
    "setting_change_theme_title": "ជ្រើសរើសរចនាបទកម្មវិធី",
    "setting_disable_notification_description": "អ្នកនឹងមិនទទួលបានការជូនដំណឹងណាមួយទេរហូតដល់អ្នកបើកជម្រើសនេះ",
    "setting_edit_email_title": "អ៊ីម៉ែល",
    "setting_edit_firstname_title": "ឈ្មោះ",
    "setting_edit_lastname_title": "នាមត្រកូល",
    "setting_edit_update_profile_title": "កែសម្រួលព័ត៌មានគណនី",
    "setting_general_account_option_title": "ជម្រើសគណនី",
    "setting_general_cache_image_title": "​រក្សាទុករូបភាព",
    "setting_general_clear_caching_description": "លុបរូបភាពដែលបានរក្សាទុក",
    "setting_general_clear_caching_sub_title": "លុបរូបភាព",
    "setting_general_enable_image_caching_description": "អនុញ្ញាតឲ្យកម្មវិធីរក្សាទុករូបភាព ដើម្បីឲ្យដំណើរការកាន់តែប្រសើរ",
    "setting_general_enable_image_caching_sub_title": "អនុញ្ញាតឲ្យ​រក្សាទុករូបភាព",
    "setting_general_mute_application_sound_description": "បិទ​សម្លេងនៅពេលទទួលបានការជូនដំណឹង",
    "setting_general_mute_application_sound_sub_title": "បិទសម្លេងការជូនដំណឹង",
    "setting_help_update_check_for_update_button": "ពិនិត្យរកកំណែថ្មី",
    "setting_help_update_check_update": "ពិនិត្យការតម្លើងកម្មវិធី...",
    "setting_help_update_download_update": "ទាញយក​កំណែថ្មី...",
    "setting_help_update_title": "Update",
    "setting_help_update_up_to_date": "កម្មវិធីរបស់អ្នកទាន់សម័យហើយ",
    "setting_help_update_version_label": "កំណែបច្ចុប្បន្ន",
    "setting_language_english_title": "English",
    "setting_language_french_title": "ភាសាបារាំង",
    "setting_language_khmer_title": "ភាសាខ្មែរ",
    "setting_popup_confirm_before_disable_notification_description": "តើអ្នកពិតជាចង់បិទការជូនដំណឹងទាំងអស់មែនទេ?",
    "setting_popup_confirm_before_disable_notification_title": "បិទរាល់ការជូនដំណឹងទាំងអស់",
    "setting_popup_disable_notification_error_description": "ការកំណត់មិនអាចផ្លាស់ប្តូរបានទេ",
    "setting_shop_addresses": "អាស្រ័យដ្ឋានដឹកជញ្ជូន",
    "setting_shop_orders_history": "ការបញ្ជាទិញបច្ចុប្បន្ន និងពីមុន",
    "setting_shop_wallet": "សមតុល្យជាក់ស្តែង",
    "setting_shop_wallet_history": "ប្រវត្តិកាបូបលុយ",
    "setting_tab_audio_and_video": "សំលេង និង វីដេអូ",
    "setting_tab_general": "ទូទៅ",
    "setting_tab_help_and_update": "ជំនួយ & ការកែលម្អថ្មី",
    "setting_theme_dark_title": "ងងឹត",
    "setting_theme_light_title": "ភ្លឺ",
    "setting_theme_purple_title": "ព៌ណស្វាយ",
    "setting_title": "ការកំណត់",
    "setting_user_disable_notification_title": "បិទ​ការផ្ដល់ដំណឹងទាំងអស់",
    "setting_version": "កំណែ",
    "setting_wallet_history_amount": "ចំនួនទឹកប្រាក់:",
    "setting_wallet_history_comment": "មតិយោបល់:",
    "setting_wallet_history_date": "កាលបរិច្ឆេទ:",
    "setting_wallet_history_info": "ព័ត៌មាន:",
    "setting_wallet_history_screen_title": "ប្រវត្តិកាបូបលុយ",
    "setting_wallet_history_type": "ប្រភេទ​ប្រតិបត្តិការ: ",
    "share_external_log_in_require": "អ្នក​ត្រូវ​ចូលគណនី {path} សិន មុននឹងអាចចែករំលែកមាតិការនេះ​",
    "shop_add_item_to_cart_different_shop_error": "អ្នកនៅតែមានការបញ្ជាទិញពីហាងផ្សេង។ តើ​អ្នក​ចង់​លុប​វា ហើយ​ចាប់​ផ្ដើម​ជាមួយ​នឹង​ការ​បញ្ជា​ទិញ​ថ្មី​?",
    "shop_add_item_to_cart_different_shop_error_cancel": "បោះបង់",
    "shop_add_item_to_cart_different_shop_error_remove": "ដកចេញ",
    "shop_add_item_to_cart_different_shop_error_title": "តើអ្នកចង់លុបការបញ្ជាទិញពីមុនរបស់អ្នកទេ?",
    "shop_add_new_location_button": "បន្ថែមទីតាំងថ្មី",
    "shop_address_home_label": "ផ្ទះ",
    "shop_address_list_delivery_here_label": "ការដឹកជញ្ជូននៅទីនេះ",
    "shop_address_list_empty_message": "អ្នកមិនទាន់បានបញ្ជូលអាសយដ្ឋានណាមួយទេ",
    "shop_address_list_name_label": "Name",
    "shop_address_list_phone_abbreviation_label": "Ph",
    "shop_address_list_screen_title": "អាសយដ្ឋានរបស់ខ្ញុំ",
    "shop_address_school_label": "សាលារៀន",
    "shop_address_work_label": "កន្លែងធ្វើការ",
    "shop_category_all_label": "ទាំងអស់",
    "shop_category_list_delivery_estimate_time": "ការប៉ាន់ស្មានពេលវេលាដឹកជញ្ជូន៖ {path}",
    "shop_category_list_delivery_time": "ចាប់ផ្តើមដឹកជញ្ជូនពី៖ {path}",
    "shop_category_list_express_store_back_redirection": "ហាង Express",
    "shop_category_list_no_product_out_of_stock": "ទំនិញទាំងអស់នៃប្រភេទនេះគឺអស់ស្តុកសម្រាប់ពេលនេះ អ្នកអាចមើលពួកវាដោយបើកជម្រើស 'មើលអស់ពីស្តុក'",
    "shop_category_list_shop_closed": "បិទ",
    "shop_check_out_button": "ការបង់ប្រាក់",
    "shop_check_out_subtotal_title": "ថ្លៃទំនិញ",
    "shop_checkout_aba_app_not_install": "មិនមានកម្មវិធី ABA ដែលបានដំឡើងនៅលើទូរសព្ទរបស់អ្នកទេ",
    "shop_checkout_access_public_coupon_title": "ចុចទីនេះដើម្បីចូលប្រើលេខកូដបញ្ចុះតម្លៃឥតគិតថ្លៃ",
    "shop_checkout_account_number": "លេខ​គណនី",
    "shop_checkout_account_owner": "ម្ចាស់គណនី",
    "shop_checkout_alert_error": "ដាស់តឿន!",
    "shop_checkout_alert_minimum_cart_amount_error": "អតិថិជនជាទីរាប់អាន ចំនួនទឹកប្រាក់បញ្ជាទិញអប្បបរមាគឺ {path}។ សូមពិនិត្យមើលរទេះរបស់អ្នក ដើម្បីបន្ថែមផលិតផលបន្ថែមទៀត។",
    "shop_checkout_alert_no_address_selected_error": "សូមជ្រើសរើសអាសយដ្ឋានដឹកជញ្ជូន!",
    "shop_checkout_alert_selected_address_out_of_range": "អាសយដ្ឋានដឹកជញ្ជូនដែលបានជ្រើសរើសមិនស្ថិតនៅក្នុងតំបន់ដែលយើងអាចដឹកជញ្ជូនបានទេ។ សូមជ្រើសរើសអាសយដ្ឋានផ្សេងទៀត!",
    "shop_checkout_alert_success_order": "ការបញ្ជាទិញរបស់អ្នកត្រូវបានបង្កើតដោយជោគជ័យ! សូមអរគុណសម្រាប់ការទិញទំនិញជាមួយយើង!",
    "shop_checkout_apply_coupon_button": "ប្រើប្រាស់​",
    "shop_checkout_asap_text": "ឆាប់បំផុតតាមដែលអាចធ្វើបាន",
    "shop_checkout_bill_detail_title": "ពិនិត្យតម្លៃ",
    "shop_checkout_browse_our_shop_redirection": "រកមើលហាងរបស់យើង",
    "shop_checkout_cancel_description": "សូម​លុប​ចោល​ទំនិញ​របស់​ខ្ញុំ",
    "shop_checkout_cancel_or_contact_title": "ក្នុងករណីទំនិញអស់ពីស្តុក ខ្ញុំចង់៖",
    "shop_checkout_cancel_title": "បោះបង់ទំនិញ",
    "shop_checkout_cant_delete_default_address_error": "អ្នកមិនអាចលុបអាសយដ្ឋានដើមរបស់អ្នកបានទេ!",
    "shop_checkout_cart_is_empty_error": "កន្រ្ដកគ្នានទំនិញ",
    "shop_checkout_cash_method_notice": "សម្រាប់វិធីសាស្រ្តសាច់ប្រាក់ សូមមានចំនួនប្រាក់ជិតបំផុត អ្នកដឹកជញ្ជូនរបស់យើងមិនមានសាច់ប្រាក់ច្រើនសម្រាប់ហេតុផលសុវត្ថិភាព។",
    "shop_checkout_cash_on_delivery_option": "ទូទាត់ផ្ទាល់ពេលដឹកជញ្ជូន",
    "shop_checkout_choose_location_button": "ជ្រើសរើសអាសយដ្ឋានដែលត្រូវដឹកជូន",
    "shop_checkout_comment_hint": "មតិយោបល់",
    "shop_checkout_comment_title": "បន្ថែមពត៍មានផ្សេងៗ",
    "shop_checkout_confirm_delete_address": "តើអ្នកប្រាកដថាចង់លុបអាសយដ្ឋាន",
    "shop_checkout_contact_description": "ទាក់ទងខ្ញុំអំពីជម្រើសជំនួស",
    "shop_checkout_contact_title": "សូមទាក់ទងមក",
    "shop_checkout_covid19_note": "សូមចំណាំថា ដោយសារស្ថានភាព COVID-19 បច្ចុប្បន្ន យើងអាចជួបប្រទះការពន្យារពេលខ្លះក្នុងការដឹកជញ្ជូនការបញ្ជាទិញ",
    "shop_checkout_delivery": "ថ្លៃដឹកជញ្ជូន",
    "shop_checkout_delivery_and_payment_screen_title": "ជម្រើសដឹកជញ្ជូននិងការបង់ប្រាក់",
    "shop_checkout_delivery_schedule_title": "ជ្រើសរើសពេលវេលាដឹកជញ្ជូន",
    "shop_checkout_discount": "បញ្ជុះតម្លៃ",
    "shop_checkout_empty_cart_label": "លុបការបញ្ជាទិញ",
    "shop_checkout_error_aba": "សេវា ABA មិនឆ្លើយតបសម្រាប់ពេលនេះទេ",
    "shop_checkout_error_cart_empty": "កន្រ្ដករបស់អ្នកទទេ {path} ដើម្បីបន្ថែមផលិតផល",
    "shop_checkout_error_invalid_coupon": "លេខកូដបញ្ចុះតម្លៃរបស់អ្នកមិនត្រឹមត្រូវទេ!",
    "shop_checkout_forget_select_payment_error": "សូមជ្រើសរើសវិធីបង់ប្រាក់",
    "shop_checkout_grand_total": "សរុប",
    "shop_checkout_input_coupon_hint": "បញ្ចូលលេខកូដ",
    "shop_checkout_input_coupon_title": "មានកូដបញ្ចុះតម្លៃទេ? ",
    "shop_checkout_input_hint": "ព័ត៌មានលម្អិតអំពីការដឹកជញ្ជូន:",
    "shop_checkout_item_not_available_error": "សូមអភ័យទោស ផលិតផលមិនមានលក់នៅពេលនេះទេ",
    "shop_checkout_missing_location_label": "រកមិនឃើញទីតាំងរបស់អ្នក",
    "shop_checkout_modify_schedule_label": "កែប្រែកាលវិភាគដឹកជញ្ជូន",
    "shop_checkout_no_associated_order": "មិនមានការបញ្ជាទិញដែលពាក់ព័ន្ធជាមួយកន្រ្ដកនេះទេ",
    "shop_checkout_no_item_available_error": "មិនមានផលិតផលទេ",
    "shop_checkout_online_method_notice": "សូមផ្ញើការទូទាត់របស់អ្នកទៅកាន់គណនីរបស់យើងខាងក្រោម",
    "shop_checkout_online_method_option": "ទូទាត់តាម អន​ឡាញ",
    "shop_checkout_order_not_exist": "ការបញ្ជាទិញនេះមិនមានទេ!",
    "shop_checkout_out_of_rang": "អ្នកនៅក្រៅជួរដឹកជញ្ជូន។",
    "shop_checkout_pay_button": "ធ្វើការកម្មង់ទិញ",
    "shop_checkout_payment_adjust_wallet_title": "ប្រើប្រាស់កាបូបលុយរបស់អ្នក",
    "shop_checkout_payment_description": "សូមជ្រើសរើសវិធីបង់ប្រាក់របស់លោកអ្នកនៅទីនេះ។",
    "shop_checkout_payment_title": "ជ្រើសរើសរបៀបទូទាត់ប្រាក់",
    "shop_checkout_payment_wallet_balance_title": "សមតុល្យជាក់ស្តែង៖",
    "shop_checkout_payment_wallet_title": "កាបូបលុយ​របស់អ្នក",
    "shop_checkout_popup_close_payment": "តើអ្នកប្រាកដថាចង់បិទទេ?",
    "shop_checkout_popup_coupon_applied": "លេខកូដគូប៉ុងត្រូវបានអនុវត្ត!",
    "shop_checkout_popup_coupon_code_error": "លេខកូដគូប៉ុងមិនអាចអនុវត្តបានទេ",
    "shop_checkout_popup_coupon_title_label": "ជ្រើសរើសគូប៉ុង",
    "shop_checkout_proceed_checkout_button": "បន្តទៅការបង់​ប្រាក់",
    "shop_checkout_products_not_available": "ផលិតផលមួយចំនួនប្រហែលជាមិនមានសម្រាប់ធ្វើការដឹកជញ្ជួននៅថ្ងៃតែមួយទេ។",
    "shop_checkout_receipt_added": "បង្កាន់ដៃ",
    "shop_checkout_receipt_remove_button": "ដកចេញ",
    "shop_checkout_remove_coupon_button": "លុប",
    "shop_checkout_remove_item": "ដកចេញពីការកុម្ម៉ង់របស់ខ្ញុំ",
    "shop_checkout_remove_item_title": "ប្រសិនបើមិនមានផលិតផលនេះ",
    "shop_checkout_review_cart_screen_title": "ពិនិត្យមើលការបញ្ជាទិញ",
    "shop_checkout_screen_title": "បន្តទិញ",
    "shop_checkout_select_schedule": "ជ្រើសរើសថ្ងៃនិងម៉ោងដឹកជញ្ជូន",
    "shop_checkout_slot_not_available_error": "ចន្លោះពេលមិនមានទេ។",
    "shop_checkout_submit_receipt_description": "សូមបញ្ជូលបង្កាន់ដៃរបស់អ្នកនៅទីនេះ",
    "shop_checkout_subtotal": "ថ្លៃទំនិញ",
    "shop_checkout_update_cart": "កំពុងអាប់ដេតកន្រ្កក...",
    "shop_checkout_wallet": "កាបូបលុយ",
    "shop_checkout_your_order_label": "ការបញ្ជាទិញរបស់អ្នក",
    "shop_current_location_current_location_label": "ទីតាំងបច្ចុប្បន្ន",
    "shop_current_location_locating_label": "កំណត់ទីតាំង",
    "shop_current_location_saved_address_label": "អាសយដ្ឋានដែលបានរក្សាទុក",
    "shop_current_location_screen_title": "អាសយដ្ឋានបច្ចុប្បន្ន",
    "shop_current_location_search_address_label": "ស្វែងរកអាសយដ្ឋាន",
    "shop_current_location_search_input_hint": "កំណត់ទីតាំងដឹកជញ្ជូន",
    "shop_current_location_search_result_label": "លទ្ធផលស្វែងរក",
    "shop_delivery_confirmation_button": "ការបញ្ជាក់",
    "shop_delivery_no_date_selected_error": "សូមជ្រើសរើសកាលបរិច្ឆេទ",
    "shop_delivery_no_time_selected_error": "សូមជ្រើសរើសពេលវេលាដឹកជញ្ជូន",
    "shop_delivery_no_times": "គ្មានពេលទំនេរ",
    "shop_delivery_screen_title": "ការដឹកជញ្ជូន ",
    "shop_delivery_slot_screen_title": "ពេលវេលាទំនេរដែលអាចដឹកបាន",
    "shop_delivery_tab_delivery": "ដឹកជញ្ជូន",
    "shop_delivery_tab_pickup": "ទៅយកផ្ទាល់",
    "shop_edit_address_screen_title": "កែប្រែអាសយដ្ឋាន",
    "shop_extra_supplier_item_title": "បន្ថែមទៅការបញ្ជាទិញរបស់អ្នក៖",
    "shop_item_counter_update_alert": "អ្នក​បាន​កែប្រែ​ចំនួន​បញ្ជា​ទិញរបស់​ផលិតផល​នេះ! តើអ្នកចង់រក្សាទុកវាទេ?",
    "shop_item_detail_confirm_button": "យល់ព្រម",
    "shop_item_detail_input_hint": "ឧ. គ្នានស្ករ",
    "shop_item_detail_label": "ព័ត៌មានលម្អិត",
    "shop_item_detail_option_edit_label": "កែប្រែជម្រើស",
    "shop_item_detail_option_selection_required": "សូមជ្រើសរើសជម្រើសមួយ",
    "shop_item_detail_section_required_label": "តម្រូវ",
    "shop_item_detail_special_intruction_description": "សូមអោយពួកយើងដឹងថាអ្នកមានការណែនាំពិសេសណាមួយក្នុងការកុម្ម៉ង់",
    "shop_item_detail_special_intruction_title": "ការណែនាំពិសេស",
    "shop_item_filter_clear_button": "លុបចេញ",
    "shop_item_filter_placeholder": "វាយបញ្ចូលដើម្បីត្រួតពិនិត្យ",
    "shop_item_filter_save_button": "រក្សាទុក",
    "shop_item_filter_title": "ត្រួតពិនិត្យ",
    "shop_item_get_same_day": "ទទួលបានថ្ងៃនេះ",
    "shop_item_get_tomorrow": "ទទួលបានថ្ងៃស្អែក",
    "shop_item_in_stock_label": "នៅ​ក្នុង​ស្តុក",
    "shop_item_out_of_stock": "អស់ពី​ស្តុក",
    "shop_item_promo_label": "ប្រូម៉ូសិន",
    "shop_item_review_label": "ពិនិត្យ",
    "shop_item_show_out_of_stock_option_title": "បង្ហាញផលិតផលអស់ស្តុក",
    "shop_item_sku": "SKU",
    "shop_item_sort_by_name_asc_title": "ឈ្មោះ (A ដល់ Z)",
    "shop_item_sort_by_name_desc_title": "ឈ្មោះ (Z ទៅ A)",
    "shop_item_sort_by_price_high_to_low_title": "តម្លៃ (ខ្ពស់ទៅទាប)",
    "shop_item_sort_by_price_low_to_high_title": "តម្លៃ (ទាបទៅខ្ពស់)",
    "shop_item_sort_title": "បង្ហាញតាមលំដាប់",
    "shop_item_stock_availability_label": "ភាពអាចរកបាន",
    "shop_item_update_error": "មានបញ្ហាកំឡុងពេលអាប់ដេតកន្រ្តករបស់អ្នក សូមព្យាយាមម្តងទៀត",
    "shop_list_current_location_label": "ទីតាំងបច្ចុប្បន្ន",
    "shop_list_location_permission_error": "សូមអនុញ្ញាតឱ្យកម្មវិធីចូលប្រើទីតាំងបច្ចុប្បន្នរបស់អ្នក",
    "shop_list_slot_button_text": "ត្រួតពិនិត្យមើលពេលវេលា",
    "shop_list_your_location_label": "ទីតាំងរបស់អ្នក",
    "shop_new_address_address_detail_error": "បញ្ចូលទីតាំង",
    "shop_new_address_address_title": "អាសយដ្ឋាន",
    "shop_new_address_city_input_hint": "ជ្រើសរើសទីក្រុង",
    "shop_new_address_city_phnom_penh_option_title": "រាជធានីភ្នំពេញ",
    "shop_new_address_city_province_option_title": "បណ្តាខេត្ត",
    "shop_new_address_city_title": "ទីក្រុង",
    "shop_new_address_contact_title": "ទំនាក់ទំនង",
    "shop_new_address_country_code_title": "លេខ​កូដ​ប្រទេស",
    "shop_new_address_customer_name_input_hint": "បញ្ចូលឈ្មោះ",
    "shop_new_address_customer_name_title": "ឈ្មោះអតិថិជន",
    "shop_new_address_delete_button": "លុប",
    "shop_new_address_delivery_instruction_input_hint": "បញ្ចូលការណែនាំអំពីការដឹកជញ្ជូន",
    "shop_new_address_delivery_instruction_title": "ការណែនាំអំពីការដឹកជញ្ជូន",
    "shop_new_address_detail_address_input_hint": "បញ្ចូលលេខអគារឬផ្ទះ",
    "shop_new_address_detail_address_title": "No.",
    "shop_new_address_done_button": "រួចរាល់",
    "shop_new_address_english_only": "(English only)",
    "shop_new_address_error_field_required": "តម្រូវឲ្យបំពេញ",
    "shop_new_address_first_name_input_hint": "បញ្ចូលឈ្មោះ",
    "shop_new_address_first_name_title": "ឈ្មោះ",
    "shop_new_address_geolocation_error": "កម្មវិធីរុករកនេះមិនគាំទ្រទីតាំងភូមិសាស្ត្រទេ។",
    "shop_new_address_home_label": "HOME",
    "shop_new_address_house_no_input_hint": "បញ្ចូលលេខផ្ទះ",
    "shop_new_address_house_no_title": "លេខ​ផ្ទះ",
    "shop_new_address_invalid_input_phone_number_format": "លេខទូរស័ព្ទមិនត្រឹមត្រូវ សូមបញ្ចូលលេខទូរស័ព្ទត្រឹមត្រូវ",
    "shop_new_address_label_input_hint": "រក្សាទុកជា (Home, Work, Temporary)",
    "shop_new_address_label_title": "ជ្រើសរើសប្រភេទទីតាំងរបស់អ្នក",
    "shop_new_address_last_name_input_hint": "បញ្ចូលនាមត្រកូល",
    "shop_new_address_last_name_title": "នាមត្រកូល",
    "shop_new_address_location_hint": "ប្រើផែនទី",
    "shop_new_address_map_back_button": "ត្រឡប់​ក្រោយ",
    "shop_new_address_map_my_location_label": "ទីតាំងរបស់ខ្ញុំ",
    "shop_new_address_map_search_address_input_hint": "ស្វែងរកអាសយដ្ឋាន",
    "shop_new_address_map_sub_title": "សម្គាល់ទីតាំងដឹកជញ្ជូនរបស់អ្នកនៅទីនេះ",
    "shop_new_address_mark_delivery_button": "សម្គាល់ទីតាំងដឹកជញ្ជូន",
    "shop_new_address_missing_details_error": "សូមបញ្ចូលព័ត៌មានលម្អិតដែលបាត់",
    "shop_new_address_other_label": "ផ្សេងៗ",
    "shop_new_address_phone_number_input_hint": "បញ្ចូលលេខទូរស័ព្ទ",
    "shop_new_address_phone_number_title": "លេខទូរស័ព្ទ",
    "shop_new_address_photo_button_label": "គេហដ្ឋាន",
    "shop_new_address_photo_description": "រូបថតនៃអាសយដ្ឋានដឹកជញ្ជូន នឹងជួយឱ្យអ្នកដឹកជញ្ជូនរបស់យើងចែកចាយអាហារដែលអ្នករងចាំបានយ៉ាងឆាប់រហ័ស",
    "shop_new_address_photo_title": "រូបថត",
    "shop_new_address_primary_address": "អាស័យដ្ឋានបឋម",
    "shop_new_address_save_address_button": "រក្សាទុកអាសយដ្ឋាន",
    "shop_new_address_school_label": "សាលារៀ់ន",
    "shop_new_address_screen_title": "អាសយដ្ឋានថ្មី",
    "shop_new_address_select_city_title": "ជ្រើសរើសទីក្រុង",
    "shop_new_address_street_no_input_hint": "បញ្ចូលលេខផ្លូវ ឬឈ្មោះផ្លូវ",
    "shop_new_address_street_no_title": "លេខផ្លូវ ឬឈ្មោះផ្លូវ",
    "shop_new_address_update_button": "ធ្វើបច្ចុប្បន្នភាព",
    "shop_new_address_work_label": "កន្លែងធ្វើការ",
    "shop_notification_order_number_title": "លេខកុម្ម៉ង់",
    "shop_notification_shop_close_status": "សូមអភ័យទោស ហាងយើងខ្ញុំបិទបណ្តោះអាសន្ន",
    "shop_notification_shop_open_status": "ហាងយើងខ្ញុំចាប់បើកហើយ បងប្អូនអាចទិញទំនិញបានយ៉ាងរីករាយ!!!",
    "shop_notification_status_arrived": "ការបញ្ជាទិញរបស់អ្នកបានមកដល់ហើយ",
    "shop_notification_status_canceled": "ការបញ្ជាទិញរបស់អ្នកបានលុបចោល",
    "shop_notification_status_delivered": "ការបញ្ជាទិញរបស់អ្នកត្រូវបានដឹកជញ្ជូន",
    "shop_notification_status_delivering": "ការបញ្ជាទិញរបស់អ្នកកំពុងត្រូវបានដឹកជញ្ជូនដោយភ្នាក់ងារដឹកជញ្ជូន",
    "shop_notification_status_processing": "ការបញ្ជាទិញរបស់អ្នកកំពុងត្រូវបានរៀបចំ",
    "shop_order_detail_bill_detail_title": "ព័ត៌មានលម្អិតអំពីវិក័យប័ត្រ",
    "shop_order_detail_payment_method_cash": "សាច់ប្រាក់",
    "shop_order_detail_payment_method_title": "វិធីទូទាត់ប្រាក់",
    "shop_order_detail_re_order_button": "កុម្ម៉ង់ម្ដងទៀត",
    "shop_order_detail_re_order_error": "អ្នក​មានផលិតផល​មួយ​ចំនួន​នៅ​ក្នុង​ការបញ្ជាទិញ​រួច​ហើយ។ ប្រសិនបើអ្នកបញ្ជាទិញឡើងវិញ ផលិតផលរបស់អ្នកនឹងត្រូវដកចេញ។ តើ​អ្នក​ប្រាកដ​ឬ​អត់?",
    "shop_order_detail_remove_item": "លុបចេញ",
    "shop_order_detail_remove_item_title": "ប្រសិនបើមិនមានផលិតផលនេះ",
    "shop_order_detail_screen_title": "ការកុម្ម៉ង់: {path}",
    "shop_order_detail_switch_to_express_mode_message_for_reorder": "សូមប្តូរទៅ Express មុនពេលបន្តបញ្ជាទិញម្តងទៀត។",
    "shop_order_detail_switch_to_normal_mode_message_for_reorder": "សូមប្តូរទៅ Delishop មុនពេលបន្តបញ្ជាទិញម្តងទៀត។",
    "shop_order_list_button_ok_dialog": "យល់ព្រម",
    "shop_order_list_comment_label": "មតិយោបល់",
    "shop_order_list_delivery_slot_label": "ពេលវេលាដឹកជញ្ជូន",
    "shop_order_list_end_of_orders_historic": "ចុងបញ្ចប់នៃការទិញរបស់អ្នក",
    "shop_order_list_items_label": "Items",
    "shop_order_list_order_date_title": "កាលបរិច្ឆេទ",
    "shop_order_list_order_details_title": "ព័ត៌មានលម្អិតនៃការបញ្ជាទិញ",
    "shop_order_list_order_number_title": "លេខកុម្ម៉ង់",
    "shop_order_list_price_label": "តម្លៃ",
    "shop_order_list_quantity_label": "បរិមាណ",
    "shop_order_list_re_order_label": "បញ្ជាទិញម្តងទៀត",
    "shop_order_list_re_order_success_content_dialog": "ប្រសិនបើផលិតផលមួយចំនួនរបស់អ្នកមិនមានទៀតទេ ពួកវានឹងត្រូវដកចេញពីការបញ្ជាទិញរបស់អ្នក។",
    "shop_order_list_re_order_success_title_dialog": "បញ្ជាទិញឡើងវិញនូវសំណើជោគជ័យ",
    "shop_order_list_screen_current_order_tab": "ការបញ្ជាទិញបច្ចុប្បន្ន",
    "shop_order_list_screen_past_order_tab": "ការបញ្ជាទិញពីមុន",
    "shop_order_list_screen_title": "ការកុម្ម៉ង់",
    "shop_order_list_status_arrived": "មកដល់",
    "shop_order_list_status_canceled": "បានលុបចោល",
    "shop_order_list_status_delivered": "បានដឹកជញ្ជូន",
    "shop_order_list_status_delivering": "កំពុងដឹកជញ្ជូន",
    "shop_order_list_status_pending": "កំពុងរងចាំ",
    "shop_order_list_status_pick_up": "បានមកយក",
    "shop_order_list_status_processing": "កំពុងដំណើរការ",
    "shop_order_list_total_title": "សរុប:",
    "shop_order_tracking_button": "តាមដានការដឹកជញ្ជូន",
    "shop_order_tracking_empty_message": "អ្នកនឹងឃើញការបញ្ជារទិញរបស់លោកអ្នកនៅទីនេះ។ អ្នកក៏អាចទំនាក់ទំនងទៅកាន់ភ្នាក់ងាររបស់យើង ប្រសិនបើមិនឃើញការបញ្ជារទិញរបស់លោកអ្នក។",
    "shop_order_tracking_empty_title": "មិនមានការបញ្ជារទិញទេ",
    "shop_order_tracking_list_full_status_title": "ស្ថានភាព:",
    "shop_order_tracking_list_screen_title": "តាមដានការដឹកជញ្ជូន",
    "shop_order_tracking_list_view_all_order_button": "មើលការបញ្ជារទិញទាំងអស់",
    "shop_order_update_item_from_basket_error": "យើង​បាន​ជួប​បញ្ហា​មួយ​ខណៈ​ពេល​ធ្វើ​បច្ចុប្បន្នភាព​ផលិតផលទៅ​ក្នុង​ការបញ្ជារទិញរបស់​អ្នក សូម​ព្យាយាម​ម្ដង​ទៀត។",
    "shop_popup_cancel_payment_button": "បោះបង់ការទូទាត់",
    "shop_popup_continue_payment_button": "បន្តការទូទាត់",
    "shop_popup_payment_locked_label": "ការបញ្ជាទិញត្រូវបានចាក់សោក្នុងដំណើរការ",
    "shop_request_add_item_to_basket_error": "សុំទោស! យើងមិនអាចបន្ថែមទំនិញ {path} ក្នុងការបញ្ជាទិញរបស់អ្នកបានទេ។ សូម​ព្យាយាម​ម្តង​ទៀត!",
    "shop_request_clear_basket_error": "សុំទោស! យើងមិនអាចលុបការបញ្ជាទិញរបស់អ្នកបានទេ។ សូម​ព្យាយាម​ម្តង​ទៀត!",
    "shop_request_remove_item_from_basket_error": "សុំទោស! យើងមិនអាចដកទំនិញ {path} ចេញពីការបញ្ជាទិញរបស់អ្នកបានទេ។ សូម​ព្យាយាម​ម្តង​ទៀត!",
    "shop_request_update_item_to_basket_error": "សុំទោស! យើងមិនអាចធ្វើបច្ចុប្បន្នភាពទំនិញ {path} ក្នុងការបញ្ជាទិញរបស់អ្នកបានទេ។ សូម​ព្យាយាម​ម្តង​ទៀត!",
    "shop_select_location_outside_cambodia_error": "អាស័យដ្ឋានដឹកជញ្ជូនគឺមានតែនៅក្នុងប្រទេសកម្ពុជាប៉ុណ្ណោះ",
    "shop_select_location_screen_title": "កំណត់ទីតាំង",
    "shop_select_location_selection_button": "ប្រើអាសយដ្ឋានដឹកជញ្ជូននេះ",
    "shop_sub_category_load_more_item": " បង្ហាញផលិតផលបន្ថែម",
    "shop_tracking_order_cancel_button": "បោះបង់",
    "shop_tracking_order_screen_title": "ការតាមដានការដឹកជញ្ជូន",
    "shop_tracking_order_title": "តាមដានការបញ្ជាទិញ",
    "sign_up_error_country_code_empty": "ត្រូវបំពេញលេខកូដប្រទេស",
    "sign_up_error_email_taken": "អ៊ី​ម៉ែ​ល​បាន​ប្រើរួច​ហើយ​!",
    "sign_up_error_invalid_email": "អ៊ីមែលមិនត្រឹមត្រូវ, Ex: example@email.com",
    "sign_up_error_invalid_first_last_name": "ត្រូវបំពេញនាមខ្លួន ឬនាមត្រកូល!",
    "sign_up_error_invalid_password": "ពាក្យសម្ងាត់របស់អ្នកត្រូវតែមានយ៉ាងហោចណាស់ 8-16 តួអក្សរ ត្រូវតែមានយ៉ាងហោចណាស់ 1 អក្សរធំ 1 អក្សរតូច និង 1 លេខ",
    "sign_up_error_invalid_phone": "លេខទូរស័ព្ទមិនត្រឹមត្រូវ សូមបញ្ចូលលេខទូរស័ព្ទត្រឹមត្រូវ",
    "sign_up_error_password_mismatch": "ពាក្យសម្ងាត់របស់អ្នកមិនត្រូវគ្នាទេ!",
    "sign_up_error_phone_taken": "លេខទូរស័ព្ទរបស់អ្នកបានចុះឈ្មោះរួចហើយ!",
    "sign_up_profile_continue_button": "បន្ត",
    "sign_up_profile_first_name_input_hint": "បញ្ចូលឈ្មោះរបស់អ្នកនៅទីនេះ ...",
    "sign_up_profile_first_name_input_title": "ឈ្មោះ",
    "sign_up_profile_last_name_input_hint": "បញ្ចូលនាមត្រកូលរបស់អ្នកនៅទីនេះ...",
    "sign_up_profile_last_name_input_title": "នាមត្រកូល",
    "sign_up_profile_title": "ប្រវត្តិរូប",
    "sign_up_screen_already_have_account_title": "មានគណនីរួចហើយ?",
    "sign_up_screen_back_button": "ត្រឡប់",
    "sign_up_screen_back_to_login_button": "ត្រឡប់ទៅចូលគណនី",
    "sign_up_screen_confirm_email_input_title": "បញ្ជាក់អាសយដ្ឋានអ៊ីមែល",
    "sign_up_screen_confirm_password_input_hint": "បញ្ចូលលេខសម្ងាត់ដូចគ្នានៅទីនេះ...",
    "sign_up_screen_confirm_password_input_title": "ផ្ទៀងផ្ទាត់ពាក្យសម្ងាត់",
    "sign_up_screen_country_code_label": "លេខ​កូដ​ប្រទេស",
    "sign_up_screen_email_input_hint": "បញ្ចូលអ៊ីមែ៉​លរបស់អ្នកនៅទីនេះ...",
    "sign_up_screen_email_input_title": "អ៊ីម៉ែល",
    "sign_up_screen_error_confirm_email_msg": "អ៊ីមែលបញ្ជាក់មិនត្រូវគ្នានឹងអ៊ីមែលដែលបានបញ្ជួលទេ",
    "sign_up_screen_error_country_code_msg": "សូមជ្រើសរើសលេខកូដប្រទេស",
    "sign_up_screen_error_enter_first_name_label": "សូមបញ្ចូលឈ្មោះរបស់អ្នក",
    "sign_up_screen_login_redirect_label": "ចូល",
    "sign_up_screen_nationality_title": "សញ្ជាតិ",
    "sign_up_screen_password_input_hint": "បញ្ចូលពាក្យសម្ងាត់របស់អ្នកនៅទីនេះ...",
    "sign_up_screen_password_input_title": "ពាក្យសម្ងាត់",
    "sign_up_screen_phone_input_hint": "បញ្ចូលលេខទូរស័ព្ទរបស់អ្នក",
    "sign_up_screen_phone_input_title": "លេខទូរសព្ទ័",
    "sign_up_screen_phone_title": "លេខទូរសព្ទ",
    "sign_up_screen_sign_up_button": "ចុះ​ឈ្មោះ",
    "sign_up_screen_title": "បង្កើតគណនី",
    "toast_copied": "បានចម្លង",
    "toast_different_file_type": "ឯកសារដែលអ្នកជ្រើសស្ថិតក្នុងប្រភេទខុសគ្នា​ សូមជ្រើសយកឯការដែលស្ថិតក្នុងប្រភេទដូចគ្នា",
    "toast_download_file_failed": "ការទាញយកឯកសារបានបរាជ័យ សូម​ព្យាយាម​ម្តង​ទៀត",
    "toast_downloading": "កំពុង​ទាញយក...",
    "toast_file_not_support": "គ្មានការគាំទ្រចំពោះឯកសារដែលអ្នកបានជ្រើសរើស",
    "toast_file_save": "ឯកសារ​ត្រូវ​បាន​រក្សាទុក",
    "toast_file_too_large": "ឯកសារដែលអ្នកជ្រើសមានទំហំធំ ទំហំអតិប្បរមាគឺ 10MB",
    "toast_save_file_fail": "បរាជ័យក្នុងការរក្សាទុក",
    "toast_text_too_long": "Your text is too long.",
    "toast_too_many_file": "អ្នកជ្រើសឯកសារច្រើនពេក ចំនួនឯកសារអតិប្បរមាគី​ ១០",
    "toast_upload_file_failed": "ការផ្ទុកឯកសារបានបរាជ័យ សូម​ព្យាយាម​ម្តង​ទៀត",
    "verify_error_firebase_token_invalid": "សញ្ញា​សម្ងាត់​មិន​ត្រឹមត្រូវ",
    "verify_phone_continue_button": "បន្ត",
    "verify_phone_description": "បញ្ចូលលេខកូដ 6 ខ្ទង់ដែលយើងផ្ញើទៅ {path}",
    "verify_phone_input_hint": "បញ្ចូលលេខកូដរបស់អ្នកនៅទីនេះ...",
    "verify_phone_input_title": "កូដ",
    "verify_phone_invalid_code": "លេខកូដមិនត្រឹមត្រូវទេ",
    "verify_phone_login_non_exist": "លេខទូរស័ព្ទមិនទាន់ចុះឈ្មោះ សូមចុះឈ្មោះឥឡូវនេះ!",
    "verify_phone_resend_button": "ផ្ញើឡើងវិញ",
    "verify_phone_resend_success_message": "លេខកូដត្រូវបានផ្ញើម្តងទៀតទៅ {path}",
    "verify_phone_screen_title": "ផ្ទៀងផ្ទាត់លេខកូដ",
    "verify_phone_sign_up_exist": "លេខ​ទូរសព្ទ​បាន​ចុះ​ឈ្មោះ​ហើយ សូម​ចូល​ទៅគណនី!"
  }
}