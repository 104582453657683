import styled from "styled-components";

export const CarouselImage = styled.img`
  .dots {
    color: red;
  }
  padding: 20px 20px 0px 20px;
  border-radius: 25px !important;
  display: block;
  width: 100% ;
  height: 100% ;
  // max-height: 600px;

  @media (max-width: 1024px) {
    height: 100%;
  }
`;
