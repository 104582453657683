import { LOCALES } from '../locales'

export default {
  [LOCALES.en]: {
    "button_add_new": "Add new",
    "button_add_to_cart": "Add To Cart",
    "button_close_label": "Close",
    "button_edit_cart": "Edit cart",
    "call_notification_allow_camera_microphone_permission": "Allow access to your camera and microphone to accept the call",
    "call_notification_allow_camera_permission": "Allow access to your camera to accept the call",
    "call_notification_allow_microphone_permission": "Allow access to your microphone to accept the call",
    "call_notification_audio_status": "Tap to return to call. {path}",
    "call_notification_end_button": "End",
    "call_notification_end_group_audio_call": "%@ end the call",
    "call_notification_end_group_video_call": "%@ end the video chat",
    "call_notification_group_audio_status": "Tap to return to group call. {path}",
    "call_notification_group_video_status": "Tap to return to video call. {path}",
    "call_notification_video_status": "Tap to return to video call. {path}",
    "call_screen_answer_button": "Answer",
    "call_screen_audio_mute_status": "Audio Muted",
    "call_screen_audio_video_disable_status": "Audio and Video off",
    "call_screen_bluetooth_button": "Bluetooth",
    "call_screen_busy_label": "{path} is busy in another call, Please try again later.",
    "call_screen_call_end_label": "Call Ended",
    "call_screen_call_label": "Calling...",
    "call_screen_call_lost_connection_label": "Your call has lost connection",
    "call_screen_camera_off_button": "Camera Off",
    "call_screen_camera_on_button": "Camera On",
    "call_screen_cancel_button": "Cancel",
    "call_screen_chat_button": "Chat",
    "call_screen_close_button": "Close",
    "call_screen_connect_label": "Connecting...",
    "call_screen_decline_button": "Decline",
    "call_screen_deny_button": "Deny",
    "call_screen_group_incoming_call_label": "{path} wants you to join a call",
    "call_screen_group_incoming_video_call_label": "{path} wants you to join a video chat",
    "call_screen_hang_up_button": "Hang Up",
    "call_screen_incoming_call_label": "is calling you...",
    "call_screen_incoming_video_call_label": "is calling video to you...",
    "call_screen_message_button": "Message",
    "call_screen_mute_button": "Mute",
    "call_screen_no_answer_label": "No Answer",
    "call_screen_option_button": "Option",
    "call_screen_other_device_answered_label": "You have answered in other device.",
    "call_screen_out_of_work_hour_label": "You can't make call during out of working hour.",
    "call_screen_phone_button": "Phone",
    "call_screen_receive_request_share_video_label": "Would like to video chat",
    "call_screen_ring_label": "Ringing...",
    "call_screen_share_audio_label": "Sharing audio",
    "call_screen_share_back_button": "Share Back",
    "call_screen_share_video_label": "Sharing video",
    "call_screen_speaker_button": "Speaker",
    "call_screen_tap_return_screen": "Tap to return to call",
    "call_screen_try_again_button": "Try again",
    "call_screen_unmute_button": "Unmute",
    "call_screen_video_disable_status": "Video Disabled",
    "call_screen_wait_request_share_video_label": "Requesting video...",
    "call_screen_waiting_label": "Waiting other to join",
    "call_toast_already_in_call_description": "You're already on a call.",
    "call_toast_already_in_call_title": "Couldn't make call",
    "call_toast_join_call": "{path} joined the call",
    "call_toast_left_call": "{path} left the call",
    "call_toast_no_answer": "{path} didn't answer",
    "call_toast_no_call_feature": "You don't have permission for call feature",
    "call_toast_no_internet": "You need to connected to your network or Wi-Fi to make a call.",
    "call_toast_reject_call": "{path} reject the call",
    "camera_screen_photo_title": "Photo",
    "camera_screen_save_button": "Save",
    "camera_screen_video_title": "Video",
    "change_password_and_confirm_didnt_match": "Your passwords do not match!",
    "change_password_and_confirm_matched": "Password matched",
    "change_password_cancel_button": "Cancel",
    "change_password_change_button": "Change",
    "change_password_confirm_password_input_hint": "Confirm new password",
    "change_password_confirm_password_title": "Confirm new password",
    "change_password_description": "Change your new password",
    "change_password_empty_error": "empty",
    "change_password_error_change_same_password": "Cannot switch to the same password. Please enter a new password",
    "change_password_error_incorrect_password": "Your password is invalid.",
    "change_password_error_password_format": "Your password must be at least 8-16 characters long, with at least 1 uppercase letter, 1 lowercase letter, and 1 number.",
    "change_password_first_time_info": "You are now using the password provided by us, we suggest you change the password for convenience.",
    "change_password_new_password_input_hint": "New password",
    "change_password_new_password_title": "New Password",
    "change_password_ok_continue_button": "Continue",
    "change_password_old_password_input_hint": "Old password",
    "change_password_old_password_title": "Old password",
    "change_password_otp_input_hint": "Enter OTP code sent to email",
    "change_password_otp_title": "OTP Code",
    "change_password_popup_success_reset_password_description": "Password has been reset successfully",
    "change_password_reset_description": "Check your email for the OTP",
    "change_password_reset_not_receive_otp_title": "Haven't receive yet?",
    "change_password_reset_resend_otp_success": "The OTP code has been sent to your email successfully",
    "change_password_reset_resend_otp_title": "Resend OTP",
    "change_password_reset_title": "Reset your Password",
    "change_password_success_message": "Your password changed successfully",
    "change_password_title": "Change your password",
    "change_password_update_button": "Update",
    "chat_delivered_status": "Delivered",
    "chat_failed_status": "Failed",
    "chat_header_active_status": "Active",
    "chat_header_call_button": "Call",
    "chat_header_join_button": "Join",
    "chat_header_offline_status": "Offline",
    "chat_header_participants": "participants",
    "chat_info_added_someone": "added",
    "chat_info_audio_called_you": "called you",
    "chat_info_badge_new": "NEW",
    "chat_info_button_call": "Call",
    "chat_info_button_join": "Join",
    "chat_info_change_group_name": "renamed the group {path}",
    "chat_info_change_group_picture": "changed the group picture",
    "chat_info_create_new_group": "Welcome to {path}.",
    "chat_info_edited_header_message": "Edited",
    "chat_info_end_group_audio_message": "The call end",
    "chat_info_end_group_video_message": "The video chat end",
    "chat_info_missed_your_call_message": "missed your call",
    "chat_info_missed_your_video_call_message": "missed your video call",
    "chat_info_remove_message": "removed a message",
    "chat_info_removed_someone": "removed",
    "chat_info_send_file_message": "sent a file message",
    "chat_info_send_gif_message": "sent a GIF message",
    "chat_info_send_n_gifs_message": "sent {path} GIF message",
    "chat_info_send_n_medias_message": "sent {path} medias",
    "chat_info_send_n_photos_message": "sent {path} photos",
    "chat_info_send_n_videos_message": "sent {path} videos",
    "chat_info_send_photo_message": "sent a photo",
    "chat_info_send_tenor_gif_message": "sent a GIF from Tenor Gif",
    "chat_info_send_video_message": "sent a video",
    "chat_info_send_voice_message": "sent a voice message",
    "chat_info_someone_and_someone": "and",
    "chat_info_someone_from_group": "from the group",
    "chat_info_someone_to_group": "to the group",
    "chat_info_start_group_audio_message": "{path} started a call",
    "chat_info_start_group_video_message": "{path} started a video chat",
    "chat_info_typing": "is typing...",
    "chat_info_video_called_you": "video called you",
    "chat_info_you_audio_called": "You called",
    "chat_info_you_chat": "You",
    "chat_info_you_missed_a_call_message": "you missed a call from {path}.",
    "chat_info_you_missed_a_video_call_message": "you missed a video call from {path}.",
    "chat_info_you_remove_message": "You removed a message",
    "chat_info_you_send_file_message": "You sent a file message",
    "chat_info_you_send_gif_message": "You sent a GIF message",
    "chat_info_you_send_n_gifs_message": "You sent {path} GIF message",
    "chat_info_you_send_n_medias_message": "You sent {path} medias",
    "chat_info_you_send_n_photos_message": "You sent {path} photos",
    "chat_info_you_send_n_videos_message": "You sent {path} videos",
    "chat_info_you_send_photo_message": "You sent a photo",
    "chat_info_you_send_tenor_gif_message": "You sent a GIF from Tenor Gif",
    "chat_info_you_send_video_message": "You sent a video",
    "chat_info_you_send_voice_message": "You sent a voice message",
    "chat_info_you_start_group_audio_message": "You started a call",
    "chat_info_you_start_group_video_message": "You started a video chat",
    "chat_info_you_video_called": "You video called",
    "chat_input_edit_message_title": "Edit Message",
    "chat_input_hint": "Type a message",
    "chat_input_voice_slide_hint": "Slide to cancel",
    "chat_message_add_or_remove_you": "You",
    "chat_message_added_someone": "added",
    "chat_message_audio_call_end": "The call ended.",
    "chat_message_call_again": "call again",
    "chat_message_call_at": "at",
    "chat_message_call_back": "call back",
    "chat_message_called_you": "called you",
    "chat_message_change_group_name": "renamed the group {path}",
    "chat_message_change_group_picture": "changed the group picture",
    "chat_message_file_download": "Download",
    "chat_message_file_open": "Open",
    "chat_message_file_upload_download_progress": "{path} of {path}",
    "chat_message_missed_your_call_message": "missed your call",
    "chat_message_missed_your_video_call_message": "missed your video call",
    "chat_message_remove_message": "removed a message",
    "chat_message_removed_someone": "removed",
    "chat_message_see_more": "See More",
    "chat_message_someone_and_someone": "and",
    "chat_message_someone_from_group": "from the group",
    "chat_message_someone_to_group": "to the group",
    "chat_message_start_group_audio": "started a call",
    "chat_message_start_group_video": "started a video chat",
    "chat_message_typing_more_user": "more...",
    "chat_message_video_call_end": "The video call ended.",
    "chat_message_video_called_you": "video called you",
    "chat_message_welcome_description": "Be the first one to start conversation...",
    "chat_message_welcome_group": "Welcome, {path}",
    "chat_message_you_called": "you called",
    "chat_message_you_missed_a_call_message": "you missed a call",
    "chat_message_you_missed_a_video_call_message": "you missed a video call",
    "chat_message_you_remove_message": "You removed a message",
    "chat_message_you_start_group_audio": "you started a call",
    "chat_message_you_start_group_video": "you started a video chat",
    "chat_message_you_video_called": "you video called",
    "chat_more_text_screen_title": "Message",
    "chat_notification_mention_you_in_message": "{path} mentioned you",
    "chat_notification_reply_button": "Reply",
    "chat_notification_reply_input_hint": "Reply here...",
    "chat_notification_send_button": "Send",
    "chat_notification_send_encrypted_text": "sent a message.",
    "chat_notification_view_button": "View Message",
    "chat_popup_confirm_delete_description": "Are you sure want to delete this message?",
    "chat_popup_confirm_delete_title": "Delete message",
    "chat_popup_copy_text_title": "Copy",
    "chat_popup_edit_title": "Edit",
    "chat_popup_forward_title": "Forward",
    "chat_popup_no_recipient": "No recipient to send message",
    "chat_popup_remove_title": "Remove",
    "chat_popup_reply_title": "Reply",
    "chat_popup_reveal_in_finder_title": "Reveal in finder",
    "chat_popup_save_all_as_title": "Save all as...",
    "chat_popup_save_all_image_title": "Save all",
    "chat_popup_save_as_title": "Save as...",
    "chat_popup_save_title": "Save",
    "chat_popup_share_title": "Share",
    "chat_popup_show_in_explorer_title": "Show in explorer",
    "chat_removed_status": "Removed",
    "chat_reply_gif_message": "GIF",
    "chat_reply_image_message": "Image",
    "chat_reply_video_message": "Video",
    "chat_reply_voice_message": "Voice message",
    "chat_screen_drop_file_upload": "Drop file to upload",
    "chat_screen_empty_message": "No talk yet",
    "chat_screen_not_select": "No conversation selected",
    "chat_screen_typing": "is typing...",
    "chat_screen_unread_message": "Unread Messages",
    "chat_seen_by_everyone": "everyone",
    "chat_seen_by_status": "Seen by",
    "chat_seen_status": "Seen",
    "chat_sending_status": "Sending",
    "chat_sent_status": "Sent",
    "chat_support_welcome_description": "How can we help you?",
    "chat_support_welcome_title": "Welcome to our support",
    "chat_timestamp_active": "Active {path}",
    "chat_timestamp_plural_day_ago_label": "{path} days ago",
    "chat_timestamp_plural_hour_ago_label": "{path} hours ago",
    "chat_timestamp_plural_month_ago_label": "{path} months ago",
    "chat_timestamp_plural_year_ago_label": "{path} years ago",
    "chat_timestamp_single_day_ago_label": "{path} day ago",
    "chat_timestamp_single_hour_ago_label": "{path} hour ago",
    "chat_timestamp_single_month_ago_label": "{path} month ago",
    "chat_timestamp_single_year_ago_label": "{path} year ago",
    "chat_toast_cant_play_audio": "Cannot play audio now!",
    "chat_toast_voice_record_hint": "Hold to record, release to send",
    "comment_edit_cancel_button": "Cancel",
    "comment_edit_save_button": "Save",
    "comment_input_hint": "Write a comment...",
    "comment_load_more_button": "load more",
    "comment_load_more_comment_button": "load more comments",
    "comment_load_more_reply_button": "load more replies",
    "comment_multiple_reply_label": "Replies",
    "comment_popup_confirm_delete_description": "Are you sure want to delete this comment?",
    "comment_popup_confirm_delete_title": "Delete comment",
    "comment_popup_menu_copy": "Copy",
    "comment_popup_menu_delete": "Delete",
    "comment_popup_menu_edit": "Edit",
    "comment_popup_menu_reply": "Reply",
    "comment_posting_status": "Posting...",
    "comment_previous_comment_button": "load previous comments",
    "comment_previous_reply_button": "load previous replies",
    "comment_reply_input_hint": "Write a reply...",
    "comment_reply_label": "Reply",
    "comment_reply_main_comment_not_found": "Comment not found",
    "comment_reply_screen_title": "Replies",
    "comment_reply_to": "reply to",
    "comment_reply_your_comment": "your comment.",
    "comment_reply_your_reply": "your reply.",
    "comment_screen_empty_message": "No comment yet",
    "comment_screen_title": "Comments",
    "comment_toast_delete_fail": "The comment could not be deleted",
    "comment_toast_post_fail": "Your comment could not be saved",
    "common_estimate_time_abbreviation_label": "Est",
    "common_footer_accept_payment_method": "We Accept",
    "common_footer_day_hours_open": "7 days a week, from 10am to 8pm",
    "common_footer_delishop_address": "Cambodia, Phnom Penh, Kampot, Siem Reap",
    "common_footer_delishop_rights_resrved": "Delishop.asia. All Rights Reserved.",
    "common_footer_menu_about_us": "About us",
    "common_footer_menu_contact_us": "Contact us",
    "common_footer_menu_delivery_information": "Delivery information",
    "common_footer_menu_faq": "FAQ",
    "common_footer_menu_my_orders": "My Orders",
    "common_footer_menu_my_profile": "My Profile",
    "common_footer_menu_payment": "Payment",
    "common_footer_menu_refund_policies": "Refund policies",
    "common_footer_menu_terms_conditions": "Terms & Conditions",
    "common_footer_title_menu_about": "About",
    "common_footer_title_menu_account": "Account",
    "common_footer_title_menu_help": "Help",
    "common_footer_title_menu_our_brands": "Our Brands",
    "common_header_button_menu": "MENU",
    "common_no_data_available": "n/a",
    "common_no_products_found_label": "No products found",
    "common_or_label": "Or",
    "common_show_more_label": "Show More",
    "common_timestamp_am_symbol": "AM",
    "common_timestamp_just_now_label": "Just now",
    "common_timestamp_plural_abbreviation_hour_label": "hrs",
    "common_timestamp_plural_abbreviation_minute_label": "mins",
    "common_timestamp_plural_abbreviation_second_label": "secs",
    "common_timestamp_plural_minute_ago_label": "{path} minutes ago",
    "common_timestamp_pm_symbol": "PM",
    "common_timestamp_single_abbreviation_hour_label": "hr",
    "common_timestamp_single_abbreviation_minute_label": "min",
    "common_timestamp_single_abbreviation_second_label": "sec",
    "common_timestamp_single_minute_ago_label": "{path} minute ago",
    "common_timestamp_today_label": "Today",
    "common_timestamp_tomorrow_label": "Tomorrow",
    "contact_empty_message": "You'll see some user here, you can also call or message them from this screen. You can also contact support to add users if you don't have any in your contact list",
    "contact_empty_title": "No Active Contact",
    "contact_us_address_label": "Delishop #71A, Street 179, Phnom Penh Cambodia",
    "contact_us_email_label": "Email:",
    "contact_us_phone_label": "Phone:",
    "contact_us_social_share_label": "Social Share:",
    "conversation_empty_message": "You'll see when others will send you messages here. You can also contact support to add users if you don't have any in your contact list",
    "conversation_empty_title": "No Active Conversation",
    "conversation_screen_all_title": "All",
    "conversation_screen_archive_menu_title": "Archived",
    "conversation_screen_group_title": "Groups",
    "conversation_screen_message_title": "Messages",
    "conversation_screen_more_conversation_title": "More Conversations",
    "create_wall_no_right_to_create_from_external_sharing": "You don't have authorization to create post.",
    "create_wall_posting_notification": "Posting...",
    "create_wall_posting_notification_failed": "Your posting failed. Tap to retry.",
    "create_wall_posting_notification_in_queue": "Your next post is in queue",
    "create_wall_posting_notification_preparing": "Preparing...",
    "create_wall_posting_notification_progression": "Uploading in progress {path}/{path}",
    "create_wall_posting_notification_success": "Your post has been created successfully",
    "create_wall_street_check_in_title": "Check In",
    "create_wall_street_description_hint": "description…",
    "create_wall_street_edit_button": "Edit",
    "create_wall_street_edit_media_add_more_button": "Add More",
    "create_wall_street_edit_media_caption_hint": "Add a caption...",
    "create_wall_street_edit_media_done_button": "Done",
    "create_wall_street_edit_media_title": "Edit",
    "create_wall_street_edit_title": "Edit a Post",
    "create_wall_street_keyboard_done_button": "Done",
    "create_wall_street_photo_button": "Photo/Video",
    "create_wall_street_post_button": "Post",
    "create_wall_street_title": "Create a Post",
    "error_general_description": "An error happen on the mobile application. Try again later and contact the support if you keep meeting this issue.",
    "error_internet_connection_description": "NO NETWORK CONNECTION",
    "error_no_data": "No data",
    "error_product_not_found": "Product not found",
    "error_retry_button": "Retry",
    "forget_password_button_back": "Back to login",
    "forget_password_button_back_to": "Back to ",
    "forget_password_button_continue": "Continue",
    "forget_password_button_resend_code": "Resend",
    "forget_password_button_reset_password": "Reset password",
    "forget_password_confirm_button": "Confirm",
    "forget_password_continue_button": "Continue",
    "forget_password_description": "We'll send you an OTP to reset your password",
    "forget_password_error_email_not_found": "Email not found",
    "forget_password_error_empty_email": "Enter email",
    "forget_password_error_invalid_email": "Enter a valid email",
    "forget_password_input_hint": "Your email here...",
    "forget_password_input_title": "Email address",
    "forget_password_login_link": "login",
    "forget_password_send_code_description": "We have send you a link to reset your password, please check your email inbox.",
    "forget_password_title": "Forget your password",
    "forgot_password_register_link": "Register here",
    "forward_audio_recorded": "Audio Recorded",
    "forward_done_button": "Done",
    "forward_empty_message": "No data",
    "forward_group_header": "Group",
    "forward_input_hint": "Type a message here (optional)",
    "forward_people_header": "Peoples",
    "forward_search_hint": "Search people or groups",
    "forward_send_button": "Send",
    "forward_sent_button": "Sent",
    "forward_share_button": "Share",
    "forward_share_direct_feed": "Share directly on my feed",
    "forward_share_direct_message": "Direct Message",
    "forward_suggest_header": "Suggested",
    "forward_title": "Forward Message",
    "gallery_button_done": "Done",
    "gallery_button_next": "Next",
    "gallery_privacy_description": "Allow application to access your Photos and Videos.",
    "gallery_privacy_title": "Access your Photos and Videos",
    "gallery_select_max": "You can not select more then {path} items",
    "gallery_title": "Camera Roll",
    "group_button_add_member": "Add people",
    "group_button_add_owner": "Add Owner",
    "group_button_remove_member": "Remove",
    "group_button_see_more": "Show more",
    "group_empty_message": "No Data",
    "group_menu_dialog_change_name_button_cancel": "Cancel",
    "group_menu_dialog_change_name_button_ok": "OK",
    "group_menu_dialog_change_name_input_hint": "Group name",
    "group_menu_dialog_change_name_title": "Change the group name",
    "group_menu_file": "Files",
    "group_menu_link": "Links",
    "group_menu_photo": "Photo & Videos",
    "group_menu_search": "Search in conversation",
    "group_menu_voice": "Voices messages",
    "group_popup_menu_change_name": "Change group name",
    "group_popup_menu_change_photo": "Change group photo",
    "group_title_media": "Group Medias",
    "group_title_member": "Member",
    "group_title_multiple_member": "Members",
    "group_title_multiple_owner": "Owners",
    "group_title_owner": "Owner",
    "group_voice_message_at": "at",
    "home_basket_alert_delete_cart": "Are you sure you want to clear the cart ?",
    "home_basket_alert_remove_item": "Are you sure you want to remove this item ?",
    "home_basket_error_value_greater_then_zero": "Please enter a value greater than 0",
    "home_basket_item_label": "item",
    "home_basket_items_label": "items",
    "home_basket_no_product_added": "No products added",
    "home_basket_order_minimum_amount": "The minimum order amount is {path}",
    "home_basket_update_quantity_popup_button_save": "Save changes",
    "home_basket_update_quantity_popup_title": "Enter the quantity of the item",
    "home_brands_label": "Brands",
    "home_delivery_slots_popup_button_disable": "Sorry we are full!",
    "home_delivery_slots_popup_button_enable": "Available",
    "home_delivery_slots_popup_button_next": "Next week",
    "home_delivery_slots_popup_button_prev": "Prev week",
    "home_delivery_slots_popup_sub_title": "Please select your delivery timeslot!",
    "home_item_popup_Loading": "Loading...",
    "home_item_popup_about_label": "About",
    "home_item_popup_button_full_view_label": "Open in Full View",
    "home_item_popup_how_to_use": "How To Use",
    "home_item_popup_ingredient_label": "Ingredient",
    "home_item_popup_item_info": "Item Info",
    "home_menu_account": "Account",
    "home_menu_all_orders": "All orders",
    "home_new_arrivals_label": "New Arrivals",
    "home_popular_products_label": "Popular Products",
    "home_promotions_label": "Promotions",
    "home_testimonial_a_great_service": "A great service overall, undoubtedly the best online supermarket in PP, with excellent customer service",
    "home_testimonial_a_great_service_auth": "Max",
    "home_testimonial_reusable_tote_bag": "I get all my groceries in a nice reusable tote bag. Products from specialized shops in Phnom Penh makes it more than just a supermarket. Thanks team Delishop!",
    "home_testimonial_reusable_tote_bag_auth": "Jeff",
    "home_testimonial_smooth_delivery": "Smooth delivery of products I couldn’t find other places in town and most important is the top-notch quality. I will be back.",
    "home_testimonial_smooth_delivery_auth": "Michael",
    "home_view_all_label": "View All",
    "home_welcome_left_content_list_delivery_hours_text": "2 hours delivery, or next day delivery",
    "home_welcome_left_content_list_option_missing_item_text": "Replacement option if missing item",
    "home_welcome_left_content_list_product_text": "15 000 products",
    "home_welcome_left_content_list_same_day_order_text": "Same day order to be placed before 2pm",
    "home_welcome_left_content_list_shopping_text": "Multi Shopping",
    "home_welcome_left_content_list_total_supplier_text": "85 suppliers delivered at the same time",
    "home_welcome_left_header_sub_title_popup": "I am not in a rush and want to have access to the whole catalogue",
    "home_welcome_left_header_title_popup": "I stay on Delishop Supermarket",
    "home_welcome_right_content_list_delivery_text": "45mn delivery",
    "home_welcome_right_content_list_shopping_text": "Single shopping",
    "home_welcome_right_content_list_suppliers_text": "25 suppliers",
    "home_welcome_right_content_list_time_order_text": "Can order from 10am to 8pm",
    "home_welcome_right_header_sub_title_popup": "I need my products urgently",
    "home_welcome_right_header_title_popup": "I go on Delishop EXPRESS",
    "inboarding_screen_skip_button": "Skip",
    "login_button_forgot_password": "Forget Your Password?",
    "login_button_forgot_password_reset_link": "Reset it",
    "login_button_login": "Continue",
    "login_button_privacy_cookies": "privacy & cookies",
    "login_button_sign_up": "Sign Up",
    "login_button_term_of_use": "Terms of use",
    "login_dont_have_account_description": "Don't have account?",
    "login_enter_email_to_register_redirection_label": "REGISTER",
    "login_error_account_not_exist_label": "Account does not exist, please {path} to enjoy our services.",
    "login_error_admin_login": "You can not login as admin account",
    "login_error_email_password": "Invalid email or password",
    "login_error_enter_a_valid_email_label": "Enter a valid email",
    "login_error_enter_an_email_label": "Enter an email. {path} to enjoy our services.",
    "login_error_field_required": "This field is required",
    "login_error_incorrect_email": "Your email is incorrect.",
    "login_error_incorrect_phone": "Invalid phone number, Please enter correct phone number.",
    "login_error_not_logged_in": "Please login and add at least 1 item in your basket to check available slots",
    "login_error_password": "Invalid password",
    "login_error_phone_password": "Invalid phone or password",
    "login_error_wrong_password_label": "Wrong password. Please try again!",
    "login_forgot_password_label": "Forget Your Password?",
    "login_input_email_hint": "Your email here...",
    "login_input_email_phone_hint": "Email/Phone",
    "login_input_email_phone_title": "Email or Phone",
    "login_input_email_title": "Email Address",
    "login_input_password_hint": "Your password here...",
    "login_input_password_title": "Password",
    "login_input_phone_hint": "Enter your phone number",
    "login_input_phone_title": "Phone Number",
    "login_login_here_label": "Login here",
    "login_re_login_error": "Apology for the inconvenience with your order, kindly re-login",
    "login_title": "Sign In",
    "login_version_label": "version",
    "login_welcome_back_label": "Welcome Back",
    "media_preview_screen_menu_save_title": "Save",
    "media_preview_screen_menu_share_title": "Share",
    "media_preview_screen_more_button": "More",
    "notification_comment_on_image": "comments on your photo",
    "notification_comment_on_media": "comments on your media",
    "notification_comment_on_post": "comments on your post",
    "notification_comment_on_video": "comments on your video",
    "notification_create_new_group": "create a group.",
    "notification_create_new_post": "added a new post.",
    "notification_default_text": "made an action.",
    "notification_empty_data": "No Data",
    "notification_like_comment": "likes your comment",
    "notification_like_image": "like your photo.",
    "notification_like_media": "like your media.",
    "notification_like_post": "like your post",
    "notification_like_reply_comment": "likes your comment",
    "notification_like_video": "like your video.",
    "notification_mention_on_comment": "mentioned you in a comment",
    "notification_mention_on_post": "mentioned you in a post",
    "notification_mention_on_reply_comment": "mentioned you in a comment.",
    "notification_order_delivering": "is delivering your order.",
    "notification_order_processing": "is processing your order.",
    "notification_reply_on_comment": "replied to your comment",
    "notification_title": "Notifications",
    "notification_title_earlier": "Earlier",
    "notification_title_new": "New",
    "notification_update_button": "Update now!",
    "notification_update_label": "A new version of {path} available now!",
    "payment_aba_card": "ABA, VISA, Mastercard during Check Out",
    "payment_aba_qr": "By ABA QR code Transfer",
    "payment_accept_payment": "We accept payment :",
    "payment_delivery_price": "Delivery prices are set as follows:",
    "payment_exchange_rate": "Exchange rate : $1 = 4100 Riels",
    "payment_heading_title": "Payments",
    "payment_in_cash": "In cash, in USD and/or Riels",
    "payment_minimum_order": "The minimum order is {path}",
    "payment_price_above": "From {path} and above: Free",
    "payment_price_from": "From {path} to {path}",
    "popup_ask_floating_window_permission_for_call_description": "Do you want your call to display over floating window?",
    "popup_ask_floating_window_permission_for_video_description": "Please allow floating permission to display video over floating window!",
    "popup_ask_floating_window_permission_proceed_button": "Proceed",
    "popup_ask_floating_window_permission_title": "Floating Window",
    "popup_ask_floating_window_permission_title_do_not_ask_again_label": "Don't ask again",
    "popup_camera_permission_denied": "Camera access is denied",
    "popup_cancel_button": "Cancel",
    "popup_country_code_screen_title": "Country Code",
    "popup_country_code_search_input_hint": "Type country code or name...",
    "popup_country_code_section_common_title": "Common",
    "popup_country_code_section_current_title": "Current",
    "popup_device_notification_setting_description": "Please check your device management and allow {path} all options to receive notification properly even after the app get closed.",
    "popup_device_notification_setting_do_not_ask_again_label": "Already done or don't ask again",
    "popup_device_notification_setting_proceed_button": "Proceed",
    "popup_device_notification_setting_title": "Your Device May Not Receiving Notification As Expected",
    "popup_error_notification_redirection_not_found_message": "The destination content was not found.",
    "popup_error_notification_redirection_not_found_title": "Notification",
    "popup_error_title": "Error",
    "popup_go_to_setting_button": "Go to the settings",
    "popup_location_permission_denied": "We need your permission to access your location",
    "popup_microphone_camera_permission_denied": "Microphone and Camera denied access",
    "popup_microphone_permission_denied": "Microphone access is denied",
    "popup_no_button": "No",
    "popup_not_now_button": "Not now",
    "popup_ok_button": "OK",
    "popup_permission_app_setting_title": "App Settings",
    "popup_permission_do_not_allow_button": "Don't allow",
    "popup_permission_force_request_camera_description": "You've previously disabled camera permission on {path}. To enable this, click App Settings below and activate Camera under the Permissions menu.",
    "popup_permission_force_request_location_description": "You've previously disabled location permission on {path}. To enable this, click App Settings below and activate Location under the Permissions menu.",
    "popup_permission_force_request_microphone_description": "You've previously disabled microphone permission on {path}. To enable this, click App Settings below and activate Microphone under the Permissions menu.",
    "popup_permission_force_request_storage_description": "You've previously disabled storage permission on {path}. To enable this, click App Settings below and activate Storage under the Permissions menu.",
    "popup_permission_request_enable_precise_location_description": "To have better experience with selecting address, please allow the application to access device's precise location.",
    "popup_permission_request_enable_precise_location_title": "Enable Precise Location Access from App Settings",
    "popup_permission_request_title": "Enable Access in App Settings",
    "popup_permission_require_all_permission_description": "For better experiences, {path} would like to access some permissions from your device.",
    "popup_photo_permission_denied": "Photos access is denied",
    "popup_request_location_permission_description": "To have better experience with selecting address, please allow the application to access device's location.",
    "popup_session_expired_description": "Warning: for security reason, your session has expired, you will be redirected to the login screen.",
    "popup_session_expired_title": "Session Expired",
    "popup_success_title": "Success",
    "popup_update_button": "Update",
    "popup_update_screen_description": "To start using this new version, {path} need to be closed. Would you like to close it now?",
    "popup_update_screen_title": "A new version of {path} available now!",
    "popup_yes_button": "Yes",
    "post_count_comment": "Comment",
    "post_count_like": "Like",
    "post_count_multiple_comment": "Comments",
    "post_count_multiple_like": "Likes",
    "post_create_new_hint": "Write something now...",
    "post_description_see_more": "See more",
    "post_detail_screen_post_deleted_message": "This post has been deleted.",
    "post_error_message": "Something goes wrong, please retry...",
    "post_location_at": "at",
    "post_menu_copy_link": "Copy link",
    "post_menu_delete_post_title": "Delete post",
    "post_menu_edit_post_title": "Edit post",
    "post_popup_confirm_delete_description": "Are you sure want to delete this post?",
    "post_popup_confirm_delete_title": "Delete post",
    "post_posting": "Posting...",
    "post_screen_empty_message": "Start create your feed here...",
    "post_user_like_screen_title": "Likes",
    "post_user_view_screen_title": "People",
    "privacy_corporate_description": "Only the members of the company can see your post",
    "privacy_corporate_title": "Corporate",
    "privacy_description": "You can change the privacy for your post here",
    "privacy_header_title": "Select your privacy",
    "privacy_only_me_description": "Only you can see your post",
    "privacy_only_me_title": "Only me",
    "privacy_public_description": "Anyone can see your post",
    "privacy_public_title": "Public",
    "privacy_title": "What people can see from you",
    "profile_user_add_address_button": "Add Address",
    "profile_user_address_details": "Address Details",
    "profile_user_call_button": "Call",
    "profile_user_category_menu_label": "Select your category",
    "profile_user_change_button": "Edit",
    "profile_user_change_password_title": "Change Password",
    "profile_user_change_phone_error": "This phone number is already exist in the system. Please use another one!",
    "profile_user_current_past_order_title": "Current & Past Orders",
    "profile_user_default_role": "Guest",
    "profile_user_edit_button": "Edit",
    "profile_user_email_input_title": "Email Address",
    "profile_user_email_title": "Email Address",
    "profile_user_first_name_title": "First Name",
    "profile_user_full_name_input_title": "Name",
    "profile_user_id_title": "User ID",
    "profile_user_last_name_title": "Last Name",
    "profile_user_login_or_register": "Login or Register",
    "profile_user_logout_button": "Logout",
    "profile_user_message_button": "Message",
    "profile_user_my_order_button": "My Orders",
    "profile_user_password_title": "Password",
    "profile_user_phone_input_title": "Mobile",
    "profile_user_phone_title": "Mobile",
    "profile_user_popup_log_out_description": "Do you want to log out?",
    "profile_user_popup_log_out_success_description": "You have been logout successfully",
    "profile_user_popup_log_out_title": "Log out",
    "profile_user_popup_update_profile_success_description": "Your profile has been updated",
    "profile_user_popup_upload_photo_fail": "An error happened during the upload. Please try again.",
    "profile_user_profile_button": "Profile",
    "profile_user_save_button": "Save",
    "profile_user_section_address_title": "Address Detail",
    "profile_user_section_info_title": "Personal Detail",
    "profile_user_section_localization_title": "Localization",
    "profile_user_section_mobile_and_password_title": "Mobile & Security",
    "profile_user_section_option_title": "Account Options",
    "profile_user_section_order_title": "My Order ",
    "profile_user_section_password_title": "Change Password",
    "profile_user_section_shop_title": "Ecommerce & Service",
    "profile_user_section_theme_title": "Edit Theme",
    "profile_user_section_wallet_title": "My Wallet",
    "profile_user_setting_button": "Settings",
    "profile_user_status_online": "Online",
    "profile_user_title": "My Profile",
    "profile_user_video_button": "Video",
    "profile_user_wallet_management_admin_heading": "Admin",
    "profile_user_wallet_management_amount_heading": "Amount",
    "profile_user_wallet_management_comment_heading": "Comment",
    "profile_user_wallet_management_creation_date_time_heading": "Creation Date/Time",
    "profile_user_wallet_management_currency": "(USD)",
    "profile_user_wallet_management_empty": "Empty",
    "profile_user_wallet_management_heading": "Manage Wallet",
    "profile_user_wallet_management_order_number_heading": "Order Number",
    "profile_user_wallet_management_reason_heading": "Reason",
    "profile_user_wallet_management_sub_title_label": "Your Wallet Balance",
    "profile_user_wallet_management_table_title": "Last Transactions",
    "profile_user_wallet_management_title_label": "Wallet Details",
    "profile_user_wallet_management_type_heading": "Type",
    "register_login_popup_email_button": "Continue with email",
    "register_login_popup_or_label": "Or",
    "register_login_popup_phone_button": "Continue with phone",
    "register_login_popup_privacy_policy": "Privacy Policy",
    "register_login_popup_term_condition": "Term and Condition",
    "register_login_popup_term_condition_and_privacy_policy_description": "By continuing, you agree to our {path} and {path}",
    "register_login_popup_title": "Sign up or log in",
    "search_address_button_confirm": "Confirm",
    "search_address_default_address": "Unnamed Road",
    "search_address_empty_message": "Search not found",
    "search_address_input_hint": "Street or place Name",
    "search_address_looking_for_address_title": "Looking for address...",
    "search_address_map_title": "Map",
    "search_address_select_location_on_map_title": "Set your location on the map",
    "search_address_set_location_on_map": "Set location on map",
    "search_address_title": "Set your location",
    "search_gif_empty_message": "No GIFs to show",
    "search_gif_input_hint": "Search GIFs",
    "search_group_empty_message": "Search not found",
    "search_group_input_hint": "Search in conversation",
    "search_home_screen_active_title_hint": "Looking for what...",
    "search_home_screen_empty_message": "Search not found",
    "search_home_screen_input_hint": "Search",
    "search_home_screen_suggested_people_title": "Suggested people",
    "search_home_screen_tab_all_title": "ALL",
    "search_home_screen_tab_message_title": "MESSAGES",
    "search_home_screen_tab_people_title": "PEOPLE",
    "search_item_home_screen_active_title_hint": "Looking for what item...?",
    "search_item_home_screen_empty_message": "Search not found",
    "search_item_home_screen_input_hint": "Search",
    "search_item_result_title": "Searched Results",
    "search_item_suggestion_in_label": "in",
    "search_item_suggestion_label": "Search Suggestions",
    "search_mention_empty_message": "Mention not found",
    "search_mention_hint": "Type two character",
    "search_supplier_item_hint": "Search in {path}",
    "setting_audio_video_audio_call_description": "Audio Call",
    "setting_audio_video_audio_call_disabled": "Audio calling disabled.",
    "setting_audio_video_audio_title": "Audio",
    "setting_audio_video_camera_label": "Camera",
    "setting_audio_video_microphone_title": "Microphone",
    "setting_audio_video_no_camera_found": "No camera found.",
    "setting_audio_video_no_device_found": "No device found.",
    "setting_audio_video_speaker_title": "Speaker",
    "setting_audio_video_video_call_description": "Video Call",
    "setting_audio_video_video_call_disabled": "Video calling disabled.",
    "setting_audio_video_video_title": "Video",
    "setting_change_language_dialog_cancel_button": "CANCEL",
    "setting_change_language_dialog_description": "Change the language of your navigation",
    "setting_change_language_dialog_ok_button": "OK",
    "setting_change_language_dialog_title": "Localization",
    "setting_change_language_title": "Change your language",
    "setting_change_theme_dialog_cancel_button": "CANCEL",
    "setting_change_theme_dialog_description": "Choose your theme color",
    "setting_change_theme_dialog_ok_button": "OK",
    "setting_change_theme_dialog_title": "Themes",
    "setting_change_theme_title": "Choose theme",
    "setting_disable_notification_description": "You will not receive any notifications until you enable this option",
    "setting_edit_email_title": "Email",
    "setting_edit_firstname_title": "First Name",
    "setting_edit_lastname_title": "Last Name",
    "setting_edit_update_profile_title": "Update Profile",
    "setting_general_account_option_title": "Account Options",
    "setting_general_cache_image_title": "Cache Image",
    "setting_general_clear_caching_description": "Clear the image that save in cache.",
    "setting_general_clear_caching_sub_title": "Clear Cache",
    "setting_general_enable_image_caching_description": "Allow application to save image into cache for better performance.",
    "setting_general_enable_image_caching_sub_title": "Enable Image Caching",
    "setting_general_mute_application_sound_description": "Turn off all application sound",
    "setting_general_mute_application_sound_sub_title": "Mute application Sound",
    "setting_help_update_check_for_update_button": "Check for Update",
    "setting_help_update_check_update": "Checking software update...",
    "setting_help_update_download_update": "Download new version...",
    "setting_help_update_title": "Update",
    "setting_help_update_up_to_date": "Your software is up to date.",
    "setting_help_update_version_label": "Current version",
    "setting_language_english_title": "English",
    "setting_language_french_title": "French",
    "setting_language_khmer_title": "Khmer",
    "setting_popup_confirm_before_disable_notification_description": "Are you sure you want to disable all notification?",
    "setting_popup_confirm_before_disable_notification_title": "Disable all notifications",
    "setting_popup_disable_notification_error_description": "The settings could not be changed",
    "setting_shop_addresses": "Delivery Address",
    "setting_shop_orders_history": "Current & past orders",
    "setting_shop_wallet": "Actual Balance",
    "setting_shop_wallet_history": "Wallet History",
    "setting_tab_audio_and_video": "Audio & Video",
    "setting_tab_general": "General",
    "setting_tab_help_and_update": "Help & Update",
    "setting_theme_dark_title": "Dark",
    "setting_theme_light_title": "Light",
    "setting_theme_purple_title": "Purple",
    "setting_title": "Settings",
    "setting_user_disable_notification_title": "Disable all notification",
    "setting_version": "Version",
    "setting_wallet_history_amount": "Amount:",
    "setting_wallet_history_comment": "Comment:",
    "setting_wallet_history_date": "Date:",
    "setting_wallet_history_info": "Info:",
    "setting_wallet_history_screen_title": "Wallet History",
    "setting_wallet_history_type": "Transaction type:",
    "share_external_log_in_require": "You need to log in into {path} first to share this content.",
    "shop_add_item_to_cart_different_shop_error": "You still have items from another shop. Shall we start over with a fresh cart?",
    "shop_add_item_to_cart_different_shop_error_cancel": "Cancel",
    "shop_add_item_to_cart_different_shop_error_remove": "Remove",
    "shop_add_item_to_cart_different_shop_error_title": "Remove your previous items?",
    "shop_add_new_location_button": "Add a new location",
    "shop_address_home_label": "HOME",
    "shop_address_list_delivery_here_label": "Delivery Here",
    "shop_address_list_empty_message": "You haven't added any addresses",
    "shop_address_list_name_label": "Name",
    "shop_address_list_phone_abbreviation_label": "Ph",
    "shop_address_list_screen_title": "My Address",
    "shop_address_school_label": "SCHOOL",
    "shop_address_work_label": "WORK",
    "shop_category_all_label": "All",
    "shop_category_list_delivery_estimate_time": "Estimate delivery time: {path}",
    "shop_category_list_delivery_time": "Open for delivery: {path}",
    "shop_category_list_express_store_back_redirection": "Express store",
    "shop_category_list_no_product_out_of_stock": "All the products of this category are out of stock for the moment, you can view them by enable the option 'view out of stock'",
    "shop_category_list_shop_closed": "Closed",
    "shop_check_out_button": "Checkout",
    "shop_check_out_subtotal_title": "Shopping Cart",
    "shop_checkout_aba_app_not_install": "No ABA application installed on current device.",
    "shop_checkout_access_public_coupon_title": "Click here to access free coupons",
    "shop_checkout_account_number": "Account Number",
    "shop_checkout_account_owner": "Account Owner",
    "shop_checkout_alert_error": "Alert!",
    "shop_checkout_alert_minimum_cart_amount_error": "Dear customer, minimum order amount is {path}. Please review your cart to add more products.",
    "shop_checkout_alert_no_address_selected_error": "Please Select delivery address!",
    "shop_checkout_alert_selected_address_out_of_range": "The delivery address is out of delivery range area. Please choose another address!",
    "shop_checkout_alert_success_order": "Your order has been created successfully! Thank you for shopping with us!",
    "shop_checkout_apply_coupon_button": "Apply",
    "shop_checkout_asap_text": "ASAP",
    "shop_checkout_bill_detail_title": "Bill Detail",
    "shop_checkout_browse_our_shop_redirection": "browse our shop",
    "shop_checkout_cancel_description": "Cancel my item please",
    "shop_checkout_cancel_or_contact_title": "In case items are Out of Stock, I would like to:",
    "shop_checkout_cancel_title": "Cancel Item",
    "shop_checkout_cant_delete_default_address_error": "You can not delete your default address!",
    "shop_checkout_cart_is_empty_error": "Cart is empty",
    "shop_checkout_cash_method_notice": "For cash method please have the most closet amount, our delivery man don't have a lot of cash for security reason.",
    "shop_checkout_cash_on_delivery_option": "Pay on Delivery",
    "shop_checkout_choose_location_button": "Choose your location",
    "shop_checkout_comment_hint": "Comment",
    "shop_checkout_comment_title": "Add Comment",
    "shop_checkout_confirm_delete_address": "Are you sure you want to delete address",
    "shop_checkout_contact_description": "Contact me about the alternatives",
    "shop_checkout_contact_title": "Be Contacted",
    "shop_checkout_covid19_note": "Please note that due to current COVID-19 situation we might experience some delay in order delivery",
    "shop_checkout_delivery": "Delivery fee",
    "shop_checkout_delivery_and_payment_screen_title": "Delivery option and payment",
    "shop_checkout_delivery_schedule_title": "Select Your Delivery Schedule",
    "shop_checkout_discount": "Discount",
    "shop_checkout_empty_cart_label": "Empty Cart",
    "shop_checkout_error_aba": "ABA services are not responding for the moment.",
    "shop_checkout_error_cart_empty": "Your cart is empty, {path} to add products",
    "shop_checkout_error_invalid_coupon": "Your coupon is invalid!",
    "shop_checkout_forget_select_payment_error": "Please select payment method",
    "shop_checkout_grand_total": "Grand total",
    "shop_checkout_input_coupon_hint": "Enter code",
    "shop_checkout_input_coupon_title": "Have a coupon code?",
    "shop_checkout_input_hint": "Delivery detail:",
    "shop_checkout_item_not_available_error": "We are sorry , item is not available now",
    "shop_checkout_missing_location_label": "Your location is missing",
    "shop_checkout_modify_schedule_label": "Modify The Delivery Schedule",
    "shop_checkout_no_associated_order": "There is no associated order with this cart",
    "shop_checkout_no_item_available_error": "No item available",
    "shop_checkout_online_method_notice": "Please send your payment to our account below",
    "shop_checkout_online_method_option": "Pay online now",
    "shop_checkout_order_not_exist": "This order does not exist!",
    "shop_checkout_out_of_rang": "You are out of delivery range.",
    "shop_checkout_pay_button": "Place Order",
    "shop_checkout_payment_adjust_wallet_title": "Adjust Wallet Balance",
    "shop_checkout_payment_description": "Please choose your payment method here.",
    "shop_checkout_payment_title": "Select Payment Method",
    "shop_checkout_payment_wallet_balance_title": "Actual Balance:",
    "shop_checkout_payment_wallet_title": "Your wallet",
    "shop_checkout_popup_close_payment": "Are you sure you want to close?",
    "shop_checkout_popup_coupon_applied": "Coupon Code applied !",
    "shop_checkout_popup_coupon_code_error": "Coupon code not applicable",
    "shop_checkout_popup_coupon_title_label": "Select a coupon",
    "shop_checkout_proceed_checkout_button": "Proceed to checkout",
    "shop_checkout_products_not_available": "Some products may not be available for same day delivery",
    "shop_checkout_receipt_added": "Receipt added",
    "shop_checkout_receipt_remove_button": "Remove",
    "shop_checkout_remove_coupon_button": "Remove",
    "shop_checkout_remove_item": "remove item",
    "shop_checkout_remove_item_title": "If item not available:",
    "shop_checkout_review_cart_screen_title": "Review Cart",
    "shop_checkout_screen_title": "Checkout",
    "shop_checkout_select_schedule": "Select a delivery slot",
    "shop_checkout_slot_not_available_error": "Slot not available",
    "shop_checkout_submit_receipt_description": "Please submit your receipt here",
    "shop_checkout_subtotal": "Subtotal",
    "shop_checkout_update_cart": "Updating Cart...",
    "shop_checkout_wallet": "Wallet",
    "shop_checkout_your_order_label": "Your Order",
    "shop_current_location_current_location_label": "Current location",
    "shop_current_location_locating_label": "Locating",
    "shop_current_location_saved_address_label": "Saved addresses",
    "shop_current_location_screen_title": "Current Location",
    "shop_current_location_search_address_label": "Search Address",
    "shop_current_location_search_input_hint": "Set a delivery location",
    "shop_current_location_search_result_label": "Search result",
    "shop_delivery_confirmation_button": "Confirmation",
    "shop_delivery_no_date_selected_error": "Please select a delivery date",
    "shop_delivery_no_time_selected_error": "Please select a delivery time",
    "shop_delivery_no_times": "No time available",
    "shop_delivery_screen_title": "Delivery",
    "shop_delivery_slot_screen_title": "Availability of Delivery Slot",
    "shop_delivery_tab_delivery": "Delivery",
    "shop_delivery_tab_pickup": "Pick up",
    "shop_edit_address_screen_title": "Edit Address",
    "shop_extra_supplier_item_title": "ADD TO YOUR ORDER:",
    "shop_item_counter_update_alert": "You have modified the order amount of the product! Do you want to save it?",
    "shop_item_detail_confirm_button": "Confirm",
    "shop_item_detail_input_hint": "Example: no sugar",
    "shop_item_detail_label": "Details",
    "shop_item_detail_option_edit_label": "Edit Option",
    "shop_item_detail_option_selection_required": "Please choose one of the required option",
    "shop_item_detail_section_required_label": "Required",
    "shop_item_detail_special_intruction_description": "Please let us know if you have special requirements for your order",
    "shop_item_detail_special_intruction_title": "Special instructions",
    "shop_item_filter_clear_button": "Clear",
    "shop_item_filter_placeholder": "Type to filter",
    "shop_item_filter_save_button": "Save",
    "shop_item_filter_title": "Filter",
    "shop_item_get_same_day": "Get it Today",
    "shop_item_get_tomorrow": "Get it tomorrow",
    "shop_item_in_stock_label": "In stock",
    "shop_item_out_of_stock": "Out of Stock",
    "shop_item_promo_label": "PROMO",
    "shop_item_review_label": "Reviews",
    "shop_item_show_out_of_stock_option_title": "Show Out of Stock",
    "shop_item_sku": "SKU",
    "shop_item_sort_by_name_asc_title": "Name(A to Z)",
    "shop_item_sort_by_name_desc_title": "Name(Z to A)",
    "shop_item_sort_by_price_high_to_low_title": "Price(High to Low)",
    "shop_item_sort_by_price_low_to_high_title": "Price(Low to High)",
    "shop_item_sort_title": "Sort",
    "shop_item_stock_availability_label": "Availability",
    "shop_item_update_error": "There is an issues during update your cart, please try again",
    "shop_list_current_location_label": "Current location",
    "shop_list_location_permission_error": "Location access denied",
    "shop_list_slot_button_text": "Check Availability",
    "shop_list_your_location_label": "Your location",
    "shop_new_address_address_detail_error": "Enter pin location",
    "shop_new_address_address_title": "Address",
    "shop_new_address_city_input_hint": "Select city",
    "shop_new_address_city_phnom_penh_option_title": "Phnom Penh",
    "shop_new_address_city_province_option_title": "Province",
    "shop_new_address_city_title": "City",
    "shop_new_address_contact_title": "Contact",
    "shop_new_address_country_code_title": "Country Code",
    "shop_new_address_customer_name_input_hint": "Name",
    "shop_new_address_customer_name_title": "Customer Name",
    "shop_new_address_delete_button": "DELETE",
    "shop_new_address_delivery_instruction_input_hint": "Enter Delivery Instruction",
    "shop_new_address_delivery_instruction_title": "Delivery Instruction",
    "shop_new_address_detail_address_input_hint": "Building or house number",
    "shop_new_address_detail_address_title": "No.",
    "shop_new_address_done_button": "DONE",
    "shop_new_address_english_only": "(English only)",
    "shop_new_address_error_field_required": "Field is required",
    "shop_new_address_first_name_input_hint": "Enter first name",
    "shop_new_address_first_name_title": "First Name",
    "shop_new_address_geolocation_error": "Geolocation is not supported by this browser.",
    "shop_new_address_home_label": "HOME",
    "shop_new_address_house_no_input_hint": "Enter house number",
    "shop_new_address_house_no_title": "House No",
    "shop_new_address_invalid_input_phone_number_format": "Invalid phone number, Please enter correct phone number.",
    "shop_new_address_label_input_hint": "Save as (Home, Work, Temporary)",
    "shop_new_address_label_title": "Label",
    "shop_new_address_last_name_input_hint": "Enter last name",
    "shop_new_address_last_name_title": "Last Name",
    "shop_new_address_location_hint": "Set location on map",
    "shop_new_address_map_back_button": "Go Back",
    "shop_new_address_map_my_location_label": "My Location",
    "shop_new_address_map_search_address_input_hint": "Search Address",
    "shop_new_address_map_sub_title": "Mark Your Delivery Location here",
    "shop_new_address_mark_delivery_button": "Mark Delivery Location",
    "shop_new_address_missing_details_error": "Please enter the missing details",
    "shop_new_address_other_label": "OTHER",
    "shop_new_address_phone_number_input_hint": "Phone number",
    "shop_new_address_phone_number_title": "Mobile",
    "shop_new_address_photo_button_label": "House",
    "shop_new_address_photo_description": "The photos of the delivery address will help our delivery man quickly deliver the food you love in no time",
    "shop_new_address_photo_title": "Photo",
    "shop_new_address_primary_address": "Primary Address",
    "shop_new_address_save_address_button": "Save Address",
    "shop_new_address_school_label": "SCHOOL",
    "shop_new_address_screen_title": "New Address",
    "shop_new_address_select_city_title": "Select City",
    "shop_new_address_street_no_input_hint": "Enter Street number/Street Name",
    "shop_new_address_street_no_title": "Street number/Street Name",
    "shop_new_address_update_button": "UPDATE",
    "shop_new_address_work_label": "WORK",
    "shop_notification_order_number_title": "Order No:",
    "shop_notification_shop_close_status": "Sorry, we are closing our shop temporary.",
    "shop_notification_shop_open_status": "We are now open the shop, you can enjoy your shopping!!!",
    "shop_notification_status_arrived": "Your order is arrived.",
    "shop_notification_status_canceled": "Your order is canceled",
    "shop_notification_status_delivered": "Your order is delivered",
    "shop_notification_status_delivering": "Your order is being delivered by courier",
    "shop_notification_status_processing": "Your order is being prepared",
    "shop_order_detail_bill_detail_title": "Bill Detail",
    "shop_order_detail_payment_method_cash": "Cash",
    "shop_order_detail_payment_method_title": "Select Payment Method",
    "shop_order_detail_re_order_button": "Re-Order",
    "shop_order_detail_re_order_error": "You are already have some items in the cart. if you re-order, your items will be removed. Are you sure?",
    "shop_order_detail_remove_item": "remove item",
    "shop_order_detail_remove_item_title": "If item not available:",
    "shop_order_detail_screen_title": "Order: {path}",
    "shop_order_detail_switch_to_express_mode_message_for_reorder": "Please switch to express mode before proceed re-order.",
    "shop_order_detail_switch_to_normal_mode_message_for_reorder": "Please switch to normal mode before proceed re-order.",
    "shop_order_list_button_ok_dialog": "OK",
    "shop_order_list_comment_label": "Comment",
    "shop_order_list_delivery_slot_label": "Delivery Slot",
    "shop_order_list_end_of_orders_historic": "End of your orders historic",
    "shop_order_list_items_label": "Items",
    "shop_order_list_order_date_title": "Order Date",
    "shop_order_list_order_details_title": "Order Details",
    "shop_order_list_order_number_title": "Order No",
    "shop_order_list_price_label": "Price",
    "shop_order_list_quantity_label": "Quantity",
    "shop_order_list_re_order_label": "Reorder",
    "shop_order_list_re_order_success_content_dialog": "If some of your products are no longer available, they will be removed from your cart.",
    "shop_order_list_re_order_success_title_dialog": "Re-order request success",
    "shop_order_list_screen_current_order_tab": "Current Order",
    "shop_order_list_screen_past_order_tab": "Past Order",
    "shop_order_list_screen_title": "Orders",
    "shop_order_list_status_arrived": "Arrived",
    "shop_order_list_status_canceled": "Canceled",
    "shop_order_list_status_delivered": "Delivered",
    "shop_order_list_status_delivering": "Dispatched",
    "shop_order_list_status_pending": "Placed",
    "shop_order_list_status_pick_up": "Pick Up",
    "shop_order_list_status_processing": "Accepted",
    "shop_order_list_total_title": "Total:",
    "shop_order_tracking_button": "Orders tracking",
    "shop_order_tracking_empty_message": "You'll see your tracking orders here. You can also contact support if you don't see your order list",
    "shop_order_tracking_empty_title": "No Active Orders",
    "shop_order_tracking_list_full_status_title": "STATUS:",
    "shop_order_tracking_list_screen_title": "Orders Tracking",
    "shop_order_tracking_list_view_all_order_button": "View all Orders",
    "shop_order_update_item_from_basket_error": "We have encountered an error while updating item to your cart, please retry",
    "shop_popup_cancel_payment_button": "Cancel",
    "shop_popup_continue_payment_button": "Continue",
    "shop_popup_payment_locked_label": "Order Locked in Processing",
    "shop_request_add_item_to_basket_error": "Sorry! we are unable to add {path} in your cart. Please try again!",
    "shop_request_clear_basket_error": "Sorry! we are unable to clear your cart. Please try again!",
    "shop_request_remove_item_from_basket_error": "Sorry! we are unable to remove {path} from your cart. Please try again!",
    "shop_request_update_item_to_basket_error": "Sorry! we are unable to update {path} in your cart. Please try again!",
    "shop_select_location_outside_cambodia_error": "The delivery address is only available in Cambodia.",
    "shop_select_location_screen_title": "Set a location",
    "shop_select_location_selection_button": "Use that delivery address",
    "shop_sub_category_load_more_item": "Load more item",
    "shop_tracking_order_cancel_button": "CANCEL",
    "shop_tracking_order_screen_title": "Delivery Tracking",
    "shop_tracking_order_title": "Track order",
    "sign_up_error_country_code_empty": "country code can not be empty.",
    "sign_up_error_email_taken": "Email already taken!",
    "sign_up_error_invalid_email": "Invalid email, Ex: example@email.com",
    "sign_up_error_invalid_first_last_name": "First or last name can not be empty!",
    "sign_up_error_invalid_password": "Your password must be at least 8-16 characters long, must contain at least 1 uppercase letter, 1 lowercase letter and 1 number.",
    "sign_up_error_invalid_phone": "Invalid phone number, Please enter correct phone number.",
    "sign_up_error_password_mismatch": "Your password didn't match!",
    "sign_up_error_phone_taken": "Your phone number already registered!",
    "sign_up_profile_continue_button": "Continue",
    "sign_up_profile_first_name_input_hint": "Your first name here...",
    "sign_up_profile_first_name_input_title": "First name",
    "sign_up_profile_last_name_input_hint": "Your last name here...",
    "sign_up_profile_last_name_input_title": "Last name",
    "sign_up_profile_title": "Profile",
    "sign_up_screen_already_have_account_title": "Already have an account?",
    "sign_up_screen_back_button": "Back",
    "sign_up_screen_back_to_login_button": "Back to Login",
    "sign_up_screen_confirm_email_input_title": "Confirm Email Address",
    "sign_up_screen_confirm_password_input_hint": "Same password here...",
    "sign_up_screen_confirm_password_input_title": "Confirm Password",
    "sign_up_screen_country_code_label": "Country Code",
    "sign_up_screen_email_input_hint": "Your email here...",
    "sign_up_screen_email_input_title": "Email Address",
    "sign_up_screen_error_confirm_email_msg": "Confirmation email does not match with the mentioned email",
    "sign_up_screen_error_country_code_msg": "Please select a country code",
    "sign_up_screen_error_enter_first_name_label": "Please enter your first name",
    "sign_up_screen_login_redirect_label": "Login",
    "sign_up_screen_nationality_title": "Nationality",
    "sign_up_screen_password_input_hint": "Your password here...",
    "sign_up_screen_password_input_title": "Password",
    "sign_up_screen_phone_input_hint": "Enter your phone number",
    "sign_up_screen_phone_input_title": "Phone",
    "sign_up_screen_phone_title": "Phone Number",
    "sign_up_screen_sign_up_button": "Sign Up",
    "sign_up_screen_title": "Sign up",
    "toast_copied": "Copied",
    "toast_different_file_type": "The file you have selected is in different type. Please select the same file type.",
    "toast_download_file_failed": "File download failed. Please try again.",
    "toast_downloading": "Downloading...",
    "toast_file_not_support": "The file you have selected is not supported.",
    "toast_file_save": "File saved",
    "toast_file_too_large": "The file you have selected is too large. The maximum size is 10MB.",
    "toast_save_file_fail": "Fail to save file",
    "toast_text_too_long": "Your text is too long.",
    "toast_too_many_file": "You have selected too many file. The maximum is 10 files.",
    "toast_upload_file_failed": "File upload failed. Please try again.",
    "verify_error_firebase_token_invalid": "Invalid token",
    "verify_phone_continue_button": "Continue",
    "verify_phone_description": "Enter the 6-degit code we send to {path}",
    "verify_phone_input_hint": "Your code here…",
    "verify_phone_input_title": "Code",
    "verify_phone_invalid_code": "The code is invalid.",
    "verify_phone_login_non_exist": "The phone number is not yet registered, please register now!",
    "verify_phone_resend_button": "Resend",
    "verify_phone_resend_success_message": "Code was resent to {path}",
    "verify_phone_screen_title": "Verify Code",
    "verify_phone_sign_up_exist": "The phone number is already registered, please go to login!"
  }
}