import * as ActionTypes from "../ActionTypes";
import API from "../../network/dashboardAPI";
import _ from 'lodash';

export const getBrands = (data, dispatch) =>{
    API.getBrands(data)
    .then((response) => response.json())
    .then((response)=>{
        if(response.success){
            dispatch({
                type : ActionTypes.GET_BRANDS,
                payload : response.data,
                success: response.success,
            })
        }
    })
}

export const addBrand = (data, dispatch) =>{
    setLoading(true,dispatch);
    API.addBrands(data)
    .then((response) => response.json())
    .then((response)=>{
        setLoading(false,dispatch);
        if(response.success){
            dispatch({
                type : ActionTypes.ADD_BRANDS,
                payload : response.data,
                success: response.success,
            })
            window.location.href = "/admin/brands";
        }else{
          //  console.log(response.error.errors[0].message);
            dispatch({
                type : ActionTypes.SET_ERROR,
                payload : response.error.errors[0].message,
                success: response.success,
            })

        }
    })
}

export const updateBrand = (data, dispatch) =>{
    setLoading(true,dispatch);
    API.updateBrands(data)
    .then((response) => response.json())
    .then((response)=>{
        setLoading(false,dispatch);
        if(response.success){
            dispatch({
                type : ActionTypes.UPDATE_BRANDS,
                payload : response.data,
                success: response.success,
            })
            window.location.href = "/admin/brands";
        }else{
            dispatch({
                type : ActionTypes.SET_ERROR,
                payload : response.error.errors[0].message,
                success: response.success,
            })

        }
    })
}


export const setSuccess = (data,dispatch) => {
    dispatch({
        type : ActionTypes.SET_SUCCESS,
        success : data,
    })
}

export const setError = (data,dispatch) =>{
    dispatch({
        type : ActionTypes.SET_ERROR,
        payload : data,
        success : false
    })
}

export const setLoading = (data,dispatch) => {
    dispatch({
        type : ActionTypes.SET_LOADING,
        payload : data,
    })
}


export const getCategory = (data,dispatch) => {
    API.getCategory(data)
    .then((response) => response.json())
    .then((response)=>{
        if(response.success){
            dispatch({
                type : ActionTypes.GET_CATEGORY,
                payload : response.data,
                success: response.success,
            })
        }
    })
}



export const addCategory = (data, dispatch) =>{
    setLoading(true,dispatch);
    API.addCategory(data)
    .then((response) => response.json())
    .then((response)=>{
        setLoading(false,dispatch);
        if(response.success){
            dispatch({
                type : ActionTypes.ADD_CATEGORY,
                payload : response.data,
                success: response.success,
            })
            window.location.href = "/admin/categories";
        }else{
            dispatch({
                type : ActionTypes.SET_ERROR,
                payload : response.error.errors[0].message,
                success: response.success,
            })

        }
    })
}



export const updateCategory = (data, dispatch) =>{
    setLoading(true,dispatch);
    API.updateCategory(data)
    .then((response) => response.json())
    .then((response)=>{
        if(response.success){
            dispatch({
                type : ActionTypes.UPDATE_CATEGORY,
                payload : response.data,
                success: response.success,
            })
            setLoading(false,dispatch);
            window.location.href = "/admin/categories";
        }else{
            dispatch({
                type : ActionTypes.SET_ERROR,
                payload : response.error.errors[0].message,
                success: response.success,
            })

        }
    })
}




export const getSubCategory = (data,dispatch) => {
    API.getSubCategory(data)
    .then((response) => response.json())
    .then((response)=>{
        if(response.success){
            dispatch({
                type : ActionTypes.GET_SUB_CATEGORY,
                payload : response.data,
                success: response.success,
            })
        }
    })
}



export const addSubCategory = (data, dispatch) =>{
    setLoading(true,dispatch);
    API.addSubCategory(data)
    .then((response) => response.json())
    .then((response)=>{
        setLoading(false,dispatch);
        if(response.success){
            dispatch({
                type : ActionTypes.ADD_SUB_CATEGORY,
                payload : response.data,
                success: response.success,
            })
            window.location.href = "/admin/subCategories";
        }else{
            dispatch({
                type : ActionTypes.SET_ERROR,
                payload : response.error.errors[0].message,
                success: response.success,
            })

        }
    })
}



export const updateSubCategory = (data, dispatch) =>{
    setLoading(true,dispatch);
    API.updateSubCategory(data)
    .then((response) => response.json())
    .then((response)=>{
        if(response.success){
            dispatch({
                type : ActionTypes.UPDATE_SUB_CATEGORY,
                payload : response.data,
                success: response.success,
            })
            setLoading(false,dispatch);
            window.location.href = "/admin/subCategories";
        }else{
            dispatch({
                type : ActionTypes.SET_ERROR,
                payload : response.error.errors[0].message,
                success: response.success,
            })

        }
    })
}


export const getProduct = (data,dispatch) => {
    API.getProduct(data)
    .then((response) => response.json())
    .then((response)=>{
        if(response.success){
            dispatch({
                type : ActionTypes.GET_PRODUCTS,
                payload : response.data,
                success: response.success,
            })
        }
    })
}


export const searchProduct = (data,dispatch) => {
    API.searchProduct(data)
    .then((response) => response.json())
    .then((response)=>{
        if(response.success){
            dispatch({
                type : ActionTypes.SEARCH_PRODUCTS,
                payload : response.data,
                success: response.success,
            })
        }
    })
}




export const getSuppliers = (data,dispatch) => {
    API.getSuppliers(data)
    .then((response) => response.json())
    .then((response)=>{
        if(response.success){
            dispatch({
                type : ActionTypes.GET_SUPPLIERS,
                payload : response.data,
                success: response.success,
            })
        }
    })
};

export const initSupplierOrders = (data, dispatch) => {
  dispatch({
    type : ActionTypes.INIT_SUPPLIER_ORDERS,
    payload : data,
    success: true,
  })
};

export const updateSupplierOrders = (data, dispatch) => {
  dispatch({
    type : ActionTypes.UPDATE_SUPPLIER_ORDERS,
    payload : data,
    success: true,
  })
};

export const initSuppliersLists = (data, dispatch) => {
  let newData = _.orderBy(data, [function(o) { return o.totalPending; }], ["desc"]);

  dispatch({
    type : ActionTypes.INIT_SUPPLIERS_LISTS,
    payload : newData,
    success: true,
  })
};

export const updateSuppliersLists = (data, dispatch) => {
  dispatch({
    type : ActionTypes.UPDATE_SUPPLIERS_LISTS,
    payload : data,
    success: true,
  })
};

export const getSubCategoryMap = (data,dispatch) => {
    API.getSubCategoryMap(data)
    .then((response) => response.json())
    .then((response)=>{
        if(response.success){
            dispatch({
                type : ActionTypes.GET_SUB_CATEGORY_BY_CATEGORY,
                payload : response.data,
                success: response.success,
            })
        }
    })
}

export const addProduct = (data, dispatch) =>{
    setLoading(true,dispatch);
    API.addProduct(data)
    .then((response) => response.json())
    .then((response)=>{
        setLoading(false,dispatch);
        if(response.success){
            dispatch({
                type : ActionTypes.ADD_PRODUCTS,
                payload : response.data,
                success: response.success,
            })
            window.location.href = "/admin/products";
        }else{
            dispatch({
                type : ActionTypes.SET_ERROR,
                payload : response.data,
                success: response.success,
            })

        }
    })
}



export const updateProduct = (data, dispatch) =>{
    setLoading(true,dispatch);
    API.updateProduct(data)
    .then((response) => response.json())
    .then((response)=>{
        setLoading(false,dispatch);
        if(response.success){
            dispatch({
                type : ActionTypes.ADD_PRODUCTS,
                payload : response.data,
                success: response.success,
            })
            window.location.href = "/admin/products";
        }else{
            dispatch({
                type : ActionTypes.SET_ERROR,
                payload : response.data,
                success: response.success,
            })

        }
    })
}



export const getOrders = (data, dispatch) =>{
    setLoading(true,dispatch);
    API.getOrders(data)
    .then((response) => response.json())
    .then((response)=>{
        setLoading(false,dispatch);
        if(response.success){
            dispatch({
                type : ActionTypes.GET_ORDERS,
                payload : response.data,
                success: response.success,
            })
            // window.location.href = "/admin/orders";
        }else{
            dispatch({
                type : ActionTypes.SET_ERROR,
                payload : response.data,
                success: response.success,
            })

        }
    })
}

export const updateOrders = (data, dispatch) => {
  dispatch({
    type : ActionTypes.UPDATE_ORDERS,
    payload : data,
    success: true,
  })
}