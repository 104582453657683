import React, {useState, useEffect, useMemo} from "react";

// ------------ Lib ------------------
import _ from "lodash";

// ------------ Components ------------
import {Modal} from "@redq/reuse-modal";
import {Row, Col, Button, Form, ListGroup} from "react-bootstrap";
import {Switch} from "@material-ui/core";
import GoogleMapInfo from "../../components/GoogleMapInfo";
import {browSer} from "../../helper/constant";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import {
    Map,
    GoogleApiWrapper,
    Marker,
    InfoWindow,
    GoogleApiOptionsFunc,
} from "google-maps-react";
import {Spinner} from "react-bootstrap"
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

// -------------- APIs ---------------
import API from "../../network/dashboardAPI";

// -------------- Style --------------
import styled from "styled-components";
import "react-datetime/css/react-datetime.css";
import {FormattedDate} from "react-intl";

let getLat;
let getLng;

const HelpPageWrapper = styled.div`
  background-color: #f7f7f7;
  position: relative;
  padding: 50px 0 50px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 989px) {
    padding-top: 70px;
  }
`;

const labelStyle = {
    lineHeight: "2.5rem",
    width: "110px"
};

const currentDate = moment(new Date()).format("YYYY-MM-DD");

const mapStyles = [
    {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
];

const OrderMap = (props) => {
    var bounds = window.google.maps.LatLngBounds();

    // --------------- States ------------------
    const [latitude, setLatitude] = useState(11.5564);
    const [longitude, setLongitude] = useState(104.9282);
    const [orderList, setOrderList] = useState([]);
    const [isRequesting, setIsRequesting] = useState(false);
    const [status, setStatus] = useState(23458);
    const [fromDate, setFromDate] = useState(currentDate);
    const [selectedStatus, setSelectedStatus] = useState();
    const [orderStatusOpts, setOrderStatusOpts] = useState([
        {
            label: "All status",
            value: {status: 23458, isShowAccptedHasDriver: false},
        },
        {label: "New", value: {status: 2, isShowAccptedHasDriver: false}},
        {label: "Accepted", value: {status: 3, isShowAccptedHasDriver: false}},
        {
            label: "Packed & Dispatched",
            value: {status: 45, isShowAccptedHasDriver: false},
        },
        {label: "Arrived", value: {status: 8, isShowAccptedHasDriver: false}}
    ]);

    const [activeMarker, setActiveMarker] = useState({});
    const [showingInfoWindow, setShowingInforWindow] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [textSearch, setTextSearch] = useState("");
    const [mapZoom, setMapZoom] = useState(14);
    const [searchString, setSearchString] = useState("");
    const [isSearching, setIsSearching] = useState(false);

    //  ------------------- Method ----------------
    const getOrderList = (param) => {
        setIsRequesting(true);
        let tmpStatus = _.clone(status);
        let tmpNumber = tmpStatus
            .toString()
            .split("")
            .map((x) => parseInt(x));
        let statusList = tmpNumber;

        let query = {
            statusList: JSON.stringify(statusList),
            fromDate
        };

        API.getOrderBasicList(query)
            .then((response) => response.json())
            .then((response) => {
                setIsRequesting(false);
                if (response.success) {
                    setOrderList(response.data);
                }
            });
    };

    useEffect(() => {
        setOrderList([]);
        getOrderList();
    }, [status, fromDate]);

    const handleSelectOrderStatus = (e) => {
        let data = orderStatusOpts[e.target.value].value;
        setStatus(data.status);

        if (showingInfoWindow) {
            setActiveMarker(null);
            setShowingInforWindow(false);
        }
    };

    const changeArrivalLimit = ((event) => {
        setFromDate(moment(event).format('YYYY-MM-DD'))

        if (showingInfoWindow) {
            setActiveMarker(null);
            setShowingInforWindow(false);
        }
    });

    const onMarkerClick = (props, marker) => {
        setSelectedOrder(props.name);
        setActiveMarker(marker);
        setShowingInforWindow(true);
    }

    const onInfoWindowClose = () => {
        setActiveMarker(null);
        setShowingInforWindow(false);
    }

    const onMapClicked = () => {
        if (showingInfoWindow) {
            setActiveMarker(null);
            setShowingInforWindow(false);
        }
    };

    const onMapLoaded = (mapProps, map) => {
        map.setOptions({
            styles: mapStyles
        })
    }

    let userMarkers = orderList.length? orderList.map((order, index) => {
        let markerIconUrl = require("../../assets/new-location-marker.png");
        let textColor = "#277203"

        if(Number(order.type) === 1){
            markerIconUrl = require("../../assets/express-location-marker.png");
            textColor = "#ff0000";
        }else{
            if (order.status === 3) {
                markerIconUrl = require("../../assets/accepted-location-marker.png");
                textColor = "#ffa500";
            } else if (order.status === 4) {
                markerIconUrl = require("../../assets/packed-location-marker.png");
                textColor = "#b87333";
            } else if (order.status === 5) {
                markerIconUrl = require("../../assets/delivering-location-marker.png");
                textColor = "#d58a94";
            } else if (order.status === 8) {
                markerIconUrl = require("../../assets/arrived-location-marker.png");
                textColor = "#b048b5";
            }
        }

        let userMarker = <Marker
            key={index}
            name={order}
            position={{
                lat: order.list_customer_address.latitude,
                lng: order.list_customer_address.long
            }}
            icon={markerIconUrl}
            onClick={onMarkerClick}
            shouldRender={false}
        />
        order.shouldRender = false
        return userMarker
    }): null;

    // set time before call api
    let typingTimer; //timer identifier
    let doneTypingInterval = 750; //time in ms (7.5 seconds)
    const handleSearch = (value) => {
        if (showingInfoWindow) {
            setActiveMarker(null);
            setShowingInforWindow(false);
        }

        setIsSearching(true)
        clearTimeout(typingTimer);
        typingTimer = setTimeout(function () {
            if(value !== ""){
                let searchValue = parseInt(value.trim());
                const findOrder = orderList.find(x => x.order_id === searchValue);
                if(findOrder){
                    console.log("found", findOrder);
                    setMapZoom(18);
                    setLatitude(findOrder.list_customer_address.latitude);
                    setLongitude(findOrder.list_customer_address.long);
                    setIsSearching(false)
                }else {
                    setMapZoom(14);
                    setLatitude(11.5564);
                    setLongitude(104.9282);
                    setIsSearching(false);
                    alert("Order number not found!");
                }
            }else{
                setMapZoom(14);
                setLatitude(11.5564);
                setLongitude(104.9282);
                setIsSearching(false);
            }

        }, doneTypingInterval);
    };

    return (
        <Modal>
            <HelpPageWrapper>
                {/* =================================== Filter =============================*/}
                <Row>
                    <Col sm={3}>
                        <InputGroup className="mb-3">
                            <FormControl
                                size="md"
                                type="text"
                                placeholder="Search order number"
                                value={searchString}
                                onChange={(e) => setSearchString(e.target.value)}
                            />
                            <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={() => handleSearch(searchString)}>Search</Button>
                            </InputGroup.Append>
                        </InputGroup>

                        {/*<Form.Control*/}
                            {/*style={{ width: "22rem" }}*/}
                            {/*size="md"*/}
                            {/*type="text"*/}
                            {/*placeholder="Search order number"*/}
                            {/*onChange={(e) => handleSearch(e.target.value)}*/}
                        {/*/>*/}
                    </Col>
                    <Col sm={4} style={{display: "inline-flex"}}>
                        <span style={{...labelStyle, width: "150px"}}>Delivery for the day:</span>&nbsp;&nbsp;
                        <Datetime style={{width: "300px"}}
                                  value={fromDate}
                                  dateFormat="YYYY-MM-DD"
                                  timeFormat={false}
                                  closeOnSelect={true}
                                  onChange={changeArrivalLimit}
                        />
                    </Col>
                    <Col sm={5} className="d-flex justify-content-end">
                        <span style={{...labelStyle}}>Filter status: </span>
                        &nbsp;&nbsp;
                        <Form.Control
                            style={{width: "300px"}}
                            as="select"
                            onChange={(e) => handleSelectOrderStatus(e)}
                            value={selectedStatus}
                            disabled={isRequesting}
                        >
                            {orderStatusOpts.map((oStatus, index) => (
                                <option value={index} key={index}>
                                    {oStatus.label}{" "}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                </Row>

                <Row>
                    <Col sm={2}>
                        <img src={require("../../assets/new-location-marker.png")} onClick={(e) => onMarkerClick({position: {
                                lat: 11.5427,
                                lng: 104.932
                            }})}/>&nbsp;&nbsp;
                        <span style={labelStyle}>New</span>
                    </Col>
                    <Col sm={2}>
                        <img src={require("../../assets/accepted-location-marker.png")}/>&nbsp;&nbsp;
                        <span style={labelStyle}>Accepted</span>
                    </Col>
                    <Col sm={2}>
                        <img src={require("../../assets/packed-location-marker.png")}/>&nbsp;&nbsp;
                        <span style={labelStyle}>Packed</span>
                    </Col>
                    <Col sm={2}>
                        <img src={require("../../assets/delivering-location-marker.png")}/>&nbsp;&nbsp;
                        <span style={labelStyle}>Delivering</span>
                    </Col>
                    <Col sm={2}>
                        <img src={require("../../assets/arrived-location-marker.png")}/>&nbsp;&nbsp;
                        <span style={labelStyle}>Arrived</span>
                    </Col>
                    <Col sm={2}>
                        <img src={require("../../assets/express-location-marker.png")}/>&nbsp;&nbsp;
                        <span style={labelStyle}>Express</span>
                    </Col>
                </Row>

                {/* =============================== Map =============================*/}
                <Row>
                    <div
                        style={{
                            width: "100%",
                            paddingTop: "10px",
                            marginBottom: "10px",
                            height: "88vh",
                            position: "relative",
                        }}
                    >
                        <Map
                            disableDefaultUI={true}
                            streetViewControl={false}
                            mapTypeControl={true}
                            keyboardShortcuts={false}
                            scrollwheel={false}
                            center={{lat: latitude, lng: longitude}}
                            google={window.google}
                            bounds={bounds}
                            style={{
                                width: "100%",
                                height: "80vh",
                            }}
                            draggable={true}
                            zoom={mapZoom}
                            initialCenter={{lat: latitude, lng: longitude}}
                            fullscreenControl={false}
                            zoomControl={true}
                            onClick={onMapClicked}
                            onReady={(mapProps, map) => onMapLoaded(mapProps, map)}
                        >
                            {
                               orderList.length? userMarkers: []
                            }

                            <InfoWindow marker={activeMarker}
                                        onClose={onInfoWindowClose}
                                        visible={showingInfoWindow}>
                                <div>
                                  <div>{selectedOrder && selectedOrder.order_id? selectedOrder.order_id: ""} {selectedOrder && selectedOrder.customer_detail? selectedOrder.customer_detail.name: ""}</div>
                                  {
                                      selectedOrder && selectedOrder.store_on_going_slot?
                                    <div>{moment(selectedOrder.store_on_going_slot.start_time, "YYYY-MM-DD hh:mm:ss").format("hh a")} - {moment(selectedOrder.store_on_going_slot.end_time, "YYYY-MM-DD hh:mm:ss").format("hh a")}</div>
                                    :<div>ASAP</div>
                                  }
                                </div>

                            </InfoWindow>
                        </Map>
                    </div>
                </Row>
            </HelpPageWrapper>
        </Modal>
    );
};

export default OrderMap;
