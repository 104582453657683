export default [
  {
    id: "1",
    type: "primary",
    title: "express-delivery",
    time_slot: "90 min express delivery",
  },
  {
    id: "2",
    type: "secondary",
    title: "8am-11am",
    time_slot: "8.00 AM - 11.00 AM",
  },
  {
    id: "3",
    type: "secondary",
    title: "11am-2pm",
    time_slot: "11.00 AM - 2.00 PM",
  },
  {
    id: "4",
    type: "secondary",
    title: "2pm-5pm",
    time_slot: "2.00 PM - 5.00 PM",
  },
  {
    id: "5",
    type: "secondary",
    title: "5pm-8pm",
    time_slot: "5.00 PM - 8.00 PM",
  },
  {
    id: "6",
    type: "secondary",
    title: "nextday",
    time_slot: "Next Day",
  },
];
