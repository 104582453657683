import * as actionTypes from "../ActionTypes";
import { dates, allslots, alltimings, twodslots } from "../../helper/constant";

const initialState = {
  address: [],
  user: [],
  past_orders: [],
  current_orders: [],
  fetch_particular_order: [],
  slots_for_date: [],
  delivery_slots: [],
  deliveryCharge: 0.0,
  distanceBetweenUserStore: 0,
  all_coupon_codes: [],
  checkoutdata: [],
  paymentResponse: null,
  success: false,
  reordersuccess: false,
  all_slots: [],
  all_timings: [],
  calendar_response: []
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.Add_Address: {
      return {
        ...state,
        success: action.success,
      };
    }
    case actionTypes.Update_Address: {
      return {
        ...state,
        success: action.success,
      };
    }
    case actionTypes.Delete_Address: {
      return {
        ...state,
        success: action.success,
      };
    }
    case actionTypes.Get_Address: {
      return {
        ...state,
        address: action.payload.sort((a, b) => {
          return a.is_default < b.is_default ? 1 : -1;
        }),
        success: action.success,
      };
    }
    case actionTypes.Update_Profile: {
      return {
        ...state,
        user: action.payload,
        success: action.success,
      };
    }
    case actionTypes.Current_Orders: {
      return {
        ...state,
        current_orders: action.payload,
        success: action.success,
      };
    }
    case actionTypes.Past_Orders: {
      return {
        ...state,
        past_orders: action.payload,
        success: action.success,
      };
    }
    case actionTypes.Fetch_Particular_Order: {
      return {
        ...state,
        fetch_particular_order: action.payload,
        success: action.success,
      };
    }
    case actionTypes.Delivery_Slots: {
      // const dates = [];
      // const slots = [];
      // Object.keys(action.payload.forEach(element => {
      //   for (var key in element) { 
      //     if (element.hasOwnProperty(key)) { 
      //       dates.push(key);
      //       element[key].forEach(elem => {
      //         slots.push(elem);
      //       })
      //     } 
      //   }  
      // })
      // );
      return {
        ...state,
        delivery_slots: dates(action.payload),
        all_timings: alltimings(action.payload),
        all_slots: allslots(action.payload),
        calendar_response: action.payload,
        success: action.success,
      };
    }
    case actionTypes.Set_All_Slots: {
      return {
        ...state,
        all_slots: allslots(action.payload)
      }
    }
    // case actionTypes.Delivery_Slots_By_Date: {
    //   return {
    //     ...state,
    //     slots_for_date: action.payload,
    //     success: action.success,
    //   };
    // }
    case actionTypes.Get_All_Coupons: {
      return {
        ...state,
        all_coupon_codes: action.payload,
        success: action.success,
      };
    }
    case actionTypes.Move_To_Checkout: {
      return {
        ...state,
        checkoutdata: action.payload,
        // delivery_slots: action.payload[0].slots,
        deliveryCharge:
          action.payload[0].self_delivery.price === undefined
            ? 0.0
            : action.payload[0].self_delivery.price,
        distanceBetweenUserStore:
          action.payload[0].distanceBetweenUserStore === undefined
            ? 0.0
            : action.payload[0].distanceBetweenUserStore,
        success: action.success,
      };
    }
    case actionTypes.Pay_By_Card: {
      return {
        ...state,
        paymentResponse: action.payload,
        success: action.success,
      };
    }
    case actionTypes.Reorder: {
      return {
        ...state,
        reordersuccess: action.reordersuccess
      }
    }
    case actionTypes.Reset_Reorder_Success: {
      return {
        ...state,
        reordersuccess: false
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
